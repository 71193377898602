import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
//import {Chart,registerables} from "node_modules/chart.js"
import {Chart,registerables} from "node_modules/chart.js"

Chart.register(...registerables);

@Component({
  selector: 'app-votingbargraph',
  templateUrl: './votingbargraph.component.html',
  styleUrls: ['./votingbargraph.component.scss']
})
export class VotingbargraphComponent implements OnInit {

  @Output() chartDataURL: EventEmitter<string> = new EventEmitter<string>();
  chartdata:any;
  optionCounts:any[]=[];
  backgroundcolour:any[]=[];
  totalvotes=0;
  labelspercent:any[]=[];
  @Input() dataToChild: string=""
  @Input() Totalvotes:any[]=[];
  metadata:any
  answered_recieved=[

    {
        "Name": "Alex",
        "UniqueVoteId": "12345",
        "Options": [1]
    },
    {
        "Name": "Jone",
        "UniqueVoteId": "12346",
        "Options": [2]
    },
    {
        "Name": "Bob",
        "UniqueVoteId": "12347",
        "Options": [3]
    },
    {
        "Name": "John",
        "UniqueVoteId": "12348",
        "Options": [3]
    },
    {
      "Name": "Alex",
      "UniqueVoteId": "12345",
      "Options": [5]
  },
  {
    "Name": "Alex",
    "UniqueVoteId": "12345",
    "Options": [4]
},


];
chartscolor:any[]=["#ADD8E6","#FFB6C1", "#90EE90","#FFA07A","#8A2BE2","#F08080","#FFFACD"]
myChart:any
  constructor() { }


  ngOnInit(): void {
    console.log("hello")

    this.chartdata=this.Totalvotes;
   //this.chartdata=this.answered_recieved
    if(this.chartdata!=null){
    console.log(this.chartdata)
    this.chartdata.forEach((vote: any) => {
      console.log(vote, "vote");
      vote.Options.forEach((option: any) => {
        console.log(option, "option");
        let index = this.optionCounts.findIndex((p: any) => p.value == option);
        console.log(index);
        if (index == -1) {
          let str = "Option " + option;
          let json = { value: option, count: 1, label: str };
          this.optionCounts.push(json);
          console.log(this.optionCounts, "this.optionCounts");
        } else {
          let index = this.optionCounts.findIndex((p: any) => p.value === option);
          this.optionCounts[index].count = this.optionCounts[index].count + 1;
        }
        // for (let i = 0; i <= this.optionCounts.length; i++) {
        //   let str = "#" + Math.floor(Math.random() * 16777215).toString(16);
        //   this.backgroundcolour.push(str);
        // }


        this.backgroundcolour=this.chartscolor.slice(0,this.optionCounts.length)


      });
    });

    let sorteddata=this.optionCounts.sort((a,b)=>a.value-b.value)
    console.log(sorteddata)

    for (let i = 0; i < this.optionCounts.length; i++) {
      console.log(this.totalvotes,"yyyy")
      console.log(this.optionCounts[i].count,"pppp")
      this.totalvotes=this.totalvotes+this.optionCounts[i].count

    }

    // for (let i = 0; i <= this.optionCounts.length; i++) {
    //   let str = "#" + Math.floor(Math.random() * 16777215).toString(16);
    //   this.backgroundcolour.push(str);
    // }

    //this.backgroundcolour=this.chartscolor
    console.log(this.totalvotes,"tpvvvv")
    for (let i = 0; i < this.optionCounts.length; i++) {
      let votepercent= (this.optionCounts[i].count/this.totalvotes)*100
     this.labelspercent.push(Math.round(votepercent));

    }






    this.RenderChart(this.optionCounts,this.backgroundcolour,this.labelspercent);
    this.shareChartData();
   // this.renderchart(this.optionCounts,this.backgroundcolour,this.labelspercent);
  }

  console.log(this.optionCounts);
// this.RenderChart(this.optionCounts,this.backgroundcolour);
}



shareChartData() {
  const chartCanvas = document.getElementById('barchart') as HTMLCanvasElement;
  const dataURL = chartCanvas.toDataURL();
  console.log(dataURL)
  this.chartDataURL.emit(dataURL);
  // this.votingurls.setChartDataURL(dataURL);
}


RenderChart(Chartsdata:any,bgcolor:any,labelspercent:any){
const myChart=new Chart("barchart",{
type:'bar',
data:{
// labels:['Red','Blue','Yellow','Green','Purple','Orange'],
labels:Chartsdata.map((item:any)=>item.label),
datasets:[{
  barPercentage: 0.4,
  label:'# of votes',
  data:labelspercent,
  backgroundColor:bgcolor,


}]
},
options:{
scales:{
  x: {

    grid: {
      color: "rgba(0, 0, 0, 0)",

    },


   },
    y: {
       grid: {
      color: "rgba(0, 0, 0, 0)",
     },
  beginAtZero: true,
  max:150,
     ticks: {
       display: false,

     }
 }
},
animation:{
  duration:0,

  onComplete: (chart) => {
    //console.log(chart.chart.data.datasets)
    const ctx = chart.chart.ctx;
    ctx.textAlign = 'center';
    ctx.fillStyle = "rgba(0, 0, 0, 1)";
    ctx.textBaseline = 'bottom';
    for (let i = 0; i < this.optionCounts.length; i++) {
      console.log(this.totalvotes,"yyyy")
      console.log(this.optionCounts[i].count,"pppp")
      this.totalvotes=this.totalvotes+this.optionCounts[i].count

    }

    console.log(this.totalvotes,"tv")
    chart.chart.data.datasets.forEach((dataset, i) => {

       const meta = chart.chart.getDatasetMeta(i);
       console.log("meta",meta.data)
       meta.data.forEach((bar,index) => {

         const data = dataset.data[index];
        // let percent=(this.optionCounts[index].count/this.totalvotes)*100
        ctx.fillText("Vote"+" "+this.optionCounts[index].count, bar.x, bar.y - 20);
       ctx.fillText(""+data+"%", bar.x, bar.y - 5);
      });
    });
  }
  },
  plugins: {

    legend: {display:false},
    tooltip:{
      enabled:false
    },



  },
}
})
}

// renderchart(Chartsdata:any,bgcolor:any,labelspercent:any){
//   console.log(Chartsdata)
//  let ctx= (<HTMLCanvasElement>document.getElementById("piechart")).getContext("2d");
//  if (this.myChart) {
//   console.log("hello")
// }
// if (!this.myChart) {
//   console.log("hello no")
// }
//   this.myChart = new Chart(ctx, {
//     type: 'bar',
//     data: {
//       labels: Chartsdata.map((item) => item.label),
//       datasets: [{
//         label: '# of votes',
//         data: labelspercent,
//         backgroundColor: bgcolor,
//         barPercentage: 0.4,
//       }]
//     },
//     options: {
//       scales: {
//         xAxes: [{

//           gridLines: {
//             drawBorder: true,
//             display: false
//         },
//         }],
//         yAxes: [{
//            gridLines: {
//             drawBorder: true,
//             display: false
//         },
//           ticks: {
//             beginAtZero: true,
//             max: 110,
//             display: false,
//           }
//         }]
//       },
//       animation: {
//         duration: 0,
//         onComplete: (chart) => {

//           console.log("chartdataOncomplete",chart)
//           var ctx = chart.chart.ctx;
//         //  var ctx=this.myChart.chart.ctx
//           ctx.textAlign = 'center';
//           ctx.fillStyle = "rgba(0, 0, 0, 1)";
//           ctx.textBaseline = 'bottom';

//           // var totalVotes = 0;
//           // for (var i = 0; i <this.optionCounts.length; i++) {
//           //   totalVotes += this.optionCounts[i].count;

//           // }
//           if(!this.metadata){
//             this.metadata=chart.chart.data.datasets[0]._meta[0]
//             console.log(this.metadata,"metadata")
//           }
//           console.log(chart.chart.data.datasets[0]._meta[0],"chart")
//           console.log(chart,"chart.data.datasets")



//           chart.chart.data.datasets.forEach(function (dataset, i) {
//            var meta = chart.chart.data.datasets[0]._meta[0];
//            // var meta = this.metadata
//             console.log(meta,"meta")
//             meta.data.forEach(function (bar, index) {
//               var data = dataset.data[index];
//               console.log(data,"data")
//               ctx.fillText("Vote" + " " + Chartsdata[index].count, bar._model.x, bar._model.y - 20);
//               ctx.fillText("" + data + "%", bar._model.x, bar._model.y - 5);
//             });
//           });

//         }
//       },
//       legend: {
//         display: false
//       },
//       tooltips: {
//         enabled: false
//       }
//     }
//   });

// }

}
