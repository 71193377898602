import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, from } from "rxjs";
import { GoogleAuthService } from "./google-auth.component";

@Injectable()
export class ClassroomApiInterceptor implements HttpInterceptor {
  constructor(private googleAuthService: GoogleAuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.startsWith("https://classroom.googleapis") ||
      req.url.startsWith("https://www.googleapis.com/drive")
    ) {
      return from(
        this.googleAuthService.getAccessToken().then((token) => {
          console.log({ token });
          var header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${token}`
          );
          var authReq = req.clone({ headers: header });
          return next.handle(authReq).toPromise();
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
