import { Injectable } from '@angular/core';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class UndoRedoService {

  pagesKey = "undo_redo_pages";

  constructor(private dialogService: DialogService) {
    
   }

  pageOperationsList = [];//contains operations done on canvas.
  removedOperationsList = [];
  PAGE_OPERATIONS_LIMIT = 20;
  canvas:any;
  pageNo;

  init(canvasInstance) {
    localStorage.removeItem(this.pagesKey);
    localStorage.setItem(this.pagesKey,JSON.stringify({
      1: { pageOperationsList: [], removedOperationsList: [] },
      2: { pageOperationsList: [], removedOperationsList: [] },
      3: { pageOperationsList: [], removedOperationsList: [] },
      4: { pageOperationsList: [], removedOperationsList: [] },
      5: { pageOperationsList: [], removedOperationsList: [] },
      6: { pageOperationsList: [], removedOperationsList: [] },
      7: { pageOperationsList: [], removedOperationsList: [] },
      8: { pageOperationsList: [], removedOperationsList: [] },
      9: { pageOperationsList: [], removedOperationsList: [] },
      10: { pageOperationsList: [], removedOperationsList: [] },
      11: { pageOperationsList: [], removedOperationsList: [] },
      12: { pageOperationsList: [], removedOperationsList: [] },
      13: { pageOperationsList: [], removedOperationsList: [] },
      14: { pageOperationsList: [], removedOperationsList: [] }, 
      15: { pageOperationsList: [], removedOperationsList: [] },
      16: { pageOperationsList: [], removedOperationsList: [] },
      17: { pageOperationsList: [], removedOperationsList: [] },
      18: { pageOperationsList: [], removedOperationsList: [] },
      19: { pageOperationsList: [], removedOperationsList: [] },
      20: { pageOperationsList: [], removedOperationsList: [] }
    }));
    this.pageOperationsList = [];
    this.removedOperationsList = [];
    this.canvas = canvasInstance;
    this.pageNo = "1";
  }

  setpageOperationsList(pageOperationsList) {
    this.pageOperationsList = JSON.parse(JSON.stringify(pageOperationsList));
  }

  setremovedOperationsList(removedOperationsList) {
    this.removedOperationsList = JSON.parse(JSON.stringify(removedOperationsList));
  }

  getpageOperationsList() {
    return JSON.parse(JSON.stringify(this.pageOperationsList));
  }

  getremovedOperationsList() {
    return JSON.parse(JSON.stringify(this.removedOperationsList));
  }

  /**
   * Called when a page is loaded.
   * Stores the previous page undo/redo stacks into localStorage and then fetches the new page undo/redo
   * stacks. 
   * @param pageNo The current pageNo.
   */
  initPage(pageNo) {
    try {
      let oldPage = this.pageNo;
      let newPage = pageNo;
      this.pageNo = newPage;
      let pages = JSON.parse(localStorage.getItem(this.pagesKey));
      pages[oldPage].pageOperationsList = [...this.pageOperationsList];
      pages[oldPage].removedOperationsList = [...this.removedOperationsList];//saving old page stacks into localStorage.
      this.pageOperationsList = pages[newPage].pageOperationsList;
      this.removedOperationsList = pages[newPage].removedOperationsList;//fetching new page stacks from localStorage.
      localStorage.setItem(this.pagesKey,JSON.stringify(pages));
    }
    catch(error) {
      console.log("DEBUGG: error",error);
    }
  }


  /**
   * The operation is stringified and then parsed to get a new instance of the operation and also to remove
   * functions from the state.
   * @param operation Contains type, oldState, newState.
   */
  pushOperationIntopageOperationsList(operation) {
    if(this.pageOperationsList.length >= this.PAGE_OPERATIONS_LIMIT) {
      this.pageOperationsList.shift();
    }
    let simpleObj = JSON.parse(JSON.stringify(operation));//Done to strip out the functions inside the fabric object.
    
    //ROHAN: attaching the id's to the objects which got removed after JSON.parse(JSON.stringify())
    if(operation.newState.type && operation.newState.type === 'activeSelection' && '_objects' in operation.newState) {
      operation.newState._objects.forEach((item, index) => {
        if(simpleObj.newState.objects[index]) {
          simpleObj.newState.objects[index].id = item.id;
          simpleObj.oldState.objects[index].id = item.id;
        }
      })
    }

    if(operation.type === 'multirem') {
      operation.newState.objects.forEach((item, index) => {
        if(simpleObj.newState.objects[index]) {
          simpleObj.newState.objects[index].id = item.id;
          simpleObj.oldState.objects[index].id = item.id;
        }
      })
    }
    
    if(operation.type != 'add-bg') {
    simpleObj.newState.id = operation.newState.id;
    simpleObj.newState.selectable = true;
    if("name" in operation.newState) {
      simpleObj.newState.name = operation.newState.name;
    }
    if(operation.type != 'add') {
      if(operation.oldState != null) {//Done only to a fix a bug in adding a Text.
        simpleObj.oldState.id = operation.newState.id;
      }
    }
    }

    // if(simpleObj.oldState != null) {
    //   if("eraser" in simpleObj.oldState) {
    //     delete simpleObj.oldState.eraser;
    //   }
    // }
    // if(simpleObj.newState != null) {
    //   if("eraser" in simpleObj.newState) {
    //     delete simpleObj.newState.eraser;
    //   }
    // }

    operation.type == "erase" ? this.pageOperationsList.push(operation) : this.pageOperationsList.push(simpleObj);
    console.log("VENKI: pageOperationsList",this.pageOperationsList);
  }

  pushOperationIntoremovedOperationsList(operation) {
    this.removedOperationsList.push(operation);
    console.log("VENKI: removedOperationsList",this.removedOperationsList);
  }

  clearRedoState() {// Whenever a added,modified or deleted operation happens, the removedOperationsList has to be cleared.
    this.removedOperationsList = [];
  }

  clearUndoState() {
    this.pageOperationsList = [];
  }

  /**
   * First, the last operation from pageOperationsList is removed and put into 
   * removedOperationsList. 
   * From the popped operation ,the newState of the object is removed from the canvas and oldState of the object
   * is added onto the canvas. 
   * For Bgs dont need to remove the new bgs. just add the old bgs.
   * @returns The object to be added onto the canvas.
   */
  undo() {
    try {
      if(this.pageOperationsList.length == 0) {
        if(localStorage.getItem("type") && localStorage.getItem("type") == 'teacher'){
          // this.dialogService.showError("Nothing to undo");
          return null;
        }
      }
      let poppedOperation = this.pageOperationsList.pop();
      let poppedObjPreviousState;

      //UNDO for bgs.
      if(poppedOperation.type == 'add-bg') {
        this.pushOperationIntoremovedOperationsList(poppedOperation);
        return {type:'background',oldState:poppedOperation.oldState};
      }

      //ROHAN: UNDO pointErase
      if(poppedOperation.type === "erase") {
        this.pushOperationIntoremovedOperationsList(poppedOperation); // Move to redo stack

          poppedOperation.newState.forEach(({ id }) => {
            const target = this.canvas.getObjects().find(obj => obj.id === id);
            if (target && target.eraser && target.eraser._objects.length > 0) {
              target.eraser._objects.pop(); // Remove the last erased path
              target.eraser.set('dirty', true); // Mark eraser as dirty to redraw
              target.set('dirty', true); // Mark target as dirty -- clearing the cache
            }
          });
          this.canvas.renderAll();
          return null;
      }

      //ROHAN: UNDO active-selection (MultiSelect).
      if(poppedOperation.newState && 'type' in poppedOperation.newState && poppedOperation.newState.type == 'activeSelection') {
        
        poppedOperation.newState.objects.forEach((item, index) => {
          const target = this.canvas.getObjects().find(obj => obj.id === item.id);
          let preservedEraserState = target.eraser ? JSON.parse(JSON.stringify(target.eraser)) : null;
          item.eraser = preservedEraserState;
          this.canvas.remove(target);
        });
        this.pushOperationIntoremovedOperationsList(poppedOperation); // Move to redo stack
          if(poppedOperation.oldState != null && 'objects' in poppedOperation.oldState) {
            poppedObjPreviousState = JSON.parse(JSON.stringify(poppedOperation.oldState));
          }
          if(poppedObjPreviousState == null) {
            return null;
          }
          this.canvas.renderAll();
          return {type:'fabricObject', oldState: poppedObjPreviousState};
      }

      if (poppedOperation.type == 'multirem') {
        this.pushOperationIntoremovedOperationsList(poppedOperation);
        if (poppedOperation.oldState != null) {
          poppedObjPreviousState = JSON.parse(JSON.stringify(poppedOperation.oldState));
        }
        if (poppedObjPreviousState == null) {
          return null;
        }
        return { type: 'fabricObject', oldState: poppedObjPreviousState };
      }
      
      //UNDO for fabric objects.
      let poppedObjId = poppedOperation.newState.id;
      let allObjects = this.canvas.getObjects();
      allObjects.forEach((object)=>{
        if(object.id == poppedObjId) {
          // preserving the eraser property to the erased object before removing from canvas, so that the eraser path can later be applied during redo.
          let preservedEraserState = object.eraser ? JSON.parse(JSON.stringify(object.eraser)) : null;
          poppedOperation.newState.eraser = preservedEraserState;
          this.canvas.remove(object);//remove new state of the object 
        }
      })
      this.pushOperationIntoremovedOperationsList(poppedOperation);
      if(poppedOperation.oldState != null) {
        poppedObjPreviousState = JSON.parse(JSON.stringify(poppedOperation.oldState));// return new instance.
      }
      if(poppedObjPreviousState == null) {
        return null;
      }
      return {type:'fabricObject',oldState:poppedObjPreviousState};
    }
    catch(error) {
      console.log(error);
      return null;
    }
  }

  /**
   * First the last operation from removedOperationsList is popped and 
   * put into pageOperationsList.
   * Then from the popped operation the oldState of object is removed from canvas and
   * new state of object is added onto the canvas. 
   * For Bgs dont need to remove the old bgs, just add the new bgs.
   * @returns Object to be added onto the canvas.
   */
  redo() {
    try {
      if(this.removedOperationsList.length == 0) {
        if(localStorage.getItem("type") && localStorage.getItem("type") == 'teacher'){
          // this.dialogService.showError("Nothing to redo");
          return null;
        }
      }
      let poppedOperation = this.removedOperationsList.pop();
      let poppedObjNewState;

      //REDO for bgs.
      if(poppedOperation.type == 'add-bg') {
        this.pageOperationsList.push(poppedOperation);
        return {type:'background',newState:poppedOperation.newState};
      }      

      //ROHAN: REDO pointErase
      if (poppedOperation.type === 'erase') {
        this.pushOperationIntopageOperationsList(poppedOperation)
        return {type: "fabricObject", newState: {type: 'eraserPaths', objects: poppedOperation.newState}};
      }

      //ROHAN: REDO active-selection (MultiSelect).
      if(poppedOperation.oldState && 'type' in poppedOperation.oldState && poppedOperation.oldState.type == 'activeSelection') {
        poppedOperation.oldState.objects.forEach(({ id }) => {
          const target = this.canvas.getObjects().find(obj => obj.id === id);
          this.canvas.remove(target);
        });
        this.pushOperationIntopageOperationsList(poppedOperation);
          if(poppedOperation.newState != null && 'objects' in poppedOperation.newState) {
            poppedObjNewState = JSON.parse(JSON.stringify(poppedOperation.newState));
          }
          if(poppedObjNewState == null) {
            return null;
          }
          this.canvas.renderAll();
          return {type:'fabricObject', newState: poppedObjNewState};
      }

      if (poppedOperation.type == 'multirem') {
        poppedOperation.oldState.objects.forEach(({id}) => {
          const target = this.canvas.getObjects().find(obj => obj.id === id);
          this.canvas.remove(target);
        })
      }

      //REDO for fabric objects.
      let allObjects = this.canvas.getObjects();
      allObjects.forEach((object)=>{
        if(object.id == poppedOperation.newState.id) {
          this.canvas.remove(object);//remove the old state of the object
        }
      })
      this.pageOperationsList.push(poppedOperation);
      if(poppedOperation.type == 'multirem' || poppedOperation.type == 'rem') {// if rem then the object has to be removed from the canvas instead of adding. The object is already removed in the previous lines.
        return null;
      }
      poppedObjNewState = JSON.parse(JSON.stringify(poppedOperation.newState));
      return {type:'fabricObject',newState:poppedObjNewState};
    }
    catch(error) {
      console.log(error);
      return null;
    }
  }

  isPageOperationsListEmpty() {
    if(this.pageOperationsList.length==0) {
     return true;
     }
    else{
     return false;
     }
    }
   
   isRemovedOperationsListEmpty() {
    if(this.removedOperationsList.length==0) {
     return true;
     }
    else{
     return false;
     }
    } 

}

//Every fabric operation done on the canvas is stored in pageOperationsList. 
//Operation -> {type,oldState,newState}
//When undo is pressed, the last operation in pageOperationsList has to be reverted. To do this simply 
//remove the newState and add the oldState of the object onto the canvas.
//Then store the reverted operation in separate list called removedOperationsList. 
//When redo is pressed the last operation which was reverted has to be redone. To do this pop the last reverted operation 
//from removedOperationsList and simply remove the oldState and newState onto the canvas.



