import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogService } from '../dialog.service';

interface Message {
  message: string;
  type: string;
}
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  //@Input() type: string = ''; 
 // @Input() message: string = '';
  @Output() dialogClosed = new EventEmitter<void>();
  @Input() message: Message | null = null;

 

  constructor(private dialogService: DialogService) { }
  showgeneralerror:boolean=false;
  showavchaterror:boolean=false;
  copyClicked:boolean=false;
  avchaturl:string;
  buttonText: string = 'Copy link';
  ngOnInit(): void {
    console.log("ccccccccccc",this.message)
    this.avchaturl = window.location.href;
    console.log("url",this.avchaturl)
    console.log("inside dailog comp ",this.message)
    if(this.message.type == 'avqr'){
      this.showavchaterror=true;
      this.showgeneralerror=false;
    }
    else{
      this.showgeneralerror=true
      this.showavchaterror=false
    }
  }

  copylink(){
    navigator.clipboard.writeText(this.avchaturl)
    this.buttonText = 'Copied';
  }

  onDialogClosed() {
    this.dialogClosed.emit();
  }

  onConfirm() {
    this.dialogService.updatePageToBeDeletedSubject();
    this.dialogService.clearError();
  }

  onCancel() {
    this.dialogService.storePageNumberToBeDeleted(-1);
    this.dialogService.clearError();
  }
}
