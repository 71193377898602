import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  HostListener,
  ElementRef
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EditorcanvasComponent } from "projects/angular-editor-fabric-js/src/public-api";
import { FileService } from "../file.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as QRCode from "qrcode";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SharetogoogleComponent } from "../sharetogoogle/sharetogoogle.component";
import { GoogleClassroomService} from "../gc-plugin/google-apis.service";
import { TokenGenerationService } from "../qrlogin/tokenGenerationService.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ColorPickerService } from "ngx-color-picker";
import { Subscription } from 'rxjs';
import { HelperService } from "../utils/helper.service";
import { BrowserDetectionService } from '../browser-detection.service';
import { DialogService } from "../dialog.service";
import { UndoRedoService } from "../undo-redo.service";
import { ThumbnailReplacerService } from "src/app/thumbnail-replacer.service.js";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { CdkDragEnd, CdkDragMove, CdkDragStart } from "@angular/cdk/drag-drop";

//list all canvas
interface JsonItem {
  id: string;
  filename: string;
  originalfilename: string;
  pathUrl: string;
}

@Component({
  selector: "app-whiteboardpage",
  templateUrl: "./whiteboardpage.component.html",
  styleUrls: ["./whiteboardpage.component.scss"],
})
export class WhiteboardpageComponent implements OnInit {
  isLoading = true;
  @ViewChild("pdfimgmodal_exit") pdfimgmodal_exit: TemplateRef<any>;
  @ViewChild("pdfimgmodalemail_exit") pdfimgmodalemail_exit: TemplateRef<any>;
  @ViewChild("QRmodal_exit") QRmodal_exit: TemplateRef<any>;
  @ViewChild('insertDiv') insertDiv!: ElementRef;
  public save_exit_ref: BsModalRef;
  modalSubscription: Subscription;
  public QRmodal_exit_ref: BsModalRef;
  public pdfimgmodalemail_exit_ref: BsModalRef;
  public pdfimgmodal_exit_ref: BsModalRef;
  public sharetogoogleRef: BsModalRef; // changes - shreyas.s
  thick: string;
  isNavOpen: boolean = false;
  opendrawtool: boolean = false;
  drawClicked: boolean = false;
  // pendrawevent: boolean = false;
  openerasetool: boolean = false;
  eraseClicked: boolean = false;
  openselectiontool: boolean = false;
  selectClicked: boolean = false;
  openpages: boolean = false;
  allpages: boolean = false;
  ShowVotesection: boolean = false;
  showdoucmentlist: Boolean = false;
  ShowBackdropSection: boolean = false;
  backdropClicked:boolean = false;
  EnableMenu: boolean = true;
  _openSelectTool: boolean = false;
  startnotification: boolean = false;
  startclassmsg: string;
  notificationIcon: string = '';
  get openSelectTool(): boolean {
    return this._openSelectTool;
  }
  set openSelectTool(val: boolean) {
    this._openSelectTool = val;
    if(val == true) {
      this.mycanvas.eventsForCornerFuncOn();
    }
    else {
      this.mycanvas.eventsForCornerFuncOff();
    }
    console.log("Venki: debugg val",val);
  }
  opentexttool: boolean = false;
  typeClicked: boolean = false;
  openUndoTool: boolean = false;
  undoClicked: boolean = false;
  undoButtonEnabled: boolean = true;
  redoButtonEnabled: boolean = true;
  openRedoTool: boolean = false;
  redoClicked: boolean = false;
  openAddPageTool: boolean = false;
  addpageClicked: boolean = false;
  openPreviousTool: boolean = false;
  previousClicked: boolean = false;
  openNextTool: boolean = false;
  nextClicked: boolean = false;
  openLogoutTool: boolean = false;
  logoutClicked: boolean = false;
  insertClicked: boolean = false;
  toolsClicked: boolean = false;
  calculatorColor: boolean = false;
  thumbnailSpinner : boolean = false
  calculatorClicked: boolean = false;
  votingColor: boolean = false;
  votingClicked: boolean = false;
  compassColor: boolean = false;
  compassClicked: boolean = false;
  rulerClicked: boolean = false;
  protractorClicked: boolean = false;
  setsquareClicked: boolean = false;
  flashlightClicked: boolean = false;
  shareClicked: boolean = false;
  frame8Clicked: boolean = false;
  pencilClicked: boolean = false;
  highlighterClicked: boolean = false;
  addcolorClicked: boolean = false;
  qrClicked: boolean = false;
  emailClicked: boolean = false;
  copyClicked: boolean = false;
  googleClicked: boolean = false;
  fileClicked: boolean = false;
  color: string = "#000000";
  intialpentrue:boolean=true;
  disabledThickness:Boolean=false;
  pencilthick:any="3"
  noInternetImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABPlBMVEUAAAD////////////////////f39/j4+Pq6urr6+vh4eHm5ubn5+fn5+fp6enq6urr6+vn5+fp6enq6urm5ubm5ubn5+fo6Ojp6enq6urm5ubo6Ojo6Ojp6enn5+fn5+fo6Ojo6Ojp6enn5+fn5+fo6Ojo6Ojp6enn5+fo6Ojo6Ojp6enn5+fn5+fo6Ojn5+fn5+fo6Ojp6enp6eno6Ojo6Ojp6eno6Ojo6Ojo6Ojn5+fo6Ojo6Ojo6Ojo6Ojo6Ojp6enn5+fo6Ojp6enn5+fo6Ojo6Ojo6Ojo6Ojp6eno6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojp6eno6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojp6eno6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6Ojo6OgT5ZkVAAAAaXRSTlMAAQIDBAUICQwNERQVICIlJisuMTM0NTg6PD5CQ0RJSkxPU1RVWVpeYGRlaGtscHV3eX2Jjo+VmJqcoqSlpqepq6ywtre7vL7HzNbX293e3+Di4+Tm5+jp6+zt7u/w8/T19vf4+fr7/P7jmzIMAAAAAWJLR0QB/wIt3gAAAVZJREFUOMuF02k7AlEUB/B/jaFkiYgkS4WEFEURsovsWZIppcn8v/8X8GKmmmmZzrv7nN9z7zn33AsgkoBpREhTYcmwjxBPVSHORo+yT8Xic/YkNid2iORegbr4Sk21i464dvcTStKmpm2aUC52lmfGxt3+2PmPSnITAOAtBADR2Mvg6g1JUvICvirrwQ4BLN2TZNWHR5J1VxcxdECSD3gnlW1AE7n8S/Z4zaGSqELmEZS+Q1ovmUb9ld1hAEBIkgKAYAUAOPSCb9MAAKvQqqkUNIjygnEgHl0vvxJJVjwGcEdSdmoilSDJWwMokMp6s9L4hkJ+GkC4Jm/qeolvybWwsQi7HQBG5htCXbfHaF72N0TXx3VImotXkn8rgJAmmbB0gg9St0eXl3xGkpQXe4rJMkmWnOgpXFfl8qWrdR9dThGa8xP7/ighTe5bzIV5HhjokwcA/AMYzIPLgiCdhAAAAABJRU5ErkJggg=='
  canvasObjects: any[] = [];
  demosubscription:Boolean;
  demomsg:string


  thumbnails: string[] = []; // Initialize an empty array

  // currentPosition = 0;
  // totalObjects = 0;
  currentYTFloatingWindows = []; //change - shreyas.s
  currentmp4FloatingWindows = []; //change - shreyas.s
  currentDocFloatingWindows = []; //change - shreyas.s
  isDocumentViewerLoading: boolean = false; //change - shreyas.s
  current3DObjFloatingWindows = []; //change - shreyas.s
  // openpages
  pageopenclose: boolean = false;
  openBackdropBox: boolean = false;
  showSpinner: boolean=false;
  hideforandroid:boolean=false;
  initials:string;
  audioshow:boolean=false
  sw: any;
  sh: any;
  showresolutionerror: boolean = false;

  ////////////////changes made for search functiobality in insert//////////////////
@ViewChild('insertImagescroll',{ static: false }) insertImagescroll:ElementRef;
@ViewChild('insertDOcumentscroll',{ static: false }) insertDOcumentscroll:ElementRef;
@ViewChild('insertaudioscroll',{ static: false }) insertaudioscroll:ElementRef;
@ViewChild('videoScroll',{ static: false }) videoScroll:ElementRef;
@ViewChild('threeDscroll',{ static: false }) threeDscroll:ElementRef
@ViewChild('modalContentInfo') modalContentInfo: TemplateRef<any>;
public modals_exit_ref: BsModalRef;
  closeIntAlert: boolean = false;
  handledrawtoolforpage:Boolean=false;
////////////////changes made for search functiobality in insert//////////////////

  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent) {
    console.log("sssssssssssss", this.pageopenclose);
    if (this.pageopenclose == true) {
      console.log("vvvvvvvvvvvvvvvvvv", this.pageopenclose);
      //this.openpages = !this.openpages;
      console.log(this.openpages,"Bandana on click")

      if (this.openpages  === true) {
        this.openSelectTool = true;
      } else if((this.openpages  === false)&&this.handledrawtoolforpage===true)  {

       this.activatedrawtool();
       this.handledrawtoolforpage=false;
      }else{
        this.openSelectTool = true;
      }
      if(this.openpages){

      }
      this.allpages = !this.allpages;
      this.backdropClicked = false;
      this.pageopenclose = false;
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.opentoolsicon = false;
      this.openinsert = false;
      this.opentexttool = false;
      this.eraseClicked = false;
      this.selectClicked = false;
      this.typeClicked = false;
      this.toolsClicked = false;
      this.insertClicked = false;
      this.drawClicked = false;
      this.redoClicked = false;
      this.undoClicked = false;
      this.openUndoTool = false;
      this.openRedoTool = false;
      this.isshareopen = false;
      this.shareClicked = false;
      this.fileClicked = false;
      this.qrClicked = false;
      this.mycanvas.exitEditText()
    } else {
      this.pageopenclose = false;
      this.openpages = false;
      this.allpages = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
  this.checkOrientationOnLoad();
  }

  objectlist:Boolean=false
  _currentPage: number = 0;
  get currentPage():number {
    return this._currentPage;
  }
  set currentPage(val:number) {
    this._currentPage = val;
    console.log("02-11-2024",this._currentPage);
    if(this.mycanvas !== undefined) {
      this.mycanvas.currentPageNumber = val;
    }
  }
  totalPages: number = 0;

  iconMoveUrl = "../assets/icon_move.svg";
  iconRotateUrl = "../assets/icon_rotate.svg";
  qrCodeData: any;
  jlist: JsonItem[] = [];
  // dialogMessage: string | null = null;

  constructor(
    public fileservice: FileService,
    private modalService: BsModalService,
    public router: Router,
    public _Activatedroute: ActivatedRoute,
    public http: HttpClient,
    private googleClassroomService: GoogleClassroomService ,
    private tokengeneration:TokenGenerationService,
    private sanitizer: DomSanitizer,
    private colorPickerService: ColorPickerService,
    public helper: HelperService,
    private browserDetectionService: BrowserDetectionService,
    private dialogService: DialogService,
    public undoRedoService: UndoRedoService,
    private ThumbnailReplacerService: ThumbnailReplacerService,
  ) {}
  openinsert: boolean = false;
  drawtoolactive:boolean=false;
  customData:any;
  imagePath: string;
  androidsessid:any;
  show: boolean = false;
  subscription: boolean = false;
  submsg: string;
  licenseupgradecheck:Boolean;
  demoUpgradeVisible: boolean = true;
  videoHeight: number;
  videoWidth: number;
  loginHappened: boolean = false;

  @ViewChild("canvas", { static: false }) mycanvas: EditorcanvasComponent;
  @ViewChild("modalContent") modalContent: TemplateRef<any>;

  //subscriptions.
  private service_deletePageSubscription:Subscription;


  ngOnInit(): void {
    this.loginHappened = true;
    this.updateVideoDimensions();
    window.addEventListener('resize', this.updateVideoDimensions.bind(this)); // Update on window resize
    //permission level
    this.licenseupgradecheck=this.fileservice.checklicenseupgradestatus()
    console.log('upgrade boolean Check', this.licenseupgradecheck );
    this.helper.validateUserForPage();
    var type = localStorage.getItem("type");
    var licensetype = localStorage.getItem("licensetype")
    if (type == "teacher" && licensetype == "1 user free version / freemium license") {
      this.show = true
    } else {
      this.show = false
    }

    var storedDifferenceDays = localStorage.getItem("subscription");
    var storedDifferenceDaysnumber = Number(storedDifferenceDays);
    console.log(storedDifferenceDaysnumber);
    if (storedDifferenceDaysnumber <= 5) {
      const dayLabel = storedDifferenceDaysnumber === 1 ? "day." : "days.";
      this.subscription = true
      this.submsg = "Subscription expiring in " + storedDifferenceDaysnumber + " " + dayLabel + " Contact admin to renew plan."
    }
    if (storedDifferenceDaysnumber === 0) {
      this.subscription = true
      this.submsg = "The Subscription expiring end date is today."
    }


    this.sw = this.getScreenWidth();
    this.sh = this.getScreenHeight()
    this.androidsessid = this.fileservice.getSessionId();
    console.log("whiteboardcomponent",this.androidsessid);
    //localStorage.setItem("CopySessionId", this.androidsessid);
    console.log("in whitboard component");
    var fullname = localStorage.getItem("username");
    this.getInitials(fullname)
    this.customData = this._Activatedroute.snapshot.data.customData;
    console.log("this.customdata",this.customData)
    this.totalPages = Number(localStorage.getItem("totalPages"));
    this.currentPage = Number(localStorage.getItem("currentPage"));
    this.thumbnails = JSON.parse(localStorage.getItem("thumbnail")) || [];
    this.fileservice.listenforlogout().subscribe((val: any) => {
      if(val.token != this.tokengeneration.getToken()) return;
      this.fileservice.getTVInformation({}, "/user/logout").then(
        (res: any) => {
          try {
            if (res && res.status) {
              localStorage.clear();
              this.router.navigate(["/qrlogin"]);
            }
          } catch (e) {
            console.error(e);
          }
        },
        (err: any) => {
          console.error(err);
        }
      );
    });

    this.fileservice.loadData.subscribe((res: any) => {
      if (res) {
        if (res.type == "total_pages") {
          this.totalPages = Number(localStorage.getItem("totalPages"));
          this.currentPage = 1;
          localStorage.setItem("currentPage", String(this.currentPage));
        } else if (res.type == "current_page") {
          this.totalPages = Number(localStorage.getItem("totalPages"));
        }
      }
    });

    this.fileservice.handlevotesSocketAnswerRecieved().subscribe((val: any) => {
      console.log(val, "value in whiteboard");
      this.fileservice.joinToClass();
      this.fileservice.emitWebSocketEvent("GetTotalvotebyid", {});
    });

    this.fileservice.refreshPageCount.subscribe(async (res) => {
      if (res == "yes") {
        //this.thumbnailSpinner = true
        // this.totalPages = Number(localStorage.getItem("totalPages"));
        // this.currentPage = Number(localStorage.getItem("currentPage"));
        // this.mycanvas.loadCache('');
        // this.thumbnailSpinner = false
      }
    })
    this.fileservice.getChangedPage().subscribe(async (val:any)=>{
      if(val && val.lessonId == localStorage.getItem('mainlessonID')){
        // localStorage.setItem('currentPage',val.pageId)
        // this.currentPage = val.pageId
        // if(this.currentPage > this.totalPages){
        //   this.totalPages = this.currentPage
        // }
        // await this.mycanvas.loadCache('')
      }
     });

     setInterval(async () => {
      if(this.fileservice.readPageChangeStatus(this.currentPage)) {
        this.fileservice.generateNewThumbnailUrlForPage(this.currentPage,localStorage.getItem("FileID"));
        this.mycanvas.saveCanvasDataToMongoDB();
        this.fileservice.updatePageChangeStatus(this.currentPage,false);
      }
    }, 60000);



    const tag = document.createElement("script"); //changes - shreyas.s
    tag.src = "https://www.youtube.com/iframe_api"; //changes - shreyas.s
    document.body.appendChild(tag); //changes - sheryas.s

    setTimeout(()=>{
         const dd=document.getElementById("pen")as HTMLElement;
         console.log("hhhh ngOnInit")
         console.log(dd,"dd");
         if(dd){
           dd.style.borderColor="#2a72db";
         }
         // const cc = document.getElementById("high") as HTMLElement;
         // cc.style.borderColor = "";
        // this.mycanvas.selectPenCil();
         this.pencilClicked=true;
         this.highlighterClicked=false;
         this.drawtoolactive=true
         this.selectedIcon="icon6";
        this.drawClicked=true;
        this.thick=this.pencilthick;
          this.mycanvas.selectPenCil();
       // this.thicknesschanged(this.thick);
         console.log(dd);

      },100)

      this.fileservice.loadThumbnail.subscribe(res=>{
        if(res == 'hide'){
          this.openpages = false
        }
      })

       this.preloadAssets().then(() => {
        this.isLoading = false;
      });


      if(type==="demoaccounts"){
        console.log("Demo accounts")
        this.demosubscription=true
        this.demomsg=`Welcome! You are currently working on a demo account. This account will be disabled after ${storedDifferenceDaysnumber} days.`
      }
      const currentUrl = window.location.href;
      const userAgent = window.navigator.userAgent;
      console.log("User Agent:", userAgent);
      console.log("Current URL:", currentUrl);

      const sessid = this.getQueryParam(currentUrl, 'sessid');
      if (sessid) {
        console.log("Session ID is present from Android");
        this.fileservice.setSessionId(sessid);
      } else {
        console.log("Either session ID is not present or not able to extract");
      }

      this.undoSubject.pipe(debounceTime(100)).subscribe(() => {
        this.mycanvas.eraseCanvasChange()
        this.recievebackdropcloseoption(false);
        this.mycanvas.unselectActiveObject();
        this.mycanvas.undo(this.browserDetectionService.getBrowserInfo().canvasWidth,
        this.browserDetectionService.getBrowserInfo().canvasHeight);
      })

      this.redoSubject.pipe(debounceTime(100)).subscribe(()=>{
        this.recievebackdropcloseoption(false);
        this.mycanvas.eraseCanvasChange()
        this.mycanvas.unselectActiveObject();
        this.mycanvas.redo(this.browserDetectionService.getBrowserInfo().canvasWidth,
        this.browserDetectionService.getBrowserInfo().canvasHeight);
      })

      this.loadLessonSubject.pipe(debounceTime(250)).subscribe(async (lessonData:any)=>{
        try {
          let activePage = 1;
          if(lessonData.ActivePage) {
            activePage = lessonData.ActivePage;
          }
          await this.mycanvas.loadLesson(lessonData.Pages,lessonData.fileId,activePage);
          const totalPages = lessonData.Pages.length;
          const currentPage = activePage;
          localStorage.setItem('currentPage',currentPage.toString());
          localStorage.setItem('totalPages',totalPages.toString());
          localStorage.setItem('FileID',lessonData.fileId);
          localStorage.setItem('mainlessonID',lessonData._id);
          this.totalPages = totalPages;
          this.updatePageNumber(currentPage);
          this.ThumbnailReplacerService.startExecutionOfTasks();
          this.hideOverlay();
        }
        catch(error) {
          console.log("Error",error);
          this.hideOverlay();
        }
      });

      this.pageChangeSubject.pipe(debounceTime(250)).subscribe(async (update:number)=>{
        try {
          await this.mycanvas.updateFileAndLoadNewPage(update);
          localStorage.setItem("currentPage", String(update));
          this.updatePageNumber(update);
          this.ThumbnailReplacerService.startExecutionOfTasks();
          this.hideOverlay();
        }
        catch(error) {
          console.log("Error",error);
          this.hideOverlay();
        }
      });

      this.addPageSubject.pipe(debounceTime(250)).subscribe(async ()=>{
        try {
          await this.mycanvas.addPage();
          //update UI and hide overlay.
          let totalPages = Number(localStorage.getItem('totalPages'))
          totalPages = totalPages + 1
          localStorage.setItem('totalPages', String(totalPages))
          this.totalPages = totalPages
          localStorage.setItem('currentPage',String(totalPages))
          this.currentPage = this.totalPages;
          const fileId = localStorage.getItem("FileID")
          this.fileservice.updateActivePage(fileId, this.currentPage).subscribe({
            next: (response) => {
            console.log('Active page updated:', response);
            },
            error: (error) => {
            console.error('Error updating active page:', error);
          }
          });
          this.openSelectTool = false;
          this.hideOverlay();
        }
        catch(error) {
          console.log("Error:",error);
          this.openSelectTool = false;
          this.hideOverlay();
        }
      });

      this.deletePageSubject.pipe(debounceTime(250)).subscribe(async (pageNumberToBeDeleted:number)=>{
        try {
          await this.mycanvas.deletePage(pageNumberToBeDeleted);
          //update UI and hide overlay.
          let totalPages = Number(localStorage.getItem('totalPages'))
          totalPages = totalPages - 1
          localStorage.setItem('totalPages', String(totalPages))
          this.totalPages = totalPages
          if(pageNumberToBeDeleted == 1) {
            localStorage.setItem('currentPage',String(pageNumberToBeDeleted))
            this.currentPage = pageNumberToBeDeleted;
          }
          else {
            localStorage.setItem('currentPage',String(pageNumberToBeDeleted - 1))
            this.currentPage = pageNumberToBeDeleted - 1;
          }
          this.hideOverlay();
        }
        catch(error) {
          console.log("Error:",error);
          this.dialogService.showError(error);
          this.hideOverlay();
        }
      })

      this.service_deletePageSubscription = this.dialogService.deletePage$.subscribe((res)=>{
        console.log("05-11-2024 ngOnInIt",res);
        if(res != -1) {
          this.deletePage(res);
        }
      })
      if(localStorage.getItem('avchatstarted') == 'yes'){
        this.StartclassNotification(localStorage.getItem('mode'))
      }
      this.checkOrientationOnLoad();
    }

    checkOrientationOnLoad(): void {
      console.log('window height and width', window.innerWidth, window.innerHeight);
      if (window.innerHeight > window.innerWidth) {
        console.log('open window alert')
        this.showresolutionerror = true
        // this.chaticon();
      } else {
        console.log('close window alert')
        this.showresolutionerror = false;
        // this.closeModal2();
      }
    }

    loadLessonSubject = new Subject();
    ngAfterViewInit() {
      //fetch last updated lesson.
      if(this.fileservice.isLoginByTeacher()) {
        this.fileservice.getLastUpdatedLesson(localStorage.getItem('userId')).subscribe((res)=>{
          console.log("last updated lesson file",res);
          localStorage.removeItem("savedCanvasData")
          localStorage.removeItem("currentCanvas")
          this.showOverlay();
          this.loadLessonSubject.next(res.lastUpdatedLesson);
        },(error)=>{
          console.error(error);
          this.hideOverlay();
        })
      }
    }

    loadbgAndColor() {
      let bgStates = this.mycanvas.getCanvasBgStates();
      let bgColor = bgStates.bgColor;
      let bgFrame = '';
      let customBg = '';
      bgStates.bgImage.startsWith('assets') ? bgFrame = bgStates.bgImage : customBg = bgStates.bgImage;

      const currentPageColor = bgColor;
      const pageColorIndex = this.selectedBgColors.indexOf(currentPageColor);
      console.log('Next load current page color:', currentPageColor, this.selectedBgColors.indexOf(currentPageColor));
      this.selectedBgColorIndex = pageColorIndex;
      if(pageColorIndex == -1) {
        this.selectedBgColorIndex = 0;
      }
      this.bgColorCurrentState = this.selectedBgColorIndex;

      const currentPageFrame = bgFrame;
      console.log('Next load current page frame:', currentPageFrame);
      const frameNumber = this.frameMapping[currentPageFrame] || '';
      console.log('Next load frame number:', frameNumber);
      if (currentPageFrame == null || currentPageFrame == "") {
        this.currentFrameImage = 'frame1';
       } else if (frameNumber == this.currentFrameImage) {
        this.currentFrameImage = this.currentFrameImage;
       } else {
        this.currentFrameImage = frameNumber;
       }
       this.bgFrameCurrentState = this.currentFrameImage;

      const currentCustomBg = customBg;
      if(currentCustomBg == null) {
       this.bgImgCurrentState = '';
      }
      else {
       this.bgImgCurrentState = currentCustomBg;
      }
      console.log("Venki: 02-11-2024",this.bgColorCurrentState,this.currentFrameImage,this.bgImgCurrentState)
  }

  bgColorLessonChange: any;
  bgFrameLessonChange: any;
  customBgLessonChange: any;

  updateVideoDimensions() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    this.videoWidth = vw * 0.40;  // 40vw
    this.videoHeight = vw * 0.24; // 24vw
  }

  handleVolumeChange() {
    const audioElement = document.getElementById('audioElement') as HTMLAudioElement;
    const isMuted = audioElement.muted || audioElement.volume === 0;

    if (isMuted) {
      this.sendAudioControlToSocket('audio_mute');
    } else {
      this.sendAudioControlToSocket('audio_unmute');
    }
  }
  handlevideoVolumeChange(){
    const videoElement = document.getElementById('videoElement') as HTMLVideoElement;
    const isMuted = videoElement.muted || videoElement.volume === 0;

    if (isMuted) {
      this.sendVideoControlToSocket('video_mute');
    } else {
      this.sendVideoControlToSocket('video_unmute');
    }
  }

  sendSeekEvent() {
    const audioElement = document.getElementById('audioElement') as HTMLAudioElement;
    const currentObj = {
      object : this.audiourl,
      media:"audio",
      action_type: 'audio_seek',
      currentTime: audioElement.currentTime,
      mainLessonId: localStorage.getItem('mainlessonID'),
      roomid:localStorage.getItem('roomID'),
    };

  console.log('sendSeekEvent: Emitting seek event to WebSocket');
  this.fileservice.emitCanvasOperationToServer(currentObj);  // Replace with your actual WebSocket emit method
  }

  checkBrowserSettings() {
    const browserInfo = this.browserDetectionService.getBrowserInfo();
    console.log('Browser Info:', browserInfo);

    if ((browserInfo.screenWidth == 1920 && browserInfo.screenHeight == 1080)||
      (browserInfo.screenWidth == 1536 && browserInfo.screenHeight == 864)||
      (browserInfo.screenWidth == 1280 && browserInfo.screenHeight == 720 )
      ) {
      console.log("screen resolution match");
    }
    else{
      console.log("screen resolution does not match");
      this.dialogService.showError('Please use 1080p or 720p resolution for the optimal experience.')
      //this.router.navigate(['/browser-warning']);
    }
  }

  // Function to extract query parameters from URL
  getQueryParam(url: string, arg1: string) {
  const queryParams = new URLSearchParams(new URL(url).search);
  return queryParams.get(arg1);
  }

  // updateProgress() {
  //   this.loadedAssets++;
  //   this.loadingProgress = (this.loadedAssets / this.totalAssets) * 100;
  // }

  preloadAssets(): Promise<void> {
    const assetsToPreload = [
      'assets/PPDS/whiteboardscreen/blue/share_blue.svg',
      'assets/PPDS/whiteboardscreen/grey/carbon_share.svg',
      'assets/PPDS/whiteboardscreen/grey/basil_video-outline.svg',
      'assets/PPDS/whiteboardscreen/qrCode.svg',
      'assets/PPDS/whiteboardscreen/grey/fluent_folder-32-regular.svg',
      'assets/PPDS/whiteboardscreen/grey/draw.svg',
      'assets/PPDS/whiteboardscreen/blue/draw_blue.svg',
      'assets/PPDS/whiteboardscreen/grey/eraser.svg',
      'assets/PPDS/whiteboardscreen/grey/grommet-icons_select.svg',
      'assets/PPDS/whiteboardscreen/grey/teenyicons_text-solid.svg',
      'assets/PPDS/whiteboardscreen/grey/fluent_toolbox-20-regular.svg',
      'assets/PPDS/whiteboardscreen/grey/basil_add-outline.svg',
      'assets/PPDS/whiteboardscreen/grey/iconamoon_do-redo-thin.svg',
      'assets/PPDS/whiteboardscreen/grey/redo.svg',
      'assets/PPDS/whiteboardscreen/grey/fluent_color-background-20-regular.svg',
      'assets/PPDS/whiteboardscreen/grey/iconoir_multiple-pages-add.svg',
      'assets/PPDS/whiteboardscreen/grey/iconamoon_arrow-up-2-light.svg',
      'assets/PPDS/whiteboardscreen/grey/system-uicons_pages.svg',
      'assets/PPDS/whiteboardscreen/grey/arrow_right.svg',
      'assets/PPDS/whiteboardscreen/grey/fluent_arrow-exit-20-regular.svg',
      'assets/tools/nonHighlightedTools/calculator.svg',
      'assets/tools/nonHighlightedTools/voting.svg',
      'assets/tools/nonHighlightedTools/compass.svg',
      'assets/tools/nonHighlightedTools/protractor.svg',
      'assets/tools/nonHighlightedTools/setsquare.svg',
      'assets/tools/nonHighlightedTools/scale.svg',
      'assets/tools/nonHighlightedTools/flashlight.svg'
    ];

    // this.totalAssets = assetsToPreload.length + 1; // Including fonts as one additional asset

    // const promises = assetsToPreload.map((url) => this.preloadImage(url));

    // return Promise.all(promises).then(() => {
    //   // Optionally, preload fonts, scripts, etc.
    //   return this.preloadFonts();
    //   // return;
    // }).then(() => {
    //   // All assets are preloaded
    // }).catch(() => {
    //   // Handle any errors gracefully
    // });
    const imagePromises = assetsToPreload.map((url) => this.preloadImage(url));
    const fontPromise = this.preloadFonts();
    // const lessonPromise = this.preloadLessonData();

    return Promise.all([...imagePromises, fontPromise]).then(() => {
      console.log('All assets preloaded successfully');
    }).catch(error => {
      console.error('Failed to preload assets:', error);
      throw error; // Propagate the error to handle it in ngOnInit
    });
  }

  preloadImage(url: string): Promise<void> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve();
      img.onerror = () => resolve();
    });
  }

  // preloadImage(url: string): Promise<void> {
  //   return new Promise((resolve) => {
  //     const img = new Image();
  //     img.src = url;
  //     img.onload = () => {
  //       // this.updateProgress();
  //       resolve();
  //     };
  //     img.onerror = () => {
  //       // this.updateProgress();
  //       resolve(); // Handle errors gracefully
  //     };
  //   });
  // }

  preloadFonts(): Promise<void> {
    const googleFontsUrl = 'https://fonts.googleapis.com/css?family=Bellefair|Fresca|Gloria+Hallelujah|Inconsolata|Indie+Flower|Josefin+Sans|Lato|Open+Sans|Open+Sans+Condensed:300|Pacifico|Raleway|Roboto|Zilla+Slab';

    return fetch(googleFontsUrl)
      .then(response => response.text())
      .then(cssText => {
        const styleElement = document.createElement('style');
        styleElement.textContent = cssText;
        document.head.appendChild(styleElement);

        const fontFamilyPromises = [
          'Bellefair', 'Fresca', 'Gloria Hallelujah', 'Inconsolata', 'Indie Flower', 'Josefin Sans', 'Lato', 'Open Sans', 'Open Sans Condensed', 'Pacifico', 'Raleway', 'Roboto', 'Zilla Slab'
        ].map(fontFamily => (document as any).fonts.load(`1em ${fontFamily}`));

        return Promise.all(fontFamilyPromises).then(() => {});
      }).catch(error => {
        console.error('Failed to load fonts:', error);
        throw error;
      });
  }

  extractFontUrls(cssText: string): string[] {
    const fontUrls: string[] = [];
    const regex = /url\((https:\/\/fonts\.gstatic\.com\/[^\)]+)\)/g;
    let match;

    while ((match = regex.exec(cssText)) !== null) {
      fontUrls.push(match[1]);
    }

    return fontUrls;
  }

  loadFont(url: string): Promise<void> {
    return new Promise((resolve) => {
      var FontFace: any;
       var document: any;
      const font = new FontFace('CustomFont', `url(${url})`);
      font.load().then(() => {
        (document.fonts as any).add(font);
        resolve();
      }).catch(() => {
        console.error(`Failed to load font from ${url}`);
        resolve();
      });
    });
  }

  //remove data
  clearLocalStorage() {
    // Remove the items from local storage
    localStorage.removeItem('prevCanvasObjLen')
    localStorage.removeItem("currentPage");
    localStorage.removeItem("savedCanvasData");
    localStorage.removeItem("totalPages");
    localStorage.removeItem('editedObj')
    // Add more items to remove as needed
    location.reload();
  }
  useropen:Boolean=false
  user() {
    this.closeallsubmenu();
     console.log("calling user");
     this.recievebackdropcloseoption(false);
    document.getElementById("mySidenav").style.width = "345px";
    this.useropen=true
  }

  closeprofile() {
    document.getElementById("mySidenav").style.width = "0";
    this.useropen=false
  }


  classAV() {
    this.recievebackdropcloseoption(false);
    this.mycanvas.unselectActiveObject();
    console.log("calling user");
      document.getElementById("avhcatSidenav").style.width = "25vw";
      this.mycanvas.EraseCanvas.select = false
      this.openSelectTool = true;
      this.opendrawtool=false;
      this.openselectiontool=false;
      this.opentoolsicon=false;
      this.openinsert=false;
      this.opentexttool = false;
      this.drawClicked = false;
      this.selectClicked = false;
      this.typeClicked = false;
      this.toolsClicked = false;
      this.insertClicked = false;
      this.undoClicked = false;
      this.redoClicked = false;
      this.shareClicked = false;
      this.isshareopen = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.openerasetool=false;
      this.selectederaser=null;
      this.mycanvas.exitEditText();
      this.removeAllGeometryTool();

  }

  closeAV() {
    document.getElementById("avhcatSidenav").style.width = "0";

    if(this.selectedIcon === 'icon1'){
      this.selectedIcon = null;
    }
  }

  filemanage() {
    console.log("calling file manager");
    this.recievebackdropcloseoption(false);
    this.openSelectTool = true;
    this.mycanvas.unselectActiveObject();
    document.getElementById("leftsidenav").style.width = "1050px";
    this.fileservice.refreshFiles.next("yes");
  }

  closefilemanage() {
  //  this.mycanvas.EditCanvas(false)
    document.getElementById("leftsidenav").style.width = "0";
   this.EnableMenu = true
    // this.mycanvas.EditCanvas(false)
    this.fileClicked = false;
    // this.selectedIcon = null;
    // if (this.fileClicked  === false) {
      // this.openSelectTool = true;
    // } else {
    //   this.openSelectTool = false;
    // }
    if(this.selectedIcon === 'icon3'){
      this.selectedIcon = null;
    }
    if(this.openSelectTool===true){
    this.mycanvas.closepend();
    }

  }

  removeAllGeometryTool() {
    this.mycanvas.compassSelected = false
    this.rulerClicked = false;
      this.mycanvas.removeRuler();
      this.compassClicked = false;
      this.compassColor = false;
      this.mycanvas.removeCompass();
      this.protractorClicked = false;
      this.mycanvas.removeProtractor();
      this.setsquareClicked = false;
      this.mycanvas.removeSetsquare();
  }


  drawtool(){
    this.mycanvas.eraseCanvasChange()
    this.recievebackdropcloseoption(false);
    this.mycanvas.unselectActiveObject();
    if(this.drawtoolactive===true){
      this.drawClicked=true;
      this.selectedIcon="icon6";
      this.opendrawtool=true;
      this.drawtoolactive = false;
      this.disabledThickness=false;
      this.openSelectTool = false;
      if(this.highlighterClicked){
        this.disabledThickness=true;
      }else{
        this.disabledThickness=false;
      }
    }
    else if(this.EnableMenu && this.drawtoolactive == false){
      this.mycanvas.toggledelfalse();
      this.openSelectTool = false;
      this.opendrawtool=!this.opendrawtool;
      this.drawtoolactive=false;
      this.drawClicked = !this.drawClicked;
      this.openerasetool=false;
      this.openselectiontool=false;
      this.opentoolsicon=false;
      this.openinsert=false;
      this.opentexttool = false;
      this.eraseClicked = false;
      this.selectClicked = false;
      this.typeClicked = false;
      this.toolsClicked = false;
      this.insertClicked = false;
      this.undoClicked = false;
      this.redoClicked = false;
      this.shareClicked = false;
      this.isshareopen = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.addcolorClicked = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.mycanvas.closepend();
      this.pencilClicked=true;
      this.pencilClicked=true;
      this.removeAllGeometryTool();

      setTimeout(() => {
       this.pendraw();
      }, 100);
      this.highlighterClicked=false;
      this.highlightSelectedColor=null;
      if(this.highlighterClicked){
        this.disabledThickness=true;
      }else{
        this.disabledThickness=false;
      }
      this.mycanvas.exitEditText();
    }
  }

  pendraw() {
    this.intialpentrue=false;
  //  event.stopPropagation();
    console.log("pendraw calling");
    const dd = document.getElementById("pen") as HTMLElement;
    dd.style.borderColor = "#2a72db";
    const cc = document.getElementById("high") as HTMLElement;
    cc.style.borderColor = "";
    this.mycanvas.selectPenCil();
    this.pencilClicked = true;
    this.highlighterClicked = false;
    this.disabledThickness=false;
    this.thick=this.pencilthick;
    this.addcolorClicked=false;
    this.showColorPicker=false;
   // this.thicknesschanged(this.thick)
  }

  public thicknesschanged(data) {
    this.pencilthick=data;
    console.log("changing thickness");
    this.mycanvas.thick(data);
  }

  stopthick(event: Event) {
    event.stopPropagation();
  }

  public hightlighter(event: Event) {
    event.stopPropagation();
    console.log("highlighter calling");
    const dd = document.getElementById("high") as HTMLElement;
    dd.style.borderColor = "#2a72db";
    const cc = document.getElementById("pen") as HTMLElement;
    cc.style.borderColor = "";
    this.mycanvas.selecthighlighter();
    this.highlighterClicked = true;
    this.pencilClicked = false;
    this.disabledThickness=true;
    this.thick="20"
    this.showColorPicker=false;
    this.addcolorClicked=false;
  }

  erasetool(){
    this.recievebackdropcloseoption(false);
    this.mycanvas.unselectActiveObject();
    if(this.EnableMenu){
      this.openerasetool=!this.openerasetool;
      if (this.openerasetool  === true) {
        this.openSelectTool = false;
      } else {
        this.openSelectTool = true;
      }
      this.eraseClicked = !this.eraseClicked;
      this.openSelectTool = false;
      this.opendrawtool=false;
      this.openselectiontool=false;
      this.opentoolsicon=false;
      this.openinsert=false;
      this.opentexttool = false;
      this.drawClicked = false;
      this.selectClicked = false;
      this.typeClicked = false;
      this.toolsClicked = false;
      this.insertClicked = false;
      this.undoClicked = false;
      this.redoClicked = false;
      this.shareClicked = false;
      this.isshareopen = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.mycanvas.exitEditText();
      this.removeAllGeometryTool();
    }
  }

  selecttool(){
    this.mycanvas.eraseCanvasChange()
    this.recievebackdropcloseoption(false);
    if(this.EnableMenu){
      this.mycanvas.toggledelfalse();
      this.openselectiontool=!this.openselectiontool;
      this.selectClicked = !this.selectClicked;
      this.openSelectTool = true;
      this.opendrawtool=false;
      this.openerasetool=false;
      this.opentoolsicon=false;
      this.openinsert=false;
      this.opentexttool = false;
      this.eraseClicked = false;
      this.drawClicked = false;
      this.typeClicked = false;
      this.toolsClicked = false;
      this.insertClicked = false;
      this.undoClicked = false;
      this.redoClicked = false;
      this.shareClicked = false;
      this.isshareopen = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.mycanvas.exitEditText();
      this.mycanvas.selectobject();
      this.removeAllGeometryTool();
    }

  }

  openerasemodal() {
    this.save_exit_ref = this.modalService.show(
      this.modalContent,
      Object.assign({}, { class: "setHeight" })
    );
    this.modalSubscription = this.save_exit_ref.onHidden.subscribe(() => {
      console.log('+++++++++++++++++++++++++++++++')
      if (this.save_exit_ref) {
        console.log('===========================')
        this.selectederaser = 'icon1';
      }
    });
   }

   ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    this.service_deletePageSubscription.unsubscribe();
  }

  public clearcanvas() {
    this.audioshow=false;
    this.videoshow=false;
    this.webpageshow=false;
    this.current3DObjFloatingWindows=[];
    this.currentDocFloatingWindows=[];
    this.currentYTFloatingWindows=[];
    this.modalService.hide();
    this.selectedBgColorIndex = 0;
    this.bgColorCurrentState = 0;
    this.bgFrameCurrentState = 'frame1';
    this.bgImgCurrentState = '';
    this.mycanvas.clearCanvas()
    this.selectederaser=null;
    this.openerasetool=false;
    this.compassClicked = false;
    this.compassColor = false;
    this.protractorClicked = false;
    this.setsquareClicked = false;
    this.rulerClicked = false;
    if(this.selectedIcon==="icon7"){
     this.selectedIcon=null;
    }
  }

  erasemodalclose() {
      this.selectederaser=null;
      this.modalService.hide();
      if(this.selectedIcon==="icon7"){
        this.selectedIcon=this.selectedIcon;
       }
      this.openerasetool=false;
      console.log(this.openerasetool,"in eraseModalClose")
      this.checkEraserStatus();
  }

  public selectobject() {
    this.mycanvas.eraseCanvasChange()
    this.intialpentrue=false;
    this.mycanvas.selectobject();
  }

  selectedIcon: string | null = null;

  selectIcon(iconName: string) {
    console.log(iconName,"iconName")
    console.log(this.drawtoolactive,"ppppp")
    if (this.selectedIcon === iconName&&this.drawtoolactive===false) {
      this.selectedIcon = null;
    } else {
        this.selectedIcon = iconName;

    }
    if(this.selectedIcon!=="icon6"){
      this.drawtoolactive=false
      this.intialpentrue=false;
      this.mycanvas.closepend();
    }
    if(iconName==="icon6"&&this.drawtoolactive===true){
      this.selectedIcon=iconName;
      this.intialpentrue=false;
     // this.drawtoolactive=false;
    }
    if(iconName==="icon7"){
      if(this.avChatStarted()){
        this.selectederaser="icon2";
        this.selectndelete();
      }
      else{
        if(!this.selectederaser){
          this.selectederaser="icon1";
         this.pointErase();
          }
      }
    }
    if(iconName==="icon7"&&this.selectederaser==='icon1'){
      this.mycanvas.activepencilmode();
    }

    if(iconName!=="icon8"){
      console.log("pppp")
      this.selectedtapvalue=null;
    }
   if(iconName==="icon8"){
    if(!this.selectedtapvalue){
      this.selectedIcon=iconName;
      this.selectedsel="icon1";
      this.selectedtapvalue="icon1";
    this.selectobject();
    }
}

if( this.useropen){
  this.closeprofile();
  this.useropen=false
}




  }

  avChatStarted(){
    if((localStorage.getItem('avchatstarted') && localStorage.getItem('avchatstarted') == 'yes')){
    return true
    }
    else{
      return false
    }
   }


  selectederaser: string | null = null;
  selecterasericon(iconName: string) {

    this.pencilClicked = false;
    if (this.selectederaser === iconName&&(iconName !=='icon1')) {
      this.selectederaser = null;
    } else {
      this.selectederaser = iconName;
      this.selectedsel = null
    }

    if(this.selectederaser===null){
      this.selectedIcon=null;
    }
    this.openerasetool=false;
    this.checkEraserStatus()
  }

  selectndelete() {
   this.mycanvas.toggleDeleteMode();
    this.openerasetool=false;
    this.eraseClicked=false;
    if (this.selectederaser  === 'icon2') {
      this.openSelectTool = false;
    } else {
      this.openSelectTool = true;
      this.mycanvas.EraseCanvas.select=false;
    }
}
  selectAreaEraser(){
    // this.mycanvas.selectAreaEraser()
  }

  pointErase() {
    console.log(this.selectederaser,"point eraser")
    if(this.selectederaser==="icon1"){
    this.mycanvas.togglePointEraserMode();
    this.openerasetool=false;
    this.eraseClicked=false;
    }else{
      this.mycanvas.eraseCanvasChange()
      this.mycanvas.closepend();
      this.openerasetool=false
    }
    this.checkEraserStatus()
  }

  // opencolorpicker() {
  //   const colorPicker = document.getElementById(
  //     "colorPicker"
  //   ) as HTMLInputElement;
  //   colorPicker.click();
  //   this.addcolorClicked = true;
  // }

  showColorPicker = false;

  opencolorpicker() {
   // this.showColorPicker = !this.showColorPicker;
    this.addcolorClicked = !this.addcolorClicked;
    this.showColorPicker=this.addcolorClicked;

    console.log(this.showColorPicker, this.addcolorClicked)

    const colorPicker = document.getElementById(
      "colorPicker"
    ) as HTMLInputElement;
    colorPicker.click();
  }

  // selectedColors: string[] = [
  //   "#e41b1b",
  //   "#3f1ded",
  //   "#18e239",
  //   "#e6f764",
  //   "#dc18d6",
  //   "#2bdbf3",
  //   "#0d600b",
  // ];


  selectedPenColors: string[] = [
    "#e41b1b",
    "#3f1ded",
    "#18e239",
    "#e6f764",
    "#dc18d6",
    "#2bdbf3",
    "#000000",
  ];

  highlightSelectedPenColor:string="#000000"
  selectedhiglightColors: string[] = [
    "#e41b1b",
    "#3f1ded",
    "#18e239",
    "#e6f764",
    "#dc18d6",
    "#2bdbf3",
    "#000000",
  ];
  highlightSelectedHighlightColor:string="#e6f764"
  customColor: string = "#000000";

  addColorTimer: any;



  addColor() {
    clearTimeout(this.addColorTimer);
    this.addColorTimer = setTimeout(() => {
     this.applyCustomColor(this.customColor);

    }, 500);
  }

  applyhiglightcustomcolor:Boolean=false;
applypencustomcolor:Boolean=false;

  applyCustomColor(color){
    if(this.pencilClicked){
     this.highlightSelectedPenColor=color
     this.applypencustomcolor=true
    }else{
      this.highlightSelectedHighlightColor=color
      this.applyhiglightcustomcolor=true
    }
    this.mycanvas.colorchanged(color);
  }


  public applycolor(data) {
    console.log("ccccolorssssss", data);
    this.mycanvas.colorchanged(data);
  }

  selectedsel: string | null = null;
  selectselect(iconName: string) {
    this.searchByname="";
    if (this.selectedsel === iconName) {
      this.selectedsel = null;
    } else {
      this.selectedsel = iconName;
      this.selectederaser = null
    }
  }


  highlightSelectedColor:any;
  selectedColorIndex: number = -1;
  // applyColorAndHighlight(index: number,color:any) {
  //   this.highlightSelectedColor=color;
  //   this.selectedColorIndex = index;
  //   const selectedColor = this.selectedColors[index];
  //   this.mycanvas.colorchanged(selectedColor);
  //   this.addcolorClicked=false;
  //   // Use the selectedColor as needed
  // }


  selectedColorPenIndex: number = -1;
  selectedColorHighlightIndex: number = -1;
  applyColorAndHighlight(index: number,color:any) {

    // this.highlightSelectedColor=color;
    // this.selectedColorIndex = index;
    // const selectedColor = this.selectedColors[index];
    // this.mycanvas.colorchanged(selectedColor);
    let selectedColor="";
    if(this.pencilClicked){
      this.applypencustomcolor=false;
      this.highlightSelectedPenColor=color;
      this.selectedColorPenIndex=index;
       selectedColor=this.selectedPenColors[index]
    }else{
      this.applyhiglightcustomcolor=false;
      this.highlightSelectedHighlightColor=color;
      this.selectedColorHighlightIndex=index;
       selectedColor=this.selectedhiglightColors[index];
    }
      this.mycanvas.colorchanged(selectedColor);
    this.addcolorClicked=false;
    this.showColorPicker=false;
    // Use the selectedColor as needed
  }

  logout() {
    if(localStorage.getItem('avchatstarted') && localStorage.getItem('avchatstarted') == 'yes'){
      console.log("ending av chat class")
      this.fileservice.quitavclass();
    }
    this.removeAllGeometryTool();
    this.recievebackdropcloseoption(false);
    const logoutdata = {
      token: this._Activatedroute.snapshot.queryParams.token, // Use the appropriate session ID or token
      sessionId: this._Activatedroute.snapshot.queryParams.sessionId,
      // Add any other data you want to send to the WebSocket server
    };
    this.fileservice.socket.emit("logout-event", logoutdata);
    this.fileservice.getTVInformation({}, "/user/logout").then(
      (res: any) => {
        try {
          if (res && res.status) {
            localStorage.clear();
            this.router.navigate(["/qrlogin"]);
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  toggleTextTool() {
    this.mycanvas.eraseCanvasChange()
    this.mycanvas.unselectActiveObject();
    if (this.EnableMenu) {
      this.mycanvas.toggledelfalse();
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.opentoolsicon = false;
      this.openinsert = false;
      this.eraseClicked = false;
      this.selectClicked = false;
      this.drawClicked = false;
      this.toolsClicked = false;
      this.insertClicked = false;
      this.undoClicked = false;
      this.redoClicked = false;
      this.shareClicked = false;
      this.isshareopen = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.selectederaser=null;
     // this.mycanvas.exitEditText();
      this.opentexttool = !this.opentexttool;
      this.typeClicked = !this.typeClicked;
      if (this.opentexttool  === true) {
        this.openSelectTool = false;
      } else {
        this.openSelectTool = false;
      }

      if(this.opentexttool){
        this.selectTextInput();
      }
      if(this.opentexttool===false || this.selectedIcon !== 'icon9'){

        this.mycanvas.exitEditText();
        this.openSelectTool = true;
      }
      this.removeAllGeometryTool();
    }
  }

  insertoption() {
    this.mycanvas.eraseCanvasChange()
    this.recievebackdropcloseoption(false);
    this.mycanvas.unselectActiveObject();
    this.openinsert = !this.openinsert;
    if (this.openinsert  === true) {
      this.openSelectTool = true;
    } else {
      this.openSelectTool = true;
    }
    this.selectedsel=null;
    this.insertClicked = !this.insertClicked;
    // if (this.openinsert === false) {
      this.medlist = false;
      this.imagelist = false;
      this.audlist = false;
      this.shapeslist = false;
      this.showdoucmentlist = false;
      this.webSites = [];
      this.allyoutubevideos = [];
      this.searchmediatext = null;
      this.searchText = null;
      this.objectlist=false;
    // }

    this.opentoolsicon = false;
    this.opendrawtool = false;
    this.openerasetool = false;
    this.openselectiontool = false;
    this.opentexttool = false;
    this.eraseClicked = false;
    this.selectClicked = false;
    this.typeClicked = false;
    this.toolsClicked = false;
    this.drawClicked = false;
    this.undoClicked = false;
    this.redoClicked = false;
    this.shareClicked = false;
    this.isshareopen = false;
    this.backdropClicked = false;
    this.openBackdropBox = false;
    this.allpages = false;
    this.openpages = false;
    this.fileClicked = false;
    this.selectederaser=null;
    this.mycanvas.exitEditText();
    this.removeAllGeometryTool();
    console.log(this.openinsert, "openinsert");
  }
  insertheight:"";
  calculateinsertheight() {
    // Check if the element is present
    if (this.insertDiv) {
      const divElement = this.insertDiv.nativeElement;
      this.insertheight = divElement.offsetHeight;
      console.log(`Height: ${this.insertheight}px`);
    } else {
      console.error('insertDiv is not defined');
    }
  }

  //----shapes part------
  line(event: Event) {
    console.log("line called");
    this.mycanvas.insertLine();
  }

  arrow(event: Event) {
    console.log("arrow called");
    this.mycanvas.insertArrow();
  }

  arc(event: Event) {
    console.log("arc called");
    this.mycanvas.insertArc();
  }

  polygon(event: Event) {
    console.log("polygon called");
    this.mycanvas.insertPolygon();
  }

  rectangle(event: Event) {
    console.log("rectangle called");
    this.mycanvas.insertRectangle();
  }

  rhombus(event: Event) {
    console.log("rhombus called");
    this.mycanvas.insertRhombus();
  }

  cube(event: Event) {
    console.log("cube called");
    this.mycanvas.insertCube();
  }

  star(event: Event) {
    console.log("star called");
    this.mycanvas.insertStar();
  }

  circle(event: Event) {
    console.log("circle called");
    this.mycanvas.insertCircle();
  }

  hemisphere(event: Event) {
    console.log("hemisphere called");
    this.mycanvas.insertHemisphere("whiteboard");
  }

  sphere(event: Event) {
    console.log("sphere called");
    this.mycanvas.insertSphere("whiteboard");
  }

  cylinder(event: Event) {
    console.log("cylinder called");
    this.mycanvas.insertCylinder("whiteboard");
  }

  triangle(event: Event) {
    console.log("triangle called");
    this.mycanvas.insertTriangle();
  }

  rightTriangle(event: Event) {
    console.log("right triangle called");
    this.mycanvas.insertRightTriangle();
  }

  pyramid(event: Event) {
    console.log("pyramid called");
    this.mycanvas.insertPyramid();
  }

  prism(event: Event) {
    console.log("prism called");
    this.mycanvas.insertPrism();
  }
  //-----shapes_part_end-----

  opentoolsicon:boolean=false;
  toolsicon(){
    this.mycanvas.eraseCanvasChange()
    this.recievebackdropcloseoption(false);
    this.mycanvas.unselectActiveObject();
    if(this.EnableMenu){
      this.opentoolsicon=!this.opentoolsicon;
      this.toolsClicked = !this.toolsClicked;
      if (this.opentoolsicon === true) {
        this.openSelectTool = true;
      } else {
        this.openSelectTool = true;
      }
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.openinsert = false;
      this.opentexttool = false;
      this.eraseClicked = false;
      this.selectClicked = false;
      this.typeClicked = false;
      this.drawClicked = false;
      this.insertClicked = false;
      this.undoClicked = false;
      this.redoClicked = false;
      this.shareClicked = false;
      this.isshareopen = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.highlightTools=true;
      this.opendrawtool=false;
      this.drawtoolactive=false;

      this.mycanvas.exitEditText();
    }
  }

  compass(event: Event) {
    console.log("compass tool calling");
    // this.compassClicked = true;
    // this.compassColor = true;
    // this.mycanvas.selectCompass();
    if (this.compassClicked == false) {
      this.compassClicked = true;
      this.compassColor = true;
       //---removing geometric tools(start)---
       this.rulerClicked = false;
       this.mycanvas.removeRuler();
       this.protractorClicked = false;
       this.mycanvas.removeProtractor();
       this.setsquareClicked = false;
       this.mycanvas.removeSetsquare();
       //---removing geometric tools(end)---
      this.mycanvas.selectCompass();
    }
    else {
      console.log('deleting compass tool')
      this.compassClicked = false;
      this.compassColor = false;
      this.mycanvas.removeCompass();
      console.log('whiteboardpage log of removeCompass()', this.mycanvas.removeCompass())
    }
  }
  handleDeleteCompass() {
    this.compassClicked = false;
    this.compassColor = false;
  }

  ruler(event: Event) {
    console.log("Ruler tool calling");
    if (this.rulerClicked == false) {
      this.rulerClicked = true;
        //---removing geometric tools(start)---
        this.compassClicked = false;
        this.compassColor = false;
        this.mycanvas.removeCompass();
        this.protractorClicked = false;
        this.mycanvas.removeProtractor();
        this.setsquareClicked = false;
        this.mycanvas.removeSetsquare();
        //---removing geometric tools(end)---
      this.mycanvas.selectRuler();
    }
    else {
      console.log("delete ruler")
      this.rulerClicked = false;
      this.mycanvas.removeRuler();
    }
  }
  handleDeleteRuler() {
    this.rulerClicked = false;
  }

  protractor(event: Event) {
    console.log("protractor tool calling");
    // this.protractorClicked = true;
    // this.mycanvas.selectProtractor();
    if (this.protractorClicked == false) {
      this.protractorClicked = true;
      //---removing geometric tools(start)---
      this.rulerClicked = false;
      this.mycanvas.removeRuler();
      this.compassClicked = false;
      this.compassColor = false;
      this.mycanvas.removeCompass();
      this.setsquareClicked = false;
      this.mycanvas.removeSetsquare();
      //---removing geometric tools(end)---
      this.mycanvas.selectProtractor();
    }
    else {
      console.log("delete ruler")
      this.protractorClicked = false;
      this.mycanvas.removeProtractor();
    }
  }
  handleDeleteProtractor() {
    this.protractorClicked = false;
  }

  setsquare(event: Event) {
    // console.log("setsquare tool calling");
    // this.mycanvas.selectSetsquare();
    if (this.setsquareClicked == false) {
      this.setsquareClicked = true;
      //---removing geometric tools(start)---
      this.rulerClicked = false;
      this.mycanvas.removeRuler();
      this.compassClicked = false;
      this.compassColor = false;
      this.mycanvas.removeCompass();
      this.protractorClicked = false;
      this.mycanvas.removeProtractor();
      //---removing geometric tools(end)---
      this.mycanvas.selectSetsquare();
    }
    else {
      console.log("delete ruler")
      this.setsquareClicked = false;
      this.mycanvas.removeSetsquare();
  }
  }
  handleDeleteSetsquare() {
    this.setsquareClicked = false;
  }

  public autocache() {
  }

  saveData() {
    // setInterval(() => {
    //   this.autocache();
    //   console.log("Save cache")
    //   }, 2000);
  }

  async updateObjectLength() {
    const savedCanvasDataV = localStorage.getItem("savedCanvasData");
    const canvasDataArray = JSON.parse(savedCanvasDataV);
    // this.totalObjects = canvasDataArray.length
    // console.log("Bug1",this.totalObjects)
  }

  async updatePage(value) {
    this.currentPage = Number(localStorage.getItem("currentPage")) + value;
    localStorage.setItem("currentPage", String(this.currentPage));
  }

  frameMapping = {
    'assets/backdrop/lightFrames/bggrid1.svg': 'frame2',
    'assets/backdrop/lightFrames/bggrid2.svg': 'frame3',
    'assets/backdrop/lightFrames/bggrid3.svg': 'frame4',
    'assets/backdrop/lightFrames/bggrid4.svg': 'frame5',
    'assets/backdrop/lightFrames/bggrid5.svg': 'frame6',
    'assets/backdrop/lightFrames/bggrid6.svg': 'frame7',
    'assets/backdrop/darkFrames/bggrid1_dark.svg': 'frame2',
    'assets/backdrop/darkFrames/bggrid2_dark.svg': 'frame3',
    'assets/backdrop/darkFrames/bggrid3_dark.svg': 'frame4',
    'assets/backdrop/darkFrames/bggrid4_dark.svg': 'frame5',
    'assets/backdrop/darkFrames/bggrid5_dark.svg': 'frame6',
    'assets/backdrop/darkFrames/bggrid6_dark.svg': 'frame7'
   };

   public get isNextDisabled(): boolean {
    return this.currentPage === this.totalPages;
  }
  loadPage(pageNo) {
    const fileId = localStorage.getItem("FileID")
    this.fileservice.updateActivePage(fileId, pageNo).subscribe({
      next: (response) => {
    console.log('Active page updated:', response);
      },
      error: (error) => {
      console.error('Error updating active page:', error);
    }
    });
    this.pageChangeButtonClicked = true;
    this.onUndoStatusChanged(this.undoButtonEnabled);
    this.onRedoStatusChanged(this.redoButtonEnabled);
    this.removeAllGeometryTool();
    this.openSelectTool = false;
    this.recievebackdropcloseoption(false);
this.handledrawtoolforpage=true
    this.showOverlay();
    this.pageChangeSubject.next(pageNo)
 }

   pageChangeSubject = new Subject();
   pageChangeButtonClicked: boolean = false;
   public async nextLoad() {
    this.pageChangeButtonClicked = true;
    this.onUndoStatusChanged(this.undoButtonEnabled);
    this.onRedoStatusChanged(this.redoButtonEnabled);
        this.removeAllGeometryTool();
      this.openSelectTool = false;
      this.recievebackdropcloseoption(false);
      if (this.currentPage == this.totalPages) {
        this.dialogService.showError("No page available!!");
      } else {
        //To-do. All steps to be done sync.
        //1) Show transparent overlay. This thing blocks all UI on the screen.
        //2) Take current-page canvas data and update lessonfile in localStorage and db.
        //3) Fetch next page data from localStorage and first update the page No UI.
        //4) Then clear all canvas data and load next page canvas json on to the canvas.
        this.showOverlay();
        let currentPageNo = JSON.parse(localStorage.getItem("currentPage"))
        let next = currentPageNo + 1;
        this.pageChangeSubject.next(next);
        const fileId = localStorage.getItem("FileID")
        this.fileservice.updateActivePage(fileId, next).subscribe({
          next: (response) => {
            console.log('Active page updated:', response);
          },
          error: (error) => {
            console.error('Error updating active page:', error);
          }
        });
      }
     }
     overlay: Boolean = false;
     showOverlay() {
      this.overlay = true;
     }
     hideOverlay() {
      this.overlay = false;
     }
     updatePageNumber(pageNo:number) {
      this.currentPage = pageNo;
     }

     public get isPreviousDisabled(): boolean {
      return this.currentPage === 1; // Disable if the current page is the first one
    }

    public async previewLoad() {
      this.pageChangeButtonClicked = true;
      this.onUndoStatusChanged(this.undoButtonEnabled);
      this.onRedoStatusChanged(this.redoButtonEnabled);
      if(!this.mycanvas.isPageNavDisabled()) {
        this.removeAllGeometryTool();
      this.openSelectTool = false;
      this.recievebackdropcloseoption(false);
      if (this.currentPage == 1) {
        this.dialogService.showError("No page available!!");
      } else {
        this.showOverlay();
        let currentPageNo = JSON.parse(localStorage.getItem("currentPage"))
        let prev = currentPageNo - 1;
        this.pageChangeSubject.next(prev);
        const fileId = localStorage.getItem("FileID")
        this.fileservice.updateActivePage(fileId, prev).subscribe({
          next: (response) => {
            console.log('Active page updated:', response);
          },
          error: (error) => {
            console.error('Error updating active page:', error);
          }
        });
      }
      }
     }

     addPageSubject = new Subject();
     async addPage() {
      //To-Do.
      //1) Immediately show overlay.
      //2) update lesson file in db and cache with current page contents.
      //3) call add-page api. If successful then update lesson file in cache also.
      //4) clear the canvas and update UI.
      //5) hide overlay.
      this.showOverlay();
      this.addPageSubject.next();
     }

     deletePageSubject = new Subject();
     deletePage(pageNumberToBeDeleted: any) {
      console.log("03-11-2024",pageNumberToBeDeleted)
      this.showOverlay();
      this.deletePageSubject.next(pageNumberToBeDeleted);
      }


  lessonChanged: boolean = false;
  handleChildEvent(data) {
      this.showOverlay();
      const lessondata = data;
      console.log("sammmmm", lessondata);
      localStorage.setItem("mainlessonID", lessondata._id);
      this.loadLessonSubject.next(lessondata);
      this.activatedrawtool();
  }

  pagebox() {
    this.mycanvas.eraseCanvasChange()
    this.removeAllGeometryTool();
    this.closefilemanage();
    this.recievebackdropcloseoption(false);
    this.mycanvas.unselectActiveObject();
    this.selectederaser=null;
     this.openpages = !this.openpages;
    this.pageopenclose = true;
    //this.allpages = true;
    this.openBackdropBox = false;
    //this.handledrawtoolforpage=false;
    // this.autocache();
    console.log("pagebox called")
  }

  //thumb
  public pagethumb() {
    //this.mycanvas.GenThumbAuto();
    this.autocache();
  }

  // Remove an object from the local storage array by index
  removeObjectByIndex(index: number): void {
    const itemsJson = localStorage.getItem("savedCanvasData");
    const thumbnailJson = localStorage.getItem("thumbnail");

    if (itemsJson) {
      const items: any[] = JSON.parse(itemsJson);

      if (index >= 0 && index < items.length) {
        items.splice(index, 1); // Remove the object at the specified index
        localStorage.setItem("savedCanvasData", JSON.stringify(items)); // Update the local storage
      }
    }

  //thumbnail
  if (thumbnailJson) {
    const items: any[] = JSON.parse(thumbnailJson);
    if (index >= 0 && index < items.length) {
      items.splice(index, 1); // Remove the object at the specified index
      localStorage.setItem('thumbnail', JSON.stringify(items)); // Update the local storage
    }
  }

    this.previewLoad();
  }

  // pagereload(){
  //   location.reload();
  // }
  isshareopen: boolean = false;
  shareoption() {
    this.mycanvas.saveCanvasDataToMongoDB();
    this.removeAllGeometryTool();
    this.recievebackdropcloseoption(false);
    this.mycanvas.unselectActiveObject();
    this.isshareopen = !this.isshareopen;
    if (this.isshareopen  === true) {
      this.openSelectTool = true;
    } else {
      this.openSelectTool = true;
    }
    this.shareClicked = !this.shareClicked;
    this.eraseClicked = false;
    this.selectClicked = false;
    this.typeClicked = false;
    this.toolsClicked = false;
    this.insertClicked = false;
    this.undoClicked = false;
    this.redoClicked = false;
    this.drawClicked = false;
    this.opendrawtool = false;
    this.openerasetool = false;
    this.openselectiontool = false;
    this.openinsert = false;
    this.opentexttool = false;
    this.opentoolsicon = false;
    this.backdropClicked = false;
    this.openBackdropBox = false;
    this.allpages = false;
    this.openpages = false;
    this.fileClicked = false;
    this.selectederaser=null;
    this.mycanvas.exitEditText();
  }
  selectingpages: boolean = false;
  mainlessonID: string;
  imagesArray: any = [];
  demoarr: any = [];
  sharespinneractive:Boolean=false;
  shareviaQR() {
    this.demoarr = []
    this.sharespinneractive=true;
    this.qrClicked = true;
    this.copyClicked = false;
    this.emailClicked = false;
    this.googleClicked = false;
    this.pdfimgmodal_exit_ref = this.modalService.show(
      this.pdfimgmodal_exit,
      Object.assign({}, { class: "setHeight" })
    );
    this.selectingpages = true;
    this.mainlessonID = localStorage.getItem("mainlessonID");
    console.log("sameeerrr", this.mainlessonID);
    // this.fileservice.thumbnailgeneration(this.mainlessonID,this.sh,this.sw).subscribe(
    //   (response: any) => {
    //     console.log("Res--->", response);
    //     this.demoarr = response.data;
    //     console.log("demoarray", this.demoarr);
    //     this.demoarr=this.demoarr.sort((a, b) => a.page_number - b.page_number);
    //     this.sharespinneractive=false;
    //     this.imagesArray = response.data.map((item) => item.image);
    //     console.log("sameeeeeeeeeeeeeeeeeeeeeeee", this.imagesArray);
    //   },
    //   (error: any) => {
    //     console.error(error);
    //   }
    // );

    //new implementaition by asmita
    const numElements = Number(localStorage.getItem('totalPages'));
    this.demoarr = this.fileservice.pageThumbnail
      .slice(0, numElements)
      .map((thumbnail, index) => ({
        image: thumbnail,
        page_number: 1 + index,
        selection: false,
      }))
      .sort((a, b) => a.page_number - b.page_number);

    console.log("Updated demoarr:", this.demoarr);
    this.sharespinneractive = false;
    this.imagesArray = this.fileservice.pageThumbnail
    console.log("sameeeeeeeeeeeeeeeeeeeeeeee", this.imagesArray);
  }

  selectAll: boolean = false;
  selectAllImages(event) {
    this.selectAll = event.target.checked;
    this.demoarr.forEach((data) => (data.selection = this.selectAll));
    this.enableShareButton();
  }

  pageidarray: any[] = [];
  handleImageSelection(data) {
    console.log("full data", data , this.demoarr);
    const index = this.pageidarray.indexOf(data.page_id);
    if (index !== -1) {
      this.pageidarray.splice(index, 1);
    } else {
      this.pageidarray.push(data.page_id);
    }
    console.log("idddddddddddddd", this.pageidarray);
    this.enableShareButton();
  }

  shareType: string = 'pdf';
  isShareButtonEnabled: boolean = false;
  enableShareButton() {
    const isAnyImageSelected = this.demoarr.some(data => data.selection);
    const isAnyPageSelected = isAnyImageSelected && this.pageidarray.length > 0;
    console.log("anyimage",isAnyImageSelected)
    console.log("anypage",isAnyPageSelected)
    const isAnyRadioButtonSelected = !!this.shareType;
    this.isShareButtonEnabled = (isAnyImageSelected || isAnyPageSelected) && isAnyRadioButtonSelected;
  }

  pdflink: string;
  sharebutton() {
    this.fileservice
      .pdfgenerationNew(this.mainlessonID, this.pageidarray , this.demoarr)
      .subscribe(
        (response) => {
          // Handle the response if needed
          console.log("pdfffff", response);
          this.pdflink = response.data;
          console.log("pdflink", this.pdflink);
          this.generateQRCodeForImagePath(this.pdflink);
        },
        (error) => {
          // Handle errors if any
          console.error(error);
        }
      );
  }
  cancel() {
    this.pdfimgmodal_exit_ref.hide();
  }

  async generateQRCodeForImagePath(imagePath: string) {
    this.selectingpages = false;
    const qrData = imagePath; // Image path data to encode in the QR code
    console.log("pdflink", qrData);

    try {
      this.qrCodeData = await QRCode.toDataURL(qrData);
      console.log("mainwrlink", this.qrCodeData);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  }
  emailsharespinneractive:Boolean=false
  shareviaEmail() {
    this.emailsharespinneractive=true
    this.emailClicked = !this.emailClicked;
    this.qrClicked = false;
    this.copyClicked= false;
    this.googleClicked=false;
    this.selectemail =false;
    this.noemail=false;

    this.pdfimgmodalemail_exit_ref = this.modalService.show(
      this.pdfimgmodalemail_exit,
      Object.assign({}, { class: "setHeight" })
    );
    this.selectingpages = true;
    this.mainlessonID = localStorage.getItem("mainlessonID");
    // console.log("sameeerrr", this.mainlessonID);
    // this.fileservice.thumbnailgeneration(this.mainlessonID,this.sh,this.sw).subscribe(
    //   (response: any) => {
    //     console.log("Res--->", response);
    //     this.demoarr = response.data;
    //     console.log("demoarray", this.demoarr);
    //     this.demoarr=this.demoarr.sort((a, b) => a.page_number - b.page_number);
    //     this.emailsharespinneractive=false;
    //     this.imagesArray = response.data.map((item) => item.image);
    //     console.log("sameeeeeeeeeeeeeeeeeeeeeeee", this.imagesArray);
    //   },
    //   (error: any) => {
    //     console.error(error);
    //   }
    // );

    //new implementaition by asmita
    const numElements = Number(localStorage.getItem('totalPages'));
    this.demoarr = this.fileservice.pageThumbnail
      .slice(0, numElements)
      .map((thumbnail, index) => ({
        image: thumbnail,
        page_number: 1 + index,
        selection: false,
      }))
      .sort((a, b) => a.page_number - b.page_number);

    console.log("Updated demoarr:", this.demoarr);
    this.emailsharespinneractive=false;
    this.imagesArray = this.fileservice.pageThumbnail
    console.log("sameeeeeeeeeeeeeeeeeeeeeeee", this.imagesArray);
  }

  selectemail:boolean=false;
  noemail: boolean = false;
  emailistarr:any[] = [];
  ee:any;
  emailList: { email: string, selectedem: boolean }[] = [];
  selectAllmm: boolean = false;
  selectedEmails: string[] = [];
  isShareemailbutton:boolean=false;
  public emailOngoing = false;
  userID:any;
  emailsent:boolean=false;
  emailselection(){
    // this.selectemail=true;
    this.userID = localStorage.getItem('userId');
    this.fileservice.getEmaillist(this.userID).subscribe(
      (response:any) => {
        console.log('Email fetch successfully', response);
        if (response.data) {
          this.selectemail=true;
          if (response.data.emaillist.length === 0) {
            this.noemail = true;
          }
          this.ee=response.data.emaillist
          this.emailList = this.ee.map(email => ({ email: email, selectedem: false }));
        } else {
          console.log('response.data is null or undefined');
          this.noemail = true;
        }
      },
      (error) => {
        console.error('Error fetching email:', error);
        this.noemail = true;
      }
    );
  }

  handleEmailSelection(email: { email: string, selectedem: boolean }) {
    this.isShareemailbutton=true
    email.selectedem ? this.selectedEmails.push(email.email) : this.selectedEmails = this.selectedEmails.filter(e => e !== email.email);

  }
  selectAllEmails() {
    this.emailList.forEach(email => email.selectedem = this.selectAllmm);
    if (this.selectAllmm) {
      this.isShareemailbutton=true;
      this.selectedEmails = this.emailList.map(email => email.email);
    } else {
      this.isShareemailbutton=false;
      this.selectedEmails = [];
    }
  }
  sharebuttonemail() {
  this.emailOngoing = true;
  this.fileservice.pdfgenerationNew(this.mainlessonID, this.pageidarray , this.demoarr).subscribe(
    (response) => {
      this.pdflink = response.data;
      const link = this.pdflink;
      console.log('link consoleeee', link)
      console.log('inside sendmail of whiteboard component',this.userID)
      if (!navigator.onLine) {
        this.dialogService.showError('No internet connection.');
        this.emailOngoing = false;
        return;
    }
    setTimeout(() => {
      this.emailOngoing = false;
      // alert('Email sent successfully');
    }, 3000);
    this.fileservice.sendpdfEmail(this.selectedEmails, link).subscribe(
      (response) => {
        this.emailOngoing = false;
        this.selectemail=false;
        //this.selectingpages = false;
        //this.pdfimgmodalemail_exit_ref.hide();
          console.log('Email sent successfully', response);
         // alert('Email sent successfully')
         this.emailsuccesfully()

        },
        (error) => {
          console.error('Error sending email:', error);
          this.emailOngoing = false;
          // alert('sending email failed')
          if (error.status === 400 && error.error.error === 'No emails found') {
              this.dialogService.showError('No emails found!!');
          } else {
            this.dialogService.showError('Failed to send email.');
          }
        }
    );
    },
    (error) => {
      // Handle errors if any
      console.error(error);
    }
  );
  }
  emailsuccesfully(){
    this.emailList=[]
    this.emailsent=true;
    setTimeout(() => {
      this.pdfimgmodalemail_exit_ref.hide();
    }, 1000);
    setTimeout(() => {
      this.emailsent=false;
      this.selectemail=false;
      this.selectingpages=false;
      this.isShareButtonEnabled=false;
    }, 1000);

  }
  //linkCopied:boolean=false;
  shareviaCopylink() {
    this.dialogService.showSimplePopup("Generating pdf, please wait...");
    //this.linkCopied = true;
    this.copyClicked= !this.copyClicked;
    this.emailClicked = false;
    this.qrClicked = false;
    this.googleClicked=false;
    this.mainlessonID = localStorage.getItem("mainlessonID");
    console.log("less",this.mainlessonID)
    //new implementaition by asmita
    const numElements = Number(localStorage.getItem('totalPages'));
    this.demoarr = this.fileservice.pageThumbnail
      .slice(0, numElements)
      .map((thumbnail, index) => ({
        image: thumbnail,
        page_number: 1 + index,
        selection: true,
      }))
      .sort((a, b) => a.page_number - b.page_number);

    console.log("Updated demoarr:", this.demoarr);
    this.fileservice
    .pdfgenerationNew(this.mainlessonID, this.pageidarray, this.demoarr )
    .subscribe(
    (response) => {
    console.log("pdfffff", response);
    this.pdflink = response.data;
    console.log("pdflink", this.pdflink);
    if (window.navigator.userAgent.includes('Windows')) {
      navigator.clipboard.writeText(this.pdflink)
      this.dialogService.hidePopup();
      alert(this.pdflink);
      //console.error("Unable to copy text");
    }
    else{
    //socekt emit copy event
      this.copyevent()
    }
    },
    (error) => {
    console.error(error);
    }
    );


    // setTimeout(() => {
    // this.linkCopied = false;
    // }, 2000);
    }


    copyevent() {
      const message = "link copied:\n"+ this.pdflink;
      //alert(message);
      this.copyClicked=false;
      //socekt emit copy event
      // const url = "https://inblrlxapp003.tpvaoc.com/fabricang?sessid=jLbLXY";
      // const currentUrl = window.location.href;
      // console.log(currentUrl, "currenturl");
      // const sessid = this.getQueryParam(currentUrl, 'sessid');
      //var sessid = localStorage.getItem("CopySessionId")
      console.log("inside copyevent function",this.androidsessid);
      if (this.androidsessid) {
      console.log("session id is present from android")
      console.log(this.androidsessid);
      const copyData = {
      sessionId: this.androidsessid,
      link: this.pdflink
      // Add any other data you want to send to the WebSocket server
      };
      this.fileservice.socket.emit('copy-event', copyData);
      this.dialogService.hidePopup();
      } else {
      console.log("No sessionid from URL")
      }
    }


  //changes start - shreyas.s
  shareviaGoogleclass() {
    this.googleClicked = true;
    const initialState = {};

    this.sharetogoogleRef = this.modalService.show(SharetogoogleComponent, {
      class: "modal-md",
      backdrop: true,
      ignoreBackdropClick: false,
      initialState: {
        lessonID: localStorage.getItem("mainlessonID"),
        pageidarray: this.pageidarray,
      },
    });
    this.sharetogoogleRef.content.closeBtnName = "Close";
  }

  onUndoStatusChanged(status: boolean) {
    console.log('onUndoStatusChanged debugg', !status, status);
    this.undoButtonEnabled = !status;
  }

  //changes end - shreyas.s
  undoSubject = new Subject();
  undo() {
    this.undoSubject.next();
  }

  toggleUndoTool() {
    this.closefilemanage();
    this.mycanvas.compassSelected = false
    this.rulerClicked = false;
      // this.mycanvas.removeRuler();
      this.compassClicked = false;
      this.compassColor = false;
      // this.mycanvas.removeCompass();
      this.protractorClicked = false;
      // this.mycanvas.removeProtractor();
      this.setsquareClicked = false;
      // this.mycanvas.removeSetsquare();
    if (this.EnableMenu) {
      this.mycanvas.toggledelfalse();
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.opentoolsicon = false;
      this.openinsert = false;
      this.opentexttool = false;
      this.eraseClicked = false;
      this.selectClicked = false;
      this.typeClicked = false;
      this.toolsClicked = false;
      this.insertClicked = false;
      this.drawClicked = false;
      this.redoClicked = false;
      this.isshareopen = false;
      this.shareClicked = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.compassClicked = false;
      this.compassColor = false;
      this.rulerClicked = false;
      this.protractorClicked = false;
      this.setsquareClicked = false;
      this.openUndoTool = !this.openUndoTool;
      if (this.openUndoTool  === true) {
        this.openSelectTool = true;
      } else {
        this.openSelectTool = false;
      }
      this.undoClicked = !this.undoClicked;
      this.mycanvas.exitEditText();

      setTimeout(() => {
        this.openUndoTool = false;
        this.undoClicked = false;
        this.selectedIcon = null
      }, 600);
    }
  }

  onRedoStatusChanged(status: boolean) {
    this.redoButtonEnabled = !status;
  }

  redoSubject = new Subject();
  redo() {
    this.redoSubject.next();
  }

  toggleRedoTool() {
    this.closefilemanage();
    this.mycanvas.compassSelected = false
    this.rulerClicked = false;
      // this.mycanvas.removeRuler();
      this.compassClicked = false;
      this.compassColor = false;
      // this.mycanvas.removeCompass();
      this.protractorClicked = false;
      // this.mycanvas.removeProtractor();
      this.setsquareClicked = false;
      // this.mycanvas.removeSetsquare();
    if (this.EnableMenu) {
      this.mycanvas.toggledelfalse();
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.opentoolsicon = false;
      this.openinsert = false;
      this.openUndoTool = false;
      this.opentexttool = false;
      this.eraseClicked = false;
      this.selectClicked = false;
      this.typeClicked = false;
      this.toolsClicked = false;
      this.insertClicked = false;
      this.undoClicked = false;
      this.drawClicked = false;
      this.isshareopen = false;
      this.shareClicked = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.compassClicked = false;
      this.compassColor = false;
      this.rulerClicked = false;
      this.protractorClicked = false;
      this.setsquareClicked = false;
      this.openRedoTool = !this.openRedoTool;
      if (this.openRedoTool  === true) {
        this.openSelectTool = true;
      } else {
        this.openSelectTool = false;
      }
      this.redoClicked = !this.redoClicked;
      this.mycanvas.exitEditText();

      setTimeout(() => {
        this.selectedIcon = null
        this.openRedoTool = false;
        this.redoClicked = false;
      }, 600);
    }
  }

  toggleAddPageTool() {
    this.removeAllGeometryTool();
     this.closefilemanage();
    if (this.EnableMenu) {
      this.mycanvas.toggledelfalse();
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.opentoolsicon = false;
      this.openinsert = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.openAddPageTool = !this.openAddPageTool;
      this.addpageClicked = !this.addpageClicked;
      this.opentexttool = false;
      this.shareClicked=false;
      this.isshareopen=false;
      this.mycanvas.exitEditText();
      this.closefilemanage();

      setTimeout(() => {
        this.openAddPageTool = false;
        this.addpageClicked = false;
        this.selectedIcon = null
        this.defaultpenactive();
      }, 600);
    }
  }

  togglePreviousTool() {
    this.closefilemanage();
    if (this.EnableMenu) {
      this.mycanvas.toggledelfalse();
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.opentoolsicon = false;
      this.openinsert = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.compassClicked = false;
      this.compassColor = false;
      this.rulerClicked = false;
      this.protractorClicked = false;
      this.setsquareClicked = false;
      this.openPreviousTool = !this.openPreviousTool;
      this.previousClicked = !this.previousClicked;
      this.opentexttool = false
      this.mycanvas.exitEditText();
      this.shareClicked=false;
      this.isshareopen=false;


      setTimeout(() => {
        this.openPreviousTool = false;
        this.previousClicked = false;
        this.selectedIcon = null;
        this.defaultpenactive();
      }, 600);
    }
  }

  toggleNextTool() {
    this.closefilemanage();
    if (this.EnableMenu) {
      this.mycanvas.toggledelfalse();
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.opentoolsicon = false;
      this.openinsert = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.opentexttool = false;
      this.compassClicked = false;
      this.compassColor = false;
      this.rulerClicked = false;
      this.protractorClicked = false;
      this.setsquareClicked = false;
      this.openNextTool = !this.openNextTool;
      this.shareClicked=false;
      this.isshareopen=false;
      this.nextClicked = !this.nextClicked;
      this.mycanvas.exitEditText();

      setTimeout(() => {
        this.openNextTool = false;
        this.nextClicked = false;
        this.selectedIcon = null;
        this.defaultpenactive();
      }, 600);
    }
  }

  toggleLogoutTool() {
    if (this.EnableMenu) {
      this.mycanvas.toggledelfalse();
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.opentoolsicon = false;
      this.openinsert = false;
      this.backdropClicked = false;
      this.openBackdropBox = false;
      this.allpages = false;
      this.openpages = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.openLogoutTool = !this.openLogoutTool;
      this.logoutClicked = !this.logoutClicked;

    }
  }

  imagelist: boolean = false;
  shapeslist: boolean = false;
  medlist: boolean = false;
  audlist: boolean = false;
  imagesList: string[] = []; // Array to hold the image URLs
  totalimagesarr: any[] = [];
  videoList: string[] = []; // Array to hold the image URLs
  totalvideosarr: any[] = [];
  audioList: string[] = []; // Array to hold the image URLs
  totalaudioarr: string[] = [];
  openshapelist() {
    this.shapeslist = !this.shapeslist;
    this.medlist = false;
    this.audlist = false;
    this.imagelist = false;
    this.showdoucmentlist = false;
    this.objectlist=false;
    // this.openinsert=false
    this.calculateinsertheight()
  }
  openimglist() {
    this.imagelist = !this.imagelist;
    this.medlist = false;
    this.audlist = false;
    this.shapeslist = false;
    this.showdoucmentlist = false;
    this.objectlist=false;
    this.selectedTabforImage = null;
    this.Searchimagetext = "";
    this.googleimages = [];
    this.selectedTabforImage="My library"
    // this.openinsert=false

    // this.fileservice.getImages().subscribe((response: any) => {
    //   console.log("Response:", response);

    //   if (response && Array.isArray(response.data)) {
    //     response.data.forEach((item: any) => {
    //       if (item.mimeType === "image/jpeg" && item.destinationpath) {
    //         if (!this.imagesList.includes(item.destinationpath)) {
    //           let titletrunc=this.extractFileName(item.destinationpath);
    //           titletrunc=this.truncateSentence(titletrunc,20)
    //           let data={destinationpath:item.destinationpath,titletrunc: titletrunc}
    //           this.imagesList.push(item.destinationpath);
    //           this.totalimagesarr.push(data);
    //           console.log("Image List:", this.imagesList);
    //         }
    //       }
    //     });
    //   }
    // });

    this.handleuploadssearch('image',false,false)
    this.calculateinsertheight()

  }

  openmedlist() {
    this.medlist = !this.medlist;
    this.selectedTabmedia="My library"
    if (this.medlist === false) {
      this.searchmediatext = null;
    }
    this.imagelist = false;
    this.audlist = false;
    this.shapeslist = false;
    this.showdoucmentlist = false;
    this.objectlist=false;
    // this.openinsert=false

    // this.fileservice.getImages().subscribe((response: any) => {
    //   console.log("Response:", response);

    //   if (response && Array.isArray(response.data)) {
    //     response.data.forEach((item: any) => {
    //       if (
    //         item.mimeType === "video/mp4" ||
    //         item.mimeType === "video/webm" ||
    //         item.mimeType === "video/ogg" ||
    //         item.mimeType === "video/quicktime" ||
    //         item.mimeType === "video/x-msvideo" ||
    //         item.mimeType === "video/x-flv" ||
    //         item.mimeType === "video/x-matroska" ||
    //         item.mimeType === "video/x-ms-wmv" ||
    //         item.mimeType === "video/3gpp" ||
    //         (item.mimeType === "video/x-m4v" && item.destinationpath)
    //       ) {
    //         if (!this.videoList.includes(item.destinationpath)) {
    //           this.videoList.push(item.destinationpath);
    //           let title=this.extractFileName(item.destinationpath)
    //           console.log(title);
    //           title=this.truncateSentence(title,20)
    //           let data={destinationpath:item.destinationpath,titletrunc:title}
    //           this.totalvideosarr.push(data);
    //           console.log("Video List:", this.videoList);
    //         }
    //       }
    //     });
    //   }
    // });
    this.handleuploadssearch('video',false,false)
    this.calculateinsertheight()
  }
  totaldocarr:any[]=[];
  documentList:any[]=[]
  opendocumentlist() {
    this.selectedTab="My library"
    this.showdoucmentlist = !this.showdoucmentlist;
    if (this.showdoucmentlist === false) {
      this.searchText = null;
      this.selectedTab = null;
      this.webSites = [];
    }
    this.imagelist = false;
    this.medlist = false;
    this.shapeslist = false;
    this.audlist = false;
    this.objectlist=false;
    // this.openinsert=false
    console.log(this.openinsert);
    // this.fileservice.getImages().subscribe((response: any) => {
    //   console.log("Response:", response);

    //   if (response && Array.isArray(response.data)) {
    //     response.data.forEach((item: any) => {
    //       if (
    //         item.mimeType === "text/plain" ||
    //         item.mimeType === "application/pdf" ||
    //         item.mimeType ===
    //           "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
    //           item.mimeType ===
    //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"||
    //         item.mimeType ===
    //           "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    //         (item.mimeType === "application/vnd.ms-excel" &&
    //           item.destinationpath)
    //       ) {
    //         if (!this.documentList.includes(item.destinationpath)) {
    //           this.documentList.push(item.destinationpath);
    //           this.totaldocarr.push(item);
    //           console.log("DOCUMENT List:", this.documentList);
    //         }
    //       }
    //     });
    //   }
    // });
    this.handleuploadssearch('document',false,false)
    this.calculateinsertheight()
  }

  openaudiolist() {
    this.selectedaudiotab="My library"
    this.audlist = !this.audlist;
    this.imagelist = false;
    this.medlist = false;
    this.shapeslist = false;
    this.showdoucmentlist = false;
    this.objectlist=false;
    // this.openinsert=false

    // this.fileservice.getImages().subscribe((response: any) => {
    //   console.log("Response:", response);

    //   if (response && Array.isArray(response.data)) {
    //     response.data.forEach((item: any) => {
    //       if (item.mimeType === "audio/mpeg" && item.destinationpath) {
    //         if (!this.audioList.includes(item.destinationpath)) {
    //            console.log(item);
    //             item.titletrunc=this.truncateSentence(item.originalName,10)
    //           this.audioList.push(item.destinationpath);
    //           this.totalaudioarr.push(item);
    //           console.log("AUDIO List:", this.audioList);
    //         }
    //       }
    //     });
    //   }
    // });
    this.handleuploadssearch("audio",false,false)
    this.calculateinsertheight()
  }

  async putoncanvas(data,type) {
    console.log("url", data);
    if(type == 'google') {
      await this.mycanvas.addImageOnCanvas(data, "insert");
    }
    else if((data.imageThumbnailPath) && (data.imageThumbnailPath != '')) {
      await this.mycanvas.addImageOnCanvas(data.imageThumbnailPath, "insert");
    }
    else {
      await this.mycanvas.addImageOnCanvas(data.destinationpath, "insert");
    }
}

  putvidoncanvas(data) {
    console.log("video url", data);
    this.mycanvas.addvideodiv(data);
  }

  isPlaying: boolean = false;
  toggleAudio(data) {
    this.isPlaying = !this.isPlaying;
    console.log("audio", data);
    this.mycanvas.addaudiodiv(data);
  }

  selectTextInput() {
    this.recievebackdropcloseoption(false);
    if(this.EnableMenu){
      this.mycanvas.addEditText()
    }
  }

  selectVotingOption() {
    console.log("hello");
    this.ShowVotesection = true;
    this.votingClicked = true;
    this.votingColor = true;
    console.log(this.ShowVotesection);
    this.highlightTools=true;
    this.opentoolsicon=false;
    this.mycanvas.closepend();
  }

  recievecloseoption(value: boolean) {
    this.ShowVotesection = value;
    this.votingClicked = false;
    this.votingColor = false;
    if(this.selectedIcon === 'icon10'){
      this.selectedIcon=null;
    }
    if(this.opentoolsicon===true){
      this.opentoolsicon=false;
    }

    this.mycanvas.closepend();
  }


  addvotinggraphinwhiteboards(chartDataURL, totalvotes) {
    this.mycanvas.addImageVotingOnCanvas(chartDataURL, totalvotes);
  }

  //changes start - shreyas.s

  addGoogleCourseMaterialHandler(data: any) {
    this.addGoogleCourseMaterialToCanvas(data);
  }
  addGoogleCourseMaterialToCanvas(material) {
    console.warn({ material });
    this.mycanvas.uploadGoogleCourseMaterial(material);
  }

  addFloatingWindowHandler(data: any) {
    console.warn(data);
    switch (data.type) {
      case "youtube":
        this.addYTDraggableWindow(data);
        break;
      case "mp4":
        this.addmp4DraggableWindow(data);
        break;
      case "pdf/doc":
        this.addDocDraggableWindow(data);
        break;
      case "pptx":
        this.addDocDraggableWindow(data);
        case "3D":
          this.add3DToViewerEventHandler(data.data);
      default:
        break;
    }
  }

  addDocDraggableWindow(data) {
    this.currentDocFloatingWindows.push({
      id: Math.floor(Math.random() * 2000),
      fileId: data.fileId,
      totalPages: data.data.pageCount,
      type: data.type,
      src:data.src,
      fileName: data.fileName?data.fileName: ""
    });
  }
  addYTDraggableWindow(data) {
    console.log("pushed");
  this.currentYTFloatingWindows=[];
    this.currentYTFloatingWindows.push({
      id: Math.floor(Math.random() * 2000),
      ytSrc: data.data,
    });
  }
  addmp4DraggableWindow(data) {
    if (this.currentmp4FloatingWindows.length > 3) return;
    console.warn("mp4");
    this.currentmp4FloatingWindows.push({
      id: Math.floor(Math.random() * 2000),
      src: data.url,
      origURL: data.origURL,
    });
  }

  handleClosemp4Container(currWindow) {
    this.currentmp4FloatingWindows = this.currentmp4FloatingWindows.filter(
      (window) => {
        if (window.id === currWindow.id) {
          URL.revokeObjectURL(window.src);
          URL.revokeObjectURL(window.origURL);
          return false;
        }
        return true;
      }
    );
  }
  handleCloseYTContainer(currWindow) {
    this.currentYTFloatingWindows = this.currentYTFloatingWindows.filter(
      (window) => window.id != currWindow.id
    );
  }
  removeDocWindowEventHandler(window) {
    console.log("05-11-2024",window);
    this.currentDocFloatingWindows = this.currentDocFloatingWindows.filter(
      (win) => win.id != window.id
    );
    console.log(this.currentDocFloatingWindows);
  }
  handleWindowSnipEventHandler(imageURL) {
    this.mycanvas.addImageOnCanvas(imageURL,"insert");
  }
  documentWindowLoadingEventHandler(toggle: boolean) {
    console.log("toggling");
    this.isDocumentViewerLoading = toggle;
  }
  // addUploadDocumentToCanvasEventHandler(fileObj) {
  //   this.mycanvas.addUploadDocumentToCanvas(fileObj);
  // }


  addUploadDocumentToCanvasEventHandler(fileObj) {
     let index= -1
    if(this.currentDocFloatingWindows.length>0){
    index=this.currentDocFloatingWindows.findIndex(item=>item.docdbid===fileObj._id);
     }
    if(index>-1){
      return
    }else{
      this.currentDocFloatingWindows=[];
    this.mycanvas.addUploadDocumentToCanvas(fileObj);

    }
  }
  selectedTab: any;
  selectTab(value:any) {
     this.searchText=null;
     this.searchByname="";


     if(value==="Google class"&&this.isGoogleclasslogin===true){
      this.selectedTab=value;
      }else if(value==="My library"||value==="Public"||value==="Webpage"){
     this.selectedTab=value;

      }else{
        false
      }

      if(value==="My library"||value==="Public"){


        //this.handleuploadssearch('document',false)
      }

   //this.selectedTab = value;
   this.webSites=[];
   }


  webSites: any;
  searchText: any;

  findvalue() {
    if (this.selectedTab === "Webpage") {
      this.searchWebpage(this.searchText);
    }
  }

  searchWebpage(value: any) {
    this.fileservice
      .getTVInformation({ search: value }, "/googleSearch")
      .then((res: any) => {
        try {
          // this.isSelectedWeb=false;
          // this.isSearchResult = true
          this.webSites = res.body;
          console.log(res.body);
          // if (!this.serachInformation.includes(this.searchInfo)) {
          //   this.serachInformation.push(this.searchInfo)
          // }
          console.log(",,,,,,,,,,,,,,,,,,,,,,", res);
        } catch (e) {
          console.error(e);
        }
      });
  }

  selectedTabmedia: any;
    selecttabmedia(value:any) {
     if(value==="Google class"&&this.isGoogleclasslogin===true){
      this.selectedTabmedia=value;
      }else if(value==="My library"||value==="Public"||value==="Youtube"){
     this.selectedTabmedia=value;
      }else{
        return
      }

      // if(value==="My library"||value==="Public"){
      //   this.handleuploadssearch("video",false)
      // }
      this.searchByname=""
     this.searchmediatext=null;
     }
  searchmediatext: any;

  findmediadata() {
    console.log("hi");
    if ((this.selectedTabmedia = "Youtube")) {
      if(!this.searchmediatext){
        return
      }else{
      console.log(this.selectedTabmedia);
      this.handleSearch(this.searchmediatext);
      }
    }
  }

  allyoutubevideos: any[] = [];
  handleSearch(inputValue: any) {
    console.log(inputValue, "youtube component");

    this.fileservice.getYoutubeVideos(inputValue).subscribe((items: any) => {
      console.log("rrrrrrrrrrrrrrrrrrr");
      let alldata = items.map((item) => {
        return {
          title: item.snippet.title,
          videoId: item.id.videoId,
          videoUrl: `https://www.youtube.com/watch?v=${item.id.videoId}`,
          iframVideoInsertion: `https://www.youtube.com/embed/${item.id.videoId}`,
          channelId: item.snippet.channelId,
          channelUrl: `https://www.youtube.com/channel/${item.snippet.channelId}`,
          channelTitle: item.snippet.channelTitle,
          description: item.snippet.description,
          publishedAt: new Date(item.snippet.publishedAt),
          thumbnail: item.snippet.thumbnails.high.url,
          titletrunc:this.truncateSentence(item.snippet.title,10)
        };
      });
      console.log(items);
      console.log(alldata);
      this.allyoutubevideos = alldata;
    });
  }

  putyoutubevideooncanvas(value: any) {
    this.mycanvas.addvideodiv(value);
  }

  openFlashlight() {
    this.flashlightClicked = !this.flashlightClicked;
    if(this.flashlightClicked){
      this.mycanvas.openFlashlight()
    }
    else{
      this.mycanvas.closeFlashlight()
    }
  }

  showCalculator: boolean = false;
  openCalculator() {
    this.showCalculator = !this.showCalculator;
    this.calculatorClicked = !this.calculatorClicked;
    this.calculatorColor = !this.calculatorColor;
    this.highlightTools=this.showCalculator?true:false;
    this.opentoolsicon=false;
    console.log(this.opentoolsicon,"in open calculator")

    // this.calculatorClicked = true;
  }

  CloseCalculator(value: boolean) {
    this.showCalculator = value;
    this.calculatorClicked = false;
    this.calculatorColor = false;
    this.highlightTools=false;
    if(this.opentoolsicon===true){
      this.opentoolsicon=false;
    }
    if(this.selectedIcon === 'icon10'){
      this.selectedIcon=null;
    }
    this.checkToolstatus()
  }

  Searchimagetext: any;
  SearchImages() {
    console.log("hello");
    if (this.selectedTabforImage === "Google image") {
      this.googleimages = [];
      this.getImagesFromGoogle();
    }
  }

  googleimages: any = [];
  // getImagesFromGoogle() {
  //   console.log("hello2");
  //   this.fileservice
  //     .getImagesfromgoogle(this.Searchimagetext, 10,startIndex)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       console.log(res.items);
  //       console.log(res.url);

  //       res.items.map((p) => {
  //         console.log(p);
  //       });

  //       // const data = res.items;
  //       // this.googleimages=res.items

  //       const extracteddata = res.items;
  //       extracteddata.forEach((element) => {
  //         console.log(element);
  //         console.log(element.title);
  //         let title = this.truncateSentence(element.title, 6);
  //         // this.imagesList.push({ thumbnailLink: element.image.thumbnailLink, width: element.image.width });
  //         let data = {
  //           thumbnailLink: element.image.thumbnailLink,
  //           title: title,
  //           imagelink: element.link
  //         };
  //         this.googleimages.push(data);

  //         //  this.imagesList = <items>[...tempArray]; //Updating the State Immutably
  //       });
  //       console.log(this.googleimages);
  //       console.log("OOOOOOOOOOOOOOOOOOO", extracteddata);

  //     });
  // }



  getImagesFromGoogle(count = 0,startIndex=1) {
    console.log("hello2");
    this.fileservice
      .getImagesfromgoogle(this.Searchimagetext,10,startIndex)
      .subscribe((res: any) => {
        console.log(res);
        console.log(res.items);
        console.log(res.url);

        res.items.map((p) => {
          console.log(p);
        });

        const extracteddata = res.items;
        extracteddata.forEach((element) => {
          console.log(element);
          console.log(element.title);
          let title = this.truncateSentence(element.title, 6);
          let data = {
            thumbnailLink: element.image.thumbnailLink,
            title: title,
            imagelink: element.link
          };
          let index=-1;
          index=this.googleimages.findIndex(item=>item.thumbnailLink===data.thumbnailLink)
          if(index===-1){
          this.googleimages.push(data);
          }
        });
        console.log(this.googleimages);
        console.log("OOOOOOOOOOOOOOOOOOO", extracteddata);

        // Check if we need to call the function again
        if (count < 2) {
          // Increment the count and call the function again
          this.getImagesFromGoogle(count + 1,startIndex=11);
        }
      });
  }
  selectedTabforImage: String;
  selectTabforImage(value:string) {
     if(value==="Google class"&&this.isGoogleclasslogin===true){
      this.selectedTabforImage=value;
      }else if(value==="Public"||value==="My library"||value==="Google image"){
      console.log("hhhii")
     this.selectedTabforImage=value;
     this.searchByname="";
     this.Searchimagetext=""
      }else{
        return
      }

      // if(value==="Public"||value==="My library"){
      //   this.handleuploadssearch('image',false)
      // }

     }

  truncateSentence(sentence: any, maxLength: any) {
    if (sentence.length > maxLength) {
      return sentence.slice(0, maxLength) + "...";
    } else {
      return sentence;
    }
  }

  //changes end - shreyas.s
  //Backdrop part
  bgImgCurrentState: string = '';
  bgFrameCurrentState: string = 'frame1';
  bgColorCurrentState: number = 0;
  selectedBgColors: string[] = ["white", "black", "#144835", "orange"];
  selectedBgColorIndex: number = 0;
  applyBgColor(index: number, fromUndoRedoService = false) {
    this.fileservice.updatePageChangeStatus(this.currentPage,true);//true means current-page changed.
    if(!fromUndoRedoService) {
      this.mycanvas.clearRemovedOperationsList();
      this.mycanvas.sendBgStatesToUndoRedoService({
        oldState:{bgColor:this.bgColorCurrentState,bgFrame:this.bgFrameCurrentState,bgImg:this.bgImgCurrentState},
        newState:{bgColor:index,bgFrame:this.bgFrameCurrentState,bgImg:''}
      });
    }
    this.bgImgCurrentState = '';
    this.selectedBgColorIndex = index;
    this.bgColorCurrentState = index;
    const selectedBgColor = this.selectedBgColors[index];
    if (false) {
      return
    } else {
      this.mycanvas.bgcolorchanged(selectedBgColor,'sendSocketEvent');
      this.toggleFrame(this.currentFrameImage,fromUndoRedoService,true);
    }
  }

  openBackdrop() {
    this.removeAllGeometryTool();
    this.mycanvas.eraseCanvasChange();
    this.mycanvas.unselectActiveObject();
    this.openBackdropBox = !this.openBackdropBox;
    if (this.openBackdropBox  === true) {
      this.openSelectTool = true;
      this.loadbgAndColor();
    } else {
      this.openSelectTool = true;
    }
    this.backdropClicked = !this.backdropClicked;
    this.opentexttool = false;
    this.allpages = false;
    this.opendrawtool = false;
    this.openerasetool = false;
    this.openselectiontool = false;
    this.opentoolsicon = false;
    this.openinsert = false;
    this.opentexttool = false;
    this.eraseClicked = false;
    this.selectClicked = false;
    this.typeClicked = false;
    this.toolsClicked = false;
    this.insertClicked = false;
    this.drawClicked = false;
    this.redoClicked = false;
    this.undoClicked = false;
    this.openUndoTool = false;
    this.openRedoTool = false;
    this.isshareopen = false;
    this.shareClicked = false;
    this.fileClicked = false;
    this.selectederaser=null;
    this.closefilemanage();

    this.mycanvas.exitEditText();
    console.log("backdrop handled")
  }

  selectedBg: string | null = null;

  // toggleFrame(frame: string) {
  //   this.currentFrameImage = this.currentFrameImage === frame ? frame : 'frame' + frame.charAt(frame.length - 1);

  //   // if (frame !== 'frame1') {
  //   //   this.addbgImageOnCanvas(`assets/backdrop/lightFrames/bggrid${frame.charAt(frame.length - 1)}.svg`);
  //   // }
  //   if (frame === 'frame1') {
  //     this.removebgImageOnCanvas();
  //   }

  //   else if (frame === 'frame2') {
  //     this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid1.svg');
  //   }

  //   else if (frame === 'frame3') {
  //     this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid2.svg');
  //   }

  //   else if (frame === 'frame4') {
  //     this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid3.svg');
  //   }

  //   else if (frame === 'frame5') {
  //     this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid4.svg');
  //   }

  //   else if (frame === 'frame6') {
  //     this.addBGImageOnCanvas('assets/backdrop/lightFrames/footballfield.svg');
  //   }

  //   else if (frame === 'frame7') {
  //     this.addBGImageOnCanvas('assets/backdrop/lightFrames/volleyball.svg');
  //   }
  // }

  currentFrameImage: string = 'frame1';
  toggleFrame(frame: string, fromUndoRedoService = false, fromapplyBgColor = false) {
    this.fileservice.updatePageChangeStatus(this.currentPage,true);//true means current-page changed.
    if(!fromUndoRedoService && !fromapplyBgColor) {
      this.mycanvas.clearRemovedOperationsList();
      this.mycanvas.sendBgStatesToUndoRedoService({
        oldState:{bgColor:this.bgColorCurrentState,bgFrame:this.bgFrameCurrentState,bgImg:this.bgImgCurrentState},
        newState:{bgColor:this.bgColorCurrentState,bgFrame:frame,bgImg:''}
      });
    }
    this.mycanvas.toggleFrame(frame);
    this.currentFrameImage = this.currentFrameImage === frame ? frame : 'frame' + frame.charAt(frame.length - 1);
    this.bgFrameCurrentState = this.currentFrameImage;
    this.bgImgCurrentState = '';

    if (false) {
      return
    } else {
      if (this.selectedBgColorIndex === 0|| this.selectedBgColorIndex === 3) {
        // for light background
        if(frame === 'frame2') {
          this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid1.svg');
        } else if (frame === 'frame3') {
          this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid2.svg');
        } else if (frame === 'frame4') {
          this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid3.svg');
        } else if (frame === 'frame5') {
          this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid4.svg');
        } else if (frame === 'frame6') {
          this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid5.svg');
        } else if (frame === 'frame7') {
          this.addbgImageOnCanvas('assets/backdrop/lightFrames/bggrid6.svg');
        } else if (frame === 'frame1') {
          this.removebgImageOnCanvas();
        }
      } else if (this.selectedBgColorIndex === 1 || this.selectedBgColorIndex === 2) {
        // for dark background
        if (frame === 'frame2') {
          this.addbgImageOnCanvas('assets/backdrop/darkFrames/bggrid1_dark.svg');
        } else if (frame === 'frame3') {
          this.addbgImageOnCanvas('assets/backdrop/darkFrames/bggrid2_dark.svg');
        } else if (frame === 'frame4') {
          this.addbgImageOnCanvas('assets/backdrop/darkFrames/bggrid3_dark.svg');
        } else if (frame === 'frame5') {
          this.addbgImageOnCanvas('assets/backdrop/darkFrames/bggrid4_dark.svg');
        } else if (frame === 'frame6') {
          this.addbgImageOnCanvas('assets/backdrop/darkFrames/bggrid5_dark.svg');
        } else if (frame === 'frame7') {
          this.addbgImageOnCanvas('assets/backdrop/darkFrames/bggrid6_dark.svg');
        } else if (frame === 'frame1') {
          this.removebgImageOnCanvas();
        }
      } else {
        // Other background colors
        if (frame !== 'frame1') {
          this.addbgImageOnCanvas(`assets/backdrop/lightFrames/bggrid${frame.charAt(frame.length - 1)}.svg`);
        } else {
          this.removebgImageOnCanvas();
        }
      }
    }
  }


  addbgImageOnCanvas(imageURL) {
    console.log("adding background image workinggg", imageURL);
    this.mycanvas.addbgImageOnCanvas(imageURL,'sendSocketEvent');
  }

  removebgImageOnCanvas() {
    console.log("Removing the background image");
    this.mycanvas.removebgImage();
  }

  addBGImageOnCanvas(data) {
    console.log('log for BG image in whiteboardpage workinggg')
    this.selectedBgColorIndex = 0;
    // this.currentFrameImage = 'frame1';
    // this.pageChangeButtonClicked = true;
    // this.applyBgColor(0);
    // this.pageChangeButtonClicked = false;
    this.mycanvas.addBGImageOnCanvas(data,'sendSocketEvent');
  }

  sendCustomBgToUndoRedoService(url) {
    this.mycanvas.clearRemovedOperationsList();
    this.fileservice.updatePageChangeStatus(this.currentPage,true);//true means current-page changed.
    this.mycanvas.sendBgStatesToUndoRedoService({
      oldState:{bgColor:this.bgColorCurrentState,bgFrame:this.bgFrameCurrentState,bgImg:this.bgImgCurrentState},
      newState:{bgColor:this.bgColorCurrentState,bgFrame:this.bgFrameCurrentState,bgImg:url}
    });
    this.bgImgCurrentState = url;
  }

  openbackdropdialog() {
    this.ShowBackdropSection = true;
  }

  recievebackdropcloseoption(value:boolean){
    this.ShowBackdropSection = value;
    // this.currentFrameImage = 'false';
  }

  // changeCanvasColor(color) {
  //   console.log('change bgcolor working')
  //   this.mycanvas.changeBgColor(color);
  // }

  //Backdrop part ends

  // add3DObjectToViewerEventHandler(_3dObj) {
  //   // console.log({ _3dObj });
  //   // this.current3DObjFloatingWindows.push({
  //   //   ..._3dObj,
  //   //   id: Math.floor(Math.random() * 2000),
  //   // });
  //   this.add3DToViewerEventHandler(_3dObj)
  // }
  handleClose3DObjectViewer(currWindow) {
    this.send3DToSocket("","close3d")
    console.log("closing 3d",currWindow)
    this.current3DObjFloatingWindows = this.current3DObjFloatingWindows.filter(
      (window) => {
        if (window.id === currWindow.id) {
          return false;
        }
        return true;
      }
    );
  }

  EditCanvas(){
    this.EnableMenu = false
    this.opendrawtool = false
    this.isshareopen = false
    this.openerasetool = false
    this.opentoolsicon = false
    this.openinsert = false
    this.openselectiontool = false
    this.mycanvas.textBoxSelect = false
    this.opentoolsicon = false
    this.opentexttool = false;
     this.eraseClicked = false;
    this.selectClicked = false;
    this.typeClicked = false;
    this.toolsClicked = false;
    this.insertClicked = false;
    this.drawClicked = false;
    this.redoClicked = false;
    this.undoClicked = false;
    this.shareClicked = false;
    this.fileClicked = true;
    this.openBackdropBox=false;
    this.selectederaser=null
    if(this.selectedIcon !== 'icon4'){
      this.mycanvas.EditCanvas(false)
    }
    //this.mycanvas.EditCanvas(false)
    this.removeAllGeometryTool();
  }

  isGoogleclasslogin:Boolean=false
  GoogleclassroomStatus(value:any){
    this.isGoogleclasslogin=value
  }

  selectedaudiotab:any;
  selecttabaudio(value:any){
     console.log(value,"value")
     console.log("audiotab")
     // this.selectedaudiotab=value;
     if(value==="Google class"&&this.isGoogleclasslogin===true){
      this.selectedaudiotab=value;
      }else if(value==="Public"||value==="My library"){
      console.log("hhhii")
     this.selectedaudiotab=value;
  //   this.handleuploadssearch('audio',false)
      }
      this.searchByname="";
     }


  extractFileName(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }
  selectedTabforObject:any
  selectTabforObject(value:any){
   if(value==="Google class"&&this.isGoogleclasslogin===true){
    this.selectedTabforObject=value;
    }else if(value==="Public"||value==="My library"){
    console.log("hhhii")
    this.selectedTabforObject=value
   // this.selectedaudiotab=value;
    }

   }


allobjects3d=[];
showobject(){
 this.objectlist=!this.objectlist
 this.imagelist=false;
 this.medlist=false;
 this.shapeslist=false;
 this.showdoucmentlist=false;
 this.audlist=false;
 this.selectedTabforObject="My library"

//  this.googleClassroomService.get3DObjectURLsfromServer().subscribe((res)=>{
//   if(res.message==="success") {
//    let data=res.list.map(item=>{
//     console.log(item,"item")
//     let titletrunc=this.truncateSentence(item.fileName,10)
//     let json={src:item.src,titletrunc:titletrunc,obj:item}
//     return json
//     })
//    console.log(data);
//    this.allobjects3d=data;
//    console.log(this.allobjects3d,"AAAAAA");
//    }
//   });
// this.handleuploadssearch('3D',false)
this.handleuploadssearch('3D',false,false)
this.calculateinsertheight()
 }

// add3DToViewerEventHandler(_3dObj) {
//  console.log({_3dObj},"3dObj");
//   this.current3DObjFloatingWindows.push({
//   ..._3dObj,
//   id:Math.floor(Math.random()*2000),
//   });
//  }


add3DToViewerEventHandler(_3dObj) {
  this.send3DToSocket(_3dObj,"add3d")
  console.log("ssssssssssssssssssssssssssssssssssssssssssssssssssss")
  console.log({_3dObj},"3dObj");
  let index=-1;
  let obj={..._3dObj};
  if(this.current3DObjFloatingWindows.length>0){
    console.log(this.current3DObjFloatingWindows);
   index=this.current3DObjFloatingWindows.findIndex((item)=>item._id===obj._id);
   console.log(index)
 }
  if(index>-1){
   return
  }else{
   this.current3DObjFloatingWindows=[];
   this.current3DObjFloatingWindows.push({
   ..._3dObj,
   id:Math.floor(Math.random()*2000),
   });
 }
 this.mycanvas.closepend();
  }

 showavchatqrcode:Boolean=false;
avchatlink:any;

recieveavchatroomid(stringurl:any){
 this.showavchatqrcode=true
  this.avchatlink=stringurl
 this.makeqrcode(stringurl);
 }

qrCodeImage:any;
 makeqrcode(stringurl:string) {
 console.log(stringurl,"wtstr")
QRCode.toDataURL(stringurl, (err,qrCodeUrl)=>{
  if(err) {
   console.error('Error generating QR code:',err);
   return;
   }
  console.log("no error",qrCodeUrl,stringurl);

  this.qrCodeImage=qrCodeUrl;
  });

}

closeAVchatQr(){
this.showavchatqrcode=false;
}

async shareavchatlink(){
try{
 //await navigator.clipboard.writeText(this.avchatlink);
 console.log('URL copied successfully!');
 }catch(error) {
 console.error('Error copying URL:',error);

 }
}
weburl:SafeResourceUrl;

webpageurl:SafeResourceUrl
webpageshow:Boolean=false
webpageunsecureurl:any;
addtoCanvas(value:any) {
  this.webpageurl=this.sanitizer.bypassSecurityTrustResourceUrl(value);
  this.webpageunsecureurl=value;
  console.log("webpageurl",this.webpageurl)
 this.webpageshow=true
 this.sendwebpageToSocket("webpage_add")
 //this.mycanvas.addwebpage(value);
 }

handlewebpageview(){
 this.webpageshow=false;
 this.sendwebpageToSocket("webpage_close")
 }

 videoshow:Boolean=false;
 videourl:SafeResourceUrl;

 showmediavideos(url){
  console.log("Local Debugg:",url);
this.audioshow=false;
  //  this.videourl=this.sanitizer.bypassSecurityTrustResourceUrl(url);
  //  console.log(this.videourl,".....this.videourl")
  //  this.videoshow=true;
   const videoElement = document.getElementById('videoElement') as HTMLVideoElement;
      console.log("urlllllll",url,videoElement)
      if (videoElement) {
        console.log("inside if cond audielement")
        videoElement.pause();
        videoElement.src = '';
        videoElement.load();
      }
      this.videourl=url
       setTimeout(() => {
        videoElement.src = url;
        videoElement.load();
        videoElement.play();
    }, 1000);
       this.videoshow=true;
      console.log("outside if cond ")
      this.sendVideoControlToSocket("video_add")
   }

   sendVideoControlToSocket(actionType: string) {
    console.log("inside sendVideoControlToSocket ")
    const videoElement = document.getElementById('videoElement') as HTMLVideoElement;
    if(actionType!="video_seek"){
    const currentObj = {
        object : this.videourl,
        media:"video",
        action_type: actionType,
        //currentTime: audioElement.currentTime,
        mainLessonId: localStorage.getItem('mainlessonID'),
        roomid:localStorage.getItem('roomID')
      };
      console.log(`Emitting ns ${actionType} to WebSocket`);
      this.fileservice.emitCanvasOperationToServer(currentObj);
    }
    else{
      const currentObj = {
        object : this.videourl,
        media:"video",
        action_type: actionType,
        currentTime: videoElement.currentTime,
        mainLessonId: localStorage.getItem('mainlessonID'),
        roomid:localStorage.getItem('roomID')
      };
      console.log(`Else Emitting seek ${actionType} to WebSocket`);
      this.fileservice.emitCanvasOperationToServer(currentObj);
    }

  }
 handlevideo(){
 this.videoshow=false;
 this.sendVideoControlToSocket('video_close');
 }

 sendwebpageToSocket(actionType: string) {
  console.log("inside sendwebpageToSocket ")
  //const videoElement = document.getElementById('videoElement') as HTMLVideoElement;
  //if(actionType!="video_seek"){
  const currentObj = {
      object : this.webpageunsecureurl,
      media:"webpage",
      action_type: actionType,
      //currentTime: audioElement.currentTime,
      mainLessonId: localStorage.getItem('mainlessonID'),
      roomid:localStorage.getItem('roomID')
    };
    console.log(`Emitting ns ${actionType} to WebSocket`);
    this.fileservice.emitCanvasOperationToServer(currentObj);
  ///}
}

send3DToSocket(_3dObj,actionType: string) {
  console.log("inside send3DToSocket ",_3dObj)
  //const videoElement = document.getElementById('videoElement') as HTMLVideoElement;
  //if(actionType!="video_seek"){
  const currentObj = {
      object : _3dObj,
      media:"3D",
      action_type: actionType,
      //currentTime: audioElement.currentTime,
      mainLessonId: localStorage.getItem('mainlessonID'),
      roomid:localStorage.getItem('roomID')
    };
    console.log(`Emitting ns ${actionType} to WebSocket`);
    this.fileservice.emitCanvasOperationToServer(currentObj);
  ///}
}

openimportbox(){
this.imagelist=false;
 this.medlist=false;
 this.shapeslist=false;
 this.audlist=false;
 this.objectlist=false;
 this.showdoucmentlist=false;
 console.log(this.showdoucmentlist)
}

highlightTools:Boolean=false;


/////////changes made for drawtool/////
handleDrawtool(data:any){
  if(this.opendrawtool){
console.log(this.opendrawtool);
this.opendrawtool=false;
this.drawtoolactive=true;
this.addcolorClicked=false;
}

}

//To close toolbox if tool clicked on
handleToolBox() {
  console.log('123456788909999999999', this.opentoolsicon)
  // if(this.opentoolsicon===true) {
  //   console.log('this.opentoolsicon', )
  //   this.opentoolsicon = false;
  //   this.checkToolstatus();
  // }
  if (this.opentoolsicon) {
    this.opentoolsicon = false;
    this.checkToolstatus();
  }
  if (
    !this.calculatorClicked &&
    !this.votingClicked &&
    !this.compassClicked &&
    !this.protractorClicked &&
    !this.setsquareClicked &&
    !this.rulerClicked
  ) {
    if(this.selectedIcon==='icon10'){
      this.selectedIcon=null;
    }
    this.opentoolsicon = false;
  }
}

checkEraserStatus(){
  if(this.selectedIcon === 'icon7'||this.selectederaser === 'icon1'||this.selectederaser === 'icon2'||  this.selectederaser === 'icon3'||this.selectederaser === 'icon4'){
    return true
  }else{
    return false
  }
}

// checkToolstatus(){
//   if(this.selectedIcon==='icon10'||this.calculatorClicked||this.votingClicked){
//       return true
//      }
//      else{
//       return false
//       }
//     }


checkToolstatus(){
  if(this.selectedIcon==='icon10'||this.calculatorClicked||this.votingClicked||this.compassClicked||this.protractorClicked||this.setsquareClicked||this.rulerClicked){
      return true
     }
     else{
      this.opentoolsicon = false;
      return false
      }
    }

    truncateFilename(filename:any, length:any) {
      //console.log(filename);
      if (filename.length <= length) {
        return filename;
      } else {
       let extensionIndex = filename.lastIndexOf('.');
      let firstPart = filename.slice(0, length);
      let extension = filename.slice(extensionIndex);
      let truncatedFilename = `${firstPart}.. ${extension}`;
      //console.log(truncatedFilename,"truncate");
      return truncatedFilename;
      }
    }

    getDocumentFileThumbnail(type) {
      switch (type) {
        case "application/pdf":
          return `assets/PPDS/filemanager/drivepdf.svg`;
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
          return `assets/PPDS/filemanager/driveimage.svg`;
        case "video/mp4":
        case "video/quicktime":
          return `assets/PPDS/filemanager/drivemp4.svg`;
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "assets/PPDS/filemanager/driveword.svg";
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return `assets/PPDS/filemanager/driveexcel.svg`;
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return "assets/PPDS/filemanager/driveppt.svg";
        case "text/plain":
          return "assets/PPDS/filemanager/drivetxt.svg";
        default:
          return `assets/PPDS/filemanager/driveimage.svg`;
      }
    }




    ////////////////////////////////////////////////search in insert/////////
    searchfilesbyname:any
    searchpage:any=1
    showdata:Boolean=false;
    Shownodata:Boolean=false;





searchtype:any="";
searchByname:any=""
// handleuploadssearch(type:any,handlepageadd:Boolean){
//   let userId=localStorage.getItem("userId")
//   console.log("..............git......")
//   console.log(this.searchpage,"searchpage")
//   this.searchtype=type
// if(handlepageadd){
//   this.searchpage=this.searchpage+1
// } else{
//   this.searchpage=1
// }
// console.log("this.searchpage",this.searchpage)
//   this.fileservice.searchuploadedContents(type,this.searchByname,this.searchpage,userId,undefined).subscribe(
//     (response:any)=>{
//       console.log("Res--->", response);


// /////////////////////////////image/////////////////////////////////
//         if(type==="image"){
//           //this.totalimages=true
//           if(this.searchpage>1&&response.data.length>0){

//             response.data.map(item=>{
//               console.log(item,"item");
//               this.totalimagesarr.push(item);
//               })
//             console.log( this.totalimagesarr,"images")
//             this.showdata=true;
//             this.Shownodata=false;
//           }else if(this.searchpage===1&&response.data.length===0){
//             this.showdata=false;
//             this.Shownodata=true;
//           }else if(this.searchpage===1&&response.data.length>0){
//            // this.totalimages=true
//           this.totalimagesarr=response.data
//           console.log(this.totalimagesarr,"this")
//          // this.Shownomatch=false;
//          this.showdata=true;
//             this.Shownodata=false;
//           }else{
//             return
//           }
//           }
//           //////////////////////////////////////////////Audio////////////////////////
//       if(type==="audio"){
//        // this.totalaudioarr=response.data
//        if(this.searchpage>1&&response.data.length>0){

//         response.data.map(item=>{
//           console.log(item,"item");
//           this.totalaudioarr.push(item);
//         })
//         this.showdata=true;
//         this.Shownodata=false;
//         console.log( this.totalaudioarr,"images")
//       }else if(this.searchpage===1&&response.data.length===0){
//         console.log("...............not cone")
//         this.showdata=false;
//         this.Shownodata=true;
//       }else if(this.searchpage===1&&response.data.length>0){

//       this.totalaudioarr=response.data
//       this.showdata=true;
//       this.Shownodata=false;
//       console.log(this.totalimagesarr,"this")
//       }else{
//         return
//       }
//       }

//       /////////////////////////////////////video//////
//       if(type==="video"){


//         if(this.searchpage>1&&response.data.length>0){

//           response.data.map(item=>{
//             console.log(item,"item");
//             this.totalvideosarr.push(item);
//           })

//           this.showdata=true;
//           this.Shownodata=false;
//           console.log( this.totalvideosarr,"images")
//         }else if(this.searchpage===1&&response.data.length===0){
//           this.showdata=false;
//           this.Shownodata=true;
//         }else if(this.searchpage===1&&response.data.length>0){

//         this.totalvideosarr=response.data
//         this.showdata=true;
//             this.Shownodata=false;
//         console.log(this.totalvideosarr,"this in video")
//         }else{
//           return
//         }
//       }
//       /////////////////////////Document//////////////////
//       if(type==="document"){
//         if(this.searchpage>1&&response.data.length>0){

//           response.data.map(item=>{
//             console.log(item,"item");
//             this.totaldocarr.push(item);

//           })
//           this.showdata=true;
//           this.Shownodata=false;
//           console.log( this.totaldocarr,"images")
//         }else if(this.searchpage===1&&response.data.length===0){
//           this.showdata=false;
//           this.Shownodata=true;
//         }else if(this.searchpage===1&&response.data.length>0){
//         this.totaldocarr=response.data
//         this.showdata=true;
//             this.Shownodata=false;
//         console.log(this.totalaudioarr,"this")
//         }else{
//           return
//         }

//       }
//       ////////////////////////////////////////////////////3D//////////////////////////////////

//       if(type==="3D"){
//         console.log("3dddddddddddddddddd")
//         if(this.searchpage>1&&response.data.length>0){

//           response.data.map(item=>{
//             console.log(item,"item");
//             this.allobjects3d.push(item);

//           })
//           this.showdata=true;
//           this.Shownodata=false;
//           console.log( this.allobjects3d,"images")
//         }else if(this.searchpage===1&&response.data.length===0){
//           this.showdata=false;
//           this.Shownodata=true;
//         }else if(this.searchpage===1&&response.data.length>0){
//         this.allobjects3d=response.data
//         this.showdata=true;
//             this.Shownodata=false;
//         console.log(this.allobjects3d,"this")
//         }else{
//           return
//         }

//       }
// },
//     (error: any) => {
//       console.error(error);
//     }
//    )
// }






onScroll(value) {
  console.log("value in onscroll",value)
  console.log("hiiii")


 const element = value==='insertImagescroll'?this.insertImagescroll.nativeElement:
 value==='insertDOcumentscroll'?this.insertDOcumentscroll.nativeElement:
 value==='insertaudioscroll'?this.insertaudioscroll.nativeElement:
 value==='threeDscroll'?this.threeDscroll.nativeElement:
 this.videoScroll.nativeElement

  console.log("element",element)
  console.log("searchtype",this.searchtype)
  console.log("elementscrolltop",element.scrollTop,element.clientHeight,element.scrollHeight)
  console.log()
  if ((element.scrollTop + element.clientHeight+2) >= element.scrollHeight) {
    console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
    if(value==='insertImagescroll'||value==='insertDOcumentscroll'|| value==='insertaudioscroll'||value==='videoScroll'){
      console.log("pppppppppppppppppppp")
 // this.handleuploadssearch(this.searchtype,true);
 this.handleuploadssearch(this.searchtype,true,this.isPublic);
    }

 else{
    return
  }
}


    console.log('API call triggered');

}

async callSaveWhiteboard(){
  this.mycanvas.saveCanvasDataToMongoDB()
}


selecttabstatus(){
  if((this.selectedtapvalue ==="icon1"
  )||(this.selectedtapvalue=== 'icon2')||this.selectedIcon==='icon8'){
    return true
  }else{
    return false
  }
}


selectedtapvalue:any=null;
selectselecttap(value){
  console.log(value,"kkkkkkkkkkkkkkkkkkkkk")
  if(this.selectedtapvalue===value){
    this.selectedtapvalue=null
    this.openselectiontool=false;
    this.selectedIcon=null;
    this.selecttabstatus()
  }else{
    this.selectedtapvalue=value;
    this.openselectiontool=false;
    console.log( this.selectedtapvalue,"kkkkkkkkkkkkk")
    this.selecttabstatus()
  }
}



InsertDatawhiteboard(data: { data1: any, data2: any, imageUrl: any }){
console.log("logs recieved",data.data1,data.data2)
if(data.data2==="video"){
  this.addWindowThumbnailToCanvas({windowType:'windowVideoMyLibrary',thumbnail:'assets/PPDS/whiteboardscreen/videothumb.svg',
    name:this.truncateFilename(data.imageUrl.originalName,5), url: data.imageUrl.destinationpath, insertedFrom:'fileManager'
  })
}
else if(data.data2==="image"){
  this.mycanvas.addImageOnCanvas(data.data1,"filemanager")
}
else if(data.data2==="audio"){
  this.addWindowThumbnailToCanvas({windowType:'windowAudioMyLibrary',thumbnail:'assets/insert/mediaDocThumbnailIcons/white.png',
    name:this.truncateFilename(data.imageUrl.originalName,5), url: data.imageUrl.destinationpath, insertedFrom:'fileManager'
  })
}
else if(data.data2==="document"){
  this.addUploadDocumentToCanvasEventHandler(data.data1)
}

this.mycanvas.closepend();



}

getInitials(fullName: string){
  // Split the full name into individual words
  const words = fullName.split(' ');
  // Get the first character of each word and join them to form initials
  this.initials = words.map(word => word.charAt(0)).join('').toUpperCase();
  console.log(this.initials)
  }

  audiourl:any

  handleaudio(){
    this.audioshow=false;
    this.sendAudioControlToSocket('audio_close');
  }


  showaudio(url){
    console.log("Local Debugg: showaudio",url);
    this.videoshow=false;
    const audioElement = document.getElementById('audioElement') as HTMLAudioElement;
    console.log("urlllllll",url,audioElement)
    if (audioElement) {
      console.log("inside if cond audielement")
      audioElement.pause();
      audioElement.src = '';
      audioElement.load();
    }
    this.audiourl=url
     setTimeout(() => {
      audioElement.src = url;
      audioElement.load();
      audioElement.play();
  }, 1000);
     this.audioshow=true;
    console.log("outside if cond ")
    this.sendAudioControlToSocket("audio_add")
  }

sendAudioControlToSocket(actionType: string) {
  console.log("inside sendAudioControlToSocket ")
  const audioElement = document.getElementById('audioElement') as HTMLAudioElement;
  if(actionType!="audio_seek"){
  const currentObj = {
      object : this.audiourl,
      media:"audio",
      action_type: actionType,
      //currentTime: audioElement.currentTime,
      mainLessonId: localStorage.getItem('mainlessonID'),
      roomid:localStorage.getItem('roomID')
    };
    console.log(`Emitting ns ${actionType} to WebSocket`);
    this.fileservice.emitCanvasOperationToServer(currentObj);
  }
  else{
    const currentObj = {
      object : this.audiourl,
      media:"audio",
      action_type: actionType,
      currentTime: audioElement.currentTime,
      mainLessonId: localStorage.getItem('mainlessonID'),
      roomid:localStorage.getItem('roomID')
    };
    console.log(`Else Emitting seek ${actionType} to WebSocket`);
    this.fileservice.emitCanvasOperationToServer(currentObj);
  }
}

    // async confirmPageChangedOrNot() {
    //   try {
    //     if(this.mycanvas.wasUndoRedoPressed) {
    //       this.mycanvas.wasUndoRedoPressed = false;
    //       return false
    //     }
    //     let currentPage = Number(localStorage.getItem('currentPage'));
    //     currentPage = currentPage - 1;
    //     if (JSON.parse(localStorage.getItem('currentCanvas'))?.["objects"]) {
    //       let currentPageObj = JSON.parse(localStorage.getItem('currentCanvas'))?.["objects"];
    //       let allPageObjofCurr = JSON.parse(localStorage.getItem('savedCanvasData'));
    //       if (JSON.stringify(currentPageObj) === JSON.stringify(allPageObjofCurr[currentPage].objects)) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     } else {
    //       return true;
    //     }
    //   } catch (error) {
    //      return true;
    //   }
    // }

    checkInternetConn(){
      if(this.fileservice.isConnected){
         this.closeIntAlert = false
      }
      else{
        if(localStorage.getItem('avchatstarted') && localStorage.getItem('avchatstarted') == 'yes'){
          this.fileservice.closeAvChat.next("yes")
        }
        document.getElementById("avhcatSidenav").style.width = "0px";
        document.getElementById("leftsidenav").style.width = "0px";
      }
      return this.fileservice.isConnected
    }

    closeAlert(){
      this.closeIntAlert = true
    }

    handleslecttap(){
      if(this.openselectiontool===true){
        this.openselectiontool=false;
      }
    }

    handleEraseTool() {
      if (this.openerasetool === true) {
        this.openerasetool = false;
      }
    }



    isPublic:Boolean

    handleuploadssearch(type:any,handlepageadd:Boolean,IsPublic:Boolean){
      let userId=localStorage.getItem("userId")
      console.log("..............git......")
      console.log(this.searchpage,"searchpage")
      this.searchtype=type
    if(handlepageadd){
      this.searchpage=this.searchpage+1
    } else{
      this.searchpage=1
    }
    this.isPublic=IsPublic;
    let userid=undefined;
    let publicflag=undefined;

    if(this.isPublic===true){
      publicflag=true;
      userid=undefined;
    }else{
      publicflag=true;
      userid=userId;
    }



    console.log("this.searchpage",this.searchpage)
      this.fileservice.searchuploadedContents(type,this.searchByname,this.searchpage,userid,publicflag).subscribe(
        (response:any)=>{
          console.log("Res--->", response);


    /////////////////////////////image/////////////////////////////////
            if(type==="image"){
              //this.totalimages=true
              if(this.searchpage>1&&response.data.length>0){

                response.data.map(item=>{
                  console.log(item,"item");
                  this.totalimagesarr.push(item);
                  })
                console.log( this.totalimagesarr,"images")
                this.showdata=true;
                this.Shownodata=false;
              }else if(this.searchpage===1&&response.data.length===0){
                this.showdata=false;
                this.Shownodata=true;
              }else if(this.searchpage===1&&response.data.length>0){
               // this.totalimages=true
              this.totalimagesarr=response.data
              console.log(this.totalimagesarr,"this")
             // this.Shownomatch=false;
             this.showdata=true;
                this.Shownodata=false;
              }else{
                return
              }
              }
              //////////////////////////////////////////////Audio////////////////////////
          if(type==="audio"){
           // this.totalaudioarr=response.data
           if(this.searchpage>1&&response.data.length>0){

            response.data.map(item=>{
              console.log(item,"item");
              this.totalaudioarr.push(item);
            })
            this.showdata=true;
            this.Shownodata=false;
            console.log( this.totalaudioarr,"images")
          }else if(this.searchpage===1&&response.data.length===0){
            console.log("...............not cone")
            this.showdata=false;
            this.Shownodata=true;
          }else if(this.searchpage===1&&response.data.length>0){

          this.totalaudioarr=response.data
          this.showdata=true;
          this.Shownodata=false;
          console.log(this.totalimagesarr,"this")
          }else{
            return
          }
          }

          /////////////////////////////////////video//////
          if(type==="video"){


            if(this.searchpage>1&&response.data.length>0){

              response.data.map(item=>{
                console.log(item,"item");
                this.totalvideosarr.push(item);
              })

              this.showdata=true;
              this.Shownodata=false;
              console.log( this.totalvideosarr,"images")
            }else if(this.searchpage===1&&response.data.length===0){
              this.showdata=false;
              this.Shownodata=true;
            }else if(this.searchpage===1&&response.data.length>0){

            this.totalvideosarr=response.data
            this.showdata=true;
                this.Shownodata=false;
            console.log(this.totalvideosarr,"this in video")
            }else{
              return
            }
          }
          /////////////////////////Document//////////////////
          if(type==="document"){
            if(this.searchpage>1&&response.data.length>0){

              response.data.map(item=>{
                console.log(item,"item");
                this.totaldocarr.push(item);

              })
              this.showdata=true;
              this.Shownodata=false;
              console.log( this.totaldocarr,"images")
            }else if(this.searchpage===1&&response.data.length===0){
              this.showdata=false;
              this.Shownodata=true;
            }else if(this.searchpage===1&&response.data.length>0){
            this.totaldocarr=response.data
            this.showdata=true;
                this.Shownodata=false;
            console.log(this.totalaudioarr,"this")
            }else{
              return
            }

          }
          ////////////////////////////////////////////////////3D//////////////////////////////////

          if(type==="3D"){
            console.log("3dddddddddddddddddd")
            if(this.searchpage>1&&response.data.length>0){

              response.data.map(item=>{
                console.log(item,"item");
                this.allobjects3d.push(item);

              })
              this.showdata=true;
              this.Shownodata=false;
              console.log( this.allobjects3d,"images")
            }else if(this.searchpage===1&&response.data.length===0){
              this.showdata=false;
              this.Shownodata=true;
            }else if(this.searchpage===1&&response.data.length>0){
            this.allobjects3d=response.data
            this.showdata=true;
                this.Shownodata=false;
            console.log(this.allobjects3d,"this")
            }else{
              return
            }

          }
    },
        (error: any) => {
          console.error(error);
        }
       )
    }





    defaultpenactive(){
      this.selectedIcon="icon6"
      this.drawtoolactive=true;
      // this.mycanvas.selectPenCil();
      this.thick=this.pencilthick;
      this.drawtoolactive=true
      this.pencilClicked=true;
      this.highlighterClicked=false;
    }



    handlesubmenuclosing(){
      console.log("hiiii");

      if(this.openinsert){
        this.openinsert=false;
        this.selectedIcon=null;
      }
      console.log(this.fileClicked,"handle")
      if(this.fileClicked){
        this.closefilemanage();
      }
      if(this.openBackdropBox){
        this.openBackdropBox=false;
        this.selectedIcon=null;
      }
      console.log(this.useropen,"in mmm")
      if( this.useropen){
        this.closeprofile();
        this.useropen=false
      }
      if(this.isshareopen){
      this.isshareopen=false;
      this.selectedIcon=null;

      }


    }



    getScreenWidth(): number {
      return window.innerWidth;
    }

    getScreenHeight(): number {
      return window.innerHeight;
    }

    closeAlertsub(){
      this.subscription = false
      localStorage.removeItem("subscription");
    }


    closeDemoAlert(){
      this.demosubscription=false;
    }


    closeallsubmenu(){
      this.removeAllGeometryTool();

      this.selectedIcon=null;
      this.openBackdropBox = false;
      this.backdropClicked = false;
      this.opentexttool = false;
      this.allpages = false;
      this.opendrawtool = false;
      this.openerasetool = false;
      this.openselectiontool = false;
      this.opentoolsicon = false;
      this.openinsert = false;
      this.opentexttool = false;
      this.eraseClicked = false;
      this.selectClicked = false;
      this.typeClicked = false;
      this.toolsClicked = false;
      this.insertClicked = false;
      this.drawClicked = false;
      this.redoClicked = false;
      this.undoClicked = false;
      this.openUndoTool = false;
      this.openRedoTool = false;
      this.isshareopen = false;
      this.shareClicked = false;
      this.fileClicked = false;
      this.selectederaser=null;
      this.closefilemanage();
      this.drawClicked=false;
      this.drawtoolactive=false;
      this.selectedsel = null;
      this.selectedtapvalue=null;
      // this.CloseCalculator()

      this.mycanvas.exitEditText();
      this.selectedIcon=null;
      this.mycanvas.closepend();
    }

    callapplyBgColor(data) {
      this.applyBgColor(data.index,true);
    }

    calltoggleFrame(data) {
      console.log("Venki: calltoggleFrame",data);
      this.toggleFrame(data.frame,true);
    }



    // handletextmodetoggle(data){
    //   console.log(data,"data");
    //   this.opentexttool=data;
    //   this.selectedIcon=data===true?"icon9":this.selectedIcon==="icon9"?null:this.selectedIcon;
    //   if (this.opentexttool  === true) {
    //     this.openSelectTool = true;
    //   }

    // }



    handletextmodetoggle(data){
      console.log(data);
      this.opentexttool=data;
      this.selectedIcon=data===true?"icon9":this.selectedIcon==="icon9"?null:this.selectedIcon;
      if (this.opentexttool  === true) {
        this.openSelectTool = false;
      }
      if(this.selectedIcon===null&&data===false){
        this.openSelectTool = true;
      }

    }

    navDisabledState = true;
    receiveNavDisabledState(state) {
      this.navDisabledState = state;
    }

    clearLowPriorityEvents() {
      this.mycanvas.eventCleanUp('whiteboard');
    }

    closeDemoUpgrade(value:boolean) {
      this.demoUpgradeVisible = value;
    }

    updateCurrentCanvasCache() {
      this.mycanvas.updateCurrentCanvasCache();
    }



    addWindowThumbnailToCanvas(data:windowThumbnail) {
      this.mycanvas.addWindowThumbnailToCanvas(data);
      //ROHAN: play multimedia at once after insert
      switch (data.windowType) {
        case "windowYoutube":
          this.addFloatingWindowHandler({ type: 'youtube', data: data.url })
          break;
        case "windowVideoMyLibrary":
          this.showmediavideos(data.url);
          break;
        case "window3D":
          this.add3DToViewerEventHandler(data.url);
          break;
        case "windowDocMyLibrary":
          this.addUploadDocumentToCanvasEventHandler(data.url)
          break;
        case "windowAudioMyLibrary":
          this.showaudio(data.url)
          break;
        case "windowDoc":
          this.addtoCanvas(data.url);
          break;
      }
    }

    onMediaDocMetaDataFromServer(event: {type:string, url:any}) {
      console.log("Local Debugg: onMediaDocMetaDataFromServer",event);
      switch(event.type) {
        case "windowYoutube":
          this.addFloatingWindowHandler({type:'youtube',data:event.url})
          break;
        case "windowVideoMyLibrary":
        this.showmediavideos(event.url);
        break;
        case "window3D":
          this.add3DToViewerEventHandler(event.url);
          break;
          case "windowDocMyLibrary":
            this.addUploadDocumentToCanvasEventHandler(event.url)
            break;
          case "windowAudioMyLibrary":
          this.showaudio(event.url)
          break;
          case "windowDoc":
            this.addtoCanvas(event.url);
            break;
      }
      }

      FromFileManager_addDocWindowWrapper(imageUrl) {
      this.addWindowThumbnailToCanvas({windowType:'windowDocMyLibrary',thumbnail:this.getDocumentFileThumbnail(imageUrl.mimeType),
        name:this.truncateFilename(imageUrl.originalName,5), url: imageUrl, insertedFrom:'fileManager'
      })
    }

      FromFileManager_add3DWindowWrapper(imageUrl) {
        this.addWindowThumbnailToCanvas({windowType:'window3D',thumbnail:'assets/insert/mediaDocThumbnailIcons/grey.png',name:this.truncateFilename(imageUrl.originalName,5),url:imageUrl, insertedFrom:'fileManager'})
      }

  closeMediaDoc3DWindows() {
    try {
      this.handleaudio();
      this.handlewebpageview();
      this.handlevideo();
      this.handleClose3DObjectViewer(this.current3DObjFloatingWindows[0]);
      this.handleCloseYTContainer(this.currentYTFloatingWindows[0]);
      this.removeDocWindowEventHandler(this.currentDocFloatingWindows[0]);
    }
    catch (error) {
      console.log(error);
    }
  }



  openInfourl(value){
    
    this.webpageurl=this.sanitizer.bypassSecurityTrustResourceUrl(value);
    this.modals_exit_ref = this.modalService.show(this.modalContentInfo, {
      class: 'setHeight',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
  }

  closeModal() {

    this.modals_exit_ref.hide();

  }

  activatedrawtool(){
    console.log("Bandana drawtool");
    this.defaultpenactive();
    if(this.openSelectTool===true){
      this.openSelectTool=false
    }
  }

  StartclassNotification(mode: any){
    console.log(mode ,"techer mode?????")
    if (mode === 'present') {
      this.startnotification = true;
      this.startclassmsg = 'Presentation Mode On';
      this.notificationIcon = 'assets/PPDS/AVchat/blue/presentmsg.svg';
    }
    if (mode === 'collab') {
      this.startnotification = true;
      this.startclassmsg = 'Collaboration Mode On';
      this.notificationIcon = 'assets/PPDS/AVchat/blue/collabmsg.svg';
    }
    if (mode === 'end') {
      this.startnotification = false;
      this.startclassmsg = '';
      this.notificationIcon = ''; // Clear the icon
    }
  }

  onDragEnded(event: any) {
    console.log("Drag ended");
  
    // Get the updated position
    const position = event.source.getFreeDragPosition();
    console.log('Position:', position);
  
    // Create movement data
    // const movementData = {
    //   media:"video",
    //   position,
    //   action_type: 'video_drag',
    //   roomid: localStorage.getItem('roomID')  // Ensure the room ID is set properly
    // };
    const movementData = {
      media:"video",
      position,
      action_type: 'video_drag',
      mainLessonId: localStorage.getItem('mainlessonID'),
      roomid:localStorage.getItem('roomID')
    };
  
    // Emit the new position to the server or handle it as required
    this.fileservice.emitCanvasOperationToServer(movementData);
    console.log('Teacher dragged video to:', movementData);
  }
  

  
  onDragMoved(event: CdkDragMove) {
    console.log('Drag moved:', event);
  }
  

  onDragStarted(event: CdkDragStart) {
    console.log("dragstarted")
    const element = event.source.element.nativeElement;
    element.style.zIndex = '101'; // Higher than the default 100
  }
  
  onDragEndednew(event: CdkDragEnd) {
    console.log("dragended")
    const element = event.source.element.nativeElement;
    element.style.zIndex = '100'; // Reset to default
  }

}
interface windowThumbnail {
      windowType:string,
      thumbnail:string,
      name:string,
      url:any,
      insertedFrom:string
}
