import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouteModule, RoutingComponents } from "./router.module";
import { DataTablesModule } from "angular-datatables";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from "./app.component";
import {
  EditorcanvasModule,
  FabricjsEditorModule,
} from "projects/angular-editor-fabric-js/src/public-api";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ColorPickerModule } from "ngx-color-picker";
import { FirstscreenComponent } from "./firstscreen/firstscreen.component";
import { UploadmediaComponent } from "./uploadmedia/uploadmedia.component";
import { WebuploadComponent } from "./webupload/webupload.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { YoutubeComponent } from "./youtube/youtube.component";
import { environment } from "../environments/environment";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { SanitizerHtmlPipe } from "./sanitizer-html.pipe";
import { EtherpadkmsComponent } from "./etherpadkms/etherpadkms.component";
import { VirtualClassComponent } from "./virtual-class/virtual-class.component";
import { TeacherkmsComponent } from "./teacherkms/teacherkms.component";
import { QRloginComponent } from "./qrlogin/qrlogin.component";
import { UserpassloginComponent } from "./userpasslogin/userpasslogin.component";
import { PassrecoveryComponent } from "./passrecovery/passrecovery.component";
import { WhiteboardpageComponent } from "./whiteboardpage/whiteboardpage.component";
import { ProfileComponent } from "./profile/profile.component";
import { SuperadminComponent } from "./superadmin/superadmin.component";
import { RegionaladminComponent } from "./regionaladmin/regionaladmin.component";

import { FilemanagerComponent } from "./filemanager/filemanager.component";
import { WhiteboardpagethumbnailComponent } from "./whiteboardpagethumbnail/whiteboardpagethumbnail.component";

import { MatIconModule, MatDialogModule } from "@angular/material";
import { PhoneuiloginComponent } from "./phoneuilogin/phoneuilogin.component";

import { RegionaladmineditComponent } from "./regionaladminedit/regionaladminedit.component";
import { CountryadminComponent } from "./countryadmin/countryadmin.component";
import { SchoolComponent } from "./school/school.component";
import { SchooladminComponent } from "./schooladmin/schooladmin.component";
import { TeacherComponent } from "./teacher/teacher.component";
import { DemoaccountsComponent } from "./demoaccounts/demoaccounts.component";
import { VotingSectionComponent } from "./voting-section/voting-section.component";
import { ClientVotingViewComponent } from "./client-voting-view/client-voting-view.component";
import { VotingbargraphComponent } from "./votingbargraph/votingbargraph.component";
import { VotingpiechartComponent } from "./votingpiechart/votingpiechart.component";
import { BackdropSectionComponent } from "./backdrop-section/backdrop-section.component";
import { BrowserWarningComponent } from './browser-warning/browser-warning.component';

import { DragDropModule } from "@angular/cdk/drag-drop"; //change - shreyas.s
import { YouTubePlayerModule } from "@angular/youtube-player"; //change - shreyas.s
import { ClassroomApiInterceptor } from "./gc-plugin/google-api.interceptor"; //change - shreyas.s
import { GoogleAuthService } from "./gc-plugin/google-auth.component"; //change - shreyas.s
import { DocumentWindowComponent } from "./document-window/document-window.component";
import { CalculatorComponent } from "./calculator/calculator.component";
import { AVchatComponent } from "./avchat/avchat.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { DemoemailComponent } from "./demoemail/demoemail.component";
import { DemoSetuserComponent } from "./demosetuser/demosetuser.component";

import { MatTabsModule } from "@angular/material/tabs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StudentavchatComponent } from "./studentavchat/studentavchat.component";
import { SharetogoogleComponent } from "./sharetogoogle/sharetogoogle.component";
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { HelperService } from './utils/helper.service';
import { UndoRedoService } from "./undo-redo.service";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { DialogService } from "./dialog.service";
import { SimplePopupComponent } from "./simple-popup/simple-popup.component";
import { AvvotingComponent } from './avvoting/avvoting.component';
import { UpgardelicenseComponent } from './upgardelicense/upgardelicense.component';
import { DemoUpgradeComponent } from "./demo-upgrade/demo-upgrade.component";

const SocketUrl = environment.serverurl;
const fabricPath = environment.enableFabricPath
const config: SocketIoConfig = {
  url: "http://localhost:5000",
  options: {},
};

// AoT requires an exported function for factories
// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/languagestring/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    RoutingComponents,
    FirstscreenComponent,
    UploadmediaComponent,
    WebuploadComponent,
    YoutubeComponent,
    EtherpadkmsComponent,
    VirtualClassComponent,
    SanitizerHtmlPipe,
    TeacherkmsComponent,
    QRloginComponent,
    UserpassloginComponent,
    PassrecoveryComponent,
    WhiteboardpageComponent,
    ProfileComponent,
    SuperadminComponent,
    RegionaladminComponent,
    RegionaladmineditComponent,
    FilemanagerComponent,
    PhoneuiloginComponent,
    WhiteboardpagethumbnailComponent,
    CountryadminComponent,
    SchoolComponent,
    SchooladminComponent,
    TeacherComponent,
    DemoaccountsComponent,
    VotingSectionComponent,
    ClientVotingViewComponent,
    VotingbargraphComponent,
    VotingpiechartComponent,
    BackdropSectionComponent,
    DocumentWindowComponent, //change - shreyas.s
    SharetogoogleComponent, //change - shreyas.s
    CalculatorComponent,
    AVchatComponent,
    StudentavchatComponent,
    ResetPasswordComponent,
    DemoemailComponent,
    DemoSetuserComponent,
    BrowserWarningComponent,
    ErrorDialogComponent,
    SimplePopupComponent,
    AvvotingComponent,
    UpgardelicenseComponent,
    DemoUpgradeComponent
  ],
  imports: [
    BrowserModule,
    FabricjsEditorModule,
    EditorcanvasModule,
    FormsModule,
    ColorPickerModule,
    RouteModule,
    ModalModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    SocketIoModule.forRoot(config),
    DataTablesModule,
    MatIconModule,
    DragDropModule, //change - shreyas.s
    YouTubePlayerModule, //change - shreyas.s
    MatTabsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: (http: HttpClient) =>{return new TranslateHttpLoader (http ,'assets/i18n','.json');},
        useFactory:(HttpLoaderFactory),
        deps: [HttpClient]
       }
      })
  ],

  //changes start - shreyas.s
  providers: [
    GoogleAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClassroomApiInterceptor,
      multi: true,
    },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
      // Conditionally set the LocationStrategy based on the environment
      {
        provide: LocationStrategy,
        useClass: environment.enableFabricPath ? HashLocationStrategy : PathLocationStrategy
      },HelperService,
      UndoRedoService,
      DialogService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
