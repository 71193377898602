import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { FileService } from "../file.service";
@Component({
  selector: "app-upgardelicense",
  templateUrl: "./upgardelicense.component.html",
  styleUrls: ["./upgardelicense.component.scss"],
})
export class UpgardelicenseComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private filesurl: FileService,
    public router: Router
  ) {}
  emailid;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.emailid = params["emailid"];
      console.log(this.emailid);

 

      this.filesurl.loginfromlicenseemail(this.emailid).subscribe((user) => {
        console.log("User Management ___________________", user);
        console.log("user user user ", user.classidavchat);
        localStorage.setItem("username", user.username);
        localStorage.setItem("type", user.type);
        localStorage.setItem("userId", user.userId.toLowerCase());
        localStorage.setItem("session_id", user.session_id);
        localStorage.setItem("accessToken", user.session_id);
        localStorage.setItem("refreshToken", user.refreshToken);
        localStorage.setItem("classidavchat", user.classidavchat);
        localStorage.removeItem("prevCanvasObjLen");
        if (user.language == undefined) {
          localStorage.setItem("lang", "en-Us");
          console.log("null lang", localStorage.getItem("lang"));
        } else if (user.language != undefined) {
          localStorage.setItem("lang", user.language);
        }

        if (user.type == "demoaccounts") {
          this.filesurl
            .getTVInformation(
              { emailid: user.userId, role: user.type },
              "/getadmin"
            )
            .then((res: any) => {
              try {
                if (res) {
                  localStorage.setItem(
                    "licensetype",
                    res.body.data.licensetype
                  );
                  console.log(res.body.data.enddate, "for licensedetails");

                  var enddateString = res.body.data.enddate;
                  var enddate = new Date(enddateString);
                  var today = new Date();
                  var differenceMs = enddate.getTime() - today.getTime();

                  var differenceDays = Math.ceil(
                    differenceMs / (1000 * 60 * 60 * 24)
                  );
                  console.log(differenceDays, "differ");
                  console.log(res.body.data.activestatus, "Bandana");
                  if (
                    user.type === "demoaccounts" &&
                    res.body.data.activestatus === "inactive"
                  ) {
                    console.log("hello");

                    return;
                  }
                  // Determine if the end date is approaching expiration
                  if (differenceDays < 0) {
                    //expecting within time
                    return
                  } else {
                    console.log(
                      "The end date is in the future. Days left until expiration:",
                      differenceDays
                    );
                    this.filesurl.setTriggerState(true);
                    localStorage.setItem(
                      "subscription",
                      differenceDays.toString()
                    );
                    this.filesurl.licensePortalFun(true)
                    this.router.navigate(["/whiteboard"]);
                  }
                } else {
                  console.log("no data from backend");
                }
              } catch (e) {
                console.log(e);
              }
            });
        }
        //console.log(result);
      });
    });
  }
}
