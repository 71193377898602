import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FileService } from '../file.service';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AbstractControl, FormGroup, FormsModule, FormControl, FormArray, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-countryadmin',
  templateUrl: './countryadmin.component.html',
  styleUrls: ['./countryadmin.component.scss']
})
export class CountryadminComponent implements OnInit {
  public save_exit_ref: BsModalRef;
  bsModalRef: BsModalRef;
  AllCountryadmin: any = [];
  position = "absolute";
  countryadmin: any = {};
  AdminCheckboxListArray: any = [];
  adminnames: any = [];
  admin: any;
  countrynames: any = [];
  country: String="";
  isModalOpen: boolean = false;
  public data: any = {};
  public error: any = {};
  public registerStatus;
  public registerStatusMessage;
  public showRegisterStatus = false;
  nodata: boolean = true;
  dltclk: boolean = false;
  public hideTable = true;
  selectedItemId: any;
  clickedRowCoordinates: { top: number, left: number } = { top: 0, left: 0 };
  selectedItemName: any;
  editclk: boolean = false;
  addclk: boolean = false;
  regionnames: any= [];countryname: string;
  regionname: string;
  countrynamesadd: any = [];
  regionnamesadd: any = [];
  Allregionandcountries: any =[];
  selectedItememail: any;
  hide = true;
  hide1 = true;
  public pageNo: number = 1;
  public pageLimit: number = 5
  public totalCount: number;
  totalEntries: number;
  public showdata = [5, 10, 25, 50, 100, 500, 1000];

  //datatable
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public dtInitialied = false;
  adminrole: string;

  @ViewChild('cardElement') cardElementRef: ElementRef;
  constructor(private http: HttpClient,
    public auth: FileService,
    public router: Router,
    public formBuilder: FormBuilder,
    private modalService: BsModalService,
    private dialogService: DialogService
  ){
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 5,
    //   // searching: false,
    //   scrollX: true,
    //   scrollY: "60vh",
    //   lengthMenu: [10, 25, 50, 100, 500, 1000],
    // };
    console.log("dtoption called")
    this.dtInitialied = false;
    this.hideTable = true;
  }
  @ViewChild(DataTableDirective) dataTableDirective: DataTableDirective;
  // @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  ngOnInit(): void {
    document.body.addEventListener('click', this.handleBodyClick.bind(this));
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      // initComplete: function () {
      //   // Customizing the search box
      //   $('.dataTables_filter input').addClass('custom-search-input');
      //   $('.custom-search-input').attr('placeholder', 'Type to search...'); // Replace 'Type to search...' with your desired placeholder text
      // }
    };
    this.countryadmin = this.formBuilder.group({
      selectedAdmin: [""]
    });
    this.getallCountryAdmins(this.pageLimit, this.pageNo)
    this.getallregionsandcountrys()
    localStorage.setItem('adminlisting', "CountryAdmin");
    this.adminrole = localStorage.getItem('adminrole')
    if (this.adminrole == 'regionaladmin') {
      this.country = '';
      this.regionname = localStorage.getItem('regionname')
      this.updates()
      console.log(this.data,"dddd")
    } else {
      this.country = '';
      this.regionname = ''
    }
    // this.getallCountryAdmins(this.pageLimit, this.pageNo)
    // this.getallregionsandcountrys()
  }


  // reinitializeDatatable(data) {
  //   this.hideTable = false;
  //   console.log(this.dtElement,"reeeeeeeeeeeeeeeeeee")
  //   if (this.dtInitialied) {
  //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //       dtInstance.destroy();
  //       this.AllCountryadmin = data;
  //       console.log( this.AllCountryadmin,"in datatableee")
  //       this.dtTrigger.next();
  //     });
  //   }
  //   else {
  //     this.dtInitialied = true;
  //     this.AllCountryadmin = data;
  //     console.log( this.AllCountryadmin,"in datatableee")
  //     this.dtTrigger.next();
  //   }
  // }

  onSearchChange(searchValue: string) {
    if (searchValue.length > 0) {
      this.auth.getTVInformation({limit: this.pageLimit, index: this.pageNo, adminname: searchValue }, '/getAllcountryadmin')
        .then((res: any) => {
          if (res && res.body.data) {
            this.AllCountryadmin = res.body.data;
            this.totalEntries = res.body.count;
            this.totalCount = Math.ceil(res.body.count / this.pageLimit);
          } else {
            this.AllCountryadmin = [];
          }
        }, (err) => {
          console.error(err);
        });
    } else {
      // When the search input is cleared, load all data again
      this.getallCountryAdmins(this.pageLimit, this.pageNo);
    }
  }

  getallCountryAdmins(lmt, indx) {
    let data = {
      limit: lmt,
      index: indx,
      regionname:this.regionname
    }
    this.adminnames =[]
    this.countryadmin = this.formBuilder.group({
      selectedAdmin: [""]
    });
    this.auth.getTVInformation(data, '/getAllcountryadmin')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                this.nodata = false
                console.log(res, "ressssssssss")
                this.AllCountryadmin = res.body.data;
                this.totalEntries = res.body.count
                this.totalCount = Math.ceil(res.body.count / lmt)
                console.log(this.totalEntries, this.totalCount, "countt")

                this.countrynames=this.countrynames.sort();

                res.body.data.map(one => {
                  this.adminnames.push(one.name)
                  // this.countrynames.push(one.countryname)
                })
                // this.reinitializeDatatable(res.body.data)
              } else {
                this.nodata = true
              }
            } else {
              this.nodata = true
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  AddCountryAdmin() {
    this.data = {}
    this.editclk = false;
    this.addclk = true
    if (this.adminrole == 'regionaladmin') {
      this.country = '';
      this.regionname = localStorage.getItem('regionname')
      this.data.regionname = localStorage.getItem('regionname')
      this.updatecountrys()
      console.log(this.data,"dddd")
    } else {
      this.country = '';
      this.regionname = ''
    }
    this.updatecountrys()
    this.save_exit_ref = this.modalService.show(this.modalContent, {
      class: 'modal-md-lg-sm',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
  }

  closeModal() {
    this.isModalOpen = false;
    this.save_exit_ref.hide();
  }

  SearchClick() {
    this.nodata = false
    let lmt =  this.pageLimit
    let indx = this.pageNo
    let data = {
      limit: lmt,
      index: indx,
      adminname: this.admin,
      countryname: this.country ,
      regionname:this.regionname
    }
    this.auth.getTVInformation(data, '/getAllCountryadmin')
      .then(
        (res: any) => {
          try {
            if (res) {
              console.log(res, "ressssssssss")
              this.AllCountryadmin = res.body.data;
              this.totalEntries = res.body.count
              this.totalCount = Math.ceil(res.body.count / lmt)
              console.log(this.totalEntries, this.totalCount, "countt")
            } else {
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }

  openModal() {
    this.isModalOpen = true;
  }

  validateRegisterUser() {
    var errors_found = false;
    this.error = {};
    this.error.firstnameNotValid = false;
    this.error.countrynameNotValid = false;
    this.error.emailidNotValid = false;
    this.error.lastnameNotValid = false;
    this.error.addressNotValid = false;
    this.error.regionameNotValid = false;
    this.error.passwordNotValid = false;
    this.error.confirmpasswordNotValid = false;


    this.data.firstname = this.data.firstname ? this.data.firstname.trim() : "";
    this.data.lastname = this.data.lastname ? this.data.lastname.trim() : "";
    this.data.address = this.data.address ? this.data.address.trim() : "";
    this.data.countryname = this.data.countryname ? this.data.countryname.trim() : "";
    this.data.emailid = this.data.emailid ? this.data.emailid.trim() : "";
    this.data.regionname = this.data.regionname ? this.data.regionname.trim() : "";
    this.data.password = this.data.password ? this.data.password.trim() : "";
    this.data.confirmpassword = this.data.confirmpassword ? this.data.confirmpassword.trim() : "";


    if (this.data.firstname.length == 0) {
      this.error.firstnameNotValid = true;
      this.error.firstnameNotValidText = "Please enter the firstname";
      errors_found = true;
    }
    if (this.data.lastname.length == 0) {
      this.error.lastnameNotValid = true;
      this.error.lastnameNotValidText = "Please enter the lastname";
      errors_found = true;
    }

    if (this.data.countryname.length == 0) {
      this.error.countrynameNotValid = true;
      this.error.countrynameNotValidText = "Please select the countryname";
      errors_found = true;
    }
    if (this.data.emailid.length == 0) {
      this.error.emailidNotValid = true;
      this.error.emailidNotValidText = "Please enter the emailid";
      errors_found = true;
    }
    if (this.data.address.length == 0) {
      this.error.addressNotValid = true;
      this.error.addressNotValidText = "Please enter the address";
      errors_found = true;
    }
    if (this.data.regionname.length == 0) {
      this.error.regionnameNotValid = true;
      this.error.regionnameNotValidText = "Please select the regionname";
      errors_found = true;
    }
    if (this.data.confirmpassword.length == 0) {
      this.error.confirmpasswordNotValid = true;
      this.error.confirmpasswordNotValidText = "Please enter the confirm password";
      errors_found = true;
    }

    if (this.data.password != this.data.confirmpassword) {
      this.error.confirmpasswordNotValid = true;
      this.error.confirmpasswordNotValidText = "Password and confirm password does not match";
      errors_found = true;
    }


    if (errors_found) {
      return;
    } else {
      if (this.addclk) {
        var self = this;
        // // self.validatemailid(self, self.data)
        //   .then((data) => self.saveRegisterUserDetails(self, data))
          // .catch((data) => console.log(" Exception during handling promise: ", data))
          self.saveRegisterUserDetails(self, self.data)
      }
      if (this.editclk) {
        this.saveEditedDetails()
      }
    }
  }



  validatemailid(self, data) {
    // data.self = this;
    return new Promise(
      (resolve, reject) => {
        self.auth
          .getTVInformation({ 'firstname': data.emailid }, '/validateemailid')
          .then(
            (res: any) => {
              if (res.body.status === "ok") {
                resolve(data);
              }else if(res.body.status == 'emailid already exist'){
                this.dialogService.showError("Email ID already exists. Please use the same password.")
              } else {
                console.log("Checking firstname: ", data.firstname,
                  " response: ", res);
                self.registerStatus = 'fail';
                self.registerStatusMessage = res.body.message;
                self.showRegisterStatus = true;
                reject(data);
              }
            }, (err: any) => {
              console.log("Error checking firstname: ", data.firstname,
                " response: ", err);
              reject(data);
            });
      });
  }


  saveRegisterUserDetails(self, data) {
    return new Promise(
      (resolve, reject) => {
        self.auth
          .getTVInformation(
            {
              firstname: data.firstname,
              password: data.password,
              countryname: data.countryname,
              regionname: data.regionname,
              emailid: data.emailid,
              lastname: data.lastname,
              address: data.address,
                //for ums
                userId : data.emailid,
                //password
                name : data.firstname + " " + data.lastname,
                role :'countryadmin',
                profileInfo : ' ',
                signature:' '
              // superadmin_id : localStorage.getItem('superadmin_id'),
            }
            , '/createcountryadmin')
          .then((res: any) => {
            try {
              console.log("Statuscheck: ", res);
              if(res.body.status == 'success'){
              self.registerStatus = res.body.status;
              self.registerStatusMessage = res.body.reason;
              self.showRegisterStatus = true;
              resolve(data);
              this.getallCountryAdmins(this.pageLimit, this.pageNo)
              this.save_exit_ref.hide();
              // this.router.navigate(['/superadmin'])
              }else if(res.body.status == false){
                if(res.body.reason == 'wrongpassword'){
                  this.dialogService.showError("Email ID already exists. Please use the same password.")
                }
                if(res.body.reason == 'sameuser'){
                  this.dialogService.showError("The same email ID exists. Please use another one.")
                }
              }else{
                reject(data);
              }
            } catch (e) {
              console.error(e);
              reject(data);
            }
          }, (err: any) => {
            console.error(err);
            reject(data);
          })
      });
  }

  handleTdClick(event: any, itemId: any ,name :any ,Event:Event) {
    // Toggle the selectedItemId to show/hide the card for the clicked row
    Event.stopPropagation();
    this.selectedItemId = this.selectedItemId === itemId ? null : itemId;
    this.selectedItemName = this.selectedItemName === name ? null : name;
    this.selectedItememail = event.emailid
    this.clickedRowCoordinates = { top: event.clientY, left: event.clientX };
    // Additional logic if needed
  }

  delete() {
    this.dltclk = true
    console.log(this.selectedItemId)
    console.log("General Delete");
    if (this.selectedItemId) {
      var yes = confirm("Are you sure you want to delete Admin? = " + this.selectedItemName);
      console.log(yes)
      if (yes == true) {
        this.auth.getTVInformation(
          { selectedid: this.selectedItemId ,userId:this.selectedItememail }, '/deletecountryadmin').then((res: any) => {
            try {
              if (res && res.status) {
                console.log(res.data, "inside dlt")
                this.dialogService.showSimplePopup("Deleted successfully.");
                this.getallCountryAdmins(this.pageLimit, this.pageNo)
              } else {
                console.error("General error");
              }
            } catch (e) {
              console.error(e);
            }
          }, (err: any) => {
            console.error(err);
          });
      } else {
        // this.AdminCheckboxListArray = [];
      }
    }
    else {
      this.dialogService.showError("Select something to delete!")
    }

  }


  edit(){
    console.log("Calling WOEditClick HTTP server regionnn")
    this.editclk = true
    this.addclk = false
    this.auth.getTVInformation({ 'id': this.selectedItemId }, '/getcountryadmin').then((res: any) => {
      try {
        console.log(res, "edit ress region")
        if (res) {
          if (res.body.status === true) {
            this.save_exit_ref = this.modalService.show(this.modalContent, Object.assign({}, { class: "modal-md-lg-sm" }));
            this.data = res.body.data;
            this.data['firstname'] = res.body.data['firstname'];
            this.data['lastname'] = res.body.data['lastname'];
            this.data['emailid'] = res.body.data['emailid'];
            this.data['address'] = res.body.data['address'];
            this.data['countryname'] = res.body.data['countryname'];
            this.data['confirmpassword'] = res.body.data['password'];
            this.updatecountrys()
            console.log("Selected resData2: ", this.data);
          }
        } else {
          this.data = {};
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  saveEditedDetails() {
    console.log("saveBPDetails Data : ", this.data);
    this.auth.getTVInformation({"data":this.data}, '/editcountryadmin').then((res: any) => {
      try {
        console.log("Status: ", res);
        if (res.body.status == true) {
          // this.router.navigate(['/countryadmin'])
          this.save_exit_ref.hide()
          this.getallCountryAdmins(this.pageLimit, this.pageNo)
        } else {
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  EditCancel(){
    this.save_exit_ref.hide()
  }

  getallregionsandcountrys() {
    this.auth.getTVInformation({}, '/getallregionsandcountrys')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                console.log(res.body.data ,"regioncountrymapping")
                this.Allregionandcountries = res.body.data
                //this.regionnames.push('All regions');
                console.log(this.countrynames,"this.....")
                this.countrynames=this.countrynames.sort();
                this.countrynames.push('All countries')
                console.log(this.countrynames,"this.countryname bandana2")
                res.body.data.map(one => {
                  this.regionnames.push(one.Region)
                  this.regionnamesadd.push(one.Region)
                  this.countrynames.push(one.Country);
                  this.countrynames=this.countrynames.sort();
                   this.updates();
                })
                this.regionnames = [... new Set(this.regionnames)]
                this.regionnamesadd = [... new Set(this.regionnamesadd)]
              } else {

              }
            } else {

              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  updatecountrys(){
    this.countrynamesadd =[]
    this.Allregionandcountries.map(one => {
      if (this.data.regionname == one.Region) {
        this.countrynamesadd.push(one.Country)
      }
    })
  }
  // updates(){
  //   this.countrynames =[]
  //   //this.countrynames.push('All countries')
  //   if(this.regionname==='All regions'){
  //     this.countrynames=this.Allregionandcountries.map((item)=>item.Country)
  //   }else{
  //   this.Allregionandcountries.map(one => {
  //     if (this.regionname == one.Region) {
  //       this.countrynames.push(one.Country)
  //     }
  //   })
  // }
  //   this.countrynames=this.countrynames.sort();
  //   this.countrynames.push('All countries')
  //   // this.getallschool()
  //   // this.getallteachers()
  // }



  updates(){
    this.countrynames =[];
    this.countrynamesadd=[];

    if(this.regionname===''){
      this.countrynames=this.Allregionandcountries.map((item)=>item.Country);
      this.countrynamesadd=this.countrynames;
    }else{
    this.Allregionandcountries.map(one => {
      if (this.regionname == one.Region) {
        this.countrynames.push(one.Country);
        this.countrynamesadd.push(one.Country);
      }
    })
  }
    this.countrynames=this.countrynames.sort();
    console.log(this.countrynames,"this.countrynames in updates");


  }

  clearFilters(){
    this.admin = null
    if (this.adminrole == 'regionaladmin') {
      this.country = '';
      this.regionname = localStorage.getItem('regionname')
    } else {
      this.country = '';
      this.regionname = ''
    }
    this.SearchClick()
  }
  clearcross(fieldName: string) {
    // Reset the value of the specified field to an empty string
    this.data[fieldName] = '';
  }

  onOverlayClick(event: MouseEvent): void {
    // Handle the click event inside the modal overlay
    // For example, prevent the modal from closing
    event.stopPropagation();
  }

  handleBodyClick(event: Event) {
    const target = event.target as HTMLElement;

    // Check if the click is outside the card
    if (this.selectedItemId) {
      if (!this.cardElementRef.nativeElement.contains(target)) {
        this.selectedItemId = null; // Hide the card
      }
    }
  }

  //server side pagination
  prevPage() {
    let temp = this.pageNo
    temp -= 1
    if (temp >= 1) {
      this.pageNo -= 1
      this.getallCountryAdmins(this.pageLimit, this.pageNo);;
    }

  }

  nextPage() {
    console.log(this.pageNo, "nextpageeeeeeee")
    let tmp = Number(this.pageNo) + 1
    //console.log(tmp,this.totalCount,"mmmmmmmmmmmm")
    if (tmp <= this.totalCount) {
      this.pageNo = Number(this.pageNo) + 1
      this.getallCountryAdmins(this.pageLimit, this.pageNo);
    }
  }



  nextPageInput() {
    let page_input = this.pageNo;
    if ((page_input <= this.totalCount) && (page_input >= 1)) {
      this.getallCountryAdmins(this.pageLimit, this.pageNo);
    } else {
      this.getallCountryAdmins(this.pageLimit, this.pageNo);
    }
  }

  PageSize(val) {
    this.pageNo = 1
    this.getallCountryAdmins(this.pageLimit, this.pageNo);
  }

  booleanValues: { [key: string]: boolean } = {};
  sortedColumn: string = 'name';
  sortFunction(colName: string) {
    if (this.booleanValues[colName] === undefined) {
      this.booleanValues[colName] = true;
    }

    if (this.booleanValues[colName]) {
      this.AllCountryadmin.sort((a, b) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0);
    } else {
      this.AllCountryadmin.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0);
    }

    this.booleanValues[colName] = !this.booleanValues[colName];
    this.sortedColumn = colName;
  }
}
