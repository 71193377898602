import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileService } from "../file.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  // public token: string;
  // public loginForm;
  loginForm: FormGroup;
  public user;
  token: string;
  newPassword: string;
  confirmPassword: string;

  constructor(private route: ActivatedRoute, private fileservice: FileService, private formBuilder: FormBuilder, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.initForm();
    this.token = this.route.snapshot.params['token'];
    this.user = this.route.snapshot.queryParams;
    
  }

  hide = true;

  initForm() {
    this.loginForm = this.formBuilder.group({
      emailid: ['', Validators.required], // Add validation if needed
      newpassword: ['', Validators.required], // Add validation if needed
      confirmpassword: ['', Validators.required] // Add validation if needed
    });
  }

  resetPassword() {

    //let userRole=this.fileservice.getrole( emailid)
    if (this.loginForm.invalid) {
      return;
    }

    const { emailid, newpassword, confirmpassword } = this.loginForm.value;
    // let userRole=this.fileservice.getrole( emailid)
    // console.log(userRole,"uuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
    if (newpassword !== confirmpassword) {
      this.dialogService.showError('Passwords do not match.');
      return;
    }

    this.fileservice.resetingPassword({
      'userId' : emailid,
      'password': newpassword,
    }).then((response) => {
      console.log('Password reset successfully', response);
      this.dialogService.showSimplePopup('Password reset successfully.');
    }).catch((error) => {
      console.error('Error resetting password:', error);
      this.dialogService.showError('Error resetting password.');
    });
    
    // this.fileservice.resetPassword(this.token, emailid, newpassword, confirmpassword).subscribe(
    //   (response) => {
    //     console.log('Password reset successfully', response);
    //     alert('Password reset successfully.')
    //   },
    //   (error) => {
    //     console.error('Error resetting password:', error);
    //   }
    // );
  }

}
