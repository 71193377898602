import { Component, OnInit,TemplateRef, HostListener, ViewChild,Input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as QRCode from 'qrcode';
import { environment } from 'src/environments/environment';
import { FileService } from '../file.service';
import { TokenGenerationService } from './tokenGenerationService.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BrowserDetectionService } from '../browser-detection.service';
import { DialogService } from '../dialog.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-qrlogin',
  templateUrl: './qrlogin.component.html',
  styleUrls: ['./qrlogin.component.scss']
})
export class QRloginComponent implements OnInit {
  constructor(public router: Router, public auth:FileService,private dialogService: DialogService,
    private tokengeneration:TokenGenerationService,private modalService: BsModalService,private http: HttpClient,
    private browserDetectionService: BrowserDetectionService) { }
  //qrCodeUrl: string = environment.etheradURL+'/fabricang/u&plogin'; // Replace with your desired URL
  //qrCodeDataUrl: string = '';

  qrCodeImageUrl: string;
  public modalRefL: BsModalRef;
  showresolutionerror: boolean = false;
  orientationMediaQuery: MediaQueryList;
  @ViewChild("pdfimgmodal_exit") pdfimgmodal_exit: TemplateRef<any>;
  public pdfimgmodal_exit_ref: BsModalRef;
  @ViewChild("modalContent") modalContent: TemplateRef<any>;
  demosuccess:any
  showmodal:Boolean=true
  inDevice:Boolean=false;
  ShowSubscriptionAlert:Boolean=false;
  submsg:string="";
  isInternetConnected:any;
  ngOnInit(): void{
    let roomId = localStorage.getItem("roomID")
    let userId = localStorage.getItem("userId")
    if (roomId && userId) {
      // Call deleteRoom only if both values exist
      this.deleteRoom(roomId, userId);
    } else {
      console.log("roomID or userId is missing in LocalStorage");
    }
    localStorage.clear();
    
    this.auth.getConnectionStatus().subscribe((status) => {
      this.isInternetConnected = status;
      console.log(this.isInternetConnected,"Bandana Checking internet connection everytime");
    });

    console.log("hiiiii in demo component")
    const userAgentcheck = window.navigator.userAgent.toLowerCase();
    const isAndroid = userAgentcheck.includes("android");
    const isChrome = userAgentcheck.includes("chrome");
    const isMozilla = userAgentcheck.includes("mozilla");
    const isSafari = userAgentcheck.includes("safari");
    const isgecko = userAgentcheck.includes("gecko")

    console.log(userAgentcheck,"Bandana");

    if (isAndroid === true && isChrome === false && isMozilla === false && isSafari === false && isgecko === false) {
      this.inDevice = true
    } else {
      this.inDevice= false;
    }
    this.demosuccess = this.auth.fetchDemoSuccess();
    console.log("demosuccess",this.demosuccess);
    if (this.demosuccess === true) {
      setTimeout(() => {
        this.openmodal();
      }, 0);
    }
    // QRCode.toDataURL(this.qrCodeUrl, (err, url) => {
    //   if (err) {
    //     console.error(err);
    //     return;
    //   }
    //   this.qrCodeDataUrl = url;
    // });

     //const token = this.generateRandomString(4);
     //const sessionId = this.generateRandomString(6);
 this.tokengeneration.generateTokenAndSessionId()
 const token = this.tokengeneration.getToken();
const sessionId = this.tokengeneration.getSessionId();

     console.log("token",token)
     console.log("session",sessionId)
     this.generateQRCode(token, sessionId);


  this.auth.listenForQRcode().subscribe((val:any)=>{
    console.warn({val});
      if(val.token!=token) {
        return;
      }
    console.log("Got login details for MNT device",val);

    this.auth
    .authenticateUser(
        {
            'username': val.username,
            'password': val.password,
        }
    )
    .then((user: any) => {
        console.log("User Management ___________________", user);

        localStorage.setItem('username', user.username);
        localStorage.setItem('type', user.type);
        localStorage.setItem('userId', (user.userId).toLowerCase());
        localStorage.setItem('session_id', user.session_id);
        localStorage.setItem('accessToken', user.session_id);
        localStorage.setItem('refreshToken', user.refreshToken);
        localStorage.setItem('classidavchat', user.classidavchat);


           if (user.language ==undefined){

            localStorage.setItem('lang',"en-Us");
            console.log("null lang",localStorage.getItem("lang"));

           }
       else if(user.language!=undefined){
            localStorage.setItem('lang',user.language);

        }

        if (user.type == 'teacher' || user.type == 'demoaccounts' || user.type == 'schooladmin') {
          console.log("Entered in need accounts Bandana Das")
          this.auth.getTVInformation({ emailid: user.userId, role: user.type }, '/getadmin').then(
            (res: any) => {
              console.log(res ,"Bandana Das response in qrlogin");
              try {
                if (res) {
                  localStorage.setItem("licensetype", res.body.data.licensetype);
                  console.log(res.body.data.enddate, "for licensedetails");

                  var enddateString = res.body.data.enddate
                  var enddate = new Date(enddateString);
                  var today = new Date();
                  var differenceMs = enddate.getTime() - today.getTime();
                  // Convert the difference from milliseconds to days
                  var differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
                  console.log(differenceDays, "differ")
                  console.log(res.body.data.activestatus,res.body.data.licensestatus,"Bandana")
                  if(user.type==='demoaccounts'&& res.body.data.activestatus==="inactive"){
                    console.log("hello");
                    this.ShowSubscriptionAlert = true;
                    this.submsg = "Subscription expired. Please contact admin to renew plan.";
                    localStorage.clear();
                    return;
                  }
                  if ((user.type === 'teacher' || user.type === 'schooladmin') && res.body.data.licensestatus === "inactive") {
                    console.log("hello");
                    this.ShowSubscriptionAlert = true;
                    this.submsg = "Subscription expired. Please contact admin to renew plan.";
                    localStorage.clear();
                    return;
                  }
                  // Determine if the end date is approaching expiration
                  if (differenceDays < 0) {
                    this.ShowSubscriptionAlert = true
                    this.submsg = "Subscription expired. Please contact admin to renew plan."
                    // alert("The subscription has expired.");
                  } else {
                    console.log("The end date is in the future. Days left until expiration:", differenceDays);
                    this.auth.setTriggerState(true);
                    localStorage.setItem("subscription", differenceDays.toString());
                    this.router.navigate(["/whiteboard"]);
                  }
                } else {
                  console.log("no data from backend")
                }
              } catch (e) {
                console.log(e)
              }
            })
        } else {
          this.auth.setTriggerState(true);
          localStorage.setItem("subscription", "100");
          this.router.navigate(["/whiteboard"]);
        }

        // this.collectUserPrefs();
        // this.router.navigate(["/whiteboard"]);
        // this.auth.setTriggerState(true);
    }, (err) => {
        console.log(err);
        localStorage.clear();
        this.dialogService.showError("Invalid credentails.");
    });

  });
  const currentUrl = window.location.href;
  const userAgent = window.navigator.userAgent;
  console.log("User Agent:", userAgent);
  console.log("Current URL:", currentUrl);

  const sessid = this.getQueryParam(currentUrl, 'sessid');
  if (sessid) {
    console.log("Session ID is present from Android");
    this.auth.setSessionId(sessid);
  } else {
    console.log("Either session ID is not present or not able to extract");
  }

  this.checkBrowserSettings();
  this.checkOrientationOnLoad();
  }

  checkOrientationOnLoad(): void {
    console.log('window width and height', window.innerWidth, window.innerHeight);
    if (window.innerHeight > window.innerWidth) {
      console.log('open window alert')
      this.showresolutionerror = true
    } else {
      console.log('close window alert')
      this.showresolutionerror = false;
    }
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
      this.checkOrientationOnLoad();
      // this.resetSideNavWidth()
    }
  

  getQueryParam(url: string, arg1: string) {
    const queryParams = new URLSearchParams(new URL(url).search);
    return queryParams.get(arg1);
    }


  // checkBrowserSettings() {
  //   const browserInfo = this.browserDetectionService.getBrowserInfo();
  //   console.log('Browser Info:', browserInfo);

  //   if ((browserInfo.screenWidth == 4096 && browserInfo.screenHeight == 2160 )||
  //     (browserInfo.screenWidth == 3840 && browserInfo.screenHeight == 2160 )||
  //     (browserInfo.screenWidth == 1920 && browserInfo.screenHeight == 1080)||
  //     (browserInfo.screenWidth == 1536 && browserInfo.screenHeight == 864)||
  //     (browserInfo.screenWidth == 1280 && browserInfo.screenHeight == 720 )
  //     ) {
  //       console.log("screen resolution match");
  //   }
  //   else{
  //     console.log("screen resolution does not match");
  //     this.dialogService.showError('Please use 1080p or 720p resolution for the optimal experience.')
  //     //this.router.navigate(['/browser-warning']);
  //   }
  // }

  checkBrowserSettings() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    console.log("gvgvgvgvg",screenWidth,screenHeight)

    this.http.post(environment.serverurl+'/api/check-resolution', { screenWidth, screenHeight }).subscribe((response: any) => {
      if (response.match) {
        console.log("Screen resolution match");
      } else {
        console.log("Screen resolution does not match");
        //this.dialogService.showError('Please use higher resolution for the optimal experience.');
        // this.router.navigate(['/browser-warning']);
      }
    }, (error) => {
      console.error('Error checking resolution:', error);
    });
  }

  generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  generateQRCode(token: string, sessionId: string): void {
   // const lc = "http://localhost:4664"
    const url = `${environment.joinavurl}/ppdslogin?token=${token}&sessionId=${sessionId}`;
   // const url = `${lc}/fabricang/#/u&plogin?token=${token}&sessionId=${sessionId}`;


    QRCode.toDataURL(url, (err, qrCodeUrl) => {
      if (err) {
        console.error('Error generating QR code:', err);
        return;
      }
      console.log("no error",qrCodeUrl)
      this.qrCodeImageUrl = qrCodeUrl;
    });
    console.log("qrcodeurl",url)
  }

  userpass(event: Event) {
    event.preventDefault();
    //this.router.navigate(['/u&plogin']);
    this.router.navigate(['/u&plogin']);

  }


  openmodal(){
    console.log("Hello popup for demoaccount")
      this.pdfimgmodal_exit_ref = this.modalService.show( this.pdfimgmodal_exit, {
      class: 'setheight',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
  }




  closeModal() {
   // this.isModalOpen = false;
    this.pdfimgmodal_exit_ref.hide();
    this.auth.updatedemostatus(false);
  }



  opendemoform:Boolean=false
  opendemoaccountForm(){
    if(this.inDevice){
     this.opendemoform=true
    }else{
      this.router.navigate(['/demoemail']);
    }
//this.opendemoform=true;
  }


  closeDemoForm(){
    this.opendemoform=false;
  }


  recievedclose(val){
this.closeDemoForm();
  }

  closeAlert(){
    this.ShowSubscriptionAlert=false;
  }

  checkConnection(){
    console.log("checkconnection");
    this.auth.getConnectionStatus().subscribe((status) => {
      this.isInternetConnected = status;
      console.log(this.isInternetConnected,"Bandana Checking internet connection everytime");
    });
  }
  deleteRoom(roomid:any,userid:any) {
    console.log("______________--delete Room funcion exe ______________________-");

    // this.createClass = false;
    // this.m1 = false;
    // this.disposeAllSlots();
    this.auth
        .getTVInformation(
            { roomId: roomid, userId: userid },
            "/deleteRoom"
        )
        .then(
            (res: any) => {
                try {
                    if (res) {
                        console.log("resData", res);
                        if (res.deleteRoom === "success") {
                        }
                    } else {
                        console.error("General error");
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            (err: any) => {
                console.error(err);
            }
        );
}

}
