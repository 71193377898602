import { Component, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FabricjsEditorComponent } from 'projects/angular-editor-fabric-js/src/public-api';
import { UploadmediaComponent } from '../uploadmedia/uploadmedia.component';
import { WebuploadComponent } from '../webupload/webupload.component';
import { YoutubeComponent } from '../youtube/youtube.component';
import { FileService } from '../file.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EtherpadkmsComponent } from '../etherpadkms/etherpadkms.component';
import { VirtualClassComponent } from '../virtual-class/virtual-class.component';
import { TeacherkmsComponent } from '../teacherkms/teacherkms.component';
import { Router } from '@angular/router';

//list all canvas
interface JsonItem {
  id: string;
  filename: string;
  originalfilename: string;
  pathUrl: string;
}



@Component({
  selector: 'app-firstscreen',
  templateUrl: './firstscreen.component.html',
  styleUrls: ['./firstscreen.component.scss']
})


export class FirstscreenComponent {
  @ViewChild(EtherpadkmsComponent) public etherpadkms: EtherpadkmsComponent;
  @ViewChild(TeacherkmsComponent) public teacherkms: TeacherkmsComponent;
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService,
    private http: HttpClient,
    private fileService: FileService,
    public router: Router
    ){}

  title = 'angular-editor-fabric-js';

  @ViewChild('canvas', { static: false }) canvas: FabricjsEditorComponent;

  filename: string = ''; // Initialize with an empty string
  jsonList: JsonItem[] = []; // Store the list of JSON items
  selectedJsonUrl: string = ''; //list , Store the selected JSON URL
  historyIndex = -1;
  history: Array<any> = [];
  json1: Array<any> = []
  vvv: Array<any> = []

  public icon2 = false;
  colVal: string;
  footerVal: string;


  //public
  jsonList_public: JsonItem[] = []; // Store the list of JSON items
  selectedJsonUrl_public: string = ''; //list , Store the selected JSON URL


    //private
    jsonList_private: JsonItem[] = []; // Store the list of JSON items
    selectedJsonUrl_private: string = ''; //list , Store the selected JSON URL



    //filter
    selectedFileType: string = 'all';



  public rasterize() {
    this.canvas.rasterize();
  }

  public rasterizeSVG() {
    this.canvas.rasterizeSVG();
  }

  public saveCanvasToJSON() {
    this.canvas.saveCanvasToJSON();
  }

  public loadCanvasFromJSON() {
    this.canvas.loadCanvasFromJSON();
  }

  public confirmClear() {
    this.canvas.confirmClear();
  }

  public changeSize() {
     this.canvas.onResize(event);
  }

  public addText() {
    this.canvas.addText();
    this.showaddtext=false;
  }

  showaddtext:boolean=false;
  showtextinput(){
    this.showaddtext=!this.showaddtext;
    this.isShowShape=false;
    this.isShowPen=false;
  }



  //lession
  leeeeeesssss:boolean=false;
  showsavelessioninput(){
    this.leeeeeesssss=!this.leeeeeesssss;
    this.isShowShape=false;
    this.isShowPen=false;
  }




  lessionsave() {
    const canvasObjects = this.canvas.canvas.getObjects();
  console.log("File----->",this.filename)
    const apiUrl = environment.serverurl+"/save-canvas"//'http://localhost:5000/save-canvas';
    // const apiUrl = "http://localhost:4000/save-canvas"//'http://localhost:5000/save-canvas';
    const requestBody = {
      filename: this.filename, // Use the dynamically updated filename
      ownerId: '55',
      subjectId: '55',
      classId: '55',
      canvas: {
        version: '1.6.6',
        objects: canvasObjects // Get the current canvas objects dynamically
      }
    };



    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http.post(apiUrl, requestBody, httpOptions).subscribe(
      (response: any) => {
        console.log('API Response:', response);
        this.showsavelessioninput();
        // Handle success
      },
      (error: any) => {
        console.error('API Error:', error);
        // Handle error
      }
    );
  }

  public getImgPolaroid(event) {
    this.canvas.getImgPolaroid(event);
  }

  public addImageOnCanvas(url) {
    this.canvas.addImageOnCanvas(url);
  }

  public addbgImageOnCanvas() {
    this.canvas.addbgImageOnCanvas();
  }

  public selectobj(){
    console.log("first")
    this.icon2=!this.icon2;
    this.canvas.selectobject();
  }
  // public addVideoToCanvas(url) {
  //   this.canvas.addVideoToCanvas(url);
  // }

  public readUrl(event) {
    this.canvas.readUrl(event);
  }

  public removeWhite(url) {
    this.canvas.removeWhite(url);
  }

  public addFigure(figure) {
    this.canvas.addFigure(figure);
  }

  public removeSelected() {
    this.canvas.removeSelected();
  }

  public sendToBack() {
    this.canvas.sendToBack();
  }

  public bringToFront() {
    this.canvas.bringToFront();
  }

  public clone() {
    this.canvas.clone();
  }

  public cleanSelect() {
    this.canvas.cleanSelect();
  }

  public setCanvasFill() {
    this.canvas.setCanvasFill();
  }

  public setCanvasImage() {
    this.canvas.setCanvasImage();
  }

  public setId() {
    this.canvas.setId();
  }

  public setOpacity() {
    this.canvas.setOpacity();
  }

  public setFill() {
    this.canvas.setFill();
  }

  public setstroke(){
    this.canvas.setstroke();
  }

  public setFontFamily() {
    this.canvas.setFontFamily();
  }

  public setTextAlign(value) {
    this.canvas.setTextAlign(value);
  }

  public setBold() {
    console.log("bold function called in app.component")
    this.canvas.setBold();
  }

  public setFontStyle() {
    console.log("setfont function called in app.component")
    this.canvas.setFontStyle();
  }

  public hasTextDecoration(value) {
    this.canvas.hasTextDecoration(value);
  }

  public setTextDecoration(value) {
    this.canvas.setTextDecoration(value);
  }

  public setFontSize() {
    this.canvas.setFontSize();
  }

  public setLineHeight() {
    this.canvas.setLineHeight();
  }

  public setCharSpacing() {
    this.canvas.setCharSpacing();
  }

  public rasterizeJSON() {
    this.canvas.rasterizeJSON();
  }

  public drawMode() {
    this.canvas.drawingMode();
  }

  public pencil(){
    // this.canvas.selectBresh();
  }

  public multidraw(){
    this.canvas.multitouchdraw();
  }

  public spray(){
    console.log("spray function callling")
    // this.canvas.selectcirbrush();
  }

  public erasebrush(){
    console.log("erasebrush function callling")
    this.canvas.eraseObject();
  }

  public deleteobject(){
    this.canvas.deleteActiveObject();
  }

  public clearcanvas(){
    this.canvas.clearAll();
  }

  public remdiv(){
    this.canvas.removeDiv();
  }

  public adddiv(){
    console.log("dddddddddddddd")
    this.canvas.addDiv();
  }

  isShowDiv:boolean=false;
  texticon(){
    console.log("ssssssss")
    this.isShowDiv=!this.isShowDiv;
    this.isShowBold=false;
    this.isShowAlignment=false;
    this.isShowList=false;
  }
  closetext(){
    this.isShowDiv=false;
    this.isShowBold=false;
    this.isShowAlignment=false;
    this.isShowList=false;
  }

  isShowPen:boolean=false;
  pendraw(){
    this.showaddtext=false;
    this.isShowShape=false;
    this.isShowPen=!this.isShowPen
    // this.canvas.closepend();
  }

  isShowBold:boolean=false;
  bold(){
    this.isShowBold=!this.isShowBold
    this.isShowAlignment=false;
    this.isShowList=false;
  }

  isShowAlignment:boolean=false
  align(){
    this.isShowAlignment=!this.isShowAlignment
    this.isShowBold=false;
    this.isShowList=false;
  }
  isShowList:Boolean=false;
  listal(){
    this.isShowList=!this.isShowList;
    this.isShowAlignment=false;
    this.isShowBold=false;
  }

  isShowShape:boolean=false;
  shapeslist(){
    this.showaddtext=false;
    this.isShowPen=false;
    this.isShowShape=!this.isShowShape
  }

  addPulicLibrary() {
    this.modalRef = this.modalService.show(UploadmediaComponent,  {initialState: {}, class: 'modal-xl setHeight'} );
    if (this.modalRef.content && this.modalRef.content.onClose) {
    this.modalRef.content.onClose.subscribe(res => {
            console.log("uploadmedia res",res)
            if(res.type=="3d"){
              this.canvas.add3dimage(res)
            }
            else if(res.type=="video"){
              this.canvas.addvideodiv(res);
            }
            else{
              this.addImageOnCanvas(res);
            }

            if (res == 'false') {

            } else {

            }
        });
      }
    }

    studentavchat(){
      this.modalRef = this.modalService.show(VirtualClassComponent,  {initialState: {}, class: 'modal-xl setHeight'} );
      if (this.modalRef.content && this.modalRef.content.onClose) {
      this.modalRef.content.onClose.subscribe(res => {
              console.log("virtual class component111111111111111",res)
              if (res == 'false') {

              } else {

              }
          });
        }
      }



  favcolor: string;
  thick:string;
  selectColour() {
    console.log("selectcolour calling")
    document.getElementById('colorpicker').click()
  }


  // changeColor(data) {
  //   console.log("vvvvvvvvvvvv",data)
  //   this.canvas.changeclr(data);
  // }

  // changethickness(data){
  //   console.log("gggggggg",data)
  //   //this.canvas.hhhhhh(data)
  // }

  public selectPenCil() {
    this.canvas.selectPenCil();
  }
  // public selectspray() {
  //   this.canvas.selectspray();
  // }
  public selectcirbrush() {
    this.canvas.selectcirbrush();
  }

  public hightlighter(){
    this.canvas.selecthighlighter()
  }

  closepen(){
    this.canvas.closepend();
    this.isShowPen=false;
  }

  public colorchanged(data) {
    console.log("colorchange calling")
    this.canvas.colorchanged(data);
  }

  public thicknesschanged(data){
    console.log("changing thickness")
    this.canvas.thick(data);
  }



  webupcanv() {
    this.modalRef = this.modalService.show(WebuploadComponent, {initialState: {}, class: 'modal-xl setHeight'} );
    this.modalRef.content.onClose.subscribe(res => {
    console.log("##########",res)
    if (res == 'false') {
            console.log("sameer")
        } else {
          this.canvas.addwebpage(res)
        }
    });
}

dragele(){
  this.canvas.dragiframe();
}

isShowWeb:boolean=false;
web1() {
  this.isShowWeb = !this.isShowWeb;
}

searchYoutube() {
  console.log("inside syt")
  this. modalRef = this.modalService.show(YoutubeComponent, { initialState: {}, class: 'modal-xl setHeight' });
  this.modalRef.content.onClose.subscribe(res => {
    console.log("%%%%%%%%%%%%",res);

      if (res == 'false') {
          console.log("res false in youtube component")
      } else {
          this.canvas.addyoutubevideo(res)
      }

  });
}

isDividePage:boolean=false;
  openavchat(){
    console.log("inside opneavchat fun")
    this.isDividePage=true;
    this.devideKMSVal()
    this.etherpadkms.openSelectClass()
    }

    devideKMSVal(){
      if(this.isDividePage ){
        this.colVal = 'col-md-7'
        this.footerVal = 'col-7'
      }
    }

    isDividePageforteacher:boolean=false;
    hideavvvv:boolean=false;
  openavchatforteacher(){
    console.log("inside opneavchat fun",this.isDividePageforteacher)


    if (this.isDividePageforteacher==true){
      this.hideavvvv=!this.hideavvvv

    }
    else{
      this.isDividePageforteacher=true;
      this.devideKMSValforteacher()
      this.teacherkms.openSelectClass()
    }

    }

    devideKMSValforteacher(){
      if(this.isDividePageforteacher ){
        this.colVal = 'col-md-7'
        this.footerVal = 'col-7'
      }
    }


showfilelist:boolean=false;
savedlesson(){
  this.showfilelist= !this.showfilelist;
}

//Method to fetch the JSON list from the API
// fetchJsonList(): void {
//   console.log("inside fetch",this.jsonList)
//   const apiUrl = 'http://localhost:5000/get-all-canvas';//"https://inblrlxapp003.tpvaoc.com/iwbNode/get-all-canvas";

//   fetch(apiUrl)
//     .then(response => response.json())
//     .then(data => {
//       this.jsonList = data;
//       console.log("jsonlist",this.jsonList)
//     })
//     .catch(error => {
//       console.error('Error fetching JSON list:', error);
//     });
// }


// fetchJsonList(): void {
//   this.fileService.getsavedlesson().subscribe(
//     (response: any) => {
//       console.log("Res--->",response)

//       this.jsonList = response();
//       console.log("list",this.jsonList)
//     },
//     (error: any) => {
//       console.error(error);
//     }
//   );
// }


fetchJsonList(): void {
  this.fileService.getalllesson().subscribe(
    (response: any) => {
      console.log("Res--->", response);

      this.jsonList = response; // Assign the response directly to jsonList
      console.log("all list", this.jsonList);
    },
    (error: any) => {
      console.error(error);
    }
  );
}


fetchJsonList_public(): void {
  this.fileService.getlesson_public('',1).subscribe(
    (response: any) => {
      console.log("Res--->", response);

      this.jsonList_public = response; // Assign the response directly to jsonList
      console.log("list_public", this.jsonList_public);
    },
    (error: any) => {
      console.error(error);
    }
  );
}


fetchJsonList_private(): void {
  let data = localStorage.getItem("userID")
  this.fileService.getlesson_private(data,'',1).subscribe(
    (response: any) => {
      console.log("Res--->", response);

      this.jsonList_private = response; // Assign the response directly to jsonList
      console.log("list_private", this.jsonList_private);
    },
    (error: any) => {
      console.error(error);
    }
  );
}



    //public
    loadSelectedJson_public(): void {
      if (!this.selectedJsonUrl_public) {
        console.error('Please select a JSON file from the dropdown.');
        return;
      }

      console.error('Please select a JSON file from the dropdown.');

      fetch(this.selectedJsonUrl_public)
        .then(response => response.json())
        .then(data => {
          // Clear the canvas before restoring the properties
          this.canvas.canvas.clear();

          this.canvas.canvas.loadFromJSON(data, () => {
            this.canvas.canvas.renderAll();
          });
        })
        .catch(error => {
          console.error('Error loading JSON from URL:', error);
        });
    }



            //private
            loadSelectedJson_private(): void {
              if (!this.selectedJsonUrl_private) {
                console.error('Please select a JSON file from the dropdown.');
                return;
              }
              console.error('the dropdown.',this.selectedJsonUrl_private);
              fetch(this.selectedJsonUrl_private)
                .then(response => response.json())
                .then(data => {
                  // Clear the canvas before restoring the properties
                  this.canvas.canvas.clear();

                  // Restore the canvas properties from the fetched JSON data
                  this.canvas.canvas.loadFromJSON(data, () => {
                    this.canvas.canvas.renderAll();
                  });
                })
                .catch(error => {
                  console.error('Error loading JSON from URL:', error);
                });
            }



            // Method to get the JSON list based on selected file type
                    getSelectedJsonList(): JsonItem[] {
                      if (this.selectedFileType === 'all') {
                        return this.jsonList;
                      } else if (this.selectedFileType === 'public') {
                        return this.jsonList_public;
                      } else if (this.selectedFileType === 'private') {
                        return this.jsonList_private;
                      } else {
                        return [];
                      }
                    }


//cache
saveCache(): void {
  const canvasJson = JSON.stringify(this.canvas.canvas.toJSON());
  localStorage.setItem('savedCanvasData', canvasJson);
}


loadCache(): void {
  const savedCanvasData = localStorage.getItem('savedCanvasData');
  if (savedCanvasData) {
    const parsedData = JSON.parse(savedCanvasData);
    this.canvas.canvas.clear();
    this.canvas.canvas.loadFromJSON(parsedData, () => {
      this.canvas.canvas.renderAll();
    });
  } else {
    console.error('No saved canvas data found.');
  }
}



// // Method to load the selected JSON into the canvas
// loadSelectedJson(): void {
//   if (!this.selectedJsonUrl) {
//     console.error('Please select a JSON file from the dropdown.');
//     return;
//   }

//   fetch(this.selectedJsonUrl)
//     .then(response => response.json())
//     .then(data => {
//       // Clear the canvas before restoring the properties
//       this.canvas.canvas.clear();

//       // Restore the canvas properties from the fetched JSON data
//       this.canvas.canvas.loadFromJSON(data, () => {
//         this.canvas.canvas.renderAll();
//       });

//       // Save the fetched JSON data to history for undo/redo functionality
//       this.history.push(JSON.stringify(data));
//       this.historyIndex = this.history.length - 1;
//     })
//     .catch(error => {
//       console.error('Error loading JSON from URL:', error);
//     });
// }



loadSelectedJson(): void {
  if (!this.selectedJsonUrl) {
    console.error('Please select a JSON file from the dropdown.');
    return;
  }

  fetch(this.selectedJsonUrl)
    .then(response => response.json())
    .then(data => {
      // Clear the canvas before restoring the properties
      this.canvas.canvas.clear();

      // Restore the canvas properties from the fetched JSON data
      this.canvas.canvas.loadFromJSON(data, () => {
        this.canvas.canvas.renderAll();
      });
    })
    .catch(error => {
      console.error('Error loading JSON from URL:', error);
    });
}

logout(){

  this.fileService.getTVInformation({}, '/user/logout').then((res: any) => {
      try {
          if (res && res.status) {
              localStorage.clear();
              this.router.navigate(['/qrlogin']);
          }
      } catch (e) {
          console.error(e);
      }
  }, (err: any) => {
      console.error(err);
  })
}



}
