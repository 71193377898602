import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { GoogleAuthService } from "../google-auth.component";

@Component({
    template:`<div></div>`
})
export class SignoutCallbackComponent implements OnInit{

    constructor(private _authService: GoogleAuthService, private _router: Router) {
    }

    ngOnInit(): void {
        this._authService.signOutComplete().then((res)=> {
            console.log(res);
            this._router.navigate(['/whiteboard'], {replaceUrl: true});
            
        });
    }

}