import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import * as fabric from "../../lib/fabric.min.js";
import { environment } from "src/environments/environment.js";

declare const fabric: any;

@Component({
  selector: "app-documentwindow",
  templateUrl: "./document-window.component.html",
  styleUrls: ["./document-window.component.scss"],
})
export class DocumentWindowComponent implements OnInit {
  @Input() windows: any;
  @Output() removeDocWindowEvent = new EventEmitter<any>();
  @Output() handleWindowSnipEvent = new EventEmitter<any>();
  fileName: any
  selectedSize: string = "M";
  baseImageURL: string;
  isLoadingCanvas: boolean = false;
  listOfAllPages = [];
  isToggleViewPagesMode: boolean = false;
  isAspectRatioHorizontal: boolean = false;
  // windowSizes = {
  //   S: { height: 340, width: 262 },
  //   M: { height: 485, width: 375 },
  //   L: { height: 582, width: 450 },
  //   XL: { height: 672, width: 519 }, // Increased by 20% from L size
  //   XXL: { height: 837, width: 648 }, // Increased by 20% from XL size
  // };
  windowSizes: any;

  canvas: fabric.Canvas;
  activePageNo: number = 1;
  totalPages: number;
  canvasId: string;
  trial = 1;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.canvasId = `canvasElement_${Math.random()
      .toString(36)
      .substring(2, 9)}`;

      this.windowSizes = {
        S: { height: 340, width: 262 },
        M: { height: 485, width: 375 },
        L: { height: 582, width: 450 },
        XL: { height: 776, width: 600 }, // Increased by 20% from L size
        XXL: { height: 837, width: 648 }, // Increased by 20% from XL size
      };
  }

  ngOnInit(): void {
    this.totalPages = this.windows.totalPages;
    this.baseImageURL = `${
      environment.serverurl
    }/getgooglefilefromserver?fileid=${this.windows.fileId}&src=${
      this.windows.src
    }&imagename=page_${this.getActivePageNo()}.png`;
    console.log(this.windows);
    this.fileName = this.removeExtension(this.windows.fileName)

    this.detectAspectRatio(this.baseImageURL).then((aspectRatio) => {
      console.warn({ aspectRatio, windowType: this.windows.type });
      if (
        (this.windows.type === "pdf/doc" && aspectRatio > 1.2) ||
        this.windows.type === "pptx"
      ) {
        // Adjust canvas size for horizontal aspect ratio
        console.log("how the turn tables");
        this.isAspectRatioHorizontal = true;
        this.windowSizes = {
          S: { height: 231, width: 441 },
          M: { height: 331, width: 588 },
          L: { height: 397, width: 706 },
          XL: { height: 476, width: 847 }, // Increased by 20% from L size
          XXL: { height: 777, width: 1382 }, // Increased by 20% from XL size
        };
      }
      // Initialize canvas with updated dimensions
      this.initializeCanvas();
      // Load the image with new dimensions if not cached
      this.loadImage(this.baseImageURL);
    });
    
    this.breakpointObserver
    .observe([
      '(min-width: 1920px) and (min-height: 1000px)', // Breakpoint for the large device
      '(max-width: 1919px) and (max-height: 999px)' // Breakpoint for the laptop
    ])
    .subscribe(result => {
      if (result.breakpoints['(min-width: 1920px) and (min-height: 1000px)']) {
        console.log('breakpoint observer large device sizes', this.windowSizes.XL)
        // Large device
        this.windowSizes = {
          S: { height: 340, width: 262 },
          M: { height: 485, width: 375 },
          L: { height: 582, width: 450 },
          XL: { height: 841, width: 654 }, // Increased by 20% from L size
          XXL: { height: 837, width: 648 }, // Increased by 20% from XL size
        };
      } else {
        // Default sizes for laptop
        console.log('breakpoint observer laptop device size', this.windowSizes.XL)
        this.windowSizes = {
          S: { height: 340, width: 262 },
          M: { height: 485, width: 375 },
          L: { height: 582, width: 450 },
          XL: { height: 776, width: 600 }, // Increased by 20% from L size
          XXL: { height: 837, width: 648 }, // Increased by 20% from XL size
        };
      }
      this.updateCanvasAndImageSize();
    });

    for (let i = 1; i <= this.totalPages; i++) {
      // this.listOfAllPages.push({
      //   src: `${environment.serverurl}/getgooglefilefromserver?fileid=${
      //     this.windows.fileId
      //   }&imagename=page-${i < 10 && this.totalPages > 10 ? "0" + i : i}.png`,
      //   index: i,
      //   isLoading: true,
      // });
      this.listOfAllPages.push({
        src: `${environment.serverurl}/getgooglefilefromserver?fileid=${this.windows.fileId}&src=${this.windows.src}&imagename=page_${i}.png`,
        index: i,
        isLoading: true,
      });
    }
  }
  ngAfterViewInit() {
    // this.initializeCanvas();
    // this.loadImage(this.baseImageURL);
    // console.log("loaded");
  }

  initializeCanvas() {
    const selectedSizeDimensions = this.windowSizes[this.selectedSize];
    this.canvas = new fabric.Canvas(this.canvasId, {
      height: selectedSizeDimensions.height,
      width: selectedSizeDimensions.width,
      selection: false,
    });
  }
  getActivePageNo() {
    // console.log(this.totalPages);
    // if (this.totalPages > 9 && this.activePageNo < 10)
    //   return "0" + this.activePageNo;
    // return this.activePageNo;
    return this.activePageNo;
  }

  loadImage(url: string) {
    console.log({ url });

    // const resizeFilter = new fabric.Image.filters.ResizeFilter({
    //   resizeType: "hermite", // or 'lanczos'
    // });

    fabric.Image.fromURL(
      url,
      (img) => {
        var scaleWidth = this.canvas.getWidth() / img.width;
        var scaleHeight = this.canvas.getHeight() / img.height;
        var scale = Math.min(scaleWidth, scaleHeight);
        this.canvas.clear();
        this.isLoadingCanvas = false;
        // img.resizeFilter = new fabric.Image.filters.Resize({
        //   resizeType: "sliceHack",
        // });

        img.set({
          left: 0,
          top: 0,
          selectable: false,
          evented: false,
          hasControls: false,
          hasBorders: false,
          centeredScaling: true,
          // scaleX: this.windowSizes[this.selectedSize].width / img.width,
          // scaleY: this.windowSizes[this.selectedSize].height / img.height,
          // // scaleX: scale,
          // // scaleY: scale,
        });
        // img.applyResizeFilters(this.canvas.renderAll.bind(this.canvas));

        img.scaleToHeight(this.windowSizes[this.selectedSize].height);
        // img.scaleToWidth(this.windowSizes[this.selectedSize].width / img.width);
        // img.filters.push(resizeFilter);
        this.canvas.viewportCenterObjectH(img);
        // img.applyFilters();
        this.canvas.add(img);
      },
      {
        crossOrigin: "Anonymous",
      }
    );
  }

  dragPosition = { x: 0, y: 0 };
  onSizeChange(event: any) {
    
    try {
      console.log("Selected size:", this.selectedSize);
    // const draggableWindow = document.getElementsByClassName("draggable-window")[0];
    const draggableWindow = document.getElementsByClassName("draggable-windownew")[0];
    draggableWindow['style'].top = "10px";
    draggableWindow['style'].left = "40px";
    this.dragPosition = { x: 40, y: 10 };
    //const newPosition = { x: 40, y: 10 }; 
    //draggableWindow['style'].transform = `translate3d(${newPosition.x}px, ${newPosition.y}px, 0px)`;
    //draggableWindow['style'].transform = "translate3d(0px, 0px, 0px)"; 
    this.updateCanvasAndImageSize();
    }
    catch(error) {
      console.log("error",error);
    }
  }

  
  updateCanvasAndImageSize() {
    const newSize = this.windowSizes[this.selectedSize];
    if (newSize) {
      this.canvas.setDimensions({
        height: newSize.height,
        width: newSize.width,
      });

      this.loadImage(this.baseImageURL); // Load the image with new dimensions if not cached
    }
  }
  checkImageExistence(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  navigateToImage(e: MouseEvent, operation: string) {
    if (this.isLoadingCanvas) {
      return;
    }
    this.isLoadingCanvas = true;
    console.log(operation === "+");
    if (operation === "+") {
      this.activePageNo = this.activePageNo + 1;
    } else {
      this.activePageNo = this.activePageNo - 1;
    }
    this.navigateToPageNo(this.activePageNo);
  }
  handleCloseDocumentWindow(windows) {
    this.removeDocWindowEvent.emit(windows);
  }
  handleImageSnip() {
    this.handleWindowSnipEvent.emit(this.baseImageURL);
  }
  handleToggleViewAllMode() {
    this.isToggleViewPagesMode = !this.isToggleViewPagesMode;
  }
  navigateToPageNo(index) {
    this.activePageNo = index;
    this.baseImageURL = `${
      environment.serverurl
    }/getgooglefilefromserver?fileid=${this.windows.fileId}&src=${
      this.windows.src
    }&imagename=page_${this.getActivePageNo()}.png`;
    this.checkImageExistence(this.baseImageURL).then((exists) => {
      if (exists) {
        // Image exists, load it
        this.loadImage(this.baseImageURL);
      } else {
        // Image doesn't exist, display placeholder or default image
        // this.loadImage(this.placeholderImageURL);
      }
    });
  }

  detectAspectRatio(url: string): Promise<number> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        resolve(aspectRatio);
      };
      img.src = url;
    });
  }
  removeExtension(filename: string): string {
    const index = filename.lastIndexOf('.');
    if (index === -1) {
    return filename;
    } else {
     return filename.substring(0, index);
    }
  } 
}
