import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '../file.service';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-phoneuilogin',
  templateUrl: './phoneuilogin.component.html',
  styleUrls: ['./phoneuilogin.component.scss']
})
export class PhoneuiloginComponent implements OnInit {

  beforelogin:boolean=false;
  scanagain:boolean=false;
  public loginForm;
    public position = "absolute";
    public ui_language;
    public username = "";
    public isSession = true;
    public width: any;
    public user;
    flashscreen:boolean=true;
    public subscriptionenabled:boolean=true;
  constructor(public router: Router,
    private formBuilder: FormBuilder,
    public auth:FileService,
    private dialogService: DialogService,
    //public filemanager:FilemanagerComponent,
    public _Activatedroute:ActivatedRoute) { }

ngOnInit() {
  this.showflashscreen()
//  this.beforelogin=true;
console.log("inside login ngoninit")
console.log(this._Activatedroute.snapshot.queryParams);
this.user = this._Activatedroute.snapshot.queryParams;
this.width = '100%'
this.loginForm = this.formBuilder.group({
loginname: ["", Validators.required],
accesskey: ["", Validators.required]
})
//this.router.navigate(['login'])
console.log("Local STORAGE ", localStorage);
if (localStorage.getItem('session_id') && localStorage.getItem('type')
&& localStorage.getItem('session_id') != "undefined" && localStorage.getItem('type') != "undefined") {
console.log("if statement")
this.auth.getTVInformation({
  token: localStorage.getItem('session_id'),
  refreshToken: localStorage.getItem('refreshToken')
}, '/token/validate')
  .then((user: any) => {
      console.log("User session_id ui_language: ", user);
      if(user&&user['auth-result'] =='invalid Token'){
           return;
          }
      // localStorage.setItem('username', user.username);
      // localStorage.setItem('userId', user.userId);
      // localStorage.setItem('type', user.type);
      // localStorage.setItem('session_id', user.session_id);
      // localStorage.setItem('accessToken', user.session_id);
      // localStorage.setItem('refreshToken', user.refreshToken);
      // this.collectUserPrefs();
      // this.router.navigate(["/dashboard"]);
      this.isSession = true;

  }, (err: any) => {
      localStorage.clear();
      this.isSession = false;
      console.log("User session_id ui_language (error): ", err);
  });

} else {
console.log("else statement")
localStorage.clear();
this.isSession = false;
}

}


  /*login(val) {
    var username = '';
    // if(val.loginname.indexOf("@") != -1){
    //    var index =  val.loginname.indexOf("@");
    //    username = val.loginname.slice(0,index)
    // }
    console.log(this.user);

    if(this.user){
        if(this.user.user){
            val.loginname = this.user.user;
        }
        if(this.user.pass){
            val.accesskey = this.user.pass;
        }
    }
    if (username == '') {
        username = val.loginname
        console.log("user",username);
    }
    if (!val.loginname || !val.accesskey) {
      this.dialogService.showError("Invalid credentials.");
        return false;
    }
    this.auth
        .authenticateUser(
            {
                'username': username,
                'password': val.accesskey,
            }
        )
        .then((user: any) => {
            console.log("User Management ___________________", user);

            localStorage.setItem('username', user.username);
            localStorage.setItem('type', user.type);
            localStorage.setItem('userId', (user.userId).toLowerCase());
            localStorage.setItem('session_id', user.session_id);
            localStorage.setItem('accessToken', user.session_id);
            localStorage.setItem('refreshToken', user.refreshToken);


               if (user.language ==undefined){

                localStorage.setItem('lang',"en-Us");
                console.log("null lang",localStorage.getItem("lang"));

               }
           else if(user.language!=undefined){
                localStorage.setItem('lang',user.language);
            }
            const loginData = {
              username:val.loginname,
              password:val.accesskey,
              token: this._Activatedroute.snapshot.queryParams.token, // Use the appropriate session ID or token
              sessionId: this._Activatedroute.snapshot.queryParams.sessionId,
              // Add any other data you want to send to the WebSocket server
            };
            this.auth.socket.emit('login-event', loginData);
            this.beforelogin=false;
            // this.collectUserPrefs();
            //this.router.navigate(["/whiteboard"]);
            //this.filemanager.newlesson();

        }, (err) => {
            console.log(err);
            localStorage.clear();
            this.dialogService.showError("Invalid credentails.");
        });
  }*/


        /////////////new...

        login(val) {
          let username = '';
          console.log(this.user);

          if (this.user) {
              if (this.user.user) {
                  val.loginname = this.user.user;
              }
              if (this.user.pass) {
                  val.accesskey = this.user.pass;
              }
          }

          if (username === '') {
              username = val.loginname;
              console.log("user", username);
          }

          if (!val.loginname || !val.accesskey) {
              this.dialogService.showError("Invalid credentials.");
              return false;
          }

          this.auth
              .authenticateUser({
                  username: username,
                  password: val.accesskey,
              })
              .then((user: any) => {
                  console.log("User Management ___________________", user);

                  localStorage.setItem('username', user.username);
                  localStorage.setItem('type', user.type);
                  localStorage.setItem('userId', (user.userId).toLowerCase());
                  localStorage.setItem('session_id', user.session_id);
                  localStorage.setItem('accessToken', user.session_id);
                  localStorage.setItem('refreshToken', user.refreshToken);

                  const language = user.language || "en-Us";                   //user.language undefined then "en-Us"
                  localStorage.setItem('lang', language);
                  console.log("language Bandana", localStorage.getItem("lang"));

                  if (user.type === 'teacher' || user.type === 'demoaccounts' || user.type === 'schooladmin') {
                      console.log("Entered in need accounts Bandana Das");

                      this.auth.getTVInformation({ emailid: user.userId, role: user.type }, '/getadmin').then(
                          (res: any) => {
                              console.log(res, "Bandana Das response in qrlogin");
                              try {
                                  if (res) {
                                      if(user.type == 'demoaccounts'){
                                        localStorage.setItem("licensetype", 'demo');
                                      }else{
                                        localStorage.setItem("licensetype", res.body.data.licensetype);
                                      }
                                      console.log(res.body.data.enddate, "for licensedetails");

                                      const enddate = new Date(res.body.data.enddate);
                                      const today = new Date();
                                      const differenceMs = enddate.getTime() - today.getTime();
                                      const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
                                      console.log(differenceDays, "difference");
                                      console.log(res.body.data.activestatus, res.body.data.licensestatus, "Bandana");

                                      if (user.type === 'demoaccounts' && res.body.data.activestatus === "inactive") {
                                          console.log("hello");
                                          this.dialogService.showError("Subscription expired. Please contact admin to renew plan.");
                                          this.subscriptionenabled = false;
                                          localStorage.clear();
                                      }

                                      if ((user.type === 'teacher' || user.type === 'schooladmin') && res.body.data.licensestatus === "inactive") {
                                          console.log("hello");
                                          this.subscriptionenabled = false;
                                          this.dialogService.showError("Subscription expired. Please contact admin to renew plan.");
                                          localStorage.clear();
                                      }

                                      if (differenceDays < 0) {
                                          this.subscriptionenabled = false;
                                          this.dialogService.showError("Subscription expired. Please contact admin to renew plan.");
                                          localStorage.clear();
                                      }
                                  } else {
                                      console.log("no data from backend");
                                      this.beforelogin = this.subscriptionenabled ? false : true;
                                  }
                              } catch (e) {
                                  this.subscriptionenabled = false;
                                  this.dialogService.showError("Internal server error");
                                  console.log(this.subscriptionenabled, "in error");
                                  console.log(e);
                              }finally {


                                    this.beforelogin = this.subscriptionenabled ? false : true;

                                console.log(this.beforelogin, "Before login", this.subscriptionenabled);
                            }
                          }
                      );
                  }else{
                    this.beforelogin = false;
                  }

                  const loginData = {
                      username: val.loginname,
                      password: val.accesskey,
                      token: this._Activatedroute.snapshot.queryParams.token,
                      sessionId: this._Activatedroute.snapshot.queryParams.sessionId,
                  };

                  this.auth.socket.emit('login-event', loginData);

              }, (err) => {
                  console.log(err);
                  localStorage.clear();
                  this.dialogService.showError("Invalid credentials.");
              });

            }


  hide = true;
get emailInput() { return this.loginForm.get('Username'); }
get passwordInput() { return this.loginForm.get('Password'); }

get isFormValid() {
  return this.loginForm.valid;
}

logout(){

  const logoutdata = {

    token: this._Activatedroute.snapshot.queryParams.token, // Use the appropriate session ID or token
    sessionId: this._Activatedroute.snapshot.queryParams.sessionId,
    // Add any other data you want to send to the WebSocket server
  };
  this.auth.socket.emit('logout-event', logoutdata);
  this.auth.getTVInformation({}, '/user/logout').then((res: any) => {
      try {
          if (res && res.status) {
              localStorage.clear();
              this.beforelogin=true;
              //this.router.navigate(['/qrlogin']);
          }
      } catch (e) {
          console.error(e);
      }
  }, (err: any) => {
      console.error(err);
  })

  this.scanagain=true;

}

clear(){
  this.loginForm = this.formBuilder.group({
    loginname: ""
})
}


showflashscreen(){
  console.log("hello")
  setTimeout(() => {
   this.flashscreen=false;
   this.beforelogin=true;
  }, 2000);
}

}
