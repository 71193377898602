import { Component, OnInit, Output, EventEmitter, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { AuthService } from '../auth.service';
import { FileService } from '../file.service';
//import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { environment } from '../../environments/environment'
import { BsModalRef } from 'ngx-bootstrap/modal';
//import { LocalizationService } from './../internationalization/localization.service';
//import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-virtual-class',
    templateUrl: './virtual-class.component.html',
    styleUrls: ['./virtual-class.component.css']
})
export class VirtualClassComponent implements OnInit {
    type: string;
    //userName: string;
    //userID: string;
    classes = [];
    public data: any = {};
    publicLessons = [];
    privateLessons = [];
    publicSelect = true;
    privateSelect = true;
    public modes = [
        {
            "key": "collab",
            "value": "Collaborative Whiteboard"
        },
        {
            "key": "present",
            "value": "Presentation"
        }]
    @Output() public closeModal = new EventEmitter();

    constructor(private _Activatedroute: ActivatedRoute, public auth: FileService, public router: Router, public bsModalRef: BsModalRef
        //public localizationService: LocalizationService,public ts: TranslateService,public bsModalRef: BsModalRef
        ) { }

    ngOnInit(): void {
        //this.userID = localStorage.getItem('userId');
        //this.type = localStorage.getItem('type');
        //this.userName = localStorage.getItem('username');
        console.log("virtual class component",this.username)
        //console.log("ccccc",this.userID,this.type)
        //console.log(environment.etheradURL);
        // if(this.type == 'teacher'){
        //     this.getClasses();
        //     this.getAllLessions();
        // }
        this.createSocektConnetion()
    }

    username:any;
    //accesstokennnnn:any;
    random:any
registernadlogin(lessonroom){
    this.auth.getTVInformation({name:this.username}, '/authenticateuser').then((res: any) => {
        try {
            console.log('############', lessonroom)
          console.log(',,,,,,,,,,,,,,,,,,,,,,', res)
          this.random=res.body["auth-result"].randomuserId;
          const accesstokennnnn=res.body["auth-result"].accessToken;
          const nameofuser= this.username
          console.log("ddddddd",this.random,accesstokennnnn,nameofuser)
          this.router.navigate(['av-chat'], { queryParams: { accesstokennnnn, lessonroom, nameofuser } });
          this.bsModalRef.hide();
          //this.getRoomDetails()
          //this.validateUser(lesson);
        } catch (e) {
          console.error(e);
        }
      })

}

    goToLessions(lesson) {
        console.log("____",this.data);
        console.log("asdfghjkl",lesson);
        // this.sendroomid(lesson);
        localStorage.setItem('roomID', lesson);
        if((this.data.classesName && this.data.lesson && this.data.classMode) || this.data.roomId){
        lesson = lesson.replace(/\s/g, "");
        
        this.router.navigate([`/av-chat`])
        //this.validateUser(lesson);
        //this.getRoomDetails();
        }else{
            //alert(this.ts.instant('dashboard.Enter required fields'));
        }
    }
    cancel() {
        //this.bsModalRef.hide();
    }
    getRoomDetails(){
        console.log("room",this.data.roomId)
        this.auth
            .getTVInformation({roomId:this.data.roomId}, "/getKmsRoom")
            .then(
                (res: any) => {
                    try {
                        if (res) {
                            console.log("resData_GET CLASSES______________", res);
                            // if(res.getClass && res.getClass == "success"){
                                this.router.navigate([`/av-chat`])
                                console.log("navigate")
                               // this.router.navigate([`/whiteboard/${res.lessonId}/${res.padId}/${btoa(res.roomId)+'?'+btoa(res.classMode)}`])
                                // this.router.navigate([`/metaverse/${res.lessonId}/${res.padId}/${btoa(res.roomId)+'?'+btoa(res.classMode)}`])
                                // }
                        } else {
                            console.error("General error");
                        }
                    } catch (e) {
                        console.error(e);
                    }
                },
                (err: any) => {
                    console.error(err);
                }
            );
    }
    validateUser(roomId) {
        console.log("calling validateUser______________________");     
        console.log("____",this.data);   
        this.auth
            .getTVInformation( {
                roomId: roomId,
                userType: "student",//this.type,
                userName: this.username,//"Student 11 a 01",//this.userName,
                userId: this.random//"student.11.a.01@tpv-tech.com"//this.userID
            }, "/validateUser")
            .then(
                (res: any) => {
                    try {
                        if (res) {
                            console.log("resData_VALIDATE STUDENT______________", res);
                            //if(res.validateUser && res.validateUser == "success"){
                                //this.bsModalRef.hide(); // res._id res.lessionpadID
                                // this.data.lesson._id &&
                                //if(this.type == "student" && roomId){
                                 //   this.getRoomDetails();
                                // }else if(this.type == "teacher" && this.data.lesson._id && this.data.lesson.lessionpadID && roomId && this.data.classMode){
                                //     // alert('herere')
                                   // this.router.navigate([`/av-chat`])
                                //     // this.router.navigate([`/metaverse/${this.data.lesson._id}/${this.data.lesson.lessionpadID}/${btoa(roomId)+'?'+btoa(this.data.classMode)}`])
                                // }else{
                                //     alert("Enter mandatory fields")
                                // }
                            //}
                           // else{
                                //alert(this.ts.instant("dashboard.You do not have permission to access this class"));
                           // }
                        } else {
                            //this.bsModalRef.hide();
                            console.error("General error");
                        }
                    } catch (e) {
                        //this.bsModalRef.hide();
                        console.error(e);
                    }
                },
                (err: any) => {
                    console.error(err);
                }
            );
    }
    getClasses() {        
             let  typeName={};
             typeName['type']="Individual";
        this.auth
            .getTVInformation(typeName, "/getClasses")
            .then(
                (res: any) => {
                    try {
                        if (res) {
                            console.log("resData_GET CLASSES______________", res);
                            if(res.getClass && res.getClass == "success"){
                                this.classes = res.classes
                                this.sortFunction()
                            }
                        } else {
                            console.error("General error");
                        }
                    } catch (e) {
                        console.error(e);
                    }
                },
                (err: any) => {
                    console.error(err);
                }
            );
    }

    sortFunction(){
        this.classes.sort((a,b) => (a.name > b.name ? 1 : ((b.name > a.name) ? -1 : 0)))
    }

    // getAllLessions() {
    //     console.log("____",this.data);
    //     this.auth.getTVInformation({ userID: this.userID }, '/getAllLessions').then((res: any) => {
    //         try {
    //             this.publicLessons = []
    //             this.privateLessons = []
    //             if (res) {
    //                 console.log("resData_getAllLessions_____________", res);
    //                 res.map(val => {
    //                     if (val.isPublic) {
    //                         this.publicLessons.push(val)
    //                     } else if (!val.isPublic && val.creatorUserId == this.userID) {
    //                         this.privateLessons.push(val)
    //                     }
    //                 })
    //             } else {
    //                 console.error("General error");
    //             }
    //         } catch (e) {
    //             console.error(e);
    //         }
    //     });
    // }
    radibuttonCheck(type) {
        console.log("____",this.data);
        if(type == 'private'){
            this.privateSelect = false;
            this.publicSelect = true;
        }else if(type == 'public'){
            this.privateSelect = true;
            this.publicSelect = false;
        }        
    }

    createSocektConnetion(){
        console.log("calling create socket")
        this.auth.joinToClass()
    }
}
