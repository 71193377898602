import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { EditorcanvasComponent } from './editorcanvas.component';

@NgModule({
  declarations: [EditorcanvasComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ColorPickerModule
  ],
  exports: [EditorcanvasComponent]
})
export class EditorcanvasModule { }
