import { Component, OnInit } from "@angular/core";
import { GoogleAuthService } from "../google-auth.component";
import { Router } from '@angular/router';

@Component({
    template:`<div></div>`
})
export class SigninCallbackComponent implements OnInit{

    constructor(private _authService: GoogleAuthService, private _router: Router) {
      
    }

    ngOnInit(): void {
      console.log("signin-callback.component")
        this._authService.signInComplete().then((res)=> {
          console.log("..........................")
          console.log("User has logged in")
            // console.log(res);
            console.log("..........................")
            this._router.navigate(['/whiteboard'], {replaceUrl: true});
            // this._location.back();
        },(err)=>{
          console.log("sign in failed.")
          console.log(err.message)
        });
    }
}

