import { Component, OnInit, Input, ViewChild, Output, ElementRef, Renderer2, EventEmitter } from '@angular/core';
import { FileService } from '../file.service';
import { EditorcanvasComponent } from 'projects/angular-editor-fabric-js/src/public-api';
import * as fabric from '../../../src/lib/fabric.min.js'
declare const fabric: any;
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from '../dialog.service';
import { BrowserDetectionService } from '../browser-detection.service';
import { replaceWithThumbnails } from "src/app/shared/utils.js";

@Component({
  selector: 'app-whiteboardpagethumbnail',
  templateUrl: './whiteboardpagethumbnail.component.html',
  styleUrls: ['./whiteboardpagethumbnail.component.scss']
})
export class WhiteboardpagethumbnailComponent implements OnInit {
  currentPosition: number = 0
  totalObjects: number = 0
  @ViewChild('canvas', { static: false }) mycanvas: EditorcanvasComponent;
  @ViewChild('thumbnailscroll', { static: false }) thumbnailscroll: ElementRef;

  @Input() openpages: boolean;
  @Input() thumbnails: string[] = [];
  showSpinner: boolean = false;
  sw: any;
  sh: any;
  AllPageThumbnail: any;
  pageNo: number;
  scrolledThumbnail: number = 4
  thumbanailPages;
  scrollSteps: number = 4
  fullScroll: boolean = false
  selectedPage : number;
  totalPages: number;
  thumbnailsNew: any[];

  @Output() onPageSelected = new EventEmitter<any>();
  @Output() onInitializeThumbComponent = new EventEmitter();
  constructor(public fileservice: FileService, private sanitizer: DomSanitizer,private renderer: Renderer2, private dialogService: DialogService,
     private browserDetectionService: BrowserDetectionService) { }
  ngOnInit(): void {
    this.onInitializeThumbComponent.next();
    this.showSpinner = true;
    this.totalPages = Number(localStorage.getItem('totalPages')) || 1;
    const scrollbarStyle = `
    .scroller {
      width: 100%;
      height: 200px;
      overflow-y: scroll;
    }

    /* For WebKit-based browsers (Chrome, Safari, Android WebView) */
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #eee;
    }

    ::-webkit-scrollbar-thumb {
      background: #55aa55;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #88dd88;
    }
  `;
  const isWebKit = 'webkitAppearance' in document.documentElement.style;

  // Apply custom scrollbar style to non-WebKit browsers and Android devices
  if (!isWebKit) {
    const styleElement = this.renderer.createElement('style');
    this.renderer.appendChild(styleElement, this.renderer.createText(scrollbarStyle));
    this.renderer.appendChild(document.head, styleElement);
  }
  this.selectedPage = Number(localStorage.getItem('currentPage'))
    let thumbnailLen = JSON.parse(localStorage.getItem('savedCanvasData'))
    this.thumbanailPages = thumbnailLen.length
    this.sw = this.getScreenWidth();
    this.sh = this.getScreenHeight()
    // setTimeout(() => {
    let AllPageThumbnail = this.thumbanailPages
    if (AllPageThumbnail <= 4) {
      this.scrolledThumbnail = this.thumbanailPages
      this.scrollSteps = this.thumbanailPages
    }
    this.initializeLoadingState()
    this.addThumbnails()
    // this.GenThumbAuto()
  }

  ngAfterViewInit(): void {

  }

  public updateObjectLength() {
    const savedCanvasDataV = localStorage.getItem('savedCanvasData');
    const canvasDataArray = JSON.parse(savedCanvasDataV);
    this.totalObjects = canvasDataArray.length
  }

  public updatePosition() {
    const currentPositionV = localStorage.getItem('CurrentPosition');
    const canvasPositionV = JSON.parse(currentPositionV);
    this.currentPosition = canvasPositionV
  }


  public autocache() {
    // this.mycanvas.saveCache();
  }

  public previewLoad() {
    // this.mycanvas.previewLoad();
    this.updatePosition();
    this.updateObjectLength();
    // this.pagethumb()
    this.autocache()
    console.log("click previewLoad");
  }

  isAvChatStarted(): boolean {
    return localStorage.getItem("avchatstarted") === 'yes';
  }  

  deletePage(pageIndex) {
    let pageNumber = Number(pageIndex) + 1
    if(localStorage.getItem("avchatstarted") && localStorage.getItem("avchatstarted") == 'yes'){
      this.dialogService.showError("You can't delete the page while collaboration is active!")
    }
    else{
      this.dialogService.storePageNumberToBeDeleted(pageNumber);
      this.dialogService.showConfirmation(`Do You want delete page:${pageNumber}`);
   }
  }



  // Remove an object from the local storage array by index
  removeObjectByIndex(index: number): void {

    console.log("trigger", index)
    const itemsJson = localStorage.getItem('savedCanvasData');
    const thumbnailJson = localStorage.getItem('thumbnail');


    if (itemsJson) {
      const items: any[] = JSON.parse(itemsJson);

      if (index >= 0 && index < items.length) {
        items.splice(index, 1); // Remove the object at the specified index
        localStorage.setItem('savedCanvasData', JSON.stringify(items)); // Update the local storage
      }
    }

    //thumbnail
    if (thumbnailJson) {
      const items: any[] = JSON.parse(thumbnailJson);

      if (index >= 0 && index < items.length) {
        items.splice(index, 1); // Remove the object at the specified index
        localStorage.setItem('thumbnail', JSON.stringify(items)); // Update the local storage
      }
    }

    this.previewLoad()

  }

  async GenThumbAuto() {
    // this.thumbnails = [];
    let AllPageThumbnail = await this.getCanvasObject()
    const canvas = new fabric.Canvas(null, { width: this.sw, height: this.sh });
    for (let ind = (this.scrolledThumbnail - this.scrollSteps); ind < this.scrolledThumbnail; ind++) {
      let fabricJson = AllPageThumbnail[ind];
      for (let index = 0; index < fabricJson.objects.length; index++) {
        console.log("Local Debugg: GenThumbAuto", fabricJson.objects[index]);
        if (fabricJson.objects[index].type && fabricJson.objects[index].type === 'image') {
          fabricJson.objects[index].crossOrigin = 'anonymous';
        }
        if (fabricJson.objects[index].name && fabricJson.objects[index].name === 'windowYoutube') {
          fabricJson.objects[index].objects[0].crossOrigin = 'anonymous';
        }
      }

      await new Promise<void>((resolve) => {
        canvas.loadFromJSON(fabricJson, () => {
          // Convert canvas to base64 image
          const base64Image = canvas.toDataURL({
            format: 'png',
            quality: 1,
          });
          this.thumbnails.push(base64Image);
          resolve();
        });
      });
    }
    this.showSpinner = false;
  }

  async getCanvasObject() {
    console.log("getting canvass")
    let AllPageThumbnail = JSON.parse(localStorage.getItem('savedCanvasData'));
    let index = Number(localStorage.getItem('currentPage'))
    index = index - 1
    AllPageThumbnail[index] = JSON.parse(localStorage.getItem('currentCanvas'))
    console.log(AllPageThumbnail ,"all pages??")
    return AllPageThumbnail
  }


  getScreenWidth(): number {
    return window.innerWidth;
  }

  getScreenHeight(): number {
    return window.innerHeight;
  }

  onScroll() {
    let Pages = this.thumbanailPages - this.scrolledThumbnail
    if (Pages >= 4) {
      this.scrolledThumbnail += 4;
    } else {
      this.scrolledThumbnail = this.thumbanailPages;
      this.scrollSteps = this.scrolledThumbnail % 4;
    }
    if (this.scrolledThumbnail < this.thumbanailPages) {
      this.GenThumbAuto();
    }
    else if ((this.scrolledThumbnail === this.thumbanailPages) && !this.fullScroll) {
      this.fullScroll = true
      this.GenThumbAuto();
    }
  }

  changePage(pageNo) {
    console.log("Venki: pageNo", pageNo);
    this.onPageSelected.emit(pageNo);
  }

  addThumbnails() {
    // var gettime = new Date();
    // console.log(gettime ,"timeee")
    console.log(this.thumbnails,"oldddd" )
    const totalPages = localStorage.getItem("totalPages");
    const fileid = localStorage.getItem("FileID");
    const currentPage = parseInt(localStorage.getItem('currentPage') || '0', 10); 
    const currentPageIndex = currentPage - 1;
    this.setThumbnailLoading(currentPageIndex, true);
    if (totalPages && fileid) {
      const totalPagesNumber = parseInt(totalPages, 10);
       // Initialize thumbnails array with empty values for each page
       this.thumbnailsNew = new Array(totalPagesNumber).fill(null);
        // Loop through all pages to populate the `thumbnails` array
        for (let pageNumber = 1; pageNumber <= totalPagesNumber; pageNumber++) {
          if (pageNumber === currentPage) {
              // For the current page, generate a base64 image and update in the array
              this.GenThumbAutoforCurrentpage(pageNumber-1); // Pass currentPageIndex to generate the base64 image
          } else {
            this.thumbnailsNew[pageNumber-1] = this.fileservice.readPageThumbnail(pageNumber)
          }
      }
      this.showSpinner = false;
    } else {
      console.error('Error: Missing totalPages or fileid from localStorage');
    }
  }


  async GenThumbAutoforCurrentpage(currentPageIndex: number) {
    const canvas = new fabric.Canvas(null, 
      { width: this.browserDetectionService.getBrowserInfo().canvasWidth, height: this.browserDetectionService.getBrowserInfo().canvasHeight });
    let fabricJson = JSON.parse(localStorage.getItem('currentCanvas'));

    if (!fabricJson) {
        console.error(`No fabric JSON found for page index: ${currentPageIndex}`);
        return;
    }

    for (let obj of fabricJson.objects) {
        // if (obj.type === 'image') obj.crossOrigin = 'anonymous';
        // if (obj.name === 'windowYoutube') obj.objects[0].crossOrigin = 'anonymous';
        if (obj.type === 'image') {
          obj.crossOrigin = 'anonymous';
          // using thumbnail images instead of original images
          if(obj.src && obj.src.includes("uploads_content/")) {
            replaceWithThumbnails(obj)
          }
          console.log("Set cross-origin for image:", obj);
      }
  
      if (obj.name === 'windowYoutube' && obj.objects && obj.objects[0]) {
          obj.objects[0].crossOrigin = 'anonymous';
          console.log("Set cross-origin for windowYoutube object:", obj.objects[0]);
      }
  
      // Check for nested objects in groups (if any)
      if (obj.type === 'group' && obj.objects) {
          for (let nestedObj of obj.objects) {
              if (nestedObj.type === 'image') {
                  nestedObj.crossOrigin = 'anonymous';
                  console.log("Set cross-origin for nested image in group:", nestedObj);
              }
          }
      }
    }

    await new Promise<void>((resolve) => {
        canvas.loadFromJSON(fabricJson, async () => {
            // Apply background color to the entire canvas
        if (fabricJson.bgColor) {
          canvas.setBackgroundColor(fabricJson.bgColor, () => {
              canvas.renderAll();
              console.log(`Background color applied to the entire canvas: ${fabricJson.bgColor}`);
          });
        }
        if (fabricJson.bgFrame) {
          await new Promise<void>((bgResolve) => {
              fabric.Image.fromURL(fabricJson.bgFrame, (img) => {
                  canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                      originX: 'left',
                      originY: 'top',
                      top: 0,
                      left: 0,
                      scaleX: canvas.width / img.width,
                      scaleY: canvas.height / img.height,
                  });
                  console.log(`Applied bgFrame from URL: ${fabricJson.bgFrame}`);
                  bgResolve();
              }, { crossOrigin: 'anonymous' });
          });
      } 
      if (fabricJson.customBg) {
        await new Promise<void>((bgResolve) => {
            fabric.Image.fromURL(fabricJson.customBg, (img) => {
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    originX: 'left',
                    originY: 'top',
                    top: 0,
                    left: 0,
                    scaleX: canvas.width / img.width,
                    scaleY: canvas.height / img.height,
                });
                console.log(`Applied customBg from URL: ${fabricJson.customBg}`);
                bgResolve();
            }, { crossOrigin: 'anonymous' });
        });
    } 


            const base64Image = canvas.toDataURL({format:'png',quality:0.0001});
            this.thumbnailsNew[currentPageIndex] = base64Image; // Update only the current page
            console.log(`Updated Base64 Thumbnail for Page ${currentPageIndex + 1}`);
            resolve();
        });
    });
    this.setThumbnailLoading(currentPageIndex, false);
}

loadingThumbnails: boolean[] = [];
initializeLoadingState() {
  const totalPages = parseInt(localStorage.getItem("totalPages") || '0', 10);
  this.loadingThumbnails = Array(totalPages).fill(false);
}
setThumbnailLoading(pageIndex: number, isLoading: boolean) {
  this.loadingThumbnails[pageIndex] = isLoading;
}

  // thumbnailInterval : any;
  // ngOnDestroy() {
  //   // Clear the interval when the component is destroyed
  //   if (this.thumbnailInterval) {
  //     clearInterval(this.thumbnailInterval);
  //   }
  // }
  // startThumbnailUpdate() {
  //   this.addThumbnails();
  //   this.thumbnailInterval = setInterval(() => {
  //     this.addThumbnails();  // Refresh thumbnails every 60 seconds
  //   }, 60000); // 60000 ms = 60 seconds
  // }

}
