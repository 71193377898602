import { Component, OnInit,ViewChild,TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileService } from "../file.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../dialog.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"

@Component({
  selector: 'app-demosetuser',
  templateUrl: './demosetuser.component.html',
  styleUrls: ['./demosetuser.component.scss']
})
export class DemoSetuserComponent implements OnInit {
  // public token: string;
  // public loginForm;
  loginForm: FormGroup;
  public user;
  token: string;
  newPassword: string;
  confirmPassword: string;
  countrynames: any =[];
  hide = true;
  hide1 = true;
  public error: any = {};
  //success: boolean = false;

  success: Boolean = false;
  emailid:any;
  showform:Boolean=true;
  webpageshow:any;
  isInternetConnected:Boolean

  constructor(private route: ActivatedRoute, private fileservice: FileService, private formBuilder: FormBuilder,public router:Router, private dialogService: DialogService,private modalService: BsModalService, private sanitizer: DomSanitizer) { }
@ViewChild('modalContent') modalContent: TemplateRef<any>;
public modals_exit_ref: BsModalRef;
  ngOnInit(): void {

    this.fileservice.getConnectionStatus().subscribe((status) => {
      this.isInternetConnected = status;
      console.log(this.isInternetConnected,"Bandana Checking internet connection everytime");
      if(!status&&this.webpageshow===true){
        this.modals_exit_ref.hide();
      }
    });
    //this.getallregionsandcountrys()
    this.initForm();
    this.token = this.route.snapshot.params['token'];
    this.user = this.route.snapshot.queryParams;
    console.log(this.token , this.user ,"checkkk")
    this.emailid=this.token;

    this.fileservice.searchuser(this.emailid).subscribe(val=>{
      console.log(val);
      if(val.data.message==="No user found"){
         this.showform=true;
      }else{
       this.showform=false;
      }
     },(error)=>{
       console.log(error)

     }
    )
  }



  /*initForm() {
    this.loginForm = this.formBuilder.group({
      emailid: ['', Validators.required], // Add validation if needed
      countryname: ['', Validators.required], // Add validation if needed
      schoolname: ['', Validators.required], // Add validation if needed
      firstname: ['', Validators.required], // Add validation if needed
      lastname: ['', Validators.required], // Add validation if needed
      password: ['', Validators.required], // Add validation if needed
      confirmpassword: ['', Validators.required] // Add validation if needed
    });
  }*/



  initForm() {
    this.loginForm = this.formBuilder.group({

      password: ['', Validators.required], // Add validation if needed
      confirmpassword: ['', Validators.required] // Add validation if needed
    });
  }






        showsuccess:Boolean=false
        emailrecieved:any;
        SaveDemoDetails() {
          if(this.isInternetConnected===false){
            return
          }
          this.error.confirmpasswordNotValid = false;
          this.token=this.emailrecieved;
          console.log(this.token,"this.token")
      if (this.loginForm.invalid) {
             return;
           }else{

        console.log(this.loginForm.value);
            if (this.loginForm.value.password != this.loginForm.value.confirmpassword) {
                   this.error.confirmpasswordNotValid = true;
                   this.error.confirmpasswordNotValidText = "Password and confirm password does not match";
                  return

           }else{
            this.fileservice.setdemoaccpassword(this.emailid,this.loginForm.value.password).subscribe(val=>{
             console.log(val)
             this.showsuccess=true;

            },(error)=>{
              this.dialogService.showError("Internal server error. Please try again later.");
              console.log(error)
            }
          )
           }



          }
        }




        login() {
          if(this.isInternetConnected===false){
            return
          }
          this.fileservice
              .authenticateUser({
                  'username': this.emailid,
                  'password': this.loginForm.value.password,
              })
              .then((user: any) => {
                  try {
                      console.log("User Management ___________________", user);
                      console.log("user user user ", user.classidavchat);

                      // Save user data in local storage
                      localStorage.setItem('username', user.username);
                      localStorage.setItem('type', user.type);
                      localStorage.setItem('userId', (user.userId).toLowerCase());
                      localStorage.setItem('session_id', user.session_id);
                      localStorage.setItem('accessToken', user.session_id);
                      localStorage.setItem('refreshToken', user.refreshToken);
                      localStorage.setItem('classidavchat', user.classidavchat);
                      localStorage.removeItem('prevCanvasObjLen');

                      // Set language
                      const language = user.language ? user.language : 'en-Us';
                      localStorage.setItem('lang', language);
                      console.log("language set to", localStorage.getItem("lang"));

                      // Emit login event to WebSocket server
                      const loginData = {
                          username: this.emailid,
                          password: this.loginForm.value.password,
                      };
                      this.fileservice.socket.emit('login-event', loginData);

                      // Handle demo accounts
                      if (user.type == 'demoaccounts') {
                          this.fileservice.getTVInformation({ emailid: user.userId, role: user.type }, '/getadmin')
                              .then((res: any) => {
                                  if (res && res.body && res.body.data) {
                                      localStorage.setItem("licensetype", res.body.data.licensetype);
                                      console.log(res.body.data.enddate, "for licensedetails");

                                      const enddate = new Date(res.body.data.enddate);
                                      const today = new Date();
                                      const differenceMs = enddate.getTime() - today.getTime();
                                      const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
                                      console.log(differenceDays, "days remaining for subscription");

                                      this.fileservice.setTriggerState(true);
                                      localStorage.setItem("subscription", differenceDays.toString());
                                      this.router.navigate(["/whiteboard"]);
                                  } else {
                                      console.error("No data from backend");
                                  }
                              })
                              .catch((error) => {
                                  console.error("Error fetching TV information:", error);
                              });
                      }

                  } catch (error) {
                      console.error("Error during login process:", error);
                      this.dialogService.showError("Internal server error. Please try again later.");
                  }
              })
              .catch((err) => {
                  console.error("Authentication error:", err);
                  localStorage.clear();
                  this.dialogService.showError("Invalid credentials.");
              });
        }


        openInNewTab(value){
          console.log(value);
          window.open(value, '_blank');
      }
      openurl(value){
        this.webpageshow=true
        this.webpageurl=this.sanitizer.bypassSecurityTrustResourceUrl(value);
        this.modals_exit_ref = this.modalService.show(this.modalContent, {
          class: 'setHeight',
          backdrop: 'static',  // Prevents closing on outside clicks
          keyboard: false      // Prevents closing using the keyboard
        });
      }


      webpageurl:any
closeModal() {

  this.modals_exit_ref.hide();
  this.webpageshow=false
}


checkConnection(){
  console.log("checkconnection");
  this.fileservice.getConnectionStatus().subscribe((status) => {
    this.isInternetConnected = status;
    console.log(this.isInternetConnected,"Bandana Checking internet connection everytime");
  });
}

}
