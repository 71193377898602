import { AfterViewInit, Component, OnInit, OnChanges } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { GoogleAuthService } from "../gc-plugin/google-auth.component";
import { GoogleClassroomService } from "../gc-plugin/google-apis.service";
import { GoogleListData } from "../filemanager/googleCustomTypes";
import { NgForm } from "@angular/forms";
import { FileService } from "../file.service";
import { WhiteboardpageComponent } from "../whiteboardpage/whiteboardpage.component";

@Component({
  selector: "app-sharetogoogle",
  templateUrl: "./sharetogoogle.component.html",
  styleUrls: ["./sharetogoogle.component.scss"],
})
export class SharetogoogleComponent implements OnInit {
  lessonID: any;
  pageidarray: any;

  constructor(
    public bsModalRef: BsModalRef,
    private googleAuthService: GoogleAuthService,
    private googleClassroomService: GoogleClassroomService,
    private fileService: FileService
  ) {}

  isLoggedIn: boolean = false;
  classList: any = [];
  fileType: "pdf" | "image";
  isLoading: boolean = false;
  isSuccess: boolean = false;

  formData = {
    googleshare_title: "",
    googleClassId: "",
  };
  ngOnInit(): void {
    this.googleAuthService.isLoggedIn().then((res) => {
      console.log({ res });
      this.isLoggedIn = res;
    });
    this.googleClassroomService
      .getCourseList()
      .subscribe((data: GoogleListData) => {
        data.courses.forEach((course) => {
          const concatenatedClassName = course.name + " " + course.section;
          this.classList.push({
            concatenatedClassName,
            course_id: course.id,
          });
        });
      });
  }

  handleGoogleClassroomConnect() {
    this.googleAuthService.signin();
  }
  submit(form: NgForm) {
    if (form.valid) {
      if (
        this.formData.googleshare_title.trim() === "" ||
        this.formData.googleClassId.trim() === ""
      )
        return;
      this.isLoading = true;
      console.warn(this.pageidarray, this.lessonID);
      this.fileService
        .pdfgeneration(this.lessonID, this.pageidarray)
        .subscribe((response) => {
          this.finalSubmit(response.data);
        });
    } else {
      // Mark form controls as touched to display validation messages
      Object.values(form.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
    console.log(this.formData);
  }
  finalSubmit(url: string) {
    // Your submit logic goes here
    const materialData = {
      title: this.formData.googleshare_title, // Title of the material
      materials: [
        {
          link: {
            url, // URL to the material if applicable
            title: this.formData.googleshare_title, // Title for the link (if applicable)
          },
          // Add other material types or attachments if needed
        },
      ],
    };
    this.googleClassroomService
      .postShareMaterialToGoogleClassroom(
        this.formData.googleClassId,
        materialData
      )
      .subscribe((data) => {
        console.log(data);
        this.isSuccess = true;
        this.isLoading = false;
        setTimeout(() => {
          this.bsModalRef.hide();
          this.isSuccess = false;
        }, 1000);
      });
    console.log("submit");
  }
  handleClassSelection(value) {
    console.log({ value });
  }
}
