var configs = {
  dev: {
    serverport: 4000,
    serverurl: "http://localhost:4000",
    // etheradURL: "https://classroom.tpvai.com",
    mongoUrl: "mongodb://localhost:27017/Iwb_Europe",
    serverPath: "",
    enableTranslations: true,
    etheradURL: "http://localhost:4000", //'https://classroom.tpvai.com',
    joinavurl:"http://localhost:4200",
    callbackUrl: "https://classroom.tpvai.com", //"https://wpdev.tpvai.com",
    avChatUrl: "wss://classroom.tpvai.com/kmswss",//"wss://classroom.tpvai.com/kmswss",
    rediServer: "inblrlx044.tpvaoc.com",
    redisPort: 11000,
    //exportedFileLocation:"C:/Users/venki.nagaraj/Desktop/IWB/Metis/IntegratedWhiteBoard/IWB/deployment/exportedfiles/",
    uploadThredyImage:
      "C:/Users/mohammed.sameer/Desktop/12sep_ppds/IntegratedWhiteBoard/backendIWB/public/threedyDetails/",
      googleapitoken:"AIzaSyB6zw3VX3Y1vHPzzQa9wn5sjctUFDQp8Js",
      googlecxtoken:"76aeb701558384b27"
  },
  acc: {
    serverport: 3000,
    serverurl: "https://inblrlxapp003.tpvaoc.com/iwbNode",
    mongoUrl: "mongodb://localhost:27017/Iwb_Europe",
    serverPath: "",
    enableTranslations: true,
    etheradURL: "https://inblrlxapp003.tpvaoc.com",
    joinavurl:"https://inblrlxapp003.tpvaoc.com/fabricang/#",
    callbackUrl: "https://inblrlxapp003.tpvaoc.com",
    etherpadApiKey:
      "12be93e1bd1e7286627f44055cd4ef7daabfa5b09eef2365155f1afcaec7005c",
    avChatUrl: "wss://classroom.tpvai.com/kmswss",
    imageUploadLocation: "/home/ubuntu/workarea/IWB/etherpadUploads/",
    padPreviewLocation: "/home/ubuntu/workarea/IWB/padStorage/",
    pdfUploads: "/home/ubuntu/workarea/IWB/padUploads",
    tempFile: "/home/ubuntu/workarea/IWB/tempImportedFiles",
    penDrawURL: "https://inblrlxapp003.tpvaoc.com/drawpen/d",
    rediServer: "inblrlxapp003.tpvaoc.com",
    redisPort: 11000,
    exportedFileLocation: "/home/ubuntu/workarea/IWB/exportedfiles/",
    uploadThredyImage: "/home/ubuntu/workarea/IWB/threedyDetails/",
    stickyNoteUploadPath: "/home/ubuntu/workarea/IWB/stickyNotes/",
    googleapitoken:"AIzaSyB6zw3VX3Y1vHPzzQa9wn5sjctUFDQp8Js",
    googlecxtoken:"76aeb701558384b27"
  },
  prodclass: {
    serverport: 3300,
    serverurl: "https://classroom.tpvai.com/iwbNode", //"https://wpdev.tpvai.com"
    mongoUrl: "mongodb://localhost:27017/Iwb_Europe",
    serverPath: "",
    enableTranslations: true,
    etheradURL: "https://classroom.tpvai.com", //'https://wpdev.tpvai.com'
    joinavurl:"https://classroom.tpvai.com",
    callbackUrl: "https://classroom.tpvai.com",//"https://classroom.tpvai.com", //"https://wpdev.tpvai.com",
    avChatUrl: "wss://classroom.tpvai.com/kmswss",//"wss://classroom.tpvai.com/kmswss",//"wss://ppdsedu.tpvai.com/kmswss", //"wss://wpdev.tpvai.com/kmswss"
    etherpadApiKey:
      "12be93e1bd1e7286627f44055cd4ef7daabfa5b09eef2365155f1afcaec7005c",
    imageUploadLocation: "/home/ubuntu/ppdsBackend/etherpadUploads/",
    padPreviewLocation: "/home/ubuntu/ppdsBackend/padStorage/",
    pdfUploads: "/home/ubuntu/ppdsBackend/padUploads",
    tempFile: "/home/ubuntu/ppdsBackend/tempImportedFiles",
    penDrawURL: "https://classroom.tpvai.com/drawpen/d", //"https://wpdev.tpvai.com/drawpen/d",
    rediServer: "wpdev.tpvai.com",
    redisPort: 6379,
    exportedFileLocation: "/home/ubuntu/ppdsBackend/exportedfiles/",
    uploadThredyImage: "/home/ubuntu/ppdsBackend/threedyDetails/",
    stickyNoteUploadPath: "/home/ubuntu/ppdsBackend/stickyNotes/",
    googleapitoken:"AIzaSyB6zw3VX3Y1vHPzzQa9wn5sjctUFDQp8Js",
    googlecxtoken:"76aeb701558384b27"
  },

  gmain: {
    serverport: 3300,
    serverurl: "https://genius-main.tpvai.com/iwbNode", //"https://wpdev.tpvai.com"
    mongoUrl: "mongodb://localhost:27017/Iwb_Europe",
    serverPath: "",
    enableTranslations: true,
    etheradURL: "https://genius-main.tpvai.com", //'https://wpdev.tpvai.com'
    joinavurl:"https://genius-main.tpvai.com",
    callbackUrl: "https://genius-main.tpvai.com", //"https://wpdev.tpvai.com",
    avChatUrl: "wss://genius-main.tpvai.com/kmswss",//"wss://ppdsedu.tpvai.com/kmswss", //"wss://wpdev.tpvai.com/kmswss"
    etherpadApiKey:
      "12be93e1bd1e7286627f44055cd4ef7daabfa5b09eef2365155f1afcaec7005c",
    imageUploadLocation: "/home/ubuntu/ppdsBackend/etherpadUploads/",
    padPreviewLocation: "/home/ubuntu/ppdsBackend/padStorage/",
    pdfUploads: "/home/ubuntu/ppdsBackend/padUploads",
    tempFile: "/home/ubuntu/ppdsBackend/tempImportedFiles",
    penDrawURL: "https://ppdsedu.tpvai.com/drawpen/d", //"https://wpdev.tpvai.com/drawpen/d",
    rediServer: "wpdev.tpvai.com",
    redisPort: 6379,
    exportedFileLocation: "/home/ubuntu/ppdsBackend/exportedfiles/",
    uploadThredyImage: "/home/ubuntu/ppdsBackend/threedyDetails/",
    stickyNoteUploadPath: "/home/ubuntu/ppdsBackend/stickyNotes/",
    googleapitoken:"AIzaSyB6zw3VX3Y1vHPzzQa9wn5sjctUFDQp8Js",
    googlecxtoken:"76aeb701558384b27"
  },

  prod: {
    serverport: 3300,
    serverurl: "https://ppdsedu.tpvai.com/iwbNode", //"https://wpdev.tpvai.com"
    mongoUrl: "mongodb://localhost:27017/Iwb_Europe",
    serverPath: "",
    enableTranslations: true,
    etheradURL: "https://ppdsedu.tpvai.com", //'https://wpdev.tpvai.com'
    joinavurl:"https://ppdsedu.tpvai.com",
    callbackUrl: "https://ppdsedu.tpvai.com", //"https://wpdev.tpvai.com",
    avChatUrl: "wss://ppdsedu.tpvai.com/kmswss",//"wss://ppdsedu.tpvai.com/kmswss", //"wss://wpdev.tpvai.com/kmswss"
    etherpadApiKey:
      "12be93e1bd1e7286627f44055cd4ef7daabfa5b09eef2365155f1afcaec7005c",
    imageUploadLocation: "/home/ubuntu/ppdsBackend/etherpadUploads/",
    padPreviewLocation: "/home/ubuntu/ppdsBackend/padStorage/",
    pdfUploads: "/home/ubuntu/ppdsBackend/padUploads",
    tempFile: "/home/ubuntu/ppdsBackend/tempImportedFiles",
    penDrawURL: "https://ppdsedu.tpvai.com/drawpen/d", //"https://wpdev.tpvai.com/drawpen/d",
    rediServer: "wpdev.tpvai.com",
    redisPort: 6379,
    exportedFileLocation: "/home/ubuntu/ppdsBackend/exportedfiles/",
    uploadThredyImage: "/home/ubuntu/ppdsBackend/threedyDetails/",
    stickyNoteUploadPath: "/home/ubuntu/ppdsBackend/stickyNotes/",
    googleapitoken:"AIzaSyB6zw3VX3Y1vHPzzQa9wn5sjctUFDQp8Js",
    googlecxtoken:"76aeb701558384b27"
  },
};

exports.configs = configs;
