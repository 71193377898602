import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from "../file.service";
// import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-passrecovery',
  templateUrl: './passrecovery.component.html',
  styleUrls: ['./passrecovery.component.scss']
})
export class PassrecoveryComponent implements OnInit {

  loginForm: FormGroup; 
  public user;

  constructor(public router: Router, public fileservice: FileService, private formBuilder: FormBuilder, private route: ActivatedRoute, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.initForm();
    this.user = this.route.snapshot.queryParams;
  }

  initForm() {
    // Initialize the login form with FormBuilder
    this.loginForm = this.formBuilder.group({
      emailid: ['', Validators.required] // Add validation if needed
    });


  }

  // login(val) {
  //   var username = '';
  //   console.log(this.user);

  //   if(this.user) {
  //     if (this.user.user) {
  //       val.loginname = this.user.user;
  //     }
  //   }
  //   if (username == '') {
  //     username = val.loginname
  //     console.log('user', username);

  //     if (!val.loginname) {
  //       alert ("Please enter valid username");
  //       return false;
  //     }
  //   }
  // }

  userID:any;
  url: any = environment.joinavurl;

  resetLink() {
    return `${this.url}`;
  }

  userpass(event: Event) {
    // Prevent the default behavior of the anchor element
    event.preventDefault();

    // Navigate using Angular Router
    this.router.navigate(['/u&plogin']);
  }

  sendForgotPasswordEmail() {
    // Get the username from the form control
    // this.userID = JSON.parse(localStorage.getItem('userId'));
    // console.log('userID logg', this.userID)
    const emailid = this.loginForm.get('emailid').value;
    console.log('username entered', emailid)

    const resetlink = `${this.url}`;
    console.log('resetlink', resetlink);

    if (!emailid) {
      this.dialogService.showError('Please enter the username');
      return false
    }

    
    // if (username === this.userID) {
      console.log('inside the if statement')
      this.fileservice.sendForgotPasswordEmail(emailid, resetlink).subscribe(
        (response) => {
          console.log('Forgot password email sent successfully', response);
          this.dialogService.showSimplePopup('Email sent successfully')
        },
        (error) => {
          if (error.status === 400 && error.error.error === 'Invalid emailId provided') {
            this.dialogService.showError('Please enter a valid Email ID.');
        } else{         
            console.error('Error sending forgot password email:', error);
            this.dialogService.showError('Username does not exist. Please enter an existing username');
        }
        }
      );
    console.log('username entered', emailid)
  }

  clear(){
    this.loginForm.patchValue({
      emailid: ""
    });
  }
}
