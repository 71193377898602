import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { FileService } from "../file.service";
import { Router } from '@angular/router';

@Injectable()
export class HelperService {

    private languageChangedSource = new Subject<string>();
    private userUILanguageChangedSource = new Subject<string>();

    languageChanged$ = this.languageChangedSource.asObservable();
    userUILanguage$ = this.userUILanguageChangedSource.asObservable();


    constructor(public auth: FileService, public router: Router) {}

    changeUserUILanguage(userUiLang) {
        this.userUILanguageChangedSource.next(userUiLang);
    }

    changeUiLanguage(setlanguage) {
        this.languageChangedSource.next(setlanguage);
        // this.saveUserPref({ui_language: setlanguage});
    }

    // saveUserPref(data) {
    //     this.auth.getTVInformation(data, "/tv/saveuserprefs");
    // }
    private lang: string = 'en';
    setLang(lang: string) {
        this.lang = lang;
      }
    
      getLang(): string {
        return this.lang;
      }

    validateUserForPage() {
        var isLoggedIn = localStorage.getItem('session_id');
        if(!(isLoggedIn && isLoggedIn.length>0)){
            this.router.navigate(['/qrlogin']);
        } 
        else {


            this.router.navigate(["/whiteboard"]);
            //this.router.navigate(['/qrlogin'])
            // this.auth
            //     .getTVInformation(
            //         {'page': "/" + page},
            //         '/user/validateuserpage'
            //     )
            //     .then((res: any) => {
            //         if (res.status === "server-nok") {
            //             this.router.navigate(['/qrlogin'])
            //         } else if (res.status !== "ok") {
            //             this.router.navigate([res.landing_page]);
            //         }
            //     }, (err: any) => {
                    
            //     });
        }
    }

}
