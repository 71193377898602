import { Injectable } from "@angular/core";
import { UserManager, User } from "oidc-client";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { authConfig } from "./auth-config";
@Injectable({
  providedIn: "root",
})
export class GoogleAuthService {
  private _userManager: UserManager;
  private _user: User;
  // private _userSubject: Subject<User> = new Subject<User>();
  private _userSubject: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);

  // Expose this subject as an Observable for components to subscribe to
  userState$: Observable<User | null> = this._userSubject.asObservable();

  CurrentUser = this._userSubject.asObservable();
  constructor() {
    this._userManager = new UserManager(authConfig);
  }
  signin() {
    this._userManager.signinRedirect().then((res) => console.log(res));
  }

  signout() {
    this._userManager.signoutRedirect().then(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        if (err.message !== "no end session endpoint") throw err;
        //let url = `http://localhost:4200/whiteboard/signout-callback`;
        let url = `${environment.etheradURL}/whiteboard/signout-callback  `;
        window.location.href = url;
      }
    );
  }

  signInComplete() {
    return this._userManager.signinRedirectCallback();
  }

  signOutComplete() {
    return this._userManager.signoutRedirectCallback();
  }

  // isLoggedIn(): Promise<boolean> {
  //   return this._userManager.getUser().then((user) => {
  //     var loggedIn = !!user && !user.expired;
  //     if (this._user != user) {
  //       this._userSubject.next(loggedIn ? user : null);
  //     }
  //     if (loggedIn) {
  //       this._user = user;
  //     }
  //     return loggedIn;
  //   });
  // }

  isLoggedIn(): Promise<boolean> {
    return this._userManager.getUser().then((user) => {
      if (!!user && user.expires_in > 0 && user.expires_in < 60) {
        // Access token about to expire in less than 60 seconds, refresh token
        this._userManager.signinSilent().then((newUser) => {
          this._user = newUser;
          this._userSubject.next(newUser);
        });
      }

      const loggedIn = !!user && !user.expired;
      if (this._user !== user) {
        this._userSubject.next(loggedIn ? user : null);
      }
      if (loggedIn) {
        this._user = user;
      }
      return loggedIn;
    });
  }

  getAccessToken(): Promise<string | void> {
    return this._userManager.getUser().then((user) => {
      return !!user && !user.expired ? user.access_token : "";
    });
  }

  getUserId(): string {
    return this.isLoggedIn() ? this._user.profile.sub : "";
  }
}
