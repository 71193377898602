// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { configs } from '../../configf/configs.js';
export const environment = {
    gwed_server_mode: "prod",
    mute_ai: "true",
    serverurl: configs.acc.serverurl,
    production: true,
    enableFabricPath : true,
    enableTranslations: configs.acc.enableTranslations,
    enable_symptom_predictions: true,
    etheradURL:configs.acc.etheradURL,
    joinavurl:configs.acc.joinavurl,
    callbackUrl: configs.acc.callbackUrl,
    avChatUrl: configs.acc.avChatUrl,
    penDrawURL: configs.acc.penDrawURL,
    appVersion: 'inblr-0.0.1',
    updateDate: '2024-04-05',
    googleapitoken:configs.acc.googleapitoken,
    googlecxtoken:configs.acc.googlecxtoken
};
