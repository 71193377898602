import { Component, OnInit } from '@angular/core';
import { BrowserDetectionService } from '../browser-detection.service';

@Component({
  selector: 'app-browser-warning',
  templateUrl: './browser-warning.component.html',
  styleUrls: ['./browser-warning.component.scss']
})

export class BrowserWarningComponent implements OnInit {
  browserInfo: any;

  constructor(private browserDetectionService: BrowserDetectionService) { }

  ngOnInit(): void {
    this.browserInfo = this.browserDetectionService.getBrowserInfo();
  }
}
