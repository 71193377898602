import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private messageSource = new BehaviorSubject<{ message: string; type: string } | null>(null);
  message$ = this.messageSource.asObservable();

  private popupSource = new BehaviorSubject<string | null>(null);
  popup$ = this.popupSource.asObservable();

  constructor() { }

  showError(message: string) {
    console.log("inside dailogservice ",message)
    this.messageSource.next({message, type: "simple" });
  }

  showAVQRError(message: string) {
    console.log("inside dailogservice ",message)
    this.messageSource.next({message, type: "avqr" });
  }

  clearError() {
    this.messageSource.next(null);
  }

  showSimplePopup(popup: string) {
    this.popupSource.next(popup);
  }

  hidePopup() {
    this.popupSource.next(null); // Clear the popup message
  }

  showConfirmation(message:string) {
    this.messageSource.next({message, type: "confirmation" });
  }




  private pageToBeDeleted = new Subject();
  deletePage$ = this.pageToBeDeleted.asObservable();

  private DELETE_PAGE_NO:number = -1;
  /**
   * This function stores the page number of the page to be deleted in the dialog service.
   * @param pageNumber Page Number of the page that needs to be deleted.
   */
  storePageNumberToBeDeleted(pageNumber) {
    console.log("05-11-2024",pageNumber);
    this.DELETE_PAGE_NO = pageNumber;
  }

  /**
   * This function updates the pageToBeDeleted subject.
   * Whenever the pageToBeDeleted Subject is updated, page deletion will happen.
   * Before this function is called, the dialog service must be given the page number of the 
   * page to be deleted. To do this call storePageNumberToBeDeleted.
   */
  updatePageToBeDeletedSubject() {
    console.log("05-11-2024 _deletePage",this.pageToBeDeleted);
    this.pageToBeDeleted.next(this.DELETE_PAGE_NO);
  }

}
