import { Component, OnInit,HostListener,Output,EventEmitter,ViewChild } from '@angular/core';
import * as QRCode from 'qrcode';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
//import { VotingServiceService } from '../voting-service.service';
import { environment } from "../../environments/environment";
import { FileService } from '../file.service';
import { WhiteboardpageComponent } from '../whiteboardpage/whiteboardpage.component';
import { EditorcanvasComponent } from 'projects/angular-editor-fabric-js/src/public-api';


@Component({
  selector: 'app-voting-section',
  templateUrl: './voting-section.component.html',
  styleUrls: ['./voting-section.component.scss']
})
export class VotingSectionComponent implements OnInit {



  lastWheelEventTime: number = 0;
  valueChangeInterval: number = 1000;
  //valueChangeInterval: number = 1500;
  value: number = 2;
  showqrcode:Boolean=false;
  prevalue:number=0;
  nextvalue:number=3;
  qrCodeData = environment.joinavurl; // URL to be encoded as a QR code
  qrCodeImage: string | null = null;
  isMultiselect:Boolean=false
  isAnonymousInput:Boolean=false;
  uyyl:string="http://localhost:4200";
  showgraph:Boolean=false;
  env=environment

  @ViewChild('canvas', { static: false })
  mycanvas: EditorcanvasComponent;
  @Output() closeShowoption = new EventEmitter<Boolean>();
  totalvotes:any[]=[]
  rdata:any
  showbargraph:Boolean=true;
  isgraphdragging:Boolean=false;
  isgraphdraggingpart:Boolean=false;
  showtable:Boolean=false;
  prevmovement:any;
  initailtouchvalue:any
  isTouching:Boolean=false;
  tabletoshow=[]
  startY: number = 0;
  addtowhiteboard=false;
  isTouchActive = false;
  chartDataURL: string | null = null;
 copyqrlink:any;
 uniquevoteid:any
 insertedbarchart:Boolean=false;
 insertedPiechart:Boolean=false;
 showtablebtn:Boolean=true;
  answered_recieved=[

    {
        "Name": "Alex",
        "UniqueVoteId": "12345",
        "Options": [1]
    },
    {
        "Name": "Jone",
        "UniqueVoteId": "12346",
        "Options": [2]
    },
    {
        "Name": "Bob",
        "UniqueVoteId": "12347",
        "Options": [3]
    },
    {
        "Name": "John",
        "UniqueVoteId": "12348",
        "Options": [3]
    },
    {
      "Name": "Alex",
      "UniqueVoteId": "12345",
      "Options": [5]
  },
  {
    "Name": "Alex",
    "UniqueVoteId": "12345",
    "Options": [4]
},
  ]


  avchatmode:Boolean=false;
  roomId:any;
  totalvotesrecieved:any;




  constructor(private router: Router,private voteurl: FileService,public whiteboardcomp :WhiteboardpageComponent, ) {




    this.voteurl.handlevotesSocketAnswerRecieved().subscribe((val:any)=>{
      this.tabletoshow=[]
      console.log(val,"value")
      this.voteurl.joinToClass();

      let dataneeded=val.data
      // let datatoshow=dataneeded.filter((item)=>
      //    item.uniqueVoteId===this.uniquevoteid
      // )
      let datatoshow = dataneeded.filter((item) => {
        console.log("Item:", item);
        console.log("this.uniquevoteid:", this.uniquevoteid);
        console.log(item.UniqueVoteId,"lllllll")
        return item.UniqueVoteId == this.uniquevoteid;
    });
      let total=datatoshow.map((vote=>{
        let data=
        vote.Options.map(option=>"option "+option)
        vote.option=data;
        return vote
      }
        ))


        this.totalvotes=total;

        if(this.avchatmodeconnection){
          this.roomId= localStorage.getItem("roomID")
          let show=""
          if(this.showqrcode===true){
            show="qr"
          }else{
            show="charts"
          }

       // let datatosend={"roomID":this.roomId,"url":this.urltoshare,"votingstatus":"active","show":show,"recievedvotes":total}
       let datatosend={"roomID":this.roomId,"url":this.qrCodeImage,"votingstatus":"active","show":"qr","recievedvotes":this.totalvotes}
          this.voteurl.emitWebSocketEvent("voting-url",datatosend)
         };
     // this.totalvotes=val.data;
     for(let i=0;i<total.length; i += 2){
      let data: {[k: string]: any} = {};
      console.log(i);
      //let data={};
      let name1=total[i].Name.length<=7?total[i].Name:this.truncateSentence(total[i].Name,7)
      data["student1name"]=name1;
      data["student1option"]=total[i].option;
      if(total.length>i+1&&total.length!=i+1){
        console.log(i)
        let name2=total[i+1].Name.length<=7?total[i+1].Name:this.truncateSentence(total[i+1].Name,7)
        data["student2name"]=name2;
      data["student2option"]=total[i+1].option;
      }
      console.log(data,"data")

       this.tabletoshow.push(data)
     }
     this.insertedbarchart=false;
     this.insertedPiechart=false
      this.voteurl.emitWebSocketEvent("GetTotalvotebyid",{});
     })



     this.avchatmode=this.voteurl.fetchfilesstatus().activemode;
     if(this.avchatmode){
      this.roomId= localStorage.getItem("roomID")
      console.log("votingBandana roomID",this.roomId);
      this.voteurl.emitWebSocketEvent("joinSocketRoom",{"roomid":this.roomId})
     };


  }


  ngOnInit(): void {


    this.voteurl.handlevotesSocketAnswerRecieved().subscribe((val:any)=>{
      this.tabletoshow=[]
      console.log(val,"value")
      this.voteurl.joinToClass();

      let dataneeded=val.data
      // let datatoshow=dataneeded.filter((item)=>
      //    item.uniqueVoteId===this.uniquevoteid
      // )
      let datatoshow = dataneeded.filter((item) => {
        console.log("Item:", item);
        console.log("this.uniquevoteid:", this.uniquevoteid);
        console.log(item.UniqueVoteId,"lllllll")
        return item.UniqueVoteId == this.uniquevoteid;
    });
      let total=datatoshow.map((vote=>{
        let data=
        vote.Options.map(option=>"option "+option)
        vote.option=data;
        return vote
      }
        ))

        console.log(total,"total")
        this.totalvotes=total;
        console.log(this.totalvotes,"totalvotes")
        for(let i=0;i<total.length; i += 2){
          let data: {[k: string]: any} = {};
          console.log(i);
          //let data={};
          let name1=total[i].Name.length<=7?total[i].Name:this.truncateSentence(total[i].Name,7)
          data["student1name"]=name1;
          data["student1option"]=total[i].option;
          if(total.length>i+1&&total.length!=i+1){
            console.log(i)
            let name2=total[i+1].Name.length<=7?total[i+1].Name:this.truncateSentence(total[i+1].Name,7)
            data["student2name"]=name2;
          data["student2option"]=total[i+1].option;
          }
          console.log(data,"data")

           this.tabletoshow.push(data)
         }
         this.insertedbarchart=false;
         this.insertedPiechart=false
      this.voteurl.emitWebSocketEvent("GetTotalvotebyid",{});
     })

    //  this.avchatmode=this.voteurl.fetchfilesstatus().activemode;
    //  if(this.avchatmode){
    //   this.roomId= localStorage.getItem("roomID")
    //   console.log("votingBandana roomID",this.roomId);
    //   this.voteurl.emitWebSocketEvent("joinSocketRoom",{"roomid":this.roomId})
    //  };
this.checkavchatconnection();


   }

    makeqrcode(id: string) {
      this.voteurl.emitWebSocketEvent("newVote",{data:"votte"})
    this.voteurl.getoptionsdata(id).subscribe(result => {

      const stringurl = `${this.qrCodeData}/voting?uniqueVoteId=${id}&options=${result.data.NumberOfOptions}&Multiselect=${result.data.Multiselect}&AnonymousInput=${result.data.AnonymousInput}`;
      this.uyyl = `${this.uyyl}/voting?uniqueVoteId=${id}&options=${result.data.NumberOfOptions}&Multiselect=${result.data.Multiselect}&AnonymousInput=${result.data.AnonymousInput}`;
      this.copyqrlink=stringurl;
      console.log(stringurl,"stringurl");
      console.log(this.uyyl,"stringurl");
      this.showtablebtn=result.data.AnonymousInput;


      console.log(this.showtablebtn,"this.showtablebtn")

      QRCode.toDataURL(stringurl, (err, qrCodeUrl) => {
        if (err) {
          console.error('Error generating QR code:', err);
          return;
        }
        console.log("no error", qrCodeUrl, stringurl);
        this.qrCodeImage = qrCodeUrl;
        this.checkavchatconnection();
        if(this.avchatmodeconnection){


          let datatosend={"roomID":this.roomId,"url":this.qrCodeImage,"votingstatus":"active","show":"qr","recievedvotes":this.totalvotes}
          this.voteurl.emitWebSocketEvent("voting-url",datatosend);
        }
      });
    }, error => {
      console.log(error);
    });
  }









   generateResult(){
   this.showgraph=true;
   this.showqrcode=false
   this.checkavchatconnection();
   if(this.avchatmodeconnection){

    let datatosend={"roomID":this.roomId,"url":this.qrCodeImage,"votingstatus":"active","show":"charts","selectedchart":"Bar","charturl":this.chartDataURL,"recievedvotes":this.totalvotes}
    this.voteurl.emitWebSocketEvent("voting-url",datatosend);
  }
   }


   @HostListener('wheel', ['$event'])
   onMouseWheel(event: WheelEvent) {
     this.handleWheelEvent(event);
   }



 truncateSentence(sentence:any, maxLength:any) {
  if (sentence.length > maxLength) {
      return sentence.slice(0, maxLength) + "...";
  } else {
      return sentence;
  }
}







  private handleWheelEvent(event: WheelEvent) {

    const currentTime = Date.now();

    if (currentTime - this.lastWheelEventTime >= this.valueChangeInterval) {
      console.log(event.deltaY, "event delay");

      if (event.deltaY > 0) {
        // Increment and loop back to 2 if it exceeds 7
        this.value = this.value < 7 ? this.value + 1 : 2;
        console.log(this.value, "this.value after incrementing");
      } else {
        // Decrement and loop back to 7 if it goes below 2
        this.value = this.value > 2 ? this.value - 1 : 7;
        console.log(this.value, "this.value after decrementing");
      }

      this.prevalue = this.value === 2 ? 7 : this.value - 1;
      this.nextvalue = this.value === 7 ? 2 : this.value + 1;

      this.lastWheelEventTime = currentTime;
    }
  }














   private isDragging = false;
  private offsetX: number = 0;
  private offsetY: number = 0;




  closeShowVote(){

    // this.showvote=false
    let closeshowoption=false;
    this.closeShowoption.emit(closeshowoption);
    this.checkavchatconnection();
    if(this.avchatmodeconnection){

     let datatosend={"roomID":this.roomId,"url":this.qrCodeImage,"votingstatus":"inactive"}
     this.voteurl.emitWebSocketEvent("voting-url",datatosend);
    }
    if(this.uniquevoteid){
    this.voteurl.closeVoting(this.uniquevoteid).subscribe((result)=>{
     console.log(result,"result,,,,,,,,,,,,,,")
 },error=>{
     console.log(error)
   })

    this.voteurl.emitWebSocketEvent("removeunwantedvotes",{uniquevoteid:this.uniquevoteid});
    console.log(this.uniquevoteid,"this.uniquevoteid");

    }


   }



  close_showqrcode(){
   this.showqrcode=false;
   if(this.showgraph===false)
     this.closeShowVote();

  }


  navigateToOtherComponent() {

    this.router.navigate([this.uyyl]);
  }


  showvote:Boolean=true;

  generateqrCode(optionForm:any){

    this.showqrcode=true;
    this.showvote=false;
    const timestamp = Date.now();
const randomString = Math.random().toString(36).substr(2, 9);

const uniqueId = timestamp+randomString;
this.uniquevoteid=uniqueId
console.log(uniqueId);
    console.log(this.isMultiselect,this.isAnonymousInput,this.value)

    let data={"UniqueVoteId":uniqueId,"NumberOfOptions":this.value,"Multiselect":this.isMultiselect,"AnonymousInput":this.isAnonymousInput};
 console.log(data);
    this.voteurl.saveuseroptions(data).subscribe((result)=>{
      console.log(result)
      this.makeqrcode(uniqueId)
    },error=>{
      console.log(error)
    })




  }







onMouseUpGraphContainer(): void {

 this.isgraphdragging=false
}

handlegraphs(value:any){
  console.log(value)
  this.showbargraph=value;
}


handleShowtable(){

  if(this.showtablebtn){
    return
  }
if(this.showtable)
this.showtable=false;
else
this.showtable=true;
}


close_graph(){
  this.showgraph=false
  if(this.showqrcode===false)
  this.closeShowVote()
}





onMouseDown(event: MouseEvent): void {
  this.startDragging(event.clientX, event.clientY);
}

onTouchStart(event: TouchEvent): void {
  const touch = event.touches[0];
  this.startDragging(touch.clientX, touch.clientY);
}

onMouseMove(event: MouseEvent): void {
  this.handleDrag(event.clientX, event.clientY);
}

onTouchMove(event: TouchEvent): void {
  const touch = event.touches[0];
  this.handleDrag(touch.clientX, touch.clientY);
}

onMouseUp(): void {
  this.isDragging = false;
}

onTouchEnd(): void {
  this.isDragging = false;
}

private startDragging(clientX: number, clientY: number): void {
  this.isDragging = true;
  this.offsetX = clientX;
  this.offsetY = clientY;
}

private handleDrag(clientX: number, clientY: number): void {
  if (this.isDragging) {
    const div = document.querySelector('.child1_container') as HTMLElement;
    const deltaX = clientX - this.offsetX;
    const deltaY = clientY - this.offsetY;
    this.offsetX = clientX;
    this.offsetY = clientY;

    div.style.left = (div.offsetLeft + deltaX) + 'px';
    div.style.top = (div.offsetTop + deltaY) + 'px';
  }
}
/////////////////////////////////////////////Graph Dragging///////////////////////////////////////////////////////
onMouseDowngraphContainer(event: MouseEvent): void {
  this.startDraggingGraph(event.clientX, event.clientY);
}

onTouchStartgraphContainer(event: TouchEvent): void {
  const touch = event.touches[0];
  this.startDraggingGraph(touch.clientX, touch.clientY);
}

onMouseMoveGraphContainer(event: MouseEvent): void {
  this.handleDragGraph(event.clientX, event.clientY);
}

onTouchMovegraphContainer(event: TouchEvent): void {
  const touch = event.touches[0];
  this.handleDragGraph(touch.clientX, touch.clientY);
}


onTouchEndgraphContainer(): void {
  //this.isDragging = false;
  this.isgraphdragging = false;
}

private startDraggingGraph(clientX: number, clientY: number): void {
  //this.isDragging = true;
  this.isgraphdragging = true;
  this.offsetX = clientX;
  this.offsetY = clientY;
}

private handleDragGraph(clientX: number, clientY: number): void {
  if (this.isgraphdragging) {
    const div = document.querySelector('.child7_container') as HTMLElement;
    const deltaX = clientX - this.offsetX;
    const deltaY = clientY - this.offsetY;
    this.offsetX = clientX;
    this.offsetY = clientY;

    div.style.left = (div.offsetLeft + deltaX) + 'px';
    div.style.top = (div.offsetTop + deltaY) + 'px';
  }
}


onTouchStartscroll(event: TouchEvent): void {
  console.log("hiii")
  this.isTouching = true;
  this.startY = event.touches[0].clientY;
}






onTouchMovescroll(event: TouchEvent): void {
  if (!this.isTouching) return;

  const currentTime = Date.now();

  if (currentTime - this.lastWheelEventTime >= this.valueChangeInterval) {
    const deltaY = event.touches[0].clientY - this.startY;

    // Adjust the counter based on the scroll direction
    if (deltaY > 0) {
      // Decrement and loop back to 7 if it goes below 2
      this.value = this.value > 2 ? this.value - 1 : 7;
    } else {
      // Increment and loop back to 2 if it exceeds 7
      this.value = this.value < 7 ? this.value + 1 : 2;
    }

    // Update the displayed value
    this.startY = event.touches[0].clientY;
    this.prevalue = this.value === 2 ? 7 : this.value - 1;
    this.nextvalue = this.value === 7 ? 2 : this.value + 1;
    this.lastWheelEventTime = currentTime;
  }
}


onTouchEndscroll(event: TouchEvent): void {
  this.isTouching = false;
}





onMouseDowngraphpart(event: MouseEvent): void {
  this.startDraggingGraphpart(event.clientX, event.clientY);
}

onTouchStartgraphpart(event: TouchEvent): void {
  const touch = event.touches[0];
  this.startDraggingGraphpart(touch.clientX, touch.clientY);
  this.addTouchClass();
}

onMouseMovegraphpart(event: MouseEvent): void {
  this.handleDragGraphpart(event.clientX, event.clientY);
}

onTouchMovegraphpart(event: TouchEvent): void {
  event.preventDefault();
  const touch = event.touches[0];
  this.handleDragGraphpart(touch.clientX, touch.clientY);
}

onMouseUpgraphpart(): void {
this.isgraphdraggingpart=false
}
onTouchEndgraphpart(): void {

  this.isgraphdraggingpart=false;
  this.isTouchActive = false;
}

private startDraggingGraphpart(clientX: number, clientY: number): void {

   this.isgraphdraggingpart=true
  this.offsetX = clientX;
  this.offsetY = clientY;

}

private handleDragGraphpart(clientX: number, clientY: number): void {
  if (this.isgraphdraggingpart) {
    const div = document.querySelector('.childgraph_container') as HTMLElement;
    const deltaX = clientX - this.offsetX;
    const deltaY = clientY - this.offsetY;
    this.offsetX = clientX;
    this.offsetY = clientY;

    div.style.left = (div.offsetLeft + deltaX) + 'px';
    div.style.top = (div.offsetTop + deltaY) + 'px';
  }
}

handlegraphonwhiteBoard(){

  console.log(this.selectedchart,"selectedchard")
  if(this.selectedchart==="Pie"){
    console.log("piechart,..................")
   this.insertedPiechart=true;
   this.checkdisablestatus()
  }
  if(this.selectedchart==="Bar"){
    this.insertedbarchart=true;
    this.checkdisablestatus()
  }
 this.whiteboardcomp.addvotinggraphinwhiteboards(this.chartDataURL,this.totalvotes);
 if(this.showgraph===false&&this.showqrcode===false)
  this.closeShowVote();
}


receiveChartDataURL(dataURL: string) {
  this.chartDataURL = dataURL;
  console.log(this.chartDataURL,"chartdataurl")
  this.checkavchatconnection();
  if(this.avchatmodeconnection){

    let datatosend={"roomID":this.roomId,"url":this.qrCodeImage,"votingstatus":"active","show":"charts","selectedchart":this.selectedchart,"charturl":this.chartDataURL,"recievedvotes":this.totalvotes}
    this.voteurl.emitWebSocketEvent("voting-url",datatosend);
  }
}





addTouchClass() {
  this.isTouchActive = true;
  // setTimeout(() => {
  //   this.isTouchActive = false;
  // }, 300); // Adjust the duration as needed to match the transition duration
}


closeonlygraph(){
  this.addtowhiteboard=false;
  this.closeShowVote()
}


CloseCanvasChart(value:any){
  console.log("closeing triger from ediotor canvas")
  this.addtowhiteboard=false;
  this.closeShowVote()
}

handleRevote(){

  this.showqrcode=true;
  this.showgraph=false;
  this.checkavchatconnection();
  if(this.avchatmodeconnection){


      let datatosend={"roomID":this.roomId,"url":this.qrCodeImage,"votingstatus":"active","show":"qr","recievedvotes":this.totalvotes}
      this.voteurl.emitWebSocketEvent("voting-url",datatosend);

  }
}

selectedchart:string="Bar"


selectchart(value:any){
  this.selectedchart=value;
  this.checkavchatconnection();
  if(this.avchatmodeconnection){

    let datatosend={"roomID":this.roomId,"url":this.qrCodeImage,"votingstatus":"active","show":"charts","selectedchart":this.selectedchart,"charturl":this.chartDataURL}
    this.voteurl.emitWebSocketEvent("voting-url",datatosend);
  }
}





async shareViaCopyUrl() {
  try {
    await navigator.clipboard.writeText(this.copyqrlink);
    console.log('URL copied successfully!');
  } catch (error) {
    console.error('Error copying URL:', error);

  }
}


checkdisablestatus(){
  console.log(this.selectedchart,this.insertedPiechart)
if((this.selectedchart==="Bar"&&this.insertedbarchart)||(this.selectedchart==="Pie"&&this.insertedPiechart)){
  return true
}else{
  return false
}

}

avchatmodeconnection:Boolean=false;
checkavchatconnection(){

  console.log("hi check conection bandana ")
  this.avchatmode=this.voteurl.fetchfilesstatus().activemode;
  this.roomId=this.roomId= localStorage.getItem("roomID");
  this.avchatmodeconnection=this.avchatmode?true:this.roomId?true:false;
  this.avchatmodeconnection=this.avchatmode?true:this.roomId?true:false;
  if(this.avchatmodeconnection){
   this.roomId= localStorage.getItem("roomID")
   console.log("votingBandana roomID",this.roomId);
   this.voteurl.emitWebSocketEvent("joinSocketRoom",{"roomid":this.roomId})
  };

}



}


