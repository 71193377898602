import { Component, OnInit ,QueryList ,ViewChildren} from '@angular/core';
import { RegionaladminComponent } from '../regionaladmin/regionaladmin.component';
import { CountryadminComponent } from '../countryadmin/countryadmin.component';
import { ProfileComponent  } from '../profile/profile.component';
import { SchoolComponent } from '../school/school.component';
import { SchooladminComponent } from '../schooladmin/schooladmin.component';
import { TeacherComponent } from '../teacher/teacher.component';
import { DemoaccountsComponent } from '../demoaccounts/demoaccounts.component';

@Component({
  selector: 'app-superadmin',
  templateUrl: './superadmin.component.html',
  styleUrls: ['./superadmin.component.scss']
})
export class SuperadminComponent implements OnInit {
  showregionaladminlist: boolean = false;
  showcountryadminlist: boolean = false;
  showschoollist: boolean = false;
  showschooladminlist: boolean = false;
  showdemoaccountlist: boolean = false;
  showteacherlist: boolean = false;
  showsoftware: boolean = false;
  adminrole: string;
  super: boolean = false;
  region: boolean = false;
  country: boolean = false;
  schooladmin: boolean = false;
  adminname: string;
  showdemolist: boolean= false;
  demo: boolean= false;
  schoolclk: boolean =false;
  userid: string;
  username: string;
  adminroleshown:any;

  constructor(public profilecomp : ProfileComponent,) {
    console.log("superrrr")
    this.adminrole = localStorage.getItem('adminrole')
    this.adminname = localStorage.getItem('admin')
    this.adminroleshown=this.adminrole==="superadmin"?
     "Superadmin":this.adminrole==="regionaladmin"?
     "Regional Admin":this.adminrole==="countryadmin"?"Country Admin":"School Admin"
  }

  @ViewChildren("regionaladminformation") regionaladmininformationItems: QueryList<RegionaladminComponent>;
  @ViewChildren("countryadminformation") countryadmininformationItems: QueryList<CountryadminComponent>;
  @ViewChildren("schoolinformation") schoolinformationItems: QueryList<SchoolComponent>;
  @ViewChildren("schooladmininformation") schooladmininformationItems: QueryList<SchooladminComponent>;
  @ViewChildren('teacherinformation') teacherinformationItems: QueryList<TeacherComponent>;
  @ViewChildren('demoaccountsinformation') demoaccountsinformationItems: QueryList<DemoaccountsComponent>;

  ngOnInit(): void {
    console.log("superadmin called??")
    this.adminrole = localStorage.getItem('adminrole')
    this.adminname = localStorage.getItem('userId')
    this.userid =localStorage.getItem("userId");
    this.username =localStorage.getItem("username");
    console.log(this.adminrole , this.adminname ,"getiing????")
    if(this.adminrole == 'superadmin'){
      this.super = true
      this.showregionaladminlist = true
    }
    if(this.adminrole == 'regionaladmin'){
      this.region = true
      this.showcountryadminlist = true
    }
    if(this.adminrole == 'countryadmin'){
      this.country = true
      this.showschoollist = true
      this.schoolclk = true
    }
    if(this.adminrole == 'schooladmin'){
      this.schooladmin = true
      this.showteacherlist = true
    }
    if(this.adminrole == 'teacher'){
      this.demo = true
      // this.showdemoaccountlist = true
      this.showdemoaccountlist = false
    }
  }

  ListRegionalAdmin(){
    // this.router.navigate(['regionaladmin'])
    console.log("its on superrr")
    this.showregionaladminlist = true;
    this.showcountryadminlist = false;
    this.showschoollist = false;
    this.showschooladminlist = false;
    this.showteacherlist = false;
    this.showdemoaccountlist = false;
    this.showsoftware = false;
    this.schoolclk = false
  }

  closeNav() {
    console.log("calling close nav")
    this.profilecomp.closesuperadmin();
  }

  ListCountryAdmin(){
    this.showregionaladminlist = false;
    this.showcountryadminlist = true;
    this.showschoollist = false;
    this.showschooladminlist = false;
    this.showteacherlist = false;
    this.showdemoaccountlist = false;
    this.showsoftware = false;
    this.schoolclk = false
  }

  ListSchools(){
    this.showregionaladminlist = false;
    this.showcountryadminlist = false;
    this.showschoollist = true;
    this.showschooladminlist = false;
    this.showteacherlist = false;
    this.showdemoaccountlist = false;
    this.showsoftware = false;
    this.schoolclk = true
  }

  ListSchoolAdmin(){
    this.showregionaladminlist = false;
    this.showcountryadminlist = false;
    this.showschoollist = false;
    this.showschooladminlist = true;
    this.showteacherlist = false;
    this.showdemoaccountlist = false;
    this.showsoftware = false;
  }
  ListTeachers(){
    this.showregionaladminlist = false;
    this.showcountryadminlist = false;
    this.showschoollist = false;
    this.showschooladminlist = false;
    this.showteacherlist = true;
    this.showdemoaccountlist = false;
    this.showsoftware = false;
  }
  ListDemoAccounts(){
    this.showregionaladminlist = false;
    this.showcountryadminlist = false;
    this.showschoollist = false;
    this.showschooladminlist = false;
    this.showteacherlist = false;
    this.showdemoaccountlist = true;
    this.showsoftware = false;
    this.schoolclk = false
  }
  Listsoftware(){
    this.showregionaladminlist = false;
    this.showcountryadminlist = false;
    this.showschoollist = false;
    this.showschooladminlist = false;
    this.showteacherlist = false;
    this.showdemoaccountlist = false;
    this.showsoftware = true;
    this.schoolclk = false
  }
}
