import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenGenerationService {
  private token: string;
  private sessionId: string;

  constructor() {
  }

  private generateRandomString(length: number): string {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  public generateTokenAndSessionId(): void {
    this.token = this.generateRandomString(4);
    this.sessionId = this.generateRandomString(6);
  }

  getToken(): string {
    return this.token;
  }

  getSessionId(): string {
    return this.sessionId;
  }
}
