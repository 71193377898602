import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { WhiteboardpageComponent } from "../whiteboardpage/whiteboardpage.component";
import { FileService } from "../file.service";
import * as QRCode from "qrcode";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { EditorcanvasComponent } from "projects/angular-editor-fabric-js/src/public-api";
import { BrowserDetectionService } from '../browser-detection.service';

//changes start - shreyas.s
import { GoogleAuthService } from "../gc-plugin/google-auth.component";
import { GoogleClassroomService } from "../gc-plugin/google-apis.service";
import {
  Course,
  GoogleCourseWorkMaterials,
  GoogleDriveFileMetaData,
  GoogleDriveFiles,
  GoogleListData,
} from "./googleCustomTypes";
import { formatDistanceToNowStrict } from "date-fns";
import { DialogService } from "../dialog.service";

//changes end - shreyas.s
//list all canvas
interface JsonItem {
  id: string;
  filename: string;
  originalfilename: string;
  pathUrl: string;
  mainlessonID: string;
}

@Component({
  selector: "app-filemanager",
  templateUrl: "./filemanager.component.html",
  styleUrls: ["./filemanager.component.scss"],
})
export class FilemanagerComponent implements OnInit, AfterViewInit {
  canvasData: any;
  draftFound:boolean=false;
  draftObjects: any[] = [];
  licensetype: string;
  // changes start - shreyas.s
  constructor(
    public whiteboardcomp: WhiteboardpageComponent,
    public auth: FileService,
    private http: HttpClient,
    private modalService: BsModalService,
    private elRef: ElementRef,
    private googleAuthService: GoogleAuthService, //changes - shreyas.s
    private googleClassroomService: GoogleClassroomService, //changes - shreyas.s
    private browserDetectionService: BrowserDetectionService,
    private dialogService: DialogService
  ) {
    this.mainname = "My Lessons";
    this.fetchavchatmode=this.auth.fetchfilesstatus();
    this.disablefiles=this.fetchavchatmode.activemode
    this.auth.getTriggerState().subscribe((state: boolean) => {
      if (state) {
        console.log("filemanager");
        //this.newlesson(); // Call the function in the FileManager component upon navigation
        this.userslesson=localStorage.getItem("userId")
        console.log("this.userless",this.userslesson)
        this.draftObjects = [];
       // this.auth.getlesson_private(this.userslesson).subscribe(
        this.auth.getlesson_private(this.userslesson,this.searchmylibrarylesson,this.searchpage).subscribe(
          (response: any) => {
          console.log("DDDDDDDDDDDDDDDDDDDD", response);
          this.jsonList_private = response; // Assign the response directly to jsonList
          console.log("EEEEEEEEEEEEEEEEEEE", this.jsonList_private);
          console.log("lengthtttttttttttt", this.jsonList_private.length);
          },
          (error: any) => {
            console.error(error);
          }
        );
        this.auth.setTriggerState(false); // Reset the trigger state
      }
    });

    this.selectedLessonfileid=localStorage.getItem('FileID')
    console.log(this.selectedLessonfileid,"this.selectedLessonid")
  }
  // changes end - shreyas.s
  @ViewChild("save_exit") save_exit: TemplateRef<any>;
  @ViewChild("move_exit") move_exit: TemplateRef<any>;
  @ViewChild("canvas", { static: false }) mycanvas: EditorcanvasComponent;
  @ViewChild("pdfimgmodalfile_exit") pdfimgmodalfile_exit: TemplateRef<any>;
  @ViewChild("filesalert_exit") filesalert_exit: TemplateRef<any>;
  @Output() childEvent = new EventEmitter<void>();
  @Output() addGoogleCourseMaterialEvent = new EventEmitter<any>(); //change shreyas.s
  @Output() addUploadedDocumentToCanvasEvent = new EventEmitter<any>(); //change shreyas.s
  @Output() add3DObjectToViewerEvent = new EventEmitter<any>();
  ///////////////////public library////
  @ViewChild('dotPub',{ static: false }) dotpubElement:  ElementRef
  @ViewChild('dotPubMyLesson',{ static: false }) dotpubmylessonElement:  ElementRef
  @ViewChild('myLibImages',{ static: false }) myLibImages:  ElementRef;
  @ViewChild('myLibVideos',{ static: false }) myLibVideos:  ElementRef;
  @ViewChild('myLib3D',{ static: false }) myLib3D:  ElementRef;
  @ViewChild('myLibAudio',{ static: false }) myLibAudio:  ElementRef;
  @ViewChild('share3DFileopt',{ static: false }) share3DFileopt:  ElementRef;
  @ViewChild('myLibDoc',{ static: false }) myLibDoc:  ElementRef;
  @ViewChild('accessTo3d',{ static: false }) accessTo3d:  ElementRef;
  @ViewChild('publicImages',{ static: false }) publicImages:  ElementRef;
  @ViewChild('shareFileopt',{static:false})shareFileopt:ElementRef
  @ViewChild('accessTopub',{static:false})accessTopub:ElementRef
  @ViewChild('publicExtraoption',{static:false})publicExtraoption:ElementRef
  @ViewChild('shareFileoptvideo',{static:false})shareFileoptvideo:ElementRef
  @ViewChild('accessTopubvideo',{static:false})accessTopubvideo:ElementRef
  @ViewChild('binmoreoption',{static:false})binmoreoption:ElementRef

  ////////////////////////////////////////////search functionality/////////////////////

  @ViewChild('scrollevent',{ static: false }) scrollevent:  ElementRef;
  @ViewChild('videoscroll',{ static: false }) videoscroll:  ElementRef;
  @ViewChild('audioscrollevent',{ static: false }) audioscrollevent:  ElementRef;
  @ViewChild('documentscroll',{ static: false }) documentscroll:  ElementRef;
  @ViewChild('mylessonscroll',{ static: false }) mylessonscroll:  ElementRef;
  @ViewChild('publicDocumentscroll',{ static: false }) publicDocumentscroll:  ElementRef;
  @ViewChild('publicAudioScroll',{ static: false }) publicAudioScroll:  ElementRef;
  @ViewChild('publicVideoscroll',{ static: false })publicVideoscroll:  ElementRef;
  @ViewChild('publicimagescrollevent',{ static: false })publicimagescrollevent:  ElementRef;
  @ViewChild('publiclessonscroll',{ static: false })publiclessonscroll:ElementRef;
  @ViewChild('binscrollevent',{ static: false })binscrollevent:ElementRef;
  @ViewChild('threeDscroll',{ static: false })threeDscrollevent:ElementRef;
  @ViewChild('publicthreedyscroll',{ static: false })publicthreedyscrollevent:ElementRef;
  @Output() inserDataevent = new EventEmitter<{ data1: any, data2: any, imageUrl: any }>();

  publicvideolibrary:Boolean=false
  ///////////////////
  public save_exit_ref: BsModalRef;
  public move_exit_ref: BsModalRef;
  public pdfimgmodalfile_exit_ref: BsModalRef;
  public filealert_exit_ref: BsModalRef;

  mylib: boolean = false;
  peoplelib: boolean = false;
  lessonlisting: boolean = false;
  uploadlessndoc: boolean = false;
  totallesson: any = [];
  myless: boolean = false;
  imglib: boolean = false;
  imglibrary: boolean = false;
  videolibrary: boolean = false;
  audiolibrary: boolean = false;
  documentlibrary: boolean = false;
  img3dlibrary: boolean = false;

  openLibrary: boolean = true;
  libraryClicked: boolean = true;
  openMyLesson: boolean = true;
  myLessonClicked: boolean = true;
  imagesClicked: boolean = false;
  openImages: boolean = false;
  videosClicked: boolean=false;
  openVideos: boolean = false;
  threedmodelsClicked: boolean = false;
  open3dmodels: boolean = false;
  audioClicked: boolean = false;
  openAudio: boolean = false;
  documentClicked: boolean = false;
  openDocument: boolean = false;
  publicLibraryClicked: boolean = false;
  openPublicLibrary: boolean = false;
  googleClicked: boolean = false;
  googleDriveClicked: boolean = false;
  binClicked: boolean = false;
  selectedSection: string;
  showConfirmation: boolean = false;
  isDeleteLesson: boolean = false;
  lessonToDelete: any = null; 
  isFileDelete: boolean = false;
  fileToDelete: any = null;
  isBinDelete: boolean = false;
  binToDelete: any = null;

  private popupElement: HTMLElement;

  // changes start - shreyas.s
  isGoogleClassroomOpen: boolean = false;
  isGoogleDriveOpen: boolean = false;
  isLoadingGoogleCourseSelection: boolean = false;
  courses: Course[] = [];
  googleDriveFiles: GoogleDriveFiles[] = [];
  sections: Map<string, Course[]> = new Map();
  sectionKeys: any = [];
  isGoogleClassroomSectionActive: boolean = false;
  googleActiveCourses: any = null;
  isShowingGoogleCourseMaterial: boolean = false;
  selectedGoogleCourseID: string | null = null;
  selectedGoogleCourseName: string | null = null;
  selectedGoogleCourseMaterials: GoogleCourseWorkMaterials;
  googleDriveContextMenuOpenFileID: string = null;
  isGoogleDriveContextMenuOpen: boolean = false;
  isGoogleDriveContextSubMenu_detailsOpen: boolean = false;
  googleDriveContextMenuID = null;
  googleDriveSelectedMetaData: GoogleDriveFileMetaData = null;
  myTempCanvas!: EditorcanvasComponent;
  selectedLessonfileid:any
  sw: any;
  sh: any;
  role:any;
  userid:any;
  show: boolean = false;
  disablefiles:Boolean=false
  fetchavchatmode:any
  // changes end - shreyas.s

  ngOnInit() {

    console.log("kkkkkkkkkkkkkkkkk")
    this.role = localStorage.getItem("type");
    this.licensetype = localStorage.getItem("licensetype")
    if (this.role == "teacher" && this.licensetype == "1 user free version / freemium license") {
      this.show = true
    } else {
      this.show = false
    }


    this.fetchavchatmode=this.auth.fetchfilesstatus();
    this.disablefiles=this.fetchavchatmode.activemode
     console.log(this.auth.fetchfilesstatus());
     console.log("Bandana disablefiles in file manager",this.disablefiles);
    this.lessonlisting = true;
    this.mylib = true;
    //const d="My lesson"
    //console.log("ngoniti")
    //this.opencontent(d)
    this.getlesson_private(false);
    this.auth.refreshFiles.subscribe((res) => {
      if (res == "yes") {
        this.selectedLessonfileid=localStorage.getItem('FileID')
        this.sw = this.getScreenWidth();
        this.sh = this.getScreenHeight()
        this.role=localStorage.getItem('type');
        this.userid=localStorage.getItem('userId');
        console.log("role",this.role);
        console.log("userid",this.userid);
    console.log(this.selectedLessonfileid,"this.selectedLessonid")
    this.fetchavchatmode=this.auth.fetchfilesstatus();
    this.disablefiles=this.fetchavchatmode.activemode;
    this.mylib = true;
        //this.getlesson_private(false);

        if(this.disablefiles===true){
          this.closeallactivefiles();
          console.log("yes bandana");
          this.imglibrary=true;
          this.imagesClicked=true;
    this.openImages=true;
    this.openMyLesson=false;
    this.myLessonClicked=false;
    this.mainname="Images"
    this.mylib=true;
    this.openLibrary=true;
          this.handleuploadssearch('image',false);
          this.lessonlisting=false;


        }else{
          console.log("no bandana");
          this.closeallactivefiles();
          this.lessonlisting=true;
          this.imagesClicked=false;
    this.openImages=false;
    this.openMyLesson=true;
    this.myLessonClicked=true;
    this.mainname="My Lessons"
    this.openLibrary=true;
    this.libraryClicked=true;
    this.mylib=true;
          this.getlesson_private(false);

        }



      }
    });

    this.popupElement = this.elRef.nativeElement.querySelector('.opendata');

    const tag = document.createElement("script"); //changes - shreyas.s
    tag.src =
      "https://ajax.googleapis.com/ajax/libs/model-viewer/3.3.0/model-viewer.min.js"; //changes - shreyas.s
    tag.setAttribute("type", "module");
    document.body.appendChild(tag); //ch
  }
  // changes start - shreyas.s
  googleclassroomconnected:boolean=false;
  ngAfterViewInit(): void {
    this.googleAuthService.userState$.subscribe((user) => {
      if (user) {
        this.googleclassroomconnected=true;
        this.getGoogleCourses();
      } else {
      }
    });
  }
  //changes end - shreyas.s

  @HostListener("document:click", ["$event"])
  clickOutside(event: Event) {
    //console.log("dddd",event)
    console.log("clickoutside");

    const clickedoptions = event.target as HTMLElement;

    if (!this.elRef.nativeElement.contains(clickedoptions)) {
      console.log("contains target");
      this.closeMoreOptions();
    }

    //filemanager options popup - start


  const clickeddot2 = event.target as HTMLElement;
    const insidePubLib = clickeddot2.closest(
      ".dot2"
    );
    if (!insidePubLib) {
      this.selectedlessonpiblic = null;
    }

    const clickeddot = event.target as HTMLElement;
    const insideFilemanager = clickeddot.closest(
      ".dot3"
    );
    if (!insideFilemanager) {
      console.log('checking log for closing the popup of filemanager files')
      this.selectedlesson = null;
      this.selectedMyLibImages = null;
      this.selectedMyLibVideos = null;
      this.selectedMyLib3D = null;
      this.selectedMyLibAudios = null;
      this.selectedMyLibDoc = null;
      this.selectedPublicimages = null;
      this.selectedPublicvideos = null;
      this.selectedPublicaudio=null;
      this.selectedPublicdoc=null;
    }

    //filemanager options popup - end

    //changes start - shreyas.s
    const clickedElement = event.target as HTMLElement;
    const isClickedInsideMenu = clickedElement.closest(
      ".googleDriveContextMenu"
    );
    const clickedOpenMenu = event.target as HTMLElement;
    const isClickedContextButton = clickedOpenMenu.closest(
      ".googleDriveContextMenuOpenButton"
    );
    if (!isClickedInsideMenu && !isClickedContextButton) {
      // this.isGoogleDriveContextMenuOpen = false;
      this.handleCloseGoogleDriveContextMenu();
    }

    //changes end - shreyas.s
  }

  closeNav() {
    console.log("calling close nav");
    this.whiteboardcomp.closefilemanage();

    this.showSpinneraudio=false;
    this.showSpinnerdocument=false;
    this.showSpinnerimage=false;
    this.showSpinnerobject=false;
    this.showSpinnervideo=false;
    this.closeallactivefiles();
  }

  openmylib() {
    this.mylib = !this.mylib;
    this.lessonlisting = true;
    this.peoplelib = false;
    this.publiclessonlisting = false;
    this.isGoogleClassroomOpen = false;
    this.isGoogleClassroomSectionActive = false; // change - shreyas.s
    this.selectedSection = null;
    this.googleClicked = false;
    this.googleDriveClicked = false;
    this.isGoogleDriveOpen = false;
    this.binlessonlisting = false;
    this.openLibrary = true;
    this.libraryClicked = true;
    this.openMyLesson = true;
    this.myLessonClicked = true;
    this.openPublicLibrary = false;
    this.publicLibraryClicked = false;
    this.binClicked = false;
    this.imglibrary = false;
    this.videolibrary = false;
    this.audiolibrary = false;
    this.documentlibrary = false;
    this.img3dlibrary = false;
    this.mainname = 'My Lessons';
    this.openImages = false;
    this.imagesClicked = false;
    this.open3dmodels = false;
    this.threedmodelsClicked = false;
    this.openVideos = false;
    this.videosClicked = false;
    this.openDocument = false;
    this.documentClicked = false;
    this.audioClicked = false;
    this.openAudio = false;
    /////////////////////bd////
    this.publiclessonlisting = false
    this.publicimglibrary = false;
    this.img3dPubliclibrary = false;
    this.audioPubliclibrary = false;
    this.documentPubliclibrary = false;
    this.publicvideolibrary = false;
    this.fetchpublicimage=false;
    this.fetchpublicvideo=false;
    this.fetchpublicaudio=false;
    this.fetchpublicdoc=false;
    this.fetchpublic3d=false;

    if(this.disablefiles===true){
      console.log("yes bandana");
      this.imglibrary=true;
      this.imagesClicked=true;
this.openImages=true;
this.openMyLesson=false;
this.myLessonClicked=false;
this.mainname="Images"
this.lessonlisting=false;
 this.handleuploadssearch('image',false);


    }else{
      console.log("no bandana");
      this.lessonlisting=true;
      this.imagesClicked=false;
this.openImages=false;
this.openMyLesson=true;
this.myLessonClicked=true;
this.mainname="My Lessons"
      this.getlesson_private(false);

    }
  }
  openpeople() {
    this.peoplelib = !this.peoplelib;
    this.publiclessonlisting = false;
    this.mylib = false;
    this.isGoogleClassroomOpen = false; // change - shreyas.s
    this.selectedSection = null;
    this.isGoogleDriveOpen = false;
    this.googleClicked = false;
    this.googleDriveClicked = false;
    this.binlessonlisting = false;
    this.binClicked = false;
    this.openLibrary = false;
    this.libraryClicked = false;
    this.publicLibraryClicked = !this.publicLibraryClicked;
    this.openPublicLibrary = !this.openPublicLibrary;
    ////////////////////////////
    this.lessonlisting = false
    this.imglibrary = false;
    this.videolibrary = false;
    this.img3dlibrary = false;
    this.audiolibrary = false;
    this.documentlibrary = false;
    this.myLessonClicked = true;
    this.openMyLesson = true;
    this.openImages = false;
    this.imagesClicked = false;
    this.videosClicked = false;
    this.openVideos = false;
    this.threedmodelsClicked = false;
    this.open3dmodels = false;
    this.audioClicked = false;
    this.openAudio = false;
    this.documentClicked = false;
    this.openDocument = false;

    this.isGoogleClassroomSectionActive = false;

    this.getlesson_public(false);
   this.publiclessonlisting = true;
   this.mainname="Lessons";

   if(this.disablefiles===true){
    this.mainname="Images";
    this.fetchpublicimage=true;
    this.imagesClicked=true;
    this.publicimglibrary=true;
       this.publiclessonlisting=false;
    this.myLessonClicked=false;
    this.openImages=true
    this.handleuploadssearch('image',false)
   }else{
     this.mainname="Lessons";
    this.publiclessonlisting=true;
    this.myLessonClicked=true;
    this.fetchpublicimage=false;
    this.imagesClicked=false;
    this.publicimglibrary=false;
     this.getlesson_public(false);
   }
  }


  mainname: string;
  opencontent(data) {
    this.uploadlessndoc=false;
    this.searchByname="";
    console.log("opencont", data);
    this.publiclessonlisting = false;
    console.log("ssss", data);
    this.mainname = data.target.outerText;
    console.log("ddddd", this.mainname);
    //this.lessonlisting=true;
    this.isGoogleClassroomSectionActive = false; // change - shreyas.s
    this.publiclessonlisting=false
    this.publicimglibrary=false;
   this.img3dPubliclibrary=false;
   this.audioPubliclibrary=false;
   this.documentPubliclibrary=false;
    if (this.mainname == "My Lessons") {
      console.log("my lessons clicked")
      if(this.disablefiles===true){
        return this.showfilealert()
      }else{
      this.lessonlisting = true;
      this.imglibrary = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.myLessonClicked = true;
      this.openMyLesson = true;
      this.openImages = false;
      this.imagesClicked = false;
      this.videosClicked = false;
      this.openVideos = false;
      this.threedmodelsClicked = false;
      this.open3dmodels = false;
      this.audioClicked = false;
      this.openAudio = false;
      this.documentClicked = false;
      this.openDocument = false;
      }
    } else if (this.mainname == "Images") {
      this.imglibrary = true;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.myLessonClicked = false;
      this.openMyLesson = false;
      this.imagesClicked = true;
      this.openImages = true;
      this.videosClicked = false;
      this.openVideos = false;
      this.threedmodelsClicked = false;
      this.open3dmodels = false;
      this.audioClicked = false;
      this.openAudio = false;
      this.documentClicked = false;
      this.openDocument = false;
    } else if (this.mainname == "Videos") {
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = true;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.myLessonClicked = false;
      this.openMyLesson = false;
      this.openImages = false;
      this.imagesClicked = false;
      this.videosClicked = true;
      this.openVideos = true;
      this.threedmodelsClicked = false;
      this.open3dmodels = false;
      this.audioClicked = false;
      this.openAudio = false;
      this.documentClicked = false;
      this.openDocument = false;
      this.isGoogleDriveOpen = false;
    } else if (this.mainname == "Audio") {
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = true;
      this.documentlibrary = false;
      this.myLessonClicked = false;
      this.openMyLesson = false;
      this.openImages = false;
      this.imagesClicked = false;
      this.videosClicked = false;
      this.openVideos = false;
      this.threedmodelsClicked = false;
      this.open3dmodels = false;
      this.audioClicked = true;
      this.openAudio = true;
      this.documentClicked = false;
      this.openDocument = false;
    } else if (this.mainname == "3D models") {
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = true;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.myLessonClicked = false;
      this.openMyLesson = false;
      this.openImages = false;
      this.imagesClicked = false;
      this.videosClicked = false;
      this.openVideos = false;
      this.threedmodelsClicked = true;
      this.open3dmodels = true;
      this.audioClicked = false;
      this.openAudio = false;
      this.documentClicked = false;
      this.openDocument = false;
    } else if (this.mainname == "Documents") {
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = true;
      this.myLessonClicked = false;
      this.openMyLesson = false;
      this.openImages = false;
      this.imagesClicked = false;
      this.videosClicked = false;
      this.openVideos = false;
      this.threedmodelsClicked = false;
      this.open3dmodels = false;
      this.audioClicked = false;
      this.openAudio = false;
      this.documentClicked = true;
      this.openDocument = true;
    }

    this.showSpinneraudio=false;
    this.showSpinnerdocument=false;
    this.showSpinnerimage=false;
    this.showSpinnerobject=false;
    this.showSpinnervideo=false;



  }

  publiclessonlisting: boolean = false;
  PulicselectedFile:any
  // openpubcontent(data) {
  //   console.log("openpub function", data);
  //   this.mainname = data.target.innerText;
  //   console.log("innertext", this.mainname);
  //   this.isGoogleClassroomSectionActive = false; // change - shreyas.s
  //   if (this.mainname == "Lessons") {
  //     this.publiclessonlisting = true;
  //     this.imglibrary = false;
  //     this.lessonlisting = false;
  //     this.videolibrary = false;
  //     this.img3dlibrary = false;
  //     this.audiolibrary = false;
  //     this.documentlibrary = false;
  //     this.myLessonClicked = true;
  //     this.imagesClicked = false;
  //     this.videosClicked = false;
  //     this.threedmodelsClicked = false;
  //     this.audioClicked = false;
  //     this.documentClicked = false;
  //   } else if (this.mainname == "Images") {
  //     this.imglibrary = false;
  //     this.lessonlisting = false;
  //     this.videolibrary = false;
  //     this.img3dlibrary = false;
  //     this.audiolibrary = false;
  //     this.documentlibrary = false;
  //     this.publiclessonlisting = false;
  //     this.myLessonClicked = false;
  //     this.imagesClicked = true;
  //     this.videosClicked = false;
  //     this.threedmodelsClicked = false;
  //     this.audioClicked = false;
  //     this.documentClicked = false;
  //   } else if (this.mainname == "Videos") {
  //     this.imglibrary = false;
  //     this.lessonlisting = false;
  //     this.videolibrary = false;
  //     this.img3dlibrary = false;
  //     this.audiolibrary = false;
  //     this.documentlibrary = false;
  //     this.publiclessonlisting = false;
  //     // this.myLessonClicked = false;
  //     this.imagesClicked = false;
  //     this.videosClicked = true;
  //     this.threedmodelsClicked = false;
  //     this.audioClicked = false;
  //     this.documentClicked = false;
  //   } else if (this.mainname == "Audio") {
  //     this.imglibrary = false;
  //     this.lessonlisting = false;
  //     this.videolibrary = false;
  //     this.img3dlibrary = false;
  //     this.audiolibrary = false;
  //     this.documentlibrary = false;
  //     this.publiclessonlisting = false;
  //     // this.myLessonClicked = false;
  //     this.imagesClicked = false;
  //     this.videosClicked = false;
  //     this.threedmodelsClicked = false;
  //     this.audioClicked = true;
  //     this.documentClicked = false;
  //   } else if (this.mainname == "3D models") {
  //     this.imglibrary = false;
  //     this.lessonlisting = false;
  //     this.videolibrary = false;
  //     this.img3dlibrary = false;
  //     this.audiolibrary = false;
  //     this.documentlibrary = false;
  //     this.publiclessonlisting = false;
  //     // this.myLessonClicked = false;
  //     this.imagesClicked = false;
  //     this.videosClicked = false;
  //     this.threedmodelsClicked = true;
  //     this.audioClicked = false;
  //     this.documentClicked = false;
  //   } else if (this.mainname == "Documents") {
  //     this.imglibrary = false;
  //     this.lessonlisting = false;
  //     this.videolibrary = false;
  //     this.img3dlibrary = false;
  //     this.audiolibrary = false;
  //     this.documentlibrary = false;
  //     this.publiclessonlisting = false;
  //     // this.myLessonClicked = false;
  //     this.imagesClicked = false;
  //     this.videosClicked = false;
  //     this.threedmodelsClicked = false;
  //     this.audioClicked = false;
  //     this.documentClicked = true;
  //   }
  // }
  fetchpublicimage:boolean=false;
  fetchpublicvideo:boolean=false;
  fetchpublicaudio:boolean=false;
  fetchpublicdoc:boolean=false;
  fetchpublic3d:boolean=false;
  openpubcontent(data) {
    console.log("openpub function", data);
    this.uploadlessndoc=false;
    // this.publiclessonlisting=true;
    // this.lessonlisting = false;
    // this.getlesson_public()
    this.searchByname="";
    this.searchpublicfile="";
    this.mainname = data.target.innerText;
    console.log("innertext", this.mainname);
    this.isGoogleClassroomSectionActive = false; // change - shreyas.s
    if (this.mainname == "Lessons") {   //lesson
      //lesson
      if(this.disablefiles){
        return this.showfilealert()
      }else{
      this.publiclessonlisting=true;
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.publiclessonlisting=true;
      this.myLessonClicked = true;
     this.imagesClicked = false;
     this.videosClicked = false;
     this.threedmodelsClicked = false;
     this.audioClicked = false;
     this.documentClicked = false;
     this.publicimglibrary=false;
     this.publicvideolibrary=false;
     this.img3dPubliclibrary=false;
     this.audioPubliclibrary=false
    this.documentPubliclibrary=false;
      }
    } else if (this.mainname == "Images") {
      console.log("openpub images")
      this.fetchpublicimage=true;
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.publiclessonlisting = false;
      this.myLessonClicked = false;
     this.imagesClicked = true;
     this.videosClicked = false;
     this.threedmodelsClicked = false;
     this.audioClicked = false;
     this.documentClicked = false;
     this.publicimglibrary=true;
     this.publicvideolibrary=false;
     this. img3dPubliclibrary=false;
     this.audioPubliclibrary=false
     this.documentPubliclibrary=false;
     this.selectedPublicimages=null;
    } else if (this.mainname == "Videos") {
      this.fetchpublicvideo=true;
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.publiclessonlisting = false;
      this.imagesClicked = false;
     this.videosClicked = true;
     this.threedmodelsClicked = false;
     this.audioClicked = false;
     this.documentClicked = false;
     this.publicimglibrary=false;
     this.publicvideolibrary=true;
     this. img3dPubliclibrary=false;
     this.audioPubliclibrary=false;
     this.documentPubliclibrary=false;
     this.myLessonClicked=false;
     this.selectedPublicvideos=null;
    } else if (this.mainname == "Audio") {
      this.fetchpublicaudio=true;
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.publiclessonlisting = false;
      this.imagesClicked = false;
     this.videosClicked = false;
     this.threedmodelsClicked = false;
     this.audioClicked = true;
     this.documentClicked = false;
     this.publicimglibrary=false;
     this.publicvideolibrary=false;
     this. img3dPubliclibrary=false;
     this.audioPubliclibrary=true;
     this.documentPubliclibrary=false;
     this.myLessonClicked=false;
     this.selectedPublicaudio=null;
    } else if (this.mainname == "3D models") {
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.publiclessonlisting = false;
      this.imagesClicked = false;
     this.videosClicked = false;
     this.threedmodelsClicked = true;
     this.audioClicked = false;
     this.documentClicked = false;
     this.publicimglibrary=false;
     this.publicvideolibrary=false;
     this.img3dPubliclibrary=true;
     this.audioPubliclibrary=false;
     this.documentPubliclibrary=false;
     this.fetchpublic3d=true;
    } else if (this.mainname == "Documents") {
      this.fetchpublicdoc=true;
      this.imglibrary = false;
      this.lessonlisting = false;
      this.videolibrary = false;
      this.img3dlibrary = false;
      this.audiolibrary = false;
      this.documentlibrary = false;
      this.publiclessonlisting = false;
       this.myLessonClicked = false;
           this.imagesClicked = false;
           this.videosClicked = false;
           this.threedmodelsClicked = false;
           this.audioClicked = false;
           this.documentClicked = true;
           this.publicimglibrary=false;
           this.publicvideolibrary=false;
           this. img3dPubliclibrary=false;
           this.audioPubliclibrary=false;
           this.documentPubliclibrary=true;
           this.selectedPublicdoc=null;
    }
    this.showSpinneraudio=false;
    this.showSpinnerdocument=false;
    this.showSpinnerimage=false;
    this.showSpinnerobject=false;
    this.showSpinnervideo=false;
  }



  uploadless() {
    console.log("upload lesson");
    this.uploadlessndoc = true;
  }

  newlesson() {
    this.uploadlessndoc = false;
    const apiUrl = environment.serverurl + "/create-initial-file"; //'http://localhost:5000/save-canvas';
    const browserInfo = this.browserDetectionService.getBrowserInfo();
    console.log('newlesson width', browserInfo.screenWidth);
    console.log('newlesson height', browserInfo.screenHeight);
    const requestBody = {
      filename: "new1",
      Bin: false,
      UserId : localStorage.getItem('userId'),
      width: browserInfo.screenWidth,
      height: browserInfo.screenHeight,
      canvas: [
        {
          version: "",
          objects: [{}],
        },
      ],
    };
    console.log('request body of newlesson', requestBody);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    this.http.post(apiUrl, requestBody, httpOptions).subscribe(
      (response: any) => {
        console.log("API Response:", response);
        this.getlesson_private(false)
        this.jsonList_private.push(response);
        localStorage.setItem("currentPage", "1");
        localStorage.setItem("mainlessonID", response.savedCanvasFile._id);
        localStorage.setItem("totalPages", "1");
        localStorage.setItem("FileID", response.savedCanvasFile.fileId);
        localStorage.removeItem("currentCanvas");
        localStorage.removeItem("editedObj")
        localStorage.removeItem("savedCanvasData");
        this.auth.refreshPageCount.next("yes");

        // Handle success
      },
      (error: any) => {
        console.error("API Error:", error);
        // Handle error
      }
    );
  }

  // createPublicLesson() {
  //   this.uploadlessndoc = false;
  //   console.log("File-----> createPublicLesson");
  //   const apiUrl = environment.serverurl + "/create-initial-file"; //'http://localhost:5000/save-canvas';
  //   const requestBody = {
  //     filename: "s",
  //     IsPublic: true,
  //     Bin: false,

  //         canvas: [
  //           {
  //             version: "",
  //             objects: [{}]
  //           }
  //         ]

  //       };
  //       const httpOptions = {
  //         headers: new HttpHeaders({
  //           'Content-Type': 'application/json'
  //         })
  //       };

  //       this.http.post(apiUrl, requestBody, httpOptions).subscribe(
  //         (response: any) => {

  //           this.jsonList_public.push(response)

  //           localStorage.setItem('currentPage','1')
  //           localStorage.setItem('totalPages','1')
  //           localStorage.setItem('FileID',response.savedCanvasFile.fileId)

  //           // Handle success
  //         },
  //         (error: any) => {
  //           console.error('API Error:', error);
  //           // Handle error
  //         }
  //       );

  // }



  selectedlesson: any = null; // to track the selected item

  //Function to open the more options menu for a specific item
  openMoreOptions(e, data: any) {
    this.uploadlessndoc=false;
    console.log("selected lesson");
    // this.selectedlesson = data;
    // this.selectedlesson = this.selectedlesson === data ? null : data;
    console.log("xcxcxc", this.selectedlesson);
    this.adjustPrivateDivPosition();
    this.accesstopub = false;
    this.sharefileopt = false;
    //console.log("xcxcxc", this.selectedlesson);
    e.stopPropagation();
    if (this.selectedlesson = data) {
      this.selectedlesson = data;
    }
    else {
      this.selectedlesson = null;
    }
  }

  // Function to close the more options menu
  closeMoreOptions() {
    this.selectedlesson = null;
    this.sharefileopt = false;
  }

  adjustPrivateDivPosition() {
    // const screenHeight = window.innerHeight;
    // const divElement = this.dotpubElement.nativeElement;
    // console.log(divElement);
    // console.log("hello i am in adjustDivPosition")
    //  const divElement = this.dotpubElement.nativeElement;
    //  console.log(divElement ,"divelementpp")
    // const divElement = this.dotpubElement.nativeElement;
    // console.log(divElement ,"divelementpp in not in settimeout")
    setTimeout(() => {
      const divElement = this.dotpubElement.nativeElement;
      console.log(divElement ,"divelementpp in settimeout")
        if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      console.log(rect,"rect",screenHeight,"screenheight",rect.left,"rect-h",rect.height)
      // Check if the div is going out of the screen
      if (rect.bottom > screenHeight) {
        // Move the div up by adjusting its top position
       // divElement.style.top = `${rect.height-600}px`;
        divElement.style.top = `${screenHeight - rect.bottom}px`;
        console.log(screenHeight - rect.height,"lllll");
      } else {
        // Reset the top position if it's within the screen
       // divElement.style.top = 'auto';
       divElement.style.top = `${-10}px`;
      }
      if((rect.left+10)>750){
        console.log(divElement.style.left+150,"ppppp")
        console.log("ppppp")
        divElement.style.left = `${divElement.style.left -5}px`;
      }else{
        console.log(rect.left,"in ")
        console.log(rect.left+10,"pppppkkkkkk")
        console.log("lllll")
        divElement.style.left = `${ divElement.style.left+181}px`;
      }
    }
    },10);


  }

  //------MY LIBRARY_IMAGES--start----------

  selectedMyLibImages: any
  openMoreOptionsMyLibImages(e, value: any) {
    this.sharefileopt = false;
    this.accesstopub = false
    e.stopPropagation();
    if (this.selectedMyLibImages === value) {
      this.selectedMyLibImages = null;
    } else
      this.selectedMyLibImages = value;

    this.adjustDivPositionMyLibImagescard();
  }

  adjustDivPositionMyLibImagescard() {
    console.log("called")
      setTimeout(() => {
        const divElement = this.myLibImages.nativeElement;
        if (divElement) {
        const rect = divElement.getBoundingClientRect();
        const screenHeight = window.innerHeight;
        if (rect.bottom > screenHeight) {
       console.log(rect.bottom,"rectbottom")
        divElement.style.top = `${screenHeight - rect.bottom}px`;
        } else {
          console.log(rect.height,"in aaaaa")
          divElement.style.top = `${-7}px`;
        }
        if((rect.left+10)>750){
          divElement.style.left = `${divElement.style.left}px`;
        }else{
          divElement.style.left = `${ divElement.style.left+181}px`;
        }
      }
      },10);
  }

  //------MY LIBRARY_IMAGES--end------------

  //------MY_LIB_VIDEOS_OPTIONS__START--------

  selectedMyLibVideos: any
  openMoreOptionsMyLibVideos(e, value: any) {
    e.stopPropagation();
    if (this.selectedMyLibVideos === value) {
      this.selectedMyLibVideos = null

    } else {
      this.selectedMyLibVideos = value;
    }

    this.shareFilevideoOpt = false;
    this.accesstopubvideo = false;
    this.adjustDivPositionMyLibVideocard();
  }

  adjustDivPositionMyLibVideocard() {
    setTimeout(() => {
      const divElement = this.myLibVideos.nativeElement;
      if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      if (rect.bottom > screenHeight) {
     console.log(rect.bottom,"rectbottom",screenHeight - rect.bottom)
      divElement.style.top = `${screenHeight - rect.bottom}px`;
      } else {
        console.log(rect.height,"in aaaaa")
        divElement.style.top = `${0}px`;
      }
      if((rect.left+10)>750){
        console.log(rect.left,"rrrrr")
        divElement.style.left = `${divElement.style.left}px`;
      }else{
        divElement.style.left = `${ divElement.style.left+181}px`;
      }
    }
    },10);
  }

  //------MY_LIB_VIDEOS_OPTIONS__END----------

  //------MY_LIB_3D_OPTIONS__START-----------

  share3Dfileopt: boolean = false;
  share3Doption() {
    this.share3Dfileopt = !this.share3Dfileopt;
    this.accessTo3D = false;
    this.adjustDivPosition3Dsharecard();
  }

  accessTo3D: boolean = false;
  access3Doption(data) {
    this.accessTo3D = !this.accessTo3D;
    this.share3Dfileopt = false;
    this.rightoptionforpriv=false;
    this.rightoptionforpub = false;
    this.adjustDivPosition3Dacesscard();
    console.log("data.ispublic",data.IsPublic)
    if(data.IsPublic==false){
      this.rightoptionforpriv=true;
    }
    else if(data.IsPublic==true){
      this.rightoptionforpub = true;
    }
  }

  selectedMyLib3D: any
  openMore3DOptions(e, value: any) {
    this.share3Dfileopt = false;
    this.accessTo3D = false;
    e.stopPropagation();
    if (this.selectedMyLib3D === value) {
      this.selectedMyLib3D = null;
    } else
      this.selectedMyLib3D = value;

    this.adjustDivPosition3Dcard()
  }

  adjustDivPosition3Dcard() {
    setTimeout(() => {
      const divElement = this.myLib3D.nativeElement;
      if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      if (rect.bottom > screenHeight) {
     console.log(rect.bottom,"rectbottom")
      divElement.style.top = `${screenHeight - rect.bottom}px`;
      } else {
        console.log(rect.height,"in aaaaa")
        divElement.style.top = `${-10}px`;
      }
      if((rect.left+10)>750){
        divElement.style.left = `${divElement.style.left}px`;
      }else{
        divElement.style.left = `${ divElement.style.left+181}px`;
      }
    }
    },10);
  }

  adjustDivPosition3Dsharecard() {
    console.log("adjust")

    setTimeout(() => {
      const divElement = this.share3DFileopt.nativeElement;
      console.log("ddd",divElement)
      if (divElement) {

      const rect = divElement.getBoundingClientRect();

      console.log(rect.left,"in adjustDivPositionvideosharecard")

      if((rect.left)>900){
        console.log(rect.left-750,"ooooo")
        console.log("rect.left",rect.left);
        divElement.style.left = `${-330}px`;
      }else{
        console.log(rect.left,"rect.left in share card")
        divElement.style.left = `${ divElement.style.left}px`;
      }

    }
    },10);
  }

  adjustDivPosition3Dacesscard() {
    console.log("adjust")

    setTimeout(() => {
      const divElement = this.accessTo3d.nativeElement;
      console.log("ddd",divElement)
      if (divElement) {
      const rect = divElement.getBoundingClientRect();
      console.log(rect.left)
      if((rect.left)>900){
        console.log(rect.left-750,"ooooo")
        divElement.style.left = `${-330}px`;
      }else{
        console.log(rect.left,"rect.left in share card")
        divElement.style.left = `${ divElement.style.left}px`;
      }
    }
    },10);
  }

  //------MY_LIB_3D_OPTIONS__END-------------


  //------MY_LIB_AUDIOS___START--------------

  selectedMyLibAudios: any
  openMoreOptionsMyLibAudios(e, value: any) {
    this.sharefileopt = false;
    this.accesstopub = false;
    e.stopPropagation();
    if (this.selectedMyLibAudios === value) {
      this.selectedMyLibAudios = null;
    } else
      this.selectedMyLibAudios = value;

    this.adjustDivPositionAudiocard()
  }

  adjustDivPositionAudiocard() {
    setTimeout(() => {
      const divElement = this.myLibAudio.nativeElement;
      if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      if (rect.bottom > screenHeight) {
     console.log(rect.bottom,"rectbottom")
      divElement.style.top = `${screenHeight - rect.bottom}px`;
      } else {
        console.log(rect.height,"in aaaaa")
        divElement.style.top = `${0}px`;
      }
      if((rect.left+10)>750){
        divElement.style.left = `${divElement.style.left}px`;
      }else{
        divElement.style.left = `${ divElement.style.left+181}px`;
      }
    }
    },10);
  }

  //------MY_LIB_AUDIOS___END---------------


  //-------MY_LIB_DOCUMENTS__START----------

  selectedMyLibDoc: any
  openMoreOptionsMyLibDoc(e, value: any) {
    this.sharefileopt = false;
    this.accesstopub = false
    e.stopPropagation();
    if (this.selectedMyLibDoc === value) {
      this.selectedMyLibDoc = null;
    } else
      this.selectedMyLibDoc = value;

    this.adjustDivPositionDoccard()
  }

  adjustDivPositionDoccard() {
    setTimeout(() => {
      const divElement = this.myLibDoc.nativeElement;
      if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      if (rect.bottom > screenHeight) {
     console.log(rect.bottom,"rectbottom")
      divElement.style.top = `${screenHeight - rect.bottom}px`;
      } else {
        console.log(rect.height,"in aaaaa")
        divElement.style.top = `${0}px`;
      }
      if((rect.left+10)>750){
        divElement.style.left = `${divElement.style.left}px`;
      }else{
        divElement.style.left = `${ divElement.style.left+181}px`;
      }
    }
    },10);
  }

  //-------MY_LIB_DOCUMENTS__END------------

  jsonList: JsonItem[] = [];
  jsonList_private: JsonItem[] = [];
  jsonList_public: JsonItem[] = []; // Store the list of JSON items
  jsonList_bin: JsonItem[] = [];
  selectedJsonUrl_public: string = ""; //list , Store the selected JSON URL
  userslesson:any;

  getlesson_private(handlepageadd:Boolean): void {
    if(handlepageadd){
      this.searchpage=this.searchpage+1
    } else{
      this.searchpage=1
    }
    this.userslesson=localStorage.getItem("userId")
    console.log("this.userless",this.userslesson)
    this.auth.getlesson_private(this.userslesson,this.searchmylibrarylesson,this.searchpage).subscribe(
      (response: any) => {
        console.log("Res--->", response);

        // this.jsonList_private = response; // Assign the response directly to jsonList
        // console.log("list_private", this.jsonList_private);
        //fname=this.jsonList_private[i].originalfilename

        if(this.searchpage>1&&response.length>0){
          console.log("hhhh")
           response.map(item=>{
             console.log(item,"item");
             this.jsonList_private.push(item);

           })
           this.Shownomatch=false
           this.totalimages=true
           console.log( this.totalimagesarr,"images")

         }else if(this.searchpage===1&&response.length===0){
          this.Shownomatch=true
          this.totalimages=false
          console.log("no match found");
         }else if(this.searchpage===1&&response.length>0){
           console.log("hhhhpppp")
           this.totalimages=true
           this.jsonList_private=response
         console.log(this.jsonList_private,"this")
         this.Shownomatch=false;
         }else{
           return
         }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  searchpublicfile:any=""
  // jsonList_public: JsonItem[] = [];
  // getlesson_public() {
  //   this.auth.getlesson_public().subscribe(
  //     (response: any) => {
  //       console.log("Res--->", response);

  //       this.jsonList_public = response; // Assign the response directly to jsonList
  //       console.log("list_public", this.jsonList_public);
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // }

  getlesson_public(handlepageadd:Boolean) {
    if(handlepageadd){
      this.searchpage=this.searchpage+1
    } else{
      this.searchpage=1
    }
    this.auth.getlesson_public( this.searchpublicfile,this.searchpage).subscribe(
      (response: any) => {
        console.log("Res--->", response);

        // this.jsonList_public = response; // Assign the response directly to jsonList
        // console.log("list_public", this.jsonList_public);
        if(this.searchpage>1&&response.length>0){
          console.log("hhhh")
           response.map(item=>{
             console.log(item,"item");
             this.jsonList_public.push(item);

           })
           this.Shownomatch=false
           this.totalimages=true
           console.log( this.totalimagesarr,"images")
           this.Shownomatch=false;
         }else if(this.searchpage===1&&response.length===0){
          this.Shownomatch=true
          this.totalimages=false
         }else if(this.searchpage===1&&response.length>0){
           console.log("hhhhpppp")
           this.totalimages=true
          this.jsonList_public=response
         console.log(this.jsonList_public,"this")
         this.Shownomatch=false;
         }else{
           return
         }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }


  binlessonlisting: boolean = false;
  searchbinlesson:any=""
  getlessonbin() {
    this.mainname = "Bin";
    this.binlessonlisting = !this.binlessonlisting;
    this.binClicked = !this.binClicked;

    //changes start - shreyas.s
    this.isGoogleClassroomOpen = false;
    this.isGoogleDriveOpen = false;
    this.isGoogleClassroomSectionActive = false;

    //changes end - shreyas.s
    this.publiclessonlisting = false;
    this.lessonlisting = false;
    this.imglibrary = false;
    this.videolibrary = false;
    this.img3dlibrary = false;
    this.audiolibrary = false;
    this.documentlibrary = false;
    this.googleClicked = false;
    this.googleDriveClicked = false;
    this.publicLibraryClicked = false;
    this.openLibrary = false;
    this.libraryClicked = false;
    this.mylib = false;
    this.peoplelib = false;
    this.publicLibraryClicked = false;
    this.openPublicLibrary = false;


    ////////////public library change////
    this.publiclessonlisting=false
    this.publicimglibrary=false;
    this.publicvideolibrary=false;
   this.img3dPubliclibrary=false;
   this.audioPubliclibrary=false;
   this.documentPubliclibrary=false;

    /////////////////



    // this.auth.getlesson_bin().subscribe(
    //   (response: any) => {
    //     console.log("Res--->", response);

    //     this.jsonList_bin = response; // Assign the response directly to jsonList
    //     console.log("list_bin", this.jsonList_bin);
    //   },
    //   (error: any) => {
    //     console.error(error);
    //   }
    // );
    this.fetchbinlesson(false)
  }

  fetchbinlesson(handlepageadd){
    console.log("this.searchbinlesson",this.searchbinlesson)
    console.log("this.searchpage",this.searchpage)
    if(handlepageadd){
      this.searchpage=this.searchpage+1
    } else{
      this.searchpage=1
    }
    this.auth.getlesson_bin(this.searchbinlesson,this.searchpage,this.userid).subscribe(
          (response: any) => {
            console.log("Res---> in bin", response);


            if(this.searchpage>1&&response.length>0){

              response.map(item=>{
                console.log(item,"item");
                this.jsonList_bin.push(item);
                })
              console.log( this.jsonList_bin,"images")
              this.Shownomatch=false;

            }else if(this.searchpage===1&&response.length===0){
             this.Shownomatch=true

            }else if(this.searchpage===1&&response.length>0){

            this.jsonList_bin =response
            console.log(this.jsonList_bin,"this")
            this.Shownomatch=false;
            }else{
              return
            }
          },
          (error: any) => {
            console.error(error);
          }
        );
  }

  accesstopub: boolean = false;
  rightoptionforpriv: boolean = false;
  rightoptionforpub: boolean = false;
  accessoption(data) {
    this.accesstopub = !this.accesstopub;
    this.sharefileopt = false;
    this.rightoptionforpriv=false;
    this.rightoptionforpub = false;
    this.adjustDivPositionacesscard();
    console.log("data.ispublic",data.IsPublic)
    if(data.IsPublic==false){
      this.rightoptionforpriv=true;
    }
    else if(data.IsPublic==true){
      this.rightoptionforpub = true;
    }
  }

  makeFilePublic(data) {
    const fileId = data.fileId;
    const updates = { IsPublic: true };

    this.auth.updatefiles(fileId, updates).subscribe(
      (response) => {
        console.log("File made public successfully", response);
        this.getlesson_private(false)
      },
      (error) => {
        console.error("Error making the file public", error);
      }
    );
    this.selectedlesson = null;
  }

  makeFilePrivate(data) {
    const fileId = data.fileId;
    const updates = { IsPublic: false };

    this.auth.updatefiles(fileId, updates).subscribe(
      (response) => {
        console.log("File made public successfully", response);
      },
      (error) => {
        console.error("Error making the file public", error);
      }
    );
    this.selectedlesson = null;
  }

  closeDialog() {
    this.showConfirmation = false;
    this.isDeleteLesson = false;
    this.isBinDelete = false;
    this.isFileDelete = false;
  }

  confirmdeletelesson(data) {
    this.lessonToDelete = data;
    this.isDeleteLesson = true;
    this.showConfirmation = true;
  }

  // deletelesson(data) {
  //   //const url = environment.serverurl+`/delete-canvasfile/${data.fileId}`;
  //   if(data.fileId===this.selectedLessonfileid){
  //     let deletelessonindex=this.jsonList_private.findIndex(item=>item['fileId']===data.fileId)
  //     console.log(deletelessonindex,"pppp");
  //     this.dialogService.showSimplePopup("Lesson cannot be deleted");
  //    this.openlessonfromlib(this.jsonList_private[deletelessonindex+1]);
  //    }
  //   const fileId = data.fileId;
  //   const updates = { Bin: true };
  //   this.auth.updatefiles(fileId, updates).subscribe(
  //     (response) => {
  //       console.log("send to bin", response);
  //       //this.jsonList_private.splice(response, 1)[0];
  //       this.jsonList_bin.push(response);
  //       this.dialogService.showSimplePopup("Lesson has been deleted.");
  //       this.getlesson_private(false)
  //     },
  //     (error) => {
  //       console.error("Error making the file public", error);
  //     }
  //   );
  //   this.selectedlesson = null;
  // }

  deletelesson() {
    if (!this.lessonToDelete) return;  // Ensure data is set

    const data = this.lessonToDelete;
    if (data.fileId === this.selectedLessonfileid) {
        const deletelessonindex = this.jsonList_private.findIndex(item => item['fileId'] === data.fileId);
        console.log(deletelessonindex, "pppp");
        this.dialogService.showSimplePopup("Lesson cannot be deleted");
        this.openlessonfromlib(this.jsonList_private[deletelessonindex + 1]);
    }

    const fileId = data.fileId;
    const updates = { Bin: true };

    // Proceed with deletion
    this.auth.updatefiles(fileId, updates).subscribe(
        (response) => {
            console.log("send to bin", response);
            this.jsonList_bin.push(response);
            this.dialogService.showSimplePopup("Lesson has been deleted.");
            this.getlesson_private(false);
        },
        (error) => {
            console.error("Error making the file public", error);
        }
    );

    this.selectedlesson = null;
    this.closeDialog();  // Close dialog after deletion
}

confirmfiledelete(data) {
  this.fileToDelete = data;
  this.isFileDelete = true;
  this.showConfirmation = true;
}

deletecontentres() {
  if (!this.fileToDelete) return;

  const contentId = this.fileToDelete.contentId;
  this.auth.deletecontent(contentId).subscribe(
    (response) => {
      console.log("DELETE from database", response);
      const typeofcontent=response.deletedContentFile.type
      console.log("ddddddddddddd",typeofcontent)
      this.handleuploadssearch(typeofcontent,false)
      this.dialogService.showSimplePopup(`${typeofcontent} has been deleted.`);
    },
    (error) => {
      console.error("Error deleting  the file ", error);
    }
  );
  this.closeDialog(); 
}

  // deletecontentres(data) {
  //    const contentId = data.contentId;
  //   // const updates = { isInRecycleBin : true };
  //   this.auth.deletecontent(contentId).subscribe(
  //     (response) => {
  //       console.log("DELETE from database", response);
  //       const typeofcontent=response.deletedContentFile.type
  //       console.log("ddddddddddddd",typeofcontent)
  //       this.handleuploadssearch(typeofcontent,false)
  //       this.dialogService.showSimplePopup(`${typeofcontent} has been deleted.`);
  //     },
  //     (error) => {
  //       console.error("Error deleting  the file ", error);
  //     }
  //   );

  // }

  duplicatemylesson(data) {
    this.selectedlesson = false;
    //const url = environment.serverurl+`/delete-canvasfile/${data.fileId}`;
    const fileId = data.fileId;
    //const updates = { Bin : true };
    this.auth.duplicatefiles(fileId).subscribe(
      (response) => {
        console.log("duplicate the file", response);
        //this.jsonList_private.splice(response, 1)[0];
        this.jsonList_private.push(response);
        this.getlesson_private(false)
      },
      (error) => {
        console.error("Error making the file public", error);
      }
    );
  }

  duplicateresourcecontents(data) {
    console.log("image data",data)
    // this.selectedlesson = false;
    //const url = environment.serverurl+`/delete-canvasfile/${data.fileId}`;
    const contentId = data.contentId;
    //const updates = { Bin : true };
    this.auth.duplicatecontents(contentId).subscribe(
      (response) => {
        console.log("duplicate the file", response);
        //this.totalimagesarr.push(response.duplicatedFile);
        const item=response.duplicatedFile
        if (response) {
          // response.duplicatedFile.forEach((item: any) => {
            if (item.mimeType === "image/jpeg" && item.destinationpath) {
              // if (!this.imagesList.includes(item.destinationpath)) {
                this.imagesList.push(item.destinationpath);

                this.totalimagesarr.push(item);

                console.log("Image List:", this.imagesList);
              // }
            } else if (
              item.mimeType === "video/mp4" ||
              item.mimeType === "video/webm" ||
              item.mimeType === "video/ogg" ||
              item.mimeType === "video/quicktime" ||
              item.mimeType === "video/x-msvideo" ||
              item.mimeType === "video/x-flv" ||
              item.mimeType === "video/x-matroska" ||
              item.mimeType === "video/x-ms-wmv" ||
              item.mimeType === "video/3gpp" ||
              (item.mimeType === "video/x-m4v" && item.destinationpath)
            ) {
             // if (!this.videoList.includes(item.destinationpath)) {
                this.videoList.push(item.destinationpath);
                this.totalvideosarr.push(item);
                console.log("Video List:", this.videoList);
             // }
            } else if (item.type === "3D" && item.destinationpath) {
             // if (!this.threeDList.includes(item.destinationpath)) {
                this._3DObjectsArray.push(item);
                console.log("3D img List:",this._3DObjectsArray);
             // }
            } else if (item.mimeType === "audio/mpeg" && item.destinationpath) {
             // if (!this.audioList.includes(item.destinationpath)) {
                this.audioList.push(item.destinationpath);
                this.totalaudioarr.push(item);
                console.log("AUDIO List:", this.audioList);
             // }
            } else if (
              item.mimeType === "text/plain" ||
              item.mimeType === "application/pdf" ||
              item.mimeType ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                item.mimeType ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"||
              item.mimeType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              (item.mimeType === "application/vnd.ms-excel" &&
                item.destinationpath)
            ) {
              //if (!this.documentList.includes(item.destinationpath)) {
                this.documentList.push(item.destinationpath);
                this.totaldocarr.push(item);
                console.log("DOCUMENT List:", this.documentList);
             // }
            }
         // });
        } else {
          console.error(
            "No 'data' array found in the response or 'destinationpath' not found in data objects."
          );
          // Handle the case when 'data' is not available or doesn't contain 'destinationpath'
        }


      },
      (error) => {
        console.error("Error making the file public", error);
      }
    );
  }

  openlessonfromlib(lessondata) {
    console.log(lessondata,"lessondata");
    localStorage.setItem("totalPages", lessondata.TotalNrPages);
    let obj = {
      type: "total_pages",
      data: lessondata.TotalNrPages,
    };
    this.auth.loadData.next(obj);
    this.auth.getLessonData(lessondata.fileId).subscribe((res: any) => {
      this.canvasData = res.Pages;
    });
    this.childEvent.emit(lessondata);
    this.selectedLessonfileid=localStorage.getItem('FileID');
    this.selectedlesson = null;
    console.log("01-11-2024",JSON.parse(localStorage.getItem("savedCanvasData")));
  }

  //renamefield:boolean=false;
  renameText:String;
  filenmae: any;
  fileid: any;
  renamefile(data) {

    console.log("filename", data.originalfilename);
    this.filenmae = data.originalfilename;
    this.fileid = data.fileId;
    this.renameText= data.originalfilename;
    this.save_exit_ref = this.modalService.show(
      this.save_exit,
      Object.assign({}, { class: "setHeight" })
    );
  }

  updatefilename(newFileName: any) {
    if(!newFileName){
      return;
    }
    let userId=localStorage.getItem('userId')
    console.log("New filename entered:", newFileName);
    const fileId = this.fileid;
    const updates = { originalfilename:newFileName,userId:userId };

    this.auth.updatefiles(fileId, updates).subscribe(
      (response) => {
        console.log("File name change successfully", response);
        this.getlesson_private(false)
        this.save_exit_ref.hide();
        this.dialogService.showSimplePopup("Updated Successfully")
      },
      (error) => {
        if(error.status===400){
          this.dialogService.showError(error.error.error);
        }
        console.error("Error changing the file name", error);
      }
    );
  }
  renameContentText:any
  contentname: any;
  contentid: any;
  renamecontent(data) {
    console.log("contentttttt", data);
    console.log("filename", data.originalName);
    this.contentname = data.originalName;
    this.contentid = data.contentId;
    this.renameContentText=this.removeExtension(this.contentname)
    this.save_exit_ref = this.modalService.show(
      this.save_exit,
      Object.assign({}, { class: "setHeight" })
    );
  }
  updatecontentname(newFileName: any) {
    if(!this.renameContentText){
      return
    }
    console.log("New filename entered:", newFileName);
    const contentId = this.contentid;
    let userId=localStorage.getItem("userId");
    newFileName= this.replaceFilename(newFileName,this.renameContentText);
    console.log(newFileName,"newfilename bandana")
    const updates = { originalName: newFileName,userId:userId,type:this.searchtype};

    this.auth.updatecontent(contentId, updates).subscribe(
      (response) => {
        console.log("Content name change successfully", response);
        this.save_exit_ref.hide();
        this.handleuploadssearch(this.searchtype,false);
        this.dialogService.showSimplePopup("Updated Successfully");
      },
      (error) => {
        if(error.status===400){
          this.dialogService.showError(error.error.error)
          }
        console.error("Error changing the file name", error);
      }
    );
  }
  pripubupdate:any;
  contentaccess(data,accestype:any,datatype)  {
    console.log("data, event",data,accestype)
    console.log("iska is public",data.IsPublic);
    const contentId = data.contentId;
    if (accestype=="private"){
      this.rightoptionforpriv=true;
      this.rightoptionforpub=false;
       this.pripubupdate = { IsPublic:false };
    }
    else{
      this.rightoptionforpub=true;
      this.rightoptionforpriv=false;
     this.pripubupdate = { IsPublic:true };

    }
    this.auth.updatecontent(contentId, this.pripubupdate).subscribe(
      (response) => {
        console.log("Content change to public change successfully", response);
        this.handleuploadssearch(datatype,false)
      },
      (error) => {
        console.error("Error changing the file name", error);
      }
    );
  }

  pripublessonupdate:any;
  lessonsaccess(data,accestype:any) {
    console.log("data, event",data,accestype)
    console.log("iska is public",data.IsPublic);
    const fileId = data.fileId;
    if (accestype=="private"){
      this.rightoptionforpriv=true;
      this.rightoptionforpub=false;
       this.pripublessonupdate = { IsPublic:false };
    }
    else{
      this.rightoptionforpub=true;
      this.rightoptionforpriv=false;
     this.pripublessonupdate = { IsPublic:true };

    }
    this.auth.updatefiles(fileId, this.pripublessonupdate).subscribe(
      (response) => {
        console.log("Content change to public change successfully", response);
        this.getlesson_private(false);
      },
      (error) => {
        console.error("Error changing the file name", error);
      }
    );
  }

  // cancel() {
  //   this.save_exit_ref.hide();
  //   this.move_exit_ref.hide();
  //   this.pdfimgmodalfile_exit_ref.hide();
  // }

  cancel(value:any) {
    console.log("cancel pressed")
    if(value==="save_exit"){
    this.save_exit_ref.hide();
    }else if(value==="move_exit"){
      this.move_exit_ref.hide();
    }else if(value==="pdfimgmodalfile_exit"){
      this.pdfimgmodalfile_exit_ref.hide();
    }else{
      return
    }
}

  movefile(data) {
    console.log("data", data);
    this.move_exit_ref = this.modalService.show(
      this.move_exit,
      Object.assign({}, { class: "setHeight" })
    );
  }

  duplicatepublicfiles(data) {
    //const url = environment.serverurl+`/delete-canvasfile/${data.fileId}`;
    const fileId = data.fileId;
    //const updates = { Bin : true };
    this.auth.duplicatetomylib(fileId).subscribe(
      (response) => {
        console.log("duplicate the file", response);
        //this.jsonList_private.splice(response, 1)[0];
        this.jsonList_private.push(response);
      },
      (error) => {
        console.error("Error making the file public", error);
      }
    );
  }
  fileName:string
  onFileSelectedimg(event: any,type:any) {
    if(event.target.files[0]){
    this.selectedFile = event.target.files[0];
    console.log("file info in selection", event, this.selectedFile);

    this.fileName=this.selectedFile.name
    const fileSizeInMB = this.selectedFile.size / (1024 * 1024);

    console.log(this.fileName,"nameoffile");
    if (type=="image"){
      // const allowedTypes = ['image/jpeg'];
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp','image/avip',];
      if (!allowedTypes.includes(this.selectedFile.type)) {
        // alert('Please select a valid image file (image, jpeg)');
        this.dialogService.showError('Please select a valid format file'+ '(jpeg, png, gif, bmp).');
        event.target.value = '';
        return;
      }
      if (this.role == 'demoaccounts' || (this.role == 'teacher' && this.licensetype == "1 user free version / freemium license")) {
        if (this.totalimagesarr.length < 10) {
          if (fileSizeInMB <= 2) {
            this.uploadcontentinlib(type);
          } else {
            this.dialogService.showError("Please select a file with a maximum size of 2MB.");
          }
        } else {
          this.dialogService.showError("You have reached the maximum number of uploads.");
        }
      } else {
        // this.uploadcontentinlib(type);
        if (fileSizeInMB <= 20) {
          this.uploadcontentinlib(type);
        } else {
          this.dialogService.showError("Please select a file with a maximum size of 20MB.");
        }
      }
    }
    else if (type == "video") {
      //  const allowedTypes = ['video/mp4', 'video/webm', 'video/quicktime'];  //quicktime not supported
      const allowedTypes = ['video/mp4', 'video/webm', 'video/ogg'];
      if (!allowedTypes.includes(this.selectedFile.type)) {
        this.dialogService.showError('Please select a valid file format' + '(mp4, webm).');
        event.target.value = '';
        return;
      }
      if (this.role == 'demoaccounts' || (this.role == 'teacher' && this.licensetype == "1 user free version / freemium license")) {
        if (this.totalvideosarr.length < 10) {
          if (fileSizeInMB <= 100) {
            this.uploadcontentinlib(type);
          } else {
            this.dialogService.showError("Please select a file with a maximum size of 100MB.");
          }
        } else {
          this.dialogService.showError("You have reached the maximum number of uploads.");
        }
      } else {
        if (fileSizeInMB <= 500) {
          this.uploadcontentinlib(type);
        } else {
          this.dialogService.showError("Please select a file with a maximum size of 500MB.");
        }
        // this.uploadcontentinlib(type);
      }

    }
    else if (type=="audio"){
     // const allowedTypes = ['audio/mpeg'];
     const allowedTypes = ['audio/mpeg','audio/mp3'];
      if (!allowedTypes.includes(this.selectedFile.type)) {
        this.dialogService.showError('Please select a valid file format'+'(mp3).');
        event.target.value = '';
        return;
      }
      if (this.role == 'demoaccounts') {
        if (this.totalaudioarr.length < 10) {
          if (fileSizeInMB <= 5) {
            this.uploadcontentinlib(type);
          } else {
            this.dialogService.showError("Please select a file with a maximum size of 5MB.");
          }
        } else {
          this.dialogService.showError("You have reached the maximum number of uploads.");
        }
      } else {
        // this.uploadcontentinlib(type);
        if (fileSizeInMB <= 500) {
          this.uploadcontentinlib(type);
        } else {
          this.dialogService.showError("Please select a file with a maximum size of 500MB.");
        }
      }
    } else if (type == "document") {
        const allowedTypes = [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "text/plain",
        ];

        if (!allowedTypes.includes(this.selectedFile.type)) {
          this.dialogService.showError('Please select a valid file format (pdf, excel, doc, txt, ppt).');
            event.target.value = '';
            return;
        }

        if (this.role == 'demoaccounts' || (this.role == 'teacher' && this.licensetype == "1 user free version / freemium license")) {
            if (this.totaldocarr.length < 10) {
                if (fileSizeInMB <= 10) {
                    this.uploadcontentinlib(type);
                } else {
                  this.dialogService.showError("Please select a file with a maximum size of 10MB.");
                }
            } else {
              this.dialogService.showError("You have reached the maximum number of uploads.");
            }
        } else {
              // this.uploadcontentinlib(type);
              if (fileSizeInMB <= 50) {
                this.uploadcontentinlib(type);
            } else {
              this.dialogService.showError("Please select a file with a maximum size of 50MB.");
            }
        }
    }
  }





    //this.preview3dImage();
    //this.uploadImage();
   // this.uploadcontentinlib();

  }

  selectedFile: File | null = null;
  //  uploadImage() {
  //   if (this.selectedFile) {
  //     const formData = new FormData();
  //     formData.append('file', this.selectedFile);
  //     console.log("inside upload image function",this.selectedFile)
  //     this.http.post(environment.serverurl+'/uploads_image', formData)
  //       .subscribe(
  //         (response: any) => {
  //           console.log("uploadimage calling",response);
  //           this.fetchImages()
  //         },
  //         (error: any) => {
  //           console.error(error);
  //         }
  //       );
  //     }

  //   }

  // uploadcontentinlib(type:any) {
  //   console.log("type",type)
  //   const formData = new FormData();
  //   formData.append("file", this.selectedFile);
  //   formData.append("type",type)
  //   console.log("formData%%%%%%%%%%%%%%%%%%%%", formData);

  //   this.auth.uploadcontent(formData).subscribe(
  //     (response) => {
  //       console.log("response", response);
  //       //this.fetchcontentfromcloud();
  //       this.handleuploadssearch(type,false)
  //       console.log("Image uploaded successfully", response);
  //     },
  //     (error) => {
  //       console.error("Error uploading image:", error);
  //     }
  //   );
  // }


  showSpinnerimage:Boolean=false;
  showSpinneraudio:Boolean=false;
  showSpinnervideo:Boolean=false;
  showSpinnerdocument:Boolean=false;
  showSpinnerobject:Boolean=false;

  uploadcontentinlib(type:any) {

    let UserId = localStorage.getItem('userId')
    console.log(this.selectedFile)
    if(type==='image'){
      this.showSpinnerimage=true;
    }else if(type==="audio"){
      this.showSpinneraudio=true;
    }else if(type==="document"){
      this.showSpinnerdocument=true
    }else if(type==="video"){
      this.showSpinnervideo=true
    }else{
      this.showSpinnerobject=true
    }
    const formData = new FormData();
    formData.append("file", this.selectedFile);
    formData.append("type",type)
    formData.append("UserId",UserId)

    console.log("formData%%%%%%%%%%%%%%%%%%%%", formData);

    this.auth.uploadcontent(formData).subscribe(
      (response) => {
        console.log("response", response);
        // this.fetchcontentfromcloud();
        if(type==='image'){
          this.showSpinnerimage=false;
        }else if(type==="audio"){
          this.showSpinneraudio=false;
        }else if(type==="document"){
          this.showSpinnerdocument=false;
        }else if(type==="video"){
          this.showSpinnervideo=false
        }else{
          this.showSpinnerobject=false
        }
        this.handleuploadssearch(type,false)
        console.log("Image uploaded successfully", response);
      },
      (error) => {
        console.error("Error uploading image:", error);
      }
    );
  }

  totalimages: boolean = false;
  imagesList: string[] = []; // Array to hold the image URLs
  videoList: string[] = []; // Array to hold the video URLs
  threeDList: string[] = []; // Array to hold the 3dimg URLs
  audioList: string[] = []; // Array to hold the audio URLs
  documentList: string[] = []; // Array to hold the document URLs
  totalimagesarr: string[] = [];
  totalvideosarr: string[] = [];
  totalaudioarr: string[] = [];
  totaldocarr: string[] = [];
  publicimgarr: string[] = [];
  publicvidarr: string[] = [];
  publicaudarr: string[] = [];
  publicdocarr: string[] = [];

  imageUrl: string | null = null;
  fetchcontentfromcloud(): void {
    this.totalimages = true;
    //this.img3d=false;
    //this.totalvideos=false;
    this.auth.getImages().subscribe(
      (response: any) => {
        console.log("Response:", response);

        if (response && Array.isArray(response.data)) {
          response.data.forEach((item: any) => {
            if (item.mimeType === "image/jpeg" && item.destinationpath) {
              if (!this.imagesList.includes(item.destinationpath)) {
                this.imagesList.push(item.destinationpath);

                this.totalimagesarr.push(item);

                console.log("Image List:", this.imagesList);
              }
            } else if (
              item.mimeType === "video/mp4" ||
              item.mimeType === "video/webm" ||
              item.mimeType === "video/ogg" ||
              item.mimeType === "video/quicktime" ||
              item.mimeType === "video/x-msvideo" ||
              item.mimeType === "video/x-flv" ||
              item.mimeType === "video/x-matroska" ||
              item.mimeType === "video/x-ms-wmv" ||
              item.mimeType === "video/3gpp" ||
              (item.mimeType === "video/x-m4v" && item.destinationpath)
            ) {
              if (!this.videoList.includes(item.destinationpath)) {
                this.videoList.push(item.destinationpath);
                this.totalvideosarr.push(item);
                console.log("Video List:", this.videoList);
              }
            } else if (item.mimeType === "glb" && item.destinationpath) {
              if (!this.threeDList.includes(item.destinationpath)) {
                this.threeDList.push(item.destinationpath);
                console.log("3D img List:", this.threeDList);
              }
            } else if (item.mimeType === "audio/mpeg" && item.destinationpath) {
              if (!this.audioList.includes(item.destinationpath)) {
                this.audioList.push(item.destinationpath);
                this.totalaudioarr.push(item);
                console.log("AUDIO List:", this.audioList);
              }
            } else if (
              item.mimeType === "text/plain" ||
              item.mimeType === "application/pdf" ||
              item.mimeType ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                item.mimeType ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"||
              item.mimeType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              (item.mimeType === "application/vnd.ms-excel" &&
                item.destinationpath)
            ) {
              if (!this.documentList.includes(item.destinationpath)) {
                this.documentList.push(item.destinationpath);
                this.totaldocarr.push(item);
                console.log("DOCUMENT List:", this.documentList);
              }
            }
          });
        } else {
          console.error(
            "No 'data' array found in the response or 'destinationpath' not found in data objects."
          );
          // Handle the case when 'data' is not available or doesn't contain 'destinationpath'
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getDocumentFileType(material) {
    switch (material.mimeType) {
      case "application/pdf":
        return `pdf`;
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return `image`;
      case "video/mp4":
      case "video/quicktime":
        return `mp4`;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx";
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return `xlsx`;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "pptx";
      case "text/plain":
        return "txt";
      default:
        return `unknown`;
    }
  }
  openDocumentInDocViewer(fileObj) {
    this.selectedMyLibDoc = false;
    this.addUploadedDocumentToCanvasEvent.emit(fileObj);
  }

  async getUploadPath(files: FileList , event:any) {

    let UserId = localStorage.getItem('userId')
    if (
      files != undefined &&
      files.length > 0 &&
      !!files[0].name != undefined
    ) {
      let fileget = event.target.files[0];
      const fileSizeInMB = fileget.size / (1024 * 1024);
      if (this.role == 'demoaccounts') {
        if (this._3DObjectsArray.length >= 10) {
          this.dialogService.showError("You have reached the maximum number of uploads.");
          return;
        }
        if (fileSizeInMB > 100) {
          this.dialogService.showError("Please select a file with a maximum size of 100MB.");
          return;
        }
      }
      let apiURL = "/threedyImageFile/uploads";
      // if (this.radibutton == 'thredycontents') {
      //   apiURL = '/threedyImageFile/uploads'
      // } else if(this.radibutton == 'pdf' || this.radibutton == 'word' || this.radibutton == 'ppt' || this.radibutton == 'excel' || this.radibutton == 'odt' || this.radibutton == 'rtf') {
      //   apiURL = '/importFilesFromLocal'
      // } else {
      //   apiURL = '/etherpad/uploads'
      // }
      let fileFormate = files[0].name.split(".");
      this.fileName=files[0].name;
      if(files[0].name){
        this.showSpinnerobject=true
      }
      // var type = files[0].type.toLowerCase()
      console.log(".....", files[0]);
      let formatesForthreedy = ["glb", "obj", "gltf", "fbx"];
      // var fileSize = Math.round(files[0].size / 1024)
      if (fileFormate[fileFormate.length - 1] != "jfif") {
        let formData: FormData = new FormData();
        formData.append("path", files[0]);
        formData.append("UserId",UserId)
        if (formatesForthreedy.includes(fileFormate[fileFormate.length - 1])) {
          this.auth
            .uploadMenuImage("profileImage", formData, apiURL)
            .subscribe((res) => {
              console.log("inside upload3d function", res);
              if (res.message === "ok") {
                //this.getThreedyContent();
                this.showSpinnerobject=false;
                this.handleuploadssearch("3D",false)
              } else {
                this.showSpinnerobject=false
                this.dialogService.showError("File upload failed.");
              }

            });
        } else {
          this.showSpinnerobject=false
        //  alert("please upload another format");
        this.dialogService.showError('Please select a valid file format'+ '(glb, fbx).');

        }
      } else {
        this.showSpinnerobject=false
        this.dialogService.showError("Please select a valid file format'+ '(glb, fbx).");
      }
      // }
    }
  }

  respResult: any = [];

  //changes start - shreyas.s
  _3DObjectsArray: any = [];
  getThreedyContent() {
    // alert(',,,,,,,,,,,,,,,,,')
    // this.auth.getTVInformation("", "/threedyContent").then((resp: any) => {
    //   try {
    //     let arrayList = resp.result;
    //     console.log("...............", resp);
    //     // let threedyapi = `${environment.serverurl}/threedyDetails/`
    //     let checkContent = [];
    //     arrayList.map((val) => {
    //       let value = val.split(".");
    //       if (value[value.length - 1] == "html") {
    //         let data = {
    //           image: `${environment.serverurl}/threedyDetails/${val}`,
    //           name: value[0],
    //           type: "thredycontents",
    //           fileType: "",
    //         };
    //         checkContent.push(data);
    //       }
    //     });

    //     let reslt = [];
    //     this.respResult = [];
    //     if (checkContent.length) {
    //       if (checkContent.length <= 4) {
    //         this.respResult.push(checkContent);
    //       } else {
    //         for (let k = 0; k < checkContent.length; k++) {
    //           reslt.push(checkContent[k]);
    //           if (reslt.length == 4) {
    //             this.respResult.push(reslt);
    //             reslt = [];
    //           }
    //         }
    //         if (reslt.length) {
    //           this.respResult.push(reslt);
    //         }
    //       }
    //     }

    //     console.log("////////////", this.respResult, checkContent);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // });

    this.googleClassroomService.get3DObjectURLsfromServer().subscribe((res) => {
      if (res.message === "success") {
        this._3DObjectsArray = res.list;
        console.log(this._3DObjectsArray);
      }
    });
  }
  // changes start - shreyas.s

  openGoogleClassroom() {
    this.isGoogleClassroomOpen = !this.isGoogleClassroomOpen;
    this.googleClicked = !this.googleClicked;
    this.mylib = false;
    this.peoplelib = false;
    this.openPublicLibrary = false;
    this.openLibrary = false;
    this.libraryClicked = false;
    this.googleDriveClicked = false;
    this.binClicked = false;
    this.binlessonlisting = false;

    this.publiclessonlisting = false
    this.publicimglibrary = false;
    this.img3dPubliclibrary = false;
    this.audioPubliclibrary = false;
    this.documentPubliclibrary = false;
    this.publicvideolibrary = false;
    // this.isGoogleDriveOpen = false;
    // this.publiclessonlisting = false;
    // this.peoplelib = false;

    // this.lessonlisting = false;

    ////////////////
  }
  openGoogleClassroomSection(data) {
    // this.activeSection=section
    this.mylib = false;
    this.img3dlibrary=false;
    this.imglibrary = false;
    this.videolibrary = false;
    this.audiolibrary = false;
    this.documentlibrary = false;
    this.publiclessonlisting = false;
    this.isGoogleDriveOpen = false;
    this.peoplelib = false;
    this.lessonlisting = false;
    this.isShowingGoogleCourseMaterial = false;
    this.binlessonlisting = false;
    this.mainname = "Google Class";
    const section = data.target.getAttribute("data-section");
    this.selectedSection = section;
    this.isGoogleClassroomSectionActive = true;
    console.log(data.target.getAttribute("data-section"));
    this.googleActiveCourses = Array.from(this.sections.get(section));
  }
  getGoogleCourses() {
    this.googleClassroomService
      .getCourseList()
      .subscribe((data: GoogleListData) => {
        const updatedSections = new Map<string, Course[]>();
        const courses = data.courses;
        courses.forEach((course) => {
          const updatedCourse = {
            ...course,
            updateTimeAgo: formatDistanceToNowStrict(
              new Date(course.updateTime),
              { addSuffix: true }
            ),
          };
          const section = updatedCourse.section;
          if (updatedSections.has(section)) {
            updatedSections.get(section).push(updatedCourse);
          } else {
            updatedSections.set(section, [updatedCourse]);
          }
        });
        this.sectionKeys = Array.from(updatedSections.keys());
        this.sections = updatedSections;
      });
  }

  getMaterialType(material: any): string {
    if (material.driveFile) {
      const acceptedFormats = [
        "mp4",
        "mov",
        "mp3",
        "jpeg",
        "jpg",
        "png",
        "svg",
        "pdf",
        "docx",
        "pptx",
        "ppt",
        "txt",
        "xlsx",
      ];
      const titleArr = material.driveFile.driveFile.title.split(".");
      const format = titleArr[titleArr.length - 1];
      const imageFormats = ["png", "jpeg", "jpg"];

      const val = acceptedFormats.includes(format)
        ? imageFormats.includes(format)
          ? "image"
          : format
        : "unknown";
      if (val === "ppt") return "pptx";
      return val;
    } else if (material.link) {
      return "link";
    } else if (material.youtubeVideo) {
      return "youtube";
    } else {
      return "unknown";
    }
  }
  handleGoogleCourseSelection(id: string, name: string) {
    this.isLoadingGoogleCourseSelection = true;
    this.selectedGoogleCourseID = id;
    this.selectedGoogleCourseName = name;
    this.googleClassroomService
      .getCourseMaterials(this.selectedGoogleCourseID)
      .subscribe((data: GoogleCourseWorkMaterials) => {
        this.isShowingGoogleCourseMaterial = true;
        this.selectedGoogleCourseMaterials = data;
        this.isLoadingGoogleCourseSelection = false;
      });
    console.log(this.selectedGoogleCourseID);
  }
  goBackToGoogleCourses() {
    this.isShowingGoogleCourseMaterial = false;
  }
  addGoogleCourseMaterialToCanvas(material) {
    this.addGoogleCourseMaterialEvent.emit(material);
  }
  openGoogleDriveSection() {
    this.isGoogleDriveOpen = true;
    this.img3dlibrary=false;
    this.mylib = false;
    this.peoplelib = false;
    this.imglibrary = false;
    this.videolibrary = false;
    this.audiolibrary = false;
    this.documentlibrary = false;
    this.publiclessonlisting = false;
    this.isGoogleClassroomOpen = false;
    this.selectedSection = null;
    this.lessonlisting = false;
    this.isGoogleClassroomSectionActive = false;
    this.binlessonlisting = false;
    this.binClicked = false;
    this.googleDriveClicked = true;
    this.openPublicLibrary = false;
    this.openLibrary = false;
    this.libraryClicked = false;
    this.googleClicked = false;
    this.mainname = "Google Drive";
    this.publiclessonlisting=false
    this.publicimglibrary=false;
   this.img3dPubliclibrary=false;
   this.audioPubliclibrary=false;
   this.documentPubliclibrary=false;
   this.publicvideolibrary = false;
    const acceptedFormats = [
      "video/mp4",
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
      "video/quicktime",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain",
    ];
    this.googleClassroomService.getGoogleDriveFiles().subscribe((data) => {
      this.googleDriveFiles = data?.files.filter((file) =>
        acceptedFormats.includes(file.mimeType)
      );
      console.log(this.googleDriveFiles);
    });
  }
  openGoogleDriveContextMenu(e, file) {
    e.stopPropagation(); //change - shreyas.s
    if (this.isGoogleDriveContextMenuOpen) {
      this.handleCloseGoogleDriveContextMenu();
      return;
    }
    this.isGoogleDriveContextMenuOpen = true;
    this.googleDriveContextMenuID = file.id;
    console.log(file.id);
  }
  handleGoogleDriveContextSubMenu_detailsOpen(event: Event, file) {
    event.stopPropagation();
    if (this.isGoogleDriveContextSubMenu_detailsOpen) {
      this.googleDriveSelectedMetaData = null;
      this.isGoogleDriveContextSubMenu_detailsOpen = false;
      return;
    }
    this.handleGetGoogleDriveMetaData(file);
    this.isGoogleDriveContextSubMenu_detailsOpen =
      !this.isGoogleDriveContextSubMenu_detailsOpen;
  }
  handleGetGoogleDriveMetaData(file) {
    this.googleClassroomService.getGoogleDriveFileMetaData(file.id).subscribe(
      (data) => {
        this.googleDriveSelectedMetaData = data;
        console.warn(data);
      },
      (err) => console.log(err)
    );
  }
  handleCloseGoogleDriveContextMenu() {
    this.isGoogleDriveContextMenuOpen = false;
    this.googleDriveSelectedMetaData = null;
    this.googleDriveContextMenuID = null;
    this.isGoogleDriveContextSubMenu_detailsOpen = false;
  }

  getDocumentFileThumbnail(type) {
    switch (type) {
      case "application/pdf":
        return `assets/PPDS/filemanager/drivepdf.svg`;
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return `assets/PPDS/filemanager/driveimage.svg`;
      case "video/mp4":
      case "video/quicktime":
        return `assets/PPDS/filemanager/drivemp4.svg`;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "assets/PPDS/filemanager/driveword.svg";
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return `assets/PPDS/filemanager/driveexcel.svg`;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "assets/PPDS/filemanager/driveppt.svg";
      case "text/plain":
        return "assets/PPDS/filemanager/drivetxt.svg";
      default:
        return `assets/PPDS/filemanager/driveimage.svg`;
    }
  }
  addGoogleDriveMaterialToCanvas(file) {}
  stopPropagation(event) {
    event.stopPropagation();
  }

  add3DObjectToViewer(_3dObj) {
    this.selectedMyLib3D=false;
    this.add3DObjectToViewerEvent.emit(_3dObj);
  }
  handleModelViewerLoad(event) {
    console.log(event);
  }

  // changes end - shreyas.s
  sharefileopt:boolean=false;
  shareoption(){
    this.sharefileopt=!this.sharefileopt;
    this.accesstopub = false;///hello
    console.log("ssssuuii")
    this.adjustDivPositionsharecard();
  }


  selectingpages: boolean = false;
  mainlessonID: string;
  imagesArray: any = [];
  demoarr: any = [];
  shareviaQR(data) {
    console.log("data ",data)
    this.pdfimgmodalfile_exit_ref = this.modalService.show(
      this.pdfimgmodalfile_exit,
      Object.assign({}, { class: "setHeight" })
    );
    this.selectingpages = true;
    this.mainlessonID = data._id
    console.log("sameeerrr", this.mainlessonID);
    this.auth.thumbnailgeneration(this.mainlessonID,this.sh,this.sw).subscribe(
      (response: any) => {
        console.log("Res--->", response);
        this.demoarr = response.data;
        console.log("demoarray", this.demoarr);
        this.imagesArray = response.data.map((item) => item.image);
        console.log("sameeeeeeeeeeeeeeeeeeeeeeee", this.imagesArray);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
  qrCodeData: any;
  async generateQRCodeForImagePath(imagePath: string) {
    this.selectingpages = false;
    const qrData = imagePath; // Image path data to encode in the QR code
    console.log("pdflink", qrData);

    try {
      this.qrCodeData = await QRCode.toDataURL(qrData);
      console.log("mainwrlink", this.qrCodeData);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  }

  pageidarray: any[] = [];
  handleImageSelection(data) {
    console.log("full data", data);
    const index = this.pageidarray.indexOf(data.page_id);
    if (index !== -1) {
      this.pageidarray.splice(index, 1);
    } else {
      this.pageidarray.push(data.page_id);
    }
    console.log("idddddddddddddd", this.pageidarray);
  }
  shareType: string = 'pdf';
  isShareButtonEnabled: boolean = false;
  enableShareButton() {
    this.isShareButtonEnabled = !!this.shareType;
  }
  selectAll: boolean = false;
  selectAllImages(event) {
    this.selectAll = event.target.checked;

    // Update the selection status of all checkboxes
    this.demoarr.forEach((data) => (data.selection = this.selectAll));
  }

  pdflink: string;
  sharebutton() {
    this.auth
      .pdfgeneration(this.mainlessonID, this.pageidarray)
      .subscribe(
        (response) => {
          // Handle the response if needed
          console.log("pdfffff", response);
          this.pdflink = response.data;
          console.log("pdflink", this.pdflink);
          this.generateQRCodeForImagePath(this.pdflink);
        },
        (error) => {
          // Handle errors if any
          console.error(error);
        }
      );
  }
  cancell() {
    this.pdfimgmodalfile_exit_ref.hide();
  }
  shareviaCopylink(data) {
    this.mainlessonID = data._id
    console.log("less",this.mainlessonID)
    this.auth
    .pdfgeneration(this.mainlessonID, this.pageidarray)
    .subscribe(
      (response) => {
        console.log("pdfffff", response);
        this.pdflink = response.data;
        console.log("pdflink", this.pdflink);
        console.log("xxxxxxxxx",window.navigator.userAgent.includes('Windows'))
        if (window.navigator.userAgent.includes('Windows')) {
          console.log("inside if window ")
          navigator.clipboard.writeText(this.pdflink)
          alert(this.pdflink);
          //console.error("Unable to copy text");
        }
        else{
              console.log("for android part")
        }

      },
      (error) => {
        console.error(error);
      }
    );
  }


Downloadlesson(data){
  this.mainlessonID = data._id
    console.log("less",this.mainlessonID)
    this.auth
    .pdfgeneration(this.mainlessonID, this.pageidarray)
    .subscribe(
      (response) => {
        console.log("pdfffff", response);
        this.pdflink = response.data;
        console.log("pdflink", this.pdflink);
        this.downloadPdf(this.pdflink,data.originalfilename)
      },
      (error) => {
        console.error(error);
      }
    );
    this.selectedlesson = null;
}

  downloadPdf(pdfUrl: string, pdfName: string = 'downloaded.pdf') {
    console.log("pdflinkkkkkkkk")
    this.http.get(pdfUrl, { responseType: 'blob' })
      .subscribe(blob => {
        this.downloadBlob(blob, pdfName);
      });
  }

  private downloadBlob(blob: Blob, filename: string) {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  restorefile(data) {
    //const url = environment.serverurl+`/delete-canvasfile/${data.fileId}`;
    const fileId = data.fileId;
    const updates = { Bin: false };
    this.auth.updatefiles(fileId, updates).subscribe(
      (response) => {
        console.log("restore from bin", response);
        //this.jsonList_private.splice(response, 1)[0];
        //this.jsonList_private.push(response);
        this.fetchbinlesson(false)
      },
      (error) => {
        console.error("Error restoring the lesson", error);
      }
    );
    this.selectedlesson = null;
  }




  /////////////////////////public library changes///////
  publicimglibrary:Boolean=false;
  img3dPubliclibrary:Boolean=false;
  audioPubliclibrary:Boolean=false;
  selectedlessonpiblic:null;
  documentPubliclibrary:Boolean=false;
  // truncateFilename(filename:any, length:any) {
  //   if (filename.length <= length) {
  //     return filename;
  //   } else {

  //     let extensionIndex = filename.lastIndexOf('.');
  //   let firstPart = filename.slice(0, length - (filename.length - extensionIndex));


  //     let extension = filename.slice(extensionIndex);

  //          let truncatedFilename = `${firstPart} .${extension}`;

  //     return truncatedFilename;
  //   }
  // }


  truncateFilename(filename:any, length:any) {
    console.log(filename);
    if (filename.length <= length) {
      return filename;
    } else {
     let extensionIndex = filename.lastIndexOf('.');
    let firstPart = filename.slice(0, extensionIndex);
    console.log(firstPart,"firstPart")
    if(firstPart.length>length){
      firstPart=firstPart.slice(0,length);
    }
    console.log("firstpart");
    let extension = filename.slice(extensionIndex);
    let truncatedFilename = `${firstPart}.. ${extension}`;
    //console.log(truncatedFilename,"truncate");
    return truncatedFilename;
    }
  }





  adjustDotpubPosition(): void {
    console.log("jk")
    const dotpubElement = this.dotpubElement.nativeElement;
    console.log(dotpubElement,"ggg");
    console.log(this.selectedlessonpiblic,"hhhh")

    if (dotpubElement && this.selectedlessonpiblic) {
      const rect = dotpubElement.getBoundingClientRect();
      console.log("rect",rect);
      const windowHeight = window.innerHeight;
      console.log(windowHeight,"windowHeight")
      const windowWidth = window.innerWidth;
      console.log(windowWidth,"windowWidth")

      // Calculate the new top and left positions
      let newTop = rect.top;
      let newLeft = rect.left;

      console.log("newTop",newTop,newLeft);

      // Adjust the position if going out of the screen vertically
      if (rect.bottom > windowHeight) {
        console.log("hii")
        newTop -= rect.bottom - windowHeight;
      }

      // Adjust the position if going out of the screen horizontally
      if (rect.right > windowWidth) {
        console.log("hello")
        newLeft -= rect.right - windowWidth;
      }

      // Apply the new position
      dotpubElement.style.top = `${newTop}px`;
      dotpubElement.style.left = `${newLeft}px`;
    }
  }
  selectedPublicimages:any
  openMoreOptionsPublicImages(e, value: any) {
    this.sharefileopt = false;
    this.accesstopub = false;
    e.stopPropagation();
    if (this.selectedPublicimages === value) {
      this.selectedPublicimages = null;
    } else
      this.selectedPublicimages = value;

    this.adjustDivPositionImagescard()
  }




  adjustDivPosition() {

    setTimeout(() => {
      const divElement = this.dotpubmylessonElement.nativeElement;
      console.log(divElement ,"divelementpp in settimeout")
        if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      console.log(rect,"rect",screenHeight,"screenheight",rect.left,"rect-h",rect.height)

      if (rect.bottom > screenHeight) {
        divElement.style.top = `${screenHeight - rect.bottom-30}px`;
        console.log( divElement.style.top,"divElement.style.to adjustDivPosition")
        console.log(screenHeight - rect.height,"lllll");
      } else {

       divElement.style.top = `${0}px`;
      }
      if((rect.left+10)>750){
        console.log(divElement.style.left+150,"ppppp")
        console.log("ppppp")
        divElement.style.left = `${divElement.style.left}px`;
      }else{
        console.log(rect.left,"in ")
        console.log(rect.left+10,"pppppkkkkkk")
        console.log("lllll")
        divElement.style.left = `${ divElement.style.left+181}px`;
      }
    }
    },10);




  }


  adjustDivPositionImagescard() {
  console.log("called")
    setTimeout(() => {
      const divElement = this.publicImages.nativeElement;
      if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      if (rect.bottom > screenHeight) {
     console.log(rect.bottom,"rectbottom")
      divElement.style.top = `${screenHeight - rect.bottom}px`;
      } else {
        console.log(rect.height,"in aaaaa")
        divElement.style.top = `${-7}px`;
      }
      if((rect.left+10)>750){
        divElement.style.left = `${divElement.style.left}px`;
      }else{
        divElement.style.left = `${ divElement.style.left+181}px`;
      }
    }
    },10);
}


adjustDivPositionVideocard(){
  console.log("called")
    setTimeout(() => {
      const divElement = this.publicExtraoption.nativeElement;
      if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      if (rect.bottom > screenHeight) {
     console.log(rect.bottom,"rectbottom",screenHeight - rect.bottom)
      divElement.style.top = `${screenHeight - rect.bottom}px`;
      } else {
        console.log(rect.height,"in aaaaa")
        divElement.style.top = `${-7}px`;
      }
      if((rect.left+10)>750){
        console.log(rect.left,"rrrrr")
        divElement.style.left = `${divElement.style.left}px`;
      }else{
        divElement.style.left = `${ divElement.style.left+181}px`;
      }



    }
    },10);
}



adjustDivPositionsharecard() {
  console.log("adjust")

  setTimeout(() => {
    const divElement = this.shareFileopt.nativeElement;
    console.log("ddd",divElement)
    if (divElement) {
    const rect = divElement.getBoundingClientRect();
    console.log(rect.left)
    //divElement.style.left=null;
    if((rect.left)>750){
      console.log(rect.left-750,"ooooo")
      if(this.mainname==="My Lessons"){
        divElement.style.left = `${-268}px`;
        }else{
          divElement.style.left = `${-330}px`
        }
      //divElement.style.left = `${-330}px`;
    }else{
      console.log(rect.left,"rect.left in share card")
      divElement.style.left = `${ divElement.style.left}px`;
    }
  }
  },10);
}


adjustDivPositionvideosharecard() {
  console.log("adjust")

  setTimeout(() => {
    const divElement = this.shareFileoptvideo.nativeElement;
    console.log("ddd",divElement)
    if (divElement) {

    const rect = divElement.getBoundingClientRect();

    console.log(rect.left,"in adjustDivPositionvideosharecard")

    if((rect.left)>900){
      console.log(rect.left-750,"ooooo")
      console.log("rect.left",rect.left);
      divElement.style.left = `${-330}px`;
    }else{
      console.log(rect.left,"rect.left in share card")
      divElement.style.left = `${ divElement.style.left}px`;
    }

  }
  },10);
}








adjustDivPositionacesscard() {
  console.log("adjust")

  setTimeout(() => {
    const divElement = this.accessTopub.nativeElement;
    console.log("ddd",divElement)
    if (divElement) {
    const rect = divElement.getBoundingClientRect();
    console.log(rect.left)
    if((rect.left)>750){
      console.log(rect.left-750,"ooooo")
      if(this.mainname==="My Lessons"){
        divElement.style.left = `${-268}px`;
      }else{
      divElement.style.left = `${-330}px`;
      }
      //divElement.style.left = `${-330}px`;
    }else{
      console.log(rect.left,"rect.left in share card")
      divElement.style.left = `${ divElement.style.left}px`;
    }
  }
  },10);
}


adjustDivPositionvideoacesscard() {
  console.log("adjust")

  setTimeout(() => {
    const divElement = this.accessTopubvideo.nativeElement;
    console.log("ddd",divElement)
    if (divElement) {
    const rect = divElement.getBoundingClientRect();
    console.log(rect.left)
    if((rect.left)>900){
      console.log(rect.left-750,"ooooo")
      divElement.style.left = `${-330}px`;
    }else{
      console.log(rect.left,"rect.left in share card")
      divElement.style.left = `${ divElement.style.left}px`;
    }
  }
  },10);
}

  selectedPublicvideos: any
  openMoreOptionsPublicvideos(e, value: any) {
    e.stopPropagation();

    if (this.selectedPublicvideos === value) {
      this.selectedPublicvideos = null

    } else {
      this.selectedPublicvideos = value;
    }

    this.shareFilevideoOpt = false;
    this.accesstopubvideo = false;

    console.log("oooi", this.shareFilevideoOpt = false,
      this.accesstopubvideo = false)
    this.adjustDivPositionVideocard();
  }

  selectedPublicaudio: any
  selectedPublic3d:any
  openMoreOptionsPublicAudios(e, value: any) {
    e.stopPropagation();

    if (this.selectedPublicaudio === value) {
      this.selectedPublicaudio = null

    } else {
      this.selectedPublicaudio = value;
    }

    this.shareFilevideoOpt = false;
    this.accesstopubvideo = false;

    console.log("oooi", this.shareFilevideoOpt = false,
      this.accesstopubvideo = false)
    this.adjustDivPositionVideocard();
  }



  openMorepublic3DOptions(e, value: any){
    e.stopPropagation();
    if (this. selectedPublic3d === value) {
      this. selectedPublic3d = null

    } else {
      this. selectedPublic3d = value;
    }
    this.adjustDivPositionVideocard();
  }

  selectedPublicdoc: any
  openMoreOptionsPublicDocs(e, value: any) {
    e.stopPropagation();

    if (this.selectedPublicdoc === value) {
      this.selectedPublicdoc = null

    } else {
      this.selectedPublicdoc = value;
    }

    this.shareFilevideoOpt = false;
    this.accesstopubvideo = false;

    console.log("oooi", this.shareFilevideoOpt = false,
      this.accesstopubvideo = false)
    this.adjustDivPositionVideocard();
  }


  openMoreOptionPublic(e,data: any) {
     e.stopPropagation();
    console.log("selected lesson");
    if(this. selectedlessonpiblic===data){
      this.selectedlessonpiblic = null;
    }else
    this. selectedlessonpiblic = data;
    console.log("xcxcxc", this.selectedlessonpiblic);
   // this.adjustDotpubPosition()
   this.adjustDivPosition();
  }

  shareFilevideoOpt:Boolean=false
  shareoptionVideo(){
    this.shareFilevideoOpt=!this.shareFilevideoOpt;
    this.accesstopubvideo = false;///hello
    console.log("ssssuuii")
   this.adjustDivPositionvideosharecard()
  }


  accesstopubvideo: boolean = false;
  accessoptiovideo(data) {
    this.accesstopubvideo = !this.accesstopubvideo;
    this.shareFilevideoOpt = false;
    this.adjustDivPositionvideoacesscard();
    this.rightoptionforpriv=false;
    this.rightoptionforpub = false;
    console.log("data.ispublic",data.IsPublic)
    if(data.IsPublic==false){
      this.rightoptionforpriv=true;
    }
    else if(data.IsPublic==true){
      this.rightoptionforpub = true;
    }
  }

  selectedplaceFiletomove:any
  selectpubtomove:boolean=false;
  Selectfiletomove(value:any){
    this.selectpubtomove=!this.selectpubtomove;
    this.selectedplaceFiletomove=value
}

handlefileMove(data){
  if(this.selectedplaceFiletomove==="Public Library"){
   this.makeFilePublic(data);
  }
};


///////////////////////search functionality code//////////////
searchmylibrarylesson:any="";
searchpage:any=1;
handlemylibrarylessonsearch(handlepageadd:boolean){
  if(handlepageadd){
    this.searchpage=this.searchpage+1
  } else{
    this.searchpage=1
  }
  console.log("this.searchpage in lb",this.searchpage)
 this.auth.searchMylibararylesson(this.userslesson,this.searchmylibrarylesson,this.searchpage).subscribe(
  (response:any)=>{
    console.log("Res--->", response);

    this.jsonList_private = response; // Assign the response directly to jsonList
    console.log("list_private", this.jsonList_private);
    if(this.searchpage>1&&response.length>0){

      response.map(item=>{
        console.log(item,"item");
        this.jsonList_private.push(item);

      })
      this.Shownomatch=false
      this.totalimages=true
      console.log( this.totalimagesarr,"images")
      this.Shownomatch=false;
    }else if(this.searchpage===1&&response.length===0){
     this.Shownomatch=true
     this.totalimages=false
    }else if(this.searchpage===1&&response.length>0){
      this.totalimages=true
      this.jsonList_private=response
    console.log(this.jsonList_private,"this")
    this.Shownomatch=false;
    }else{
      return
    }
    //fname=this.jsonList_private[i].originalfilename
  },
  (error: any) => {
    console.error(error);
  }
 )
}

searchByname:any="";
searchtype:any;
Shownomatch:Boolean=false;
errortoshow=""



handleuploadssearch(type:any,handlepageadd:Boolean){
  let userId=localStorage.getItem("userId")
  console.log(this.searchpage,"searchpage")
  this.searchtype=type
if(handlepageadd){
  this.searchpage=this.searchpage+1
} else{
  this.searchpage=1
}
if (this.fetchpublicimage||this.fetchpublicvideo||this.fetchpublicaudio||this.fetchpublicdoc||this.fetchpublic3d) {
  let publicflag=true;
  // If publicImage is true, search for public content
  this.auth.searchuploadedContents(type, this.searchByname, this.searchpage, undefined, publicflag).subscribe(
    (response:any)=>{
      console.log("Res-pubbbbbbbbbbbbb", response);
        if(type==="image"){
        //this.totalimages=true
        if(this.searchpage>1&&response.data.length>0){

          response.data.map(item=>{
            console.log(item,"item");
            this.publicimgarr.push(item);
            })
          console.log( this.publicimgarr,"images")
          this.Shownomatch=false;
          this.totalimages=true
        }else if(this.searchpage===1&&response.data.length===0){
         this.Shownomatch=true
         this.totalimages=false
        }else if(this.searchpage===1&&response.data.length>0){
          this.totalimages=true
        this.publicimgarr=response.data
        console.log(this.publicimgarr,"this")
        this.Shownomatch=false;
        }else{
          return
        }
         }
        if(type==="audio"){
          // this.totalaudioarr=response.data
          if(this.searchpage>1&&response.data.length>0){
           this.totalimages=true
           response.data.map(item=>{
             console.log(item,"item");
             this.publicaudarr.push(item);
           })
           this.Shownomatch=false;
           console.log( this.publicaudarr,"images")
         }else if(this.searchpage===1&&response.data.length===0){
           this.Shownomatch=true;
           this.totalimages=false
         }else if(this.searchpage===1&&response.data.length>0){
           this.totalimages=true
         this.publicaudarr=response.data
         this.Shownomatch=false;
         console.log(this.publicaudarr,"this")
         }else{
           return
         }
         }

         /////////////////////////////////////video//////
         if(type==="video"){

           //this.totalvideosarr=response.data
           if(this.searchpage>1&&response.data.length>0){
             this.totalimages=true
             response.data.map(item=>{
               console.log(item,"item");
               this.publicvidarr.push(item);
             })
             this.Shownomatch=false;
             console.log( this.publicvidarr,"images")
           }else if(this.searchpage===1&&response.data.length===0){
               this.Shownomatch=true;
               this.totalimages=false
           }else if(this.searchpage===1&&response.data.length>0){

           this.publicvidarr=response.data
           this.totalimages=true
           this.Shownomatch=false;
           console.log(this.publicvidarr,"this in video")
           }else{
             return
           }
         }
         /////////////////////////Document//////////////////
         if(type==="document"){
           if(this.searchpage>1&&response.data.length>0){

             response.data.map(item=>{
               console.log(item,"item");
               this.publicdocarr.push(item);

             })
             this.totalimages=true;
             this.Shownomatch=false;
             console.log( this.publicdocarr,"images")
           }else if(this.searchpage===1&&response.data.length===0){
             this.Shownomatch=true;
             this.totalimages=false
           }else if(this.searchpage===1&&response.data.length>0){
           this.publicdocarr=response.data
           this.totalimages=true;
           this.Shownomatch=false;
           console.log(this.publicdocarr,"this")
           }else{
             return
           }

         }
         /////////////////////////////////////////////3D////////////////////////////////////////////
         if(type==="3D"){
          if(this.searchpage>1&&response.data.length>0){

            response.data.map(item=>{
              console.log(item,"item");
              this._3DObjectsArray.push(item);

            })
            this.totalimages=true;
            this.Shownomatch=false;
            console.log( this.totaldocarr,"images")
          }else if(this.searchpage===1&&response.data.length===0){
            this.Shownomatch=true;
            this.totalimages=false
          }else if(this.searchpage===1&&response.data.length>0){
          this._3DObjectsArray=response.data
          this.totalimages=true;
          this.Shownomatch=false;
          console.log(this._3DObjectsArray,"this")
          }else{
            return
          }

        }

    })
}
else{

  this.auth.searchuploadedContents(type,this.searchByname,this.searchpage,userId, undefined).subscribe(
    (response:any)=>{
      console.log("Res--->", response);


/////////////////////////////image/////////////////////////////////
        if(type==="image"){
          //this.totalimages=true
          if(this.searchpage>1&&response.data.length>0){

            response.data.map(item=>{
              console.log(item,"item");
              this.totalimagesarr.push(item);
              })
            console.log( this.totalimagesarr,"images")
            this.Shownomatch=false;
            this.totalimages=true
          }else if(this.searchpage===1&&response.data.length===0){
           this.Shownomatch=true
           this.totalimages=false
          }else if(this.searchpage===1&&response.data.length>0){
            this.totalimages=true
          this.totalimagesarr=response.data
          console.log(this.totalimagesarr,"this")
          this.Shownomatch=false;
          }else{
            return
          }
          }
          //////////////////////////////////////////////Audio////////////////////////
      if(type==="audio"){
       // this.totalaudioarr=response.data
       if(this.searchpage>1&&response.data.length>0){
        this.totalimages=true
        response.data.map(item=>{
          console.log(item,"item");
          this.totalaudioarr.push(item);
        })
        this.Shownomatch=false;
        console.log( this.totalaudioarr,"images")
      }else if(this.searchpage===1&&response.data.length===0){
        this.Shownomatch=true;
        this.totalimages=false
      }else if(this.searchpage===1&&response.data.length>0){
        this.totalimages=true
      this.totalaudioarr=response.data
      this.Shownomatch=false;
      console.log(this.totalimagesarr,"this")
      }else{
        return
      }
      }

      /////////////////////////////////////video//////
      if(type==="video"){

        //this.totalvideosarr=response.data
        if(this.searchpage>1&&response.data.length>0){
          this.totalimages=true
          response.data.map(item=>{
            console.log(item,"item");
            this.totalvideosarr.push(item);
          })
          this.Shownomatch=false;
          console.log( this.totalvideosarr,"images")
        }else if(this.searchpage===1&&response.data.length===0){
            this.Shownomatch=true;
            this.totalimages=false
        }else if(this.searchpage===1&&response.data.length>0){

        this.totalvideosarr=response.data
        this.totalimages=true
        this.Shownomatch=false;
        console.log(this.totalvideosarr,"this in video")
        }else{
          return
        }
      }
      /////////////////////////Document//////////////////
      if(type==="document"){
        if(this.searchpage>1&&response.data.length>0){

          response.data.map(item=>{
            console.log(item,"item");
            this.totaldocarr.push(item);

          })
          this.totalimages=true;
          this.Shownomatch=false;
          console.log( this.totaldocarr,"images")
        }else if(this.searchpage===1&&response.data.length===0){
          this.Shownomatch=true;
          this.totalimages=false
        }else if(this.searchpage===1&&response.data.length>0){
        this.totaldocarr=response.data
        this.totalimages=true;
        this.Shownomatch=false;
        console.log(this.totalaudioarr,"this")
        }else{
          return
        }

      }
      ////////////////////////////////////////////////////3D///////////////////////////////////////
      if(type==="3D"){
        if(this.searchpage>1&&response.data.length>0){

          response.data.map(item=>{
            console.log(item,"item");
            this._3DObjectsArray.push(item);

          })
          this.totalimages=true;
          this.Shownomatch=false;
          console.log( this.totaldocarr,"images")
        }else if(this.searchpage===1&&response.data.length===0){
          this.Shownomatch=true;
          this.totalimages=false
        }else if(this.searchpage===1&&response.data.length>0){
        this._3DObjectsArray=response.data
        this.totalimages=true;
        this.Shownomatch=false;
        console.log(this._3DObjectsArray,"this")
        }else{
          return
        }

      }

    },
    (error: any) => {
      console.error(error);
    }
   )
  }
}











onScroll(value) {

  console.log("value in onscroll",value)
  console.log("hiiii")

  // const element = value==='scrollevent'?this.scrollevent.nativeElement:value==='videoscroll'?this.videoscroll.nativeElement:value==='documentscroll'?this.documentscroll.nativeElement:this.audioscrollevent.nativeElement;
 // const element = value==='scrollevent'?this.scrollevent.nativeElement:value==='videoscroll'?this.videoscroll.nativeElement:value==='documentscroll'?this.documentscroll.nativeElement:value==='audioscrollevent'?this.audioscrollevent.nativeElement:this.mylessonscroll.nativeElement;
  const element = value==='scrollevent'?this.scrollevent.nativeElement:
  value==='videoscroll'?this.videoscroll.nativeElement:
  value==='documentscroll'?this.documentscroll.nativeElement:
  value==='audioscrollevent'?this.audioscrollevent.nativeElement:
  value==='publicDocumentscroll'?this.publicDocumentscroll.nativeElement:
  value==='publicAudioScroll'?this.publicAudioScroll.nativeElement:
  value==='publicVideoscroll'?this.publicVideoscroll.nativeElement:
  value==='publicimagescrollevent'?this.publicimagescrollevent.nativeElement:
  value==='publiclessonscroll'?this.publiclessonscroll.nativeElement:
  value==='binscrollevent'?this.binscrollevent.nativeElement:
  value==='threeDscroll'?this.threeDscrollevent.nativeElement:
  value==='publicthreedyscroll'?this.publicthreedyscrollevent:
  this.mylessonscroll.nativeElement;

  console.log("element",element)
  console.log("searchtype",this.searchtype)
  console.log("elementscrolltop",element.scrollTop,element.clientHeight,element.scrollHeight)
  console.log()
  if ((element.scrollTop + element.clientHeight+2) >= element.scrollHeight) {
    console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
    if(value==='scrollevent'||value==='videoscroll'||value==='documentscroll'||value==='audioscrollevent'||value==='publicDocumentscroll'||value==='publicAudioScroll'||value==='publicVideoscroll'||value==='publicimagescrollevent'||value==='threeDscroll'||value==='publicthreedyscroll'){
      console.log("type",this.searchtype)
  this.handleuploadssearch(this.searchtype,true);
    }
  else if(value==='mylessonscroll'){
    console.log("mylessson................")
  // this.handlemylibrarylessonsearch(true)
  this.getlesson_private(true)
  } else if(value==='publiclessonscroll'){
    console.log("publiclesson................")
  // this.handlemylibrarylessonsearch(true)
  this.getlesson_public(true)
  }
  else if(value==='binscrollevent'){
    console.log("binscrollevent................")
  // this.handlemylibrarylessonsearch(true)
  this.fetchbinlesson(true)
  }
 else{
    return
  }
}


    console.log('API call triggered');

}


Insertdata(value:string,type:string,imageUrl:any){
  console.log("clickeddddddddddddd")
  console.log(value);


  this.inserDataevent.emit({ data1: value, data2: type, imageUrl:imageUrl })
}

putimgoncanvas(data) {
  console.log("url", data);
  this.mycanvas.addImageOnCanvas(data,"filemanager");
}

duplicatecontinmylib(data){
  console.log("dupppppppp",data)
  const contentId = data.contentId;
  //const updates = { Bin : true };
  this.auth.duplicateresourcetomylib(contentId).subscribe(
    (response) => {
      console.log("duplicated to my library", response);
      if(response.status){
        this.dialogService.showError("File already exists.")
        return;
      }
      //this.totalimagesarr.push(response.duplicatedFile);
      const item=response.duplicatedFile
      if (response) {
        // response.duplicatedFile.forEach((item: any) => {
          if (item.mimeType === "image/jpeg" && item.destinationpath) {
            // if (!this.imagesList.includes(item.destinationpath)) {
              this.imagesList.push(item.destinationpath);

              this.totalimagesarr.push(item);

              console.log("Image List:", this.imagesList);
            // }
          } else if (
            item.mimeType === "video/mp4" ||
            item.mimeType === "video/webm" ||
            item.mimeType === "video/ogg" ||
            item.mimeType === "video/quicktime" ||
            item.mimeType === "video/x-msvideo" ||
            item.mimeType === "video/x-flv" ||
            item.mimeType === "video/x-matroska" ||
            item.mimeType === "video/x-ms-wmv" ||
            item.mimeType === "video/3gpp" ||
            (item.mimeType === "video/x-m4v" && item.destinationpath)
          ) {
           // if (!this.videoList.includes(item.destinationpath)) {
              this.videoList.push(item.destinationpath);
              this.totalvideosarr.push(item);
              console.log("Video List:", this.videoList);
           // }
          } else if (item.mimeType === "glb" && item.destinationpath) {
           // if (!this.threeDList.includes(item.destinationpath)) {
              this.threeDList.push(item.destinationpath);
              console.log("3D img List:", this.threeDList);
           // }
          } else if (item.mimeType === "audio/mpeg" && item.destinationpath) {
           // if (!this.audioList.includes(item.destinationpath)) {
              this.audioList.push(item.destinationpath);
              this.totalaudioarr.push(item);
              console.log("AUDIO List:", this.audioList);
           // }
          } else if (
            item.mimeType === "text/plain" ||
            item.mimeType === "application/pdf" ||
            item.mimeType ===
              "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
              item.mimeType ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"||
            item.mimeType ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            (item.mimeType === "application/vnd.ms-excel" &&
              item.destinationpath)
          ) {
            //if (!this.documentList.includes(item.destinationpath)) {
              this.documentList.push(item.destinationpath);
              this.totaldocarr.push(item);
              console.log("DOCUMENT List:", this.documentList);
           // }
          }
       // });
      } else {
        console.error(
          "No 'data' array found in the response or 'destinationpath' not found in data objects."
        );
        // Handle the case when 'data' is not available or doesn't contain 'destinationpath'
      }


    },
    (error) => {
      console.error("Error while duplicating the file to my library", error);
    }
  );
  }


duplicatelessonsinmylib(data){
    console.log("dupppppppp",data)
    const fileId = data.fileId;
    //const updates = { Bin : true };
    this.auth.duplicatelessonstomylib(fileId).subscribe(
      (response) => {
        console.log("duplicated to my library", response);
        if(response.status){
          this.dialogService.showError("File already exists.")
          return;
        }
        const item=response.duplicatedFile
        if (response) {
          this.jsonList_private.push(item)

        }

        else {
          console.error(
            "No 'data' array found in the response  not found in data objects."
          );
          // Handle the case when 'data' is not available or doesn't contain 'destinationpath'
        }


      },
      (error) => {
        console.error("Error while duplicating the file to my library", error);
      }
    );
  }



  isPublicchanges(data){
    console.log("clicked");
    console.log(data,"data")
    if(data.IsPublic===true){
      this.lessonsaccess(data,'private')
    }else{
      this.makeFilePublic(data)
    }



  }


  handlepublicupdates(data,datatype){
  let type
    if(data.IsPublic===true){
     type='private'
    }else
    type='public'
    this.contentaccess(data,type,datatype)



  }



  getScreenWidth(): number {
    return window.innerWidth;
  }

  getScreenHeight(): number {
    return window.innerHeight;
  }



  deletePublicContent(type:any,data){



    console.log(data, "deletePublicContent");
    console.log(data.contentId);
    this.auth.deletePublicContent(data.contentId).subscribe(
      (response: any) => {
        console.log("Response:", response);
        this.handleuploadssearch(type,false);
        this.dialogService.showSimplePopup(`${type} has been deleted.`);


      },
      (error: any) => {
        console.error("Error:", error);
       if (error.status === 404) {
          console.error("Content not found.");
        } else if (error.status === 500) {
          console.error("Server error, please try again later.");
        } else {
          console.error("An unknown error occurred.");
        }
      }

    );
  }



  deletePublicContentlesson(data){
    console.log(data,"data in public lesson")
    this.auth.deletePubliclesson(data).subscribe(
      (response: any) => {
        console.log("Response:", response);
        this.getlesson_public(false);
        this.dialogService.showSimplePopup('Lesson has been deleted.');

      },
      (error: any) => {
        console.error("Error:", error);
       if (error.status === 404) {
          console.error("Content not found.");
        } else if (error.status === 500) {
          console.error("Server error, please try again later.");
        } else {
          console.error("An unknown error occurred.");
        }
      }

    );
  }



  selectedbinlesson=null;
  openMoreOptionsbin(e:any,data){
   if(this.selectedbinlesson===data){
    this.selectedbinlesson=null
   }else{
    this.selectedbinlesson=data

   }
   this.adjustbinDivPosition();
  }



  adjustbinDivPosition() {
console.log("hhhhhh")
    setTimeout(() => {
      const divElement = this.binmoreoption.nativeElement;
      console.log(divElement ,"divelementpp in settimeout")
        if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      console.log(rect,"rect",screenHeight,"screenheight",rect.left,"rect-h",rect.height)

      if (rect.bottom > screenHeight) {
        divElement.style.top = `${screenHeight - rect.bottom-30}px`;
        console.log( divElement.style.top,"divElement.style.to adjustDivPosition")
        console.log(screenHeight - rect.height,"lllll");
      } else {

      // divElement.style.top = `${0}px`;
      }
      if((rect.left+10)>750){
        console.log(divElement.style.left+150,"ppppp")
        console.log("ppppp")
        divElement.style.left = `${divElement.style.left}px`;
      }else{
        console.log(rect.left,"in ")
        console.log(rect.left+10,"pppppkkkkkk")
        console.log("lllll")
        divElement.style.left = `${ divElement.style.left+181}px`;
      }
    }
    },10);




  }

  confirmbindelete(data) {
    this.binToDelete = data;
    this.isBinDelete = true;
    this.showConfirmation = true;
  }

  deletebinfiles() {
    const data = this.binToDelete;
    this.auth.deletebinlesson(data.fileId).subscribe(
      (response: any) => {
        console.log("Response:", response);
        this.fetchbinlesson(false);
        this.dialogService.showSimplePopup('Lesson has been deleted');

      },
      (error: any) => {
        console.error("Error:", error);
       if (error.status === 404) {
          console.error("Content not found.");
        } else if (error.status === 500) {
          console.error("Server error, please try again later.");
        } else {
          console.error("An unknown error occurred.");
        }
      }

    );
    this.closeDialog();
  }



  // deletebinfiles(data){
  //   console.log(data);
  //   this.auth.deletebinlesson(data.fileId).subscribe(
  //     (response: any) => {
  //       console.log("Response:", response);
  //       this.fetchbinlesson(false);
  //       this.dialogService.showSimplePopup('Lesson has been deleted');

  //     },
  //     (error: any) => {
  //       console.error("Error:", error);
  //      if (error.status === 404) {
  //         console.error("Content not found.");
  //       } else if (error.status === 500) {
  //         console.error("Server error, please try again later.");
  //       } else {
  //         console.error("An unknown error occurred.");
  //       }
  //     }

  //   );
  //     }

      alertmsgshown:any=""
      showfilealert(){
      this.alertmsgshown=this.fetchavchatmode.coursemodeactive==="Present"?"Presentation mode active":"Collaboration mode active"
        this.filealert_exit_ref = this.modalService.show(
          this.filesalert_exit,
          Object.assign({}, { class: "setHeight" })
        );

        setTimeout(() => {
          this.filealert_exit_ref.hide();
        }, 1000);

      }



      closeallactivefiles(){
        this.lessonlisting = false;
        this.imglibrary = false;
        this.videolibrary = false;
        this.img3dlibrary = false;
        this.audiolibrary = false;
        this.documentlibrary = false;
        this.myLessonClicked = false;
        this.openMyLesson = false;
        this.openImages = false;
        this.imagesClicked = false;
        this.videosClicked = false;
        this.openVideos = false;
        this.threedmodelsClicked = false;
        this.open3dmodels = false;
        this.audioClicked = false;
        this.openAudio = false;
        this.documentClicked = false;
        this.openDocument = false;
        this.imglibrary = false;
        this.lessonlisting = false;
        this.videolibrary = false;
        this.img3dlibrary = false;
        this.audiolibrary = false;
        this.documentlibrary = false;
        this.publiclessonlisting = false;
        this.myLessonClicked = false;
       this.imagesClicked = false;
       this.videosClicked = false;
       this.threedmodelsClicked = false;
       this.audioClicked = false;
       this.documentClicked = false;
       this.publicimglibrary=false;
       this.publicvideolibrary=false;
       this. img3dPubliclibrary=false;
       this.audioPubliclibrary=false
       this.documentPubliclibrary=false;
       this.selectedPublicimages=null;
       this.peoplelib = !this.peoplelib;
    this.publiclessonlisting = false;
    this.mylib = false;
    this.isGoogleClassroomOpen = false; // change - shreyas.s
    this.selectedSection = null;
    this.isGoogleDriveOpen = false;
    this.googleClicked = false;
    this.googleDriveClicked = false;
    this.binlessonlisting = false;
    this.binClicked = false;
    this.openLibrary = false;
    this.libraryClicked = false;
    this.publicLibraryClicked = false;
    this.openPublicLibrary = false;
    ////////////////////////////
    this.lessonlisting = false
    this.imglibrary = false;
    this.videolibrary = false;
    this.img3dlibrary = false;
    this.audiolibrary = false;
    this.documentlibrary = false;
    this.myLessonClicked = false;
    this.openMyLesson = false;
    this.openImages = false;
    this.imagesClicked = false;
    this.videosClicked = false;
    this.openVideos = false;
    this.threedmodelsClicked = false;
    this.open3dmodels = false;
    this.audioClicked = false;
    this.openAudio = false;
    this.documentClicked = false;
    this.openDocument = false;
    this.peoplelib=false

    this.isGoogleClassroomSectionActive = false;






}


removeExtension(filename:any){
  let nameWithoutExtension = filename.substring(0, filename.lastIndexOf('.'));
  let extension = filename.substring(filename.lastIndexOf('.') + 1);

  return nameWithoutExtension

}


 replaceFilename(filename, newName) {

  let extension = filename.substring(filename.lastIndexOf('.'));


  return `${newName}${extension}`;
}


}
















