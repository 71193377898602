
import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";

// Import your components or modules here
import { FirstscreenComponent } from "./firstscreen/firstscreen.component";
import { EtherpadkmsComponent } from "./etherpadkms/etherpadkms.component";
import { QRloginComponent } from "./qrlogin/qrlogin.component";
import { UserpassloginComponent } from "./userpasslogin/userpasslogin.component";
import { PassrecoveryComponent } from "./passrecovery/passrecovery.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { WhiteboardpageComponent } from "./whiteboardpage/whiteboardpage.component";
import { SuperadminComponent } from "./superadmin/superadmin.component";
import { RegionaladmineditComponent } from "./regionaladminedit/regionaladminedit.component";
import { PhoneuiloginComponent } from "./phoneuilogin/phoneuilogin.component";
import { ClientVotingViewComponent } from "./client-voting-view/client-voting-view.component";
import { SigninComponent } from "./gc-plugin/signin.component";
import { SigninCallbackComponent } from "./gc-plugin/signin-callback/signin-callback.component";
import { SignoutCallbackComponent } from "./gc-plugin/signin-callback/signout-callback.component";
import { StudentavchatComponent } from "./studentavchat/studentavchat.component";
import { CalculatorComponent } from "./calculator/calculator.component";
import { VotingSectionComponent } from "./voting-section/voting-section.component";
import { DemoemailComponent } from "./demoemail/demoemail.component";
import { DemoSetuserComponent } from "./demosetuser/demosetuser.component";
import { BrowserWarningComponent } from './browser-warning/browser-warning.component';
import { UpgardelicenseComponent } from './upgardelicense/upgardelicense.component';


// ...

// Define your routes
let routes: Routes = [
  //changes - shreyas.s
  { path: "qrlogin", component: QRloginComponent },
  { path: "u&plogin", component: UserpassloginComponent },
  { path: "pass-recovery", component: PassrecoveryComponent },
  { path: "reset-password/:token", component: ResetPasswordComponent },
  { path: "av-chat", component: EtherpadkmsComponent },
  { path: "sketchboard", component: FirstscreenComponent },
  // { path: 'whiteboard', component: WhiteboardpageComponent },
  { path: "superadmin", component: SuperadminComponent },
  { path: "regionaladminedit/:id", component: RegionaladmineditComponent },
  { path: "ppdslogin", component: PhoneuiloginComponent },
  { path: "voting", component: ClientVotingViewComponent },
  { path: "joinclassroom", component: StudentavchatComponent },
  { path: "calciforandapp", component: CalculatorComponent},
  { path: "rulerforandapp", component: WhiteboardpageComponent,data:{customData:'ruler'}},
  { path: "protractorforandapp", component: WhiteboardpageComponent,data:{customData:'protractor'}},
  { path: "compassforandapp", component: WhiteboardpageComponent,data:{customData:'compass'}},
  { path: "setsquareforandapp", component: WhiteboardpageComponent,data:{customData:'setsquare'}},
  { path: "avchatforandapp", component: WhiteboardpageComponent,data:{customData:'avchat'}},
  { path: "votingforandapp", component: WhiteboardpageComponent,data:{customData:'voting'}},
  { path: "voteforandapp", component: VotingSectionComponent},
  { path: "3dobject", component: WhiteboardpageComponent,data:{customData:'obj3d'}},
  { path:'demoemail' ,component: DemoemailComponent},
  { path:'demosetuser/:token' ,component: DemoSetuserComponent},
  { path: 'browser-warning', component: BrowserWarningComponent },
  { path: 'loginupgradedlicenseaccount', component: UpgardelicenseComponent  },


  //changes start - shreyas.s
  {
    path: "whiteboard",
    children: [
      {
        path: "signin",
        component: SigninComponent,
      },
      {
        path: "signin-callback",
        component: SigninCallbackComponent,
      },
      {
        path: "signout-callback",
        component: SignoutCallbackComponent,
      },
      {
        path: "",
        component: WhiteboardpageComponent,
      },
    ],
  },
  //changes end - shreyas.s
  {
    path: "**",
    redirectTo: "qrlogin",
  },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  onSameUrlNavigation: "reload",
  scrollPositionRestoration: "enabled",
  // ...any other options you'd like to use
};


@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class RouteModule {}

export const RoutingComponents = [
  FirstscreenComponent,
  QRloginComponent,
  EtherpadkmsComponent,
  UserpassloginComponent,
  PassrecoveryComponent,
  ResetPasswordComponent,
  WhiteboardpageComponent,
  ClientVotingViewComponent,
  UpgardelicenseComponent
];
