import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FileService } from '../file.service';

@Component({
  selector: 'app-client-voting-view',
  templateUrl: './client-voting-view.component.html',
  styleUrls: ['./client-voting-view.component.scss']
})
export class ClientVotingViewComponent implements OnInit {



  name:string=""
  showoptionview:Boolean=false;
  shownameform:Boolean=true;
  showsuccessful:Boolean=false;
   numberOfOptions:number=0
   multiselect:Boolean=false
   anonymousInput:Boolean=false
   options: any[] = [];
   uniquevoteid:any
   selectedOptions: string[] = [];
   showType2:Boolean=false;
   Isvotingexpired:Boolean=false;

  constructor(private route: ActivatedRoute,private voteurl:FileService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      // Access individual query parameters
      this. numberOfOptions = +params['options'];
      this. multiselect = JSON.parse(params['Multiselect']);
      this. anonymousInput = JSON.parse(params['AnonymousInput']);
      //this.uniquevoteid= JSON.parse(params['uniqueVoteId']);;
      this.uniquevoteid=params['uniqueVoteId'];

      // Now, you can use these values in your component
      console.log('NumberOfOptions:', this.numberOfOptions);
      console.log('Multiselect:', this.multiselect);
      console.log('AnonymousInput:', this.anonymousInput);
       console.log("umiqueid", this.uniquevoteid)

       if(this.anonymousInput===true){
        this.shownameform=false;
        this.showoptionview=true;

       }


    });

    for(let i=0;i<this.numberOfOptions;i++){


     let str="Option"+" "+(i+1);
     let json={"label":str,"value":(i+1)}
    // this.options.push(str);
    this.options.push(json);
    }
console.log(this.uniquevoteid,"pppp")
    // this.voteurl.handleWebSocketEvent("AnswerRecieved",(data)=>{
    //   console.log("After submit in client view",data)
    //   console.log(this.uniquevoteid,"kkk")
    //   this.voteurl.emitWebSocketEvent("GetTotalvotebyid",{data:this.uniquevoteid});
    //  })
    //  this.voteurl.handlevotesSocket("AnswerRecieved").subscribe((val:any)=>{
    //   console.log(val,"value")
    //   this.voteurl.joinToClass();
    //   this.voteurl.emitWebSocketEvent("GetTotalvotebyid",{data:this.uniquevoteid});
    //  })

     this.voteurl.handlevotesSocketAnswerRecieved().subscribe((val:any)=>{
      console.log(val,"value")
      this.voteurl.joinToClass();
      //this.voteurl.emitWebSocketEvent("GetTotalvotebyid",{data:this.uniquevoteid});
     })
    //  this.voteurl.handleWebSocketEvent("TotalvotesRecievedbyid",(data)=>{
    //   console.log("VotesRecivedbyid",data)
    //   this.voteurl.setSharedData(data);
    //   this.voteurl.sendData(data);

    //   // this.totalvotes=data.data
    //   // console.log(this.totalvotes,"TotalVotes")
    //  })
    //  this.voteurl.emitWebSocketEvent("newVote",{data:"votte"})
    //  this.voteurl.handleWebSocketEvent("okrecieved",(data)=>{
    //   console.log(data)
    //  })
    //  console.log("Subscribed to listener");
    //  this.voteurl.handlevotesSocket("AnswerRecieved").subscribe((val: any) => {
    //   console.log(val,"lllll")

    // });
    this.checkvalidation(this.uniquevoteid)
  }


  checkvalidation(id:any){
    console.log("hello",id);
    this.voteurl.getoptionsdata(id).subscribe(result => {
     console.log(result,"result")


      console.log(result.data.isValid,"checking is valid")
      if(result.data.isValid===true){
        this.Isvotingexpired=false
      }else{
        this.Isvotingexpired=true;
      }

    }, error => {
      console.log(error);
    });
  }

  onButtonClick(){
    console.log(this.name)
    this.showoptionview=true;
  this.shownameform=false;
  }


  toggleOption(option: string) {
    console.log(this.multiselect===true)
   if(this.multiselect){
     console.log("llllllllloooooooo")
     let index=this.selectedOptions.findIndex(p=>p===option)
     if(index>-1){
       this.selectedOptions.splice(index,1);
     }else{
     this.selectedOptions.push(option)
    this.logSelectedOptions();
     }
   }else{
     console.log("llllllllloooooooopppp")
   let index=this.selectedOptions.findIndex(p=>p===option)
   if(index>-1){
     this.selectedOptions=[];
   }else{
  this.selectedOptions=[];
   this.selectedOptions.push(option)
  this.logSelectedOptions();
   }
 }
 }

 logSelectedOptions() {
   console.log('Selected options:', this.selectedOptions);
 }

 getSelectedOptions() {
   this.logSelectedOptions();

 }

 onSubmit(){
  this.checkvalidation(this.uniquevoteid);
  this. showsuccessful=true;
  this.showoptionview=false;
  let jsondata={"name":this.name,"options":this.selectedOptions,"UniqueVoteId":this.uniquevoteid};

   this.voteurl.joinToClass();
   if(this.Isvotingexpired===false){
  this.voteurl.emitWebSocketEvent("AnswerSubmit",{data:jsondata});
   }

  // this.voteurl.handleWebSocketEvent("AnswerRecieved",(data)=>{
  //  console.log("After submit in client component",data)
  //  this.voteurl.emitWebSocketEvent("GetTotalvotebyid",{data:this.uniquevoteid});
  // //  this.voteurl.handleWebSocketEvent("TotalvotesRecievedbyid",(data)=>{
  // //   console.log("VotesRecivedbyid",data)
  // //   // this.totalvotes=data.data
  // //   // console.log(this.totalvotes,"TotalVotes")
  // //  })
  // })


 }

 clearInput(){
  this.name=""
 }

}
