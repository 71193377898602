import { Component, OnInit ,ViewChild ,TemplateRef, Input ,ViewChildren, QueryList} from '@angular/core';
import { FileService } from '../file.service';
import { AbstractControl, FormGroup, FormsModule, FormControl } from '@angular/forms';
import { FormArray, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
// import { HelperService } from '../utils/helper.service';
// import { TranslateService } from '@ngx-translate/core';
import { Router ,ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RegionaladminComponent } from '../regionaladmin/regionaladmin.component';

@Component({
    selector: 'app-regionaladminedit',
    templateUrl: './regionaladminedit.component.html',
    styleUrls: ['./regionaladminedit.component.css']
})
export class RegionaladmineditComponent implements OnInit {
  // public save_exit_ref: BsModalRef;
    public position = "relative";
    public userTypes;
    public sites;
    public gweds;
    public workshopNames;
    public data: any = {} ;
    public error: any = {} ;
    public registerStatus;
    public registerStatusMessage;
    public showRegisterStatus =  false;
    public countries;
    public regionname = ['India', 'China','North America','EMEA', 'APAC'];
    public editId: string;
    public page_mode = 'newrecord';
    saveClicked: boolean;
    // public regionaladmincomp: RegionaladminComponent

    @Input() isOpen: any;
    
    constructor(public auth: FileService,
                public formBuilder: FormBuilder,
                // public helper: HelperService,
                // public translate: TranslateService,
                public regionaladmincomp:RegionaladminComponent,
                public router:Router,
                private _Activatedroute: ActivatedRoute,
                ) {
        // helper.languageChanged$.subscribe(
        //     language => {
        //         translate.use(language);
        //     });
    }

    ngOnInit() {
      console.log(this.isOpen,"opennn")
      this.openerasemodal()
        // this.helper.validateSuperAdminUserForPage('superadminuser-management');
        this.editId = this._Activatedroute.snapshot.params['id'];
        if (this.editId == 'newentry') {
            this.page_mode = "newrecord";
            console.log('this.editId', this.editId);
        } else {
            this.page_mode = "editrecord";
        }
        this.showRegisterStatus = false;
        this.registerStatus = "";
        this.registerStatusMessage = "";
        // this.collectUserTypes();
        this.data.region = "region"
        if(this.editId != 'newentry'){
        this.regionaladminEditClick()
        }else{
          // this.popup()
        }
    }
    openerasemodal(){
      this.isOpen = true
      // this.save_exit_ref = this.modalService.show(this.modalContent, Object.assign({}, { class: "modal-md-lg-sm" }));
    }
    closeModal() {
      this.isOpen = false;
      this.regionaladmincomp.closeModal()
    }
    // closeModal() {
    //   this.bsModalRef.hide();
    // }
    regionaladminEditClick() {
        console.log("Calling WOEditClick HTTP server")
        this.auth.getTVInformation({ 'id': this.editId }, '/getregionaladmin').then((res: any) => {
          try {
            console.log(res, "edit ress")
            if (res) {
              if (res.status === true) {
                this.data = res.data;
                this.data['firstname'] = res.data['firstname'];
                this.data['lastname'] = res.data['lastname'];
                this.data['emailid'] = res.data['emailid'];
                this.data['address'] = res.data['address'];
    
                console.log("Selected resData2: ", this.data);
              }
            } else {
              this.data = {};
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err: any) => {
          console.error(err);
        })
    
      }

    validateRegisterUser(){
        var errors_found = false;
        this.error = {};
        this.error.firstnameNotValid = false;
        // this.error.passwordNotValid = false;
        // this.error.confirmpasswordNotValid = false;
        this.error.regionnameNotValid = false;
        this.error.emailidNotValid = false;
        this.error.lastnameNotValid = false;
        this.error.addressNotValid = false;
       

        this.data.firstname = this.data.firstname ? this.data.firstname.trim() : "";
        this.data.lastname = this.data.lastname ? this.data.lastname.trim() : "";
        this.data.address = this.data.address ? this.data.address.trim() : "";
        // this.data.password = this.data.password ? this.data.password.trim() : "";
        // this.data.confirmpassword = this.data.confirmpassword ? this.data.confirmpassword.trim() : "";
        this.data.regionname = this.data.regionname ? this.data.regionname.trim() : "";
        this.data.emailid = this.data.emailid ? this.data.emailid.trim() : "";
        


        if (this.data.firstname.length == 0) {
            this.error.firstnameNotValid = true;
            this.error.firstnameNotValidText = "Please enter the firstname";
            errors_found = true;
        }
        if (this.data.lastname.length == 0) {
            this.error.lastnameNotValid = true;
            this.error.lastnameNotValidText = "Please enter the lastname";
            errors_found = true;
        }

        // if (this.data.password.length == 0) {
        //     this.error.passwordNotValid = true;
        //     this.error.passwordNotValidText = "Please enter the password";
        //     errors_found = true;
        // }

        // if (this.data.confirmpassword.length == 0) {
        //     this.error.confirmpasswordNotValid = true;
        //     this.error.confirmpasswordNotValidText = "Please enter the confirm password";
        //     errors_found = true;
        // }

        // if(this.data.password != this.data.confirmpassword){
        //     this.error.confirmpasswordNotValid = true;
        //     this.error.confirmpasswordNotValidText = "Password and confirm password does not match";
        //     errors_found = true;
        // }

        if (this.data.regionname.length == 0) {
            this.error.regionnameNotValid = true;
            this.error.regionnameNotValidText = "Please select the regionname";
            errors_found = true;
        }


        if (errors_found) {
            return;
        } else {
            //Strong password checking

            // let password = this.data.password;
            let passlen = false;
            let hasLower = false;
            let hasUpper = false;
            let hasNum = false;
            let hasSpecial = false;
            var missing_mandatory_fields = [];

            // if (password.length >= 8) {
            //     passlen = true;
            // } else {
            //     missing_mandatory_fields.push("Must be at least 8 characters long")
            // }

            // const lowercaseRegex = new RegExp("(?=.*[a-z])");// has at least one lower case letter
            // if (lowercaseRegex.test(password)) {
            //     hasLower = true;
            // } else {
            //     missing_mandatory_fields.push("Must include at least one lowercase letter (a-z)")
            // }

            // const uppercaseRegex = new RegExp("(?=.*[A-Z])"); //has at least one upper case letter
            // if (uppercaseRegex.test(password)) {
            //     hasUpper = true;
            // } else {
            //     missing_mandatory_fields.push("Must include at least one uppercase letter (A-Z)")
            // }

            // const numRegex = new RegExp("(?=.*\\d)"); // has at least one number
            // if (numRegex.test(password)) {
            //     hasNum = true;
            // } else {
            //     missing_mandatory_fields.push("Must include at least one number")
            // }

            // const specialcharRegex = new RegExp("[!@#$%^&*(),.?\":{}|<>]");
            // if (specialcharRegex.test(password)) {
            //     hasSpecial = true;
            // } else {
            //     missing_mandatory_fields.push("Must include at least one non-alphanumeric charecter (@!#$%^&*()_-+={}[]|)")
            // }

            // let counter = 0;
            // let checks = [passlen, hasLower, hasUpper, hasNum, hasSpecial];
            // console.log(checks)
            // for (let i = 0; i < checks.length; i++) {
            //     if (checks[i]) {
            //         counter += 1;
            //     }
            // }

            // if (counter < 5) {
            //     // return { invalidPassword: true }
            //     var message = "Please Check Password:";
            //     message += "\n       " + missing_mandatory_fields.join("\n       ");
            //     alert(message);
            //     return;
            // } else {
                var self = this;
                self.checkfirstname(self, self.data)
                    .then((data) => self.saveRegisterUserDetails(self, data))
                    .catch((data) => console.log(" Exception during handling promise: ", data));
            // }
        }
    }

 

    checkfirstname(self, data) {
        // data.self = this;
        return new Promise(
            (resolve, reject) => {
                self.auth
                    .getTVInformation({'firstname': data.firstname}, '/checkuser')
                    .then(
                        (res: any) => {
                            if (res.body.status === "ok") {
                                resolve(data);
                            } else {
                                console.log("Checking firstname: ", data.firstname,
                                            " response: ", res);
                                self.registerStatus = 'fail';
                                self.registerStatusMessage = res.body.message;
                                self.showRegisterStatus =  true;
                                reject(data);
                            }
                        }, (err: any) => {
                            console.log("Error checking firstname: ", data.firstname,
                                        " response: ", err);
                            reject(data);
                        });
            });
    }

  
    saveRegisterUserDetails(self, data){
        return new Promise(
            (resolve, reject) => {
                self.auth
                    .getTVInformation(
                        {
                            firstname: data.firstname,
                            password: data.password,
                            regionname: data.regionname,
                            emailid: data.emailid,
                            lastname: data.lastname,
                            address: data.address
                            // superadmin_id : localStorage.getItem('superadmin_id'),
                        }
                        ,'/createregionaladmin')
                    .then((res: any) => {
                        try {
                            console.log("Status: ", res);
                            self.registerStatus = res.body.status;
                            self.registerStatusMessage = res.body.reason;
                            self.showRegisterStatus =  true;
                            resolve(data);
                            // this.router.navigate(['/superadmin'])
                        } catch (e) {
                            console.error(e);
                            reject(data);
                        }
                    }, (err: any) => {
                        console.error(err);
                        reject(data);
                    })
            });
    }


    validateSaveRegionaladminData() {
        console.log("validateSaveKMSData:", this.data);
        var errors_found = false;
        this.error.remarkNotValid = false;
        if (this.data['firstname'].length == 0) {
          this.error.remarkNotValid = true;
          this.error.remarkValidText = "Please enter the firstname";
          errors_found = true;
        }
    
        if (errors_found) {
          this.saveClicked = false;
          return;
        } else {
          this.saveClicked = true;
          this.saveBPDetails();
        }
      }
      saveBPDetails() {
        console.log("saveBPDetails Data : ", this.data);
        this.auth.getTVInformation({"data":this.data}, '/editregionaladmin').then((res: any) => {
          try {
            console.log("Status: ", res);
            if (res.status == true) {
              this.router.navigate(['/regionaladmin'])
            } else {
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err: any) => {
          console.error(err);
        })
      }

    Exit() {
        if (this.editId == 'newentry') {
            this.router.navigate(['/superadmin'])
        } else {
            this.router.navigate(['/regionaladmin'])
        }
    }

    
}
