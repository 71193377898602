import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
  Injectable,
  AfterViewInit,
  ElementRef,
  Output,
  Input,
  EventEmitter,
  HostListener,
  NgZone,
  SimpleChanges
} from "@angular/core";
//import { AuthService } from "../auth.service";
import {
  AbstractControl,
  FormGroup,
  FormsModule,
  FormControl,
} from "@angular/forms";
import {
  FormArray,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
//import { HelperService } from "../utils/helper.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
//import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import * as kurentoUtils from "../../assets/bower_components/kurento-utils/js/kurento-utils.js";
import { environment } from '../../environments/environment';
import { FileService } from '../file.service';
//import { LocalizationService } from './../internationalization/localization.service';

// const handpose = require('@tensorflow-models/handpose');
// require('@tensorflow/tfjs-backend-webgl');

declare global {
  interface Window {
      RTCPeerConnection: RTCPeerConnection;
      mozRTCPeerConnection: RTCPeerConnection;
      webkitRTCPeerConnection: RTCPeerConnection;
  }
}

// declare var DeepAR:any;
@Injectable({
  providedIn: "root",
})
@Component({
  selector: 'app-teacherkms',
  templateUrl: './teacherkms.component.html',
  styleUrls: ['./teacherkms.component.scss']
})
export class TeacherkmsComponent implements OnInit {
  // Message:string[] = [];
  // NAme:string[] = [];
  Name!: string;
  Room!: string;
  inputTouched = false;
  loading = false;
  public position = "relative";
  public data: any = {};
  public etherpad = {};
  public error: any = {};
  public userName = "";
  public userType = "";
  public userImage = "";
  public id = "";
  public roomId;
  public userId;
  public roomMembers;
  public ws = null;
  public titleName = "Untitled";
  public audio = null;
  public selfVideo = null;
  public selfVideoStream = null;
  public screenShareStream = null;
  // public canvas=null;
  // public deepAR=null;
  public handRiseInterval = null;
  public speakerTalkingStream = null;
  public otherVideo = [];
  public remoteUser = [];
  public otherMembers = [];
  public webRtcPeerAudio = null;
  public webRtcPeerSelfVideo = null;
  public webRtcPeerScreenShare = null;
  public webRtcPeerOtherVideo = [];
  public isShowVideo = false;
  public isVideo = false;
  public isListPaticipants = false;
  public isPaticipants = false;
  public isShowChat = false;
  public isChat = false;
  public lock = false;
  public muteAV = false;
  public lock1 = false;
  public startClass = false;
  public selectClass = false;
  public directClass = 'false';
  public classes = [];
  public m1 = false;
  public muteAV1 = false;
  public accessToken;
  public videoCapable;
  publicLessons = [];
  privateLessons = [];
  publicSelect = true;
  public teacherId;
  public screenShare = null;
  privateSelect = true;
  public userID;
  public padId;
  public classMode;
  public createClass = false;
  public currPageNo = 1;
  public modes = [
      {
          "key": "collab",
          "value": "Collaborative Whiteboard"
      },
      {
          "key": "present",
          "value": "Presentation"
      }]
  public group = {
      color: "white",
      backgroundColor: "#2a72db",
  };
  public group1 = {
      color: "black",
      backgroundColor: "white",
  };

  public full = false;
  public v1 = false;
  classesName: any;
  @Input('handRaisedStudentDetails') public handRaisedStudentDetails;
  @Input('setZindex') public setZindex;
  @Output() public close = new EventEmitter();
  @Output() setPostionFixed: EventEmitter<string> = new EventEmitter();
  @Output() sharedScreenFromAd: EventEmitter<string> = new EventEmitter();
  @Output() classcreated: EventEmitter<string> = new EventEmitter();
  @Output() isAvchatStart = new EventEmitter();
  @Output() sharestop = new EventEmitter();
  yesstarted: boolean = true;
  isMobileDevice: boolean = false;
  isHandRaisedAlready: boolean = false;
  isSpeakerSpeaking: boolean = false;
  isHandRaisedAndSpeakingAlready: boolean = false;
  container: HTMLElement;
  textmessage: string = '';
  callsendroomid: boolean;
  Handraise: boolean = false;
  handraise_isteacher: boolean = false;
  handraise_array: boolean[] = [false, false, false, false, false, false, false, false, false, false];
  // ishandraisefullscreen: boolean[] = [false,false,false,false];
  handraise_name_array: string[] = [];
  clearShareScreen: any;
  ipAddress: any;
  ctx: any;
  isScreenShare: boolean = false;
  videoStreamForSharescreen: any;
  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);
  localIp: string;
  isSharedScreen: boolean = true;
  mediaStreamForStopSharing: any;
  isDisabled: boolean = false;
  isSetIndex: boolean = false;
  constructor(
    public auth: FileService,
      public formBuilder: FormBuilder,
      //public helper: HelperService,
      private _Activatedroute: ActivatedRoute,
      private modalService: BsModalService,
      //public translate: TranslateService,
      private http: HttpClient,
      public router: Router,
      // public localizationService: LocalizationService, public ts: TranslateService,
      private zone: NgZone
  ) {
      //handraise feature
      // if (localStorage.getItem('type') == 'teacher') {
      //     this.handraise_isteacher = true;
      //     if (1) {
      //         this.auth.listen_to_handraise().subscribe((res: any) => {
      //             console.log("handraise_testing1", res);
      //             var i = 0;
      //             var length = this.roomMembers.length;
      //             for (i = 0; i < length; i++) {
      //                 if (this.roomMembers[i].name == res.username) {
      //                     this.handraise_array[i] = res.ishandraised;
      //                     console.log("handraise_testing2", i);
      //                     break;
      //                 }
      //             }
      //             length = this.handraise_name_array.length;
      //             // for(i = 0;i<length; i++){
      //             //     if(res.username == this.handraise_name_array[i]){
      //             //         this.ishandraisefullscreen[i] = res.ishandraised;
      //             //         break;
      //             //     }
      //             // }
      //         });
      //         this.auth.sub_to_handraise_once_in_ekms = !this.auth.sub_to_handraise_once_in_ekms;
      //     }
      // }
      //text-chat feature
      this.callsendroomid = true;
      if (this.auth.callchatsocket) {
          console.log("In the constructor in kms");
          this.auth.socket.on("message-array", (data: any, Data: any) => {
              console.log("message from constructor", data);
              console.log("name from constructor", Data);
              this.auth.Message = data;
              this.auth.NAme = Data;
          });
          // helper.languageChanged$.subscribe((language) => {
          //     translate.use(language);
          // });
          this.auth.Listen().subscribe((res: any) => {
              console.log("In Listen", res);
              this.auth.Message.push(res.message);
              this.auth.NAme.push(res.name);
              console.log('Venki_message', this.auth.Message);
          });
          this.auth.callchatsocket = !this.auth.callchatsocket;
      }

      this.auth.stpsharingObs.subscribe((res) => {
          if (res.type == 'stopSharing' && res.val == true) {
              this.stopSharing()
          }
      });
  }

  initialState: any;
  // @ViewChild('fullscreenmode') fullscreenmode: TemplateRef<any>;
  ngAfterViewInit() { }
  ngOnInit() {
      this.directClass = "false"
      this.padId ="7KVUR57W7B6Z" //this._Activatedroute.snapshot.params['type'];
      this.id = "63314ea7ec9280058488eaf8"//this._Activatedroute.snapshot.params["id"];
      this.userID = localStorage.getItem('userId');//teacher.english.ab@tpv-tech.com
      this.userType = localStorage.getItem("type");


      console.log("bbbbb",this.directClass,this.padId,this.id,this.userID, this.userType)
      //this.getPads();
      this.video();

      // this.audio = document.getElementById("audioConference");
      // this.optionsAudio.remoteVideo = this.audio;
      // if (this.userType == "teacher") {
      //     console.log("teacher");
      //     this.selfVideo = document.getElementById("teacherVideo");
      //     this.optionsSelfVideo.localVideo = this.selfVideo;
      //     this.otherVideo[0] = document.getElementById("otherVideo0");
      //     this.optionsOtherVideo[0].remoteVideo = this.otherVideo[0];
      //     this.remoteUser[0] = null;
      // } else if (this.userType == "student") {
      //     console.log("student");
      //     this.selfVideo = document.getElementById("otherVideo0");
      //     this.optionsSelfVideo.localVideo = this.selfVideo;
      //     this.otherVideo[0] = document.getElementById("teacherVideo");
      //     this.optionsOtherVideo[0].remoteVideo = this.otherVideo[0];
      //     this.remoteUser[0] = "teacher";
      // }
      // // this.selfVideo = document.getElementById("teacherVideo");

      // // this.otherVideo[0] = document.getElementById("otherVideo0");
      // // this.optionsOtherVideo[0].remoteVideo = this.otherVideo[0];
      // this.otherVideo[1] = document.getElementById("otherVideo1");
      // this.optionsOtherVideo[1].remoteVideo = this.otherVideo[1];
      // this.otherVideo[2] = document.getElementById("otherVideo2");
      // this.optionsOtherVideo[2].remoteVideo = this.otherVideo[2];
      // this.otherVideo[3] = document.getElementById("otherVideo3");
      // this.optionsOtherVideo[3].remoteVideo = this.otherVideo[3];
      // this.remoteUser[1] = null;
      // this.remoteUser[2] = null;
      // this.remoteUser[3] = null;
      this.userName = localStorage.getItem("username");
      this.accessToken = localStorage.getItem("accessToken");
      console.log("aaaaa", this.directClass, this.padId, this.id ,this.userName);
      console.log("ccccc",this.accessToken)
      this.initialState = { directClass: this.directClass, padId: this.padId, id: this.id, userName: this.userName, accessToken: this.accessToken };
      if (this.directClass != 'false') {
          var myArray = this.directClass.split("?");
          console.log("inside 1 aaaaa____", myArray.length);

          this.directClass = atob(myArray[0]);
          if (myArray.length > 1) {
              this.classMode = atob(myArray[1]);
          } else {
              this.classMode = localStorage.getItem('classModeInfo')
              console.log("classmode",this.classMode)
          }
          alert(this.classMode)
          console.log("inside aaaaa", this.directClass, this.classMode);
          this.startAvClass(this.directClass);
      } else {
          // this.getClasses();
      }
      // this.ws = new WebSocket(
      //     `${environment.avChatUrl}` +
      //     "?accessToken=" +
      //     btoa(this.accessToken)
      // );
      // this.ws.onopen = () => {
      //     console.log("websocket connection opened");
      // };
      // this.ws.onerror = (err) => {
      //     console.log("websocket connection error", err);
      // };
      // this.ws.onclose = () => {
      //     console.log("__________connection closed________");
      //     this.leaveRoom();
      // };
      // this.ws.onmessage = this.wsMessageHandling;
      this.userImage = this.userName.charAt(0);
      // this.helper.validateUserForPage('etherpad');

      this.auth.getShareScreenActions().subscribe((val) => {
          // alert(val)
          // console.log('val.message.', val.message)
          if (val.message.screenshare && this.userType == 'student') {
              this.screenShareFun(val.message.screenshare);
          } else if (!val.message.screenshare) {
              if (this.webRtcPeerScreenShare) {
                  this.isSharedScreen = true
                  this.webRtcPeerScreenShare.dispose();
                  this.webRtcPeerScreenShare = null;
              }
              this.isSharedScreen = true
          }
      });

      let details = navigator.userAgent
      let regexp = /android|iphone|kindle|ipad/i;
      this.isMobileDevice = regexp.test(details);

      if (this.userType == 'teacher') {
          this.determineLocalIp()
      }

      this.auth.getNoification().subscribe((res: any) => {
          if (res && res.message && res.message.type == 'stopSharing' && this.userType != 'teacher' && res.message.padID == this.padId) {
              this.stopSharing()
          }
      })

  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes && changes.setZindex) {
          this.isSetIndex = changes.setZindex.currentValue
      }
  }

  getIPAddress() {
      this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
          this.ipAddress = res.ip;
      });
  }

  private determineLocalIp() {
      this.getRTCPeerConnection();
      const pc: any = new RTCPeerConnection({ iceServers: [] });
      pc.createDataChannel('');
      pc.createOffer().then(pc.setLocalDescription.bind(pc));

      pc.IceCandidateFound = (ice) => {
          this.zone.run(() => {
              if (!ice || !ice.candidate || !ice.candidate.candidate) {
                  return;
              }

              this.localIp = this.ipRegex.exec(ice.candidate.candidate)[1];

              console.log('OOOOOOOOOOOOOOOOOOOOOOOOOOOOOO', this.localIp)
              // sessionStorage.setItem('LOCAL_IP', this.localIp);

              pc.IceCandidateFound = () => { };
              pc.close();
          });
      };
  }

  private getRTCPeerConnection() {
      return (
          window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
      );
  }

  screenHeight: any;
  screenWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
      //    this.full=false;
      this.screenHeight = window.innerHeight.toString();
      this.screenWidth = window.innerWidth.toString();
      console.log("deviceSize", this.screenHeight, this.screenWidth, typeof this.screenHeight);
      if (this.screenHeight == "480" && this.screenWidth == "640") {
          //   this.showButton=true;
      }
      else {
          // this.showButton=false;

      }
      if (document.fullscreenElement) {
          ;
          console.log("deviceSize", this.full)
      }

      else {
          this.full = false;
          console.log("deviceSize", this.full,)
      }

  }
  async startAvClass(directClass) {
    console.log("directclassssss",directClass)
      this.directClass = directClass;
      this.audio = document.getElementById("audioConference");
      this.optionsAudio.remoteVideo = this.audio;
      if (this.userType == "teacher") {
          console.log("teacher");
          this.selfVideo = document.getElementById("teacherVideo");
          this.optionsSelfVideo.localVideo = this.selfVideo;

          this.screenShare = document.getElementById("screenShare");
          this.optionsScreenShare.localVideo = this.screenShare;
          // this.deepAR.stopVideo();
          // let canvas= document.getElementById("deepar-canvas");
          // let video0= document.getElementById("otherVideo0");
          // canvas.style.display="none";
          // video0.style.display="block";
          // let enableFilters=document.getElementById("enableFilters");
          // enableFilters.style.display="none"
          this.otherVideo[0] = document.getElementById("otherVideo0");
          this.optionsOtherVideo[0].remoteVideo = this.otherVideo[0];
          this.remoteUser[0] = null;
      } else if (this.userType == "student") {
          console.log("student");
          // this.canvas=<HTMLCanvasElement> document.getElementById("deepar-canvas")
          // this.optionsSelfVideo.videoStream=this.canvas.captureStream();
          this.selfVideo = document.getElementById("otherVideo0");
          this.optionsSelfVideo.localVideo = this.selfVideo;

          this.screenShare = document.getElementById("screenShare");
          this.optionsScreenShare.remoteVideo = this.screenShare;
          this.otherVideo[0] = document.getElementById("teacherVideo");
          this.optionsOtherVideo[0].remoteVideo = this.otherVideo[0];
          this.remoteUser[0] = "teacher";
      }
      // this.selfVideo = document.getElementById("teacherVideo");

      // this.otherVideo[0] = document.getElementById("otherVideo0");
      // this.optionsOtherVideo[0].remoteVideo = this.otherVideo[0];
      this.otherVideo[1] = document.getElementById("otherVideo1");
      this.optionsOtherVideo[1].remoteVideo = this.otherVideo[1];
      this.otherVideo[2] = document.getElementById("otherVideo2");
      this.optionsOtherVideo[2].remoteVideo = this.otherVideo[2];
      this.otherVideo[3] = document.getElementById("otherVideo3");
      this.optionsOtherVideo[3].remoteVideo = this.otherVideo[3];
      this.remoteUser[1] = null;
      this.remoteUser[2] = null;
      this.remoteUser[3] = null;
      this.ws = new WebSocket(
          `${environment.avChatUrl}` +
          "?accessToken=" +
          btoa(this.accessToken)
      );
      this.ws.onopen = () => {
          this.JoinClass();
          console.log("websocket connection opened");
      };
      this.ws.onerror = (err) => {
          console.log("websocket connection error", err);
      };
      this.ws.onclose = () => {
          console.log("__________connection closed________");
          this.leaveRoom();
      };
      this.ws.onmessage = this.wsMessageHandling;
  }
  getFreeSlotIndex() {
      return this.remoteUser.findIndex((val) => {
          return val === null;
      });
  }
  getIndexOfRemoteUser(remoteUserId) {
      return this.remoteUser.findIndex((val) => {
          return val === remoteUserId;
      });
  }
  startRemoteVideo(remoteUserId, freeSlot) {
      this.remoteUser[freeSlot] = remoteUserId;
      let msgs = {
          accessToken: this.accessToken,
          type: "EnableVideoRemote",
          roomId: this.roomId,
          userId: this.userId,
          remoteUserId,
      };
      console.log(msgs);
      if (msgs.remoteUserId != null) {
          console.log(
              "New LOGS________freeslot and __________remoteUserId________________",
              freeSlot,
              msgs
          );
          this.ws.send(JSON.stringify(msgs));
          this.createLocalWebrtcEndpointOtherVideo(freeSlot);
      }
  }
  disableRemoteVideo(remoteUserId) {
      let msgs = {
          accessToken: this.accessToken,
          type: "DisableVideoRemote",
          roomId: this.roomId,
          userId: this.userId,
          remoteUserId: remoteUserId,
      };
      console.log(msgs);
      this.ws.send(JSON.stringify(msgs));
  }
  enableRemoteVideo(remoteUserId, role) {
      console.log(role);

      if (role == "teacher") {
          var freeSlot = 0;
          this.startRemoteVideo(remoteUserId, freeSlot);
          if (
              this.webRtcPeerOtherVideo[freeSlot] &&
              this.webRtcPeerOtherVideo[freeSlot].pc
          ) {
              this.webRtcPeerOtherVideo[
                  freeSlot
              ].pc.oniceconnectionstatechange = (event) => {
                  if (
                      event === "failed" ||
                      event === "disconnected" ||
                      event === "closeed"
                  ) {
                      // TODO  Failured case
                      this.webRtcPeerOtherVideo[freeSlot].dispose();
                      this.disableRemoteVideo(this.remoteUser[0]);
                      this.webRtcPeerOtherVideo[freeSlot] = null;
                      this.remoteUser[0] = "teacher";
                  } else if (event === "completed") {
                      // TODO Successful
                  }
              };
          }
      } else if (role == "student") {
          var freeSlot = this.getFreeSlotIndex();
          if (freeSlot === -1) {
              return;
          }
          this.startRemoteVideo(remoteUserId, freeSlot);
      }
  }
  closeVirtual() {
    console.log("close class")
      this.close.emit(true);
  }

  kmsIceCandidateFoundAudio = function (candidate) {
      this.IceCandidateFound(candidate, "IceAudioClient");
  }.bind(this);
  kmsIceCandidateFoundSelfVideo = function (candidate) {
      this.IceCandidateFound(candidate, "IceVideoSelfClient");
  }.bind(this);
  kmsIceCandidateFoundScreenShare = function (candidate) {
      this.IceCandidateFound(candidate, "IceScreenShareClient");
  }.bind(this);
  kmsIceCandidateFoundOtherVideo0 = function (candidate) {
      this.IceCandidateFound(candidate, "IceVideoRemoteClient", 0);
  }.bind(this);
  kmsIceCandidateFoundOtherVideo1 = function (candidate) {
      this.IceCandidateFound(candidate, "IceVideoRemoteClient", 1);
  }.bind(this);
  kmsIceCandidateFoundOtherVideo2 = function (candidate) {
      this.IceCandidateFound(candidate, "IceVideoRemoteClient", 2);
  }.bind(this);
  kmsIceCandidateFoundOtherVideo3 = function (candidate) {
      this.IceCandidateFound(candidate, "IceVideoRemoteClient", 3);
  }.bind(this);

  public constraintsAudio = {
      audio: true,
      video: false,
  };
  public constraintsOtherVideo = {
      audio: false,
      video: true,
  };
  public constraintsSelfVideo = {
      audio: false,
      video: true,
  };
  public constraintsScreenShare = {
      audio: false,
      video: true,
  };
  public optionsAudio = {
      localVideo: undefined,
      remoteVideo: this.audio,
      IceCandidateFound: this.kmsIceCandidateFoundAudio,
      mediaConstraints: this.constraintsAudio,
  };
  public optionsSelfVideo = {
      localVideo: this.selfVideo,
      remoteVideo: undefined,
      videoStream: this.selfVideoStream,
      IceCandidateFound: this.kmsIceCandidateFoundSelfVideo,
      mediaConstraints: this.constraintsSelfVideo,
  };
  public optionsScreenShare = {
      localVideo: this.userType == 'teacher' ? this.screenShare : undefined,
      remoteVideo: this.userType == 'student' ? this.screenShare : undefined,
      videoStream: undefined,
      IceCandidateFound: this.kmsIceCandidateFoundScreenShare,
      mediaConstraints: this.constraintsScreenShare,
  };
  public optionsOtherVideo = [
      {
          localVideo: undefined,
          remoteVideo: null, // updated in init function
          IceCandidateFound: this.kmsIceCandidateFoundOtherVideo0,
          mediaConstraints: this.constraintsOtherVideo,
      },
      {
          localVideo: undefined,
          remoteVideo: null, // updated in init function
          IceCandidateFound: this.kmsIceCandidateFoundOtherVideo1,
          mediaConstraints: this.constraintsOtherVideo,
      },
      {
          localVideo: undefined,
          remoteVideo: null, // updated in init function
          IceCandidateFound: this.kmsIceCandidateFoundOtherVideo2,
          mediaConstraints: this.constraintsOtherVideo,
      },
      {
          localVideo: undefined,
          remoteVideo: null, // updated in init function
          IceCandidateFound: this.kmsIceCandidateFoundOtherVideo3,
          mediaConstraints: this.constraintsOtherVideo,
      },
  ];

  sdpAnswerAudio(message) {
      this.webRtcPeerAudio.processAnswer(message.sdpAnswer);
  }
  sdpAnswerSelfVideo(message) {
      this.webRtcPeerSelfVideo.processAnswer(message.sdpAnswer);
  }
  sdpAnswerScreenShare(message) {
      this.webRtcPeerScreenShare.processAnswer(message.sdpAnswer);
  }
  sdpAnswerOtherVideo(message) {
      if (message.remoteUserId) {
          const index = this.getIndexOfRemoteUser(message.remoteUserId);
          if (index != -1) {
              this.webRtcPeerOtherVideo[index].processAnswer(
                  message.sdpAnswer
              );
          }
      }
  }

  createLocalWebrtcEndpointAudio() {
      console.log(
          "this.options in createLocalWebrtcEndpointAudio",
          this.optionsAudio
      );
      this.webRtcPeerAudio = kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(
          this.optionsAudio,
          (error) => {
              console.log(" inside webrtcSendRecV callback ERROR", error);
              if (error) return;
              setTimeout(() => {
                  this.onWebrtcCreatedAudio();
              }, 1000);
          }
      );
  }
  // onVideoFilterClick(filter){
  //     // console.log("@@@filter",filter)
  //     this.deepAR.switchEffect(0, 'slot', '../../assets/deepAR/effects/'+filter,)

  // }
  setRedisData(action) {
      let key = `${this.roomId}+${this.userID}`
      let info = {
          key: key,
          value: "",
          roomID: this.roomId,
          action: action,
          userID: this.userID
      }
      this.auth.getTVInformation(info, '/setAvatharInfo').then((res: any) => {
          try {
              console.log(',,,,,,,,,,,,,,,,,,,,,,', res)
          } catch (e) {
              console.error(e);
          }
      })
  }
  // async handRiseDetection() {
  //     // console.log("@@@inside loadmodel")
  //     let raiseHand = document.getElementById('raiseHand');
  //     const model = await handpose.load();
  //     let videoElement: any;
  //     if (this.userType == "teacher") {
  //         videoElement = document.getElementById("teacherVideo")
  //     } else {
  //         videoElement = document.getElementById("otherVideo0")
  //     }

  //     this.handRiseInterval = setInterval(async () => {

  //         const predictions = await model.estimateHands(videoElement);
  //         if (predictions.length > 0 && predictions[0]['handInViewConfidence'] > 0.98) {
  //             if (!this.isHandRaisedAlready) {
  //                 // this.setRedisData("handRaised") 
  //                 console.log("@@--->Hand Raised")
  //                 this.emitMessageToUsers('handRaised', 'AI')//When hand raised

  //                 // if (document.getElementById('raiseHand').innerHTML == '') {
  //                 // raiseHand.innerHTML = 'Hand Raised'

  //                 // }
  //             }

  //             if (this.isHandRaisedAlready && this.isSpeakerSpeaking) {
  //                 if (!this.isHandRaisedAndSpeakingAlready) {
  //                     console.log("@@@Speaking, Hand raised") //When speaking & hand raised 
  //                     this.emitMessageToUsers('handRaiseAndSpeaking', 'AI')
  //                 }
  //                 this.isHandRaisedAndSpeakingAlready = true
  //             }
  //             this.isHandRaisedAlready = true
  //         }
  //         else {

  //             if (this.isHandRaisedAlready) {
  //                 console.log("@@--->Hand not raised")
  //                 this.emitMessageToUsers('idle', 'AI') ////When hand not raised
  //             }
  //             this.isHandRaisedAlready = false
  //             this.isHandRaisedAndSpeakingAlready = false

  //             // raiseHand.innerHTML = ''
  //         }

  //     }, 300);

  // }

  emitMessageToUsers(action, type) {
      let key = `${this.roomId}+${this.userID}`
      let info = {
          key: key,
          roomID: this.roomId,
          action: action,
          userID: this.userId,
          role: localStorage.getItem("type"),
          name: localStorage.getItem("username"),
          type: type
      }
      this.auth.sendKMSMessages(info)
  }
  detectSpeakerTalking() {
      let audioStream = kurentoUtils.WebRtcPeer.getAudioStream()
      let isTalkingElement = document.getElementById('isTalking')
      const speechEvent = kurentoUtils.WebRtcPeer.hark(audioStream, { threshold: -50 });
      speechEvent.on('speaking', () => {
          console.log("@@speaking")
          this.isSpeakerSpeaking = true
          this.emitMessageToUsers('speakerSpeaking', 'AI') //when speaker speaking
          // isTalkingElement.innerHTML="Speaking"
      });
      speechEvent.on('stopped_speaking', () => {
          console.log("@@not speaking")
          this.isSpeakerSpeaking = false;
          this.isHandRaisedAndSpeakingAlready = false;
          this.emitMessageToUsers('idle', 'AI') //when speaker not speaking
          //    isTalkingElement.innerHTML=""
      });
  }

  createLocalWebrtcEndpointSelfVideo() {
      // this.canvas =<HTMLCanvasElement> document.getElementById('deepar-canvas');
      // var video = <HTMLVideoElement>document.getElementById('otherVideo0')

      // var deepAR = DeepAR({ 
      //   canvasWidth:  670, 
      //   canvasHeight: 480,
      //   licenseKey: '5c30c8474a8342ec697be96440755886066a2b8da812c66a47f7a8a34e241a547030701f1bd349c5',
      //   canvas: this.canvas,
      //   numberOfFaces: 1,
      //   libPath: './lib',
      //   segmentationInfoZip: 'segmentation.zip',
      //   onInitialize: function() {

      //     deepAR.startVideo(false);
      //   }

      // });
      // this.deepAR=deepAR;
      // // console.log("@@@deepar new",deepAR) 
      // deepAR.downloadFaceTrackingModel('../../assets/deepAR/lib/models-68-extreme.bin');
      // deepAR.onVideoStarted = function() {
      //     var loaderWrapper = document.getElementById('loader-wrapper');
      //     loaderWrapper.style.display = 'none';
      //   };


      //   let checkbox=document.getElementById("checkbox")
      // checkbox.addEventListener('change', (event) => {
      //     if ((<HTMLInputElement>event.currentTarget).checked) {
      //         deepAR.switchEffect(0, 'slot', '../../assets/deepAR/effects/lion',function(){
      //         })

      //     } else {
      //         deepAR.switchEffect(0, 'slot', '../../assets/deepAR/effects/background_segmentation',)
      //     }
      //     console.log("@@@ctx",this.canvas.getContext("2d"))
      //   })
      console.log(
          "this.options in createLocalWebrtcEndpointSelfVideo",
          this.optionsSelfVideo
      );
      this.webRtcPeerSelfVideo = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
          this.optionsSelfVideo,
          (error) => {
              console.log(" inside webrtcSendRecV callback ERROR", error);
              if (error) return;
              setTimeout(() => {
                  this.onWebrtcCreatedSelfVideo();
              }, 1000);
          }
      );
      var videoElement = <HTMLVideoElement>document.getElementById("otherVideo0")
      videoElement.onpause = function () {
          videoElement.play()
      };

      // this.handRiseDetection()
      if (this.userType == 'student') {
          setTimeout(() => {
              console.log('notification..............', this.accessToken, "EnableScreenShare", this.roomId, this.userId,)
              this.setGetDelValueInRedis('get', '')
          }, 5000)
      } else {
          this.redisSubscribe(false)
      }
  }
  createLocalWebrtcEndpointScreenShare() {

      console.log('student oprtion1', this.optionsScreenShare)
      if (this.userType == "teacher" && !this.isMobileDevice) {
          console.log("teacher___+++_+_++__");
          // @ts-ignore
          if (navigator.mediaDevices.getDisplayMedia) {
              console.log("teacher___+++_+_++__________________________if");
              // @ts-ignore
              navigator.mediaDevices.getDisplayMedia(this.constraintsScreenShare).then((mediaStream) => {
                  console.log("mediaStream____________if____________", mediaStream)
                  this.mediaStreamForStopSharing = mediaStream;
                  const videoTrack = mediaStream.getVideoTracks()[0];
                  videoTrack.addEventListener('ended', event => {
                      this.stopSharing()
                  });
                  this.screenShareCall(mediaStream);
              }).catch((error) => {
                  // todo return
                  console.log("error in if cond____asda____", error);
              });
              // @ts-ignore
          } else if (navigator.getDisplayMedia) {
              console.log("teacher___+++_+_++________________________else");
              // @ts-ignore
              navigator.getDisplayMedia(this.constraintsScreenShare).then((mediaStream) => {
                  console.log("mediaStream___________else_____________", mediaStream)

                  this.screenShareCall(mediaStream)
              }).catch((error) => {
                  console.log("error in else________", error);

                  // todo return
              });
          }
      } else if (this.userType == 'student') {
          console.log('student oprtion2', this.optionsScreenShare)
          this.webRtcPeerScreenShare = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
              this.optionsScreenShare,
              (error) => {
                  console.log(" inside webrtcSendRecV callback ERROR", error);
                  if (error) return;
                  setTimeout(() => {
                      this.onWebrtcCreatedScreenShare();
                  }, 1000);
              }
          );
      }
  }

  screenShareCall = (mediaStream) => {
      this.optionsScreenShare.videoStream = mediaStream;
      this.optionsScreenShare.localVideo = this.screenShare
      console.log("this.optionsScreenShare _______", this.optionsScreenShare, mediaStream);
      console.log("this.options in createLocalWebrtcEndpointScreenShare", this.optionsScreenShare);
      this.webRtcPeerScreenShare = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
          this.optionsScreenShare,
          (error) => {
              console.log(" inside webrtcSendRecV callback ERROR", error);
              if (error) return;
              setTimeout(() => {
                  this.onWebrtcCreatedScreenShare();
              }, 1000);
          }
      );
  }


  createLocalWebrtcEndpointOtherVideo(index) {
      console.log(
          "this.options in createLocalWebrtcEndpointOtherVideo",
          this.optionsOtherVideo[index]
      );
      this.webRtcPeerOtherVideo[index] =
          kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
              this.optionsOtherVideo[index],
              (error) => {
                  console.log(" inside webrtcSendRecV callback ERROR", error);
                  if (error) return;
                  setTimeout(() => {
                      this.onWebrtcCreatedOtherVideo(index);
                  }, 1000);
              }
          );
  }
  wsMessageHandling = function (message) {
      var parsedMessage = JSON.parse(message.data);
      console.info("Received message: " + message.data);
      switch (parsedMessage.type) {
          case "SdpAnswerAudio":
              this.sdpAnswerAudio(parsedMessage);
              break;
          case "IceAudioKms":
              this.webRtcPeerAudio.addIceCandidate(parsedMessage.candidate);
              break;
          case "SdpAnswerVideoSelf":
              this.sdpAnswerSelfVideo(parsedMessage);
              break;
          case "IceVideoSelfKms":
              this.webRtcPeerSelfVideo.addIceCandidate(
                  parsedMessage.candidate
              );
              break;
          case "SdpAnswerVideoRemote":
              this.sdpAnswerOtherVideo(parsedMessage);
              break;
          case "IceVideoRemoteKms":
              if (parsedMessage.remoteUserId) {
                  const index = this.getIndexOfRemoteUser(
                      parsedMessage.remoteUserId
                  );
                  if (index != -1) {
                      this.webRtcPeerOtherVideo[index].addIceCandidate(
                          parsedMessage.candidate
                      );
                  }
              }
              break;
          case "ParticipantListUpdated":
              this.roomId = parsedMessage.roomId;
              this.changePage("noAction");
              break;
          case "DeleteRoom":
              this.disposeAllSlots();
              break;
          case "SdpAnswerScreenShare":
              this.sdpAnswerScreenShare(parsedMessage);
              break;
          case "IceScreenShareKms":
              this.webRtcPeerScreenShare.addIceCandidate(
                  parsedMessage.candidate
              );
              break;
          default:
              console.error("Unrecognized message", parsedMessage);
      }
  }.bind(this);

  onWebrtcCreatedAudio() {
      console.log("inside onWebrtcCreated");
      this.webRtcPeerAudio.generateOffer((error, offerSdp) => {
          this.onOffer(error, offerSdp, "SdpOfferAudio");
      });
      // this.detectSpeakerTalking()
  }
  onWebrtcCreatedSelfVideo() {
      console.log("inside onWebrtcCreated");
      this.webRtcPeerSelfVideo.generateOffer((error, offerSdp) => {
          this.onOffer(error, offerSdp, "SdpOfferVideoSelf");
      });
  }
  onWebrtcCreatedScreenShare() {
      console.log("inside onWebrtcCreated");
      this.webRtcPeerScreenShare.generateOffer((error, offerSdp) => {
          this.onOffer(error, offerSdp, "SdpOfferScreenShare");
      });
  }
  onWebrtcCreatedOtherVideo(index) {
      console.log("inside onWebrtcCreated");
      this.webRtcPeerOtherVideo[index].generateOffer((error, offerSdp) => {
          this.onOffer(error, offerSdp, "SdpOfferVideoRemote", index);
      });
  }
  IceCandidateFound(candidate, type, index = -1) {
      console.log("Local candidate" + JSON.stringify(candidate));
      //  if (state == I_CAN_START){
      var message = {
          accessToken: this.accessToken,
          type: type,
          roomId: this.roomId,
          userId: this.userId,
          candidate: candidate,
      };
      if (type === "IceVideoRemoteClient" && index != -1) {
          message["remoteUserId"] = this.remoteUser[index];
      }
      this.ws.send(JSON.stringify(message));
  }

  onOffer(error, offerSdp, type, index = -1) {
      // if (error) return onError(error)
      console.log("onOffer function", error);
      console.log(offerSdp);
      console.info("Invoking SDP offer callback function " + location.host);
      var message = {
          accessToken: this.accessToken,
          type: type,
          roomId: this.roomId,
          userId: this.userId,
          sdpOffer: offerSdp,
          remoteUserId: undefined
      };
      console.log("________________SdpOfferScreenShare", type, this.teacherId);

      if (type == 'SdpOfferVideoRemote') {
          console.log("TYPE _____ SdpOfferVideoRemote __________________________");
          message.remoteUserId = this.remoteUser[index]
          this.ws.send(JSON.stringify(message));
      }
      else if (type == 'SdpOfferScreenShare' && this.userType == 'student') {
          console.log("TYPE _____ SdpOfferScreenShare __________________________", this.teacherId);
          message.remoteUserId = this.teacherId;
          console.log("____________________            console.log(message);______________", message);
          this.ws.send(JSON.stringify(message));

      } else {
          this.ws.send(JSON.stringify(message));
      }
      console.log("____________________    console.log_", message);

  }
  MuteUnmute() {
      this.m1 = !this.m1;
      if (this.webRtcPeerAudio) {
          this.webRtcPeerAudio.audioEnabled =
              !this.webRtcPeerAudio.audioEnabled;
      }
      // if(!this.m1){
      //     this.speakerTalkingStream.getTracks()[0].enabled = false
      // }else{
      //     this.speakerTalkingStream.getTracks()[0].enabled = true
      // }
  }
  // getClasses() {
  //     let typeName = {};
  //     // this.classes=[];
  //     typeName['type'] = "Individual";
  //     this.auth
  //         .getTVInformation(typeName, "/getClasses")
  //         .then(
  //             (res: any) => {
  //                 try {
  //                     if (res) {
  //                         console.log("resData_GET CLASSES______________", res);
  //                         if (res.getClass && res.getClass == "success") {
  //                             this.classes = res.classes
  //                             this.data.classesName = this.classes[0]
  //                             this.sortFunction()
  //                         }
  //                     } else {
  //                         console.error("General error");
  //                     }
  //                 } catch (e) {
  //                     console.error(e);
  //                 }
  //             },
  //             (err: any) => {
  //                 console.error(err);
  //             }
  //         );
  // }

  sortFunction() {
      this.classes.sort((a, b) => (a.name > b.name ? 1 : ((b.name > a.name) ? -1 : 0)))
  }
//   updateKmsRooms(roomId) {
//       if (this.classMode == undefined) {
//           this.classMode = this.data.classMode
//       }
//       this.auth
//           .getTVInformation({ roomId: roomId, padId: this.padId, lessonId: this.id, classId: this.directClass, classMode: this.classMode }, "/updateKmsRooms")
//           .then(
//               (res: any) => {
//                   try {
//                       if (res) {
//                           console.log("resData", res);
//                           if (res.createRoom === "success") {
//                           }
//                       } else {
//                           console.error("General error");
//                       }
//                   } catch (e) {
//                       console.error(e);
//                   }
//               },
//               (err: any) => {
//                   console.error(err);
//               }
//           );
//   }

  screenShareFun(shareType) {
      // alert(this.userType)
      this.isDisabled = true;
      this.sharestop.emit(this.yesstarted)
      var msg = {
          accessToken: this.accessToken,
          type: "EnableScreenShare",
          roomId: this.roomId,
          userId: this.userId,
      };
      console.log('notification..............1', this.accessToken, "EnableScreenShare", this.roomId, this.userId,)
      console.log("New LOGS________________3_______________________", msg);
      this.ws.send(JSON.stringify(msg));
      console.log("New LOGS________________check_______________________", msg);
      this.createLocalWebrtcEndpointScreenShare();
      this.isSharedScreen = false
      if (this.userType == 'teacher' && !shareType) {
          this.isScreenShare = true
          if (this.isMobileDevice) {
              this.screenShare = document.getElementById("myCanvasforShare");
              this.ctx = this.screenShare.getContext("2d");
              this.isScreenShare = true
              //             this.screenShare.setWidth(700);
              // this.screenShare.setHeight(500);
              // this.ctx.canvas.width = 1484;
              // this.ctx.canvas.height = 1041;
              this.screenShareForCanvas()
              this.emitEventForAD('start')
          }
          this.notifyStudents(true)
          this.setGetDelValueInRedis('set', true)
      }
  }

  emitEventForAD(type) {
      this.sharedScreenFromAd.emit(type)
  }

  setGetDelValueInRedis(type, val) {
      let key = this.roomId + 'setbwscreenshare'
      this.auth.getTVInformation({ key, value: val, type }, "/setGetDelInRedis").then((res: any) => {
          try {
              console.log('typeshare..........', res.result, type, type == 'get' && this.userType == 'student' && res.result.reply == 'true')
              if (type == 'get' && this.userType == 'student' && res.result.reply == 'true') {
                  this.redisSubscribe(true)
                  this.screenShareFun(true)
              } else if (type == 'get') {
                  this.redisSubscribe(false)
              }

          } catch (e) {
              console.error(e);
          }
      }
      );
  }

  stopSharing() {
      this.isDisabled = false;
      console.log("stopsharefun is called")
      this.sharestop.emit(!this.yesstarted)
      if (this.userType != 'teacher') {
          this.videoStreamForSharescreen = document.getElementById("screenShare");
      }
      this.isScreenShare = false
      this.isSharedScreen = true;
      if (this.videoStreamForSharescreen && this.videoStreamForSharescreen.srcObject) {
          const stream = this.videoStreamForSharescreen.srcObject;
          const tracks = stream.getTracks();
          tracks.forEach((track) => {
              track.stop();
          });
          this.videoStreamForSharescreen.srcObject = null;
          if (this.userType == 'teacher') {
              clearInterval(this.clearShareScreen)
              this.setGetDelValueInRedis('del', '')
              this.auth.reausableSocket(this.padId, 'stopSharing')
          }
      } else if (this.userType == 'teacher') {
          this.auth.reausableSocket(this.padId, 'stopSharing')
      }
  }

  notifyStudents(screenShare) {
      let key = `${this.roomId}+${this.userId}+sharescreen`;
      let value = {
          classID: "",
          type: this.userType,
          screenshare: screenShare,
          roomID: this.roomId,
          userID: this.userId
      }
      this.setRedisDataForScreenShare(key, value)
      this.auth.getTVInformation({ value: value }, "/shareScreenIntiate")
          .then(
              (res: any) => {
                  try {

                  } catch (e) {
                      console.error(e);
                  }
              },
              (err: any) => {
                  console.error(err);
              }
          );
  }

  screenShareForCanvas() {
      let varOpened = true
      this.clearShareScreen = setInterval(() => {
          this.auth.getThirPartInfo(`https://${this.localIp}:7050`).subscribe((res: any) => {
              let result: any = res._body;
              let result1 = result.split(':')
              // if (result1[2] == 'false}' || varOpened == true) {
              this.createCanvasImage('data:image/png;base64,' + result.split(',"')[0].split(':')[1], varOpened)
              varOpened = false
              // }
          })
      }, 100)
  }



  createCanvasImage(img, call) {

      // ctx.canvas.width = 1484;
      // ctx.canvas.height = 1041;
      var image = new Image();
      image.onload = () => {
          console.log(image.width, image.height, this.screenShare.width, this.screenShare.height)
          // ctx.clearRect(0, 0, window.innerWidth,window.innerHeight);
          this.ctx.drawImage(image, 0, 0, 700, 400);
          // ctx.clearRect(0, 0, 10, 10);
          // ctx.drawImage(image, 0, 0);
      };
      image.src = img
      if (call == true) {
          this.screenShareCall(this.screenShare.captureStream(5))
          const stream = this.screenShare.captureStream();
          this.videoStreamForSharescreen = document.getElementById("screenShare");
          this.videoStreamForSharescreen.srcObject = stream
      }
      //   imageObj.onload = function() {
      //     ctx.drawImage(imageObj, 0, 0,window.innerWidth,window.innerHeight);
      //   };
  }

  createRoom() {
      if (this.createClass == false) {
          this.createClass = true;
          this.auth
              .getTVInformation({ className: this.directClass }, "/createRoom")
              .then(
                  (res: any) => {
                      try {
                          if (res) {
                              console.log("resData sameer", res);
                              //if (res.createRoom === "success") {
                                  this.roomId = res.body.roomId;
                                  localStorage.setItem('roomID', res.body.roomId)
                                  this.auth.socket.emit("emit_handraise", { username: localStorage.getItem('username'), ishandraised: false }, { roomid: localStorage.getItem('roomID'), user: 'teacher' });
                                  this.joinRoom();
                                  //this.updateKmsRooms(this.roomId);
                             // }
                          } else {
                              console.error("General error");
                          }
                      } catch (e) {
                          console.error(e);
                      }
                  },
                  (err: any) => {
                      console.error(err);
                  }
              );
      }
  }
  joinRoom() {
      this.auth
          .getTVInformation(
              {
                  roomId: this.roomId,
                  userType: this.userType,
                  userName: this.userName,
              },
              "/joinRoom"
          )
          .then(
              (res: any) => {
                  try {
                      if (res) {
                          console.log("resDatasam", res);
                          console.log(
                              "New LOGS_________removed audio___________________"
                          );

                          if (res.body.joinRoom === "success") {
                              this.m1 = true;
                              this.userId = res.body.userId;
                              this.otherMembers.push(this.userId);
                              localStorage.setItem('remoteUser', this.userId)
                              let msg = {
                                  accessToken: this.accessToken,
                                  type: "EnableAudio",
                                  roomId: this.roomId,
                                  userId: this.userId,
                              };
                              this.ws.send(JSON.stringify(msg));
                              this.createLocalWebrtcEndpointAudio();
                              console.log(
                                  "New LOGS__________________2_____________________",
                                  msg
                              );
                              msg = {
                                  accessToken: this.accessToken,
                                  type: "EnableVideoSelf",
                                  roomId: this.roomId,
                                  userId: this.userId,
                              };
                              console.log(
                                  "New LOGS________________3_______________________",
                                  msg
                              );
                              this.ws.send(JSON.stringify(msg));
                              this.createLocalWebrtcEndpointSelfVideo();
                          }
                      } else {
                          console.error("General error");
                      }
                  } catch (e) {
                      console.error(e);
                  }
              },
              (err: any) => {
                  console.error(err);
              }
          );
  }
  ngOnDestroy() {
      if (this.userId && this.roomId) {
          this.leaveRoom();
          if (this.userType == 'teacher') {
              this.notifyStudents(false)
          }
          this.setPostionFixed.emit('fixed')
      }
  }
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
      console.log("Processing beforeunload.._____________________________________________________.");
      if (this.userId && this.roomId) {
          this.leaveRoom();
          if (this.userType == 'teacher') {
              this.notifyStudents(false)
          }
      }
  }
  JoinClass() {
      this.selectClass = false;
      this.startClass = true;
      if (this.userType == "student") {
          this.roomId = "OJK408"//this.directClass;
          localStorage.setItem('roomID', "OJK408");
          this.auth.socket.emit("curt/spotlRoomJoin", { roomid: localStorage.getItem('roomID') });
          this.joinRoom();
          this.isAvchatStart.emit(this.startClass);


      } else if (this.userType == 'teacher') {
          this.createRoom();
      }
  }
  openSelectClass() {
    console.log("open select function")
      //if (this.userType == "teacher" && this.directClass == 'false') {
        //  this.getClasses();
          this.getLession();
          this.selectClass = true;
     // }
  }
  quit() {
      this.auth.socket.removeListener('fetch_curtain_spotlight_when_teacher_selects_or_deselects');
      this.auth.socket.removeListener("object_moving_or_scaling");
      this.closeVirtual();
      this.roomMembers = [];
      this.startClass = false;
      this.selectClass = true;
      console.log("_____________________ QUIT FUNCTION CALLED _____________________");
      if (this.userType === 'teacher') {
          this.auth.sendEndingEvent(this.roomId)
          this.auth.endClassByStudent()
          this.deleteRoom();
          this.auth.DELETEKEY(localStorage.getItem('roomID'));
          this.avatarSubscribeDelete()
          this.setPostionFixed.emit('fixed')
          // this.emitMessageToUsers('', 'cancelDashboard')
          console.log("_____________________ CALLING DELETE ROOM _____________________");
      } else if (this.userType === 'student') {
          this.auth.endClassByStudent()
          this.leaveRoom();
          this.avatarSubscribeDelete()
          this.setPostionFixed.emit('fixed')
          // this.emitMessageToUsers('', 'cancelDashboardStudent')
          console.log("_____________________CALLING LEAVE ROOM _____________________");
      }
  }

  avatarSubscribeDelete() {
      this.userID = localStorage.getItem('remoteUser');
      let roomID = localStorage.getItem('roomID');
      let key = `${roomID}+${this.userID}`
      this.auth.getTVInformation({ key: key, roomID: roomID, userID: this.userID }, '/deleteAvatarDetailsOnRedis').then((res: any) => {
          try {
              console.log(',,,,,,,,,,,,,,,,,,,,,,', res)
          } catch (e) {
              console.error(e);
          }
      })
  }
  leaveRoom() {
      this.createClass = false;
      this.m1 = false;
      this.disposeAllSlots()
      this.auth
          .getTVInformation(
              { roomId: this.roomId, userId: this.userId },
              "/leaveRoom"
          )
          .then(
              (res: any) => {
                  try {
                      if (res) {
                          console.log("resData", res);
                          if (res.leaveRoom === "success") {
                          }
                      } else {
                          console.error("General error");
                      }
                  } catch (e) {
                      console.error(e);
                  }
              },
              (err: any) => {
                  console.error(err);
              }
          );
  }

  deleteRoom() {
      console.log("______________--delete Room funcion exe ______________________-");

      this.createClass = false;
      this.m1 = false;
      this.disposeAllSlots();
      this.auth
          .getTVInformation(
              { roomId: this.roomId, userId: this.userId },
              "/deleteRoom"
          )
          .then(
              (res: any) => {
                  try {
                      if (res) {
                          console.log("resData", res);
                          if (res.deleteRoom === "success") {
                          }
                      } else {
                          console.error("General error");
                      }
                  } catch (e) {
                      console.error(e);
                  }
              },
              (err: any) => {
                  console.error(err);
              }
          );
  }

  getRoomMembersList() {
      //TODO Get the list of members in the room
      return new Promise((resolve) => {
          this.auth
              .getTVInformation({ roomId: this.roomId }, "/getRoomMembers")
              .then(
                  (res: any) => {
                      try {
                          if (res) {
                              console.log("resData", res.body.roomMembers);
                              //if (res.body.getRoomMembers === "success") {
                                  this.roomMembers = res.body.roomMembers;
                                  console.log("___________--room members_____________", this.roomMembers);
                                  this.roomMembers.forEach(element => {
                                      if (element.role == 'teacher') {
                                          this.teacherId = element.userId;
                                          console.log("___________________________________________________________________", this.teacherId);

                                      }
                                  });
                                  resolve(res);
                            //  }
                          } else {
                              console.error("General error");
                              resolve({});
                          }
                      } catch (e) {
                          console.error(e);
                          resolve({});
                      }
                  },
                  (err: any) => {
                      console.error(err);
                      resolve({});
                  }
              );
      });
  }

  getTeacherUserId() {
      const teacherIndex = this.videoCapable.findIndex(
          (element) => element.role === "teacher"
      );
      return teacherIndex === -1 ? null : this.videoCapable[teacherIndex];
  }
  getRemoteStudentUserId(position) {
      let counter = 0;
      const studentIndex = this.videoCapable.findIndex(
          (element) => {
              if (element.role === 'student' && element.userId != this.userId) {
                  if (counter == position) {
                      return true;
                  }
                  counter++;
              }
              return false;
          }
      );
      return studentIndex === -1 ? null : this.videoCapable[studentIndex];
  }

  getMembersForDisplay(pageNumber) {
      //TODO Filter from the room members list based on page Number and Video status
      return new Promise((resolve) => {
          let membersForDisplay = [];
          if (this.userType === "student") {
              const teacherId = this.getTeacherUserId();
              if (teacherId) {
                  membersForDisplay.push(teacherId);
              }
              let totalRemoteStudents = 3;
              let position = (pageNumber - 1) * 3;
              while (totalRemoteStudents) {
                  let studentId = this.getRemoteStudentUserId(position);
                  if (studentId) {
                      membersForDisplay.push(studentId);
                      position++;
                      totalRemoteStudents--;
                  } else {
                      break;
                      // totalRemoteStudents = 0;
                  }
              }
          } else if (this.userType === "teacher") {
              let totalRemoteStudents = 4;
              let position = (pageNumber - 1) * 4;
              while (totalRemoteStudents) {
                  let studentId = this.getRemoteStudentUserId(position);
                  console.log('ksk', totalRemoteStudents, studentId);
                  if (studentId) {
                      membersForDisplay.push(studentId);
                      position++;
                      totalRemoteStudents--;
                  } else {
                      break;
                      // totalRemoteStudents = 0;
                  }
                  this.studentHand = studentId;
                  console.log("kskna", this.studentHand.name);
              }
          }
          // 1. for students teachers video should be present
          // 2. if teahcer's video not available - keep one slot empty (to be decided)
          // 3. it should not have current users id (logged in userId)
          // 4. if there are less than 3 students video - mark slots empty (TBD)
          console.log(
              "___________-videoCapable____________-",
              this.videoCapable
          );
          resolve(membersForDisplay);
      });
      // TODO
  }
  studentHand: any;

  freeUpSlots(membersToDisplay) {
      //TODO Dispose video in the current gird based on members to be displayed
      this.remoteUser.forEach((element, index) => {
          // clearing empty slots
          if (element != null && !(index == 0 && element == "teacher")) {
              const memberIndex = membersToDisplay.findIndex(
                  (member) => element === member.userId
              );
              console.log(memberIndex);

              if (memberIndex == -1) {
                  this.webRtcPeerOtherVideo[index].dispose();
                  this.disableRemoteVideo(this.remoteUser[index]);
                  this.webRtcPeerOtherVideo[index] = null;
                  if (index == 0) {
                      if (this.userType == "teacher") {
                          this.remoteUser[index] = null;
                      } else if (this.userType == "student") {
                          this.remoteUser[index] = "teacher";
                      }
                  } else {
                      this.remoteUser[index] = null;
                  }
              }
          }
      });
  }

  fillEmptySlots(membersToDisplay) {
      //TODO Enable remote video based on members to be displayed and the currently active videos
      this.remoteUser.forEach((element, index) => {
          // filling empty slots
          if (element == null || (element == "teacher" && index == 0)) {
              if (index == 0 && this.userType == "student") {
                  const memberIndex = membersToDisplay.findIndex(
                      (member) => member.role == "teacher"
                  );
                  if (memberIndex != -1) {
                      this.enableRemoteVideo(
                          membersToDisplay[memberIndex].userId,
                          "teacher"
                      );
                  }
              } else {
                  membersToDisplay.forEach((element, index) => {
                      const userIndex = this.remoteUser.findIndex(
                          (user) => element.userId === user
                      );
                      if (userIndex == -1) {
                          this.enableRemoteVideo(element.userId, "student");
                      }
                  });
              }
          }
      });
  }

  updateVideoGrid(pageNumber) {
      // let membersToDisplay = this.getMembersForDisplay(pageNumber);
      this.getMembersForDisplay(pageNumber).then(
          (res: any) => {
              try {
                  if (res) {
                      console.log("______________________membersToDisplay__________________", res);
                      localStorage.setItem('membersDisplay', JSON.stringify(res))
                      this.freeUpSlots(res);
                      this.fillEmptySlots(res);
                      this.emitMessageToUsers('', 'Grid')
                  } else {
                      console.error("General error");
                  }
              } catch (e) {
                  console.error(e);
              }
          },
          (err: any) => {
              console.error(err);
          }
      );
  }

  changePage(action) {
      this.getRoomMembersList().then(
          (res: any) => {
              try {
                  if (res) {
                    console.log("roommemberslist",res)
                      // this.roomMembers = res.roomMembers;
                      this.videoCapable = res.body.roomMembers.filter(
                          (user) => user.video == true
                      );
                      let totalPages = Math.ceil(
                          this.videoCapable.length / 4
                      );

                      console.log(this.videoCapable);
                      console.log(totalPages);

                      if (action == "prev") {
                          if (this.currPageNo > 1) {
                              this.currPageNo--;
                          }
                      } else if (action == "next") {
                          if (this.currPageNo < totalPages) {
                              this.currPageNo++;
                          }
                      } else if (action == "noAction") {
                          if (this.currPageNo > totalPages) {
                              this.currPageNo = totalPages;
                          }
                      }
                      console.log(this.currPageNo);
                      this.updateVideoGrid(this.currPageNo);
                  } else {
                      console.error("General error");
                  }
              } catch (e) {
                  console.error(e);
              }
          },
          (err: any) => {
              console.error(err);
          }
      );
  }

  // getRoomMembers() {
  //     // this.v1 = !this.v1
  //     // if (true) {
  //     this.auth
  //         .getTVInformation({ roomId: this.roomId }, "/getRoomMembers")
  //         .then(
  //             (res: any) => {
  //                 try {
  //                     if (res) {
  //                         console.log("resData", res.roomMembers);
  //                         if (res.getRoomMembers === "success") {
  //                             this.roomMembers = res.roomMembers;
  //                             console.log(
  //                                 "room members__________",
  //                                 this.roomMembers
  //                             );
  //                             console.log(
  //                                 "remote Users____++++____++++____",
  //                                 this.remoteUser
  //                             );
  //                             // this.freeUpSlots();
  //                             // this.fillEmptySlots();
  //                         }
  //                     } else {
  //                         console.error("General error");
  //                     }
  //                 } catch (e) {
  //                     console.error(e);
  //                 }
  //             },
  //             (err: any) => {
  //                 console.error(err);
  //             }
  //         );
  // }
  // else{
  //     this.webRtcPeerOtherVideo.forEach((element) => {
  //         if (element) {
  //             element.dispose();
  //             element = null;
  //         }
  //     });
  //     this.remoteUser.forEach(element => {
  //         if (element) {
  //             let msgs = {
  //                 accessToken: this.accessToken,
  //                 type: "DisableVideoRemote",
  //                 roomId: this.roomId,
  //                 userId: this.userId,
  //                 remoteUserId: element,
  //             };
  //             console.log(msgs);
  //             this.ws.send(JSON.stringify(msgs));
  //         }
  //     });
  //     if(this.userType == "teacher"){
  //         this.remoteUser[0] = null;
  //     }else if(this.userType == "student"){
  //         this.remoteUser[0] = "teacher";
  //     }
  //     this.remoteUser[1] = null;
  //     this.remoteUser[2] = null;
  //     this.remoteUser[3] = null;
  // }
  // }

  disposeAllSlots() {
      if (this.webRtcPeerAudio) {
          this.webRtcPeerAudio.dispose();
          this.webRtcPeerAudio = null;
          // this.deepAR.stopVideo()
          clearInterval(this.handRiseInterval)
          // this.speakerTalkingStream.getTracks().forEach(function(track) {
          //     track.stop();
          //   });
      }
      if (this.webRtcPeerSelfVideo) {
          this.webRtcPeerSelfVideo.dispose();
          this.webRtcPeerSelfVideo = null;
      }
      if (this.webRtcPeerScreenShare && !this.isMobileDevice) {
          this.webRtcPeerScreenShare.dispose();
          this.webRtcPeerScreenShare = null;
      }
      this.webRtcPeerOtherVideo.forEach((element) => {
          if (element) {
              element.dispose();
              element = null;
          }
      });
      if (this.userType == "teacher") {
          this.remoteUser[0] = null;
          this.setGetDelValueInRedis('del', '')
      } else if (this.userType == "student") {
          this.remoteUser[0] = "teacher";
      }
      this.remoteUser[1] = null;
      this.remoteUser[2] = null;
      this.remoteUser[3] = null;
      this.setPostionFixed.emit('fixed')
      clearInterval(this.clearShareScreen)
      this.router.navigate([`/dashboard`])
  }

  getLession() {
    this.data.lessonName = "26september"//res.lessonName;
    //   this.auth.getTVInformation({ id: "63314ea7ec9280058488eaf8" }, '/getSingleLesson').then((res: any) => {
    //       try {
    //           if (res) {
    //               console.log("get One Lesson______________", res);
    //               this.data.lessonName = res.lessonName;
    //           } else {
    //               console.error("General error");
    //           }
    //       } catch (e) {
    //           console.error(e);
    //       }
    //   }, (err: any) => {
    //       console.error(err);
    //   });
  }

  validateUser(classId) {
      console.log("sameer",this.data)
      console.log("classid",classId)
      console.log("1111",this.data.classesName)
      console.log("2222",this.data.classMode)
      if (this.userType == "teacher" // && this.data.classesName && this.data.classMode
      ) {
        //this.startAvClass("be60ccbe-98c0-4826-a554-cf22352a89ed");
          console.log("calling validateUser______________________", this.data, classId);
          //console.log("____", this.userType, this.userName, this.userID, classId.id);
          
          this.auth
              .getTVInformation({
                    roomId: "be60ccbe-98c0-4826-a554-cf22352a89ed" ,//classId.id,
                    userType: "teacher",//this.userType,
                    userName: "Eng Teacher 11_12_AB",//this.userName,
                    userId: "teacher.english.ab@tpv-tech.com",//this.userID
              }, "/validateUser")
              .then(
                  (res: any) => {
                      try {
                          if (res) {
                              console.log("resData_VALIDATE STUDENT______________", res);
                              //if (res.validateUser && res.validateUser == "success") {
                                  // res._id res.lessionpadID
                                  // this.data.lesson._id &&
                                  // this.directClass = 
                                  this.sendCreateEventToEtherpad("be60ccbe-98c0-4826-a554-cf22352a89ed")
                                  this.startAvClass("be60ccbe-98c0-4826-a554-cf22352a89ed");
                             // }
                             // else {
                                //  alert(this.ts.instant("dashboard.You do not have permission to access this class"));
                             // }
                          } else {
                              console.error("General error");
                          }
                      } catch (e) {
                          console.error(e);
                      }
                  },
                  (err: any) => {
                      console.error(err);
                  }
              );
      } else {
        //  alert(this.ts.instant('dashboard.Enter required fields'));
      }
  }
  // disposeAllSlotsExceptTeacher() {
  //     this.webRtcPeerOtherVideo.forEach((element) => {
  //         if (element) {
  //             element.dispose();
  //             element = null;
  //         }
  //     });
  //     this.remoteUser[1] = null;
  //     this.remoteUser[2] = null;
  //     this.remoteUser[3] = null;
  // }

  // getPads() {
  //     this.auth.getTVInformation({}, "/getPads").then(
  //         (res: any) => {
  //             try {
  //                 if (res) {
  //                     console.log("resData", res);
  //                     if (res.status === "true") {
  //                         res.data.forEach((element) => {
  //                             if (
  //                                 this.id == "new" &&
  //                                 element.padId == "hU4dpADwSIc4SsZB-eZT"
  //                             ) {
  //                                 this.titleName = element.padName;
  //                             } else if (
  //                                 this.id != "new" &&
  //                                 element.padId == "TiSQlX0anHIFx5OzG_VY"
  //                             ) {
  //                                 this.titleName = element.padName;
  //                             }
  //                         });
  //                     }
  //                 } else {
  //                     console.error("General error");
  //                 }
  //             } catch (e) {
  //                 console.error(e);
  //             }
  //         },
  //         (err: any) => {
  //             console.error(err);
  //         }
  //     );
  // }
  cHat() {
      this.isShowChat = true;
      this.isChat = true;
      this.isPaticipants = false;
      this.isListPaticipants = false;
      this.isShowVideo = false;
      this.isListPaticipants = false;
      this.isVideo = false;
      if (this.callsendroomid) {
          this.sendroomid(localStorage.getItem('roomID'));
          this.callsendroomid = !this.callsendroomid;
      }
  }
  video() {
      this.isChat = false;
      this.isShowChat = false;
      this.isShowVideo = true;
      this.isVideo = true;
      this.isPaticipants = false;
      this.isListPaticipants = false;
      //   console.log("vid",this.isShowVideo);
  }
  paticipants() {
      this.isChat = false;
      this.isShowChat = false;
      this.isPaticipants = true;
      this.isListPaticipants = true;
      this.isShowVideo = false;
      this.isVideo = false;
      console.log("enter", "enter");
  }

  redisSubscribe(val) {
      // alert('subscribing')
      this.auth.sendMessageForShareScreen(this.roomId);
      let key = `${this.roomId}+${this.userId}+sharescreen`;
      let value = {
          classID: "",
          type: this.userType,
          screenshare: val,
          roomID: this.roomId,
          userID: this.userId
      }
      this.setRedisDataForScreenShare(key, value)
      // this.auth.getTVInformation({ roomID: this.roomId }, "/screenSubscribe")
      //     .then((res: any) => {
      //         try {
      //             let key = `${this.roomId}+${this.userId}`;
      //             let value = {
      //                 classID: "",
      //                 type: this.userType,
      //                 screenshare: false,
      //                 roomID: this.roomId,
      //                 userID: this.userId
      //             }
      //             console.log(",,,,,,,,,,,,,,,,,,,,,,", res);
      //             this.setRedisDataForScreenShare(key, value)
      //         } catch (e) {
      //             console.error(e);
      //         }
      //     });
  }

  deleteSubscriptionDetails() {
      let key = ``
      this.auth.getTVInformation({ key: key, roomID: "" }, '/deleteScreenDetailsOnRedis').then((res: any) => {
          try {
              console.log(',,,,,,,,,,,,,,,,,,,,,,', res)
          } catch (e) {
              console.error(e);
          }
      })
  }

  setRedisDataForScreenShare(key, value) {
      let info = {
          key: key,
          value: value,
      };
      this.auth.getTVInformation(info, "/setScreenShareInfo").then((res: any) => {
          try {

          } catch (e) {
              console.error(e);
          }
      });
  }

  sendCreateEventToEtherpad(data) {
      this.classcreated.emit(data)
  }
  sendroomid(data: any) {
      console.log("from send roomid", data);
      this.auth.Joinroom(data);
      this.auth.N = "Eng Teacher 11_12_AB"//localStorage.getItem("username");
      this.auth.R = data;
  }
  sendmessage(data: any) {
      console.log("from send sendmessage", data, this.auth.R, this.auth.N);
      if (data.message == '' || data.message == null || data.message == ' ') {
          console.log("Null or empty", data.message);
          return;
      }
      this.auth.Emit(data.message, this.auth.R, this.auth.N);
      this.auth.NAme.push(this.auth.N);
      this.auth.Message.push(data.message);
      this.textmessage = null;
  }
  config = {
      backdrop: true,
      ignoreBackdropClick: true
  };

  public modalRef: BsModalRef;

  exitFullScreenMode() {
      // let elem= document.querySelector('.fullScreeningMode');
      this.full = false;
      console.log("exitFullScreenMode_______________________", this.full, document.fullscreenElement, document.exitFullscreen);
      if (document.fullscreenElement) {
          document.exitFullscreen()
          console.log("Document Exited from Full screen mode__________1__________")
      }
  }
  fullscreenMode() {


      let elem = document.querySelector('.fullScreeningMode');
      this.full = true;
      console.log("{{{{{{{{{{{{{{", this.full);

      let methodToBeInvoked = elem.requestFullscreen ||
          elem["webkitRequestFullScreen"] || elem['mozRequestFullscreen']
          || elem['msRequestFullscreen'];

      if (methodToBeInvoked) methodToBeInvoked.call(elem);
      //             // if(!document.fullscreenElement==false){
      //             //     this.fullScreenActivate=false;
      //             // }F

  }


  createSocektConnetion() {
      this.auth.joinToClass()
  }

  handraise() {
      console.log("handraise");
      this.Handraise = !this.Handraise;
      this.auth.socket.emit("emit_handraise", { username: localStorage.getItem('username'), ishandraised: this.Handraise }, { roomid: localStorage.getItem('roomID'), user: 'student' });
  }


}

