import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FileService } from '../file.service';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AbstractControl, FormGroup, FormsModule, FormControl, FormArray, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.scss']
})
export class TeacherComponent implements OnInit {

  public save_exit_ref: BsModalRef;
  public save_exit_ref1: BsModalRef;
  public save_exit_ref2: BsModalRef;
  public save_exit_ref3: BsModalRef;
  bsModalRef: BsModalRef;
  Allteachers: any = [];
  position = "absolute";
  teachers: any = {};
  AdminCheckboxListArray: any = [];
  adminnames: any = [];
  admin: any;
  schoolnames: any = [];
  schoolname: any;
  isModalOpen: boolean = false;
  public data: any = {};
  public error: any = {};
  public registerStatus;
  public registerStatusMessage;
  public showRegisterStatus = false;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dtInitialied: boolean = false;
  nodata: boolean = true;
  selectedItemId: any;
  clickedRowCoordinates: { top: number, left: number } = { top: 0, left: 0 };
  selectedItemName: any;
  editclk: boolean = false;
  addclk: boolean = false;
  dltclk: boolean = false;
  regionnames: any= [];countrynames: any= [];
  countryname: string;
  regionname: string;
  countrynamesadd: any = [];
  regionnamesadd: any = [];
  Allregionandcountries: any =[];
  selectedItememail: any;
  adminrole: string;
  editemailclk: boolean;
  addemailclk: boolean;
  currentEmailId: any;
  hide = true;
  hide1 = true;
  public pageNo: number = 1;
  public pageLimit: number = 5
  public totalCount: number;
  totalEntries: number;
  public showdata = [5, 10, 25, 50, 100, 500, 1000];
  schoolnamesadd: any=[];
  selectedItemIdemail: boolean;
  decodedLICId: string;
  activestatus: boolean;
  encodedLICId: any;

  constructor(public auth: FileService,
    public router: Router,
    public formBuilder: FormBuilder,
    private modalService: BsModalService,
    private dialogService: DialogService
  ) {
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 10,
    //   searching: false,
    //   scrollX: true,
    //   lengthChange: false
    // };
  }
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  @ViewChild('modalContent1') modalContent1: TemplateRef<any>;
  @ViewChild('modalContent2') modalContent2: TemplateRef<any>;
  @ViewChild('modalContent3') modalContent3: TemplateRef<any>;
  @ViewChild('cardElement') cardElementRef: ElementRef;

  ngOnInit(): void {
    document.body.addEventListener('click', this.handleBodyClick.bind(this));
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
    }
    this.teachers = this.formBuilder.group({
      selectedAdmin: [""]
    });
    localStorage.setItem('adminlisting', "teachers");

    this.adminrole = localStorage.getItem('adminrole')
    if (this.adminrole == 'regionaladmin') {
      this.regionname = localStorage.getItem('regionname')
      this.countryname = '';
      this.schoolname = '';
      this.updates()
    }else if (this.adminrole == 'countryadmin') {
      this.countryname = localStorage.getItem('countryname');
      this.regionname = localStorage.getItem('regionname')
      this.schoolname = '';
      this.updates()
    }else if (this.adminrole == 'schooladmin') {
      this.countryname = localStorage.getItem('countryname');
      this.regionname = localStorage.getItem('regionname')
      this.schoolname = localStorage.getItem('schoolname')
      this.updates()
    } else {
      this.countryname = '';
      this.regionname = ''
      this.schoolname = '';
    }
    this.getallschool()
    // this.getallcountrys()
    this.getallregionsandcountrys()
    this.getallteachers(this.pageLimit, this.pageNo)
  }


  reinitializeDatatable(data) {
    if (this.dtInitialied) {
      console.log(this.dtElement)
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.Allteachers = data;
        this.dtTrigger.next();
      });
    }
    else {
      this.dtInitialied = true;
      this.Allteachers = data;
      this.dtTrigger.next();
    }
  }
  getallschool() {
    this.schoolnames =[]
    this.schoolnamesadd =[]
    this.schoolnames.push('All schools')
    this.auth.getTVInformation({countryname: this.countryname,regionname: this.regionname}, '/getallschoolforadd')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                console.log(res, "ressssssssss")
                res.body.data.map(one => {
                  this.schoolnames.push(one.schoolname)
                  this.schoolnamesadd.push(one.schoolname)
                })
              } else {
              }
            } else {
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  getallcountrys() {
    this.auth.getTVInformation({}, '/getAllcountryadmin')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                //this.countrynames.push('All countries')
                res.body.data.map(one => {
                  this.countrynames.push(one.countryname)
                })
              } else {
              }
            } else {
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  getallregionsandcountrys() {
    this.auth.getTVInformation({}, '/getallregionsandcountrys')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                console.log(res.body.data ,"regioncountrymapping")
                this.Allregionandcountries = res.body.data
                // this.regionnames.push('All regions')
                // this.countrynames.push('All countries')
                res.body.data.map(one => {
                  this.regionnames.push(one.Region)
                  this.regionnamesadd.push(one.Region)
                  this.countrynames.push(one.Country)
                  this.updates();
                })
                this.regionnames = [... new Set(this.regionnames)]
                this.regionnamesadd = [... new Set(this.regionnamesadd)]
              } else {

              }
            } else {

              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  // updatecountrys(){
  //   this.countrynamesadd =[]
  //   this.Allregionandcountries.map(one => {
  //     if (this.data.regionname == one.Region) {
  //       this.countrynamesadd.push(one.Country)
  //     }
  //   })
  // }


  updatecountrys(){
    this.countrynamesadd =[];
    if(this.data&&!this.data.regionname){
      this.Allregionandcountries.map(one => {
        {
          this.countrynamesadd.push(one.Country)
        }
      })
    }else{
    this.Allregionandcountries.map(one => {
      if (this.data.regionname == one.Region) {
        this.countrynamesadd.push(one.Country)
      }
    })
  }
  }
  updates(){
    this.countrynames =[]
    //this.countrynames.push('All countries')
    this.Allregionandcountries.map(one => {
      if (this.regionname == one.Region) {
        this.countrynames.push(one.Country)
      }
    })
    this.getallschool()
    // this.getallteachers()
  }

  onSearchChange(searchValue: string) {
    if (searchValue.length > 0) {
      this.auth.getTVInformation({limit: this.pageLimit, index: this.pageNo, adminname: searchValue }, '/getallteachers')
        .then((res: any) => {
          if (res && res.body.data) {
            this.Allteachers = res.body.data;
            this.totalEntries = res.body.count;
            this.totalCount = Math.ceil(res.body.count / this.pageLimit);
          } else {
            this.Allteachers = [];
          }
        }, (err) => {
          console.error(err);
        });
    } else {
      // When the search input is cleared, load all data again
      this.getallteachers(this.pageLimit, this.pageNo);
    }
  }

  getallteachers(lmt, indx) {
    let data = {
      limit: lmt,
      index: indx,
      schoolname: this.schoolname,
      countryname: this.countryname,
      regionname: this.regionname
    }
    this.adminnames =[]
    this.teachers = this.formBuilder.group({
      selectedAdmin: [""]
    });
    this.auth.getTVInformation(data, '/getallteachers')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                this.nodata = false
                // this.reinitializeDatatable(res.body.data)
                console.log(res, "ressssssssss")
                this.Allteachers = res.body.data;
                this.totalEntries = res.body.count
                this.totalCount = Math.ceil(res.body.count / lmt)
                console.log(this.totalEntries, this.totalCount, "countt")
                res.body.data.map(one => {
                  this.adminnames.push(one.name)
                  // this.schoolnames.push(one.schoolname)
                })
              } else {
                this.nodata = true
              }
            } else {
              this.nodata = true
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  Addteachers() {
    this.countrynamesadd = []
    this.data ={}
    this.addclk = true
    this.editclk = false
    if (this.adminrole == 'countryadmin') {
      this.data.regionname = localStorage.getItem('regionname')
      this.updatecountrys()
      this.data.countryname = localStorage.getItem('countryname');
      this.getallschool()
      console.log(this.data,"dddd")
    }
    if (this.adminrole == 'regionaladmin') {
      this.data.regionname = localStorage.getItem('regionname')
      this.updatecountrys()
      this.getallschool()
    }
    if (this.adminrole == 'schooladmin') {
      this.data.countryname = localStorage.getItem('countryname');
      this.data.regionname = localStorage.getItem('regionname')
      this.data.schoolname = localStorage.getItem('schoolname')
      this.getregioncountrys()
      // this.updatecountrys()
    }
    this.save_exit_ref = this.modalService.show(this.modalContent, {
      class: 'modal-md-lg-sm',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
  }

  closeModal() {
    this.isModalOpen = false;
    this.save_exit_ref.hide();
  }
  AddLicense(){
    this.save_exit_ref.hide();
    this.save_exit_ref1 = this.modalService.show(this.modalContent1, {
      class: 'modal-md-lg-sm',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
  }

  closeModal1() {
    this.save_exit_ref1.hide();
  }

  SearchClick() {
    this.nodata = false
    let lmt =  this.pageLimit
    let indx = this.pageNo
    let data = {
      limit: lmt,
      index: indx,
      adminname: this.admin,
      schoolname: this.schoolname,
      countryname: this.countryname ,
      regionname: this.regionname
    }
    this.auth.getTVInformation(data, '/getallteachers')
      .then(
        (res: any) => {
          try {
            if (res) {
              console.log(res, "ressssssssss")
              this.Allteachers = res.body.data;
              this.totalEntries = res.body.count
              this.totalCount = Math.ceil(res.body.count / lmt)
              console.log(this.totalEntries, this.totalCount, "countt")
            } else {
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }

  openModal() {
    this.isModalOpen = true;
  }

  validateRegisterUser() {
    var errors_found = false;
    this.error = {};
    this.error.firstnameNotValid = false;
    this.error.schoolnameNotValid = false;
    this.error.emailidNotValid = false;
    this.error.regionnameNotValid = false;
    this.error.countrynameNotValid = false;
    this.error.passwordNotValid = false;
    this.error.confirmpasswordNotValid = false;

    this.data.firstname = this.data.firstname ? this.data.firstname.trim() : "";
    this.data.lastname = this.data.lastname ? this.data.lastname.trim() : "";
    this.data.schoolname = this.data.schoolname ? this.data.schoolname.trim() : "";
    this.data.emailid = this.data.emailid ? this.data.emailid.trim() : "";
    this.data.regionname = this.data.regionname ? this.data.regionname.trim() : "";
    this.data.countryname = this.data.countryname ? this.data.countryname.trim() : "";
    this.data.password = this.data.password ? this.data.password.trim() : "";
    this.data.confirmpassword = this.data.confirmpassword ? this.data.confirmpassword.trim() : "";

    if (this.data.firstname.length == 0) {
      this.error.firstnameNotValid = true;
      this.error.firstnameNotValidText = "Please enter the firstname";
      errors_found = true;
    }
    if (this.data.lastname.length == 0) {
      this.error.lastnameNotValid = true;
      this.error.lastnameNotValidText = "Please enter the lastname";
      errors_found = true;
    }

    if (this.data.schoolname.length == 0) {
      this.error.schoolnameNotValid = true;
      this.error.schoolnameNotValidText = "Please select the schoolname";
      errors_found = true;
    }
    if (this.data.regionname.length == 0) {
      this.error.regionnameNotValid = true;
      this.error.regionnameNotValidText = "Please select the regionname";
      errors_found = true;
    }
    if (this.data.countryname.length == 0) {
      this.error.countrynameNotValid = true;
      this.error.countrynameNotValidText = "Please select the countryname";
      errors_found = true;
    }
    if (this.data.emailid.length == 0) {
      this.error.emailidNotValid = true;
      this.error.emailidNotValidText = "Please enter the emailid";
      errors_found = true;
    }
    if (this.data.confirmpassword.length == 0) {
      this.error.confirmpasswordNotValid = true;
      this.error.confirmpasswordNotValidText = "Please enter the confirm password";
      errors_found = true;
    }

    if (this.data.password != this.data.confirmpassword) {
      this.error.confirmpasswordNotValid = true;
      this.error.confirmpasswordNotValidText = "Password and confirm password does not match";
      errors_found = true;
    }

    if (errors_found) {
      return;
    } else {
      if (this.addclk) {
        var self = this;
        self.AddLicense()
      }
      if (this.editclk) {
        this.saveEditedDetails()
      }
      // self.checkteachername(self, self.data)
      //   // .then((data) => self.saveRegisterUserDetails(self, data))
      //   .then((data) => self.AddLicense(self, data))
      //   .catch((data) => console.log(" Exception during handling promise: ", data))
    }
  }

  licenseback(){
    this.save_exit_ref1.hide();
    this.save_exit_ref = this.modalService.show(this.modalContent,{
      class: 'modal-md-lg-sm',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
  }

  licensestatus(e: any) {
    console.log(e)
    this.data.licensestatus = e
  }
  validatelicense(){
    var errors_found = false;
    this.error = {};
    this.error.orderidNotValid = false;
    // this.error.validityNotValid = false;
    this.error.licensetypeNotValid = false;
    this.error.licensestatusNotValid = false;
    this.error.startdateNotValid = false;
    this.error.enddateNotValid = false;

    this.data.startdate = this.data.startdate ? this.data.startdate.trim() : "";
    this.data.enddate = this.data.enddate ? this.data.enddate.trim() : "";
    this.data.orderid = this.data.orderid ? this.data.orderid.trim() : "";
    // this.data.validity = this.data.validity ? this.data.validity.trim() : "";
    this.data.licensetype = this.data.licensetype ? this.data.licensetype.trim() : "";
    this.data.licensestatus= this.data.licensestatus ? this.data.licensestatus.trim() : "";



    if (this.data.orderid.length == 0) {
      this.error.orderidNotValid = true;
      this.error.orderidNotValidText = "Please enter the orderid";
      errors_found = true;
    }
    // if (this.data.validity.length == 0) {
    //   this.error.validityNotValid = true;
    //   this.error.validityNotValidText = "Please enter the validity";
    //   errors_found = true;
    // }
    if (this.data.startdate.length == 0) {
      this.error.startdateNotValid = true;
      this.error.startdateNotValidText = "Please enter the startdate";
      errors_found = true;
    }

    if (this.data.enddate.length == 0) {
      this.error.enddateNotValid = true;
      this.error.enddateNotValidText = "Please enter the enddate";
      errors_found = true;
    }
    if (this.data.licensetype.length == 0) {
      this.error.licensetypeNotValid = true;
      this.error.licensetypeNotValidText = "Please enter the licensetype";
      errors_found = true;
    }

    if (this.data.licensestatus.length == 0) {
      this.error.licensestatusNotValid = true;
      this.error.licensestatusNotValidText = "Please enter the licensestatus";
      errors_found = true;
    }


    if (errors_found) {
      return;
    } else {
      var self = this;
      // this.saveRegisterUserDetails(self,self.data)
    }
  }

  checkteachername(self, data) {
    // data.self = this;
    return new Promise(
      (resolve, reject) => {
        self.auth
          .getTVInformation({ 'firstname': data.firstname }, '/checkuserteacher')
          .then(
            (res: any) => {
              console.log(res,"userrr")
              if (res.body.status === "ok") {
                resolve(data);
              } else {
                console.log("Checking teachername: ", data.firstname,
                  " response: ", res);
                self.registerStatus = 'fail';
                self.registerStatusMessage = res.body.message;
                self.showRegisterStatus = true;
                reject(data);
              }
            }, (err: any) => {
              console.log("Error checking teachername: ", data.firstname,
                " response: ", err);
              reject(data);
            });
      });
  }


  saveRegisterUserDetails() {
    var self = this;
    var data = self.data
    return new Promise(
      (resolve, reject) => {
        self.auth
          .getTVInformation(
            {
              schoolname: data.schoolname,
              password: data.password,
              firstname:data.firstname,
              lastname:data.lastname,
              countryname: data.countryname,
              regionname: data.regionname,
              address: data.address,
              orderid: data.orderid,
              // validity:data.validity,
              startdate :data.startdate,
              enddate:data.enddate,
              licensetype:data.licensetype,
              licensestatus:data.licensestatus,
              emailid: data.emailid,
              LICId:  this.encodedLICId,
                  //for ums
                  userId : data.emailid,
                  //password
                  name : data.firstname + " " + data.lastname,
                  role :'teacher',
                  profileInfo : ' ',
                  signature:' '
              // superadmin_id : localStorage.getItem('superadmin_id'),
            }
            , '/createteacher')
          .then((res: any) => {
            try {
              console.log("Statuscheck: ", res);
              if(res.body.status == 'success'){
              self.registerStatus = res.body.status;
              self.registerStatusMessage = res.body.reason;
              self.showRegisterStatus = true;
              resolve(data);
              this.getallteachers(this.pageLimit, this.pageNo)
              this.save_exit_ref1.hide();
              // this.router.navigate(['/superadmin'])
            } else if (res.body.status == false) {
              if (res.body.reason == 'wrongpassword') {
                this.dialogService.showError("Email ID already exists. Please use the same password.")
              }
              if (res.body.reason == 'sameuser') {
                this.dialogService.showError("The same email ID exists. Please use another one.")
              }
            } else {
              reject(data);
            }
            } catch (e) {
              console.error(e);
              reject(data);
            }
          }, (err: any) => {
            console.error(err);
            reject(data);
          })
      });
  }

  handleTdClick(event: any, itemId: any ,name :any ,Event:Event) {
    // Toggle the selectedItemId to show/hide the card for the clicked row
    Event.stopPropagation();
    this.selectedItemId = this.selectedItemId === itemId ? null : itemId;
    this.selectedItemName = this.selectedItemName === name ? null : name;
    this.selectedItememail = event.emailid
    this.clickedRowCoordinates = { top: event.clientY, left: event.clientX };
    this.selectedItemIdemail = itemId
    console.log(this.selectedItemIdemail,"idd")
    // Additional logic if needed
  }

  delete() {
    this.dltclk = true
    console.log(this.selectedItemId)
    console.log("General Delete");
    if (this.selectedItemId) {
      var yes = confirm("Are you sure you want to delete Admin? = " + this.selectedItemName);
      console.log(yes)
      if (yes == true) {
        this.auth.getTVInformation(
          { selectedid: this.selectedItemId ,userId:this.selectedItememail}, '/deleteteacher').then((res: any) => {
            try {
              if (res && res.status) {
                console.log(res.data, "inside dlt")
                this.dialogService.showSimplePopup("Deleted successfully.");
                this.getallteachers(this.pageLimit, this.pageNo)
              } else {
                console.error("General error");
              }
            } catch (e) {
              console.error(e);
            }
          }, (err: any) => {
            console.error(err);
          });
      } else {
        // this.AdminCheckboxListArray = [];
      }
    }
    else {
      this.dialogService.showError("Select something to delete!")
    }

  }


  edit(){
    console.log("Calling WOEditClick HTTP server regionnn")
    this.editclk = true
    this.addclk = false
    this.auth.getTVInformation({ 'id': this.selectedItemId }, '/getteacher').then((res: any) => {
      try {
        console.log(res, "edit ress region")
        if (res) {
          if (res.body.status === true) {
            this.save_exit_ref = this.modalService.show(this.modalContent, Object.assign({}, { class: "modal-md-lg-sm" }));
            this.data = res.body.data;
            this.data['firstname'] = res.body.data['firstname'];
            this.data['lastname'] = res.body.data['lastname'];
            this.data['emailid'] = res.body.data['emailid'];
            this.data['address'] = res.body.data['address'];
            this.data['countryname'] = res.body.data['countryname'];
            this.data['confirmpassword'] = res.body.data['password'];
            this.updatecountrys()
            console.log("Selected resData2: ", this.data);
          }
        } else {
          this.data = {};
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  saveEditedDetails() {
    console.log("saveBPDetails Data : ", this.data);
    this.auth.getTVInformation({"data":this.data}, '/editteacher').then((res: any) => {
      try {
        console.log("Status: ", res);
        if (res.body.status == true) {
          // this.router.navigate(['/countryadmin'])
          this.save_exit_ref.hide()
          this.getallteachers(this.pageLimit, this.pageNo)
        } else {
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  EditCancel(){
    this.save_exit_ref.hide()
  }
  mangageEmailsById:any;
  manageemail(id){
    this.save_exit_ref2 = this.modalService.show(this.modalContent2, Object.assign({}, { class: "modal-md-lg-sm" }));
    this.mangageEmailsById=id;
    this.getemaillist()
  }
  getemaillist(){
    this.auth.getTVInformation({ 'id': this.selectedItemIdemail }, '/getteacher').then((res: any) => {
      try {
        console.log(res, "edit ress region")
        if (res) {
          if (res.body.status === true) {
            this.data = res.body.data;
          }
        }
      }catch(e){

      }
    })
  }

  closeModal2() {
    this.save_exit_ref2.hide();
  }

  AddEmail(){
    // this.save_exit_ref2.hide();
    this.addemailclk = true
    this.editemailclk = false
    this.save_exit_ref3 = this.modalService.show(this.modalContent3, Object.assign({}, { class: "modal-md-lg-sm" }));
  }
  closeModal3() {
    this.save_exit_ref3.hide();
  }
  SaveEmail(){
     console.log(this.data.emailtoadd , this.data , 'chkkk')
     this.auth.getTVInformation({"data":this.data}, '/editteacher').then((res: any) => {
      try {
        console.log("Status: ", res);
        if (res.body.status == true) {
          this.save_exit_ref3.hide()
          this.getemaillist()
        } else {
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }
  EditSaveEmail(){
   console.log(this.currentEmailId ,this.data.newEmailId, this.selectedItemId,"editsavee")
   this.auth.getTVInformation({ 'id': this.selectedItemIdemail ,'currentemail': this.currentEmailId ,'newemail':this.data.newEmailId}, '/editteacheremail').then((res: any) => {
    try {
      console.log(res, "emailtoeditt")
      if (res) {
        if (res.body.status === true) {
          this.dialogService.showSimplePopup('Changes saved successfully.')
          this.closeModal3()
          this.getemaillist()
        }else{
          this.dialogService.showError('Changes not saved. Please try again later.')
        }
      }
    }catch(e){
      console.log(e)
      this.dialogService.showError('Changes not saved. Please try again later.')
    }
  })

  }
  getregioncountrys(){
    console.log(this.data.schoolname,"school")
    this.auth.getTVInformation({ 'schoolname': this.data.schoolname }, '/getschooll').then((res: any) => {
      try {
        console.log(res, "get scholll for country")
        if (res) {
          if (res.body.status === true) {
             this.data.regionname = res.body.data.regionname
             this.data.countryname =res.body.data.countryname
             //extract license details from selected school
             this.data.startdate =res.body.data.startdate
             this.data.enddate = res.body.data.enddate
             this.data.orderid =res.body.data.orderid
             this.data.licensetype = res.body.data.licensetype
             this.encodedLICId = res.body.data.LICId; // This should be the base64-encoded value
             this.decodedLICId = atob(this.encodedLICId);
             console.log(this.decodedLICId);  // This will print the decoded value
             this.data.licensestatus= res.body.data.licensestatus
            if (this.data.licensestatus == 'active') {
              this.activestatus = true
            } else {
              this.activestatus = false
            }
          }
        }
      }catch(e){

      }
    })
  }
  clearFilters(){
    this.admin = null
    if (this.adminrole == 'regionaladmin') {
      this.regionname = localStorage.getItem('regionname')
      this.countryname = '';
      this.schoolname = '';
      this.updates()
    }else if (this.adminrole == 'countryadmin') {
      this.countryname = localStorage.getItem('countryname');
      this.regionname = localStorage.getItem('regionname')
      this.schoolname = '';
      this.updates()
    }else if (this.adminrole == 'schooladmin') {
      this.countryname = localStorage.getItem('countryname');
      this.regionname = localStorage.getItem('regionname')
      this.schoolname = localStorage.getItem('schoolname')
      this.updates()
    } else {
      this.countryname = '';
      this.regionname = ''
      this.schoolname = '';
    }
    this.SearchClick()
  }
  EditEmail(item){
   console.log(this.selectedItemId,item,"checkkk")
   this.currentEmailId = item
   this.addemailclk = false
   this.editemailclk = true
   this.save_exit_ref3 = this.modalService.show(this.modalContent3, Object.assign({}, { class: "modal-md-lg-sm" }));
  }
  DeleteEmail(item){
    console.log(this.selectedItemId,item,"deletechkk")
    console.log(this.data.schoolname,"school")
    this.auth.getTVInformation({ 'id': this.mangageEmailsById ,'email': item }, '/deleteteacheremail').then((res: any) => {
      try {
        console.log(res, "emailtodlttt")
        if (res) {
          if (res.body.status === true) {
            this.dialogService.showSimplePopup('Deleted successfully.')
            this.getemaillist()
          }
        }
      }catch(e){
        console.log(e)
      }
    })
  }
  clearcross(fieldName: string) {
    // Reset the value of the specified field to an empty string
    this.data[fieldName] = '';
  }

  onOverlayClick(event: MouseEvent): void {
    // Handle the click event inside the modal overlay
    // For example, prevent the modal from closing
    event.stopPropagation();
  }

  handleBodyClick(event: Event) {
    const target = event.target as HTMLElement;

    // Check if the click is outside the card
    if (this.selectedItemId) {
      if (!this.cardElementRef.nativeElement.contains(target)) {
        this.selectedItemId = null; // Hide the card
      }
    }
  }

  //server side pagination
  prevPage() {
    let temp = this.pageNo
    temp -= 1
    if (temp >= 1) {
      this.pageNo -= 1
      this.getallteachers(this.pageLimit, this.pageNo);;
    }

  }

  nextPage() {
    console.log(this.pageNo, "nextpageeeeeeee")
    let tmp = Number(this.pageNo) + 1
    //console.log(tmp,this.totalCount,"mmmmmmmmmmmm")
    if (tmp <= this.totalCount) {
      this.pageNo = Number(this.pageNo) + 1
      this.getallteachers(this.pageLimit, this.pageNo);
    }
  }



  nextPageInput() {
    let page_input = this.pageNo;
    if ((page_input <= this.totalCount) && (page_input >= 1)) {
      this.getallteachers(this.pageLimit, this.pageNo);
    } else {
      this.getallteachers(this.pageLimit, this.pageNo);
    }
  }

  PageSize(val) {
    this.pageNo = 1
    this.getallteachers(this.pageLimit, this.pageNo);
  }

  booleanValues: { [key: string]: boolean } = {};
  sortedColumn: string = 'name';
  sortFunction(colName: string) {
    if (this.booleanValues[colName] === undefined) {
      this.booleanValues[colName] = true;
    }

    if (this.booleanValues[colName]) {
      this.Allteachers.sort((a, b) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0);
    } else {
      this.Allteachers.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0);
    }

    this.booleanValues[colName] = !this.booleanValues[colName];
    this.sortedColumn = colName;
  }
}

