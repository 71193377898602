import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FabricjsEditorComponent } from 'projects/angular-editor-fabric-js/src/public-api';
import { Observable, Subject } from 'rxjs';
import { FileService } from '../file.service';
//let html2canvas = require('../../../node_modules/html2canvas');

@Component({
  selector: 'app-webupload',
  templateUrl: './webupload.component.html',
  styleUrls: ['./webupload.component.scss']
})
export class WebuploadComponent implements OnInit {
//   @ViewChild('canvas', { static: false }) canvas: FabricjsEditorComponent;
//   public onClose: Subject<any>;
  
//   constructor() { }

//   ngOnInit(): void {
//     this.onClose = new Subject();
   
//   }

// // public addwebpage(){
// //   console.log("calling from webupload")
// //   this.canvas.addwebpage();
// // }




webSites = []
searchInfo: any
serachInformation = []
isSearchResult: boolean = false;
public onClose: Subject<any>;
@ViewChild('screen') screen: any;
//@Output() webOpened: EventEmitter<string> = new EventEmitter();
//@Output() checkURLs: EventEmitter<string> = new EventEmitter();
isSelectedWeb: boolean = false;
selecteWebsite: any;
constructor(public bsModalRef: BsModalRef,private fileService: FileService) { }

ngOnInit(): void {
  this.searchGoogleAudio()
  this.onClose = new Subject();
}

closeWebPart() {
  //this.webOpened.emit("false");
}

backInfo() {
  let index = this.serachInformation.indexOf(this.searchInfo)
  console.log(index, this.serachInformation[index - 1], this.serachInformation)
  if(index){
    this.searchInfo = this.serachInformation[index - 1]
    this.searchWebsites()
  }    
}

frontInfo() {
  let index = this.serachInformation.indexOf(this.searchInfo)
  console.log(index, this.serachInformation[index + 1], this.serachInformation, this.serachInformation.length)
  if(index && index < this.serachInformation.length - 1){
    this.searchInfo = this.serachInformation[index + 1]
    this.searchWebsites()
  }
  
  if(index == 0 && this.serachInformation.length > 1){
    this.searchInfo = this.serachInformation[index + 1]
    this.searchWebsites()
  }
}


fullscreen(){
  console.log('LLLLLLLLLLLLLLLLLLLLLLLL')
  if(!this.selecteWebsite){
    alert('please select website info')
  }
  console.log("fullscreenin dailog")
   let elem =  document.querySelector('#mainContainer');
   let methodToBeInvoked = elem.requestFullscreen || 
   elem["webkitRequestFullScreen"] || elem['mozRequestFullscreen'] 
   || elem['msRequestFullscreen']; 

   if(methodToBeInvoked) methodToBeInvoked.call(elem);
}

searchWebsites() {
  if(this.searchInfo){
  // if(this.isValidUrl(this.searchInfo)){
  //   console.log("URL is Valid")
  //   this.selctWebsite(this.searchInfo)
  // } else {
    var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
if(this.searchInfo.match(urlRegex)   )
{
this.isSelectedWeb = true
  this.selecteWebsite = this.searchInfo;
  // console.log('&&&&&&&&&&&&777', data)
  // this.auth.getWebsites(data.displayLink, data.pagemap.metatags[0].site).subscribe(res => {
  //   console.log('OOOOOOOOOOOOOOOOOOOOO', res)
  // })
  
  this.isSearchResult = false
}
else{
    this.fileService.getTVInformation({ search: this.searchInfo }, '/googleSearch').then((res: any) => {
      try {
        this.isSelectedWeb=false;
        this.isSearchResult = true
        this.webSites = res.body;
        if (!this.serachInformation.includes(this.searchInfo)) {
          this.serachInformation.push(this.searchInfo)
        }
        console.log(',,,,,,,,,,,,,,,,,,,,,,', res)
      } catch (e) {
        console.error(e);
      }
    })
  // }
  
}
}
}

searchGoogleAudio() {
  this.fileService.getTVInformation({}, '/googleAudio').then((res: any) => {
    try {
      console.log(',,,,,,,,,,,,,,,,,,,,,,', res)
    } catch (e) {
      console.error(e);
    }
  })
}
selctWebsite(data) {
  console.log("sameer",data)
  this.isSelectedWeb = true
  this.selecteWebsite = data.link;
  // console.log('&&&&&&&&&&&&777', data)
  // this.auth.getWebsites(data.displayLink, data.pagemap.metatags[0].site).subscribe(res => {
  //   console.log('OOOOOOOOOOOOOOOOOOOOO', res)
  // })
  console.log('kesav',data);
  this.isSearchResult = false
}
closeWebPartSelected() {
  this.isSelectedWeb = false
  this.selecteWebsite = ""
  this.isSearchResult = true
}

NewTab() {
  window.open("https://chrome.google.com/webstore/detail/ignore-x-frame-headers/gleekbfjekiniecknbkamfmkohkpodhe/related", "_blank");
}

isValidUrl(_string){
const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
return matchPattern.test(_string);
}



// async save1(imgBase64) {
//   const file = this.dataURLtoFile(imgBase64, 'filename.png')
//   this.fileService.uploadMenuImage("profileImage", file, '/etherpad/uploads', '').subscribe((res: any) => {
//     let reslt = res._body;
//     console.log(reslt)
//     this.imageUploadToEtherPad(reslt)
//   })
// }

dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// save(fileName) {
//   // First we get our section to save from dom
//   let section: HTMLElement = document.querySelector('#mainContainer');
//   let body1 = $('#mainContainer').contents().find("body");
//   var $element = $(body1.html());
//   // var body = $(iframe).contents().find('body')[0];
//   // We pass that section to html2Canvase
//   console.log($element[0])

//   // let section = document.querySelector('#mainContainer');
//   html2canvas(section).then(canvas => {
//     console.log(canvas.toDataURL())
//     var link = document.createElement('a'); 
//     this.save1(canvas.toDataURL())
//     link.href = canvas.toDataURL();
//     link.download = fileName;
//     document.body.appendChild(link);
//     link.click();
//   });
// }

imageUploadToEtherPad(image) {
  if(!image){
    alert('please select website info')
  }

  let info = {
    type:"URL",
    data: image
}

  this.emitMessageToEtherpad(info)

  // this.auth.getTVInformation({ image: image }, '/setetherpadFootNoteURL').then((res: any) => {
  //   try {
  //     console.log(res)
  //     // this.respResult = res;
  //   } catch (e) {
  //     console.error(e);
  //   }
  // })
}
w=900;
h=420;
selectedFrame='medium';
sizeofIframe(width,height,select){
this.w=width;
this.h=height;
this.selectedFrame=select;
}
imageUploadToEtherPadAsIfram(image){
  // if(!image){
  //   alert('please select website info')
  // }
  // let width = this.w;
  //     let height = this.h;
  //     let info = {
  //         type:"IFRAME",
  //         data: image,
  //         width: width,
  //         height: height
  //     }
  //     console.log("whatis",info,this.w,this.h,width,height);
  // this.emitMessageToEtherpad(info)
  console.log("addwebsite",image)
  this.onClose.next(image);
}

emitMessageToEtherpad(URL){
  console.log("inside emit message func",URL)
  this.onClose.next(URL);
  //this.checkURLs.emit(URL);
}

closemodal(){
  this.bsModalRef.hide();
}

close(){
  this.onClose.next();
}

}