import { Component, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FileService } from '../file.service';
import { FormBuilder } from '@angular/forms';
import { QRloginComponent } from './../qrlogin/qrlogin.component';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { DialogService } from '../dialog.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-demoemail',
  templateUrl: './demoemail.component.html',
  styleUrls: ['./demoemail.component.scss']
})
export class DemoemailComponent implements OnInit {


  constructor(public router: Router, public fileservice: FileService, private formBuilder: FormBuilder, private route: ActivatedRoute, private sanitizer: DomSanitizer, private dialogService: DialogService, private modalService: BsModalService) { }
  @ViewChild(QRloginComponent, { static: false }) qrLoginComponent: QRloginComponent;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  public modals_exit_ref: BsModalRef;
  @Output() closedemoform: EventEmitter<string> = new EventEmitter();

  // public data: any = {};
  showextralinks: Boolean;
  data = {
    regionnameadd: '',
    countrynameadd: '',
    firstname: '',
    lastname: '',
    emailid: '',
    institutename: '',
    function: '',
    city: '',
    state: ''
  };
  url: any = environment.joinavurl;
  Allregionandcountries: any =[];
  regionnames: any =[];
  isInternetConnected:any;

  ngOnInit(): void {

    this.fileservice.getConnectionStatus().subscribe((status) => {
      this.isInternetConnected = status;
      console.log(this.isInternetConnected,"Bandana Checking internet connection everytime");
      if(!status&&this.webpageshow===true){
        this.modals_exit_ref.hide();
      }
    });
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes("android");
    const isChrome = userAgent.includes("chrome");
    const isMozilla = userAgent.includes("mozilla");
    const isSafari = userAgent.includes("safari");
    const isgecko = userAgent.includes("gecko")

    console.log(userAgent);

    if (isAndroid === true && isChrome === false && isMozilla === false && isSafari === false && isgecko === false) {
      this.showextralinks = false
    } else {
      this.showextralinks = true;
    }


    //this.showextralinks=false

    this.getallregionsandcountrys()
  }


  /*  sendDemoPasswordEmail() {
      console.log('username entered', this.data.emailid)

      const resetlink = `${this.url}`;
      console.log('resetlink', resetlink);

      if (!this.data.emailid) {
        alert('Please enter the emailid');
        return false
      }

      console.log('inside the if statement')
      this.fileservice.sendDemoPasswordEmail(this.data.emailid, resetlink).subscribe(
        (response) => {
          console.log('Forgot password email sent successfully', response);
          alert('Email sent successfully.')
        },
        (error) => {
          if (error.status === 400 && error.error.error === 'Invalid emailId provided') {
            alert('Please Enter Valid EmailId!');
          } else {
            console.error('Error sending demo password email:', error);
            alert("Failed!")
            // alert('Username does not exist. Enter existing username');
          }
        }
      );

    }*/

  emailbtndisable: Boolean = false

  sendDemoPasswordEmail() {

    this.emailbtndisable = true;
    console.log('Form Submitted!', this.data);
    // Your code to handle form submission
    const resetlink = `${this.url}`;
    console.log('resetlink', resetlink);
    this.fileservice.demoaccountvalidation(this.data.emailid).subscribe(
      (val: any) => {
        console.log("value", val)
        if (val.data.result === 200) {
          this.fileservice.setdemoaccountsviaemail(this.data).subscribe(      // adding data in db
            (val: any) => {
              this.fileservice.sendDemoPasswordEmail(this.data.emailid, resetlink, this.data.firstname).subscribe(     //sending email
                (response) => {

                  console.log('Forgot password email sent successfully', response);
                  this.dialogService.showSimplePopup('Email sent successfully')

                  this.showForm = false;

                  this.data = {
                    regionnameadd: '',
                    countrynameadd: '',
                    firstname: '',
                    lastname: '',
                    emailid: '',
                    institutename: '',
                    function: '',
                    city: '',
                    state: ''
                  };
                  this.emailbtndisable = false;
                },
                (error) => {
                  if (error.status === 400 && error.error.error === 'Invalid emailId provided') {
                    this.dialogService.showError('Please enter a valid Email ID.');
                  } else {
                    console.error('Error sending demo password email:', error);
                    this.dialogService.showError("Failed to send email.")
                    // alert('Username does not exist. Enter existing username');
                  }
                  this.data = {
                    regionnameadd: '',
                    countrynameadd: '',
                    firstname: '',
                    lastname: '',
                    emailid: '',
                    institutename: '',
                    function: '',
                    city: '',
                    state: ''
                  };
                  this.emailbtndisable = false;
                }
              );

            }
          ), (error: any) => {
            this.dialogService.showError("Internal server error. Please try again later.")

            console.log("An error occured while inserting data in demoaccount ", error)
          }
        } else {

          this.dialogService.showError(val.data.message)
          this.emailbtndisable = false;
        }

        console.log(val)

      }, (error: any) => {
        this.dialogService.showError(error.error.message)
        console.error("An error occurred", error);
        this.emailbtndisable = false;


      }
    );

  }





  item: any
  countrynames: any = [];
  getallregionsandcountrys() {
    console.log("getting countrys");
    this.fileservice.fetchCountries().subscribe(
      (val: any) => {
        this.Allregionandcountries = val.data
        console.log("Countries fetched successfully", val);
        this.countrynames = val.data.map(item => item.Country);
        this.regionnames = val.data.map(item => item.Region);
        this.regionnames = this.regionnames.sort();
        this.countrynames = this.countrynames.sort();
        this.regionnames = [... new Set(this.regionnames)]
        console.log(this.countrynames);

      },
      (error: any) => {

        console.error("An error occurred while fetching countries", error);

      }
    );
  }

  closeButton() {
    console.log("hello")
    if(this.showextralinks){
    this.router.navigate(['/qrlogin']);
    }else{
      this.closedemoform.emit();
    }
  }
  showstatefield: Boolean = false;
  countrychange() {
    console.log(this.data.countrynameadd);
    if (this.data.countrynameadd === "United States of America") {
      this.showstatefield = true;
      this.getstatesbycountry(this.data.countrynameadd);

    } else {
      this.showstatefield = false;
    }
  }

  states: any = [];
  getstatesbycountry(country) {
    console.log("getting countrys");
    this.fileservice.fetchStatesbyCountry(country).subscribe(
      (val: any) => {

        console.log("Countries fetched successfully", val);
        this.states = val.data.map(item => item.state);
        this.states = this.states.sort();
        console.log(this.states);

      },
      (error: any) => {

        console.error("An error occurred while fetching states", error);

      }
    );
  }



  showForm: Boolean = true;



  webpageshow: Boolean = false;
  webpageurl: any
  openurl(value: any) {
    this.webpageurl = this.sanitizer.bypassSecurityTrustResourceUrl(value);
    console.log("webpageurl", this.webpageurl)
    this.webpageshow = true
    this.isMaximized = false;
    this.modals_exit_ref = this.modalService.show(this.modalContent, {
      class: 'setHeight',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
  }



  closewebpageview() {
    this.webpageshow = false;
  }



  openInNewTab(url: string) {
    window.open(url, '_blank');
  }


  isMaximized = false;
  maximizeWindow() {
    console.log("maximizeWindow")
    const windowDiv = document.querySelector('.webpage') as HTMLElement;
    if (windowDiv) {
      windowDiv.style.width = '100vw';
      windowDiv.style.height = '100vh';
      windowDiv.style.top = '0';
      windowDiv.style.left = '0';
      windowDiv.style.borderRadius = '0';
      this.isMaximized = true;
    }
  }

  //showForm:Boolean=true;

  minimizeWindow() {
    console.log("minimizeWindow")
    const windowDiv = document.querySelector('.webpage') as HTMLElement;
    if (windowDiv) {
      windowDiv.style.width = '60%';
      windowDiv.style.height = '80%';
      windowDiv.style.top = '50px';
      windowDiv.style.left = '50px';
      windowDiv.style.borderRadius = '5px';
      this.isMaximized = false;
    }
  }




  //////////////////////////New Fuctions added for step form////////////////////
  showformpart: number = 1;

  next() {
    this.showformpart = this.showformpart + 1
  }


  backformbtn() {
    if (this.showformpart === 1) {
      this.router.navigate(['/qrlogin']);
    } else {
      this.showformpart = this.showformpart - 1
      if (this.showformpart < 3) {
        this.shownextbtn = true;
      }
    }
  }

  shownextbtn = true
  valid: Boolean = true


  checkdisabledbtn() {
    if (this.showformpart === 1) {
      // if(this.data.firstname&&this.data.lastname&&this.data.emailid&&(Object.keys(this.errors).length === 0))
      if (
        this.data.firstname &&
        this.data.lastname &&
        this.data.emailid &&
        (!this.errors.hasOwnProperty('firstname') &&
          !this.errors.hasOwnProperty('lastname') &&
          !this.errors.hasOwnProperty('email'))
      ) {
        return false
      } else {
        return true
      }
    }
    if (this.showformpart === 2) {
      if (this.data.institutename && this.data.function &&
        (!this.errors.hasOwnProperty('institutename') &&
          !this.errors.hasOwnProperty('function')
        )) {
        return false
      } else {
        return true
      }
    }
    if (this.showformpart === 3) {
      if (this.showstatefield) {
        if (this.data.regionnameadd && this.data.countrynameadd && this.data.city && this.data.state &&
          (!this.errors.hasOwnProperty('regionnameadd') &&
           !this.errors.hasOwnProperty('countrynameadd') &&
            !this.errors.hasOwnProperty('city') &&
            !this.errors.hasOwnProperty('state')
          )) {
          console.log("jjjj")
          this.shownextbtn = false
          // return false

        } else {
          this.shownextbtn = true;
          return true
        }
      }
      else if (this.data.regionnameadd && this.data.countrynameadd && this.data.city && (!this.errors.hasOwnProperty('regionnameadd') &&
        !this.errors.hasOwnProperty('countrynameadd') &&
        !this.errors.hasOwnProperty('city')

      )) {
        // console.log("ppppp")

        this.shownextbtn = false
        // return false

      } else {
        // console.log("cccc")

        this.shownextbtn = true;
        return true
      }
    }
  }




  validateEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  errors: {}

  checkerrors(type: string, value: boolean): void {
    console.log("checkerrors")
    if (!this.errors) {
      this.errors = {};
    }

    if (value) {

      this.errors[type] = value;
    } else {

      if (this.errors.hasOwnProperty(type)) {
        delete this.errors[type];
      }
    }
    // console.log(this.errors, "getting errors")
  }

  validateEmailOnChange(emailid: any): void {
    const isValid = this.validateEmail(this.data.emailid);

    // Set custom validation errors based on email validity
    emailid.control.setErrors(!isValid ? { email: true } : null);

    // Call checkerrors to further handle other validations
    this.checkerrors("email", emailid.invalid);
  }

  closeModal() {

    this.modals_exit_ref.hide();
    this.webpageshow = false;
  }

  regionchange() {
    this.countrynames = []
    this.Allregionandcountries.map((one: { Region: any; Country: any; }) => {
      if (this.data.regionnameadd == one.Region) {
        this.countrynames.push(one.Country)
      }
    })
  }


  checkConnection(){
    console.log("checkconnection");
    this.fileservice.getConnectionStatus().subscribe((status) => {
      this.isInternetConnected = status;
      console.log(this.isInternetConnected,"Bandana Checking internet connection everytime");
    });
  }
}



