import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FileService } from '../file.service';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AbstractControl, FormGroup, FormsModule, FormControl, FormArray, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RegionaladmineditComponent } from '../regionaladminedit/regionaladminedit.component';
import { LayoutModule } from '@angular/cdk/layout';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-regionaladmin',
  templateUrl: './regionaladmin.component.html',
  styleUrls: ['./regionaladmin.component.scss']
})
export class RegionaladminComponent implements OnInit {
  public save_exit_ref: BsModalRef;
  bsModalRef: BsModalRef;
  Allregionaladmin: any = [];
  position = "absolute";
  Regional: FormGroup;
  AdminCheckboxListArray: any = [];
  adminnames: any = [];
  admin: any;
  region: any;
  isModalOpen: boolean = false;
  public data: any = {};
  public error: any = {};
  public registerStatus;
  public registerStatusMessage;
  public showRegisterStatus = false;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dtInitialized: boolean = false;
  nodata: boolean = true;
  public regionaladmineditcomp: RegionaladmineditComponent
  dltclk: boolean = false;
  public hideTable = true;
  selectedItemId: any;
  clickedRowCoordinates: { top: number, left: number } = { top: 0, left: 0 };
  selectedItemName: any;
  editclk: boolean = false;
  addclk: boolean = false;
  regionnames: any= [];countrynames: any= [];
  countryname: string;
  regionname: string;
  countrynamesadd: any = [];
  regionnamesadd: any = [];
  Allregionandcountries: any =[];
  selectedItememail: any;
  hide = true;
  hide1 = true;
  public pageNo: number = 1;
  public pageLimit: number = 5
  public totalCount: number;
  totalEntries: number;
  public showdata = [5, 10, 25, 50, 100, 500, 1000];

  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  constructor(public auth: FileService,
    public router: Router,
    public formBuilder: FormBuilder,
    private modalService: BsModalService,
    private dialogService: DialogService
  ) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2,
      // searching: false,
      lengthMenu: [10, 25, 50, 100, 500, 1000],
    };
    this.dtInitialized = false;
    this.hideTable = true;
  }
  @ViewChild('cardElement') cardElementRef: ElementRef;
  
  ngOnInit(): void {
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 5,
    //   processing: true,
    // }
    document.body.addEventListener('click', this.handleBodyClick.bind(this));
    console.log('regional called...')
    localStorage.setItem('adminlisting', "RegionalAdmin");
    this.region = 'All regions'
    this.getallRegionalAdmins(this.pageLimit, this.pageNo)
    this.getallregionsandcountrys()
    this.Regional = this.formBuilder.group({
      selectedAdmin: [""]
    });
  }


  async reinitializeDatatable(data) {
    if (this.dtInitialized) {
      try {
        console.log(this.dtElement, "dtttt");
        const dtInstance: DataTables.Api = await this.dtElement.dtInstance;
        dtInstance.destroy();
      } catch (error) {
        console.error("Error destroying DataTable:", error);
      }
    }
  
    this.Allregionaladmin = data;
    this.dtTrigger.next();
  }

  onSearchChange(searchValue: string) {
    if (searchValue.length > 0) {
      this.auth.getTVInformation({limit: this.pageLimit, index: this.pageNo, adminname: searchValue }, '/getallregionaladmin')
        .then((res: any) => {
          if (res && res.body.data) {
            this.Allregionaladmin = res.body.data;
            this.totalEntries = res.body.count;
            this.totalCount = Math.ceil(res.body.count / this.pageLimit);
          } else {
            this.Allregionaladmin = [];
          }
        }, (err) => {
          console.error(err);
        });
    } else {
      // When the search input is cleared, load all data again
      this.getallRegionalAdmins(this.pageLimit, this.pageNo);
    }
  }

  getallRegionalAdmins(lmt, indx) {
    let data = {
      limit: lmt,
      index: indx,
    }
    this.adminnames =[]
    this.Regional = this.formBuilder.group({
      selectedAdmin: [""]
    });
    this.auth.getTVInformation(data, '/getallregionaladmin')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                this.nodata = false
                console.log(res, "ressssssssss")
                this.Allregionaladmin = res.body.data;
                // this.reinitializeDatatable(res.body.data);
                this.totalEntries = res.body.count
                this.totalCount = Math.ceil(res.body.count / lmt)
                console.log(this.totalEntries, this.totalCount, "countt")
                res.body.data.map(one => {
                  this.adminnames.push(one.name)
                  // this.regionnames.push(one.regionname)
                })
              } else {
                this.nodata = true
              }
            } else {
              this.nodata = true
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  AddRegionalAdmin() {
    // this.router.navigate(['regionaladminedit/newentry'])
    // this.isModalOpen = true;
    this.data = {}
    this.editclk = false;
    this.addclk = true
    this.save_exit_ref = this.modalService.show(this.modalContent, {
      class: 'modal-md-lg-sm',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
    
  }
  closeModal() {
    this.isModalOpen = false;
    this.save_exit_ref.hide();
  }

  SearchClick() {
    this.nodata = false
    this.auth.getTVInformation({limit:this.pageLimit,index:this.pageNo, adminname: this.admin, regionname: this.region }, '/getallregionaladmin')
      .then(
        (res: any) => {
          try {
            if (res) {
              console.log(res, "ressssssssss")
              this.Allregionaladmin = res.body.data;
              this.totalEntries = res.body.count
              this.totalCount = Math.ceil(res.body.count / this.pageLimit)
            } else {
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }

  openModal() {
    this.isModalOpen = true;
  }

  validateRegisterUser() {
    var errors_found = false;
    this.error = {};
    this.error.firstnameNotValid = false;
    this.error.regionnameNotValid = false;
    this.error.emailidNotValid = false;
    this.error.lastnameNotValid = false;
    this.error.addressNotValid = false;
    this.error.passwordNotValid = false;
    this.error.confirmpasswordNotValid = false;

    this.data.firstname = this.data.firstname ? this.data.firstname.trim() : "";
    this.data.lastname = this.data.lastname ? this.data.lastname.trim() : "";
    this.data.address = this.data.address ? this.data.address.trim() : "";
    this.data.regionname = this.data.regionname ? this.data.regionname.trim() : "";
    this.data.emailid = this.data.emailid ? this.data.emailid.trim() : "";
    this.data.password = this.data.password ? this.data.password.trim() : "";
    this.data.confirmpassword = this.data.confirmpassword ? this.data.confirmpassword.trim() : "";


    if (this.data.firstname.length == 0) {
      this.error.firstnameNotValid = true;
      this.error.firstnameNotValidText = "Please enter the firstname";
      errors_found = true;
    }
    if (this.data.lastname.length == 0) {
      this.error.lastnameNotValid = true;
      this.error.lastnameNotValidText = "Please enter the lastname";
      errors_found = true;
    }
    if (this.data.confirmpassword.length == 0) {
      this.error.confirmpasswordNotValid = true;
      this.error.confirmpasswordNotValidText = "Please enter the confirm password";
      errors_found = true;
    }

    if (this.data.password != this.data.confirmpassword) {
      this.error.confirmpasswordNotValid = true;
      this.error.confirmpasswordNotValidText = "Password and confirm password does not match";
      errors_found = true;
    }

    if (this.data.regionname.length == 0) {
      this.error.regionnameNotValid = true;
      this.error.regionnameNotValidText = "Please select the regionname";
      errors_found = true;
    }
    if (this.data.emailid.length == 0) {
      this.error.emailidNotValid = true;
      this.error.emailidNotValidText = "Please enter the emailid";
      errors_found = true;
    }
    if (this.data.address.length == 0) {
      this.error.addressNotValid = true;
      this.error.addressNotValidText = "Please enter the address";
      errors_found = true;
    }


    if (errors_found) {
      return;
    } else {
      if (this.addclk) {
        var self = this;
        // self.checkfirstname(self, self.data)
        self.saveRegisterUserDetails(self, self.data)
          // .catch((data) => console.log(" Exception during handling promise: ", data))
      }
      if (this.editclk) {
        this.saveEditedDetails()
      }
    }
  }



  checkfirstname(self, data) {
    // data.self = this;
    return new Promise(
      (resolve, reject) => {
        self.auth
          .getTVInformation({ 'firstname': data.firstname }, '/checkuser')
          .then(
            (res: any) => {
              if (res.body.status === "ok") {
                resolve(data);
              } else {
                console.log("Checking firstname: ", data.firstname,
                  " response: ", res);
                self.registerStatus = 'fail';
                self.registerStatusMessage = res.body.message;
                self.showRegisterStatus = true;
                reject(data);
              }
            }, (err: any) => {
              console.log("Error checking firstname: ", data.firstname,
                " response: ", err);
              reject(data);
            });
      });
  }


  saveRegisterUserDetails(self, data) {
    return new Promise(
      (resolve, reject) => {
        self.auth
          .getTVInformation(
            {
              firstname: data.firstname,
              password: data.password,
              regionname: data.regionname,
              emailid: data.emailid,
              lastname: data.lastname,
              address: data.address,
              //for ums
              userId : data.emailid,
              //password
              name : data.firstname + " " + data.lastname,
              role :'regionaladmin',
              profileInfo : ' ',
              signature:' '
              // superadmin_id : localStorage.getItem('superadmin_id'),
            }
            , '/createregionaladmin')
          .then((res: any) => {
            try {
              console.log("Statuscheck: ", res);
              if(res.body.status == 'success'){
              self.registerStatus = res.body.status;
              self.registerStatusMessage = res.body.reason;
              self.showRegisterStatus = true;
              resolve(data);
              this.getallRegionalAdmins(this.pageLimit, this.pageNo)
              this.save_exit_ref.hide();
              // this.router.navigate(['/superadmin'])
            }else if(res.body.status == false){
              if(res.body.reason == 'wrongpassword'){
                this.dialogService.showError("Email ID already exists. Please use the same password.")
              }
              if(res.body.reason == 'sameuser'){
                this.dialogService.showError("The same email ID exists. Please use another one.")
              }
            }else{
              reject(data);
            }
            } catch (e) {
              console.error(e);
              reject(data);
            }
          }, (err: any) => {
            console.error(err);
            reject(data);
          })
      });
  }
  handleTdClick(event: any, itemId: any ,name :any ,Event:Event) {
    // Toggle the selectedItemId to show/hide the card for the clicked row
    Event.stopPropagation();
    console.log(event,"eee")
    this.selectedItemId = this.selectedItemId === itemId ? null : itemId;
    this.selectedItemName = this.selectedItemName === name ? null : name;
    this.selectedItememail = event.emailid 
    this.clickedRowCoordinates = { top: event.clientY, left: event.clientX };
    // Additional logic if needed
  }

  delete() {
    this.dltclk = true
    console.log(this.selectedItemId ,this.selectedItememail)
    console.log("General Delete");
    if (this.selectedItemId) {
      var yes = confirm("Are you sure you want to delete Admin? = " + this.selectedItemName);
      console.log(yes)
      if (yes == true) {
        this.auth.getTVInformation(
          { selectedid: this.selectedItemId ,userId:this.selectedItememail }, '/deleteregionaladmin').then((res: any) => {
            try {
              if (res && res.status) {
                console.log(res.data, "inside dlt")
                this.dialogService.showSimplePopup("Deleted successfully.");
                this.getallRegionalAdmins(this.pageLimit, this.pageNo)
              } else {
                console.error("General error");
              }
            } catch (e) {
              console.error(e);
            }
          }, (err: any) => {
            console.error(err);
          });
      } else {
        // this.AdminCheckboxListArray = [];
      }
    }
    else {
      this.dialogService.showError("Select something to delete!")
    }

  }


  edit(){
    console.log("Calling WOEditClick HTTP server regionnn")
    this.editclk = true
    this.addclk = false
    this.auth.getTVInformation({ 'id': this.selectedItemId }, '/getregionaladmin').then((res: any) => {
      try {
        console.log(res, "edit ress region")
        if (res) {
          if (res.body.status === true) {
            this.save_exit_ref = this.modalService.show(this.modalContent, Object.assign({}, { class: "modal-md-lg-sm" }));
            this.data = res.body.data;
            this.data['firstname'] = res.body.data['firstname'];
            this.data['lastname'] = res.body.data['lastname'];
            this.data['emailid'] = res.body.data['emailid'];
            this.data['address'] = res.body.data['address'];
            this.data['confirmpassword'] = res.body.data['password'];

            console.log("Selected resData2: ", this.data);
          }
        } else {
          this.data = {};
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  saveEditedDetails() {
    console.log("saveBPDetails Data : ", this.data);
    this.auth.getTVInformation({"data":this.data}, '/editregionaladmin').then((res: any) => {
      try {
        console.log("Status: ", res);
        if (res.body.status == true) {
          // this.router.navigate(['/countryadmin'])
          this.save_exit_ref.hide()
          this.getallRegionalAdmins(this.pageLimit, this.pageNo)
        } else {
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  EditCancel(){
    this.save_exit_ref.hide()
  }

  getallregionsandcountrys() {
    this.auth.getTVInformation({}, '/getallregionsandcountrys')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                console.log(res.body.data ,"regioncountrymapping")
                this.Allregionandcountries = res.body.data
                this.regionnames.push('All regions')
                this.countrynames.push('All countries')
                res.body.data.map(one => {
                  this.regionnames.push(one.Region)
                  this.regionnamesadd.push(one.Region)
                  this.countrynames.push(one.Country)
                })
                this.regionnames = [... new Set(this.regionnames)]
                this.regionnamesadd = [... new Set(this.regionnamesadd)]
              } else {
               
              }
            } else {
              
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  updatecountrys(){
    this.countrynamesadd =[]
    this.Allregionandcountries.map(one => {
      if (this.data.regionname == one.Region) {
        this.countrynamesadd.push(one.Country)
      }
    })
  }
  clearFilters(){
    this.admin = null
    this.region = 'All regions'
    this.SearchClick()
  }

  onOverlayClick(event: MouseEvent): void {
    // Handle the click event inside the modal overlay
    // For example, prevent the modal from closing
    event.stopPropagation();
  }

  clearcross(fieldName: string) {
    // Reset the value of the specified field to an empty string
    this.data[fieldName] = '';
  }
  handleBodyClick(event: Event) {
    const target = event.target as HTMLElement;

    // Check if the click is outside the card
    if (this.selectedItemId) {
      if (!this.cardElementRef.nativeElement.contains(target)) {
        this.selectedItemId = null; // Hide the card
      }
    }
  }

  //server side pagination
  prevPage() {
    let temp = this.pageNo
    temp -= 1
    if (temp >= 1) {
        this.pageNo -= 1
        this.getallRegionalAdmins(this.pageLimit, this.pageNo);;
    }

}

nextPage() {
    console.log(this.pageNo, "nextpageeeeeeee")
    let tmp = Number(this.pageNo) + 1
    //console.log(tmp,this.totalCount,"mmmmmmmmmmmm")
    if (tmp <= this.totalCount) {
        this.pageNo = Number(this.pageNo) + 1
        this.getallRegionalAdmins(this.pageLimit, this.pageNo);
    }
}



nextPageInput() {
    let page_input = this.pageNo;
    if ((page_input <= this.totalCount) && (page_input >= 1)) {
        this.getallRegionalAdmins(this.pageLimit, this.pageNo);
    } else {
        this.getallRegionalAdmins(this.pageLimit, this.pageNo);
    }
}

PageSize(val) {
    this.pageNo = 1
    this.getallRegionalAdmins(this.pageLimit, this.pageNo);
}

booleanValues: { [key: string]: boolean } = {};
sortedColumn: string = 'name';
sortFunction(colName: string) {
  if (this.booleanValues[colName] === undefined) {
    this.booleanValues[colName] = true;
  }

  if (this.booleanValues[colName]) {
    this.Allregionaladmin.sort((a, b) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0);
  } else {
    this.Allregionaladmin.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0);
  }

  this.booleanValues[colName] = !this.booleanValues[colName];
  this.sortedColumn = colName;
}

}
