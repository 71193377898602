import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { GoogleAuthService } from "./google-auth.component";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class GoogleClassroomService {
  private classroomBaseUrl = "https://classroom.googleapis.com";
  private googleDriveBaseUrl = "https://www.googleapis.com/drive";
  // private serverURL = "http://localhost:4000"; // change in prod
  private serverURL = `${environment.serverurl}`; // change in prod
  constructor(
    private http: HttpClient,
    private googleAuthService: GoogleAuthService
  ) {}

  getCourseList(): Observable<any> {
    console.log("getCourseList");
    return this.http.get<any>(`${this.classroomBaseUrl}/v1/courses`);
  }
  getCourseMaterials(id: string): Observable<any> {
    console.log({ id });
    console.log("getCourseList");
    return this.http.get<any>(
      `${this.classroomBaseUrl}/v1/courses/${id}/courseWorkMaterials`
    );
  }
  getCourseMaterialMedia(fileId: string): Observable<any> {
    return this.http.get<any>(
      `${this.googleDriveBaseUrl}/v3/files/${fileId}?alt=media`,
      { responseType: "arraybuffer" as "json" }
    );
  }
  postShareMaterialToGoogleClassroom(
    courseId,
    materialObject
  ): Observable<any> {
    return this.http.post<any>(
      `${this.classroomBaseUrl}/v1/courses/${courseId}/courseWorkMaterials`,
      materialObject
    );
  }
  getGoogleDriveFiles(): Observable<any> {
    return this.http.get<any>(`${this.googleDriveBaseUrl}/v3/files`);
  }
  getGoogleDriveFileMetaData(fileId: string): Observable<any> {
    return this.http.get<any>(
      `${this.googleDriveBaseUrl}/v3/files/${fileId}?fields=id,name,mimeType,createdTime,modifiedTime,size,parents,owners,permissions,thumbnailLink`
    );
  }
  async postGoogleDriveFileToServer(
    fileId: string,
    fileExtension: string
  ): Promise<any> {
    const accessToken = await this.googleAuthService.getAccessToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const body = {
      accessToken,
      fileId,
      fileExtension,
    };

    return await this.http.post<any>(
      `${this.serverURL}/getgooglefilefromgoogledrive`,
      body,
      { headers: headers }
    );
  }

  async postUploadDocumentImagesToServer(
    fileDestinationPath: string,
    fileExtension: string
  ): Promise<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const body = {
      fileDestinationPath,
      fileExtension,
    };
    return await this.http.post<any>(
      `${this.serverURL}/postuploaddocumentimagestoserver`,
      body,
      { headers: headers }
    );
  }

  get3DObjectURLsfromServer(): Observable<any> {
    return this.http.get<any>(`${this.serverURL}/get3dobjecturlsfromserver`);
  }
}
