import { Component, OnInit,Input,HostListener,Output,EventEmitter } from '@angular/core';
import {Chart,registerables} from "node_modules/chart.js"
 Chart.register(...registerables);

@Component({
  selector: 'app-votingpiechart',
  templateUrl: './votingpiechart.component.html',
  styleUrls: ['./votingpiechart.component.scss']
})
export class VotingpiechartComponent implements OnInit {



  @Output() chartDataURL: EventEmitter<string> = new EventEmitter<string>();
  chartdata:any;
  optionCounts:any[]=[];
  backgroundcolour:any[]=[];
  totalvotes=0;
  labelspercent:any[]=[];
myChart:any
chartscolor:any[]=["#ADD8E6","#FFB6C1", "#90EE90","#FFA07A","#8A2BE2","#F08080","#FFFACD"]
  @Input() Totalvotes:any[]=[];
  answered_recieved=[

    {
        "Name": "Alex",
        "UniqueVoteId": "12345",
        "Options": [1]
    },
    {
        "Name": "Jone",
        "UniqueVoteId": "12346",
        "Options": [2]
    },
    {
        "Name": "Bob",
        "UniqueVoteId": "12347",
        "Options": [3]
    },
    {
        "Name": "John",
        "UniqueVoteId": "12348",
        "Options": [3]
    }


];
  constructor() { }

  ngOnInit(): void {

    console.log(this.totalvotes)
    // this.chartdata=this.Totalvotes;
    this.chartdata=this.Totalvotes
    console.log(this.chartdata,"pppp")
    if(this.chartdata!=null){
    console.log(this.chartdata,"chartdata")
    this.chartdata.forEach((vote: any) => {
      console.log(vote, "vote");
      vote.Options.forEach((option: any) => {
        console.log(option, "option");
        let index = this.optionCounts.findIndex((p: any) => p.value == option);
        console.log(index);
        if (index == -1) {
          let str = "Option " + option;
          let json = { value: option, count: 1, label: str };
          this.optionCounts.push(json);
          console.log(this.optionCounts, "ppppp");
        } else {
          let index = this.optionCounts.findIndex((p: any) => p.value === option);
          this.optionCounts[index].count = this.optionCounts[index].count + 1;
        }
        // for (let i = 0; i <= this.optionCounts.length; i++) {
        //   let str = "#" + Math.floor(Math.random() * 16777215).toString(16);
        //   this.backgroundcolour.push(str);
        // }

        this.backgroundcolour=this.chartscolor.slice(0,this.optionCounts.length)


      });
    });

    for (let i = 0; i < this.optionCounts.length; i++) {
      console.log(this.totalvotes,"yyyy")
      console.log(this.optionCounts[i].count,"pppp")
      this.totalvotes=this.totalvotes+this.optionCounts[i].count

    }
    console.log(this.totalvotes,"tpvvvv")
    for (let i = 0; i < this.optionCounts.length; i++) {
      let votepercent= (this.optionCounts[i].count/this.totalvotes)*100
     this.labelspercent.push(Math.round(votepercent));

    }
     this.RenderChart3(this.optionCounts,this.backgroundcolour,this.labelspercent);
    // this.shareChartData();


  } ///

  console.log(this.optionCounts);

}


shareChartData() {
  const chartCanvas = document.getElementById('piechart') as HTMLCanvasElement;
  const dataURL = chartCanvas.toDataURL();
  console.log(dataURL,"piechart")

  this.chartDataURL.emit(dataURL);

}


RenderChart1(Chartsdata:any,bgcolor:any,labelspercent:any){
const myChart=new Chart("piechart",{
 type:'pie',
 data:{
  // labels:['Red','Blue','Yellow','Green','Purple','Orange'],
  labels:Chartsdata.map((item:any)=>item.label),
   datasets:[{
    // barPercentage: 0.4,
     label:'# of votes',
     data:labelspercent,
     backgroundColor:bgcolor,


   }]
 },
 options:{

   animation:{
     duration:0,

     onComplete: (chart) => {

       const ctx = chart.chart.ctx;
       ctx.textAlign = 'center';
       ctx.fillStyle = "rgba(0, 0, 0, 1)";
       ctx.textBaseline = 'bottom';
       for (let i = 0; i < this.optionCounts.length; i++) {
         console.log(this.totalvotes,"yyyy")
         console.log(this.optionCounts[i].count,"pppp")
         this.totalvotes=this.totalvotes+this.optionCounts[i].count

       }

       console.log(this.totalvotes,"tv")
       chart.chart.data.datasets.forEach((dataset, i) => {

          const meta = chart.chart.getDatasetMeta(i);
          console.log("meta",meta.data)
          meta.data.forEach((bar,index) => {

            const data = dataset.data[index];

           ctx.fillText("Vote"+" "+this.optionCounts[index].count, bar.x, bar.y - 50);
          ctx.fillText(""+data+"%", bar.x, bar.y - 5);
         });
       });
     }
     },
     plugins: {
        legend: {
       display: true,
        position: 'right',

     }



     },
 }
})
 }

 RenderChart(Chartsdata: any, bgcolor: any, labelspercent: any) {
  const myChart = new Chart("piechart", {
    type: 'pie',
    data: {
      labels: Chartsdata.map((item: any) => item.label),
      datasets: [{
     data: labelspercent,
        backgroundColor: bgcolor,
      }]
    },
    options: {
      animation: {
        duration: 0,
        onComplete: (chart) => {
          const ctx = chart.chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.textBaseline = 'bottom';

          chart.chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.chart.getDatasetMeta(i);
            meta.data.forEach((bar, index) => {
              console.log(bar,"bar")
              const data = dataset.data[index];
              console.log(meta)
              console.log(meta.controller.getDataset())

             // Calculate the position based on the angle and radius
              //  const angle = bar["startAngle"] + meta.controller.getDataset().data[index].circumference / 2;
              //  const radius = bar.outerRadius;
              //  const x = bar.x + radius * Math.cos(angle);
              //  const y = bar.y + radius * Math.sin(angle);

              // ctx.fillText("Vote" + " " + this.optionCounts[index].count, x, y - 50);
              // ctx.fillText("" + data.toFixed(2) + "%", x, y - 5);
            });
          });
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'right',
        }
      },
    }
  });
}





// done(){

//   var url=this.myChart.toBase64Image();
//   console.log(url,"piecharturl")
//   this.chartDataURL.emit(url);

// }

done() {
  // Assuming you have the canvas element with the id "piechart"
  const canvas = <HTMLCanvasElement>document.getElementById("piechart");

  if (canvas) {
    const url = canvas.toDataURL(); // This will generate the base64 image URL
    console.log(url, "piecharturl");
    this.chartDataURL.emit(url);
  } else {
    console.error("Canvas element not found");
  }
}


RenderChart3(Chartsdata:any, bgcolor:any, labelspercent:any) {
  const ctx = (<HTMLCanvasElement>document.getElementById("piechart")).getContext("2d");
this. myChart = new Chart("piechart", {
    type: 'pie',
    data: {
      labels: Chartsdata.map((item) => item.label),
      datasets: [{
        label: '# of votes',
        data: labelspercent,
        backgroundColor: bgcolor,
      }]
    },
    options: {
      plugins: {
        legend: {
          display: true,
          position: 'right',
        }
      },
      animation: {
        onComplete: (chart) => {
          const ctx = chart.chart.ctx;
          const chartArea=chart.chart.chartArea
          const canvasWidth = chart.chart.width;
          const canvasHeight = chart.chart.height;
        //  const radius = chart.chart.scales.r * 0.5;
         // (chart.chart.scales.["radius"])*0.5
         const radius = Math.min(chartArea.width, chartArea.height) / 2;


          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          chart.chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.chart.getDatasetMeta(i);
            meta.data.forEach((element, index) => {
              const model = element;

              // Calculate distance from the center of the pie chart
              const distance = Math.sqrt(Math.pow(model.x - canvasWidth / 2, 2) + Math.pow(model.y - canvasHeight / 2, 2));

              // Determine whether to place the value inside or outside based on available space
              // if (distance < radius * 0.7) {
              //   // Inside the pie chart section
              //   ctx.fillStyle = 'white'; // Set the color for inside values
              //   ctx.fillText(`${dataset.data[index]}%`, model.x, model.y);
              // } else {
              //   // Outside the pie chart section
              //   ctx.fillStyle = "rgba(0, 0, 0, 1)"; // Set the color for outside values
              //   ctx.fillText(`${dataset.data[index]}%`, model.x + (model.x < canvasWidth / 2 ? 20 : -20), model.y);
              // }
            });
          });
         this.done();
        }

      }
    }
  });


}





}
