import { Component, OnInit,Input } from '@angular/core';
import { FileService } from '../file.service';

@Component({
  selector: 'app-avvoting',
  templateUrl: './avvoting.component.html',
  styleUrls: ['./avvoting.component.scss']
})
export class AvvotingComponent implements OnInit {

  @Input() childData:any

  constructor(private voteurl: FileService) {





    console.log("hello",this.childData)


  }




  Showqrcode:Boolean=true;
  totalvotesrecieved:any=[];
  selectedchart:any;
  showcharts:Boolean=false;
  charturl:any
  qrCodeImage:any

  ngOnInit(): void {
    console.log(this.childData);
    console.log(this.childData.votingstatus,"dddddd")
    this.qrCodeImage=this.childData.url



    console.log("hello avv")
    this.Showqrcode=true;
    this.voteurl.handleOnSockets('broadcast-voting-url').subscribe((val:any)=>{
      console.log(val,"Bandana Das");
    this.totalvotesrecieved=val.recievedvotes
    console.log(val.votingstatus==="active");
    console.log(val.show==="qr")
      if(val.votingstatus==="active"){
        console.log("hello1111111",val.votingstatus)
        if(val.show==="qr"){
          //this.makeqrcode(val.url)
         // this.Showqrcode=true;
          this.qrCodeImage=val.url;
          this.Showqrcode=true;
          this.showcharts=false;

         if("recievedvotes" in  val ){
          console.log("hello in recievedvotes.........")
          this.totalvotesrecieved=val.recievedvotes;
         }

        }else{
           this.showcharts=true;

           this.Showqrcode=false;
           if("selectedchart" in val){
            this.selectedchart=val.selectedchart;
            this.charturl=val.charturl;

            console.log(val.charturl,"Bandana charturl");
            if("recievedvotes" in  val ){
              this.totalvotesrecieved=val.recievedvotes;
             }
           }
        }


      }



     })
  }

}
