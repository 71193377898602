import { Component, HostListener, ViewChild } from '@angular/core';
import { FabricjsEditorComponent, EditorcanvasComponent } from 'projects/angular-editor-fabric-js/src/public-api';
import {FileService} from '../app/file.service'
import { environment } from "../environments/environment";
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from './utils/helper.service';
import { DialogService } from './dialog.service';

interface DialogMessage {
  message: string;
  type: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  dialogMessage: DialogMessage | null = null;
  popupMessage: string | null = null;

  constructor(private FileService:FileService,private http: HttpClient,translate: TranslateService,private dialogService: DialogService,
    private HelperService:HelperService){
    translate.setDefaultLang('en');
    //translate.use('en');
    const currentUrl = window.location.href;
    let lang = this.getQueryParam(currentUrl, 'lang');
    if (!lang) {
      lang = 'en';
    }
    console.log("Language in URL:", lang);
    //localStorage.setItem("Lang", lang)
    translate.use(lang);
    this.HelperService.setLang(lang);
    
    
    //socekt emit copy event
    // const url = "https://inblrlxapp003.tpvaoc.com/fabricang?sessid=jLbLXY";
   
    const aa= window.navigator.userAgent;
    console.log("agent",aa);
    console.log(currentUrl, "currenturl");
    const sessid = this.getQueryParam(currentUrl, 'sessid');
    console.log(sessid);
    if (sessid) {
    console.log("session id is present from android")
    //localStorage.setItem("CopySessionId", sessid)
    //console.log("getsession",localStorage.getItem("CopySessionId"))
    this.FileService.setSessionId(sessid);
    //console.log(sessid);
    }
    else{
      console.log("either session id is not present or not able to extract")
      console.log("currenturl",currentUrl)
    }
    }

    ngOnInit(): void {
      this.dialogService.message$.subscribe(message => {
        this.dialogMessage = message;
      });

      this.dialogService.popup$.subscribe(popup => {
        this.popupMessage = popup;
        console.log("GEnerating pdf message",this.popupMessage)
        if (popup && !popup.includes("Generating pdf, please wait...")) {
          this.showPopup();
        }

      });
    }

  title = 'angular-editor-fabric-js';

  @ViewChild('canvas', { static: false }) canvas: FabricjsEditorComponent;
  @ViewChild('canvas', { static: false }) mycanvas: EditorcanvasComponent;

  @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event) {
  //   event.preventDefault();
  //   this.unloadHandler(event);
  // }

  showPopup() {
    // Display the popup
    setTimeout(() => {
      this.popupMessage = ''; // Hide after a few seconds
    }, 3000); // Adjust the delay as needed
  }

  hidepdfpopup(){
    this.popupMessage = '';
  }

 
  onDialogClosed() {
    this.dialogMessage = null;
    this.dialogService.clearError();
  }
  
  unloadHandler(event) {
    this.myFunction(event);
    // Add any other cleanup logic if needed before the browser unloads
  }
  
  myFunction(event) {
    event.preventDefault();
  
    // Before making the API call, add an event listener for beforeunload
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  
    const fileId = localStorage.getItem('FileID');
    const selectedPage = localStorage.getItem('currentPage');
    let canvasObjects;
    canvasObjects = JSON.parse(localStorage.getItem('currentCanvas'))["objects"];
    if(localStorage.getItem('avchatstarted') && localStorage.getItem('avchatstarted') == 'yes'){
    // Filter out objects with ownerType 'student'
    canvasObjects = canvasObjects.filter(item => item.ownerType !== 'student');
    }
    const canvasData = {
      mainLessonId: localStorage.getItem('mainlessonID'),
      fileId: fileId,
      selectedPage: selectedPage,
      objects: canvasObjects,
      bgColor: this.mycanvas.canvas.backgroundColor,
      bgFrame: this.mycanvas.bgFrame,
    };
  
    const url = `${environment.serverurl}/update-canvas`;
    // Return a Promise from the API call
    return this.http.post(url, canvasData)
      .toPromise()
      .then(() => {
        console.log('Save to MongoDB successful');
        // Additional logic after successful save
      })
      .catch(error => {
        console.error('Error saving to MongoDB:', error);
        // Handle the error appropriately
      })
      .finally(() => {
        // Remove the beforeunload event listener
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
      });
  }
  
   beforeUnloadHandler(event) {
    // Prompt the user with a custom message
    const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
    event.returnValue = confirmationMessage; // Standard for most browsers
    return confirmationMessage; // For some older browsers
  }

  // Function to extract query parameters from URL
  getQueryParam(url: string, arg1: string) {
  const queryParams = new URLSearchParams(new URL(url).search);
  return queryParams.get(arg1);
  }
  

  public rasterize() {
    this.canvas.rasterize();
  }

  public rasterizeSVG() {
    this.canvas.rasterizeSVG();
  }

  public saveCanvasToJSON() {
    this.canvas.saveCanvasToJSON();
  }

  public loadCanvasFromJSON() {
    this.canvas.loadCanvasFromJSON();
  }

  public confirmClear() {
    this.canvas.confirmClear();
  }

  public changeSize() {
    this.canvas.changeSize();
  }

  public addText() {
    this.canvas.addText();
  }

  public getImgPolaroid(event) {
    this.canvas.getImgPolaroid(event);
  }

  public addImageOnCanvas(url) {
    this.canvas.addImageOnCanvas(url);
  }

  public readUrl(event) {
    this.canvas.readUrl(event);
  }

  public removeWhite(url) {
    this.canvas.removeWhite(url);
  }

  public addFigure(figure) {
    this.canvas.addFigure(figure);
  }

  public removeSelected() {
    this.canvas.removeSelected();
  }

  public sendToBack() {
    this.canvas.sendToBack();
  }

  public bringToFront() {
    this.canvas.bringToFront();
  }

  public clone() {
    this.canvas.clone();
  }

  public cleanSelect() {
    this.canvas.cleanSelect();
  }

  public setCanvasFill() {
    this.canvas.setCanvasFill();
  }

  public setCanvasImage() {
    this.canvas.setCanvasImage();
  }

  public setId() {
    this.canvas.setId();
  }

  public setOpacity() {
    this.canvas.setOpacity();
  }

  public setFill() {
    this.canvas.setFill();
  }

  public setFontFamily() {
    this.canvas.setFontFamily();
  }

  public setTextAlign(value) {
    this.canvas.setTextAlign(value);
  }

  public setBold() {
    this.canvas.setBold();
  }

  public setFontStyle() {
    this.canvas.setFontStyle();
  }

  public hasTextDecoration(value) {
    this.canvas.hasTextDecoration(value);
  }

  public setTextDecoration(value) {
    this.canvas.setTextDecoration(value);
  }

  public setFontSize() {
    this.canvas.setFontSize();
  }

  public setLineHeight() {
    this.canvas.setLineHeight();
  }

  public setCharSpacing() {
    this.canvas.setCharSpacing();
  }

  public rasterizeJSON() {
    this.canvas.rasterizeJSON();
  }

  public drawMode() {
    this.canvas.drawingMode();
  }
}
