import { Component, OnInit, Renderer2, ElementRef,Output,EventEmitter,VERSION } from "@angular/core";
import { FileService } from "../file.service";
import { WhiteboardpageComponent } from "../whiteboardpage/whiteboardpage.component";
import {
  AbstractControl,
  FormGroup,
  FormsModule,
  FormControl,
  FormArray,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";

import { GoogleAuthService } from "../gc-plugin/google-auth.component"; //changes - shreyas.s
import { GoogleClassroomService } from "../gc-plugin/google-apis.service"; //changes - shreyas.s
import { environment } from '../../environments/environment';
import { HelperService } from '../utils/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from "../dialog.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  currentApplicationVersion = environment.appVersion;
  currentdate = environment.updateDate;
  form: FormGroup;
  public loginForm;
  public admins = [];
  public selectedOption: any;
  suboption: boolean = false;
  managepf: boolean = false;
  managepass: boolean = false;
  connectedapp: boolean = false;
  manageadmin: boolean = false;
  calladmin: boolean = false;

  // changes start - shreyas.s
  isLoggedin: boolean = false;
  isGoogleDisconnectConfirmation: boolean = false;
  profilePicture: string = "";
  email: string = "";
  userId: any = "";
  accessToken: any = "";
  userid: string;
  username: string;
  roles: any =[];
  showCurrentPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  managelang: boolean = false;
  key:'English'
  data: any = {};
  error: any = {};
  role: string;
  hide = true;
  hide1 = true;
  hide2 = true;
  @Output() googleClassroomlogin = new EventEmitter<Boolean>();
  testaddress: any;
  testname: any;
  testpassword: any;
  manageinfo: boolean = false;
  managesubcription: boolean = false;
  licensetype: any;
  validity: any;
  public ui_language :any


displaymenus:Boolean=false;
displaysubmenus:Boolean=false;
  lang: string;
  @Output() InfourlEmitter = new EventEmitter<string>();

  constructor(
    public whiteboardcomp: WhiteboardpageComponent,
    public formBuilder: FormBuilder,
    public auth: FileService,
    private googleAuthService: GoogleAuthService,
    private googleClassroomService: GoogleClassroomService,
    public translate: TranslateService,
    public helper: HelperService,
    private dialogService: DialogService
    ) {
    //translate.setDefaultLang('en');
      // helper.setLang.(
      //     language => {
      //       const langg= localStorage.getItem("lang")
      //         this.ui_language = langg;
      //         translate.use(langg);
      this.lang = this.helper.getLang();
      console.log('Language in another component:', this.lang);
      this.ui_language = this.lang;
      translate.use(this.lang);
          // });
      // this.showLangSelection = environment.enableTranslations;
    this.form = new FormGroup({
      fullname: new FormControl(''),
      address: new FormControl(''),
      email: new FormControl(''),
      currentp: new FormControl(''),
      newp: new FormControl(''),
      confirmp: new FormControl('')
    });
  }
  initialFormValues: any;

  //changes end - shreyas.s
  ngOnInit(): void {
    console.log(this.currentApplicationVersion,VERSION,"vvvvvvvvvvvvvvvv")
    this.userid =localStorage.getItem("userId");
    this.username =localStorage.getItem("username");
    this.role =localStorage.getItem("type");
    this.getaddress()
    this.suboption = false;
    this.displaymenus=true;
    this.loginForm = this.formBuilder.group({
      adminrole: ["", Validators.required],
      adminname: ["", Validators.required],
    });
    // this.getalladmins()
    this.getroles()
    //changes start - shreyas.s
    this.googleAuthService.isLoggedIn().then((res) => {
      this.isLoggedin = res.valueOf();
      if (this.isLoggedin) {
        this.userId = this.googleAuthService.getUserId();
        this.accessToken = this.googleAuthService.getAccessToken();

        console.log("logged in");
        this.googleClassroomlogin.emit(true);
      } else {
        console.warn("not logged in");
      }
    });
    //changes end - shreyas.s
    this.initialFormValues = this.form.value;
  }
  resetForm() {
    // Reset the form to its initial values
    this.form.reset(this.initialFormValues);
    this.getaddress()
    this.getroles()
  }

  getroles(){
    this.data.fullname = localStorage.getItem("username");
    this.data.email = localStorage.getItem("userId");
    this.data.role = localStorage.getItem("type");
    this.auth.getTVInformation({userId:this.userid},'/getrole' ).then(
      (res: any) => {
        try {
          if (res) {
            console.log(res, "ressssssssss");
            if (res.body.result.length > 0) {
              // this.data.profileiduserdb = res.body.result[0]._id
              const uniqueRolesSet = new Set();

              res.body.result.forEach(one => {
                  uniqueRolesSet.add(one.role);
              });

              // Convert set back to array if needed
              this.roles = Array.from(uniqueRolesSet);
              const roleHierarchy = ['superadmin', 'regionaladmin', 'countryadmin', 'schooladmin', 'teacher'];

              // Sort the roles based on their index in the hierarchy
              const sortedRoles = this.roles.sort((a, b) => roleHierarchy.indexOf(a) - roleHierarchy.indexOf(b));

              this.selectedOption = sortedRoles[0]
              this.updateadmins();
            } else {
              console.log("no rolee")
            }
          } else {
            console.error("General error");
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  validityshowed:any
  getaddress(){
    this.auth.getTVInformation({emailid:this.userid ,role:this.role},'/getadmin' ).then(
      (res: any) => {
        try {
          if (res) {
            console.log(res, "for address");
            this.data.address = res?.body?.data?.address
            this.data.password = res?.body?.data?.password
            this.data.profileid = res?.body?.data?._id
            this.testpassword = res?.body?.data?.password
            this.testname = res?.body?.data?.name
            this.testaddress= res?.body?.data?.address
            this.data.fullname = res?.body?.data?.name
            this.username = res?.body?.data?.name
            // localStorage.setItem('username', res?.body?.data?.name);
            if (this.role == 'demoaccounts') {
              this.licensetype = "Demo"
              this.validity = res?.body?.data?.enddate
              this.validityshowed=this.changeDateFormat(this.validity)
            } else {
              if (res?.body?.data?.licensetype) {
                this.licensetype = res?.body?.data?.licensetype
                this.validity = res?.body?.data?.enddate
                this.validityshowed=this.changeDateFormat(this.validity)
              } else {
                this.licensetype = 'Pro'
                this.validity = "2025-02-12"
                this.validityshowed=this.changeDateFormat(this.validity)
              }
            }

          } else {
            console.error("General error");
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  saveprofile(){
    console.log(this.form.value,"savee profilee")
    var errors_found = false;
    this.error = {};
    this.error.emailidNotValid = false;
    this.error.fullnameNotValid = false;
    this.error.addressNotValid = false;
    this.error.passwordNotValid = false;
    this.error.newpNotValid = false;
    this.error.confirmpasswordNotValid = false;

    this.form.value.fullname = this.form.value.fullname ? this.form.value.fullname.trim() : "";
    this.form.value.address = this.form.value.address ? this.form.value.address.trim() : "";
    this.form.value.email = this.form.value.email ? this.form.value.email.trim() : "";
    this.form.value.newp = this.form.value.newp ? this.form.value.newp.trim() : "";
    this.form.value.confirmp = this.form.value.confirmp ? this.form.value.confirmp.trim() : "";
    this.form.value.currentp = this.form.value.currentp ? this.form.value.currentp.trim() : "";

    if (this.form.value.newp != this.form.value.confirmp) {
      this.error.confirmpasswordNotValid = true;
      this.error.confirmpasswordNotValidText = "New password and confirm password does not match";
      errors_found = true;
    }
    if (this.form.value.fullname.length == 0) {
      this.error.fullnameNotValid = true;
      this.error.fullnameNotValidText = "Please enter the fullname";
      errors_found = true;
    }
    // if (this.form.value.address.length == 0) {
    //   this.error.addressNotValid = true;
    //   this.error.addressNotValidText = "Please enter the address";
    //   errors_found = true;
    // }
    if (this.form.value.email.length == 0) {
      this.error.emailNotValid = true;
      this.error.emailNotValidText = "Please enter the email";
      errors_found = true;
    }
    // if (this.form.value.currentp.length == 0) {
    //   this.error.passwordNotValid = true;
    //   this.error.passwordNotValidText = "Please enter the password";
    //   errors_found = true;
    // }
    if (this.form.value.newp.length == 0) {
      this.form.value.newp=this.testpassword
      // this.error.newpNotValid = true;
      // this.error.newpNotValidText = "Please enter the newpassword";
      // errors_found = true;
    }

    if (this.form.value.confirmp.length == 0) {
      console.log(this.testpassword,"passs")
      this.form.value.confirmp=this.testpassword
      // this.error.confirmpasswordNotValid = true;
      // this.error.confirmpasswordNotValidText = "Please enter the confirm password";
      // errors_found = true;
    }


    if (errors_found) {
      return;
    } else {
      //save api call
    this.auth.getTVInformation({"datatofind":this.data,"datatosave":this.form.value}, '/saveprofile').then(
      (res: any) => {
        try {
          console.log(res,"profile changes")
          if (res.body.status == true) {
            this.dialogService.showSimplePopup("Changes saved successfully.")
            this.suboption = false;
            this.managepf = false;
            this.managepass = false;
            this.connectedapp = false;
            this.manageadmin = false;
            this.managelang = false
            this.error = {};
            this.isGoogleDisconnectConfirmation = false; //changes shreyas.s
            this.displaymenus=true;
            this.displaysubmenus=false;
          } else {
            console.error("General error");
            this.dialogService.showError("Changes not saved. Please try again later.")
            this.displaymenus=true;
            this.displaysubmenus=false;
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err) => {
        console.log(err);
      }
    );
    }
  }

  getalladmins() {
    var url;
    if (this.selectedOption == "superadmin") {
      url = `/getallsuperadmin`;
    }
    if (this.selectedOption == "regionaladmin") {
      url = `/getallregionaladmin`;
    }
    if (this.selectedOption == "countryadmin") {
      url = `/getallcountryadmin`;
    }
    if (this.selectedOption == "schooladmin") {
      url = `/getallschooladmin`;
    }
    this.admins = [];
    this.auth.getTVInformation({}, url).then(
      (res: any) => {
        try {
          if (res) {
            if (res.body.data.length > 0) {
              console.log(res, "ressssssssss");
              res.body.data.map((one) => {
                this.admins.push(one.emailid);
              });
            } else {
            }
          } else {
            console.error("General error");
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  updateadmins() {
    console.log(this.selectedOption);
    // this.getalladmins();
    this.auth.getTVInformation({emailid:this.userid ,role:this.selectedOption},'/getadmin' ).then(
      (res: any) => {
        try {
          if (res) {
            console.log(res, "for country and region");
            if (this.selectedOption == "superadmin") {
              localStorage.removeItem("regionname");
              localStorage.removeItem("countryname");
              localStorage.removeItem("schoolname");
            }
            if (this.selectedOption == "regionaladmin") {
              localStorage.setItem("regionname", res.body.data.regionname)
            }
            if (this.selectedOption == "countryadmin") {
              localStorage.setItem("regionname", res.body.data.regionname)
              localStorage.setItem("countryname", res.body.data.countryname)
            }
            if (this.selectedOption == "schooladmin") {
              localStorage.setItem("regionname", res.body.data.regionname)
              localStorage.setItem("countryname", res.body.data.countryname)
              localStorage.setItem("schoolname", res.body.data.schoolname)
            }
            if (this.selectedOption == "teacher") {
              localStorage.setItem("regionname", res.body.data.regionname)
              localStorage.setItem("countryname", res.body.data.countryname)
              localStorage.setItem("schoolname", res.body.data.schoolname)
            }
          } else {
            console.error("General error");
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  closeNav() {
    console.log("calling close nav");
    this.whiteboardcomp.closeprofile();
  }

  manageprofile() {
    this.resetForm()
    console.log(this.data.fullname,"fulll")
    this.suboption = true;
    this.managepf = true;
    this.displaymenus=false;
    this.displaysubmenus=true;
  }

  gobacktoprofile() {
    this.suboption = false;
    this.managepf = false;
    this.managepass = false;
    this.connectedapp = false;
    this.manageadmin = false;
    this.managelang = false
    this.error = {};
    this.isGoogleDisconnectConfirmation = false; //changes shreyas.s
    this.manageinfo = false;
    this.managesubcription = false;
    this.displaysubmenus=false;
    this.displaymenus=true;
  }
  gobacktoprofileadmin() {
    console.log(this.form.value.address , this.testaddress ,"testt")
    if(this.form.value.confirmp.length > 0 || this.form.value.newp.length > 0
      ||(this.form.value.fullname != this.testname)||(this.form.value.address != this.testaddress)){
      var yes = confirm("You want to save changes?")
      console.log(yes,"yesssss")
      if(yes == true){
         this.saveprofile()
      }else{
        this.suboption = false;
        this.managepf = false;
        this.managepass = false;
        this.connectedapp = false;
        this.manageadmin = false;
        this.managelang = false
        this.error = {};
        this.data.fullname = this.testname
        this.isGoogleDisconnectConfirmation = false; //changes shreyas.s
        this.displaymenus=true;
        this.displaysubmenus=false;
      }
    } else {
      this.suboption = false;
      this.managepf = false;
      this.managepass = false;
      this.connectedapp = false;
      this.manageadmin = false;
      this.managelang = false
      this.error = {};
      this.isGoogleDisconnectConfirmation = false; //changes shreyas.s
      this.displaymenus=true;
      this.displaysubmenus=false;
    }
  }
  cancel() {
    this.suboption = false;
    this.managepf = false;
    this.managepass = false;
    this.connectedapp = false;
    this.manageadmin = false;
    this.managelang = false
    this.error = {};
    this.form.value.newp =""
    this.form.value.confirmp =""
    this.getroles()
    this.displaymenus=true;
    this.displaysubmenus=false;
  }

  changepass() {
    this.suboption = true;
    this.managepass = true;
  }

  otherapps() {
    this.suboption = true;
    this.connectedapp = true;
    this.displaysubmenus=true;
    this.displaymenus=false
  }

  gotosuperadmiprofile() {
    // this.router.navigate(['superadmin'])
    console.log("calling superadmin");
    // document.getElementById("superadminnav").style.width = "100%";
    this.suboption = true;
    this.manageadmin = true;
    this.displaymenus=false;
    this.displaysubmenus=false
  }

  closesuperadmin() {
    this.calladmin = false;
    document.getElementById("superadminnav").style.width = "0";
  }

  login(val) {
    this.calladmin = true;
    if (this.selectedOption) {
      localStorage.setItem("adminrole", this.selectedOption);
      localStorage.setItem("admin", val.adminname);
      document.getElementById("superadminnav").style.width = "100%";
      // setTimeout(() => {
      //   document.getElementById("superadminnav").style.width = "100%";
      // });
    }

    // if(!val.loginname || !val.accesskey){
    //     alert("Please enter valid username and password");
    //     return false;
    // }
    // this.auth
    //     .authenticateSuperAdmin(
    //         {
    //             'username':val.loginname,
    //             'password':val.accesskey,
    //             'admintype': this.admintype,
    //             'gwed_server_mode': this.GWED_SERVER_MODE
    //         }
    //     )
    //     .then((user: any) => {
    //         console.log(user,"userr")
    //         localStorage.setItem('username', user.username);
    //         localStorage.setItem('usertype', user.usertype);
    //         localStorage.setItem('session_id', user.session_id);
    //         localStorage.setItem('superadmin_id', user.id);
    //         localStorage.setItem('admintype', this.admintype);
    //         console.log(this.admintype,"admintypee")
    //         if (this.admintype == 'SuperAdmin') {
    //             this.router.navigate(['superadmin']);
    //         }
    //         if (this.admintype == 'RegionalAdmin') {
    //             this.router.navigate(['regionaladmin']);
    //         }
    //     }, (err) => {
    //         console.log(err);
    //         localStorage.clear();
    //         alert("Invalid credentails, Try again!");
    //     });
  }

  // changes start  - shreyas.s
  handleGoogleClassroomConnect() {
    if (this.isLoggedin) {
      this.isGoogleDisconnectConfirmation = true;
      this.googleClassroomlogin.emit(true);
    } else {
      this.googleAuthService.signin();
    }
  }
  handleGoogleDisconnectConfirmation() {
    this.isGoogleDisconnectConfirmation = false;
    this.googleAuthService.signout();
    this.googleClassroomlogin.emit(false);
  }
  // changes end  - shreyas.s

  //asmita
  togglePasswordVisibility(controlName: string): void {
    switch (controlName) {
      case 'currentp':
        this.showCurrentPassword = !this.showCurrentPassword;
        break;
      case 'newp':
        this.showNewPassword = !this.showNewPassword;
        break;
      case 'confirmp':
        this.showConfirmPassword = !this.showConfirmPassword;
        break;
    }
  }
  managelanguagae() {
    this.suboption = true;
    this.managelang = true;
    this.displaymenus=false;
    this.displaysubmenus=true;
  }

  gotoinfoprofile() {
    console.log("calling infoo");
    this.suboption = true;
    this.manageinfo = true;
    this.displaymenus=false;
    this.displaysubmenus=true;
  }

  gotosubscriptionprofile() {
    console.log("calling infoo");
    this.suboption = true;
    this.managesubcription = true;
    this.displaymenus=false;
    this.displaysubmenus=true;
  }


  setUiLang() {
    // console.log(this.ui_language,"language changed")
    // this.helper.changeUiLanguage(this.ui_language);
    // localStorage.setItem("ui_lang",this.ui_language)
      // Change language
      this.translate.use(this.ui_language);
  }


  showLinks(linkstobeshown){

    let LinkTobeShown=linkstobeshown==='Terms of Use'?
    "http://genius-tou.s3-website.eu-central-1.amazonaws.com/":
    linkstobeshown==='Privacy policy'?
    "https://genius-tou.s3.eu-central-1.amazonaws.com/privacy-statement/philips_genius_privacy_statement.htm":
    " https://genius-tou.s3.eu-central-1.amazonaws.com/open-source/open_source_licenses_details.htm"


    this.InfourlEmitter.emit(LinkTobeShown);
  }


  changeDateFormat(dateString) {

    let parts = dateString.split('-');


    let formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;

    return formattedDate;
}
}
