import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {

  constructor(private location: Location,) { }

  androidcalci:boolean=false;

  ngOnInit(): void {
    const fullPath = this.location.path(true);
    console.log('Full URL:', fullPath);
    if (fullPath=="/calciforandapp"){
      this.androidcalci=true;
    }

   // console.log("cccc",this.androidcalci)
}

displayValue: string = '';
answerShown:any=0;
history:any=0;
prevalue:any;
signadded:number=0;

@Output() closeCalculator = new EventEmitter<Boolean>();

// appendValue(value: string,type:string) {
// if(type==="sign"){
//   this.signadded=this.signadded+1;
//   let s1= this.displayValue.slice(-1);
//   if(s1==="+"||s1==="-"||s1==="/"||s1==="*"||s1==="."){
//     this.displayValue=this.displayValue.slice(0,-1)+value;

//   }else{
//     this.displayValue += value;
//   }
//  console.log(s1);
// }else{
//   let match = this.displayValue.match(/(-?\d+(\.\d+)?)$/);
//   console.log(match,"match");
//     if (match) {
//    let lastNumber = parseFloat(match[0]);
//    let l1=lastNumber+"";
//    console.log("l1",l1);
//   if(l1.length<=15){
//     this.displayValue += value;
//   }

//    }else{
//     console.log("hhhhhhhh")
//     this.displayValue += value;
//    }

//     }
// //
// //   this.prevalue=value;

// }




clear() {
  this.displayValue='';
  this.answerShown=0;
}

// calculate() {
//   console.log(typeof this.displayValue === "string", "llll");
//   if (!this.displayValue) {
//     return this.answerShown = 0;
//   } else {
//     try {
//       console.log(this.displayValue);
//       let expression = this.displayValue.replace(/%/g, '/100');
//       this.answerShown = eval(expression.replace(/\b(\d+)\b/g, "$1n")).toString();
//       console.log(this.answerShown, "äaaaaaaaaaa");
//       this.history = this.answerShown;
//     } catch (error) {
//       this.answerShown = 'Error';
//     }
//   }
// }

shownanswer:any
calculate() {

 // console.log(typeof this.displayValue === "string", "llll");
  if (!this.displayValue) {
    return this.answerShown = 0;
  } else {
    try {
      // let expression = this.displayValue.replace(/%/g, '/100');
      let expression = this.displayValue.replace(/(^|[^\d.])0+(\d+)/g, '$1$2').replace(/%/g, '/100');
      //  let answer=eval(expression.replace(/\b(\d+)\b/g, "$1n")).toString()
      //  console.log(answer,"answer");
        let answer2=eval(expression).toString();
        console.log(answer2,"answer2");

     // this.answerShown = eval(expression.replace(/\b(\d+)\b/g, "$1n")).toString();
      // if(answer.length<20&&(expression.includes("/")===false)){
      //   this.answerShown= answer;
      // }else{
      //   this.answerShown= eval(expression).toString();
      // }
      let answertoShown=eval(expression).toString();
      console.log(answertoShown,"aaa")
      if(answertoShown==="NaN"){
        this.answerShown="Error"
        this.displayValue="Error"

      }else{

        this.answerShown=answertoShown.length>12?this.convertToExponential(answertoShown):answertoShown;
        this.displayValue=this.answerShown
        this.history = this.answerShown;
      }
      console.log(this.answerShown, "äaaaaaaaaaa");

    //  this.history = this.answerShown;
    } catch (error) {
      this.answerShown = 'Error';
    }
  }
  this.shownanswer=this.answerShown;
  this.resizeToFit();
}


backdelete(){
  this.displayValue=this.displayValue.slice(0,-1);
  }

  // toggleSign() {
  //   console.log("ppp")
  //  let match = this.displayValue.match(/(-?\d+(\.\d+)?)$/);
  //  console.log(match,"match");
  //    if (match) {
  //   let lastNumber = parseFloat(match[0]);
  //   console.log(lastNumber);
  //   let newLastNumber = -lastNumber;
  //   this.displayValue = this.displayValue.replace(/(-?\d+(\.\d+)?)$/, newLastNumber.toString());
  //   }
  // }


//   toggleSign() {
//     console.log("ppp")
//     let match = this.displayValue.match(/(-?\d+(\.\d+)?)([+\-*/])?$/);
//     console.log(match, "match");

//     if (match) {
//         let lastNumber = parseFloat(match[1]);
//         console.log(lastNumber);
//         let newLastNumber = -lastNumber;
//         this.displayValue = this.displayValue.replace(/(-?\d+(\.\d+)?)([+\-*/])?$/, newLastNumber.toString());
//     }
// }


// toggleSign() {
//   console.log("ppp")
//   let match = this.displayValue.match(/(-?\d+(\.\d+)?)([+\-*/])?$/);
//   console.log(match, "match");

//   if (match) {
//       let lastNumber = parseFloat(match[1]);
//       console.log(lastNumber);
//       let newLastNumber = -lastNumber;
//       this.displayValue = this.displayValue.replace(/(-?\d+(\.\d+)?)([+\-*/])?$/, newLastNumber.toString());
//   }
// }

toggleSign() {

  let match = this.displayValue.match(/(-?\d+(\.\d+)?)([+\-*/]\s?)?$/);


  if (match) {
    let oper=this.displayValue[match.index + match[1].length] || '';

      let lastNumber = parseFloat(match[1]);

      let newLastNumber = -lastNumber;
      this.displayValue = this.displayValue.replace(/(-?\d+(\.\d+)?)([+\-*/]\s?)?$/, newLastNumber.toString());
  }
}









  CloseCalculator(){
    let closecalculator=false
    this.closeCalculator.emit(closecalculator);
  }

  initalanswerfontsize:number;
  // calculateFontSize(): number {
  //   const anshown = document.getElementById('answerShown');
  //   const initialFontSize = parseFloat(window.getComputedStyle(anshown).fontSize);

  //   //console.log(this.initalanswerfontsize,",,,,,,,,,,,,,,,")
  //   if(!this.initalanswerfontsize){
  //  this.initalanswerfontsize=initialFontSize
  //   }

  //   if(this.initalanswerfontsize>40){
  //     this.initalanswerfontsize=40
  //     console.log(this.initalanswerfontsize,"this........")
  //   }

  //   if(this.initalanswerfontsize<=40){
  //   //console.log(initialFontSize,"initialfontsize")
  //   let maxLength = 8;
  //    let length = this.answerShown.length;
  //     let  baseFontSize = 15;
  //   if(maxLength<length){
  //    // console.log(Math.min(length - maxLength, 0));
  //      return baseFontSize - Math.min(length - maxLength, 0);
  //   }else{
  //     //console.log(this.initalanswerfontsize)
  //     return this.initalanswerfontsize;
  //   }
  // }else{
  //   let maxLength = 6;
  //   let length = this.answerShown.length;
  //    let  baseFontSize = 10;
  //  if(maxLength<length){
  //   // console.log(Math.min(length - maxLength, 0));
  //     return baseFontSize - Math.min(length - maxLength, 0);
  //  }else{
  //    console.log(this.initalanswerfontsize)
  //    return this.initalanswerfontsize;
  //  }
  // }
  // }



  calculateFontSize(): number {
    const anshown = document.getElementById('answerShown');
    const initialFontSize = parseFloat(window.getComputedStyle(anshown).fontSize);

    if (!this.initalanswerfontsize) {
      this.initalanswerfontsize = initialFontSize / 16; // Convert initial px value to rem
    }

    if (this.initalanswerfontsize > 2.5) { // Set max font-size to 2.5rem
      this.initalanswerfontsize = 2.5;
      console.log(this.initalanswerfontsize, "this........");
    }

    let maxLength, length, baseFontSize;

    if (this.initalanswerfontsize <= 2.5) {
      maxLength = 10;
      length = this.answerShown.length;
      baseFontSize = 1.5; // Base font-size in rem

      if (maxLength < length) {
        return baseFontSize - Math.min(length - maxLength, 0) / 16; // Convert to rem
      } else {
        return this.initalanswerfontsize;
      }
    } else {
      maxLength = 8;
      length = this.answerShown.length;
      baseFontSize = 1.5; // Base font-size in rem

      if (maxLength < length) {
        return baseFontSize - Math.min(length - maxLength, 0) / 16; // Convert to rem
      } else {
        console.log(this.initalanswerfontsize);
        return this.initalanswerfontsize;
      }
    }
  }



  // calculateFontSizehistory(): number {

  //  let maxLength =7;
  //   let length = this.history.length;
  //   let baseFontSize = 10;
  //   const content = document.getElementById('content');
  //  const initialFontSize = parseFloat(window.getComputedStyle(content).fontSize);

  //   if(maxLength<length){
  //     console.log(baseFontSize - Math.min(length - maxLength, 0));
  //   // Adjust the font size dynamically based on the length
  //  // return baseFontSize - Math.min(length - maxLength, 0);
  //  return initialFontSize-Math.max(length - maxLength,baseFontSize);
  //   }
  // }


  windowsfontsize:number;

  resizeToFit() {
console.log("ppppppppppppppppppppppp")
    let maxLength =12;
     let length = this.history.length;
     let baseFontSize = 10;
     const content = document.getElementById('content');
    const initialFontSize = parseFloat(window.getComputedStyle(content).fontSize);
    if(!this.windowsfontsize){
      this.windowsfontsize = initialFontSize
    }


     if(maxLength<length){
       console.log(baseFontSize - Math.min(length - maxLength, 0));
     // Adjust the font size dynamically based on the length
    // return baseFontSize - Math.min(length - maxLength, 0);
    console.log(length-maxLength);
   let newFontSize = initialFontSize-Math.min(length - maxLength,initialFontSize);
   if(initialFontSize>16){
    let n1=Math.min(newFontSize,1)
    console.log(newFontSize);
      content.style.fontSize = n1 + 'px';
   }else{
   let n1=Math.max(newFontSize,10)
   console.log(newFontSize);
     content.style.fontSize = n1 + 'px';
   }
     }else{
      content.style.fontSize = this.windowsfontsize + 'px';
     }
   }

  calculateFontSizeInput(){

    let maxLength = 7;
     let length = this.displayValue.length;
     let baseFontSize = 8;



     if(maxLength<length){

     return baseFontSize - Math.min(length - maxLength, 0);
     }
  }

  // scrollToEnd() {
  //   const input=document.getElementById('input');
  //   const index=input.innerText.length;
  //   const maxScrollLeft=input.scrollWidth-input.clientWidth;

  //   input.scrollLeft=maxScrollLeft;
  // }


  scrollToEnd() {
    const input = document.getElementById('input');

    // Use a slight delay to ensure that the content update is processed before scrolling
    setTimeout(() => {
      const maxScrollLeft = input.scrollWidth - input.clientWidth;
      input.scrollLeft = maxScrollLeft; // Scroll to the end of the input field
    }, 0);
  }



  convertToExponential(num) {
    const numStr = num.toString();

    // Check if the number has more than 12 digits (ignoring the decimal point if present)
    if (numStr.replace('.', '').length > 12) {
      // Convert to exponential notation
      return Number(num).toExponential();
    }

    // Return the number as it is if not more than 12 digits
    return num;
  }




  hasOneDecimal(value) {
    const regex = /^\d+(\.\d{1,8})?$/;
    return regex.test(value);
  }



 getNumberAfterLastSign(expression) {
    // Regular expression to match the last sign (+, -, *, /, %) and the number after it
    const match = expression.match(/[\+\-\*\/%](\d*\.?\d*)$/);
    return match ? match[1] : null;
  }


  appendValue(value: string,type:string) {
    // if(!this.displayValue&&value==="0"){
    //   return
    // }

    if(this.shownanswer==='Error'||this.shownanswer==='Infinity'||this.shownanswer==='-Infinity'||(this.shownanswer===this.displayValue&&type==="number")){
      console.log(this.answerShown);
      this.displayValue='';
      this.shownanswer='';

    }

    console.log(value,"value",type,"type")
    if(type==="sign"){
      if(!this.displayValue){
        if(value==="."){
          this.displayValue=this.displayValue+"0"+value
        }else{
        return
      }
    }
      this.signadded=this.signadded+1;
      let s1= this.displayValue.slice(-1);
      if((s1==="+")||s1==="-"||s1==="/"||s1==="*"||s1==="%"){
        if(value==="."){

          this.displayValue=this.displayValue+"0"+value;
        }else{
      this.displayValue=this.displayValue.slice(0,-1)+value;
        }

      }else if((s1==="%"&&value===s1)||s1==="."&&value===s1){
          return
      }
      else{

      if(value==="."){
        let lastnumber=this.getNumberAfterLastSign(this.displayValue)

       let checkpointerstatus=!lastnumber?this.displayValue.includes("."):lastnumber.includes(".");
       if(checkpointerstatus===true){
        return
       }else{
        this.displayValue+=value
       }

      }else{
       console.log("Coming in block 7")

        this.displayValue+=value
      }


      }

    }else{
      let match = this.displayValue.match(/(-?\d+(\.\d+)?)$/);
      console.log(match,"match");
        if (match) {

       let lastNumber = match[1];
       console.log(lastNumber,"lastnumber");

       if(lastNumber=="0"&&value==="0"){
        return
       }

       if(this.displayValue=="0"&&lastNumber==="0"&&value!=="0"){
        console.log(value)

         return this.displayValue=value;
       }


       let l1=lastNumber+"";


      if(l1.includes(".")){
        console.log("hiiiiiii div9");
        console.log(l1.length,"length")
      console.log(this.hasOneDecimal(lastNumber),"check bandana decimal")
        if(l1.length<=24&&this.hasOneDecimal(lastNumber)===true){
          this.displayValue+=value
        }else{
          return
        }
      }

       if(l1.includes("-")){
        if(l1.length<=16){
          this.displayValue+=value;
        }
       }
       if(!(l1.includes("-"))&&!(l1.includes("."))){
        console.log("hi div5")
      if(l1.length<=15){
        this.displayValue += value;
      }
    }

       }else{
        console.log("hhhhhhhh div8")
        this.displayValue += value;
       }

        }

        this.scrollToEnd();
    //
    //   this.prevalue=value;

    }










}


