import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as fabric from '../../../../src/lib/fabric.min.js'
declare const fabric: any;
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'angular-editor-fabric-js',
  templateUrl: './angular-editor-fabric-js.component.html',
  styleUrls: ['./angular-editor-fabric-js.component.css'],
})
export class FabricjsEditorComponent implements AfterViewInit {
  //[x: string]: any;
  @ViewChild('htmlCanvas') htmlCanvas: ElementRef;

  @ViewChild('canvasContainer', { static: true }) canvasElement: ElementRef;
  //@ViewChild('videoUrlInput', { static: true }) videoUrlInput: ElementRef;
  //@ViewChild('buttonContainer', { static: true }) buttonContainer: ElementRef;


  public canvas: fabric.Canvas;
  public props = {
    canvasFill: '#ffffff',
    canvasImage: '',
    id: null,
    opacity: null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: '',
    width:null,
    stroke:null,
  };

  public textString: string;
  public url: string | ArrayBuffer = '';
  public size: any = {
    width: 1700,
    height: 820
  };


  public json: any;
  private globalEditor = false;
  public textEditor = false;
  public lineeditor = false;
  private imageEditor = false;
  public figureEditor = false;
  public selected: any;
  colcolcol:any;
  isPlaying:boolean = false;
  webbbbbbselected:boolean=false;
  youtubeselected:boolean=false;
  videodivselected:boolean=false;
  threedydivselected:boolean=false;

  private videos: { videoObj: fabric.Image, videoEl: HTMLVideoElement }[] = [];


  ytvid: SafeResourceUrl
  weburl: SafeResourceUrl 
  mainvideourl:SafeResourceUrl
  main3dimage:SafeResourceUrl
  constructor(private sanitizer: DomSanitizer) { }
  

  ngAfterViewInit(): void {
    this.colcolcol = '#000000';
    this.strokethicknes=1;
    console.log("ssssssssss",this.strokethicknes)
    console.log("for inblr")
    // setup front side canvas
    this.canvas = new fabric.Canvas(this.htmlCanvas.nativeElement, {
      hoverCursor: 'pointer',
      selection: true,
      selectionBorderColor: 'blue',
      isDrawingMode: true
    });
    this.onResize('')


    this.canvas.on({
      'object:moving': (e) => { },
      'object:modified': (e) => { },
      'object:selected': (e) => {

        const selectedObject = e.target;
        this.selected = selectedObject;
        selectedObject.hasRotatingPoint = true;
        selectedObject.transparentCorners = false;
        selectedObject.cornerColor = 'rgba(255, 87, 34, 0.7)';

        this.resetPanels();

        if (selectedObject.type !== 'group' && selectedObject) {

          this.getId();
          this.getOpacity();

          switch (selectedObject.type) {
            case "line":
              this.lineeditor = true;
              this.getstroke();
              break;
            case 'rect':
            case 'circle':
            case 'triangle':
              this.figureEditor = true;
              this.getFill();
              break;
            case 'i-text':
              this.textEditor = true;
              this.getLineHeight();
              this.getCharSpacing();
              this.getBold();
              this.getFill();
              this.getTextDecoration();
              this.getTextAlign();
              this.getFontFamily();
              break;
            case 'image':
              break;
          }
        }
      },
      'selection:cleared': (e) => {
        this.selected = null;
        this.resetPanels();
      }
    });

    //this.canvas.setWidth(this.size.width);
    //this.canvas.setHeight(this.size.height);

    // get references to the html canvas element & its context
    this.canvas.on('mouse:down', (e) => {
      const canvasElement: any = document.getElementById('canvas');
      
    });

    // Assuming you have a reference to the canvas element
var canvas = document.getElementById('canvasContainer');

// Add a click event listener to the canvas
canvas.addEventListener('touchstart', (event) => {
  this.webbbbbbselected=false;
  console.log("event1 ",event)
  // Check if the clicked element is the div with ID "mydiv" or its child elements
  var myDiv = (event.target as Element).closest('#webdiv');
  const dd = document.getElementById('webdiv') as HTMLElement;
  
  if (myDiv) {
    console.log("div element selected")
    dd.style.border = "5px solid orange"
    dd.style.zIndex ="10"
    this.webbbbbbselected=true;
    this.canvas.discardActiveObject();
    this.canvas.renderAll(); 
    // The click occurred on or within the "mydiv" element
    // Do something with the selected div
    // ...
  }
  else{
    dd.style.border = "1px solid black"
    dd.style.zIndex ="1"
  }
});

canvas.addEventListener('mousedown', (event) => {
  this.webbbbbbselected=false;
  console.log("event1 ",event)
  // Check if the clicked element is the div with ID "mydiv" or its child elements
  var myDiv = (event.target as Element).closest('#webdiv');
  const dd = document.getElementById('webdiv') as HTMLElement;
  
  if (myDiv) {
    console.log("div element selected")
    dd.style.border = "5px solid orange"
    dd.style.zIndex ="10"
    this.webbbbbbselected=true;
    this.canvas.discardActiveObject();
    this.canvas.renderAll(); 
    // The click occurred on or within the "mydiv" element
    // Do something with the selected div
    // ...
  }
  else{
    dd.style.border = "1px solid black"
    dd.style.zIndex ="1"
  }
});
    

canvas.addEventListener('touchstart', (event) => {
  this.youtubeselected=false;
  console.log("event1 ",event)
  // Check if the clicked element is the div with ID "mydiv" or its child elements
  var myDiv = (event.target as Element).closest('#mydiv');
  const dd = document.getElementById('mydiv') as HTMLElement;
  
  if (myDiv) {
    console.log("div element selected")
    dd.style.border = "5px solid orange"
    dd.style.zIndex ="10"
    this.youtubeselected=true;
    this.canvas.discardActiveObject();
    this.canvas.renderAll(); 
    // The click occurred on or within the "mydiv" element
    // Do something with the selected div
    // ...
  }
  else{
    dd.style.border = "1px solid black"
    dd.style.zIndex ="1"
  }
});

canvas.addEventListener('mousedown', (event) => {
  this.youtubeselected=false;
  console.log("event1 ",event)
  // Check if the clicked element is the div with ID "mydiv" or its child elements
  var myDiv = (event.target as Element).closest('#mydiv');
  const dd = document.getElementById('mydiv') as HTMLElement;
  
  if (myDiv) {
    console.log("div element selected")
    dd.style.border = "5px solid orange"
    dd.style.zIndex ="10"
    this.youtubeselected=true;
    this.canvas.discardActiveObject();
    this.canvas.renderAll(); 
    // The click occurred on or within the "mydiv" element
    // Do something with the selected div
    // ...
  }
  else{
    dd.style.border = "1px solid black"
    dd.style.zIndex ="1"
  }
});
    


canvas.addEventListener('touchstart', (event) => {
  this.videodivselected=false;
  console.log("event1 ",event)
  // Check if the clicked element is the div with ID "mydiv" or its child elements
  var myDiv = (event.target as Element).closest('#videodiv');
  const dd = document.getElementById('videodiv') as HTMLElement;
  
  if (myDiv) {
    console.log("div element selected")
    dd.style.border = "5px solid orange"
    dd.style.zIndex ="10"
    this.videodivselected=true;
    this.canvas.discardActiveObject();
    this.canvas.renderAll(); 
    // The click occurred on or within the "mydiv" element
    // Do something with the selected div
    // ...
  }
  else{
    dd.style.border = "1px solid black"
    dd.style.zIndex ="1"
  }
});

canvas.addEventListener('mousedown', (event) => {
  this.videodivselected=false;
  console.log("event1 ",event)
  // Check if the clicked element is the div with ID "mydiv" or its child elements
  var myDiv = (event.target as Element).closest('#videodiv');
  const dd = document.getElementById('videodiv') as HTMLElement;
  
  if (myDiv) {
    console.log("div element selected")
    dd.style.border = "5px solid orange"
    dd.style.zIndex ="10"
    this.videodivselected=true;
    this.canvas.discardActiveObject();
    this.canvas.renderAll(); 
    // The click occurred on or within the "mydiv" element
    // Do something with the selected div
    // ...
  }
  else{
    dd.style.border = "1px solid black"
    dd.style.zIndex ="1"
  }
});


canvas.addEventListener('touchstart', (event) => {
  this.threedydivselected=false;
  console.log("event1 ",event)
  
  var myDiv = (event.target as Element).closest('#threedydiv');
  const dd = document.getElementById('threedydiv') as HTMLElement;
  
  if (myDiv) {
    console.log("div element selected")
    dd.style.border = "5px solid orange"
    dd.style.zIndex ="10"
    this.threedydivselected=true;
    this.canvas.discardActiveObject();
    this.canvas.renderAll(); 
   
  }
  else{
    dd.style.border = "1px solid black"
    dd.style.zIndex ="1"
  }
});

canvas.addEventListener('mousedown', (event) => {
  this.threedydivselected=false;
  console.log("event1 ",event)
  
  var myDiv = (event.target as Element).closest('#threedydiv');
  const dd = document.getElementById('threedydiv') as HTMLElement;
  
  if (myDiv) {
    console.log("div element selected")
    dd.style.border = "5px solid orange"
    dd.style.zIndex ="10"
    this.threedydivselected=true;
    this.canvas.discardActiveObject();
    this.canvas.renderAll(); 
    
  }
  else{
    dd.style.border = "1px solid black"
    dd.style.zIndex ="1"
  }
});






   //youtube videos iframe drag and resize
    var draggableDiv = document.getElementById("mydiv");
    var draggableDivHeader = document.getElementById("mydivheader");
    var canvas = document.getElementById("canvas");
    var canvasContainer = document.getElementById("canvasContainer");
    var isDragging = false;
    var initialX, initialY;
    
    
    draggableDivHeader.addEventListener("mousedown", startDragging, { passive: false });
    draggableDivHeader.addEventListener("touchstart", startDragging, { passive: false });
    
    
    document.addEventListener("mouseup", stopDragging);
    document.addEventListener("touchend", stopDragging);
    
   
    document.addEventListener("mousemove", dragDiv, { passive: false });
    document.addEventListener("touchmove", dragDiv, { passive: false });
    
    function startDragging(event) {
      event.preventDefault();
      isDragging = true;
      
      if (event.type === "mousedown") {
        initialX = event.clientX - draggableDiv.getBoundingClientRect().left;
        initialY = event.clientY - draggableDiv.getBoundingClientRect().top;
      } else if (event.type === "touchstart") {
        console.log("touch evet",event)
        var touch = event.touches[0];
        initialX = touch.clientX - draggableDiv.getBoundingClientRect().left;
        initialY = touch.clientY - draggableDiv.getBoundingClientRect().top;
      }
    }
    
    function stopDragging() {
      isDragging = false;
    }
    
    function dragDiv(event) {
      if (isDragging) {
        event.preventDefault();
        
        if (event.type === "mousemove") {
          var x = event.clientX - initialX;
          var y = event.clientY - initialY;
          draggableDiv.style.left = x + "px";
          draggableDiv.style.top = y + "px";
        } else if (event.type === "touchmove") {
          console.log("touch move ",event)
          var touch = event.touches[0];
          var x = touch.clientX - initialX;
          var y = touch.clientY - initialY;
          draggableDiv.style.left = x + "px";
          draggableDiv.style.top = y + "px";
        }
      }
    }


    //web page iframe drag and resize
    var draggablewebDiv = document.getElementById("webdiv");
    var draggablewebDivHeader = document.getElementById("webmydivheader");
    var canvas = document.getElementById("canvas");
    var canvasContainer = document.getElementById("canvasContainer");
    var issDragging = false;
    var initialXX, initialYY;
    
    
    draggablewebDivHeader.addEventListener("mousedown", startDragging1, { passive: false });
    draggablewebDivHeader.addEventListener("touchstart", startDragging1, { passive: false });
    
    
    document.addEventListener("mouseup", stopDragging1);
    document.addEventListener("touchend", stopDragging1);
    
    
    document.addEventListener("mousemove", dragDiv1, { passive: false });
    document.addEventListener("touchmove", dragDiv1, { passive: false });
    
    function startDragging1(event) {
      console.log("start dragging for webpage")
      event.preventDefault();
      issDragging = true;
      
      if (event.type === "mousedown") {
        initialXX = event.clientX - draggablewebDiv.getBoundingClientRect().left;
        initialYY = event.clientY - draggablewebDiv.getBoundingClientRect().top;
      } else if (event.type === "touchstart") {
        console.log("touch evet",event)
        var touch = event.touches[0];
        initialXX = touch.clientX - draggablewebDiv.getBoundingClientRect().left;
        initialYY = touch.clientY - draggablewebDiv.getBoundingClientRect().top;
      }
    }
    
    function stopDragging1() {
      issDragging = false;
    }
    
    function dragDiv1(event) {
      if (issDragging) {
        event.preventDefault();
        
        if (event.type === "mousemove") {
          var x = event.clientX - initialXX;
          var y = event.clientY - initialYY;
          draggablewebDiv.style.left = x + "px";
          draggablewebDiv.style.top = y + "px";
        } else if (event.type === "touchmove") {
          console.log("touch move ",event)
          var touch = event.touches[0];
          var x = touch.clientX - initialXX;
          var y = touch.clientY - initialYY;
          draggablewebDiv.style.left = x + "px";
          draggablewebDiv.style.top = y + "px";
        }
      }
    }



    //videos iframe drag and resize
    var draggablevidDiv = document.getElementById("videodiv");
    var draggablevidDivHeader = document.getElementById("videodivheader");
   
    var issvidDragging = false;
    var initialXXvid, initialYYvid;
    
   
    draggablevidDivHeader.addEventListener("mousedown", startDragging2, { passive: false });
    draggablevidDivHeader.addEventListener("touchstart", startDragging2, { passive: false });
    
   
    document.addEventListener("mouseup", stopDragging2);
    document.addEventListener("touchend", stopDragging2);
    
    
    document.addEventListener("mousemove", dragDiv2, { passive: false });
    document.addEventListener("touchmove", dragDiv2, { passive: false });
    
    function startDragging2(event) {
      console.log("start dragging for video")
      event.preventDefault();
      issvidDragging = true;
      
      if (event.type === "mousedown") {
        initialXXvid = event.clientX - draggablevidDiv.getBoundingClientRect().left;
        initialYYvid = event.clientY - draggablevidDiv.getBoundingClientRect().top;
      } else if (event.type === "touchstart") {
        console.log("touch evet",event)
        var touch = event.touches[0];
        initialXXvid = touch.clientX - draggablevidDiv.getBoundingClientRect().left;
        initialYYvid = touch.clientY - draggablevidDiv.getBoundingClientRect().top;
      }
    }
    
    function stopDragging2() {
      issvidDragging = false;
    }
    
    function dragDiv2(event) {
      if (issvidDragging) {
        event.preventDefault();
        
        if (event.type === "mousemove") {
          var x = event.clientX - initialXXvid;
          var y = event.clientY - initialYYvid;
          draggablevidDiv.style.left = x + "px";
          draggablevidDiv.style.top = y + "px";
        } else if (event.type === "touchmove") {
          console.log("touch move ",event)
          var touch = event.touches[0];
          var x = touch.clientX - initialXXvid;
          var y = touch.clientY - initialYYvid;
          draggablevidDiv.style.left = x + "px";
          draggablevidDiv.style.top = y + "px";
        }
      }
    }
    
  
    //3dimage iframe drag and resize
    var draggable3dDiv = document.getElementById("threedydiv");
    var draggable3dDivHeader = document.getElementById("threedydivheader");
    
    var iss3dDragging = false;
    var initialXX3d, initialYY3d;
    
    
    draggable3dDivHeader.addEventListener("mousedown", startDragging3, { passive: false });
    draggable3dDivHeader.addEventListener("touchstart", startDragging3, { passive: false });
    
    
    document.addEventListener("mouseup", stopDragging3);
    document.addEventListener("touchend", stopDragging3);
    
   
    document.addEventListener("mousemove", dragDiv3, { passive: false });
    document.addEventListener("touchmove", dragDiv3, { passive: false });
    
    function startDragging3(event) {
      event.preventDefault();
      iss3dDragging = true;
      
      if (event.type === "mousedown") {
        initialXX3d = event.clientX - draggable3dDiv.getBoundingClientRect().left;
        initialYY3d = event.clientY - draggable3dDiv.getBoundingClientRect().top;
      } else if (event.type === "touchstart") {
        console.log("touch evet",event)
        var touch = event.touches[0];
        initialXX3d = touch.clientX - draggable3dDiv.getBoundingClientRect().left;
        initialYY3d = touch.clientY - draggable3dDiv.getBoundingClientRect().top;
      }
    }
    
    function stopDragging3() {
      iss3dDragging = false;
    }
    
    function dragDiv3(event) {
      if (iss3dDragging) {
        event.preventDefault();
        
        if (event.type === "mousemove") {
          var x = event.clientX - initialXX3d;
          var y = event.clientY - initialYY3d;
          draggable3dDiv.style.left = x + "px";
          draggable3dDiv.style.top = y + "px";
        } else if (event.type === "touchmove") {
          console.log("touch move ",event)
          var touch = event.touches[0];
          var x = touch.clientX - initialXX3d;
          var y = touch.clientY - initialYY3d;
          draggable3dDiv.style.left = x + "px";
          draggable3dDiv.style.top = y + "px";
        }
      }
    }
    
  

  
    
  }

  handleClick(event: MouseEvent){
    console.log('Canvas clicked!', event);
  }


  
  
  

 
  
  /*------------------------Block elements------------------------*/

  // Block "Size"

  changeSize() {
    //this.canvas.setWidth(this.size.width);
    //this.canvas.setHeight(this.size.height);
  }

  // Block "Add text"

  addText() {
    if (this.textString) {
      const text = new fabric.IText(this.textString, {
        left: 10,
        top: 10,
        fontFamily: 'helvetica',
        angle: 0,
        fill: '#000000',
        scaleX: 0.5,
        scaleY: 0.5,
        fontWeight: '',
        hasRotatingPoint: true
      });

      this.extend(text, this.randomId());
      this.canvas.add(text);
      this.selectItemAfterAdded(text);
      this.textString = '';
    }
  }

  // Block "Add images"

  getImgPolaroid(event: any) {
    const el = event.target;
    fabric.loadSVGFromURL(el.src, (objects, options) => {
      const image = fabric.util.groupSVGElements(objects, options);
      image.set({
        left: 10,
        top: 10,
        angle: 0,
        padding: 10,
        cornerSize: 10,
        hasRotatingPoint: true,
      });
      this.extend(image, this.randomId());
      this.canvas.add(image);
      this.selectItemAfterAdded(image);
    });
  }

  // Block "Upload Image"

  addbgImageOnCanvas() {
    var imageURL = "https://iwbeurope.s3.eu-west-1.amazonaws.com/lines.png ";
    var imageObj = new Image();
  imageObj.onload = () => {
    var canvasElement = this.canvas.getElement(); // Get the underlying HTML canvas element
    var canvasWidth = canvasElement.width;
    var canvasHeight = canvasElement.height;
    
    this.canvas.setBackgroundImage(
      imageURL,
      this.canvas.renderAll.bind(this.canvas),
      {
        originX: "left",
        originY: "top",
        top: 0,
        left: 0,
        scaleX: canvasWidth / imageObj.width,
        scaleY: canvasHeight / imageObj.height,
        width: canvasWidth,
        height: canvasHeight
      }
    );
  };
  imageObj.src = imageURL;
    
  }



  addImageOnCanvas(url) {
    if (url) {
      fabric.Image.fromURL(url, (image) => {
        image.set({
          left: 10,
          top: 10,
          angle: 0,
          padding: 10,
          cornerSize: 10,
          hasRotatingPoint: true
        });
        image.scaleToWidth(200);
        image.scaleToHeight(200);
        this.extend(image, this.randomId());
        this.canvas.add(image);
        this.selectItemAfterAdded(image);
      });
    }
  }

  readUrl(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.url = readerEvent.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeWhite(url) {
    this.url = '';
  }

  // Block "Add figure"

  addFigure(figure) {
    let add: any;
    switch (figure) {
      case 'line':
        console.log("adding line",this.props.fill)
        add = new fabric.Line([50, 100, 200, 200], {
          left: 10,
          top: 10,
          stroke:'#3f51b5'
      });
        break;
      
      case 'rectangle':
        add = new fabric.Rect({
          width: 200, height: 100, left: 10, top: 10, angle: 0,
          fill: '#3f51b5'
        });
        break;
      case 'square':
        add = new fabric.Rect({
          width: 100, height: 100, left: 10, top: 10, angle: 0,
          fill: '#4caf50'
        });
        break;
      case 'triangle':
        add = new fabric.Triangle({
          width: 100, height: 100, left: 10, top: 10, fill: '#2196f3'
        });
        break;
      case 'circle':
        add = new fabric.Circle({
          radius: 50, left: 10, top: 10, fill: '#ff5722'
        });
        break;
    }
    this.extend(add, this.randomId());
    this.canvas.add(add);
    this.selectItemAfterAdded(add);
  }

  /*Canvas*/

  cleanSelect() {
    this.canvas.discardActiveObject().renderAll();
  }

  selectItemAfterAdded(obj) {
    this.canvas.discardActiveObject().renderAll();
    this.canvas.setActiveObject(obj);
  }

  setCanvasFill() {
    if (!this.props.canvasImage) {
      this.canvas.backgroundColor = this.props.canvasFill;
      this.canvas.renderAll();
    }
  }

  extend(obj, id) {
    obj.toObject = ((toObject) => {
      return function() {
        return fabric.util.object.extend(toObject.call(this), {
          id
        });
      };
    })(obj.toObject);
  }

  setCanvasImage() {
    const self = this;
    if (this.props.canvasImage) {
      this.canvas.setBackgroundColor(new fabric.Pattern({ source: this.props.canvasImage, repeat: 'repeat' }), () => {
        self.props.canvasFill = '';
        self.canvas.renderAll();
      });
    }
  }

  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }

  /*------------------------Global actions for element------------------------*/

  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) { return ''; }

    if (object.getSelectionStyles && object.isEditing) {
      return (object.getSelectionStyles()[styleName] || '');
    } else {
      return (object[styleName] || '');
    }
  }

  setActiveStyle(styleName, value: string | number, object: fabric.IText) {
    object = object || this.canvas.getActiveObject() as fabric.IText;
    if (!object) { return; }

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;

      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.setSelectionStyles({underline: true});
        } else {
          object.setSelectionStyles({underline: false});
        }

        if (value.includes('overline')) {
          object.setSelectionStyles({overline: true});
        } else {
          object.setSelectionStyles({overline: false});
        }

        if (value.includes('line-through')) {
          object.setSelectionStyles({linethrough: true});
        } else {
          object.setSelectionStyles({linethrough: false});
        }
      }

      object.setSelectionStyles(style);
      object.setCoords();

    } else {
      if (typeof value === 'string') {
        if (value.includes('underline')) {
        object.set('underline', true);
        } else {
          object.set('underline', false);
        }

        if (value.includes('overline')) {
          object.set('overline', true);
        } else {
          object.set('overline', false);
        }

        if (value.includes('line-through')) {
          object.set('linethrough', true);
        } else {
          object.set('linethrough', false);
        }
      }

      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }


  getActiveProp(name) {
    const object = this.canvas.getActiveObject();
    if (!object) { return ''; }

    return object[name] || '';
  }

  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject();
    if (!object) { return; }
    console.log("set using active prop 11")
    object.set(name, value).setCoords();
    console.log("set using active prop 22")
    this.canvas.renderAll();
  }

  clone() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      let clone;
      switch (activeObject.type) {
        case 'rect':
          clone = new fabric.Rect(activeObject.toObject());
          break;
        case 'circle':
          clone = new fabric.Circle(activeObject.toObject());
          break;
        case 'line':
          clone = new fabric.Line(activeObject.toObject());
          break;
        case 'triangle':
          clone = new fabric.Triangle(activeObject.toObject());
          break;
        case 'i-text':
          clone = new fabric.IText('', activeObject.toObject());
          break;
        case 'image':
          clone = fabric.util.object.clone(activeObject);
          break;
      }
      if (clone) {
        clone.set({ left: 10, top: 10 });
        this.canvas.add(clone);
        this.selectItemAfterAdded(clone);
      }
    }
  }

  getId() {
    this.props.id = this.canvas.getActiveObject().toObject().id;
  }

  setId() {
    const val = this.props.id;
    const complete = this.canvas.getActiveObject().toObject();
    console.log(complete);
    this.canvas.getActiveObject().toObject = () => {
      complete.id = val;
      return complete;
    };
  }

  getOpacity() {
    this.props.opacity = this.getActiveStyle('opacity', null) * 100;
  }

  setOpacity() {
    this.setActiveStyle('opacity', parseInt(this.props.opacity, 10) / 100, null);
  }

  getFill() {
    console.log("get fill 111111",this.props.fill)
    this.props.fill = this.getActiveStyle('fill', null);
    console.log("get fill 222222",this.props.fill)
  }

  setFill() {
    console.log("set fill 11111",this.props.fill)
    this.setActiveStyle('fill', this.props.fill, null);
    console.log("set fill 2222222",this.props.fill)
  }

  setstroke(){
    console.log("set stroke1111",this.props.stroke)
    this.setActiveStyle('stroke', this.props.stroke, null);
    console.log("set stroke22222",this.props.stroke)
  }

  getstroke() {
    console.log("get stroke111",this.props.stroke)
    this.props.stroke=this.getActiveStyle('stroke', null);
    console.log("get stroke2222",this.props.stroke)

  }

  getLineHeight() {
    this.props.lineHeight = this.getActiveStyle('lineHeight', null);
  }

  setLineHeight() {
    this.setActiveStyle('lineHeight', parseFloat(this.props.lineHeight), null);
  }

  getCharSpacing() {
    this.props.charSpacing = this.getActiveStyle('charSpacing', null);
  }

  setCharSpacing() {
    this.setActiveStyle('charSpacing', this.props.charSpacing, null);
  }

  getFontSize() {
    this.props.fontSize = this.getActiveStyle('fontSize', null);
  }

  setFontSize() {
    this.setActiveStyle('fontSize', parseInt(this.props.fontSize, 10), null);
  }

  getBold() {
    this.props.fontWeight = this.getActiveStyle('fontWeight', null);
  }

  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '', null);
  }

  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    if (this.props.fontStyle) {
      this.setActiveStyle('fontStyle', 'italic', null);
    } else {
      this.setActiveStyle('fontStyle', 'normal', null);
    }
  }

  getTextDecoration() {
    this.props.TextDecoration = this.getActiveStyle('textDecoration', null);
  }

  setTextDecoration(value) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, 'g'), '');
    } else {
      iclass += ` ${value}`;
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle('textDecoration', this.props.TextDecoration, null);
  }

  hasTextDecoration(value) {
    return this.props.TextDecoration.includes(value);
  }

  getTextAlign() {
    this.props.textAlign = this.getActiveProp('textAlign');
  }

  setTextAlign(value) {
    this.props.textAlign = value;
    this.setActiveProp('textAlign', this.props.textAlign);
  }

  getFontFamily() {
    this.props.fontFamily = this.getActiveProp('fontFamily');
  }

  setFontFamily() {
    this.setActiveProp('fontFamily', this.props.fontFamily);
  }

  /*System*/


  removeSelected() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      this.canvas.remove(activeObject);
      // this.textString = '';
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      const self = this;
      activeGroup.forEach((object) => {
        self.canvas.remove(object);
      });
    }
  }

  bringToFront() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      activeObject.bringToFront();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.bringToFront();
      });
    }
  }

  sendToBack() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      this.canvas.sendToBack(activeObject);
      activeObject.sendToBack();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.sendToBack();
      });
    }
  }

  confirmClear() {
    if (confirm('Are you sure?')) {
      this.canvas.clear();
    }
  }

  rasterize() {
    const image = new Image();
    image.src = this.canvas.toDataURL({format: 'png'});
    const w = window.open('');
    w.document.write(image.outerHTML);
    this.downLoadImage();
  }

  downLoadImage(){
    const c = this.canvas.toDataURL({format: 'png'});
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = c;
    downloadLink.target = '_self';
    downloadLink.download = Date.now()+'.png';
    downloadLink.click();   
  }

  rasterizeSVG() {
    const w = window.open('');
    w.document.write(this.canvas.toSVG());
    this.downLoadSVG();
    return 'data:image/svg+xml;utf8,' + encodeURIComponent(this.canvas.toSVG());
  }

  downLoadSVG(){
    const c = 'data:image/svg+xml;utf8,' + encodeURIComponent(this.canvas.toSVG());
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = c;
    downloadLink.target = '_self';
    downloadLink.download = Date.now()+'.svg';
    downloadLink.click();   
  }

  saveCanvasToJSON() {
    const json = JSON.stringify(this.canvas);
    localStorage.setItem('Kanvas', json);
    console.log('json');
    console.log(json);

  }

  loadCanvasFromJSON() {
    const CANVAS = localStorage.getItem('Kanvas');
    console.log('CANVAS');
    console.log(CANVAS);

    // and load everything from the same json
    this.canvas.loadFromJSON(CANVAS, () => {
      console.log('CANVAS untar');
      console.log(CANVAS);

      // making sure to render canvas at the end
      this.canvas.renderAll();

      // and checking if object's "name" is preserved
      console.log('this.canvas.item(0).name');
      console.log(this.canvas);
    });

  }

  rasterizeJSON() {
    this.json = JSON.stringify(this.canvas, null, 2);
  }

  resetPanels() {
    this.textEditor = false;
    this.imageEditor = false;
    this.figureEditor = false;
    this.lineeditor = false;
    
  }

  drawingMode(){
    this.canvas.isDrawingMode = !this.canvas.isDrawingMode;
  }

  onResize(data) {
    console.log("gggggggggg",data)
    var winW = window.innerWidth,
      winH = window.innerHeight,
      //curW = this.canvas.getWidth(),

      //curH = this.canvas.getHeight(),
      canW = winW,
      canH = winH;
      console.log("cccccc",winH,winW)
    this.canvas.setWidth(canW);
    this.canvas.setHeight(canH);
    console.log("ddddd",canH,canW)
    this.canvas.renderAll();
  };

  pencolour:any;
  changeclr(data){
    console.log("ssssssssss",data)
    this.pencolour = data;
  }

  forhighlighter:any;
  colorchanged(data) {
     if(this.highlighterselected== false){
      console.log("if condition",data)
      this.colcolcol=data;
      this.props.fill = data
      this.canvas.freeDrawingBrush.color = data;
      console.log("colcol,props.fill, data",this.colcolcol,this.props.fill,data)
      // console.log('jii', data, this.props.fill)
      this.setFill()
    }
     else{
      this.colcolcol=data;
      this.selecthighlighter()
      
    }
  }

  strokethicknes:any;
  thick(data){
    console.log("thickness",data)
    this.props.fill=data;
    this.strokethicknes=data;
    this.props.width=data;
    this.canvas.freeDrawingBrush.width=data;

  }

  selectedOption: string | null = null;

selectPenCil() {
  this.highlighterselected=false;
  console.log("1xxxxxxxxxxxxxx11111111111111")
  this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
  this.canvas.freeDrawingBrush.color=this.colcolcol;
  this.canvas.isDrawingMode = true;
  this.canvas.freeDrawingBrush.width = this.strokethicknes;
}


selectcirbrush() {
  this.highlighterselected=false;
  console.log("3333333333333333",this.strokethicknes)
  this.canvas.isDrawingMode = true;
  this.canvas.freeDrawingBrush = new fabric.CircleBrush(this.canvas);
  this.canvas.freeDrawingBrush.color=this.colcolcol;
  this.canvas.freeDrawingBrush.width = this.strokethicknes;
}

highlighterselected:boolean=false;
selecthighlighter(){
  this.highlighterselected=true;
  console.log("selected color",this.colcolcol)
  const rgbaColor = new fabric.Color(this.colcolcol);
  rgbaColor.setAlpha(0.5);
  this.forhighlighter = rgbaColor.toRgba();;
  console.log("222222222222222",this.forhighlighter)
  this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
  this.canvas.freeDrawingBrush.color=this.forhighlighter
  this.canvas.isDrawingMode = true;
  this.canvas.freeDrawingBrush.width = 25;
}

eraseObject(): void {
  console.log("eraseObject")
  // if (this.eraserEnabled) {
    // this.canvas.isDrawingMode = true;
    // this.canvas.freeDrawingBrush.width = 20;
    // this.canvas.freeDrawingBrush.color = 'white';
    this.canvas.clear;
     
  // }
 }
  


closepend(){
  this.canvas.isDrawingMode = false;
}

multitouchdraw(){
  
}

divele:boolean=false;
dragiframe(){
  this.divele=true;
}
selectobject(){
  console.log("drawingmode")
  this.canvas.isDrawingMode=!this.canvas.isDrawingMode
}

deleteActiveObject() {
  if(this.webbbbbbselected == true){
    this.removewebiframe();
  }
  else if (this.youtubeselected== true){
    this.removeytiframe();
  }
  else if (this.videodivselected== true){
    this.removevideoiframe();
  }
  else if (this.threedydivselected== true){
    this.remove3diframe();
  }

  else{
  var activeObject = this.canvas.getActiveObject();
  if (activeObject) {
      this.canvas.remove(activeObject);
  }
  var objs;
  objs = this.canvas._objects.filter(function (obj) {
      return obj;
  });
  console.log("checking content",objs);
  if (objs.length == 0) {
      console.log("content missing")
      //this.isDisabled=true;
      return;
  }
}
}

clearAll(){
  this.canvas.clear();
  var objs;
  objs = this.canvas._objects.filter(function (obj) {
      return obj;
  });
  
  console.log("checking content",objs);
  if (objs.length == 0) {
      console.log("content missing")
      //this.isDisabled=true;
      return;
  }

}


removeDiv() {
  console.log("newwwwwww")
  const divToRemove = document.getElementById('mydiv');
  if (divToRemove) {
    divToRemove.remove();
  }
}

showDiv:boolean=false;

addDiv() {
  this.showDiv = true;
//   const canvasContainer = document.getElementById('canvasContainer');

//   // Create the div element
//   const div = document.createElement('div');
//   div.innerHTML = 
//   document.getElementById("mydivheader").innerHTML 
//  // div.textContent = 'Dynamic Div';
//   div.className='mydiv';
//   div.style.position= "absolute";
//   div.style.zIndex= "9";
//   div.style.backgroundColor="#f1f1f1;";
//   div.style.border="1px solid #d3d3d3";
//   div.style.resize="both";
//   div.style.overflow=" auto";

//   // Append the div to the container
//   canvasContainer.appendChild(div);
}
removeytiframe(){
  this.showDiv=false;
  const frameZones = Array.from(document.querySelectorAll('iframe'));
  frameZones.forEach(v => { v.src = v.src });
}

isShowYtt:boolean=false;
addyoutubevideo(data){
this.isShowYtt=true;
console.log("youtube video",data)
console.log("sameer",data.data)
//this.ytvid=data.data;
this.ytvid = this.sanitizer.bypassSecurityTrustResourceUrl(data.data);
this.showDiv = true;
console.log("sanitizer",this.ytvid)


}
isShowwebb:boolean=false;
addwebpage(data){
  console.log("webpage",data)
  this.weburl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
  this.webshowDiv = true;
  this.isShowwebb = true;
}
webshowDiv:boolean=false;
addwebdiv(){
  this.webshowDiv = true;
}
removewebiframe(){
  this.webshowDiv = false;
}


videoshowDiv:boolean=false;
isShowiframevideos:boolean=false;
addvideodiv(vurl){
  console.log("inside addvideo in fabric editor ",vurl)
  this.videoshowDiv = true;
  this.isShowiframevideos=true;
  this.mainvideourl = this.sanitizer.bypassSecurityTrustResourceUrl(vurl.videourl);
}
removevideoiframe(){
  console.log("removevideoiframe")
  const iframe = document.getElementById('myvIframe') as HTMLIFrameElement;
  if (iframe && iframe.parentNode) {
    iframe.parentNode.removeChild(iframe);
  }
  this.videoshowDiv = false;
}

threedyshowDiv:boolean=false;
isShowiframe3d:boolean=false;
add3dimage(d3url){
  console.log("inside add3dimage in fabric editor ",d3url)
  this.threedyshowDiv = true;
  this.isShowiframe3d=true;
  this.main3dimage = this.sanitizer.bypassSecurityTrustResourceUrl(d3url.three3durl);
}

remove3diframe(){
  console.log("remove3diframe")
  this.threedyshowDiv = false;
}

}







