import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-savepopup',
  templateUrl: './savepopup.component.html',
  styleUrls: ['./savepopup.component.css']
})
export class SavepopupComponent implements OnInit {
  public onClose: Subject<any>;

  constructor(public bsModalRef: BsModalRef) { }
  @Input() public user;

  ngOnInit() {
    this.onClose = new Subject();
    console.log(this.user,"checkkk")
  }
  yes(){
    console.log("inside yes func")
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
  no(){
    console.log("inside no func")
    this.bsModalRef.hide();
  }
  closeModal(){
    this.bsModalRef.hide();
  }

}
