import { Component, OnInit, Output, EventEmitter, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { AuthService } from '../auth.service';
import { FileService } from '../file.service';
//import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { environment } from '../../environments/environment'
import { DialogService } from '../dialog.service';
import { HttpClient } from '@angular/common/http';
//import { BsModalRef } from 'ngx-bootstrap/modal';
//import { LocalizationService } from './../internationalization/localization.service';
//import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-studentavchat',
  templateUrl: './studentavchat.component.html',
  styleUrls: ['./studentavchat.component.scss']
})
export class StudentavchatComponent implements OnInit {
    type: string;
    userName: string;
    public CIDforav="";
    roomId;
    userID: string;
    classes = [];
    public data: any = {};
    publicLessons = [];
    privateLessons = [];
    publicSelect = true;
    privateSelect = true;
    public modes = [
        {
            "key": "collab",
            "value": "Collaborative Whiteboard"
        },
        {
            "key": "present",
            "value": "Presentation"
        }]
    shareType: any;
    licensetype: any
    studentlimit: number;

    //@Output() public closeModal = new EventEmitter();

    constructor(private _Activatedroute: ActivatedRoute, public auth: FileService,
        public router: Router, private http: HttpClient,private dialogService: DialogService,
        //public localizationService: LocalizationService,public ts: TranslateService,public bsModalRef: BsModalRef
        ) { }

    ngOnInit(): void {
        this.checkBrowserSettings();
        //this.userID = localStorage.getItem('userId');
        this.type = localStorage.getItem('type');
        this.userName = localStorage.getItem('username');
        console.log("virtual class component",this.username)
        //console.log("ccccc",this.userID,this.type)
        //console.log(environment.etheradURL);
        // if(this.type == 'teacher'){
        //     this.getClasses();
        //     this.getAllLessions();
        // }
         this._Activatedroute.queryParams.subscribe(params=>{
            this.data.roomId=params['roomId'];
            this.CIDforav=params['classid'];
            // this.teacherid = params['userid'];
            // this.role = params['type']
            this.licensetype = params['licensetype']
            console.log('NumberOfOptions:',this.data.roomId);
        });
        this.createSocektConnetion()
    }

    username:any;
    //accesstokennnnn:any;
    random:any
    registernadloginold(lessonroom){
    //localStorage.setItem('session_id','teacher.english.ab@tpv-tech.com#1705153460762#30ff7fe8-0e0b-4776-a723-6ba56db5507a')
    this.auth.getavchatstudent({name:this.username}, '/authenticateuser').then((res: any) => {
        try {
            console.log('############', lessonroom)
          console.log(',,,,,,,,,,,,,,,,,,,,,,', res)
          this.random=res.body["auth-result"].randomuserId;
          const accesstokennnnn=res.body["auth-result"].accessToken;
          const nameofuser= this.username
          const CIDforav= this.CIDforav
          let shareType;
          let lessonId;
          let pageNum;
          let fileId;
          this.auth.joinLesson(lessonroom).subscribe((res: any) => {
            shareType = res.data.shareType
            lessonId = res.data.lesson_id
            pageNum = res.data.pageNum
            fileId = res.data.fileId
            // this.canvasShare = res.shareaccess;
            // for (var data of this.canvasShare){
            //     if(data.token == localStorage.getItem('roomID')){
            //         shareType = data.type
                    this.router.navigate(['av-chat'], { queryParams: { accesstokennnnn, lessonroom, nameofuser,shareType,lessonId,fileId,pageNum,CIDforav } });
            //         break;
            //     }
            // }

          });
          //this.bsModalRef.hide();
          //this.getRoomDetails()
          //this.validateUser(lesson);
        } catch (e) {
          console.error(e);
        }
      })

    }

    registernadlogin(lessonroom) {
        if (this.licensetype !== 'srcadmin') {
            this.studentlimit = 0;
            console.log(this.licensetype, "licensetype for teacher????")
            if (this.licensetype == '30 user school license' || this.licensetype == '60 user school license'|| this.licensetype == '100 user school license') {
                this.studentlimit = 40
            }
            if (this.licensetype == '200 user school license') {
                this.studentlimit = 200
            }
            //consider as free version license
            if (this.licensetype == 'demo') {
                this.studentlimit = 5
            }
            // Retrieve `membersDisplay` from localStorage
            let studentcount = localStorage.getItem("membersDisplay");
            if (studentcount) {
                // Parse the stringified array
                const studentArray = JSON.parse(studentcount);

                // Ensure it's a valid array
                if (Array.isArray(studentArray)) {
                    if (studentArray.length > this.studentlimit) {
                        console.warn("Array count exceeds the limit. Exiting...");
                        this.dialogService.showError("Student Limit is: " + this.studentlimit);
                        // alert("Students Limit is : " + this.studentlimit);
                        return; // Stop further execution
                    } else {
                        console.log("Array count is within limit. Proceeding...");
                        this.proceedWithLogin(lessonroom); // Handle login logic separately
                    }
                } else {
                    console.error("membersDisplay is not an array.");
                }
            } else {
                console.warn("membersDisplay is not found in localStorage. Initializing...");
                // Initialize membersDisplay as an empty array
                localStorage.setItem("membersDisplay", JSON.stringify([]));
                this.proceedWithLogin(lessonroom); // Handle login logic separately
            }
        } else {
            this.proceedWithLogin(lessonroom);
        }
    }

    // Separate method for the login process
    proceedWithLogin(lessonroom) {
        this.auth.getavchatstudent({ name: this.username }, '/authenticateuser').then((res: any) => {
            try {
                console.log('Lesson Room:', lessonroom);
                console.log('Response:', res);

                this.random = res.body["auth-result"].randomuserId;
                const accesstokennnnn = res.body["auth-result"].accessToken;
                const nameofuser = this.username;
                const CIDforav = this.CIDforav;

                let shareType;
                let lessonId;
                let pageNum;
                let fileId;

                this.auth.joinLesson(lessonroom).subscribe((res: any) => {
                    shareType = res.data.shareType;
                    lessonId = res.data.lesson_id;
                    pageNum = res.data.pageNum;
                    fileId = res.data.fileId;

                    // Navigate to 'av-chat' with query parameters
                    this.router.navigate(['av-chat'], {
                        queryParams: {
                            accesstokennnnn,
                            lessonroom,
                            nameofuser,
                            shareType,
                            lessonId,
                            fileId,
                            pageNum,
                            CIDforav
                        }
                    });
                });
            } catch (e) {
                console.error("Error during login:", e);
            }
        });
    }


    goToLessions(lesson) {
        console.log("____",this.data);
        console.log("asdfghjkl",lesson);
        // this.sendroomid(lesson);
        localStorage.setItem('roomID', lesson);
        if((this.data.classesName && this.data.lesson && this.data.classMode) || this.data.roomId){
        lesson = lesson.replace(/\s/g, "");

        this.router.navigate([`/av-chat`])
        //this.validateUser(lesson);
        //this.getRoomDetails();
        }else{
            //alert(this.ts.instant('dashboard.Enter required fields'));
        }
    }
    cancel() {
        //this.bsModalRef.hide();
    }
    getRoomDetails(){
        console.log("room",this.data.roomId)
        this.auth
            .getTVInformation({roomId:this.data.roomId}, "/getKmsRoom")
            .then(
                (res: any) => {
                    try {
                        if (res) {
                            console.log("resData_GET CLASSES______________", res);
                            // if(res.getClass && res.getClass == "success"){
                                this.router.navigate([`/av-chat`])
                                console.log("navigate")
                               // this.router.navigate([`/whiteboard/${res.lessonId}/${res.padId}/${btoa(res.roomId)+'?'+btoa(res.classMode)}`])
                                // this.router.navigate([`/metaverse/${res.lessonId}/${res.padId}/${btoa(res.roomId)+'?'+btoa(res.classMode)}`])
                                // }
                        } else {
                            console.error("General error");
                        }
                    } catch (e) {
                        console.error(e);
                    }
                },
                (err: any) => {
                    console.error(err);
                }
            );
    }
    validateUser(roomId) {
        console.log("calling validateUser______________________");
        console.log("____",this.data);
        this.auth
            .getTVInformation( {
                roomId: roomId,
                userType: "student",//this.type,
                userName: this.username,//"Student 11 a 01",//this.userName,
                userId: "student.11.a.01@tpv-tech.com"//this.random//"student.11.a.01@tpv-tech.com"//this.userID
            }, "/validateUser")
            .then(
                (res: any) => {
                    try {
                        if (res) {
                            console.log("resData_VALIDATE STUDENT______________", res);
                            //if(res.validateUser && res.validateUser == "success"){
                                //this.bsModalRef.hide(); // res._id res.lessionpadID
                                // this.data.lesson._id &&
                                //if(this.type == "student" && roomId){
                                 //   this.getRoomDetails();
                                // }else if(this.type == "teacher" && this.data.lesson._id && this.data.lesson.lessionpadID && roomId && this.data.classMode){
                                //     // alert('herere')
                                   // this.router.navigate([`/av-chat`])
                                //     // this.router.navigate([`/metaverse/${this.data.lesson._id}/${this.data.lesson.lessionpadID}/${btoa(roomId)+'?'+btoa(this.data.classMode)}`])
                                // }else{
                                //     alert("Enter mandatory fields")
                                // }
                            //}
                           // else{
                                //alert(this.ts.instant("dashboard.You do not have permission to access this class"));
                           // }
                        } else {
                            //this.bsModalRef.hide();
                            console.error("General error");
                        }
                    } catch (e) {
                        //this.bsModalRef.hide();
                        console.error(e);
                    }
                },
                (err: any) => {
                    console.error(err);
                }
            );
    }
    getClasses() {
             let  typeName={};
             typeName['type']="Individual";
        this.auth
            .getTVInformation(typeName, "/getClasses")
            .then(
                (res: any) => {
                    try {
                        if (res) {
                            console.log("resData_GET CLASSES______________", res);
                            if(res.getClass && res.getClass == "success"){
                                this.classes = res.classes
                                this.sortFunction()
                            }
                        } else {
                            console.error("General error");
                        }
                    } catch (e) {
                        console.error(e);
                    }
                },
                (err: any) => {
                    console.error(err);
                }
            );
    }

    sortFunction(){
        this.classes.sort((a,b) => (a.name > b.name ? 1 : ((b.name > a.name) ? -1 : 0)))
    }

    // getAllLessions() {
    //     console.log("____",this.data);
    //     this.auth.getTVInformation({ userID: this.userID }, '/getAllLessions').then((res: any) => {
    //         try {
    //             this.publicLessons = []
    //             this.privateLessons = []
    //             if (res) {
    //                 console.log("resData_getAllLessions_____________", res);
    //                 res.map(val => {
    //                     if (val.isPublic) {
    //                         this.publicLessons.push(val)
    //                     } else if (!val.isPublic && val.creatorUserId == this.userID) {
    //                         this.privateLessons.push(val)
    //                     }
    //                 })
    //             } else {
    //                 console.error("General error");
    //             }
    //         } catch (e) {
    //             console.error(e);
    //         }
    //     });
    // }
    radibuttonCheck(type) {
        console.log("____",this.data);
        if(type == 'private'){
            this.privateSelect = false;
            this.publicSelect = true;
        }else if(type == 'public'){
            this.privateSelect = true;
            this.publicSelect = false;
        }
    }

    createSocektConnetion(){
        console.log("calling create socket")
        this.auth.joinToClass()
        this.auth.emitWebSocketEvent("joinSocketRoom",{roomid:this.data.roomId})
    }

    clear(){
        this.username="";
     }



  checkBrowserSettings() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    console.log("gvgvgvgvg",screenWidth,screenHeight)

    this.http.post(environment.serverurl+'/api/check-resolution', { screenWidth, screenHeight }).subscribe((response: any) => {
      if (response.match) {
        console.log("Screen resolution match");
      } else {
        console.log("Screen resolution does not match");
        // this.dialogService.showError('Please use higher resolution for the optimal experience.');
        // this.router.navigate(['/browser-warning']);
      }
    }, (error) => {
      console.error('Error checking resolution:', error);
    });
  }
}
