import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, take } from "rxjs/operators";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";
import { BehaviorSubject, Subject } from "rxjs";
import * as io from "socket.io-client";
import { ConnectionService } from "ng-connection-service";
import { BrowserDetectionService } from "./browser-detection.service";

@Injectable({
  providedIn: "root",
})
export class FileService {
  private triggerState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private cameraChangeSubject = new BehaviorSubject<MediaDeviceInfo[]>([]);
  cameraChange$: Observable<MediaDeviceInfo[]> = this.cameraChangeSubject.asObservable();

  private logSubject = new BehaviorSubject<string | null>(null);
  log$: Observable<string | null> = this.logSubject.asObservable();

  private previousCameras: MediaDeviceInfo[] = [];

   // Method to get public IP address
   getPublicIp(): Observable<any> {
    return this.http.get('https://api.ipify.org?format=json');
  }
  setTriggerState(state: boolean) {
    console.log("fileservice", state);
    this.triggerState.next(state);
  }

  getTriggerState(): Observable<boolean> {
    return this.triggerState.asObservable();
  }
  typeObs: BehaviorSubject<any>;
  stpsharingObs: BehaviorSubject<any>;
  checkCurrentModel: BehaviorSubject<any>;
  curType;
  currentModel;
  currentWhiteboardMode: String = "collb";
  stpsharing;
  socket: any;
  callchatsocket;
  CS_;
  CS__;
  d = {};
  N;
  R;
  p = false;
  Message: string[] = [];
  NAme: string[] = [];
  sub_to_handraise_once_in_ekms;
  sub_to_handraise_once_in_etp;
  status = "ONLINE"; //initializing as online by default
  isConnected: boolean = true;
  googleapitoken;
  googlecxtoken;
  private onlineStatus: BehaviorSubject<boolean>;
  constructor(
    private http: HttpClient,
    public router: Router,
    private connectionService: ConnectionService,
    private browserDetectionService: BrowserDetectionService
  ) {
    this.googleapitoken = environment.googleapitoken;
    this.googlecxtoken = environment.googlecxtoken;
    this.sub_to_handraise_once_in_ekms = true;
    this.sub_to_handraise_once_in_etp = true;
    this.callchatsocket = true;
    this.CS_ = true;
    this.CS__ = true;
    this.typeObs = new BehaviorSubject<any>({});
    this.checkCurrentModel = new BehaviorSubject<any>({});
    this.stpsharingObs = new BehaviorSubject<any>({});
    this.initializeDeviceChangeDetection();
    this.newRequest();
    this.socket.on("reconnect", () => {
      if (localStorage.getItem("type") == "teacher") {
        this.socket.emit(
          "emit_handraise",
          { username: localStorage.getItem("username"), ishandraised: false },
          { roomid: localStorage.getItem("roomID"), user: "teacher" }
        );
      }
      this.socket.emit("curt/spotlRoomJoin", {
        roomid: localStorage.getItem("roomID"),
      });
      this.d["userID"] = localStorage.getItem("remoteUser");
      this.d["roomID"] = localStorage.getItem("roomID");
      if (
        localStorage.getItem("remoteUser") != "null" &&
        localStorage.getItem("remoteUser") != null
      ) {
        console.log(
          "socket reconceted................................",
          this.d,
          localStorage.getItem("remoteUser")
        );
        this.socket.emit("user-rejoined", this.d);
        this.Joinroom(localStorage.getItem("roomID"));
      }
      // console.log('socket reconceted................................', localStorage.getItem('padInfo') != 'null' && localStorage.getItem('padInfo') != null)
      if (
        localStorage.getItem("padInfo") != "null" &&
        localStorage.getItem("padInfo") != null
      ) {
        this.socket.emit("scrachpad:reconect", localStorage.getItem("padInfo"));
      }
    });

    this.connectionService.monitor().subscribe((isConnected) => {
      if (isConnected.hasNetworkConnection) {
        this.isConnected = true;
      } else {
        this.isConnected = false;
      }
      this.connectionStatus.next(isConnected.hasNetworkConnection);
    });
  }

  // getImages():Observable<any>
  // {
  //   return this.http.get<any>(environment.serverurl+'/allimages');
  // }

  private initializeDeviceChangeDetection(): void {
    navigator.mediaDevices.addEventListener('devicechange', this.handleDeviceChange);
    this.updateCameraList();
  }

  private handleDeviceChange = () => {
    this.updateCameraList();
  };

  private async updateCameraList() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputs = devices.filter(device => device.kind === 'videoinput');


      this.detectCameraChanges(videoInputs);
      this.previousCameras = videoInputs;
      this.cameraChangeSubject.next(videoInputs);

    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  }

  private detectCameraChanges(currentCameras: MediaDeviceInfo[]) {
    const previousCameraIds = this.previousCameras.map(camera => camera.deviceId);
    const currentCameraIds = currentCameras.map(camera => camera.deviceId);


    currentCameraIds.forEach(deviceId => {
      if (!previousCameraIds.includes(deviceId)) {
        const camera = currentCameras.find(cam => cam.deviceId === deviceId);
        const message = `Camera connected: ${camera?.label || 'Unknown Camera'}`;
        console.log(message);
        this.logSubject.next(message);
      }
    });


    previousCameraIds.forEach(deviceId => {
      if (!currentCameraIds.includes(deviceId)) {
        const camera = this.previousCameras.find(cam => cam.deviceId === deviceId);
        const message = `Camera disconnected: ${camera?.label || 'Unknown Camera'}`;
        console.log(message);
        this.logSubject.next(message);
      }
    });
  }


  ngOnDestroy(): void {
    navigator.mediaDevices.removeEventListener('devicechange', this.handleDeviceChange);
  }

  getImages(): Observable<any> {
    return this.http.get<any>(environment.serverurl + "/getuploadcontent");
  }

  get3dImages(): Observable<any> {
    return this.http.get<any>(environment.serverurl);
  }

  getVideos(): Observable<any> {
    return this.http.get<any>(environment.serverurl + "/allvideos");
  }

  getWebsites(domainName, siteName) {
    let url = `https://sites.google.com/feeds/activity/${domainName}/${siteName}`;
    return this.http.get(url);
  }

  getEmaillist(emailId: string) {
    console.log("file.service.ts log of getemail()");
    return this.http.post(environment.serverurl + "/getemaillist", { emailId });
  }

  sendEmail(emailId: string, link: string) {
    console.log("file.service.ts log of sendEmail()");
    return this.http.post(environment.serverurl + "/send-email", {
      emailId,
      link,
    });
  }
  sendpdfEmail(emailId: any, link: string) {
    console.log("file.service.ts log of sendEmail()");
    return this.http.post(environment.serverurl + "/sendemailpdf", {
      emailId,
      link,
    });
  }

  sendForgotPasswordEmail(emailid: string, resetlink: string) {
    console.log("file.service log of sendForgotPasswordEmail");
    return this.http.post(environment.serverurl + "/forgotpassword", {
      emailid,
      resetlink,
    });
  }

  resetPassword(
    token: string,
    emailid: string,
    newPassword: string,
    confirmPassword: string
  ) {
    console.log("file.service log of resetPassword");
    return this.http.post(environment.serverurl + `/reset-password/${token}`, {
      emailid,
      newPassword,
      confirmPassword,
    });
  }
  sendDemoPasswordEmail(emailid: string, resetlink: string, firstname: string) {
    console.log("file.service log of sendForgotPasswordEmail");
    return this.http.post(environment.serverurl + "/demoemail", {
      emailid,
      resetlink,
      firstname,
    });
  }
  // getsavedlesson():Observable<any>
  // {
  //   return this.http.get<any>('https://inblrlxapp003.tpvaoc.com/iwbNode/get-all-canvas');
  // }

  // http://inblrlxapp003.tpvaoc.com/iwbNode/get-all-canvas

  // getalllesson():Observable<any>
  // {
  //   return this.http.get<any>("http://inblrlxapp003.tpvaoc.com/iwbNode/get-all-canvas");
  // }

  getalllesson(): Observable<any> {
    return this.http.get<any>(environment.serverurl + "/get-all-canvas");
  }

  // getlesson_public(): Observable<any> {
  //   return this.http.get<any>(environment.serverurl + "/allfile/public");
  // }

  getlesson_public(searchtext: any, page: any): Observable<any> {
    return this.http.get<any>(
      environment.serverurl +
        `/allfile/public?search=${searchtext}&page=${page}`
    );
  }

  // getlesson_bin(): Observable<any> {
  //   console.log("clling in fileservice");
  //   return this.http.get<any>(environment.serverurl + "/allfile/bin");
  // }

  getlesson_bin(searchtext: any, pagenum: any, userid): Observable<any> {
    console.log("clling in fileservice");
    return this.http.get<any>(
      environment.serverurl +
        `/allfile/bin?userId=${userid}&search=${searchtext}&page=${pagenum}`
    );
  }

  // getlesson_private(lesonowner): Observable<any> {
  //   return this.http.get<any>(environment.serverurl + `/allfile/private/${lesonowner}`);
  // }

  getlesson_private(lesonowner, searchtext, page): Observable<any> {
    return this.http.get<any>(
      environment.serverurl +
        `/allfile/private/${lesonowner}?search=${searchtext}&page=${page}`
    );
  }

  getLessonData(filename: string): Observable<any> {
    const url = `${environment.serverurl}/getcanvas/${filename}`;
    return this.http.get(url);
  }

  synchronous_getLessonData(filename: string) {
    return new Promise((resolve,reject)=>{
        const url = `${environment.serverurl}/getcanvas/${filename}`;
        this.http.get(url).subscribe((response)=>{
          resolve(response);
        },(error)=>{
          console.error(error);
          reject(new Error("Error in getting lesson data"));
        })
    });
  }

  updatefiles(fileId: string, updates: object): Observable<any> {
    const url = `${environment.serverurl}/update-canvasfile/${fileId}`;
    return this.http.post(url, updates);
  }

  updateActivePage(fileId: string, activePage: number) {
    return this.http.post(`${environment.serverurl}/updateActivePage/${fileId}`, { activePage });
  }

  getActivePagedata(lessonId: string) {
    return this.http.get(`${environment.serverurl}/getActivePageData/${lessonId}`);
  }

  synchronous_getActivePagedata(lessonId) {
    return new Promise((resolve,reject)=>{
      this.http.get(`${environment.serverurl}/getActivePageData/${lessonId}`).subscribe((response)=>{
        resolve(response);
      },(error)=>{
        console.error(error);
        reject(new Error("Error in getting active page"));
      })
  });
  }

  updatecontent(contentId: string, updates: object): Observable<any> {
    const url = `${environment.serverurl}/update-contentlib/${contentId}`;
    return this.http.post(url, updates);
  }

  deletecontent(contentId: string): Observable<any> {
    const url = `${environment.serverurl}/delete-contentlib/${contentId}`;
    return this.http.delete(url);
  }

  updateCanvas(
    // fileId: string,
    // totalPage: string,
    // seletedPage: string,
    updates: object
  ): Observable<any> {
    if (this.isConnected) {
      const url = `${environment.serverurl}/update-canvas`;
      return this.http.post(url, updates);
    }
  }

  updatePage(updates):Promise<string> {
    return new Promise((resolve,reject)=>{
      if (this.isConnected) {
        console.log("24-10-2024 updatecanvas called",updates);
        const url = `${environment.serverurl}/update-canvas`;
        this.http.post(url, updates).subscribe((response)=>{
          resolve("Successful");
        },(error)=>{
          reject(new Error("Error in updating a Page."));
        })
      }
    });
  }

  addPage(pageInfo:{fileId:string,newPage:{objects:object[],version:string,
    bgColor: string,
    bgFrame: string,
    customBg: string,
    pageHeight: number,
    pageWidth: number
  },TotalNrPages:number}) {
    return new Promise((resolve,reject)=>{
      if (this.isConnected) {
        const url = `${environment.serverurl}/add-page`;
        this.http.put(url, pageInfo).subscribe((response)=>{
          resolve("Successful");
        },(error)=>{
          reject(new Error("Error in adding a Page."));
        })
      }
    });
  }

  deletePage(lesson_id: any,fileid:any, pageNo: any) {
    return new Promise((resolve,reject)=>{
      if (this.isConnected) {
        const url = environment.serverurl + `/deletePage?lesson_id=${lesson_id}&pageNo=${pageNo}&fileid=${fileid}`;
        this.http.delete(url).subscribe((response)=>{
          resolve("Successful");
        },(error)=>{
          console.error(error);
          reject(new Error("Error in deleting a Page."));
        })
      }
    });
  }

  duplicatefiles(fileId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.serverurl}/duplicate-canvas-file/${fileId}`,
      {}
    );
  }
  duplicatecontents(contentId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.serverurl}/duplicate-content/${contentId}`,
      {}
    );
  }

  duplicatetomylib(fileId: string): Observable<any> {
    const data = {
      IsPublic: false,
    };

    return this.http.post<any>(
      `${environment.serverurl}/duplicate-canvas-file/${fileId}`,
      data
    );
  }

  duplicateresourcetomylib(contentId: string): Observable<any> {
    const userid = localStorage.getItem("userId");
    const data = {
      IsPublic: false,
      userId: userid,
    };

    return this.http.post<any>(
      `${environment.serverurl}/duplicate-to-my-lib/${contentId}`,
      data
    );
  }

  duplicatelessonstomylib(fileId: string): Observable<any> {
    const userid = localStorage.getItem("userId");
    const data = {
      IsPublic: false,
      userId: userid,
    };

    return this.http.post<any>(
      `${environment.serverurl}/duplicatelesson-to-my-lib/${fileId}`,
      data
    );
  }

  //   uploadcontent(data) {
  //     //const formData = new FormData();
  //     //formData.append('file', file);
  //  return this.http.post(`${environment.serverurl}/documentuploadfile`,data);
  //   }

  uploadcontent(data) {
    //const formData = new FormData();
    //formData.append('file', file);
    console.log(data);

    return this.http.post(`${environment.serverurl}/documentuploadfile`, data);
  }

  // thumbnailgeneration(lessonId: string): Observable<any> {
  //   const data = {
  //     lesson_id: lessonId,
  //   };
  //   return this.http.post<any>(
  //     `${environment.serverurl}/page-thumbnail`,
  //     data,
  //     {}
  //   );
  // }

  thumbnailgeneration(
    lessonId: string,
    screenheight: any,
    screenwidth: any
  ): Observable<any> {
    const data = {
      lesson_id: lessonId,
      sw: screenwidth,
      sh: screenheight,
    };
    return this.http.post<any>(
      `${environment.serverurl}/page-thumbnail`,
      data,
      {}
    );
  }

  pdfgeneration(lessonId: string, pageid: string[]): Observable<any> {
    const data = {
      lesson_id: lessonId,
      page_id: pageid,
    };
    return this.http.post(`${environment.serverurl}/generatePDF`, data);
  }
  
  pdfgenerationNew(lessonId: string, pageid: string[] , downloadarr:any): Observable<any> {
    const data = {
      lesson_id: lessonId,
      page_id: pageid,
      downloadarr
    };
    return this.http.post(`${environment.serverurl}/generatePDF`, data);
  }

  //more lession
  //thumnail_Page
  //   postCanvasData(dataUrl: string) {
  //     return this.http.post(environment.serverurl+"/thumbnail_whiteboardPage", { dataUrl });
  //   }

  sendCanvasDataToAPI(canvasData: any): Promise<string> {
    const requestBody = {
      canvasData,
    };

    return this.http
      .post<any>(
        environment.serverurl + "/thumbnail_whiteboardPage",
        requestBody
      )
      .toPromise()
      .then((response) => {
        if (response.data && response.data.imageUrl) {
          console.log("response:->", response);
          return response.data.imageUrl;
        }
        throw new Error("Invalid response from the API");
      });
  }

  updateCanvasData(
    fileId: string,
    pageNum: string,
    canvasData: any[]
  ): Observable<any> {
    // Parse the JSON strings in canvasData into JavaScript objects
    const parsedCanvasData = canvasData;

    // Create the payload for the API request
    // const payload = {
    //   Pages: parsedCanvasData.map((canvasItem) => {
    //     return {
    //       version: canvasItem.version,
    //       objects: canvasItem.objects,
    //     };
    //   }),
    // };

    const payload = {
      Pages: parsedCanvasData,
    };

    // Make the API call to update the canvas file using the environment variable
    return this.http.post(
      `${environment.serverurl}/update-canvasfile/${fileId}/${pageNum}`,
      payload
    );
  }

  public Codes: any;

  public SERVER_BASE = environment.serverurl;
  SERVER_URL = this.SERVER_BASE;

  handleAuthResult(res) {
    try {
      if (
        res.headers &&
        res.headers._headers &&
        res.headers._headers.has("content-type")
      ) {
        var contenttype = res.headers._headers.get("content-type");
        //   console.log("contenttype contenttype[0]",contenttype,contenttype[0])
        if (contenttype && contenttype.length > 0) {
          if (
            !contenttype[0].startsWith("text/csv") &&
            !contenttype[0].startsWith(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) &&
            !contenttype[0].startsWith("application/octet-stream") &&
            !contenttype[0].startsWith("application/zip")
          ) {
            res = JSON.parse(res._body);
            if (res["auth-result"] === "loginRequired") {
              console.log("auth related error", res);
              // localStorage.clear();
              // this.router.navigate(['login']);
              return false;
            }
          }
        }
      }
      return true;
    } catch (e) {
      console.log("Exception in response ...", res, e);
      // localStorage.clear();
      // this.router.navigate(['login']);
      return false;
    }
  }

  resetingPassword(body) {
    console.log("inside reseting password in file.service.ts", this.SERVER_URL);
    return new Promise((resolve, reject) => {
      console.log("promise log of reseting password", body);
      this.http.post(`${this.SERVER_URL}/reset-password`, body).subscribe(
        (res: any) => {
          console.log("inside http.post res:any", res);
          var temp;
          try {
            temp = res;
            console.log("inside try", temp);
            if (temp["auth-result"] == "ok") {
              console.log("inside if resolve");
              resolve(temp);
            } else {
              console.log("inside if reject");
              reject(temp);
            }
          } catch (e) {
            temp = res._body;
          }
        },
        (res: any) => {
          console.log("Reseting password failed", res);
          var temp;
          try {
            temp = JSON.parse(res._body);
          } catch (e) {
            temp = res._body;
          }
          reject(temp);
        }
      );
    });
  }

  authenticateUser(body) {
    console.log("Server URL: ", this.SERVER_URL);
    return new Promise((resolve, reject) => {
      console.log("Authenticating user");
      this.http.post(`${this.SERVER_URL}/user/auth`, body).subscribe(
        (res: any) => {
          console.log("!! authenticateUser: Auth service resolve !!", res);
          var temp;
          try {
            console.log("inside try", temp);
            temp = res;
            //temp = JSON.parse(res);
            console.log("temp", temp);
            if (temp["auth-result"] == "ok") {
              resolve(temp);
            } else {
              reject(temp);
            }
          } catch (e) {
            temp = res._body;
          }
        },
        (res: any) => {
          console.log("authenticateUser: Auth service Reject", res);
          var temp;
          try {
            temp = JSON.parse(res._body);
          } catch (e) {
            temp = res._body;
          }
          reject(temp);
        }
      );
    });
  }

  sam: any;
  getTVInformation(body, url) {
    console.log("inside get tv", body, url);
    var headers: Headers;
    this.sam = localStorage.getItem("session_id");
    console.log("inside get tv", this.sam);

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: localStorage.getItem("session_id"),
      });
      console.log("serverurl", this.SERVER_URL);
      this.http
        .post<any>(`${this.SERVER_URL}${url}`, body, {
          headers,
          observe: "response",
        })
        .subscribe(
          (res: HttpResponse<any>) => {
            console.log(
              "getTVInformation: Auth service Resolve",
              `${this.SERVER_URL}${url}`,
              body,
              res.body
            );
            if (this.handleAuthResult(res)) {
              var temp;
              try {
                console.log("post", res.body);
                temp = JSON.parse(res.body);
                // console.log(temp,"post111")
              } catch (e) {
                temp = res;
              }
              resolve(temp);
            }
          },
          (res: any) => {
            console.log(
              "getTVInformation: Auth service Reject",
              `${this.SERVER_URL}${url}`,
              res
            );
            if (this.handleAuthResult(res)) {
              var temp;
              try {
                temp = JSON.parse(res._body);
              } catch (e) {
                temp = res._body;
              }
              reject(temp);
            }
          }
        );
    });
  }

  getavchatstudent(body, url) {
    console.log("inside get tv", body, url);
    //var headers: Headers;
    //this.sam = localStorage.getItem("session_id");
    console.log("inside get tv", this.sam);

    return new Promise((resolve, reject) => {
      // const headers = new HttpHeaders({
      //   Authorization: localStorage.getItem("session_id"),
      // });
      console.log("serverurl", this.SERVER_URL);
      this.http
        .post<any>(`${this.SERVER_URL}${url}`, body, {
          //headers,
          observe: "response",
        })
        .subscribe(
          (res: HttpResponse<any>) => {
            console.log(
              "getTVInformation: Auth service Resolve",
              `${this.SERVER_URL}${url}`,
              body,
              res.body
            );
            if (this.handleAuthResult(res)) {
              var temp;
              try {
                console.log("post", res.body);
                temp = JSON.parse(res.body);
                // console.log(temp,"post111")
              } catch (e) {
                temp = res;
              }
              resolve(temp);
            }
          },
          (res: any) => {
            console.log(
              "getTVInformation: Auth service Reject",
              `${this.SERVER_URL}${url}`,
              res
            );
            if (this.handleAuthResult(res)) {
              var temp;
              try {
                temp = JSON.parse(res._body);
              } catch (e) {
                temp = res._body;
              }
              reject(temp);
            }
          }
        );
    });
  }

  getSymptomPredictions(body, url) {
    var headers: Headers;
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.SERVER_URL}${url}`, body, {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem("session_id"),
          }),
        })
        .subscribe(
          (res: any) => {
            var temp;
            try {
              temp = JSON.parse(res._body);
            } catch (e) {
              console.log("exception in getting symptoms!!!");
              temp = res._body;
            }
            resolve(temp);
          },
          (res: any) => {
            console.log("getSymptomPredictions: Auth service Reject", res);
            var temp;
            try {
              temp = JSON.parse(res._body);
            } catch (e) {
              temp = res._body;
            }
            reject(temp);
          }
        );
    });
  }

  getInformation(url) {
    var headers: Headers;
    var datatypeset = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem("session_id"),
      }),
    };
    console.log(
      "localStorage.getItem('session_id')",
      localStorage.getItem("session_id")
    );
    console.log("datatypeset", datatypeset);
    return new Promise((resolve, reject) => {
      this.http.get(`${this.SERVER_URL}${url}`).subscribe(
        (res: any) => {
          console.log("getInformation: Auth service Resolve", res);
          // if (this.handleAuthResult(res)) {
          var temp;
          var contenttype = res.headers._headers.get("content-type");
          if (
            contenttype[0].startsWith(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) ||
            contenttype[0].startsWith("application/octet-stream") ||
            contenttype[0].startsWith("application/zip")
          ) {
            console.log("binary data: sending response, ", res);
            temp = res;
          } else {
            try {
              temp = JSON.parse(res._body);
              console.log("binary data: try response, ", res);
            } catch (e) {
              console.log("binary data: catch response, ", e);
              temp = res._body;
              // }
            }
            resolve(temp);
          }
        },
        (res: any) => {
          console.log("getInformation: Auth service Reject", res);
          // if (this.handleAuthResult(res)) {
          var temp;
          try {
            temp = JSON.parse(res._body);
          } catch (e) {
            temp = res._body;
          }
          reject(temp);
          // }
        }
      );
    });
  }

  postTvInformationRequest(data, url) {
    console.log("postTvInformationRequest ctnlist is called");
    return this.http
      .post(`${this.SERVER_URL}${url}`, data, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("session_id"),
        }),
      })
      .pipe(
        map((res: any) => {
          //   if (this.handleAuthResult(res)) {
          var temp;
          try {
            temp = JSON.parse(res["_body"]);
          } catch (e) {
            temp = [];
          }
          return temp;
          //   }
        })
      );
  }

  loadDatatable(url, filterDate, dtparams, cb) {
    console.log("Auth loadDatatable called", url, dtparams);
    dtparams.filterStartDate = filterDate.startdate;
    dtparams.filterEndDate = filterDate.enddate;
    this.http
      .post(`${this.SERVER_URL}${url}`, dtparams, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("session_id"),
        }),
      })
      .subscribe((resp: any) => {
        console.log("Response received for datatable callback:", resp);
        // if (this.handleAuthResult(resp)) {
        var temp;
        try {
          temp = JSON.parse(resp["_body"]);
          cb({
            recordsTotal: temp.recordsTotal,
            recordsFiltered: temp.recordsFiltered,
            data: temp.data,
          });
        } catch (e) {
          console.log("Parsing error: ", e);
          cb({});
          // }
        }
      });
  }

  saveVideoPhone(body) {
    body.usertype = localStorage.getItem("type");
    this.http
      .post(`${this.SERVER_URL}/tv/ts/videoPhoneSave`, body, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("session_id"),
        }),
      })
      .subscribe(
        (res: any) => {
          console.log("Data sent");
        },
        (res: any) => {
          console.log("Data sent");
        }
      );
  }
  saveWorkorder(body) {
    body.usertype = localStorage.getItem("type");
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.SERVER_URL}/workorderSave`, body, {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem("session_id"),
          }),
        })
        .subscribe(
          (res: any) => {
            console.log("Data sent");
            var temp;
            try {
              temp = JSON.parse(res._body);
            } catch (e) {
              temp = res._body;
            }
            resolve(temp);
          },
          (res: any) => {
            console.log("Data sent");
            var temp;
            try {
              temp = JSON.parse(res._body);
            } catch (e) {
              temp = res._body;
            }
            reject(temp);
          }
        );
    });
  }

  saveDataToServer(body) {
    body.usertype = localStorage.getItem("type");
    this.http
      .post(`${this.SERVER_URL}/tv/save`, body, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("session_id"),
        }),
      })
      .subscribe(
        (res: any) => {
          console.log("Data sent");
        },
        (res: any) => {
          console.log("Data sent");
        }
      );
  }

  openVideoPhone(body, url) {
    var headers: Headers;

    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.SERVER_URL}${url}`, body, {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem("session_id"),
          }),
        })
        .subscribe(
          (res: any) => {
            // if (this.handleAuthResult(res)) {
            var temp;
            try {
              temp = JSON.parse(res._body);
            } catch (e) {
              temp = res._body;
            }
            resolve(temp);
            // }
          },
          (res: any) => {
            console.log(
              "videophone: Auth service Reject",
              `${this.SERVER_URL}${url}`,
              res
            );
            // if (this.handleAuthResult(res)) {
            var temp;
            try {
              temp = JSON.parse(res._body);
            } catch (e) {
              temp = res._body;
            }
            reject(temp);
            // }
          }
        );
    });
  }

  getVirtualUI(body, url) {
    var headers: HttpHeaders;

    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.SERVER_URL}${url}`, body, {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem("session_id"),
          }),
        })
        .subscribe(
          (res: any) => {
            console.log(
              "Virtual UI: Auth service Resolve",
              `${this.SERVER_URL}${url}`,
              body,
              res
            );
            // if (this.handleAuthResult(res)) {
            var temp;
            try {
              temp = JSON.parse(res._body);
            } catch (e) {
              temp = res._body;
            }
            resolve(temp);
            // }
          },
          (res: any) => {
            console.log(
              "Virtual UI: Auth service Reject",
              `${this.SERVER_URL}${url}`,
              res
            );
            // if (this.handleAuthResult(res)) {
            var temp;
            try {
              temp = JSON.parse(res._body);
            } catch (e) {
              temp = res._body;
            }
            reject(temp);
            // }
          }
        );
    });
  }

  getSpInformation(body, url) {
    var headers: Headers;

    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.SERVER_URL}${url}`, body, {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem("session_id"),
          }),
        })
        .subscribe(
          (res: any) => {
            console.log(
              "getTVInformation: Auth service Resolve",
              `${this.SERVER_URL}${url}`,
              body,
              res
            );
            // if (this.handleAuthResult(res)) {
            var temp;
            var contenttype = res.headers._headers.get("content-type");
            if (
              contenttype[0].startsWith(
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ) ||
              contenttype[0].startsWith("application/octet-stream") ||
              contenttype[0].startsWith("application/zip")
            ) {
              console.log("binary data: sending response, ", res);
              temp = res;
            } else {
              try {
                temp = JSON.parse(res._body);
                console.log("binary data: try response, ", res);
              } catch (e) {
                console.log("binary data: catch response, ", e);
                temp = res._body;
                // }
              }
              resolve(temp);
            }
          },
          (res: any) => {
            console.log(
              "getTVInformation: Auth service Reject",
              `${this.SERVER_URL}${url}`,
              res
            );
            // if (this.handleAuthResult(res)) {
            var temp;
            try {
              temp = JSON.parse(res._body);
            } catch (e) {
              temp = res._body;
            }
            reject(temp);
            // }
          }
        );
    });
  }

  uploadMenuImage(fileType: string, formData: FormData, url): Observable<any> {
    // let formData = new FormData();
    // console.log("formdata",formData)
    // formData.append("path", file);
    // //formData.append("name", fileType);
    // //formData.append("type", type);
    // console.log("formdata",formData)

    return this.http.post(`${environment.serverurl}${url}`, formData);
    //{
    //     headers: new HttpHeaders(
    //         {
    //             "Authorization": localStorage.getItem('session_id')
    //         }
    //     )
    // })
  }

  // getImages(paramsSearch, numberOfImages) {
  //     let url = `https://customsearch.googleapis.com/customsearch/v1?key=AIzaSyDxhBNn84N5lbKtI_2mq5rR_RJO-KyHmdM&cx=017901247231445677654:zwad8gw42fj&searchType=image&as_rights=(cc_publicdomain%7Ccc_attribute%7Ccc_sharealike%7Ccc_nonderived).-(cc_noncommercial)&q=${paramsSearch}&num=${numberOfImages}`
  //     return this.http.get(url)
  // }

  // getWebsites(domainName, siteName) {
  //     let url = `https://sites.google.com/feeds/activity/${domainName}/${siteName}`
  //     return this.http.get(url)
  // }

  newRequest(): Subject<MessageEvent> {
    console.log("emittting socket");
    console.log("ss", environment.etheradURL);
    //this.socket.ioSocket.nsp = '/iwbNode';
    this.socket = io(environment.etheradURL, {
      upgrade: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
      pingInterval: 30000,
      pingTimeout: 60000,
    });
    console.log("socket", this.socket);
    const observable = new Observable((observer) => {
      return () => {
        this.socket.disconnect();
      };
    });
    const observer = {
      next: (data: Object) => {
        this.socket.emit("message", data);
      },
    };
    return Subject.create(observer, observable);
  }

  sendMessage(roomID: string, userID) {
    this.socket.emit("joinMetisRoom", {
      roomID: roomID,
      userID: userID,
    });
  }

  sendMessageForShareScreen(roomID: string) {
    this.socket.emit("JoinscreenShare", {
      roomID: roomID,
      data: "sharescreen",
    });
  }

  sendKMSMessages(Info) {
    this.socket.emit("kmsupdates", {
      details: Info,
    });
    // console.log("emitted KM Info");
  }

  sendEndingEvent(roomID) {
    this.socket.emit("endClass", {
      roomID: roomID,
    });
    // console.log("emitted KM Info");
  }

  endClassByStudent() {
    this.socket.disconnect();
  }

  muteUnmuteStudnet(data) {
    this.socket.emit("changeMuteStatus", {
      studentId: data.studentId,
      status: data.status,
      roomId: data.roomID,
    });
  }

  getUpdatedMuteStatus() {
    const obs = new Observable((observer) => {
      this.socket.on("changedMuteStatus", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  muteUnmuteByStudnet(data) {
    this.socket.emit("updateMuteByStudent", {
      studentId: data.studentId,
      status: data.status,
      roomId: data.roomID,
    });
  }

  getUpdatedMuteByStudent() {
    const obs = new Observable((observer) => {
      this.socket.on("getMuteByStudent", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  enableDisableCam(data) {
    this.socket.emit("toggleCamByStudnet", {
      studentId: data.studentId,
      status: data.status,
      roomId: data.roomID,
    });
  }

  getStudentToggledCam() {
    const obs = new Observable((observer) => {
      this.socket.on("toggledCamByStudent", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  getTotalDisCamStudnets(data) {
    this.socket.emit("TotalDisCamStudnets", {
      roomId: data.roomID,
    });
  }

  retrieveDisCamStudents() {
    const obs = new Observable((observer) => {
      this.socket.on("recieveDisCamStudents", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  joinToClass() {
    console.log("calling inside file service1111");
    this.socket.connect();
  }

  listenForKmsInfo() {
    const obs = new Observable((observer) => {
      this.socket.on("kms-message", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }
  // sendMessage(roomID: string) {
  //     this.socket.emit("JoinscreenShare", {
  //         roomID: roomID
  //     });
  //     console.log("emitted");
  // }

  getActions(): any {
    const obs = new Observable((observer) => {
      this.socket.on("userAction", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  getShareScreenActions(): any {
    const obs = new Observable((observer) => {
      this.socket.on("sharescreenAction", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }
  changeType(type) {
    this.curType = type;
    this.typeObs.next(type);
  }

  checkCurrentModelPopup(modelName) {
    this.currentModel = modelName;
    this.checkCurrentModel.next(modelName);
  }
  Joinroom(data: any) {
    console.log("Went into join room", data);
    console.log("socket obj or fun", this.socket);
    this.socket.emit("Joinchatroom", { roomid: data });
  }
  Emit(data: any, roomid: any, name: any) {
    this.socket.emit("emit-message", {
      message: data,
      roomid: roomid,
      name: name,
      socketid: this.socket.id,
    });
  }
  Listen() {
    const obs = new Observable((observer) => {
      this.socket.on("listen-message", (res) => {
        console.log("In Listen");
        observer.next(res);
      });
    });
    return obs;
  }
  DELETEKEY(data) {
    this.socket.emit("Delete Key", { roomid: data });
  }

  listen_to_handraise_array() {
    const obs = new Observable((observer) => {
      this.socket.on("listen_handraise_array", (res) => {
        observer.next(res);
      });
    });
    return obs;
  }

  listen_to_handraise() {
    const obs = new Observable((observer) => {
      console.log("handraise_testing_inside_observable 1");
      this.socket.on("listen_handraise", (res) => {
        console.log("handraise_testing_inside_observable 2", res);
        observer.next(res);
      });
    });
    return obs;
  }

  // scratch pad sockets

  JoinScratchPad(padID) {
    this.socket.emit("scrachpad:subscribe", {
      padID: padID,
    });
  }

  openScratchPad(padID) {
    this.socket.emit("scrachpad:opend", {
      padID: padID,
    });
  }

  getOpenScratchPadEvent(): any {
    const obs = new Observable((observer) => {
      this.socket.on("scrachpad:opend", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  scratchPadProcess(padID, json) {
    console.log("emitted");
    this.socket.emit("scrachpad:progress", {
      padID: padID,
      json: json,
    });
  }

  getScratchPadProcessEvent(): any {
    const obs = new Observable((observer) => {
      this.socket.on("scrachpad:progress", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  leaveSocketRoom(roomName) {
    this.socket.emit("unsubscribe", roomName);
  }

  //quizz notification

  emitQuizNotification(QuestionObject) {
    this.socket.emit(
      "emitQuizNotification",
      { isNotificationSet: true, QuestionObject: QuestionObject },
      { roomid: localStorage.getItem("roomID") }
    );
    console.log("Quiz_emitNoti", QuestionObject);
  }

  ListenQuizNotification() {
    const obs = new Observable((observer) => {
      this.socket.removeListener("listenQuizNotification");
      this.socket.on("listenQuizNotification", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  getCursorEvents(): any {
    const obs = new Observable((observer) => {
      this.socket.on("scrachpad:cursorEvent", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  emitScrapadCursorEvent(padID, name, coordinates) {
    console.log("emitted", padID, name, coordinates);
    this.socket.emit("scrachpad:cursorEvent", {
      padID: padID,
      name: name,
      coordinates: coordinates,
    });
  }

  getThirPartInfo(url) {
    // let url = `https://sites.google.com/feeds/activity/${domainName}/${siteName}`
    return this.http.get(url);
  }

  reausableSocket(padId, type) {
    this.socket.emit("reuse:socket", {
      padID: padId,
      type: type,
    });
  }

  getNoification() {
    const obs = new Observable((observer) => {
      this.socket.on("reuse:socket", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  stopSharing(type) {
    this.stpsharing = type;
    this.stpsharingObs.next(type);
  }

  getNotifications(): any {
    const obs = new Observable((observer) => {
      this.socket.on("dashboard-notifications", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  getNotificationsInsideWhiteBoard(): any {
    const obs = new Observable((observer) => {
      this.socket.on("notification:whiteBoard", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  listenForQRcode() {
    console.log("Created login listener for MNT device");
    const obs = new Observable((observer) => {
      this.socket.on("login-event", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }
  listenforlogout() {
    console.log("Created logout listener for MNT device");
    const obs = new Observable((observer) => {
      this.socket.on("logout-event", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  ///////////////////////////////////////////////////Voting Section/////////////////////////////////////////////

  saveuseroptions(data: any): Observable<any> {
    // console.log(this.baseUrl,data)
    return this.http.post(
      environment.serverurl + "/submit_voting_options",
      data
    );
  }

  getoptionsdata(data: any): Observable<any> {
    return this.http.get(environment.serverurl + `/voting/${data}/info`);
  }

  handleWebSocketEvent(eventName: string, callback: (data: any) => void): void {
    console.log(eventName, "handle");
    this.socket.on(eventName, callback);
  }

  closeVoting(data: any): Observable<any> {
    let sendata = { uniquevoteid: data };
    console.log(data, "data.........................");
    return this.http.post(environment.serverurl + "/close_voting", sendata);
  }

  //   handleWebSocketEvent(eventName: string, callback: (data: any) => void): void {
  //     const obs = new Observable(observer => {
  //       this.socket.on(eventName, callback) {
  //           observer.next(res);
  //       });
  //   });
  //      return obs;
  // }

  sendUndoEventToSocket(pageOperationsList, removedOperationsList, canvasWidth,canvasHeight) {
    if (this.isConnected) {
      this.socket.emit("undo", {
        pageOperationsList: pageOperationsList,
        removedOperationsList: removedOperationsList,
        sender_canvasWidth: canvasWidth,
        sender_canvasHeight: canvasHeight
      });
    }
  }

  sendRedoEventToSocket(pageOperationsList, removedOperationsList, canvasWidth,canvasHeight) {
    if (this.isConnected) {
      this.socket.emit("redo", {
        pageOperationsList: pageOperationsList,
        removedOperationsList: removedOperationsList,
        sender_canvasWidth: canvasWidth,
        sender_canvasHeight: canvasHeight
      });
    }
  }

  emitWebSocketEvent(eventName: string, data: any): void {
    if (this.isConnected) {
      this.socket.emit(eventName, data);
    }
  }

  // listen_to_totalvotes() {
  //   console.log("kkkk")
  //   const obs = new Observable(observer => {
  //       this.socket.on("TotalvotesRecievedbyid", (res:any) => {
  //         console.log("Got TotalvotesRecievedbyidinservicefile",res);
  //           observer.next(res);
  //       });
  //   });
  //   return obs;
  // }

  listen_to_totalvotes() {
    //console.log("Created login listener for MNT device");
    const obs = new Observable((observer) => {
      this.socket.on("TotalvotesRecievedbyid", function (res) {
        console.log(" listen_to_totalvotes", res);
        observer.next(res);
      });
    });
    return obs;
  }

  handlevotesSocketAnswerRecieved() {
    console.log("handlevotesSocket");
    //console.log("Created login listener for MNT device");
    const obs = new Observable((observer) => {
      this.socket.on("AnswerRecieved", function (res) {
        console.log(" AnswerRecieved", res);
        observer.next(res);
      });
    });
    return obs;
  }

  getUndoEvent() {
    const obs = new Observable((observer) => {
      this.socket.on("receiveUndo", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  getRedoEvent() {
    const obs = new Observable((observer) => {
      this.socket.on("receiveRedo", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  getChangedPage() {
    const obs = new Observable((observer) => {
      this.socket.on("recieveChangedPage", function (res) {
        observer.next(res);
      });
    });
    return obs;
  }

  //behaviour//

  public loadData = new Subject();
  public refreshFiles = new Subject();
  public refreshPageCount = new Subject();
  public loadThumbnail = new Subject();
  public closeAvChat = new Subject();
  public enableDisableCamSub = new Subject();

  private API_URL = "https://www.googleapis.com/youtube/v3/search";
  private API_TOKEN = environment.googleapitoken;

  getYoutubeVideos(query: any): Observable<any> {
    console.log("hello");
    const url = `${this.API_URL}?q=${query}&key=${this.API_TOKEN}&part=snippet&type=video&maxResults=20`;
    return this.http.get(url).pipe(map((response: any) => response.items));
  }

  getImagesfromgoogle(paramsSearch, numberOfImages, startindex) {
    console.log(this.googleapitoken, "googleapitoken");
    console.log(this.googlecxtoken, "googlecxtoken");
    //  let url = `https://customsearch.googleapis.com/customsearch/v1?key=AIzaSyDxhBNn84N5lbKtI_2mq5rR_RJO-KyHmdM&cx=017901247231445677654:zwad8gw42fj&searchType=image&as_rights=(cc_publicdomain%7Ccc_attribute%7Ccc_sharealike%7Ccc_nonderived).-(cc_noncommercial)&safe=active&q=${paramsSearch}&num=${numberOfImages}&start=${startindex}`;
    let url = `https://customsearch.googleapis.com/customsearch/v1?key=${this.googleapitoken}&cx=${this.googlecxtoken}&searchType=image&as_rights=(cc_publicdomain%7Ccc_attribute%7Ccc_sharealike%7Ccc_nonderived).-(cc_noncommercial)&safe=active&q=${paramsSearch}&num=${numberOfImages}&start=${startindex}`;
    console.log(url, "url");
    console.log(url);
    return this.http.get(url);
  }

  changeShareAccess(data) {
    return this.http.post(environment.serverurl + "/lesson/shareMode", data);
  }

  updateRoomId(data: any): Observable<any> {
    return this.http.post(environment.serverurl + `/lesson/updateRoomId`, data);
  }

  joinLesson(data): Observable<any> {
    return this.http.get(environment.serverurl + `/lesson/join_lesson/${data}`);
  }

  changeShareMode(data): Observable<any> {
    return this.http.post(
      environment.serverurl + `/lesson/shareModeChange`,
      data
    );
  }

  ///////////////////////SEarch functionalities///////////////////////////////////
  searchMylibararylesson(lesonowner, search, page): Observable<any> {
    return this.http.get<any>(
      environment.serverurl +
        `/searchfile/private/${lesonowner}?search=${search}&page=${page}`
    );
  }

  searchuploadedContents(
    type,
    search,
    page,
    userId: string,
    isPublic: boolean
  ): Observable<any> {
    //return this.http.get<any>(environment.serverurl + `/uploadedcontentsearch/${type}?search=${search}&page=${page}&userid=${userid},${isPublic}`);
    let url = `${environment.serverurl}/uploadedcontentsearch/${type}?search=${search}&page=${page}`;
    console.log("param", type, search, page, userId, isPublic);
    if (userId) {
      // If userId is provided, add it to the URL
      url += `&userid=${userId}`;
    } else if (isPublic) {
      console.log("issssssss", isPublic);
      // If isPublic is true, add it to the URL
      url += `&ispublic=true`;
    }

    return this.http.get<any>(url);
  }

  private sessionId: string | null = null;

  setSessionId(sessionId: string) {
    this.sessionId = sessionId;
    console.log("set fileservice", this.sessionId);
  }

  getSessionId(): string | null {
    console.log("get fileservice before return ", this.sessionId);
    return this.sessionId;
  }

  deletePublicContent(id: any): Observable<any> {
    return this.http.delete<any>(
      environment.serverurl + `/deletePublicContent/${id}`
    );
  }

  deletePubliclesson(id: any): Observable<any> {
    console.log(id, "for public lesson");
    return this.http.delete<any>(
      environment.serverurl + `/deletePubliclesson/${id}`
    );
  }

  deletebinlesson(id: any): Observable<any> {
    console.log(id, "for public lesson");
    return this.http.delete<any>(
      environment.serverurl + `/deletebinlesson/${id}`
    );
  }

  ///////////////Demo Account api calling///////////////////////
  fetchCountries(): Observable<any> {
    return this.http.get<any>(environment.serverurl + `/fetchCountries`);
  }

  setdemoaccounts(data): Observable<any> {
    return this.http.post<any>(environment.serverurl + `/setdemoaccount`, {
      data: data,
    });
  }

  setdemoaccountsviaemail(data): Observable<any> {
    console.log(data);
    return this.http.post<any>(
      environment.serverurl + `/setdemoaccountdetails`,
      { data: data }
    );
  }

  demoaccountvalidation(userid): Observable<any> {
    return this.http.get<any>(
      environment.serverurl + `/validateuserdemo/${userid}`
    );
  }

  setdemoaccpassword(userid, password): Observable<any> {
    let data = { email: userid, password: password };
    console.log(data, "data");
    return this.http.post<any>(
      environment.serverurl + `/setdemoaccpassword`,
      data
    );
    // return this.http.post<any>(environment.serverurl + `/validateuserdemo/${userid}`);
  }

  searchuser(userid): Observable<any> {
    console.log("userid", userid);
    return this.http.get<any>(environment.serverurl + `/searchuser/${userid}`);
    // return this.http.post<any>(environment.serverurl + `/validateuserdemo/${userid}`);
  }

  fetchStatesbyCountry(country: any): Observable<any> {
    console.log(country, "Country recieved");
    return this.http.get<any>(
      environment.serverurl + `/fetchbystates/${country}`
    );
  }

  demosuccess: Boolean = false;
  updatedemostatus(value) {
    this.demosuccess = value;
  }
  fetchDemoSuccess(): any {
    console.log();
    return this.demosuccess;
  }

  activefiles: Boolean = false;
  coursemodeactive: string = "";
  activatepublicandprivtefiles(value, activemode) {
    //  console.log("active files Bandana","activeFiles")
    this.activefiles = value;
    this.coursemodeactive = activemode;
    console.log("active files Bandana", this.activefiles);
  }

  fetchfilesstatus() {
    console.log("active files Bandana", "activeFiles");
    let datasend = {
      activemode: this.activefiles,
      coursemodeactive: this.coursemodeactive,
    };
    return datasend;
  }

  //Presentation/Collaboration
  listenCanvasOperationFromServer() {
    //this.socket.off("broadcast_canvas_operation");
    const obs = new Observable((observer) => {
      this.socket.on("broadcast_canvas_operation", function (res) {
        //listen to broadcast_canvas_operation event.
        observer.next(res);
      });
    });
    return obs;
  }

  stopListeningToCanvasOperationFromServer() {
    this.socket.off("broadcast_canvas_operation");
  }

  emitCanvasOperationToServer(data: any) {
    if (this.isConnected) {
      console.log("Debugg emitCanvasOperationToServer:",data);
      data.sender_canvasHeight = this.browserDetectionService.getBrowserInfo().canvasHeight;
      data.sender_canvasWidth = this.browserDetectionService.getBrowserInfo().canvasWidth;
      this.socket.emit("canvas_operation", data); //emit canvas operation event.
    }
  }

  sendObjDataToSocket(obj, type) {
    if (this.avChatStarted() || !this.isLoginByTeacher()) {
      console.log("Venki: debuggggggggggggggggggg");

      if (localStorage.getItem("userId")) {
        obj.ownerType = "teacher";
      } else {
        obj.ownerType = "student";
      }

      let currentObj = {
        object: obj,
        pageNo: localStorage.getItem("currentPage"),
        action_type: type,
        mainLessonId: localStorage.getItem("mainlessonID"),
      };

      this.emitCanvasOperationToServer(currentObj);
    }
  }

  avChatStarted() {
    if (
      localStorage.getItem("avchatstarted") &&
      localStorage.getItem("avchatstarted") == "yes"
    ) {
      return true;
    } else {
      return false;
    }
  }

  isLoginByTeacher() {
    if (
      localStorage.getItem("type") &&
      localStorage.getItem("type") != "student"
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleOnSockets(data: string) {
    const obs = new Observable((observer) => {
      this.socket.on(data, function (res) {
        console.log(data, res);
        observer.next(res);
      });
    });
    return obs;
  }

  loginfromlicenseemail(emailid) {
    console.log(emailid, "emailid......");
    let sendData = { emailid: emailid };
    if (this.validateEmail(emailid)) {
      return this.http.post<any>(
        environment.serverurl + `/getuserinfoandlogin`,
        sendData
      );
    }
  }

  loginAfterActivateLicense(emailid) {
    console.log(emailid, "emailid......");
    let sendData = { emailid: emailid };
    if (this.validateEmail(emailid)) {
      return this.http.post<any>(
        environment.serverurl + `/getSchoolAdminInfoAndLogin`,
        sendData
      );
    }
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }


  licensePortal: Boolean;
  licensePortalFun(value) {
    this.licensePortal=value
  }
  checklicenseupgradestatus(){
   return this.licensePortal
  }

  private quitAction = new Subject<void>();
  quitAction$ = this.quitAction.asObservable();
  quitavclass(){
    this.quitAction.next();
  }
  //MediaDocMetaData apis
  postMediaDocMetaData(metaData:{fileId:String, thumbnailId:number, thumbnailType:String, url:any}):Observable<any> {
    if (this.isConnected) {
      const url = `${environment.serverurl}/post-media-doc-meta-data`;
      return this.http.post(url, metaData);
    }
  }

  getMediaDocMetaData(fileId:string, thumbnailId:number):Observable<any> {
    const url = `${environment.serverurl}/get-media-doc-meta-data`;
    return this.http.get(url, {
      params: { fileId: fileId, thumbnailId: thumbnailId.toString()}
    });
  }

  deleteMediaDocMetaData(fileId:string, thumbnailId:number):Observable<any> {
    const url = `${environment.serverurl}/delete-media-doc-meta-data`;
    return this.http.delete(url, {
      params: { fileId: fileId, thumbnailId: thumbnailId.toString()}
    });
  }
  //update and read page change status.
  readonly TOTAL_PAGES:number = 20;
  private pageChangeStatus:boolean[] = new Array(this.TOTAL_PAGES).fill(false);
  updatePageChangeStatus(pageNumber:number, status:boolean) {
    this.pageChangeStatus[pageNumber - 1] = status;
  }
  readPageChangeStatus(pageNumber:number): boolean {
    return this.pageChangeStatus[pageNumber - 1];
  }
  updateAllPageChangeStatus(status) {
    for(let i=0; i<this.TOTAL_PAGES; i++) {
      this.pageChangeStatus[i] = status;
    }
  }

  //page thumbnails utilities.
  public pageThumbnail:string[] = new Array(this.TOTAL_PAGES).fill('url');
  initializeLessonThumbnails(fileId: string) {
    var gettime = new Date();
    let timestamp = gettime.getTime(); // Get timestamp in milliseconds
    for (let i = 0; i < this.TOTAL_PAGES; i++) {
      let thumbnailUrl = `${environment.serverurl}/thumbnail/${fileId}_${i + 1}.png?&time=${timestamp}`; // URL format
      this.pageThumbnail[i] = thumbnailUrl
    }
  }

  readPageThumbnail(pageNumber: number ) {
    return this.pageThumbnail[pageNumber - 1];
  }

  updatePageThumbnail(pageNumber:number, url:string) {
    this.pageThumbnail[pageNumber - 1] = url;
  }

  generateNewThumbnailUrlForPage(pageNumber:number,fileId:string) {
    var gettime = new Date();
    let timestamp = gettime.getTime(); // Get timestamp in milliseconds
    let thumbnailUrl = `${environment.serverurl}/thumbnail/${fileId}_${pageNumber}.png?&time=${timestamp}`;
    this.updatePageThumbnail(pageNumber,thumbnailUrl);
  }


  public connectionStatus = new BehaviorSubject<boolean>(true);

  getConnectionStatus(): Observable<boolean> {
    console.log("get connection Bandana ")
    return this.connectionStatus.asObservable();
  }

  //after login apis.
  getLastUpdatedLesson(userId:string):Observable<any> {
    const url = `${environment.serverurl}/get-last-updated-lesson`;
    return this.http.get(url, {
      params: { userId: userId}
    });
  }

}
