import { Component, OnInit, HostListener, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FileService } from '../file.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-demo-upgrade',
  templateUrl: './demo-upgrade.component.html',
  styleUrls: ['./demo-upgrade.component.scss']
})
export class DemoUpgradeComponent implements OnInit {

  @Output() closeDemoUpgradeOption = new EventEmitter<Boolean>();
  mainHeadingText: string = 'License ID';
  subHeadingText: string = 'Please enter License ID received on registered email';
  buttonText: string = 'Submit';
  licenseid:string;
  invoicenr:string;
  startdate: string;
  enddate:string;
  licensetype:string;
  firstname: string;
  lastname:string;
  emailid: string;
  country: string;
  city: string;
  orderid: string;
  licenseActiveStatus: string;
  schoolname: string;
  schoolcode: string;
  regionname: string;
  schoolcountry: string;
  address: string;

  showgeneralerror:boolean=true;
  isLicenseDetailsVisible: boolean = false;
  isSchoolDetailsVisible = false;
  islicenseIDEntryVisible = true;
  isLicenseIDFieldVisible: boolean = true;
  isLicenseIDCorrect: boolean = false;
  verifySchoolDetailsVisible: boolean = false;
  isSuccessful: boolean = false;
  isCloseClicked: boolean = false;
  isSchoolName: boolean = false;
  public licenseIDEntryForm;
  schoolForm: FormGroup;
  regionnamesadd: any = [];
  countrynamesadd: any = [];
  Allregionandcountries: any = [];
  public licenseEntryForm;
  submittedSchoolDetails: any = null; // Store submitted data
  constructor(private route: ActivatedRoute,private fb: FormBuilder,public http: HttpClient,public auth: FileService) { }

  ngOnInit(): void {
    this.licenseEntryForm = this.fb.group({
      licenseID: ["", Validators.required]
    })
    this.schoolForm = this.fb.group({
      schoolName: ['', Validators.required],
      schoolCode: ['', Validators.required],
      country: ['', Validators.required],
      regionname: ['', Validators.required],
      postalAddress: ['', Validators.required]
    });
    this.getallregionsandcountrys();
  }

  getallregionsandcountrys() {
    this.auth.getTVInformation({}, '/getallregionsandcountrys')
      .then((res: any) => {
        if (res && res.body.data.length > 0) {
          this.Allregionandcountries = res.body.data;
          this.regionnamesadd = [...new Set(this.Allregionandcountries.map(one => one.Region))];
        }
      });
  }

  updateCountries() {
    this.countrynamesadd = [];
    const selectedRegion = this.schoolForm.get('regionname').value;
    this.Allregionandcountries.forEach(one => {
      if (selectedRegion === one.Region) {
        this.countrynamesadd.push(one.Country);
      }
    });
  }

  closeDemoUpgrade() 
  {
    // this.showvote=false
    let closeDemoUpgradeOption=false;
    this.isCloseClicked = true;
    this.mainHeadingText = 'Are you sure?';
    this.subHeadingText = '';
    this.isSuccessful = false;
    this.isLicenseIDFieldVisible = false;
    this.isLicenseDetailsVisible = false; 
    this.islicenseIDEntryVisible = false;
    this.isSchoolDetailsVisible = false;
    // this.closeDemoUpgradeOption.emit(closeDemoUpgradeOption);
 
  }

  directCloseDemoUpgrade() {
      this.closeDemoUpgradeOption.emit(false);

  }

  activateLater() {
    this.closeDemoUpgradeOption.emit(false);

}

  // onSubmit() {
  //   this.isLicenseDetailsVisible = true; // Show the license details when submit is clicked
  //   this.islicenseIDEntryVisible = false;
  //   this.subHeadingText = 'Please review details attached to this License ID'
  // }

  onSubmit(val) {
    this.isLicenseIDCorrect = false;
    const licenseID = this.licenseEntryForm.get('licenseID')?.value;

    this.auth.getTVInformation({"selectedid": licenseID}, '/license/getlicenseforupgrade').then((res: any) => {
      try {
        console.log('Status:', res);
        if (res.body.status == false) {
          this.isLicenseIDCorrect = true;
        } else {
        this.isLicenseDetailsVisible = true; // Show the license details when submit is clicked
        this.islicenseIDEntryVisible = false;
        this.subHeadingText = 'Please review details attached to this License ID'
        this.licenseid = licenseID;
        this.invoicenr = res.body.data.data.invoicenr;
        this.startdate = res.body.data.data.startdate;
        this.enddate = res.body.data.data.enddate;
        this.licensetype = res.body.data.data.licensetype;
        this.firstname = res.body.data.data.firstname;
        this.lastname = res.body.data.data.lastname;
        this.emailid = res.body.data.data.emailid;
        this.country =res.body.data.data.countryname;
        this.city = res.body.data.data.city;
        this.orderid = res.body.data.data.orderid;
        this.licenseActiveStatus = res.body.data.data.licenseactivestatus;
        }
      }
      catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  } 
  // onSubmit(): void {
  //   if (this.licenseEntryForm.valid) {
  //     const licenseID = this.licenseEntryForm.get('licenseID')?.value;
  //     const payload = licenseID ; // Encode the licenseID using Base64
  //     console.log('payload', payload)
  //     this.http.post('/api/v1/license/getlicenseforedit', payload).subscribe({
  //       next: (response: any) => {
  //         if (response.status) {
  //           // Handle success
  //           this.isLicenseDetailsVisible = true;
  //           this.islicenseIDEntryVisible = false;
  //           this.subHeadingText = 'Please review details attached to this License ID';
  //           console.log('License details:', response.data);
  //         } else {
  //           // Handle failure
  //           alert(response.message || 'License retrieval failed');
  //         }
  //       },
  //       error: (err) => {
  //         // Handle error
  //         console.error('Error:', err);
  //         alert('An error occurred while retrieving the license details.');
  //       }
  //     });
  //   } else {
  //     alert('Please enter a valid License ID.');
  //   }
  // }

  get isFormValid() {
    return this.licenseEntryForm.valid;
  }

  get isSchoolFormValid() {
    return this.schoolForm.valid;
  }

  OnCreateSchool1() {
    this.isCloseClicked = false;
    this.isSchoolDetailsVisible = true;
    this.isLicenseIDFieldVisible = false;
    this.isLicenseDetailsVisible = false; 
    // this.islicenseIDEntryVisible = false;
    this.mainHeadingText = 'Create school';
    this.subHeadingText = 'Enter school details for license assignment';
    this.verifySchoolDetailsVisible = false;
  }

  OnCreateSchool2(val) {
    this.isSchoolDetailsVisible = false;
    this.mainHeadingText = 'School details';
    this.subHeadingText = 'Please review school details for license activation';
    this.verifySchoolDetailsVisible = true;

    this.schoolname = this.schoolForm.get('schoolName')?.value;
    this.schoolcode = this.schoolForm.get('schoolCode')?.value; 
    this.regionname = this.schoolForm.get('regionname')?.value;
    this.schoolcountry = this.schoolForm.get('country')?.value; 
    this.address = this.schoolForm.get('postalAddress')?.value; 

  }

  // OnActivateLicense() {
  //   const licenseID = this.licenseEntryForm.get('licenseID')?.value;

  //   this.auth.getTVInformation({"selectedid": licenseID}, '/license/getlicenseforedit').then((res: any) => {
  //     try {
  //       console.log('Status:', res);
  //       if (res.body.status == false) {
  //         return
  //       } else {
  //       this.licenseid = licenseID;
  //       this.invoicenr = res.body.data.data.invoicenr;
  //       this.startdate = res.body.data.data.startdate;
  //       this.enddate = res.body.data.data.enddate;
  //       this.licensetype = res.body.data.data.licensetype;
  //       this.firstname = res.body.data.data.firstname;
  //       this.lastname = res.body.data.data.lastname;
  //       this.emailid = res.body.data.data.emailid;
  //       this.country =res.body.data.data.countryname;
  //       this.city = res.body.data.data.city;

  //       this.schoolname = this.schoolForm.get('schoolName')?.value;
  //   this.schoolcode = this.schoolForm.get('schoolCode')?.value; 
  //   this.schoolcountry = this.schoolForm.get('country')?.value; 
  //   this.address = this.schoolForm.get('postalAddress')?.value; 
  //       }
  //     }
  //     catch (e) {
  //       console.error(e);
  //     }
  //   }, (err: any) => {
  //     console.error(err);
  //   })
  //   this.schoolname = this.schoolForm.get('schoolName')?.value;
  //   this.schoolcode = this.schoolForm.get('schoolCode')?.value; 
  //   this.schoolcountry = this.schoolForm.get('country')?.value; 
  //   this.address = this.schoolForm.get('postalAddress')?.value; 
  //   this.verifySchoolDetailsVisible = false;
  //   this.mainHeadingText = 'Success!';
  //   this.subHeadingText = '';
  //   this.isSuccessful = true;
  // }

  OnActivateLicense() {
    // this.route.queryParams.subscribe((params) => {
    //   this.emailid = params["emailid"];
    //   console.log(this.emailid);

 

    //   this.auth.loginfromlicenseemail(this.emailid).subscribe((user) => {
    //     console.log("User Management ___________________", user);
    //     console.log("user user user ", user.classidavchat);
    //     localStorage.setItem("username", user.username);
    //     localStorage.setItem("type", user.type);
    //     localStorage.setItem("userId", user.userId.toLowerCase());
    //     localStorage.setItem("session_id", user.session_id);
    //     localStorage.setItem("accessToken", user.session_id);
    //     localStorage.setItem("refreshToken", user.refreshToken);
    //     localStorage.setItem("classidavchat", user.classidavchat);
    //     localStorage.removeItem("prevCanvasObjLen");
    //     if (user.language == undefined) {
    //       localStorage.setItem("lang", "en-Us");
    //       console.log("null lang", localStorage.getItem("lang"));
    //     } else if (user.language != undefined) {
    //       localStorage.setItem("lang", user.language);
    //     }

    //     if (user.type == "schooladmin") {
    //       this.auth
    //         .getTVInformation(
    //           { emailid: user.userId, role: user.type },
    //           "/getadmin"
    //         )
    //         .then((res: any) => {
    //           try {
    //             if (res) {
    //               localStorage.setItem(
    //                 "licensetype",
    //                 res.body.data.licensetype
    //               );
    //               console.log(res.body.data.enddate, "for licensedetails");

    //               var enddateString = res.body.data.enddate;
    //               var enddate = new Date(enddateString);
    //               var today = new Date();
    //               var differenceMs = enddate.getTime() - today.getTime();

    //               var differenceDays = Math.ceil(
    //                 differenceMs / (1000 * 60 * 60 * 24)
    //               );
    //               console.log(differenceDays, "differ");
    //               console.log(res.body.data.activestatus, "Bandana");
    //               if (
    //                 user.type === "schooladmin" &&
    //                 res.body.data.activestatus === "active"
    //               ) {
    //                 console.log("hello");

    //                 return;
    //               }
    //               // Determine if the end date is approaching expiration
    //               if (differenceDays < 0) {
    //                 //expecting within time
    //                 return
    //               } else {
    //                 console.log(
    //                   "The end date is in the future. Days left until expiration:",
    //                   differenceDays
    //                 );
    //                 this.auth.setTriggerState(true);
    //                 localStorage.setItem(
    //                   "subscription",
    //                   differenceDays.toString()
    //                 );
                   
    //               }
    //             } else {
    //               console.log("no data from backend");
    //             }
    //           } catch (e) {
    //             console.log(e);
    //           }
    //         });
    //     }
    //     //console.log(result);
    //   });
    // });
    var licenseStatus;
    if (this.licenseActiveStatus == 'true') {
      licenseStatus = 'active'
    } else {
      licenseStatus = 'inactive'
    }
    // Use the retrieved details to create the school
    const schoolData = {
      emailid: this.emailid,
      schoolname: this.schoolForm.get('schoolName')?.value,
      schoolcode: this.schoolForm.get('schoolCode')?.value,
      regionname: this.schoolForm.get('regionname')?.value,
      countryname: this.schoolForm.get('country')?.value,
      address: this.schoolForm.get('postalAddress')?.value,
      startdate: this.startdate,
      enddate: this.enddate,
      licensetype: this.licensetype,
      licensestatus: licenseStatus,
      orderid: this.orderid,
      firstname: this.firstname,
      lastname: this.lastname,
      LICId: this.licenseid
    };

    console.log('schoolData', schoolData)

    this.auth.getTVInformation(schoolData, '/createschoolwithoutlicense').then((res: any) => {
      console.log('res school', res)
      // if (res.status === "success") {
      if (schoolData.schoolname == '') {
        this.isSchoolName = true;
      }
        console.log("School successfully created");
        this.verifySchoolDetailsVisible = false;
        this.mainHeadingText = 'Success!';
        this.subHeadingText = '';
        this.isSuccessful = true;
        // Handle successful creation (e.g., show a message, redirect, etc.)
      // } else {
        // console.error("Failed to create school", res);
        // Handle the error (e.g., show an error message)
      // }
    }, (err: any) => {
      console.error("Error in API", err);
    });
  }

  //---------------------
//   goToWhiteboard() {
    
//       // this.emailid = params["emailid"];
     
// let emailid = localStorage.getItem('userId');
// console.log('gotToWhiteboard emailid',emailid);

//       this.auth.loginfromlicenseemail(emailid).subscribe((user) => {
//         console.log("User Management ___________________", user);
//         console.log("user user user ", user.classidavchat);
//         localStorage.setItem("username", user.username);
//         localStorage.setItem("type", user.type);
//         localStorage.setItem("userId", user.userId.toLowerCase());
//         localStorage.setItem("session_id", user.session_id);
//         localStorage.setItem("accessToken", user.session_id);
//         localStorage.setItem("refreshToken", user.refreshToken);
//         localStorage.setItem("classidavchat", user.classidavchat);
//         localStorage.setItem('adminrole', 'schooladmin');
//         localStorage.removeItem("prevCanvasObjLen");
//         if (user.language == undefined) {
//           localStorage.setItem("lang", "en-Us");
//           console.log("null lang", localStorage.getItem("lang"));
//         } else if (user.language != undefined) {
//           localStorage.setItem("lang", user.language);
//         }

//         if (user.type == "schooladmin") {
//           this.auth
//             .getTVInformation(
//               { emailid: user.userId, role: user.type },
//               "/getadmin"
//             )
//             .then((res: any) => {
//               try {
//                 if (res) {
//                   localStorage.setItem(
//                     "licensetype",
//                     res.body.data.licensetype,
//                   );
//                   localStorage.setItem(
//                     "schoolname",
//                     res.body.data.schoolname,
//                   );
//                   localStorage.setItem(
//                     "countryname",
//                     res.body.data.countryname
//                   );
//                   localStorage.setItem(
//                     "regionname",
//                     res.body.data.regionname
//                   );
//                   // localStorage.setItem(
//                   //   'adminrole',
//                   //   'schooladmin'
//                   // )
//                   console.log(res.body.data.enddate, "for licensedetails");

//                   var enddateString = res.body.data.enddate;
//                   var enddate = new Date(enddateString);
//                   var today = new Date();
//                   var differenceMs = enddate.getTime() - today.getTime();

//                   var differenceDays = Math.ceil(
//                     differenceMs / (1000 * 60 * 60 * 24)
//                   );
//                   console.log(differenceDays, "differ");
//                   console.log(res.body.data.activestatus, "Bandana");
//                   if (
//                     user.type === "schooladmin" &&
//                     res.body.data.activestatus === "active"
//                   ) {
//                     console.log("hello");

//                     return;
//                   }
//                   // Determine if the end date is approaching expiration
//                   if (differenceDays < 0) {
//                     //expecting within time
//                     return
//                   } else {
//                     console.log(
//                       "The end date is in the future. Days left until expiration:",
//                       differenceDays
//                     );
//                     this.auth.setTriggerState(true);
//                     localStorage.setItem(
//                       "subscription",
//                       differenceDays.toString()
//                     );
//                     window.location.reload();
                   
//                   }
//                 } else {
//                   console.log("no data from backend");
//                 }
//               } catch (e) {
//                 console.log(e);
//               }
//             });
//         }
//         //console.log(result);
//       });

//     //   let demoData = this.emailid;

//     //   this.auth.getTVInformation(demoData, '/deleteDemoAcc').then((res: any) => {
//     //     console.log('res school', res)
//     //   }, (err: any) => {
//     //   console.error("Error in API", err);
//     // });
      
    
//     this.closeDemoUpgradeOption.emit(false);
//   }
  //---------------------

//   goToWhiteboard() {
    
//     // this.emailid = params["emailid"];
   
// let emailid = localStorage.getItem('userId');
// console.log('gotToWhiteboard emailid',emailid);
// var licenseStatus;
//     if (this.licenseActiveStatus == 'true') {
//       licenseStatus = 'active'
//     } else {
//       licenseStatus = 'inactive'
//     }
// // let demoData = this.emailid;
// const schoolAdminData = {
//   emailid: this.emailid,
//   institutename: this.schoolForm.get('schoolName')?.value,
//   schoolcode: this.schoolForm.get('schoolCode')?.value,
//   regionname: this.schoolForm.get('regionname')?.value,
//   countryname: this.schoolForm.get('country')?.value,
//   city: this.schoolForm.get('postalAddress')?.value,
//   startdate: this.startdate,
//   enddate: this.enddate,
//   licensetype: this.licensetype,
//   licensestatus: licenseStatus,
//   orderid: this.orderid,
//   firstname: this.firstname,
//   lastname: this.lastname,
//   activestatus: licenseStatus

// };

// console.log('schoolData', schoolAdminData)

// this.auth.getTVInformation(schoolAdminData, '/createschooladd').then((res: any) => {
// console.log('res school11', res)

// // if (res.status == true) {


//     this.auth.loginfromlicenseemail(emailid).subscribe((user) => {
//       console.log("User Management ___________________", user);
//       console.log("user user user ", user.classidavchat);
//       localStorage.setItem("username", user.username);
//       localStorage.setItem("type", user.type);
//       localStorage.setItem("userId", user.userId.toLowerCase());
//       localStorage.setItem("session_id", user.session_id);
//       localStorage.setItem("accessToken", user.session_id);
//       localStorage.setItem("refreshToken", user.refreshToken);
//       localStorage.setItem("classidavchat", user.classidavchat);
//       localStorage.setItem('adminrole', 'schooladmin');
//       localStorage.removeItem("prevCanvasObjLen");
//       if (user.language == undefined) {
//         localStorage.setItem("lang", "en-Us");
//         console.log("null lang", localStorage.getItem("lang"));
//       } else if (user.language != undefined) {
//         localStorage.setItem("lang", user.language);
//       }

//       if (user.type == "schooladmin") {
//         this.auth
//           .getTVInformation(
//             { emailid: user.userId, role: user.type },
//             "/getadmin"
//           )
//           .then((res: any) => {
//             try {
//               if (res) {
//                 localStorage.setItem(
//                   "licensetype",
//                   res.body.data.licensetype,
//                 );
//                 localStorage.setItem(
//                   "schoolname",
//                   res.body.data.schoolname,
//                 );
//                 localStorage.setItem(
//                   "countryname",
//                   res.body.data.countryname
//                 );
//                 localStorage.setItem(
//                   "regionname",
//                   res.body.data.regionname
//                 );
//                 // localStorage.setItem(
//                 //   'adminrole',
//                 //   'schooladmin'
//                 // )
//                 console.log(res.body.data.enddate, "for licensedetails");

//                 var enddateString = res.body.data.enddate;
//                 var enddate = new Date(enddateString);
//                 var today = new Date();
//                 var differenceMs = enddate.getTime() - today.getTime();

//                 var differenceDays = Math.ceil(
//                   differenceMs / (1000 * 60 * 60 * 24)
//                 );
//                 console.log(differenceDays, "differ");
//                 console.log(res.body.data.activestatus, "Bandana");
//                 if (
//                   user.type === "schooladmin" &&
//                   res.body.data.activestatus === "active"
//                 ) {
//                   console.log("hello");

//                   return;
//                 }
//                 // Determine if the end date is approaching expiration
//                 if (differenceDays < 0) {
//                   //expecting within time
//                   return
//                 } else {
//                   console.log(
//                     "The end date is in the future. Days left until expiration:",
//                     differenceDays
//                   );
//                   this.auth.setTriggerState(true);
//                   localStorage.setItem(
//                     "subscription",
//                     differenceDays.toString()
//                   );
//                   window.location.reload();
                 
//                 }
//               } else {
//                 console.log("no data from backend");
//               }
//             } catch (e) {
//               console.log(e);
//             }
//           });
//       }
//       //console.log(result);
//     });
//   // }
//   // else {
//   //   console.log('DemoAccounts not deleted');
//   // }
//   }, (err: any) => {
//     console.error("Error in API", err);
//     });
    

    
  
//   this.closeDemoUpgradeOption.emit(false);
// }

//-------------------

goToWhiteboard() {
  let emailid = localStorage.getItem('userId');
  console.log('goToWhiteboard emailid', emailid);
  
  var licenseStatus = this.licenseActiveStatus == 'true' ? 'active' : 'inactive';

  const schoolAdminData = {
    emailid: this.emailid,
    schoolname: this.schoolForm.get('schoolName')?.value,
    schoolcode: this.schoolForm.get('schoolCode')?.value,
    regionname: this.schoolForm.get('regionname')?.value,
    countryname: this.schoolForm.get('country')?.value,
    city: this.schoolForm.get('postalAddress')?.value,
    startdate: this.startdate,
    enddate: this.enddate,
    licensetype: this.licensetype,
    licensestatus: licenseStatus,
    orderid: this.orderid,
    firstname: this.firstname,
    lastname: this.lastname,
    activestatus: licenseStatus,
    LICId: this.licenseid
  };

  console.log('schoolData', schoolAdminData);

  this.auth.getTVInformation(schoolAdminData, '/createschooladd').then((res: any) => {
    console.log('res school11', res);

    this.auth.loginAfterActivateLicense(emailid).subscribe((user) => {
      console.log("User Management ___________________", user);
      console.log("user user user ", user.classidavchat);

      // Update localStorage with user information
      localStorage.setItem("username", user.username);
      localStorage.setItem("type", user.type);
      localStorage.setItem("userId", user.userId.toLowerCase());
      localStorage.setItem("session_id", user.session_id);
      localStorage.setItem("accessToken", user.session_id);
      localStorage.setItem("refreshToken", user.refreshToken);
      localStorage.setItem("classidavchat", user.classidavchat);
      localStorage.setItem("type", 'schooladmin');
      localStorage.setItem('adminrole', 'schooladmin'); // Set role to schooladmin
      
      // Update user role from the backend response
      if (user.type == "schooladmin") {
        this.auth.getTVInformation({ emailid: user.userId, role: user.type }, "/getadmin")
          .then((res: any) => {
            try {
              if (res) {
                localStorage.setItem("licensetype", res.body.data.licensetype);
                localStorage.setItem("schoolname", res.body.data.schoolname);
                localStorage.setItem("countryname", res.body.data.countryname);
                localStorage.setItem("regionname", res.body.data.regionname);

                console.log(res.body.data.enddate, "for licensedetails");

                var enddateString = res.body.data.enddate;
                var enddate = new Date(enddateString);
                var today = new Date();
                var differenceMs = enddate.getTime() - today.getTime();
                var differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
                
                console.log(differenceDays, "differ");
                console.log(res.body.data.activestatus, "Bandana");

                if (user.type === "schooladmin" && res.body.data.activestatus === "active") {
                  console.log("User is active and has schooladmin role.");
                  return;
                }

                // Check for subscription expiration
                if (differenceDays < 0) {
                  return;
                } else {
                  console.log("The end date is in the future. Days left until expiration:", differenceDays);
                  this.auth.setTriggerState(true);
                  localStorage.setItem("subscription", differenceDays.toString());
                  window.location.reload();  // Reload page to apply changes
                }
              } else {
                console.log("No data from backend.");
              }
            } catch (e) {
              console.log(e);
            }
          });
      }
    }, (err: any) => {
      console.error("Error in API", err);
    });
  });

  this.closeDemoUpgradeOption.emit(false);
}


  onCreateSchool(): void {
    if (this.schoolForm.valid) {
      // Store the form values to display in another div
      this.submittedSchoolDetails = this.schoolForm.value;

      // Optionally reset the form after submission
      this.schoolForm.reset();
    } else {
      console.log('Form is invalid');
    }
  }

  onNextStep() {
    if (this.isLicenseDetailsVisible) {
      this.isSchoolDetailsVisible = true;
      this.isLicenseDetailsVisible = false;
    } else {
      this.isLicenseDetailsVisible = true;
      // this.form.get('licenseID')?.disable(); // Disable License ID input field
    }
  }

   clear(){
    this.licenseEntryForm.patchValue({
      licenseID: ""
    });
    }

}
