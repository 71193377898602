import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FileService } from '../file.service';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AbstractControl, FormGroup, FormsModule, FormControl, FormArray, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { differenceInDays } from 'date-fns';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-demoaccounts',
  templateUrl: './demoaccounts.component.html',
  styleUrls: ['./demoaccounts.component.scss']
})
export class DemoaccountsComponent implements OnInit {

  public save_exit_ref: BsModalRef;
  public save_exit_ref1: BsModalRef;
  bsModalRef: BsModalRef;
  Alldemoaccounts: any = [];
  position = "absolute";
  demoaccounts: any = {};
  AdminCheckboxListArray: any = [];
  adminnames: any = [];
  admin: any;
  schoolnames: any = [];
  schoolname: any;
  isModalOpen: boolean = false;
  public data: any = {};
  public error: any = {};
  public registerStatus;
  public registerStatusMessage;
  public showRegisterStatus = false;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dtInitialied: boolean = false;
  nodata: boolean = true;
  countrynames: any = [];
  dltclk: boolean = false;
  public hideTable = true;
  selectedItemId: any;
  clickedRowCoordinates: { top: number, left: number } = { top: 0, left: 0 };
  selectedItemName: any;
  editclk: boolean = false;
  addclk: boolean = false;
  regionnames: any = []; countryname: string;
  regionname: string;
  countrynamesadd: any = [];
  regionnamesadd: any = [];
  Allregionandcountries: any = [];
  adminrole: string;
  public pageNo: number = 1;
  public pageLimit: number = 5
  public totalCount: number;
  totalEntries: number;
  public showdata = [5, 10, 25, 50, 100, 500, 1000];
  hide = true;
  hide1 = true;
  addclkl: boolean = false;
  editclkl: boolean = false;
  isDemoClicked: boolean = false;
  todayDate: any;

  disabledfield: Boolean = false;
  activestatus: Boolean = false;

  // @ViewChild('cardElement') cardElementRef: ElementRef;
  @ViewChild('cardElement', { static: false }) cardElementRef!: ElementRef;
  LICId: any;
  decodedLICId: string;
  constructor(public auth: FileService,
    public router: Router,
    public formBuilder: FormBuilder,
    private modalService: BsModalService,
    private dialogService: DialogService
  ) {
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 10,
    //   searching: false,
    //   scrollX: true,
    //   lengthChange: false
    // };
  }
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  @ViewChild('modalContent1') modalContent1: TemplateRef<any>;
  url: any = environment.joinavurl;
  userForm!: FormGroup;

  ngOnInit(): void {
    this.regionname='';
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
    const year = today.getFullYear(); // Full year (4 digits)

    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate);
    this.todayDate = formattedDate;

    this.todayDate = formattedDate;
    console.log(this.todayDate, "this.todaydate Bandana")
    document.body.addEventListener('click', this.handleBodyClick.bind(this));
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
    }
    this.demoaccounts = this.formBuilder.group({
      selectedAdmin: [""]
    });

    localStorage.setItem('adminlisting', "demoaccounts");
    this.adminrole = localStorage.getItem('adminrole')
    if (this.adminrole == 'regionaladmin') {
      this.regionname = localStorage.getItem('regionname')
      this.countryname = '';
    } else if (this.adminrole == 'countryadmin') {
      this.regionname = localStorage.getItem('regionname')
      this.countryname = localStorage.getItem('countryname');
      console.log("cccccccc")
    } else if (this.adminrole == 'teacher') {
      this.regionname = localStorage.getItem('regionname')
      this.countryname = localStorage.getItem('countryname');
      this.schoolname = localStorage.getItem('schoolname');
    } else {
      this.schoolname = ''
      this.countryname = '';
      this.regionname = ''
    }
    this.getallschool()
    // this.getallcountrys()
    this.getalldemoaccounts(this.pageLimit, this.pageNo)
    this.getallregionsandcountrys();
    this.data.licensetype = "1 user free version / freemium license"
    this.userForm = this.formBuilder.group({
      validity: ['', Validators.required],
      licenseid: ['', Validators.required],
    })
  }


  reinitializeDatatable(data) {
    if (this.dtInitialied) {
      console.log(this.dtElement)
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.Alldemoaccounts = data;
        this.dtTrigger.next();
      });
    }
    else {
      this.dtInitialied = true;
      this.Alldemoaccounts = data;
      this.dtTrigger.next();
    }
  }
  getallschool() {
    this.schoolnames = []
    this.auth.getTVInformation({}, '/getallschoolforadd')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                console.log(res, "ressssssssss")
                // this.schoolnames.push('All schools')
                res.body.data.map(one => {
                  this.schoolnames.push(one.schoolname)
                })
              } else {
              }
            } else {
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  getallcountrys() {
    this.auth.getTVInformation({}, '/getAllcountryadmin')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                this.countrynames.push('All country')
                res.body.data.map(one => {
                  this.countrynames.push(one.countryname)
                })
                this.countrynames = this.countrynames.sort();
              } else {
              }
            } else {
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }

  getalldemoaccounts(lmt, indx) {
    let data = {
      limit: lmt,
      index: indx,
      schoolname: this.schoolname,
      countryname: this.countryname
    }
    this.adminnames = []
    this.demoaccounts = this.formBuilder.group({
      selectedAdmin: [""]
    });
    this.auth.getTVInformation(data, '/getalldemoaccounts')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                this.nodata = false
                // this.reinitializeDatatable(res.body.data)
                console.log(res, "ressssssssss")
                this.Alldemoaccounts = res.body.data;
                this.totalEntries = res.body.count
                this.totalCount = Math.ceil(res.body.count / lmt)
                console.log(this.totalEntries, this.totalCount, "countt")
                res.body.data.map(one => {
                  this.adminnames.push(one.name)
                  // this.schoolnames.push(one.schoolname)
                })
              } else {
                this.nodata = true
              }
            } else {
              this.nodata = true
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  AddDemoaccounts() {
    this.data = {}
    this.editclk = false;
    this.addclk = true
    this.isDemoClicked = false
    if (this.adminrole == 'regionaladmin') {
      this.data.regionname = localStorage.getItem('regionname')
      this.updatecountrys()
    } else if (this.adminrole == 'countryadmin') {
      this.data.countryname = localStorage.getItem('countryname');
      console.log(this.data, "dddd")
    } else if (this.adminrole == 'teacher') {
      // this.data.regionname = localStorage.getItem('regionname')
      // this.updatecountrys()
      this.data.countryname = localStorage.getItem('countryname');
      this.data.regionname = localStorage.getItem('regionname');
      console.log(this.data, "dddd")
    } else {

    }
    this.save_exit_ref = this.modalService.show(this.modalContent, {
      class: 'setHeight',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
  }

  closeModal() {
    this.isModalOpen = false;
    this.save_exit_ref.hide();
  }
  AddLicense() {
    this.editclkl = false
    this.addclkl = true
    this.save_exit_ref.hide();
    this.save_exit_ref1 = this.modalService.show(this.modalContent1, {
      class: 'modal-md-lg-sm',
      backdrop: 'static',  // Prevents closing on outside clicks
      keyboard: false      // Prevents closing using the keyboard
    });
  }

  closeModal1() {
    this.save_exit_ref1.hide();
  }

  SearchClick() {
    this.nodata = false
    let lmt = this.pageLimit
    let indx = this.pageNo
    let data = {
      limit: lmt,
      index: indx,
      adminname: this.admin,
      schoolname: this.schoolname,
      countryname: this.countryname
    }
    this.auth.getTVInformation(data, '/getalldemoaccounts')
      .then(
        (res: any) => {
          try {
            if (res) {
              console.log(res, "ressssssssss")
              this.Alldemoaccounts = res.body.data;
              this.totalEntries = res.body.count
              this.totalCount = Math.ceil(res.body.count / lmt)
              console.log(this.totalEntries, this.totalCount, "countt")
            } else {
              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }

  openModal() {
    this.isModalOpen = true;
  }

  licensestatus(e: any) {
    console.log(e)
    this.data.licensestatus = e
    this.isDemoClicked = !this.isDemoClicked;
  }
  validatelicenseold() {
    var errors_found = false;
    this.error = {};
    this.error.orderidNotValid = false;
    // this.error.validityNotValid = false;
    this.error.licensetypeNotValid = false;
    this.error.licensestatusNotValid = false;
    this.error.startdateNotValid = false;
    this.error.enddateNotValid = false;

    this.data.startdate = this.data.startdate ? this.data.startdate.trim() : "";
    this.data.enddate = this.data.enddate ? this.data.enddate.trim() : "";
    this.data.orderid = this.data.orderid ? this.data.orderid.trim() : "";
    // this.data.validity = this.data.validity ? this.data.validity.trim() : "";
    this.data.licensetype = this.data.licensetype ? this.data.licensetype.trim() : "";
    this.data.licensestatus = this.data.licensestatus ? this.data.licensestatus.trim() : "";



    // if (this.data.orderid.length == 0) {
    //   this.error.orderidNotValid = true;
    //   this.error.orderidNotValidText = "Please enter the orderid";
    //   errors_found = true;
    // }

    if (this.data.startdate.length == 0) {
      this.error.startdateNotValid = true;
      this.error.startdateNotValidText = "Please enter the startdate";
      errors_found = true;
    }

    if (this.data.enddate.length == 0) {
      this.error.enddateNotValid = true;
      this.error.enddateNotValidText = "Please enter the enddate";
      errors_found = true;
    }
    // if (this.data.licensetype.length == 0) {
    //   this.error.licensetypeNotValid = true;
    //   this.error.licensetypeNotValidText = "Please enter the licensetype";
    //   errors_found = true;
    // }

    // if (this.data.licensestatus.length == 0) {
    //   this.error.licensestatusNotValid = true;
    //   this.error.licensestatusNotValidText = "Please enter the licensestatus";
    //   errors_found = true;
    // }


    if (errors_found) {
      return;
    } else {
      if (this.addclkl) {
        var self = this;
        this.saveRegisterUserDetails(self, self.data)
      }
      if (this.editclkl) {
        this.savelicenseEditedDetails()
      }
    }
  }
  validatelicense(): void {
    var errors_found = false;
    this.error = {};
    const formValue = this.userForm.value;

    this.data.enddate = formValue.validity ? formValue.validity.trim() : "";
    this.data.licenseactivestatus = this.activestatus
    if (errors_found) {
      return;
    } else {
      if (this.editclkl) {
        this.savelicenseEditedDetails()
      }
    }

  }
  checkteachername(self, data) {
    // data.self = this;
    return new Promise(
      (resolve, reject) => {
        self.auth
          .getTVInformation({ 'firstname': data.firstname }, '/checkuserteacher')
          .then(
            (res: any) => {
              console.log(res, "userrr")
              if (res.body.status === "ok") {
                resolve(data);
              } else {
                console.log("Checking teachername: ", data.firstname,
                  " response: ", res);
                self.registerStatus = 'fail';
                self.registerStatusMessage = res.body.message;
                self.showRegisterStatus = true;
                reject(data);
              }
            }, (err: any) => {
              console.log("Error checking teachername: ", data.firstname,
                " response: ", err);
              reject(data);
            });
      });
  }




  disableemaildemo: Boolean = false;

  saveRegisterUserDetails(self, data) {
    this.disableemaildemo = true
    console.log(this.data);

    const resetlink = `${this.url}`;
    this.auth.demoaccountvalidation(this.data.emailid).subscribe(
      (val: any) => {
        console.log("value", val)
        if (val.data.result === 200) {
          this.auth.setdemoaccountsviaemail(this.data).subscribe(      // adding data in db
            (val: any) => {
              this.auth.sendDemoPasswordEmail(this.data.emailid, resetlink, this.data.firstname).subscribe(     //sending email
                (response) => {

                  console.log('Demo email send', response);
                  this.dialogService.showSimplePopup('Email sent successfully')
                  this.disableemaildemo = true
                   this.closeModal();
                   this.getalldemoaccounts(this.pageLimit, this.pageNo)
                  // this.closeModal1();


                },
                (error) => {
                  if (error.status === 400 && error.error.error === 'Invalid emailId provided') {
                    this.dialogService.showError('Please enter a valid Email ID.');

                  } else {
                    console.error('Error sending demo password email:', error);
                    this.dialogService.showError("Failed to send email.")

                  }


                }
              );

            }
          ), (error: any) => {
            this.dialogService.showError("Internal server error. Please try again later.")

            console.log("An error occured while inserting data in demoaccount ", error)
          }
        } else {

          this.dialogService.showError(val.data.message)
          this.disableemaildemo = false

        }

        console.log(val)

      }, (error: any) => {

        console.error("An error occurred", error);
      }
    );
  }

  handleTdClick(Event: any, itemId: any, name: any, item: any) {
    // Toggle the selectedItemId to show/hide the card for the clicked row
    console.log(item.LICId, "eee")
    this.LICId = item.LICId
    Event.stopPropagation();
    this.selectedItemId = this.selectedItemId === itemId ? null : itemId;
    this.selectedItemName = this.selectedItemName === name ? null : name;
    this.clickedRowCoordinates = { top: Event.clientY, left: Event.clientX };
    // Additional logic if needed
  }

  delete() {
    this.dltclk = true
    console.log(this.selectedItemId)
    console.log("General Delete");
    if (this.selectedItemId) {
      var yes = confirm("Are you sure you want to delete? = " + this.selectedItemName);
      console.log(yes)
      if (yes == true) {
        this.auth.getTVInformation(
          { selectedid: this.selectedItemId, licid: this.LICId }, '/deletedemoaccount').then((res: any) => {
            try {
              if (res && res.status) {
                console.log(res.data, "inside dlt")
                this.dialogService.showSimplePopup("Deleted successfully.");
                this.getalldemoaccounts(this.pageLimit, this.pageNo)
              } else {
                console.error("General error");
              }
            } catch (e) {
              console.error(e);
            }
          }, (err: any) => {
            console.error(err);
          });
      } else {
        // this.AdminCheckboxListArray = [];
      }
    }
    else {
      this.dialogService.showError("Select something to delete!")
    }

  }


  edit() {
    console.log("Calling WOEditClick HTTP server regionnn")
    this.editclk = true
    this.addclk = false
    this.auth.getTVInformation({ 'id': this.selectedItemId }, '/getdemoaccount').then((res: any) => {
      try {
        console.log(res, "edit ress region")
        if (res) {
          if (res.body.status === true) {
            this.save_exit_ref = this.modalService.show(this.modalContent, Object.assign({}, { class: "modal-md-lg-sm" }));
            this.data = res.body.data;
            this.data['firstname'] = res.body.data['firstname'];
            this.data['lastname'] = res.body.data['lastname'];
            this.data['emailid'] = res.body.data['emailid'];
            this.data['address'] = res.body.data['address'];
            this.data['confirmpassword'] = res.body.data['password'];
            this.data.regionnameadd=res.body.data.regionname;
            this.data.countrynameadd=res.body.data.countryname;
            console.log("Selected resData2: ", this.data);
          }
        } else {
          this.data = {};
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  saveEditedDetails() {
    console.log("saveBPDetails Data : ", this.data);
    this.data.countryname=this.data.countrynameadd?this.data.countrynameadd:this.data.countryname;
    this.data.regionname=this.data.regionnameadd?this.data.regionnameadd:this.data.regionname
    this.auth.getTVInformation({ "data": this.data }, '/editdemoaccount').then((res: any) => {
      try {
        console.log("Status: ", res);
        if (res.body.status == true) {
          // this.router.navigate(['/countryadmin'])
          this.save_exit_ref.hide()
          this.getalldemoaccounts(this.pageLimit, this.pageNo)
        } else {
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  EditCancel() {
    this.save_exit_ref.hide()
  }

  previousenddate: any

  licenseditold() {
    console.log("Calling WOEditClick HTTP server regionnn")
    this.editclkl = true
    this.addclkl = false
    this.disabledfield = true
    this.auth.getTVInformation({ 'id': this.selectedItemId }, '/getdemoaccount').then((res: any) => {
      try {
        console.log(res, "edit ress region")
        if (res) {
          if (res.body.status === true) {
            this.save_exit_ref1 = this.modalService.show(this.modalContent1, {
              class: 'modal-md-lg-sm',
              backdrop: 'static',  // Prevents closing on outside clicks
              keyboard: false      // Prevents closing using the keyboard
            });
            this.data = res.body.data;
            console.log(this.data);
            let givenDateString = this.data.enddate;
            this.previousenddate = this.data.enddate;


            let givenDate = new Date(givenDateString);


            let today = new Date();


            today.setHours(0, 0, 0, 0);
            givenDate.setHours(0, 0, 0, 0);


            // if (today.getTime() === givenDate.getTime()) {
            //   console.log('Today is the given date.');
            //   this.activestatus=true;
            // } else if (today.getTime() < givenDate.getTime()) {
            //  this.activestatus=true;
            // } else {
            //   console.log('Today is after the given date.');
            //   this.activestatus=false;
            // }


            if (today.getTime() > givenDate.getTime()) {
              this.activestatus = false;

            }

            if ((today.getTime() > givenDate.getTime()) === false && this.data.activestatus === "active") {
              this.activestatus = true
            }

            if ((today.getTime() > givenDate.getTime()) === false && this.data.activestatus === "inactive") {
              this.activestatus = false
            }
            if (this.data.licensestatus == 'demo') {
              this.isDemoClicked = true
            } else {
              this.isDemoClicked = false
            }
          }
        } else {
          this.data = {};
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  licenseedit() {
    console.log("Calling WOEditClick HTTP server")
    // this.error = {};
    this.editclkl = true
    this.addclkl = false
    this.auth.getTVInformation({ 'selectedid': this.LICId }, '/license/getlicenseforedit').then((res: any) => {
      try {
        console.log(res, "edit ress")
        if (res) {
          if (res.body.status === true) {
            this.save_exit_ref1 = this.modalService.show(this.modalContent1, {
              class: 'modal-md-lg-sm',
              backdrop: 'static',  // Prevents closing on outside clicks
              keyboard: false      // Prevents closing using the keyboard
            });
            this.data = res.body.data.data;
            console.log(this.data);
            let encodedLICId = this.LICId; // This should be the base64-encoded value
            this.decodedLICId = atob(encodedLICId);
            console.log(this.decodedLICId);  // This will print the decoded value
            this.userForm.patchValue({
              licenseid: this.decodedLICId,
              validity: this.formatDate(this.data['enddate']),

            });

            if (this.data.licenseactivestatus == 'true') {
              this.activestatus = true
            } else {
              this.activestatus = false
            }
            // this.data['confirmpassword'] = res.body.data['password'];

            console.log("Selected resData2: ", this.data);

          }
        } else {
          this.data = {};
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }
  formatDate(date: any): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;  // Format as YYYY-MM-DD
  }

  savelicenseEditedDetails() {
    console.log("saveBPDetails Data : ", this.data);
    this.disableemaildemo=true;
    var savedata = {
      licenseactivestatus: this.data.licenseactivestatus,
      validity: this.data.enddate,
      LICId: this.data.licenseid
    }

    console.log(savedata, "editeddata")
    this.auth.getTVInformation({ "data": this.data }, '/license/savelicsense').then((res: any) => {
      try {
        this.disableemaildemo=false;
        console.log("Status: ", res);
        if (res.body.status == true) {
          this.save_exit_ref1.hide()
          this.getalldemoaccounts(this.pageLimit, this.pageNo)
        } else {
          console.error("General error");
        }
      } catch (e) {
        console.error(e);
      }
    }, (err: any) => {
      console.error(err);
    })
  }

  licenseEditCancel() {
    this.save_exit_ref1.hide()
  }

  getallregionsandcountrys() {
    this.auth.getTVInformation({}, '/getallregionsandcountrys')
      .then(
        (res: any) => {
          try {
            if (res) {
              if (res.body.data.length > 0) {
                console.log(res.body.data, "regioncountrymapping")
                this.Allregionandcountries = res.body.data
                // this.regionnames.push('All regions')
                // this.countrynames.push('All countries')
                res.body.data.map(one => {
                  this.regionnames.push(one.Region)
                  this.regionnamesadd.push(one.Region)
                  this.countrynames.push(one.Country);
                  this.countrynames = this.countrynames.sort();
                  this.countrynamesadd.push(one.Country);
                  this.updates();
                })
                this.regionnames = [... new Set(this.regionnames)]
                this.regionnamesadd = [... new Set(this.regionnamesadd)]
              } else {

              }
            } else {

              console.error("General error");
            }
          } catch (e) {
            console.error(e);
          }
        }, (err) => {
          console.log(err)
        })
  }
  updatecountrys() {
    this.countrynamesadd = []
    this.Allregionandcountries.map(one => {
      if (this.data.regionname == one.Region) {
        this.countrynamesadd.push(one.Country)
      }
    })
  }
  clearFilters() {
    this.admin = null
    if (this.adminrole == 'regionaladmin') {
      this.regionname = localStorage.getItem('regionname')
      this.countryname = '';
    } else if (this.adminrole == 'countryadmin') {
      this.regionname = localStorage.getItem('regionname')
      this.countryname = localStorage.getItem('countryname');
    } else if (this.adminrole == 'teacher') {
      this.regionname = localStorage.getItem('regionname')
      this.countryname = localStorage.getItem('countryname');
      this.schoolname = localStorage.getItem('schoolname');
    } else {
      this.schoolname = 'All schools'
      this.countryname = '';
      this.regionname = 'All regions'
    }
    this.SearchClick()
  }
  clearcross(fieldName: string) {
    // Reset the value of the specified field to an empty string
    this.data[fieldName] = '';
  }
  onOverlayClick(event: MouseEvent): void {
    // Handle the click event inside the modal overlay
    // For example, prevent the modal from closing
    event.stopPropagation();
  }

  handleBodyClick(event: Event) {
    const target = event.target as HTMLElement;
    if (this.selectedItemId && this.cardElementRef?.nativeElement) {
      if (!this.cardElementRef.nativeElement.contains(target)) {
        this.selectedItemId = null; // Hide the card
      }
    }
  }

  //server side pagination
  prevPage() {
    let temp = this.pageNo
    temp -= 1
    if (temp >= 1) {
      this.pageNo -= 1
      this.getalldemoaccounts(this.pageLimit, this.pageNo);;
    }

  }

  nextPage() {
    console.log(this.pageNo, "nextpageeeeeeee")
    let tmp = Number(this.pageNo) + 1
    //console.log(tmp,this.totalCount,"mmmmmmmmmmmm")
    if (tmp <= this.totalCount) {
      this.pageNo = Number(this.pageNo) + 1
      this.getalldemoaccounts(this.pageLimit, this.pageNo);
    }
  }



  nextPageInput() {
    let page_input = this.pageNo;
    if ((page_input <= this.totalCount) && (page_input >= 1)) {
      this.getalldemoaccounts(this.pageLimit, this.pageNo);
    } else {
      this.getalldemoaccounts(this.pageLimit, this.pageNo);
    }
  }

  PageSize(val) {
    this.pageNo = 1
    this.getalldemoaccounts(this.pageLimit, this.pageNo);
  }

  booleanValues: { [key: string]: boolean } = {};
  sortedColumn: string = 'name';
  sortFunction(colName: string) {
    if (this.booleanValues[colName] === undefined) {
      this.booleanValues[colName] = true;
    }

    if (this.booleanValues[colName]) {
      this.Alldemoaccounts.sort((a, b) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0);
    } else {
      this.Alldemoaccounts.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0);
    }

    this.booleanValues[colName] = !this.booleanValues[colName];
    this.sortedColumn = colName;
  }

  onStartDateChange() {
    const startDate = new Date(this.data.startdate);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 30); // Add 30 days to start date
    this.data.enddate = endDate.toISOString().substring(0, 10); // Format end date as 'YYYY-MM-DD'
  }

  calculateDaysLeft(enddate: string): string {
    let left: string;
    const today = this.normalizeDate(new Date());
    const endDate = this.normalizeDate(new Date(enddate));
    const days = differenceInDays(endDate, today);
    // console.log(endDate, days, "days left");

    if (days === 0) {
      left = "Expiring date is today";
    } else if (days < 0) {
      left = `Expired ${Math.abs(days)} days ago`;
    } else {
      left = `${days} days left`;
    }
    return left;
  }
  normalizeDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  isExpired(enddate: string): boolean {
    const today = this.normalizeDate(new Date());
    const endDate = this.normalizeDate(new Date(enddate));
    return endDate < today;
  }


  activestatusupdate() {
    if (this.disabledfield === true) {
      return
    }
    if (this.activestatus === true) {
      this.activestatus = false
    } else {
      this.activestatus = true
    }

    console.log(this.activestatus, "console")
  }


  disablefields() {
    this.disabledfield = false
  }



  showstatefield: Boolean = false;
  countrychange() {
    console.log(this.data.countryname);
    if (this.data.countryname === "United States of America") {
      this.showstatefield = true;

      this.getstatesbycountry(this.data.countryname);

    } else {
      this.data.state = "";
      this.showstatefield = false;
    }
  }

  states: any = [];
  getstatesbycountry(country) {
    console.log("getting countrys");
    this.auth.fetchStatesbyCountry(country).subscribe(
      (val: any) => {

        console.log("States fetched", val);
        this.states = val.data.map(item => item.state);
        this.states = this.states.sort();
        console.log(this.states);

      },
      (error: any) => {

        console.error("An error occurred while fetching states", error);

      }
    );
  }



  validateRegisterUser(form) {
    if (form.valid) {
      console.log(this.data);
      console.log("Form is valid");
      if (this.addclk) {
        var self = this;
        // self.AddLicense()
        this.saveRegisterUserDetails(self, self.data)
      }
      if (this.editclk) {
        this.saveEditedDetails()
      }
    } else {
      console.log("Form is invalid")
    }
  }
  setStatus(status: boolean) {
    this.activestatus = status;
  }


  updateDemoactivestatus(value) {
    console.log(this.previousenddate)
    if (this.disabledfield) {
      console.log("hello")
      return
    }
    console.log(value);
    this.activestatus = value;
    this.data.activestatus = value === true ? 'active' : 'inactive';

    if (this.data.activestatus === 'active') {
      this.updateendate();
    } else {
      this.data.enddate = this.previousenddate;
    }

  }



  updateendate() {
    let givenDateString = this.data.enddate;


    let givenDate = new Date(givenDateString);


    let today = new Date();




    if ((today.getTime() > givenDate.getTime()) && this.activestatus) {
      let tday = new Date();
      let endDate = tday;
      endDate.setDate(tday.getDate() + 30); // Add 30 days to start date
      this.data.enddate = endDate.toISOString().substring(0, 10);
      console.log('Today is after the given date.');

    }
  }

  regionchange() {
    this.countrynamesadd = []
    if (this.data.regionnameadd === 'All regions') {
      this.countrynamesadd = this.Allregionandcountries.map((item) => item.Country)
    } else {
      this.Allregionandcountries.map((one: { Region: any; Country: any; }) => {
        if (this.data.regionnameadd == one.Region) {
          this.countrynamesadd.push(one.Country)
        }
      })
    }
  }


  updates(){
    this.countrynames =[]

    if(this.regionname===''){
      this.countrynames=this.Allregionandcountries.map((item)=>item.Country)
    }else{
    this.Allregionandcountries.map(one => {
      if (this.regionname == one.Region) {
        this.countrynames.push(one.Country)
      }
    })
  }
    this.countrynames=this.countrynames.sort();
    console.log(this.countrynames,"this.countrynames in updates");
   // this.countrynames.push('All countries');
    // this.getallschool()
    // this.getallteachers()
  }

}


