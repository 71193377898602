import { Component, OnInit } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { FileService } from '../file.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-uploadmedia',
  templateUrl: './uploadmedia.component.html',
  styleUrls: ['./uploadmedia.component.scss']
})
export class UploadmediaComponent implements OnInit {
  selectedFile: File | null = null;
  imageUrl: string | null = null;
  image3dUrl: string | null = null;
  videoUrl: string | null = null;
  
  imagesList: string[] = []; // Array to hold the image URLs
  images3dList: string[] = ['https://wpdev.tpvai.com/threedyDetails/path-1654242400671-273339586-airboat.html','https://wpdev.tpvai.com/threedyDetails/path-1655270774116-152621670-Pawns.html','https://wpdev.tpvai.com/threedyDetails/path-1658239033782-802916607-RobotExpressive.html'];
  videolist:  string[]=  [];
  public onClose: Subject<any>;


  constructor(private fileService: FileService,private http: HttpClient,public bsModalRef: BsModalRef) {}


  ngOnInit(): void {
    this.onClose = new Subject();
    this.fetchImages(); 
    //this.fetchVideos();   
  }

  totalimages:boolean=false;
  fetchImages(): void {
    this.totalimages=true;
    this.img3d=false;
    this.totalvideos=false;
    this.fileService.getImages().subscribe(
      (response: any) => {
        this.imagesList = response.filenames; // Extract the image URLs from the API response
        console.log("list",this.imagesList)
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  totalvideos:boolean=false;
  fetchVideos(): void {
  this.totalimages=false;
  this.img3d=false;
  this.totalvideos=true;
    this.fileService.getVideos().subscribe(
      (response: any) => {
        this.videolist = response.filenames; // Extract the image URLs from the API response
        console.log("list",this.videolist)
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
  img3d:boolean=false;
  fetch3dImages(): void {
    
    const image33d=this.images3dList
    console.log("list",image33d)
    // console.log("calling3d function")
    // this.fileService.get3dImages().subscribe(
    //   (response: any) => {
    //     this.images3dList = response.filenames; // Extract the image URLs from the API response
    //     console.log("list",this.images3dList)
    //   },
    //   (error: any) => {
    //     console.error(error);
    //   }
    // );
    this.totalimages=false;
    this.totalvideos=false;
    this.img3d=true;
  }

  gettingimageurl(url){
    console.log("iiiii",url)
    this.onClose.next(url);
  }

  gettingvideourl(url){
    
    var gh={
      videourl:url,
      type:"video"
    }
    console.log("vvvv",gh)
    this.onClose.next(gh);
  }

  getting3dimage(url){
    var threed={
      three3durl:url,
      type:"3d"
    }
    console.log("3ddd",threed)
    this.onClose.next(threed);
  }


  closeModal() {
    this.bsModalRef.hide();
  }

  onFileSelectedimg(event: any) {
    this.selectedFile = event.target.files[0];
    console.log("cccccccc",event,this.selectedFile)
    this.preview3dImage();
  }

  previewImage() {
    console.log("priveiw image function called")
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    console.log("imageurl",this.imageUrl)
    reader.readAsDataURL(this.selectedFile);
  }

  onFileSelectedvid(event: any) {
    this.selectedFile = event.target.files[0];
    console.log("Selected File:", this.selectedFile);
    this.previewVideo();
  }
  
  previewVideo() {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.videoUrl = event.target.result;
      console.log("ssss",event)
    };
    
    console.log("Video URL:", this.videoUrl);
    reader.readAsDataURL(this.selectedFile);
  }

  preview3dImage() {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.image3dUrl = event.target.result;
    };
    console.log("image3durl",this.image3dUrl)
    reader.readAsDataURL(this.selectedFile);
  }





  uploadImage() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
console.log("inside upload image function",this.selectedFile)
      this.http.post(environment.serverurl+'/uploads_image', formData)
        .subscribe(
          (response: any) => {
            console.log("uploadimage calling",response);
            this.fetchImages()
          },
          (error: any) => {
            console.error(error);
          }
        );
    }
    
  }

  uploadVideos() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      this.http.post(environment.serverurl+'/uploads_video', formData)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.fetchVideos()
          },
          (error: any) => {
            console.error(error);
          }
        );
    }
    
  }

}

