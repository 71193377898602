import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.css']
})
export class YoutubeComponent implements OnInit {
  loading: boolean = false;
  search: boolean=false;
  private API_URL = 'https://www.googleapis.com/youtube/v3/search';
  private API_TOKEN = 'AIzaSyA7-LLTipSAorbPUW3_wovA1g2DEkM85CY';

searchText: any;
  inputTouched: boolean = false;
  public onClose: Subject<any>;
  videos: any = [];

  constructor(private http: HttpClient, public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  handleSearch(inputValue: any) {
    console.log(inputValue);
    this.loading = true;
    this.search=true;
    this.getVideos(inputValue)
        .subscribe((items: any) => {
            this.videos = items.map(item => {
                return {
                    title: item.snippet.title,
                    videoId: item.id.videoId,
                    videoUrl: `https://www.youtube.com/watch?v=${item.id.videoId}`,
                    iframVideoInsertion: `https://www.youtube.com/embed/${item.id.videoId}`,
                    channelId: item.snippet.channelId,
                    channelUrl: `https://www.youtube.com/channel/${item.snippet.channelId}`,
                    channelTitle: item.snippet.channelTitle,
                    description: item.snippet.description,
                    publishedAt: new Date(item.snippet.publishedAt),
                    thumbnail: item.snippet.thumbnails.high.url
                };
            });
            console.log(items);
            console.log(this.videos);

            this.inputTouched = true;
            this.loading = false;
        });
}

getVideos(query: any): Observable<any> {
  const url = `${this.API_URL}?q=${query}&key=${this.API_TOKEN}&part=snippet&type=video&maxResults=10`;
  return this.http.get(url)
      .pipe(
          map((response: any) => response.items)
      );
}

imageUploadToEtherPadAsIfram(image,thumbnail,videoUrl) {

  let width = 600;
  let height = 400;
  // this.loader = true;
  // this.removeLoader();
  let info = {
    type: "IFRAME",
    data: image,
    width: width,
    height: height,
    thumbnail:thumbnail,
    videourl:videoUrl
  }
  this.onClose.next(info);
}

closeModal() {
  this.bsModalRef.hide();
}

}
