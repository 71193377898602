import { Component, OnInit, ViewChild, HostListener, TemplateRef, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileService } from '../file.service';
// import { FilemanagerComponent } from '../filemanager/filemanager.component';
import { BrowserDetectionService } from '../browser-detection.service';
import { DialogService } from '../dialog.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-userpasslogin',
  templateUrl: './userpasslogin.component.html',
  styleUrls: ['./userpasslogin.component.scss']
})
export class UserpassloginComponent implements OnInit {
  //loginForm: FormGroup;
  public loginForm;
    public position = "absolute";
    public ui_language;
    public username = "";
    public isSession = true;
    public width: any;
    public user;
    userrole: any;
    closeIntAlert: boolean = false;
    subscription: boolean = false;
    submsg: string;
    publicIp: any;
    isInternetConnected:Boolean
    showresolutionerror: boolean = false;
  constructor(public router: Router,
              private formBuilder: FormBuilder,
              public auth:FileService,
              //public filemanager:FilemanagerComponent,
              public _Activatedroute:ActivatedRoute,
              private dialogService: DialogService,
              private browserDetectionService: BrowserDetectionService) { }

  ngOnInit() {
    console.log("inside login ngoninit")
    this.auth.getConnectionStatus().subscribe((status) => {
      this.isInternetConnected = status;
      console.log(this.isInternetConnected,"Bandana Checking internet connection everytime");
    });

console.log(this._Activatedroute.snapshot.queryParams);
this.user = this._Activatedroute.snapshot.queryParams;
    this.width = '100%'
    this.loginForm = this.formBuilder.group({
        loginname: ["", Validators.required],
        accesskey: ["", Validators.required]
    })
    //this.router.navigate(['login'])
    console.log("Local STORAGE ", localStorage);
    if (localStorage.getItem('session_id') && localStorage.getItem('type')
        && localStorage.getItem('session_id') != "undefined" && localStorage.getItem('type') != "undefined") {
          console.log("if statement")
        this.auth.getTVInformation({
            token: localStorage.getItem('session_id'),
            refreshToken: localStorage.getItem('refreshToken')
        }, '/token/validate')
            .then((user: any) => {
                console.log("User session_id ui_language: ", user);
                if(user&&user['auth-result'] =='invalid Token'){
                     return;
                    }
                // localStorage.setItem('username', user.username);
                // localStorage.setItem('userId', user.userId);
                // localStorage.setItem('type', user.type);
                // localStorage.setItem('session_id', user.session_id);
                // localStorage.setItem('accessToken', user.session_id);
                // localStorage.setItem('refreshToken', user.refreshToken);
                // this.collectUserPrefs();
                // this.router.navigate(["/dashboard"]);
                this.isSession = true;

            }, (err: any) => {
                localStorage.clear();
                this.isSession = false;
                console.log("User session_id ui_language (error): ", err);
            });

    } else {
      console.log("else statement")
        localStorage.clear();
        this.isSession = false;
    }
    const currentUrl = window.location.href;
    const userAgent = window.navigator.userAgent;
    console.log("User Agent:", userAgent);
    console.log("Current URL:", currentUrl);

    const sessid = this.getQueryParam(currentUrl, 'sessid');
    if (sessid) {
      console.log("Session ID is present from Android");
      this.auth.setSessionId(sessid);
    } else {
      console.log("Either session ID is not present or not able to extract");
    }

    //this.checkBrowserSettings();
    this.checkOrientationOnLoad();
}

checkOrientationOnLoad(): void {

  console.log('window width and height', window.innerWidth, window.innerHeight);
  if (window.innerHeight > window.innerWidth) {
    console.log('open window alert')
    this.showresolutionerror = true
  } else {
    console.log('close window alert')
    this.showresolutionerror = false;
  }
}

@HostListener('window:resize', ['$event'])
onResize(event: Event) {
  this.checkOrientationOnLoad();
    // this.resetSideNavWidth()
  }


checkBrowserSettings() {
  const browserInfo = this.browserDetectionService.getBrowserInfo();
  console.log('Browser Info:', browserInfo);

  if ((browserInfo.screenWidth == 1920 && browserInfo.screenHeight == 1080)||
      (browserInfo.screenWidth == 1536 && browserInfo.screenHeight == 864)||
      (browserInfo.screenWidth == 1280 && browserInfo.screenHeight == 720 )
      ) {
        console.log("screen resolution match");
    }
    else{
      console.log("screen resolution does not match");
      this.dialogService.showError('Please use 1080p or 720p resolution for the optimal experience.')
      //this.router.navigate(['/browser-warning']);
    }
}

// Function to extract query parameters from URL
getQueryParam(url: string, arg1: string) {
const queryParams = new URLSearchParams(new URL(url).search);
return queryParams.get(arg1);
}

get isFormValid() {
  return this.loginForm.valid;
}


login(val) {
  let roomId = localStorage.getItem("roomID")
  let userId = localStorage.getItem("userId")
  if (roomId && userId) {
    // Call deleteRoom only if both values exist
    this.deleteRoom(roomId, userId);
  } else {
    console.log("roomID or userId is missing in LocalStorage");
  }
  localStorage.clear();
  if(this.isInternetConnected===false){
    return
  }
  var username = '';
  // if(val.loginname.indexOf("@") != -1){
  //    var index =  val.loginname.indexOf("@");
  //    username = val.loginname.slice(0,index)
  // }
  console.log(this.user);

  if(this.user){
      if(this.user.user){
          val.loginname = this.user.user;
      }
      if(this.user.pass){
          val.accesskey = this.user.pass;
      }
  }
  if (username == '') {
      username = val.loginname
      console.log("user",username);
  }
  if (!val.loginname || !val.accesskey) {
    console.log("Before sending message to DialogService");
    this.dialogService.showError("Invalid credentials.");
    console.log("After sending message to DialogService");      return false;
  }
  this.auth.getPublicIp().subscribe((data) => {
    console.log(data,"ipppp")
    this.publicIp = data.ip;
    console.log(this.publicIp,"public ippp")
  this.auth
      .authenticateUser(
          {
              'username': username,
              'password': val.accesskey,
               publicIp: this.publicIp,
               device_sessid: localStorage.getItem('device_sessid')
          }
      )
      .then((user: any) => {
          console.log("User Management ___________________", user);
          console.log("user user user ",user.classidavchat)
          localStorage.setItem('username', user.username);
          localStorage.setItem('type', user.type);
          localStorage.setItem('userId', (user.userId).toLowerCase());
          localStorage.setItem('session_id', user.session_id);
          localStorage.setItem('accessToken', user.session_id);
          localStorage.setItem('refreshToken', user.refreshToken);
          localStorage.setItem('classidavchat', user.classidavchat);
          localStorage.removeItem('prevCanvasObjLen')
             if (user.language ==undefined){

              localStorage.setItem('lang',"en-Us");
              console.log("null lang",localStorage.getItem("lang"));

             }
         else if(user.language!=undefined){
              localStorage.setItem('lang',user.language);
          }
          const loginData = {
            username:val.loginname,
            password:val.accesskey,
            token: this._Activatedroute.snapshot.queryParams.token, // Use the appropriate session ID or token
            sessionId: this._Activatedroute.snapshot.queryParams.sessionId,
            // Add any other data you want to send to the WebSocket server
          };
          this.auth.socket.emit('login-event', loginData);
          // this.collectUserPrefs();
          // this.router.navigate(["/whiteboard"]);
          // this.auth.setTriggerState(true);
          //this.filemanager.newlesson();
        if (user.type == 'teacher' || user.type == 'demoaccounts' || user.type == 'schooladmin') {
          this.auth.getTVInformation({ emailid: user.userId, role: user.type }, '/getadmin').then(
            (res: any) => {
              try {
                if (res) {
                  if(user.type == 'demoaccounts'){
                    localStorage.setItem("licensetype", 'demo');
                  }else{
                    localStorage.setItem("licensetype", res.body.data.licensetype);
                  }
                  console.log(res.body.data.enddate, "for licensedetails");
                  // check subscription expired or not
                  // var enddateString = "2024-06-06"
                  var enddateString = res.body.data.enddate
                  var enddate = new Date(enddateString);
                  var today = new Date();
                  var differenceMs = enddate.getTime() - today.getTime();
                  // Convert the difference from milliseconds to days
                  var differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
                  console.log(differenceDays, "differ")
                  console.log(res.body.data.activestatus,res.body.data.licensestatus,"Bandana")
                  if(user.type==='demoaccounts'&& res.body.data.activestatus==="inactive"){
                    console.log("hello");
                    this.subscription = true;
                    this.submsg = "Subscription expired. Please contact admin to renew plan.";
                    return;
                  }
                  if ((user.type === 'teacher' || user.type === 'schooladmin') && res.body.data.licensestatus === "inactive") {
                    console.log("hello");
                    this.subscription = true;
                    this.submsg = "Subscription expired. Please contact admin to renew plan.";
                    return;
                  }
                  // Determine if the end date is approaching expiration
                  if (differenceDays < 0) {
                    this.subscription = true
                    this.submsg = "Subscription expired. Please contact admin to renew plan."
                    // alert("The subscription has expired.");
                  } else {
                    console.log("The end date is in the future. Days left until expiration:", differenceDays);
                    this.auth.setTriggerState(true);
                    localStorage.setItem("subscription", differenceDays.toString());
                    this.router.navigate(["/whiteboard"]);
                  }
                } else {
                  console.log("no data from backend")
                }
              } catch (e) {
                console.log(e)
              }
            })
        } else {
          this.auth.setTriggerState(true);
          localStorage.setItem("subscription", "100");
          this.router.navigate(["/whiteboard"]);
        }

      }, (err) => {
          console.log(err);
          localStorage.clear();
          this.dialogService.showError("Invalid credentails.");
      });
    })
}

hide = true;
get emailInput() { return this.loginForm.get('Username'); }
get passwordInput() { return this.loginForm.get('Password'); }


  qrcode(event: Event) {
    event.preventDefault();
    this.router.navigate(['/qrlogin']);
  }
  changepass(event: Event) {
    event.preventDefault();
    this.router.navigate(['/pass-recovery']);
  }

  clear(){
  //   this.loginForm = this.formBuilder.group({
  //     loginname: ""
  // })
  this.loginForm.patchValue({
    loginname: ""
  });
  }
  closeAlert(){
    this.closeIntAlert = true
    this.subscription = false
  }


  checkConnection(){
    console.log("checkconnection");
    this.auth.getConnectionStatus().subscribe((status) => {
      this.isInternetConnected = status;
      console.log(this.isInternetConnected,"Bandana Checking internet connection everytime");
    });
  }

  deleteRoom(roomid:any,userid:any) {
    console.log("______________--delete Room funcion exe ______________________-");

    // this.createClass = false;
    // this.m1 = false;
    // this.disposeAllSlots();
    this.auth
        .getTVInformation(
            { roomId: roomid, userId: userid },
            "/deleteRoom"
        )
        .then(
            (res: any) => {
                try {
                    if (res) {
                        console.log("resData", res);
                        if (res.deleteRoom === "success") {
                        }
                    } else {
                        console.error("General error");
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            (err: any) => {
                console.error(err);
            }
        );
}

}
