import { Component, OnInit, HostListener, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "../../environments/environment";
import { FileService } from '../file.service';
import { WhiteboardpageComponent } from '../whiteboardpage/whiteboardpage.component';
import { EditorcanvasComponent } from 'projects/angular-editor-fabric-js/src/public-api';


@Component({
  selector: 'app-backdrop-section',
  templateUrl: './backdrop-section.component.html',
  styleUrls: ['./backdrop-section.component.scss']
})
export class BackdropSectionComponent implements OnInit {

  @ViewChild('canvas', {static: false})
  mycanvas: EditorcanvasComponent;
  @Output() closeShowoption = new EventEmitter<Boolean>();
  imagelist:boolean=false;
  imagesList: string[] = [];
  totalimagesarr: string[] = [];
  openLibrary: boolean = false;
  libraryClicked: boolean = false;
  openpubliclibrary: boolean = false;
  publiclibraryClicked: boolean = false;

  constructor(private router: Router, public whiteboardcomp :WhiteboardpageComponent, public fileservice:FileService) { }

  ngOnInit(): void {
  }

  isPublic:Boolean
  searchpage:any=1
  searchtype:any="";
  searchByname:any="";
  showdata:Boolean=false;
  Shownodata:Boolean=false;
  handleuploadssearch(type:any,handlepageadd:Boolean,IsPublic:Boolean){
    let userId=localStorage.getItem("userId")
    console.log("..............git......")
    console.log(this.searchpage,"searchpage")
    this.searchtype=type
  if(handlepageadd){
    this.searchpage=this.searchpage+1
  } else{
    this.searchpage=1
  }
  this.isPublic=IsPublic;
  let userid=undefined;
  let publicflag=undefined;

  if(this.isPublic===true){
    publicflag=true;
    userid=undefined;
    this.openLibrary = false;
    this.libraryClicked = false;
    this.publiclibraryClicked = true;
    this.openpubliclibrary = true;
  }else{
    publicflag=false;
    userid=userId;
    this.openLibrary = true;
    this.libraryClicked = true;
    this.publiclibraryClicked = false;
    this.openpubliclibrary = false;
  }



  console.log("this.searchpage",this.searchpage)
    this.fileservice.searchuploadedContents(type,this.searchByname,this.searchpage,userid,publicflag).subscribe(
      (response:any)=>{
        console.log("Res--->", response);


  /////////////////////////////image/////////////////////////////////
          if(type==="image"){
            //this.totalimages=true
            if(this.searchpage>1&&response.data.length>0){

              response.data.map(item=>{
                console.log(item,"item");
                this.totalimagesarr.push(item);
                })
              console.log( this.totalimagesarr,"images")
              this.showdata=true;
              this.Shownodata=false;
            }else if(this.searchpage===1&&response.data.length===0){
              this.showdata=false;
              this.Shownodata=true;
            }else if(this.searchpage===1&&response.data.length>0){
             // this.totalimages=true
            this.totalimagesarr=response.data
            console.log(this.totalimagesarr,"this")
           // this.Shownomatch=false;
           this.showdata=true;
              this.Shownodata=false;
            }else{
              return
            }
            }

        }

     )
  }


  showbackdrop: Boolean = true;
  selectedIndex: number = -1;

  selectCard(index: number) {
    this.selectedIndex = index;
  }
  
  addBGImageOnCanvas(data: string) {
    console.log("url", data);
    this.whiteboardcomp.addBGImageOnCanvas(data);
    this.whiteboardcomp.sendCustomBgToUndoRedoService(data);
  }

  closeShowBackdrop(){
    // this.showvote=false
    let closeshowoption=false;
 
    this.closeShowoption.emit(closeshowoption);
 
   }


}

