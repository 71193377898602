import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import * as fabric from "../../../../src/lib/fabric.min.js";
declare const fabric: any;
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActiveSelection, Canvas, PencilBrush } from "fabric/fabric-impl";
import { FileService } from "../../../../src/app/file.service";
import { ColorPickerService } from "ngx-color-picker";
import { Location } from '@angular/common';
import { GoogleClassroomService } from "src/app/gc-plugin/google-apis.service.js"; //changes - shreyas.s
import { UndoRedoService } from "src/app/undo-redo.service.js";
import { DialogService } from "src/app/dialog.service";
import { BrowserDetectionService } from "src/app/browser-detection.service.js";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { getTotalImagesCount, preprocessLessonData } from "src/app/shared/utils.js";
import { Page } from "src/app/lesson-files.js";
import { ThumbnailReplacerService } from "src/app/thumbnail-replacer.service.js";
@Component({
  selector: "editorcanvas",
  templateUrl: "./editorcanvas.component.html",
  styleUrls: ["./editorcanvas.component.css"],
})
export class EditorcanvasComponent implements AfterViewInit {
  multimediaObjArr : Array<String> = ["windowYoutube", "windowVideoMyLibrary", "window3D", "windowDocMyLibrary", "windowAudioMyLibrary", "windowDoc"]
  multiObjsToRem : any = [];
  delCheck : boolean = false;
  //[x: string]: any;
  @ViewChild("htmlCanvas") htmlCanvas: ElementRef;
  @ViewChild('contextBox', { static: false }) contextBox: ElementRef;
  @ViewChild('dropdownContainer', { static: true }) dropdownContainer: ElementRef;

  @ViewChild("canvasContainer", { static: true }) canvasElement: ElementRef;
  @Output() closeDrawtoolBox=new EventEmitter<any>()
  @Output() closeToolBox = new EventEmitter<any>()
  @Output() deleteRuler=new EventEmitter<any>()
  @Output() deleteProtractor = new EventEmitter<any>()
  @Output() deleteSetsquare = new EventEmitter<any>()
  @Output() deleteCompass = new EventEmitter<any>()
  @Output() closeEraseBox = new EventEmitter<any>()
  @ViewChild('contextmenu',{static:false}) contextmenu:ElementRef;
  @ViewChild('colorpicker',{static:false}) colorpicker:ElementRef;


  defaulthighlighter:string="yellow";
  defaulthighlighteractive:Boolean=true;

  @Output() addFloatingWindowEvent = new EventEmitter<any>(); //change shreyas.s
  @Output() documentWindowLoadingEvent = new EventEmitter<any>(); //change - shreyas.s
  @Output() closeselecttap = new EventEmitter<any>();
  @Output() closesubmenu = new EventEmitter<any>()
  @Output() callapplyBgColor = new EventEmitter<any>();
  @Output() calltoggleFrame = new EventEmitter<any>();
  @Output() toggletextactive = new EventEmitter<any>();
  @Output() passNavDisabledState = new EventEmitter<any>();
  @Output() undoStatusChanged = new EventEmitter<boolean>();
  @Output() redoStatusChanged = new EventEmitter<Boolean>();
  @Output() mediaDocMetaDataFromServer = new EventEmitter<{type:string, url:any}>();


  @ViewChild('colorPicker') colorPicker!: ElementRef;
  @ViewChild('TextcolorPicker') TextcolorPicker!: ElementRef;
  @ViewChild('textContextMenu',{static:false}) textContextMenu!: ElementRef;

  public position = 0;
  showSpinner: boolean=false;
  public canvas: fabric.Canvas;
  iconMoveUrl = "assets/icon_move_blue.svg";
  iconRotateUrl = "assets/icon_rotate.svg";
  iconPenUrl="assets/icon_pen.svg";
  toolClose="assets/icon_close_blue.svg";

  public props = {
    canvasFill: "#ffffff",
    canvasImage: "",
    id: null,
    opacity: null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: "",
    width: null,
    stroke: null,
  };

  public textString: string;
  public url: string | ArrayBuffer = "";
  public size: any = {
    width: 1700,
    height: 820,
  };

  public json: any;
  private globalEditor = false;
  public textEditor = false;
  public lineeditor = false;
  private imageEditor = false;
  public figureEditor = false;
  public selected: any;
  colcolcol: any;
  highlighterColor: any;
  selectAreaBool:boolean = false
  deletedObj;
  isPlaying: boolean = false;
  webbbbbbselected: boolean = false;
  youtubeselected: boolean = false;
  videodivselected: boolean = false;
  audiodivselected: boolean = false;
  threedydivselected: boolean = false;
  textBoxSelect: boolean = false;
  textbox: fabric.IText;
  previousActObj;
  thumbnails: string[] = [];

  bgColor: any;
  bgFrame: any;
  customBg: any;
  compassSelected : boolean = false
  editObjectType = {
    'sendBack':false,
    'bringFront':false,
    'undo':false,
    'redo':false,
    'select':false,
    'horizontalFlip':false,
    'verticalFlip':false
  }
  shareType: string;

  private videos: { videoObj: fabric.Image; videoEl: HTMLVideoElement }[] = [];

  ytvid: SafeResourceUrl;
  weburl: SafeResourceUrl;
  mainvideourl: SafeResourceUrl;
  mainaudurl: SafeResourceUrl;
  main3dimage: SafeResourceUrl;

  currentPosition: number;

  //---compass----

  groupleft: any;
  groupright: any;
  info: any;
  iconPenElement: any;
  iconPenImage: any;
  compassInserted: boolean = false;
  showAngleInputDialog: boolean = false;
  // degreeInput: number = 40;
  buttonLeft: number = 110;
  buttonTop: number = 50;
  buttonRotate: number = 0;
  // private setAngle: number = 40;
  radius:number = 203;
  displayRadius: number = 30;
  totalRotationAngle = 0;
  circleCenterPoint: any;
  // centerPoint: any;
  saveClicked: boolean = false;
  compassInsertedInfo: boolean = false;


  //---compass----


  //---ruler---

  imgElement: any;
  rulerImage: any;
  point1: any;
  point2: any;
  point: any;
  m: any;
  c: any;
  isDown: any;
  rulerLine: any;
  isDisabled: boolean = true;
  m2: any;
  c2: any;

  tracePath: any;
  arc: any;
  drawArc: boolean = true;
  showArcOptions: boolean = false;
  selectedOptioncompass: boolean = false;
  arcThickness: number = 4;
  arcColor: string = '#ff0000';
  //---ruler---

  //--protractor---

  protractorElement: any;
  protractorImage: any;

  //--protractor---

  //---setSquare---
  setsquareElement: any;
  setSquareImage: any;
  lor: any;
  set: any;
  line2: any;
  circle2: any;
  isDown2: any;
  canvasHistory = [];
  selectedCanvasInd: any;
  //---setSquare---

  //---contextmenu---

  selectedObject: fabric.Object | null = null;
  contextBoxVisible: boolean = false;
  contextBoxPosition: { top: number; left: number } = { top: 0, left: 0 };
  canvasData: any;
  chartActive: Boolean = false;
  //---contextmenu---

  //---textContextBox--
  textContextVisible: boolean = false;
  textContextPosition: { top: number; left: number } = { top: 0, left: 0 };
  isTextSelected: boolean = false;
  isBoldActive: boolean = false;
  isItalicActive: boolean = false;
  isLeftAlignActive: boolean = false;
  isCenterAlignActive: boolean = false;
  isRightAlignActive: boolean = false;
  isJustifyAlignActive: boolean = false;
  isBulletsActive: boolean = false;
  // Font size related properties
  fontSizes: number[] = Array.from({ length: 48 }, (_, i) => i + 3); // Font sizes from 3 to 50
  selectedFontSize: number = 10;
  dropdownOpen: boolean = false;
  openUpwards: boolean = false;
  dropdownHeight: number = 200;
  openUpwardsFont: boolean = false;
  dropdownOpenFont: boolean = false;
  SelectedColorpallete:any;
  // Font type related properties
  fontTypes: string[] = [
    "Arial",
    "Courier New",
    "Pacifico",
    // "Georgia",
    "Times New Roman",
    // "Fresco",
    "Lato",
    "Indie Flower",
    "Josefin Sans",
    "Zilla Slab",
    "Raleway",
    "Bellefair",
    "Inconsolata",
    "Gloria Hallelujah",
  ];
  selectedFontType: string = "Arial";

  // Text color related properties
  selectedTextColor: string = "#000000";
  //---textContextBox--

  //----ColorPicker----
  colorPickerPosition: { top: number; left: number } = { top: 0, left: 0 };
  colorPickerVisible: boolean = false;

  @Output() closecanvaschart: EventEmitter<string> = new EventEmitter<string>();
  @Input() chartdataurl: any
  @Input() Totalvotes: any[] = [];
  latest_object_action;
  DuplObj: boolean = false;
  latestCanvas: fabric.Canvas;
  EraseCanvas = {
    onTouch: false,
    eraseAll: false,
    context: false,
    select: false,
    point:false,
    select_area:false
  }
  executeBool: boolean=false;
  internetConnected: boolean=true;
  compassFullCircle: boolean = false;
  bgColorApplied: boolean = false;
  bgFrameApplied : boolean = false;
  bgCustomimgApplied : boolean = false;
  circleCreated: boolean;
  CompassHalfCircle: boolean = false;
  //subscriptions.
  private listenCanvasOperationSubscription:Subscription;

  constructor(private sanitizer: DomSanitizer, public fileservice: FileService,
    public elementRef: ElementRef, private colorPickerService: ColorPickerService,
    private googleClassroomService: GoogleClassroomService, //change - shreyas.s
    private UndoRedoService: UndoRedoService,
    private ThumbnailReplacerService: ThumbnailReplacerService,
    private dialogService: DialogService,
    private browserDetectionService: BrowserDetectionService,
  ) {
  }

  ngAfterViewInit(): void {
    this.colcolcol = "#000000";
    this.strokethicknes = 3;
    // this.saveCache()
    // setup front side canvas
    this.canvas = new fabric.Canvas(this.htmlCanvas.nativeElement, {
      hoverCursor: "pointer",
      selection: true,
      selectionBorderColor: "blue",
      backgroundColor: this.bgColor,
      //for compass part
      preserveObjectStacking: true,
      isDrawingMode: false,
      isTouchSupported: true,
      // gestures: ['drag', 'zoom', 'rotate'],
      // controlsAboveOverlay: true,
      // allowTouchScrolling: true,
    });
    this.UndoRedoService.init(this.canvas);
    this.ThumbnailReplacerService.init(this.canvas);
    this.onResize("");


    //-----------------TOOLS PART___________________
    // Assuming you have a reference to the canvas 'element'
    var canvas = document.getElementById("canvasContainer");

    // Add a click event listener to the canvas
    canvas.addEventListener("touchstart", (event) => {
      // this.canvas.renderAll()
      this.webbbbbbselected = false;
      // console.log("event1 ",event)
      // Check if the clicked element is the div with ID "mydiv" or its child elements
      var myDiv = (event.target as Element).closest("#webdiv");
      const dd = document.getElementById("webdiv") as HTMLElement;
      // this.initializeCanvasEvents();

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.webbbbbbselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        // The click occurred on or within the "mydiv" element
        // Do something with the selected div
        // ...
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
    });


    canvas.addEventListener("mousedown", (event) => {
      this.webbbbbbselected = false;
      // console.log("event1 ",event)
      console.log("event1 ", event);

      // Check if the clicked element is the div with ID "mydiv" or its child elements
      var myDiv = (event.target as Element).closest("#webdiv");
      const dd = document.getElementById("webdiv") as HTMLElement;

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.webbbbbbselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        // The click occurred on or within the "mydiv" element
        // Do something with the selected div
        // ...
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
      this.closeToolBox.emit(false);
      this.closeEraseBox.emit(false);
      if(this.canvas.isDrawingMode === true) {
        this.closeDrawtoolBox.emit(false);
        this.closeEraseBox.emit(false);
      }
      if(this.canvas.isDrawingMode === false) {
        this.closeselecttap.emit(false);
      }

      this.closesubmenu.emit(false);
    });

    canvas.addEventListener("click", (event: any) => {
        setTimeout(async () => {
          this.erasePointObj();
        },10)
    });


    canvas.addEventListener("touchstart", (event) => {
      this.youtubeselected = false;
      // console.log("event1 ",event)
      // Check if the clicked element is the div with ID "mydiv" or its child elements
      var myDiv = (event.target as Element).closest("#mydiv");
      const dd = document.getElementById("mydiv") as HTMLElement;

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.youtubeselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        // The click occurred on or within the "mydiv" element
        // Do something with the selected div
        // ...
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
      this.closeToolBox.emit(false);
      this.closeEraseBox.emit(false);
      if(this.canvas.isDrawingMode === true) {
        this.closeDrawtoolBox.emit(false);
        this.closeEraseBox.emit(false);
      }
      if(this.canvas.isDrawingMode === false) {
        this.closeselecttap.emit(false);
      }
      this.closesubmenu.emit(false);
    });

    canvas.addEventListener("mousedown", (event) => {
      this.youtubeselected = false;
      // console.log("event1 ",event)
      // Check if the clicked element is the div with ID "mydiv" or its child elements
      var myDiv = (event.target as Element).closest("#mydiv");
      const dd = document.getElementById("mydiv") as HTMLElement;

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.youtubeselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        // The click occurred on or within the "mydiv" element
        // Do something with the selected div
        // ...
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
    });

    canvas.addEventListener("touchstart", (event) => {
      this.videodivselected = false;
      // console.log("event1 ",event)
      // Check if the clicked element is the div with ID "mydiv" or its child elements
      var myDiv = (event.target as Element).closest("#videodiv");
      const dd = document.getElementById("videodiv") as HTMLElement;

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.videodivselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        // The click occurred on or within the "mydiv" element
        // Do something with the selected div
        // ...
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
    });

    canvas.addEventListener("mousedown", (event) => {
      this.videodivselected = false;
      // console.log("event1 ",event)
      // Check if the clicked element is the div with ID "mydiv" or its child elements
      var myDiv = (event.target as Element).closest("#videodiv");
      const dd = document.getElementById("videodiv") as HTMLElement;

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.videodivselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        // The click occurred on or within the "mydiv" element
        // Do something with the selected div
        // ...
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
    });

    canvas.addEventListener("touchstart", (event) => {
      this.audiodivselected = false;
      // console.log("event1 ",event)
      // Check if the clicked element is the div with ID "mydiv" or its child elements
      var myDiv = (event.target as Element).closest("#audiodiv");
      const dd = document.getElementById("audiodiv") as HTMLElement;

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.audiodivselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        // The click occurred on or within the "mydiv" element
        // Do something with the selected div
        // ...
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
    });

    canvas.addEventListener("mousedown", (event) => {
      this.audiodivselected = false;
      // console.log("event1 ",event)
      // Check if the clicked element is the div with ID "mydiv" or its child elements
      var myDiv = (event.target as Element).closest("#audiodiv");
      const dd = document.getElementById("audiodiv") as HTMLElement;

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.audiodivselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        // The click occurred on or within the "mydiv" element
        // Do something with the selected div
        // ...
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
    });

    canvas.addEventListener("touchstart", (event) => {
      this.threedydivselected = false;
      // console.log("event1 ",event)

      var myDiv = (event.target as Element).closest("#threedydiv");
      const dd = document.getElementById("threedydiv") as HTMLElement;

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.threedydivselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
    });

    canvas.addEventListener("mousedown", (event) => {
      this.threedydivselected = false;
      // console.log("event1 ",event)

      var myDiv = (event.target as Element).closest("#threedydiv");
      const dd = document.getElementById("threedydiv") as HTMLElement;

      if (myDiv) {
        console.log("div element selected");
        dd.style.border = "5px solid orange";
        dd.style.zIndex = "10";
        this.threedydivselected = true;
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
      } else {
        dd.style.border = "1px solid black";
        dd.style.zIndex = "1";
      }
    });

    canvas.addEventListener("mousedown", (event) => {
      console.log("textevent", this.textbox);
      if (this.textbox) {
        if (!this.textbox.text.trim()) {
          this.textBoxSelect = true;
          this.canvas.remove(this.textbox);
        }
      }
      if (this.canvas.isDrawingMode) {
        this.textBoxSelect = false;
      }
      if (this.textBoxSelect) {
        this.textBoxSelect = false;
        // this.selectedFontSize = 10;
        console.log("inside if ", this.textBoxSelect);
        this.textbox = new fabric.IText("", {
          left: event.offsetX - 10,
          top: event.offsetY - 10,
          // fontSize: this.selectedFontSize,
          fontFamily: "Arial",
          angle: 0,
          fill: this.colcolcol,
          scaleX: 0.5,
          editable: true,
          scaleY: 0.5,
          fontWeight: "",
          hasRotatingPoint: false,
          //borderColor: '#A52A2A',
          cornerSize: 7,
          padding: 6,
          transparentCorners: false,
          cornerColor: "white",
          cornerStrokeColor: "#109CF1",
          borderDashArray: [3, 5],
          borderColor: "#109CF1",
          name: "IText",
          isEditing:false,
        });
        this.textbox.setControlsVisibility({
          tl: false,
          mt: false,
          tr: false,
          ml: false,
          mr: false,
          bl: false,
          mb: false,
          br: false,
          mtr: false,
        });
        // this.extend(this.textbox, this.randomId());
        // Add the textbox to the canvas
        this.canvas.add(this.textbox);
        this.canvas.discardActiveObject().renderAll();
        //this.canvas.setActiveObject(this.textbox);
        //this.selectItemAfterAdded(this.textbox);
        this.textbox.enterEditing(); // Trigger editing mode
        this.canvas.setActiveObject(this.textbox);
        this.updateTextContextStates();
        if (this.textbox) {
          this.textbox.on('editing:exited', (event) => {
            console.log('editing exited log mousedown', event)
            this.handleTextMode(false);
           });
        }
        // this.textbox.on('editing:exited', () => {
        //   this.handleTextMode(false);
        //   if(this.textbox){
        //   if (!this.textbox.text.trim()) {
        //    this.canvas.remove(this.textbox);
        //   }
        // }
        //  });
      }
    });

    canvas.addEventListener("touchstart", (event) => {
      // this.initializeCanvasEvents();
      if (this.textbox) {
        if (!this.textbox.text.trim()) {
          this.textBoxSelect = true;
          this.canvas.remove(this.textbox);
        }
      }
      if (this.canvas.isDrawingMode) {
        this.textBoxSelect = false;
      }
      // touchstart
      if (this.textBoxSelect) {
        this.textBoxSelect = false;
        // this.selectedFontSize = 10;
        const touch = event.touches[0];
        const element = this.elementRef.nativeElement;
        const rect = element.getBoundingClientRect();
        this.textbox = new fabric.IText("", {
          left: touch.clientX - rect.left - 10,
          top: touch.clientY - rect.top - 10,
          fontFamily: "Arial",
          angle: 0,
          fill: this.colcolcol,
          // centeredScaling: false,
          scaleX: 0.5,
          editable: true,
          scaleY: 0.5,
          fontWeight: "",
          hasRotatingPoint: false,
          //borderColor: '#A52A2A',
          cornerSize: 7,
          padding: 6,
          transparentCorners: false,
          cornerColor: "white",
          cornerStrokeColor: "#109CF1",
          borderDashArray: [3, 5],
          borderColor: "#109CF1",
          name: "IText",
          isEditing:false,
        });
        this.textbox.setControlsVisibility({
          tl: false,
          mt: false,
          tr: false,
          ml: false,
          mr: false,
          bl: false,
          mb: false,
          br: false,
          mtr: false,
        });
        this.extend(this.textbox, this.randomId());
        // Add the textbox to the canvas
        this.canvas.add(this.textbox);
        this.canvas.discardActiveObject().renderAll();

        this.textbox.enterEditing(); // Trigger editing mode
        this.canvas.setActiveObject(this.textbox);
        this.updateTextContextStates();
        if (this.textbox) {
          this.textbox.on('editing:exited', (event) => {
            console.log('editing exited log mousedown', event)
            this.handleTextMode(false);
           });
        }
        // this.textbox.on('editing:exited', () => {
        //   if(this.textbox){
        //     this.handleTextMode(false);
        //   if (!this.textbox.text.trim()) {
        //    this.canvas.remove(this.textbox);
        //   }
        // }
        //  });
      }
    });

    canvas.addEventListener('touchend', (event) => {
      // if(this.fileservice.currentWhiteboardMode != 'present'){
        this.fileservice.loadThumbnail.next("hide")
        setTimeout(async () => {
          this.erasePointObj();
        },10)
      // }

    })
    //youtube videos iframe drag and resize
    var draggableDiv = document.getElementById("mydiv");
    var draggableDivHeader = document.getElementById("mydivheader");
    var canvas = document.getElementById("canvas");
    var canvasContainer = document.getElementById("canvasContainer");
    var isDragging = false;
    var initialX, initialY;

    draggableDivHeader.addEventListener("mousedown", startDragging, {
      passive: false,
    });
    draggableDivHeader.addEventListener("touchstart", startDragging, {
      passive: false,
    });

    document.addEventListener("mouseup", stopDragging);
    document.addEventListener("touchend", stopDragging);

    document.addEventListener("mousemove", dragDiv, { passive: false });

    function startDragging(event) {
      event.preventDefault();
      isDragging = true;

      if (event.type === "mousedown") {
        initialX = event.clientX - draggableDiv.getBoundingClientRect().left;
        initialY = event.clientY - draggableDiv.getBoundingClientRect().top;
      } else if (event.type === "touchstart") {
        console.log("touch evet", event);
        var touch = event.touches[0];
        initialX = touch.clientX - draggableDiv.getBoundingClientRect().left;
        initialY = touch.clientY - draggableDiv.getBoundingClientRect().top;
      }
    }

    function stopDragging() {
      isDragging = false;
    }

    function dragDiv(event) {
      if (isDragging) {
        event.preventDefault();

        if (event.type === "mousemove") {
          var x = event.clientX - initialX;
          var y = event.clientY - initialY;
          draggableDiv.style.left = x + "px";
          draggableDiv.style.top = y + "px";
        } else if (event.type === "touchmove") {
          console.log("touch move ", event);
          var touch = event.touches[0];
          var x = touch.clientX - initialX;
          var y = touch.clientY - initialY;
          draggableDiv.style.left = x + "px";
          draggableDiv.style.top = y + "px";
        }
      }
    }

    //web page iframe drag and resize
    var draggablewebDiv = document.getElementById("webdiv");
    var draggablewebDivHeader = document.getElementById("webmydivheader");
    var canvas = document.getElementById("canvas");
    var canvasContainer = document.getElementById("canvasContainer");
    var issDragging = false;
    var initialXX, initialYY;

    draggablewebDivHeader.addEventListener("mousedown", startDragging1, {
      passive: false,
    });
    draggablewebDivHeader.addEventListener("touchstart", startDragging1, {
      passive: false,
    });

    document.addEventListener("mouseup", stopDragging1);
    document.addEventListener("touchend", stopDragging1);

    document.addEventListener("mousemove", dragDiv1, { passive: false });

    function startDragging1(event) {
      console.log("start dragging for webpage");
      event.preventDefault();
      issDragging = true;

      if (event.type === "mousedown") {
        initialXX =
          event.clientX - draggablewebDiv.getBoundingClientRect().left;
        initialYY = event.clientY - draggablewebDiv.getBoundingClientRect().top;
      } else if (event.type === "touchstart") {
        console.log("touch evet", event);
        var touch = event.touches[0];
        initialXX =
          touch.clientX - draggablewebDiv.getBoundingClientRect().left;
        initialYY = touch.clientY - draggablewebDiv.getBoundingClientRect().top;
      }
    }

    function stopDragging1() {
      issDragging = false;
    }

    function dragDiv1(event) {
      if (issDragging) {
        event.preventDefault();

        if (event.type === "mousemove") {
          var x = event.clientX - initialXX;
          var y = event.clientY - initialYY;
          draggablewebDiv.style.left = x + "px";
          draggablewebDiv.style.top = y + "px";
        } else if (event.type === "touchmove") {
          console.log("touch move ", event);
          var touch = event.touches[0];
          var x = touch.clientX - initialXX;
          var y = touch.clientY - initialYY;
          draggablewebDiv.style.left = x + "px";
          draggablewebDiv.style.top = y + "px";
        }
      }
    }

    //videos iframe drag and resize
    var draggablevidDiv = document.getElementById("videodiv");
    var draggablevidDivHeader = document.getElementById("videodivheader");

    var issvidDragging = false;
    var initialXXvid, initialYYvid;

    draggablevidDivHeader.addEventListener("mousedown", startDragging2, {
      passive: false,
    });
    draggablevidDivHeader.addEventListener("touchstart", startDragging2, {
      passive: false,
    });

    document.addEventListener("mouseup", stopDragging2);
    document.addEventListener("touchend", stopDragging2);


    document.addEventListener("mousemove", dragDiv2, { passive: false });

    function startDragging2(event) {
      console.log("start dragging for video");
      event.preventDefault();
      issvidDragging = true;

      if (event.type === "mousedown") {
        initialXXvid =
          event.clientX - draggablevidDiv.getBoundingClientRect().left;
        initialYYvid =
          event.clientY - draggablevidDiv.getBoundingClientRect().top;
      } else if (event.type === "touchstart") {
        console.log("touch evet", event);
        var touch = event.touches[0];
        initialXXvid =
          touch.clientX - draggablevidDiv.getBoundingClientRect().left;
        initialYYvid =
          touch.clientY - draggablevidDiv.getBoundingClientRect().top;
      }
    }

    function stopDragging2() {
      issvidDragging = false;
    }

    function dragDiv2(event) {
      if (issvidDragging) {
        event.preventDefault();

        if (event.type === "mousemove") {
          var x = event.clientX - initialXXvid;
          var y = event.clientY - initialYYvid;
          draggablevidDiv.style.left = x + "px";
          draggablevidDiv.style.top = y + "px";
        } else if (event.type === "touchmove") {
          console.log("touch move ", event);
          var touch = event.touches[0];
          var x = touch.clientX - initialXXvid;
          var y = touch.clientY - initialYYvid;
          draggablevidDiv.style.left = x + "px";
          draggablevidDiv.style.top = y + "px";
        }
      }
    }

    //audios iframe drag and resize
    var draggableaudDiv = document.getElementById("audiodiv");
    var draggableaudDivHeader = document.getElementById("audiodivheader");

    var issaudDragging = false;
    var initialXXaud, initialYYaud;

    draggableaudDivHeader.addEventListener("mousedown", startDraggingaud, {
      passive: false,
    });
    draggableaudDivHeader.addEventListener("touchstart", startDraggingaud, {
      passive: false,
    });

    document.addEventListener("mouseup", stopDraggingaud);
    document.addEventListener("touchend", stopDraggingaud);

    document.addEventListener("mousemove", dragDivaud, { passive: false });
    document.addEventListener("touchmove", dragDivaud, { passive: false });

    function startDraggingaud(event) {
      console.log("start dragging for audio");
      event.preventDefault();
      issaudDragging = true;

      if (event.type === "mousedown") {
        initialXXaud =
          event.clientX - draggableaudDiv.getBoundingClientRect().left;
        initialYYaud =
          event.clientY - draggableaudDiv.getBoundingClientRect().top;
      } else if (event.type === "touchstart") {
        console.log("touch evet", event);
        var touch = event.touches[0];
        initialXXaud =
          touch.clientX - draggableaudDiv.getBoundingClientRect().left;
        initialYYaud =
          touch.clientY - draggableaudDiv.getBoundingClientRect().top;
      }
    }

    function stopDraggingaud() {
      issaudDragging = false;
    }

    function dragDivaud(event) {
      if (issaudDragging) {
        event.preventDefault();

        if (event.type === "mousemove") {
          var x = event.clientX - initialXXaud;
          var y = event.clientY - initialYYaud;
          draggableaudDiv.style.left = x + "px";
          draggableaudDiv.style.top = y + "px";
        } else if (event.type === "touchmove") {
          console.log("touch move ", event);
          var touch = event.touches[0];
          var x = touch.clientX - initialXXaud;
          var y = touch.clientY - initialYYaud;
          draggableaudDiv.style.left = x + "px";
          draggableaudDiv.style.top = y + "px";
        }
      }
    }

    //3dimage iframe drag and resize
    var draggable3dDiv = document.getElementById("threedydiv");
    var draggable3dDivHeader = document.getElementById("threedydivheader");

    var iss3dDragging = false;
    var initialXX3d, initialYY3d;

    draggable3dDivHeader.addEventListener("mousedown", startDragging3, {
      passive: false,
    });
    draggable3dDivHeader.addEventListener("touchstart", startDragging3, {
      passive: false,
    });

    document.addEventListener("mouseup", stopDragging3);
    document.addEventListener("touchend", stopDragging3);


    document.addEventListener("mousemove", dragDiv3, { passive: false });

    function startDragging3(event) {
      event.preventDefault();
      iss3dDragging = true;

      if (event.type === "mousedown") {
        initialXX3d =
          event.clientX - draggable3dDiv.getBoundingClientRect().left;
        initialYY3d =
          event.clientY - draggable3dDiv.getBoundingClientRect().top;
      } else if (event.type === "touchstart") {
        console.log("touch evet", event);
        var touch = event.touches[0];
        initialXX3d =
          touch.clientX - draggable3dDiv.getBoundingClientRect().left;
        initialYY3d =
          touch.clientY - draggable3dDiv.getBoundingClientRect().top;
      }
    }

    function stopDragging3() {
      iss3dDragging = false;
    }

    function dragDiv3(event) {
      if (iss3dDragging) {
        event.preventDefault();

        if (event.type === "mousemove") {
          var x = event.clientX - initialXX3d;
          var y = event.clientY - initialYY3d;
          draggable3dDiv.style.left = x + "px";
          draggable3dDiv.style.top = y + "px";
        } else if (event.type === "touchmove") {
          console.log("touch move ", event);
          var touch = event.touches[0];
          var x = touch.clientX - initialXX3d;
          var y = touch.clientY - initialYY3d;
          draggable3dDiv.style.left = x + "px";
          draggable3dDiv.style.top = y + "px";
        }
      }
    }
    this.setupCustomControlForObjects();

    // this.loadCanvasState();
    // this.bindCanvasEvents();

      // Add event listener for object added
    //  this.selectPenCil();
      this.listenCanvasOperationSubscription = this.fileservice.listenCanvasOperationFromServer().subscribe(async (val:any)=>{
        console.log("Canvas Operation:",val,localStorage.getItem('mainlessonID'),localStorage.getItem('currentPage'))
        if (val.mainLessonId == localStorage.getItem('mainlessonID') && val.pageNo == localStorage.getItem('currentPage')){
            await this.processCanvasOperationFromServer(val.object,val.action_type,val.sender_canvasWidth,val.sender_canvasHeight)
        }
        // this.mycanvas.canvas.renderAll()
       });
  }

  ngOnDestroy() {
    this.listenCanvasOperationSubscription.unsubscribe();
  }
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    if (this.dropdownOpen) {
      this.dropdownOpenFont = false;
      this.adjustDropdownDirection();
    }
  }

  toggleFontDropdown() {
    this.dropdownOpenFont = !this.dropdownOpenFont;
    if (this.dropdownOpenFont) {
      this.dropdownOpen = false;
      this.adjustFontDropdownDirection();
    }
  }

  selectFontSize(size: number) {
    this.selectedFontSize = size;
    this.dropdownOpen = false;
    this.changeFontSize();
  }

  selectFontFamily(fontType: string) {
    this.selectedFontType = fontType;
    this.dropdownOpenFont = false;
    this.changeFontType();
  }

  adjustDropdownDirection() {
    setTimeout(() => {
      const dropdown = document.querySelector('.custom-dropdown ul') as HTMLElement;
      if (dropdown) {
        const activeObject = this.canvas.getActiveObject();
        const rect = dropdown.getBoundingClientRect();
        const bottomSpace = window.innerHeight - rect.bottom;
        // const topSpace = rect.top;
        const topSpace = activeObject.top - 200;

        // Fixing dropdown height issue
        const dropdownHeight = Math.min(dropdown.scrollHeight, 200); // Ensure it doesn't exceed max height

        console.log('bottomSpace log', bottomSpace);
        console.log('topSpace log', topSpace);
        console.log('dropdown.scrollHeight log', dropdown.scrollHeight);
        console.log('adjusted dropdown height log', dropdownHeight);

        if (topSpace < dropdownHeight) {
          console.log('Opening down log');
          this.openUpwards = false;
        } else {
          console.log('Opening up log');
          this.openUpwards = true;
        }
      }
    }, 0);
  }

  adjustFontDropdownDirection() {
    setTimeout(() => {
      const dropdown = document.querySelector('.custom-dropdown-font ul') as HTMLElement;
      if (dropdown) {
        const activeObject = this.canvas.getActiveObject();
        const rect = dropdown.getBoundingClientRect();
        const bottomSpace = window.innerHeight - rect.bottom;
        // const topSpace = rect.top;
        const topSpace = activeObject.top - 200;

        // Fixing dropdown height issue
        const dropdownHeight = Math.min(dropdown.scrollHeight, 200); // Ensure it doesn't exceed max height

        console.log('bottomSpace log', bottomSpace);
        console.log('topSpace log', topSpace);
        console.log('dropdown.scrollHeight log', dropdown.scrollHeight);
        console.log('adjusted dropdown height log', dropdownHeight);

        if (topSpace < dropdownHeight) {
          console.log('Opening down log');
          this.openUpwards = false;
        } else {
          console.log('Opening up log');
          this.openUpwards = true;
        }
      }
    }, 0);
  }

  async changeFontSize() {
    this.dropdownOpenFont = false;
    const scaleFactor = 4; // Adjust the scale factor as needed
    const activeObject = this.canvas.getActiveObject();

    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      const scaledFontSize = this.selectedFontSize * scaleFactor;

      activeObject.set({ fontSize: scaledFontSize });
      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });
    }
  }

  async changeFontType() {
    const scaleFactor = 4;
    this.dropdownOpen = false;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      await (document as any).fonts.load(`1em ${this.selectedFontType}`);
      activeObject.set({ fontFamily: this.selectedFontType });
      const scaledFontSize = this.selectedFontSize * scaleFactor;

      activeObject.set({ fontSize: scaledFontSize });
      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });

    }
  }

  // updateTextContextStates() {
  //   const activeObject = this.canvas.getActiveObject();
  //   if (activeObject && activeObject.name === "IText") {
  //     this.isBoldActive = activeObject.getFontWeight() === "bold";
  //     this.isItalicActive = activeObject.getFontStyle() === "italic";
  //     this.isLeftAlignActive = activeObject.textAlign === "left";
  //     this.isCenterAlignActive = activeObject.textAlign === "center";
  //     this.isRightAlignActive = activeObject.textAlign === "right";
  //     this.isJustifyAlignActive = activeObject.textAlign === "justify";
  //     this.isBulletsActive = activeObject.text.includes("•");
  //   }
  // }
  updateTextContextStates() {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      console.log("texteditior sameer 1")
      this.isTextSelected = true;
      this.isBoldActive = activeObject.fontWeight === "bold";
      this.isItalicActive = activeObject.fontStyle === "italic";
      this.isLeftAlignActive = activeObject.textAlign === "left";
      this.isCenterAlignActive = activeObject.textAlign === "center";
      this.isRightAlignActive = activeObject.textAlign === "right";
      this.isJustifyAlignActive = activeObject.textAlign === "justify";
      this.isBulletsActive = activeObject.text.includes("•");
      this.selectedFontSize = activeObject.fontSize / 4; // Adjust according to scale factor
      this.selectedFontType = activeObject.fontFamily;
    }
  }

  async toggleBold() {
    console.log("Bold working");
    this.dropdownOpen = false;
    this.dropdownOpenFont = false;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      console.log("Bold working inside");
      const currentFontWeight = activeObject.getFontWeight() || "normal";
      const newFontWeight = currentFontWeight === "bold" ? "normal" : "bold";
      activeObject.setFontWeight(newFontWeight);
      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });
      this.updateTextContextStates();
    }
  }

  async toggleItalic() {
    console.log("Italic working");
    this.dropdownOpen = false;
    this.dropdownOpenFont = false;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      console.log("Italic working inside");
      const currentFontStyle = activeObject.getFontStyle() || "normal";
      const newFontStyle = currentFontStyle === "italic" ? "normal" : "italic";
      activeObject.setFontStyle(newFontStyle);
      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });
      this.updateTextContextStates();
    }
  }

  async leftAlign() {
    this.dropdownOpen = false;
    this.dropdownOpenFont = false;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      activeObject.set({ textAlign: "left" });

      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });
      this.updateTextContextStates();
    }
  }

  async rightAlign() {
    this.dropdownOpen = false;
    this.dropdownOpenFont = false;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      activeObject.set({ textAlign: "right" });

      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });
      this.updateTextContextStates();

    }
  }

  async centerAlign() {
    this.dropdownOpen = false;
    this.dropdownOpenFont = false;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      activeObject.set({ textAlign: "center" });

      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });
      this.updateTextContextStates();
    }
  }

  async justifyAlign() {
    this.dropdownOpen = false;
    this.dropdownOpenFont = false;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      activeObject.set({ textAlign: "justify" });

      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });
      this.updateTextContextStates();
    }
  }

  async formatList() {
    this.dropdownOpen = false;
    this.dropdownOpenFont = false;
    console.log("Format List working");

    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      console.log("Format List working inside");
      const currentText = activeObject.text;
      const bulletPresent = currentText.includes("•");
      const newText = bulletPresent
        ? this.removeBulletPoints(currentText)
        : this.addBulletPoints(currentText);
      activeObject.set({ text: newText });
      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });
      this.updateTextContextStates();
    }
  }
  addBulletPoints(text: string): string {
    const lines = text.split("\n");
    return lines.map((line) => `• ${line}`).join("\n");
  }

  removeBulletPoints(text: string): string {
    return text.replace(/• /g, "");
  }

  async changeTextColor() {
    this.dropdownOpen = false;
    this.dropdownOpenFont = false;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
      activeObject.set({ fill: this.selectedTextColor });
      this.canvas.renderAll();
      this.canvas.fire('object:modified', { target: activeObject });
    }
  }
  addcolorpicker:Boolean=false;
  // opencolorpicker() {
  //   this.addcolorpicker=!this.addcolorpicker
  //   if(this.addcolorpicker){
  //   const colorPicker = document.getElementById(
  //     "colorPicker"
  //   ) as HTMLInputElement;
  //   colorPicker.click();
  //   }
  // }


  opencolorpicker() {
    this.addcolorpicker = !this.addcolorpicker;

    if (this.addcolorpicker) {
      const colorPicker = document.getElementById("colorPicker") as HTMLInputElement;
      colorPicker.click();

      setTimeout(() => {
        // Get color picker dimensions and position
        const colorPickerContainer = document.getElementById("colorpicker") as HTMLElement;
        if (colorPickerContainer) {
          const dimensions = colorPickerContainer.getBoundingClientRect();
          const colorPickerWidth = dimensions.width; // Width of color picker
          const colorPickerHeight = dimensions.height; // Height of color picker
          const colorPickerLeft = dimensions.left; // Current left position
          const screenWidth = window.innerWidth; // Screen width
          const screenHeight = window.innerHeight; // Screen height

          let colorPickerDimensions=colorPicker.getBoundingClientRect();
          let colorPickerDimensions_width=colorPickerDimensions.width;
          let colorPickerDimensions_height=colorPickerDimensions.height;
          let colorPickerDimensions_left=colorPickerDimensions.left;


          console.log('Color picker Width:', colorPickerWidth);
          console.log('Color picker Height:', colorPickerHeight);
          console.log('Color picker Left:', colorPickerLeft);
          console.log('Color picker Right (calculated):', colorPickerLeft + colorPickerWidth);
          console.log('Color_Picker Width:', colorPickerDimensions_width);
          console.log('Color_Picker Height:', colorPickerDimensions_height);
          console.log('Color_Picker Left:',  colorPickerDimensions_left);
          console.log('TOTAL WIDTH:', colorPickerWidth + colorPickerDimensions_width);


          // Check if the color picker overflows the screen (right side)
          if (colorPickerLeft + colorPickerWidth+210 > screenWidth&&((dimensions.top + colorPickerHeight+210 > screenHeight)===false)) {
            // Adjust left position to keep it within the screen
            let removalofleft=colorPickerWidth + colorPickerDimensions_width;
            //let removalofleft=colorPickerWidth + colorPickerDimensions_width;
           // const newLeftPosition = screenWidth-550; // 10px buffer
           const newLeftPosition = screenWidth-(removalofleft+100);
            this.colorPickerPosition.left = newLeftPosition; // Update Angular binding
            colorPickerContainer.style.left = `${newLeftPosition}px`; // Update DOM
            console.log('Adjusted Left Position (Right Overflow):', newLeftPosition);
          }

          // Optional: Handle vertical overflow (if required)
          console.log("dimensions.top",dimensions.top,colorPickerHeight,screenHeight)
          if (dimensions.top + colorPickerHeight+210 > screenHeight&&(colorPickerLeft + colorPickerWidth+210 > screenWidth===false)) {
            let removaloftop=dimensions.top + colorPickerHeight;
            const newTopPosition = screenHeight-(removaloftop-200) // 10px buffer
            this.colorPickerPosition.top = newTopPosition; // Update Angular binding
            colorPickerContainer.style.top = `${newTopPosition}px`; // Update DOM
            console.log('Adjusted Top Position (Bottom Overflow):', newTopPosition);
          }

          if (dimensions.top + colorPickerHeight+210 > screenHeight&&(colorPickerLeft + colorPickerWidth+210 > screenWidth)) {
            let removaloftop=dimensions.top + colorPickerHeight;
            const newTopPosition = screenHeight-(removaloftop-200) // 10px buffer
            this.colorPickerPosition.top = newTopPosition; // Update Angular binding
            colorPickerContainer.style.top = `${newTopPosition}px`; // Update DOM
            console.log('Adjusted Top Position (Bottom Overflow):', newTopPosition);
            let removalofleft=colorPickerWidth + colorPickerDimensions_width;
            //let removalofleft=colorPickerWidth + colorPickerDimensions_width;
           // const newLeftPosition = screenWidth-550; // 10px buffer
           const newLeftPosition = screenWidth-(removalofleft+200);
            this.colorPickerPosition.left = newLeftPosition; // Update Angular binding
            colorPickerContainer.style.left = `${newLeftPosition}px`; // Update DOM
            console.log('Adjusted Left Position (Right Overflow):', newLeftPosition);
          }


        }
      }, 0);
    }
  }



  selectedColors: string[] = [
    "#e41b1b",
    "#3f1ded",
    "#18e239",
    "#e6f764",
    "#dc18d6",
    "#2bdbf3",
    "#000000",
  ];
  customColor: string = "#000000";
  addColorTimer: any;
  // addColor() {
  //   clearTimeout(this.addColorTimer);
  //   this.addColorTimer = setTimeout(() => {
  //   this.selectedColors.push(this.customColor);
  //   console.log("colorsssssss", this.selectedColors);
  //   if (this.selectedColors.length > 7) {
  //     this.selectedColors.shift();
  //   }
  // }, 500);
  // }


  addColor() {
    clearTimeout(this.addColorTimer);
    this.addColorTimer = setTimeout(() => {
this.applyColorAndHighlight(-1,this.customColor)

  }, 500);
  }

  selectedColorIndex: number = -1;
  customcoloractive:Boolean=false
  applyColorAndHighlight(index: number,customcolor:any) {
    console.log("inside applyColorAndHighlight");
    const activeObject = this.canvas.getActiveObject();
    activeObject.oldState = JSON.parse(JSON.stringify(activeObject));
    this.customcoloractive=index===-1?true:false;
    if (activeObject && activeObject.selectable) {
      // this.selectedColorIndex = index;
      // const selectedColor = this.selectedColors[index];
      this.SelectedColorpallete=customcolor
      const selectedColor = customcolor;
      if (activeObject.type === 'group') {
        activeObject._objects.forEach((obj) => {
          //For arrow
          if (obj.type === 'line' || obj.type === 'triangle') {
            obj.set({ stroke: selectedColor, fill: selectedColor });
          }
          //For cylinder
          else if (obj.type === 'path' ) {
            obj.set({ stroke: selectedColor });
          }
        });
      }
      else if (activeObject && activeObject.type === "i-text") {
        console.log('active object is IText')
        activeObject.set({ fill: selectedColor});
      } else {
        //For the remaining shapes
        activeObject.set({ stroke: selectedColor });
      }
      this.canvas.renderAll();
      this.clearRemovedOperationsList()
      let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
      this.redoStatusChanged.emit(test2);
      this.canvas.fire('object:modified', { target: activeObject });
    }
  }


  // duplicateObject() {
  //   if (this.selectedObject) {
  //     const clonedObject = fabric.util.object.clone(this.selectedObject);
  //     clonedObject.left = (clonedObject.left ?? 0) + 30;
  //     clonedObject.top = (clonedObject.top ?? 0) + 30;
  //     this.canvas.add(clonedObject);
  //     this.DuplObj = true
  //     this.canvas.renderAll();
  //     this.clearRemovedOperationsList()
  //     // let objects = this.canvas.getObjects();
  //     // let totalLength = objects.length-1;
  //     // this.canvas.getObjects()[totalLength].id = Math.floor(100000 + Math.random() * 900000);
  //     // this.saveCache()
  //     // this.saveCanvasDataToSocket()
  //   }
  // }



  lastselecteddpObj
  mp:any
   duplicateObject() {
  if(this.selectedObject) {
    console.log('selected object duplicate', this.selectedObject)
         if(!this.lastselecteddpObj||this.lastselecteddpObj !== this.selectedObject.id){
         this.lastselecteddpObj=this.selectedObject.id;
          this.mp=1
         }
         if(this.lastselecteddpObj===this.selectedObject.id){
          this.mp=this.mp+7;
         }
         const offset = 5;
         if("type" in this.selectedObject) {
          if(this.selectedObject.type == 'image') {
            if(getTotalImagesCount(this.canvas) >= 10) {
              this.dialogService.showSimplePopup("Maximum limit reached.");
              return;
            }
          }
         }
         else if("name" in this.selectedObject) {
          if(this.selectedObject.name == 'image') {
            if(getTotalImagesCount(this.canvas) >= 10) {
              this.dialogService.showSimplePopup("Maximum limit reached.");
              return;
            }
          }
          }
           this.selectedObject.clone((clonedObject)=>{
            clonedObject.set({
             left:(this.selectedObject.left??0)+(offset + this.mp),
             top:(this.selectedObject.top??0)+(offset + this.mp),
             name: this.selectedObject.name
             });
             this.canvas.add(clonedObject);
            //  this.canvas.setActiveObject(clonedObject);

             this.selectedObject = clonedObject;
            //  if (clonedObject.type === 'i-text') {
            //    clonedObject.set({
            //      name: "IText"
            //    });
            //  }
            // this.updateTextContextStates();
            this.DuplObj=true;
            this.canvas.renderAll();
            this.clearRemovedOperationsList()
            let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
            this.redoStatusChanged.emit(test2);
            });
           }



   }

  flipHorizontal() {
    if (this.selectedObject) {
      this.selectedObject.oldState = JSON.parse(JSON.stringify(this.selectedObject));
      this.selectedObject.set("flipY", !this.selectedObject.flipY);
      this.canvas.renderAll();
      this.clearRemovedOperationsList()
      let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
      this.redoStatusChanged.emit(test2);
      this.canvas.fire('object:modified', { target: this.selectedObject });
    }
  }

  flipVertical() {
    if (this.selectedObject) {
      this.selectedObject.oldState = JSON.parse(JSON.stringify(this.selectedObject));
      this.selectedObject.set("flipX", !this.selectedObject.flipX);
      this.canvas.renderAll();
      this.clearRemovedOperationsList()
      let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
      this.redoStatusChanged.emit(test2);
      this.canvas.fire('object:modified', { target: this.selectedObject });
    }
  }

  sendToBack() {
    if (this.selectedObject) {
      this.selectedObject.oldState = JSON.parse(JSON.stringify(this.selectedObject));
      this.canvas.sendToBack(this.selectedObject);
      this.selectedObject.set({ SendBackObject: true });
      this.selectedObject.set({ BringFrontObject: false });
      this.canvas.renderAll();
      this.clearRemovedOperationsList()
      let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
      this.redoStatusChanged.emit(test2);
      this.canvas.fire('object:modified', { target: this.selectedObject });
      // if(this.avChatStarted){
      //   this.editObjectType.select = true
      //   this.editObjectType.sendBack = true
      //   // this.saveCanvasDataToSocket()
      // }
    }
  }

  bringToFront() {
    if (this.selectedObject) {
      this.selectedObject.oldState = JSON.parse(JSON.stringify(this.selectedObject));
      console.log(
        "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$",
        this.selectedObject,
        this.canvas
      );
      this.canvas.bringToFront(this.selectedObject);
      this.selectedObject.set({ BringFrontObject: true });
      this.selectedObject.set({ SendBackObject: false });
      this.canvas.renderAll();
      this.clearRemovedOperationsList()
      let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
      this.redoStatusChanged.emit(test2);
      this.canvas.fire('object:modified', { target: this.selectedObject });
      // if(this.avChatStarted){
      //   this.editObjectType.select = true
      //   this.editObjectType.bringFront = true
      //   // this.saveCanvasDataToSocket()
      // }
    }
  }



  /*------------------------Block elements------------------------*/

  // Block "Size"

  changeSize() {
    //this.canvas.setWidth(this.size.width);
    //this.canvas.setHeight(this.size.height);
  }

  // Block "Add text"

  addText() {
    console.log("inside addtext function", this.textString);
    if (this.textString) {
      console.log("inside if condition ", this.textString);
      const textbox = new fabric.IText(this.textString, {
        left: 10,
        top: 10,
        fontFamily: "helvetica",
        angle: 0,
        fill: "#000000",
        scaleX: 0.5,
        scaleY: 0.5,
        fontWeight: "",
        //editable:true,
        hasRotatingPoint: true,
      });

      this.extend(textbox, this.randomId());
      this.canvas.add(textbox);
      this.selectItemAfterAdded(textbox);
      this.textString = "";
    }
  }

  addEditText() {
    this.textBoxSelect = true;
    this.canvas.isDrawingMode = false;
    //this.addText()
  }


  exitEditText(){
    console.log("exitedit",this.textbox);
    if (this.textbox && this.textbox.text === "") {
      console.log('exiteditText remove textbox', this.textbox.text)
      this.canvas.remove(this.textbox);
    }
    if(this.textbox && this.textbox.isEditing)
    {
      this.textbox.exitEditing();
      this.textbox=null;
      this.textBoxSelect=false;
    }else{
      if(this.textbox){
        this.textbox.exitEditing();
      }
      this.textBoxSelect=false;
      //this.textbox.exitEditing();
      this.textbox=null;
    }
  }



  // Block "Add images"

  getImgPolaroid(event: any) {
    const el = event.target;
    fabric.loadSVGFromURL(el.src, (objects, options) => {
      const image = fabric.util.groupSVGElements(objects, options);
      image.set({
        left: 10,
        top: 10,
        angle: 0,
        padding: 10,
        cornerSize: 10,
        hasRotatingPoint: true,
      });
      this.extend(image, this.randomId());
      this.canvas.add(image);
      this.selectItemAfterAdded(image);
    });
  }

  // Block "Upload Image"
  //---backdropPart----

  currentFrame: any;
  toggleFrame(frame) {
    console.log('toggleframe function log', frame);
    this.currentFrame = frame;
  }

  //For frames.
  addbgImageOnCanvas(imageURL: string,type) {
    console.log("02-11-2024 addbgImageOnCanvas",type)
    if(imageURL){
      this.bgFrameApplied = true
    }
    this.bgFrame = imageURL;
    fabric.Image.fromURL(this.bgFrame, (img) => {
    this.canvas.setBackgroundImage(img, ()=>{
      this.canvas.renderAll();
      if(type == 'sendSocketEvent'){
        if (this.canvas.backgroundImage != null) {
          let bg = this.canvas.backgroundImage.getSrc();
          bg = bg.replace(/^.*\/assets/, "assets");
          let obj = {'bgFrame':bg,'id':Math.floor(100000 + Math.random() * 900000)}
          if(this.avChatStarted()){
            this.sendObjDataToSocket(obj,'edit')
          }
        }
      }
    }, {
      originX: 'left',
      originY: 'top',
      top: 0,
      left: 0,
      scaleX: this.canvas.width / img.width,
      scaleY: this.canvas.height / img.height
    });

  }, { crossOrigin: 'anonymous' });
  //   var imageObj = new Image();
  //   var winW = window.innerWidth,
  //   winH = window.innerHeight,
  //   //curW = this.canvas.getWidth(),

  //   //curH = this.canvas.getHeight(),
  //   canW = winW,
  //   canH = winH;
  //   // console.log("cccccc", winH, winW)
  //   this.canvas.setWidth(canW);
  //  this.canvas.setHeight(canH);
  //   console.log('canvas width and height', canW, canH)

  //   imageObj.onload = () => {
  //     var canvasElement = this.canvas.getElement(); // Get the underlying HTML canvas element

  //     let canvasWidth = this.canvas.getWidth();
  //   let canvasHeight = this.canvas.getHeight();
  //   console.log('height and width from get', canvasWidth, canvasHeight)

  //     // var canvasWidth = canvasElement.width;
  //     // var canvasHeight = canvasElement.height;

  //     // Set the target dimensions for the canvas background
  //     var targetWidth = canvasWidth;
  //     var targetHeight = canvasHeight;

  //     // Calculate the scaling factors to cover the canvas
  //     var scaleX = canvasWidth / targetWidth;
  //     var scaleY = canvasHeight / targetHeight;

  //     // Use the maximum scale factor while maintaining the aspect ratio
  //     var scale = Math.max(scaleX, scaleY);

  //     // Calculate the new dimensions to cover the canvas
  //     var newWidth = imageObj.width * scale;
  //     var newHeight = imageObj.height * scale;

  //     // Calculate the position to center the image on the canvas
  //     var left = (canvasWidth - newWidth) / 2;
  //     var top = (canvasHeight - newHeight) / 2;

  //     this.canvas.setBackgroundImage(
  //       imageURL,
  //       this.canvas.renderAll.bind(this.canvas),
  //       {
  //         originX: "left",
  //         originY: "top",
  //         top: top,
  //         left: left,
  //         scaleX: scale,
  //         scaleY: scale,
  //         width: newWidth,
  //         height: newHeight,
  //       }
  //     );
  //   };

    // imageObj.src = imageURL;
  }




  //for custom bgs.
  addBGImageOnCanvas(data: string,type, showAlert: boolean = true) {
    try{
      this.customBg = data;
      if (!this.customBg) {
        return;
      }
      // this.bgFrame = null
      // this.bgColor = 'white'
      this.bgCustomimgApplied=true;
      fabric.Image.fromURL(this.customBg, (img) => {
        if (showAlert && (img.width < 1280 || img.height < 720)) {
          this.dialogService.showError('Image is smaller than the recommended size of 1280x720 pixels and may not appear as intended.');
        }
      this.canvas.setBackgroundImage(img, () => {
        this.canvas.renderAll();
        if(type == 'sendSocketEvent'){
          if (this.canvas.backgroundImage != null) {
            let bg = this.canvas.backgroundImage.getSrc();
            bg = bg.replace(/^.*\/assets/, "assets");
            let obj = {'customBg':bg,'id':Math.floor(100000 + Math.random() * 900000)}
            if(this.avChatStarted()){
              this.sendObjDataToSocket(obj,'edit')
            }
          }
        }
      }, {
        originX: 'left',
        originY: 'top',
        top: 0,
        left: 0,
        scaleX: this.canvas.width / img.width,
        scaleY: this.canvas.height / img.height
      });

    }, { crossOrigin: 'anonymous' });
  }
  catch{

  }
  finally{
  }

}


  removebgImage() {
    console.log('inside remove bgImage of editor')
    this.canvas.setBackgroundImage(null, this.canvas.renderAll.bind(this.canvas));
    this.bgFrame = null
    this.customBg = null
    let obj = {'bgFrame':'null','id':Math.floor(100000 + Math.random() * 900000)}
      if(this.avChatStarted()){
        this.fileservice.sendObjDataToSocket(obj,'edit')
      }
  }

  //---Backdrop Part Ends---------


  addbgImagefromurl(imageURL) {
    //var imageURL = "https://iwbeurope.s3.eu-west-1.amazonaws.com/lines.png ";
    var imageObj = new Image();
    var winW = window.innerWidth,
    winH = window.innerHeight,
    //curW = this.canvas.getWidth(),

    //curH = this.canvas.getHeight(),
    canW = winW,
    canH = winH;
    // console.log("cccccc", winH, winW)
    this.canvas.setWidth(canW);
   this.canvas.setHeight(canH);
    console.log('canvas width and height', canW, canH)

    imageObj.onload = () => {
      var canvasElement = this.canvas.getElement(); // Get the underlying HTML canvas element

      let canvasWidth = this.canvas.getWidth();
    let canvasHeight = this.canvas.getHeight();
    console.log('height and width from get', canvasWidth, canvasHeight)

      // var canvasWidth = canvasElement.width;
      // var canvasHeight = canvasElement.height;

      // Set the target dimensions for the canvas background
      var targetWidth = canvasWidth;
      var targetHeight = canvasHeight;

      // Calculate the scaling factors to cover the canvas
      var scaleX = canvasWidth / targetWidth;
      var scaleY = canvasHeight / targetHeight;

      // Use the maximum scale factor while maintaining the aspect ratio
      var scale = Math.max(scaleX, scaleY);

      // Calculate the new dimensions to cover the canvas
      var newWidth = imageObj.width * scale;
      var newHeight = imageObj.height * scale;

      // Calculate the position to center the image on the canvas
      var left = (canvasWidth - newWidth) / 2;
      var top = (canvasHeight - newHeight) / 2;

      this.canvas.setBackgroundImage(
        imageURL,
        this.canvas.renderAll.bind(this.canvas),
        {
          originX: "left",
          originY: "top",
          top: top,
          left: left,
          scaleX: scale,
          scaleY: scale,
          width: newWidth,
          height: newHeight,
        }
      );
    }
  imageObj.src = imageURL;

  }
  //---Backdrop Part Ends---------

  async addImageOnCanvas(url, type) {
    let imagesCount = getTotalImagesCount(this.canvas);
    if(imagesCount >= 10) {
      this.dialogService.showSimplePopup("Maximum limit reached.");
      return;
    }
    if (url) {
        fabric.Image.fromURL(
            url,
            (image) => {
                image.set({
                    left: 10,
                    top: 10,
                    angle: 0,
                    id: Math.floor(Math.random() * 999999) + 1,
                    // cornerSize: 7,
                    padding: 6,
                    // transparentCorners: false,
                    // cornerColor: "white",
                    // cornerStrokeColor: "#109CF1",
                    // borderColor: "#109CF1",
                    name: "image",
                });

                const canvasCenter = {
                    x: this.canvas.getWidth() / 2,
                    y: this.canvas.getHeight() / 2,
                };

                const range = 50; // Adjust this value as needed
                const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
                const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

                if (type === "filemanager") {
                    image.set({
                        left: randomLeft + 320,
                        top: randomTop,
                        originX: 'center',
                        originY: 'center'
                    });
                } else {
                    image.set({
                        left: randomLeft - 400,
                        top: randomTop,
                        originX: 'center',
                        originY: 'center'
                    });
                }

                image.setControlsVisibility({
                    tl: true,
                    mt: true,
                    tr: true,
                    ml: true,
                    mr: true,
                    bl: true,
                    mb: true,
                    br: true,
                    mtr: false,
                    colorControl: false,
                });

                image.scaleToWidth(200);
                image.scaleToHeight(200);

                this.canvas.add(image);
                this.canvas.renderAll();
                this.saveShapeImgObj();
                this.canvas.isDrawingMode = false;
            },
        );
   }
}



  readUrl(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.url = readerEvent.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeWhite(url) {
    this.url = "";
  }

  // Block "Add figure"

  addFigure(figure) {
    let add: any;
    switch (figure) {
      case "line":
        console.log("adding line", this.props.fill);
        add = new fabric.Line([50, 100, 200, 200], {
          left: 10,
          top: 10,
          stroke: "#3f51b5",
        });
        break;

      case "rectangle":
        add = new fabric.Rect({
          width: 200,
          height: 100,
          left: 10,
          top: 10,
          angle: 0,
          fill: "#3f51b5",
        });
        break;
      case "square":
        add = new fabric.Rect({
          width: 100,
          height: 100,
          left: 10,
          top: 10,
          angle: 0,
          fill: "#4caf50",
        });
        break;
      case "triangle":
        add = new fabric.Triangle({
          width: 100,
          height: 100,
          left: 10,
          top: 10,
          fill: "#2196f3",
        });
        break;
      case "circle":
        add = new fabric.Circle({
          radius: 50,
          left: 10,
          top: 10,
          fill: "#ff5722",
        });
        break;
    }
    this.extend(add, this.randomId());
    this.canvas.add(add);
    this.selectItemAfterAdded(add);
  }

  /*Canvas*/

  cleanSelect() {
    this.canvas.discardActiveObject().renderAll();
  }

  selectItemAfterAdded(obj) {
    this.canvas.discardActiveObject().renderAll();
    this.canvas.setActiveObject(obj);
  }

  setCanvasFill() {
    if (!this.props.canvasImage) {
      this.canvas.backgroundColor = this.props.canvasFill;
      this.canvas.renderAll();
    }
  }

  extend(obj, id) {
    obj.toObject = ((toObject) => {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          id,
        });
      };
    })(obj.toObject);
  }

  setCanvasImage() {
    const self = this;
    if (this.props.canvasImage) {
      this.canvas.setBackgroundColor(
        new fabric.Pattern({
          source: this.props.canvasImage,
          repeat: "repeat",
        }),
        () => {
          self.props.canvasFill = "";
          self.canvas.renderAll();
        }
      );
    }
  }

  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }

  /*------------------------Global actions for element------------------------*/

  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) {
      return "";
    }

    if (object.getSelectionStyles && object.isEditing) {
      return object.getSelectionStyles()[styleName] || "";
    } else {
      return object[styleName] || "";
    }
  }

  setActiveStyle(styleName, value: string | number, object: fabric.IText) {
    object = object || (this.canvas.getActiveObject() as fabric.IText);
    object.oldState = JSON.parse(JSON.stringify(object));
    if (!object) {
      return;
    }

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;

      if (typeof value === "string") {
        if (value.includes("underline")) {
          object.setSelectionStyles({ underline: true });
        } else {
          object.setSelectionStyles({ underline: false });
        }

        if (value.includes("overline")) {
          object.setSelectionStyles({ overline: true });
        } else {
          object.setSelectionStyles({ overline: false });
        }

        if (value.includes("line-through")) {
          object.setSelectionStyles({ linethrough: true });
        } else {
          object.setSelectionStyles({ linethrough: false });
        }
      }

      object.setSelectionStyles(style);
      object.setCoords();
    } else {
      if (typeof value === "string") {
        if (value.includes("underline")) {
          object.set("underline", true);
        } else {
          object.set("underline", false);
        }

        if (value.includes("overline")) {
          object.set("overline", true);
        } else {
          object.set("overline", false);
        }

        if (value.includes("line-through")) {
          object.set("linethrough", true);
        } else {
          object.set("linethrough", false);
        }
      }

      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
    this.canvas.fire('object:modified', { target: object });
  }

  getActiveProp(name) {
    const object = this.canvas.getActiveObject();
    if (!object) {
      return "";
    }

    return object[name] || "";
  }

  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject();
    if (!object) {
      return;
    }
    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }

  clone() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      let clone;
      switch (activeObject.type) {
        case "rect":
          clone = new fabric.Rect(activeObject.toObject());
          break;
        case "circle":
          clone = new fabric.Circle(activeObject.toObject());
          break;
        case "line":
          clone = new fabric.Line(activeObject.toObject());
          break;
        case "triangle":
          clone = new fabric.Triangle(activeObject.toObject());
          break;
        case "i-text":
          clone = new fabric.IText("", activeObject.toObject());
          break;
        case "image":
          clone = fabric.util.object.clone(activeObject);
          break;
      }
      if (clone) {
        clone.set({ left: 10, top: 10 });
        this.canvas.add(clone);
        this.selectItemAfterAdded(clone);
      }
    }
  }

  getId() {
    this.props.id = this.canvas.getActiveObject().toObject().id;
  }

  setId() {
    const val = this.props.id;
    const complete = this.canvas.getActiveObject().toObject();
    console.log(complete);
    this.canvas.getActiveObject().toObject = () => {
      complete.id = val;
      return complete;
    };
  }

  getOpacity() {
    this.props.opacity = this.getActiveStyle("opacity", null) * 100;
  }

  setOpacity() {
    this.setActiveStyle(
      "opacity",
      parseInt(this.props.opacity, 10) / 100,
      null
    );
  }

  getFill() {
    console.log("get fill 111111", this.props.fill);
    this.props.fill = this.getActiveStyle("fill", null);
    console.log("get fill 222222", this.props.fill);
  }

  setFill() {
    console.log("set fill 11111", this.props.fill);
    this.setActiveStyle("fill", this.props.fill, null);
    console.log("set fill 2222222", this.props.fill);
  }

  setstroke() {
    console.log("set stroke1111", this.props.stroke);
    this.setActiveStyle("stroke", this.props.stroke, null);
    console.log("set stroke22222", this.props.stroke);
  }

  getstroke() {
    console.log("get stroke111", this.props.stroke);
    this.props.stroke = this.getActiveStyle("stroke", null);
    console.log("get stroke2222", this.props.stroke);
  }

  getLineHeight() {
    this.props.lineHeight = this.getActiveStyle("lineHeight", null);
  }

  setLineHeight() {
    this.setActiveStyle("lineHeight", parseFloat(this.props.lineHeight), null);
  }

  getCharSpacing() {
    this.props.charSpacing = this.getActiveStyle("charSpacing", null);
  }

  setCharSpacing() {
    this.setActiveStyle("charSpacing", this.props.charSpacing, null);
  }

  getFontSize() {
    this.props.fontSize = this.getActiveStyle("fontSize", null);
  }

  setFontSize() {
    this.setActiveStyle("fontSize", parseInt(this.props.fontSize, 10), null);
  }

  getBold() {
    this.props.fontWeight = this.getActiveStyle("fontWeight", null);
  }

  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    this.setActiveStyle(
      "fontWeight",
      this.props.fontWeight ? "bold" : "",
      null
    );
  }

  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    if (this.props.fontStyle) {
      this.setActiveStyle("fontStyle", "italic", null);
    } else {
      this.setActiveStyle("fontStyle", "normal", null);
    }
  }

  getTextDecoration() {
    this.props.TextDecoration = this.getActiveStyle("textDecoration", null);
  }

  setTextDecoration(value) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, "g"), "");
    } else {
      iclass += ` ${value}`;
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle("textDecoration", this.props.TextDecoration, null);
  }

  hasTextDecoration(value) {
    return this.props.TextDecoration.includes(value);
  }

  getTextAlign() {
    this.props.textAlign = this.getActiveProp("textAlign");
  }

  setTextAlign(value) {
    this.props.textAlign = value;
    this.setActiveProp("textAlign", this.props.textAlign);
  }

  getFontFamily() {
    this.props.fontFamily = this.getActiveProp("fontFamily");
  }

  setFontFamily() {
    this.setActiveProp("fontFamily", this.props.fontFamily);
  }

  /*System*/

  removeSelected() {
    console.log("calling remove slected")
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      this.canvas.remove(activeObject);
      // this.textString = '';
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      const self = this;
      activeGroup.forEach((object) => {
        self.canvas.remove(object);
      });
    }
  }

  unselectActiveObject() {
    // const activeObject = this.canvas.getActiveObject();
    // if (activeObject) {
      console.log('inside the unselectActiveObject function');
      this.canvas.discardActiveObject();
      this.colorPickerVisible = false;
      this.contextBoxVisible = false;
       this.isTextSelected=false;

      this.canvas.renderAll();
    // }
    // this.canvas.discardActiveObject();
  }

  confirmClear() {
    if (confirm("Are you sure?")) {
      this.canvas.clear();
    }
  }

  rasterize() {
    const image = new Image();
    image.src = this.canvas.toDataURL({ format: "png" });
    const w = window.open("");
    w.document.write(image.outerHTML);
    this.downLoadImage();
  }

  downLoadImage() {
    const c = this.canvas.toDataURL({ format: "png" });
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.href = c;
    downloadLink.target = "_self";
    downloadLink.download = Date.now() + ".png";
    downloadLink.click();
  }

  rasterizeSVG() {
    const w = window.open("");
    w.document.write(this.canvas.toSVG());
    this.downLoadSVG();
    return "data:image/svg+xml;utf8," + encodeURIComponent(this.canvas.toSVG());
  }

  downLoadSVG() {
    const c =
      "data:image/svg+xml;utf8," + encodeURIComponent(this.canvas.toSVG());
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.href = c;
    downloadLink.target = "_self";
    downloadLink.download = Date.now() + ".svg";
    downloadLink.click();
  }

  saveCanvasToJSON() {
    const json = JSON.stringify(this.canvas);
    localStorage.setItem("Kanvas", json);
    console.log("json");
    console.log(json);
  }

  loadCanvasFromJSON() {
    const CANVAS = localStorage.getItem("Kanvas");
    console.log("CANVAS");
    console.log(CANVAS);

    // and load everything from the same json
    this.canvas.loadFromJSON(CANVAS, () => {
      console.log("CANVAS untar");
      console.log(CANVAS);

      // making sure to render canvas at the end
      this.canvas.renderAll();

      // and checking if object's "name" is preserved
      console.log("this.canvas.item(0).name");
      console.log(this.canvas);
    });
  }

  rasterizeJSON() {
    this.json = JSON.stringify(this.canvas, null, 2);
  }

  resetPanels() {
    this.textEditor = false;
    this.imageEditor = false;
    this.figureEditor = false;
    this.lineeditor = false;
  }

  drawingMode() {
    this.canvas.isDrawingMode = !this.canvas.isDrawingMode;
    this.canvas.selection = false;
    this.canvas
      .getObjects()
      .forEach(
        (object: {
          selectable: boolean;
          evented: boolean;
          setCoords: () => void;
        }) => {
          object.selectable = false;
          object.evented = false;
          object.setCoords();
        }
      );
  }
  onResize(data) {
    var canW = this.browserDetectionService.getBrowserInfo().canvasWidth,
      canH = this.browserDetectionService.getBrowserInfo().canvasHeight;

      console.log("DEBUGG: page dimensions:", canW, canH);

    this.canvas.setWidth(canW);
    this.canvas.setHeight(canH);
    // console.log("ddddd", canH, canW)
    this.canvas.renderAll();
  }

  pencolour: any;
  changeclr(data) {
    console.log("ssssssssss", data);
    this.pencolour = data;
  }

  forhighlighter: any;
  colorchanged(data) {
    if (this.highlighterselected == false) {
      console.log("if condition", data);
      this.colcolcol = data;
      this.props.stroke = data;
      this.canvas.freeDrawingBrush.color = data;
      console.log(
        "colcol,props.stroke, data",
        this.colcolcol,
        this.props.stroke,
        data
      );
      // console.log('jii', data, this.props.fill)
      //this.setFill();
    } else {
      this.defaulthighlighteractive=false
      this.highlighterColor = data;
      this.selecthighlighter();
    }
  }

  strokethicknes: any;
  thick(data) {
    console.log("thickness", data);
    this.props.fill = data;
    this.strokethicknes = data;
    this.props.width = data;
    this.canvas.freeDrawingBrush.width = data;
  }

  selectedOption: string | null = null;

  selectPenCil() {
    this.setupCustomControlForObjects();
    this.highlighterselected = false;
    console.log("1xxxxxxxxxxxxxx11111111111111");
    this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
    this.canvas.freeDrawingBrush.color = this.colcolcol;
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush.width = this.strokethicknes;
  }

  selectcirbrush() {
    this.highlighterselected = false;
    console.log("3333333333333333", this.strokethicknes);
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush = new fabric.CircleBrush(this.canvas);
    this.canvas.freeDrawingBrush.color = this.colcolcol;
    this.canvas.freeDrawingBrush.width = this.strokethicknes;
  }

  highlighterselected: boolean = false;
  selecthighlighter() {
    this.highlighterselected = true;
    console.log("selected color", this.highlighterColor);
    let rgbaColor
    if(this.defaulthighlighteractive===true){
       rgbaColor = new fabric.Color(this.defaulthighlighter);
      // this.defaulthighlighteractive=false
    }else{
       rgbaColor = new fabric.Color(this.highlighterColor);
    }
   // const rgbaColor = new fabric.Color(this.colcolcol);
    rgbaColor.setAlpha(0.5);
    this.forhighlighter = rgbaColor.toRgba();
    console.log("222222222222222", this.forhighlighter);
    this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
    this.canvas.freeDrawingBrush.color = this.forhighlighter;
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush.width = 25;
  }

  eraseObject(): void {
    console.log("eraseObject");
    this.canvas.clear;

    // }
  }

  closepend() {
    this.canvas.isDrawingMode = false;
  }

  multitouchdraw() { }

  divele: boolean = false;
  dragiframe() {
    this.divele = true;
  }
  selectobject() {

    console.log("drawingmode");
    this.canvas.isDrawingMode = false;
    this.canvas.forEachObject((object) => {
      object.set({ selectable: true });
      object.setCoords();
    });
  }

 async deleteActiveObject() {
  console.log("calling deleteactive function")
    if (this.webbbbbbselected == true) {
      this.removewebiframe();
    } else if (this.youtubeselected == true) {
      this.removeytiframe();
    } else if (this.videodivselected == true) {
      this.removevideoiframe();
    } else if (this.threedydivselected == true) {
      this.remove3diframe();
    } else {
      var activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        this.canvas.remove(activeObject);
      }
      var objs;
      objs = this.canvas._objects.filter(function (obj) {
        return obj;
      });
      console.log("checking content", objs);
      if (objs.length == 0) {
        console.log("content missing");
        //this.isDisabled=true;
        return;
      }
    }
  }

  clearAll() {
    this.canvas.clear();
    var objs;
    objs = this.canvas._objects.filter(function (obj) {
      return obj;
    });

    console.log("checking content", objs);
    if (objs.length == 0) {
      console.log("content missing");
      //this.isDisabled=true;
      return;
    }
  }

  removeDiv() {
    const divToRemove = document.getElementById("mydiv");
    if (divToRemove) {
      divToRemove.remove();
    }
  }

  showDiv: boolean = false;

  addDiv() {
    this.showDiv = true;
  }
  removeytiframe() {
    this.showDiv = false;
    const frameZones = Array.from(document.querySelectorAll("iframe"));
    frameZones.forEach((v) => {
      v.src = v.src;
    });
  }

  isShowYtt: boolean = false;
  addyoutubevideo(data) {
    this.isShowYtt = true;
    console.log("youtube video", data);
    console.log("sameer", data.data);
    //this.ytvid=data.data;
    this.ytvid = this.sanitizer.bypassSecurityTrustResourceUrl(data.data);
    this.showDiv = true;
    console.log("sanitizer", this.ytvid);
  }
  isShowwebb: boolean = false;
  addwebpage(data) {
    console.log("webpage", data);
    this.weburl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
    this.webshowDiv = true;
    this.isShowwebb = true;
  }
  webshowDiv: boolean = false;
  addwebdiv() {
    this.webshowDiv = true;
  }
  removewebiframe() {
    this.webshowDiv = false;
  }

  videoshowDiv: boolean = false;
  isShowiframevideos: boolean = false;
  addvideodiv(vurl) {
    console.log("inside addvideo in fabric editor ", vurl);
    this.videoshowDiv = true;
    this.isShowiframevideos = true;
    this.mainvideourl = this.sanitizer.bypassSecurityTrustResourceUrl(vurl);
  }
  removevideoiframe() {
    console.log("removevideoiframe");
    this.videoshowDiv = false;
    this.isShowiframevideos = false;
    this.mainvideourl = null;
  }

  removewebShowdiviframe() {
    console.log("removevideoiframe");
    this.webshowDiv = false;
    this.isShowwebb = false;
    this.weburl = null;
  }

  audioshowDiv: boolean = false;
  isShowiframeaudio: boolean = false;
  addaudiodiv(audurl) {
    console.log("inside addaudio in fabric editor ");
    this.audioshowDiv = true;
    this.isShowiframeaudio = true;
    this.mainaudurl = this.sanitizer.bypassSecurityTrustResourceUrl(audurl);
  }

  removeaudioiframe() {
    this.audioshowDiv = false;
    this.isShowiframeaudio = false;
    this.mainaudurl = null;
  }

  threedyshowDiv: boolean = false;
  isShowiframe3d: boolean = false;
  add3dimage(d3url) {
    console.log("inside add3dimage in fabric editor ", d3url);
    this.threedyshowDiv = true;
    this.isShowiframe3d = true;
    this.main3dimage = this.sanitizer.bypassSecurityTrustResourceUrl(
      d3url.three3durl
    );
  }

  remove3diframe() {
    console.log("remove3diframe");
    this.threedyshowDiv = false;
  }

  deleteMode: boolean = false;
  toggleDeleteMode() {
    console.log(this.EraseCanvas.select,"Ans")
   // this.EraseCanvas.select = !this.EraseCanvas.select
   if(!this.avChatStarted()){
    if(this.EraseCanvas.select===false){
      console.log("hhhhhh")
      this.eraseCanvasChange()
    }
   }

    //this.canvas.selection = false;
    if(this.avChatStarted()){
      this.deleteMode = true;
      this.EraseCanvas.select=true;
      this.EraseCanvas.onTouch=true;
    }
    else {
      this.deleteMode = !this.deleteMode;
      //this.EraseCanvas.onTouch = !this.EraseCanvas.onTouch
    }

    this.canvas.isDrawingMode = false;
    //this.EraseCanvas.select = !this.EraseCanvas.select
    console.log("sameerrrrrrrrr",this.EraseCanvas.onTouch)
    console.log("deletemode", this.deleteMode);
    this.dddd(this.deleteMode,'toggleDlt');
  }

  toggledelfalse() {
    this.deleteMode = false;
    this.dddd(this.deleteMode,'toggleDlt');
  }

  dddd(data,type) {
    console.log("data", data);
    if (data == true && type == 'toggleDlt') {
      this.EraseCanvas.onTouch = true

      console.log("inside ddd");
    this.canvas.on("object:removed", (event) => {
      console.log("eventttttttttttt",event)
      this.isTextSelected = false;
      console.log("Object removed from canvas");
    });
    }
     else {
      console.log("vvvvvvvvvvvvvvvvvv");
      this.canvas.off("mouse:down");
    }
  }

  // _______________________________Ujjwal____________________________________________
  //_________________________________AutoSave___________________Cache_________________
  saveCanvasState() {
    const canvasState = this.canvas.toJSON();
    localStorage.setItem("canvasState", JSON.stringify(canvasState));
  }

  isObjectOnCanvas(id:number) {
    let objectsOnCanvas = this.canvas.getObjects();
    for(let index=0; index<objectsOnCanvas.length; index++) {
      if(id == objectsOnCanvas[index].id) {
        return true;
      }
    }
    return false;
  }


  async processCanvasOperationFromServer(obj, canvas_operation,sender_canvasWidth,sender_canvasHeight) {
    console.log("processCanvasOperationFromServer",obj,canvas_operation);
    if(canvas_operation == 'audio_add' || canvas_operation == 'audio_pause' || canvas_operation == 'audio_play' ||
    canvas_operation == 'audio_mute' || canvas_operation == 'audio_unmute' || canvas_operation == 'audio_seek'
    || canvas_operation == 'audio_ended' || canvas_operation == 'audio_close' || canvas_operation == 'video_add' || canvas_operation == 'video_pause' || canvas_operation == 'video_play' ||
    canvas_operation == 'video_mute' || canvas_operation == 'video_unmute' || canvas_operation == 'video_seek'
    || canvas_operation == 'video_ended' || canvas_operation == 'video_close'
  ){
      return;
  }
    try{
      if(this.fileservice.currentWhiteboardMode == 'present'){
        if(obj){
          obj.selectable = false
        }
      }
      else{
        if(obj){
          obj.selectable = true
        }
      }
    if (canvas_operation === 'add') {
      console.log('canvas_operation add', obj)
      if(this.isObjectOnCanvas(obj.id) == false) {
        console.log('canvas isObjectOnCanvas', obj)
        await this.addObjectToCanvas(obj,sender_canvasWidth,sender_canvasHeight);
      }
    } else if (canvas_operation === 'delete') {
      if(this.isObjectOnCanvas(obj.id) == true) {
        await this.deleteObjectFromCanvas(obj.id);
      }
    } else if (canvas_operation === 'edit' || canvas_operation === 'onTouch') {
      if("bgColor" in obj || "bgFrame" in obj || "customBg" in obj) {
        await this.modObjectOnCanvas(obj,sender_canvasWidth,sender_canvasHeight);
      }
      else {
        if(this.isObjectOnCanvas(obj.id) == true) {
          await this.modObjectOnCanvas(obj,sender_canvasWidth,sender_canvasHeight);
        }
        else {
          await this.addObjectToCanvas(obj,sender_canvasWidth,sender_canvasHeight);
        }
      }
    } else if (canvas_operation === 'eraseAll') {
      await this.clearCanvasAsync();
    }
    const objects = this.canvas.getObjects();
    let jsonObjects = objects.map(obj => obj.toJSON(['id','ownerType']));
    let canvasObjects = jsonObjects
    this.setCanvasLocalStrValue(canvasObjects)
  }
  catch(err){
    console.log("error occured while recieving object",err)
  }
}

  async addObjectToCanvas(obj,sender_canvasWidth:number,sender_canvasHeight:number) {
    if(obj.type == 'image'){
      await this.addImgObject(obj,sender_canvasWidth,sender_canvasHeight)
    }
    else{
      await this.addObject(obj,sender_canvasWidth,sender_canvasHeight);
    }
  }

  async deleteObjectFromCanvas(id:number) {
    console.log("calling deleteobject fun")
    let objectsOnCanvas = this.canvas.getObjects();
    for(let index=0; index<objectsOnCanvas.length; index++) {
      if(id == objectsOnCanvas[index].id) {
        let objToRemove = objectsOnCanvas[index];
        objToRemove.fromServer = true;
        this.canvas.remove(objToRemove)
        this.canvas.requestRenderAll();
        return;
      }
    }
  }

  async modObjectOnCanvas(objNewState,sender_canvasWidth:number,sender_canvasHeight:number) {
    if(objNewState.bgColor){
      this.canvas.backgroundColor = objNewState.bgColor
      this.canvas.renderAll()
    }
    else if(objNewState.bgFrame){
      if(objNewState.bgFrame == 'null'){
        objNewState.bgFrame = null
      }
      let frameImg = objNewState.bgFrame
      fabric.Image.fromURL(frameImg, (img) => {
        this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
          originX: 'left',
          originY: 'top',
          top: 0,
          left: 0,
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height
        });

      }, { crossOrigin: 'anonymous' });
      this.canvas.renderAll()
    }
    else if(objNewState.customBg){
      if(objNewState.customBg == 'null'){
        objNewState.customBg = null
      }
      let frameImg = objNewState.customBg
      fabric.Image.fromURL(frameImg, (img) => {
        this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
          originX: 'left',
          originY: 'top',
          top: 0,
          left: 0,
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height
        });

      }, { crossOrigin: 'anonymous' });
      this.canvas.renderAll()
    }
    else{
      let objectsOnCanvas = this.canvas.getObjects();
      for(let index=0; index<objectsOnCanvas.length; index++) {
        if(objNewState.id == objectsOnCanvas[index].id) {
          let objToBeModified = objectsOnCanvas[index];
          await this.updateObject(objNewState, objToBeModified,sender_canvasWidth,sender_canvasHeight);
          return;
        }
      }
    }

  }



  async clearCanvasAsync() {
    return new Promise<void>((resolve) => {
      let canvasObj = {
        objects: [],
        version: "5.2.1",
      };
       localStorage.setItem('currentCanvas',JSON.stringify(canvasObj))
       this.canvas.__eventListeners = {};
       this.canvas.backgroundColor = null
       this.canvas.backgroundImage = null
       this.canvas.clear();
       this.isPageLoading = true;
      this.canvas.loadFromJSON(canvasObj, () => {
        this.canvas.renderAll();
        this.addAddModRemFabricListeners();
        this.isPageLoading = false;
        resolve();
      });
    });
  }

  /**
   * Xt and Xy are scaleX and scaleY values.
   * Xy = (receiversCanvasHeight/sendersCanvasHeight).
   * Note: Xa*Xb = Xc.
   * @param page
   * @returns sacledPage
   */
  scalePage(page) {
    let Xt = this.browserDetectionService.getBrowserInfo().canvasWidth/page.pageWidth;
    let Yt = this.browserDetectionService.getBrowserInfo().canvasHeight/page.pageHeight;
    let noOfObjects = page.objects.length;
    for(let index = 0; index < noOfObjects; index++) {
      if(!('customBg' in page.objects[index]) && !('bgColor' in page.objects[index]) && !('bgFrame' in page.objects[index])) {
        try {
          console.log("DEBUGG: scalePage",page.objects[index]);
          //scale top and left.
          page.objects[index].left = page.objects[index].left * Xt;
          page.objects[index].top = page.objects[index].top * Yt;
          //set scaleX and scaleY.
          page.objects[index].scaleX = Xt * page.objects[index].scaleX
          page.objects[index].scaleY = Yt * page.objects[index].scaleY
        }
        catch(error) {
          console.log("DEBUGG: scalePage error",error);
        }
      }
    }
    let scaledPage = page;
    return scaledPage;
  }

  scalePages(pages) {
    let noOfPages = pages.length;
    for(let index=0;index<noOfPages;index++) {
      pages[index] = this.scalePage(pages[index]);
    }
    let scaledPages = pages;
    return scaledPages;
  }

scaleFabricObjects(res) {
  let noOfPages = res.Pages.length;
  for(let j=0;j<noOfPages;j++) {
  let Xt = this.browserDetectionService.getBrowserInfo().canvasWidth/res.Pages[j].pageWidth;
  let Yt = this.browserDetectionService.getBrowserInfo().canvasHeight/res.Pages[j].pageHeight;
  let noOfObjects = res.Pages[j].objects.length;
  for(let i = 0; i < noOfObjects; i++) {
    //scale top and left.
    res.Pages[j].objects[i].left = res.Pages[j].objects[i].left * Xt;
    res.Pages[j].objects[i].top = res.Pages[j].objects[i].top * Yt;
    //scale scale
    res.Pages[j].objects[i].scaleX = Xt * res.Pages[j].objects[i].scaleX
    res.Pages[j].objects[i].scaleY = Yt * res.Pages[j].objects[i].scaleY
  }
  }
  return res;
}

applyPropertiesToFabricObjectsBeforeLoading(page) {
  let objects = page.objects;
  for(let i=0; i< objects.length; i++) {
    if("name" in objects[i]) {
      if(objects[i].name == "windowYoutube" || objects[i].name == "windowYoutube" ||
        objects[i].name == "windowVideoMyLibrary" ||
        objects[i].name == "windowDocMyLibrary" ||
        objects[i].name == "windowDoc" ||
        objects[i].name == "windowAudioMyLibrary" ||
        objects[i].name == "window3D") {
          objects[i].hasBorders = false;
          objects[i].hasControls = false;
        }
    }
  }
  page.objects = objects;
  return page
}

isPageLoading: Boolean = false;
  // new code
  async loadLessonForStudent(pageNo) {
    let fileId = localStorage.getItem("FileID")
    if(fileId){
      try {
        //start of new logic.
        let res:any = await this.fileservice.synchronous_getLessonData(fileId); 
        let lessonData = res.Pages;
        preprocessLessonData(lessonData[pageNo-1], "student");
        this.ThumbnailReplacerService.captureImagesCrossingThreshold(lessonData[pageNo-1]);
        let scaledPages = this.scalePages(lessonData);
        lessonData = scaledPages;
  
        //cache lesson file and load page active page.
        let fetchIndex = pageNo - 1;
        let fetchedPage = lessonData[fetchIndex];
        if(fetchedPage == undefined) {
          throw new Error("Page Not found.");
        }
        localStorage.setItem("savedCanvasData", JSON.stringify(lessonData));
        this.canvas.__eventListeners = {};
        // fetchedPage.objects.forEach((object) => {
        //   if(object.name && this.multimediaObjArr.includes(object.name)) {
        //     object.hasControls = false;
        //     object.hasBorders = false;
        //   }
        // })
        
        this.canvas.clear();
        await this.synchronous_loadFromJSON(fetchedPage);
        //apply backgrounds.
        let bgColor = fetchedPage.bgColor;
        let bgFrame = fetchedPage.bgFrame;
        let customBg = fetchedPage.customBg;
        let bg = "";
        if (bgColor != "" && bgColor != null) {
          this.canvas.backgroundColor = bgColor;
        }
        if (customBg != "" && customBg != null) {
          bg = customBg;
        }
        else if (bgFrame != "" && bgFrame != null) {
          bg = bgFrame;
        }
        if (bg != "" && bg != null) {
          bg = bg.replace(/^.*\/assets/, "assets");
          await this.synchronous_setBackgroundImage(bg);
        }
        //store fetched page into currentCanvas.
        localStorage.setItem('currentCanvas',JSON.stringify(fetchedPage));
        //last logic
        this.selectPenCil();
        this.canvas.renderAll();
        this.addAddModRemFabricListeners();
        this.unblockPageNav();//Has to be removed.
        this.isPageLoading = false;//Has to be removed.

        //temp place to start thumbnail-looper.
        this.ThumbnailReplacerService.startExecutionOfTasks();
      }
      catch (error) {
        this.canvas.__eventListeners = {};
        this.selectPenCil();
        this.canvas.renderAll();
        this.addAddModRemFabricListeners();
        console.error(error);
      }
      }
    }


    async loadstudentCache(type) {
      let fileId = localStorage.getItem("FileID")
      if(fileId){
        localStorage.removeItem("savedCanvasData")
        localStorage.removeItem("undoCanvasObjects")
        localStorage.removeItem("currentCanvas")
        this.fileservice
          .getActivePagedata(fileId)
          .subscribe((res: any) => {
            console.log("DEBUGG: loadCache",res);
            localStorage.setItem("currentPage",res.ActivePage)
            console.log("DEBUGG: loadCache",res.pageData);
            let scaledPage = this.scalePage(res.pageData);//res.Page = {}
            res.pageData = scaledPage
            let pageData = res.pageData;
            // this.canvasData = res.Pages;
            // let savedCanvasData = res.Pages;
            // localStorage.setItem(
            //   "savedCanvasData",
            //   JSON.stringify(savedCanvasData)
            // );
            // let CurrentPosition = parseInt(localStorage.getItem("currentPage"));
            // this.UndoRedoService.initPage(CurrentPosition);
            // let test1 = this.UndoRedoService.isPageOperationsListEmpty();
            // this.undoStatusChanged.emit(test1);
            // let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
            // this.redoStatusChanged.emit(test2);
            // console.log("DEBUGG: undo",test1);
            // console.log("DEBUGG: redo",test2);
            // CurrentPosition = CurrentPosition - 1
            if (pageData) {
              this.canvas.__eventListeners = {};
              this.canvas.clear();
              // let parsedData = savedCanvasData[CurrentPosition];
              // pageData = this.applyPropertiesToFabricObjectsBeforeLoading(pageData);
              console.log("Local Debugg: parsedData", pageData);
              localStorage.setItem(
                "currentCanvas",
                JSON.stringify(pageData)
              );
              if(type == 'disableObj'){
                this.isPageLoading = true;
                this.canvas.loadFromJSON(pageData, () => {
                  this.canvas.forEachObject((object) => {
                        object.set({ selectable: false, lockMovementX: false, lockMovementY: false, lockScalingX: false,
                          lockRotation: false
                         });
                      });
                  this.canvas.renderAll();
                  this.addAddModRemFabricListeners();
                  this.unblockPageNav();
                  this.isPageLoading = false;
                });
              }
              else{
                  this.canvas.loadFromJSON(pageData, () => {
                    // this.canvas.backgroundColor = pageData.bgColor;
                  this.bgcolorchanged(pageData.bgColor,'');
                  this.addbgImageOnCanvas(pageData.bgFrame,'');
                  this.addBGImageOnCanvas(pageData.customBg,'', false);
                  this.canvas.renderAll();
                  this.addAddModRemFabricListeners();
                  this.unblockPageNav();
                  this.isPageLoading = false;
                })
              }
            } else {
              console.error("No saved canvas data found.");
              this.unblockPageNav();
            }
            this.showSpinner = false
            // this.UndoRedoService.clearUndoState();
            // this.clearRemovedOperationsList()
          });
        }
      }


  bottomGreyAreaHeight = 0;
  sideGreyAreaWidth = 0;
  canvasContainerFlexDirection = false;// false means column.
  pageChangeSocketEventSubject = new Subject<number>();
  ngOnInit() {

    //initialize greyArea dimensions and canvas container flex direction. Needs to be done before ngAfterViewInit.
    let viewPortHeight = this.browserDetectionService.getBrowserInfo().viewportHeight;
    let canvasHeight = this.browserDetectionService.getBrowserInfo().canvasHeight;
    let viewPortWidth = this.browserDetectionService.getBrowserInfo().viewportWidth;
    let canvasWidth = this.browserDetectionService.getBrowserInfo().canvasWidth;
    if(this.browserDetectionService.getBrowserInfo().viewPortType == 'type1') {
      this.bottomGreyAreaHeight = viewPortHeight - canvasHeight;
      this.sideGreyAreaWidth = 0;
      this.canvasContainerFlexDirection = false;// false means column.
    }
    else {
      this.bottomGreyAreaHeight = 0;
      this.sideGreyAreaWidth = (viewPortWidth - canvasWidth) / 2;
      this.buttonLeft = 110 + this.sideGreyAreaWidth;
      this.canvasContainerFlexDirection = true;// true means row.
    }

    this.pageChangeSocketEventSubject.pipe(debounceTime(800)).subscribe((pageNumber)=>{
      this.changeStudentPage(pageNumber);
    });
  }

  /**
   * loads first page of a lesson.
   * lessonData is array of pages.
   */
  async loadLesson(lessonData: Page[], fileId:string, pageNo:number) {
    try {
      console.log("06-12-2024 loadLesson",lessonData);
      preprocessLessonData(lessonData[pageNo-1], "teacher");
      this.ThumbnailReplacerService.captureImagesCrossingThreshold(lessonData[pageNo-1]);
      
      //some initialization.
      this.removeCompass();
      this.colorPickerVisible = false;
      this.isTextSelected = false;
      this.contextBoxVisible = false;
      this.lockUndo = false;
      this.lockRedo = false;
      this.UndoRedoService.clearUndoState();
      this.clearRemovedOperationsList()

      //initial code.
      this.fileservice.initializeLessonThumbnails(fileId);
      this.fileservice.updateAllPageChangeStatus(false);
      let scaledPages = this.scalePages(lessonData);
      lessonData = scaledPages;

      //cache lesson file and load page active page.
      let fetchIndex = pageNo - 1;
      let fetchedPage = lessonData[fetchIndex];
      if(fetchedPage == undefined) {
        throw new Error("Page Not found.");
      }
      localStorage.setItem("savedCanvasData", JSON.stringify(lessonData));
      this.canvas.__eventListeners = {};
      this.canvas.clear();
      await this.synchronous_loadFromJSON(fetchedPage);
      //apply backgrounds.
      let bgColor = fetchedPage.bgColor;
      let bgFrame = fetchedPage.bgFrame;
      let customBg = fetchedPage.customBg;
      let bg = "";
      if (bgColor != "" && bgColor != null) {
        this.canvas.backgroundColor = bgColor;
      }
      if (customBg != "" && customBg != null) {
        bg = customBg;
      }
      else if (bgFrame != "" && bgFrame != null) {
        bg = bgFrame;
      }
      if (bg != "" && bg != null) {
        bg = bg.replace(/^.*\/assets/, "assets");
        await this.synchronous_setBackgroundImage(bg);
      }
      //store fetched page into currentCanvas.
      localStorage.setItem('currentCanvas',JSON.stringify(fetchedPage));
      //last logic
      this.selectPenCil();
      this.canvas.renderAll();
      this.addAddModRemFabricListeners();
      this.unblockPageNav();//Has to be removed.
      this.isPageLoading = false;//Has to be removed.
      //undo/redo initialization.
      this.UndoRedoService.initPage(pageNo);
      let test1 = this.UndoRedoService.isPageOperationsListEmpty();
      this.undoStatusChanged.emit(test1);
      let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
      this.redoStatusChanged.emit(test2);
    }
    catch (error) {
      this.canvas.__eventListeners = {};
      this.selectPenCil();
      this.canvas.renderAll();
      this.addAddModRemFabricListeners();
      throw new Error(error);
    }
  }

      /**
   *
   * @param pageNo pageNo to be loaded from cache.
   */
      async updateFileAndLoadNewPage(pageNo: number) {
        try {
          //some initialization.
          this.removeCompass();
          this.colorPickerVisible = false;
          this.isTextSelected = false;
          this.contextBoxVisible = false;
          this.lockUndo = false;
          this.lockRedo = false;

          //start of main logic.
          let currentPageObjects = this.canvas.getObjects().map(obj => obj.toJSON(['id', 'name', 'ownerType', 'hasBorders', 'hasControls']));
          if (currentPageObjects && currentPageObjects.length > 0) {
            currentPageObjects = this.removeDuplicates(currentPageObjects, "id");
          }
          let currentPage = {
            objects: [],
            version: "1.0",
            bgColor: this.canvas.backgroundColor,
            bgFrame: '',
            customBg: '',
            pageHeight: this.browserDetectionService.getBrowserInfo().canvasHeight,
            pageWidth: this.browserDetectionService.getBrowserInfo().canvasWidth,
          };
          if (currentPageObjects) {
            currentPage.objects = currentPageObjects;
          }
          if (this.canvas.backgroundImage != null) {
            let bg = this.canvas.backgroundImage.getSrc();
            bg = bg.replace(/^.*\/assets/, "assets");
            bg.startsWith('assets') ? currentPage.bgFrame = bg : currentPage.customBg = bg;
          }

          //update lesson file in db.
          let fileId = localStorage.getItem('FileID');
          let currentPageNo = JSON.parse(localStorage.getItem("currentPage"));
          let canvasData = {
            mainLessonId: localStorage.getItem('mainlessonID'),
            fileId: fileId,
            selectedPage: currentPageNo,
            objects: currentPage.objects,
            bgColor: currentPage.bgColor,
            bgFrame: currentPage.bgFrame,
            customBg: currentPage.customBg,
            pageWidth: this.browserDetectionService.getBrowserInfo().canvasWidth,
            pageHeight: this.browserDetectionService.getBrowserInfo().canvasHeight
          }
          if(this.fileservice.readPageChangeStatus(this.currentPageNumber)) {// Should update db only if a page has changed.
            this.fileservice.generateNewThumbnailUrlForPage(this.currentPageNumber,localStorage.getItem("FileID"));
            await this.fileservice.updatePage(canvasData);
            this.fileservice.updatePageChangeStatus(this.currentPageNumber,false)// Set the page change status of current page to false once the db is updated.
          }
          //cache current page to localStorage.
          let lessonData = JSON.parse(localStorage.getItem("savedCanvasData"));
          let cacheIndex = currentPageNo - 1;
          lessonData[cacheIndex] = currentPage;
          //fetch and load page
          let fetchIndex = pageNo - 1;
          let fetchedPage = lessonData[fetchIndex];
          if(fetchedPage == undefined) {
            throw new Error("Page Not found.");
          }
          localStorage.setItem("savedCanvasData", JSON.stringify(lessonData));
          this.canvas.__eventListeners = {};
          this.canvas.clear();
          //preprocess pagejson
          preprocessLessonData(fetchedPage, "teacher");
          await this.synchronous_loadFromJSON(fetchedPage);
          //apply backgrounds.
          let bgColor = fetchedPage.bgColor;
          let bgFrame = fetchedPage.bgFrame;
          let customBg = fetchedPage.customBg;
          let bg = "";
          if (bgColor != "" && bgColor != null) {
            this.canvas.backgroundColor = bgColor;
          }
          if (customBg != "" && customBg != null) {
            bg = customBg;
          }
          else if (bgFrame != "" && bgFrame != null) {
            bg = bgFrame;
          }
          if (bg != "" && bg != null) {
            bg = bg.replace(/^.*\/assets/, "assets");
            await this.synchronous_setBackgroundImage(bg);
          }
          //store fetched page into currentCanvas.
          localStorage.setItem('currentCanvas',JSON.stringify(fetchedPage));
          //load original images.
          this.ThumbnailReplacerService.captureImagesCrossingThreshold(fetchedPage);
          //last logic
          this.selectPenCil();
          this.canvas.renderAll();
          this.addAddModRemFabricListeners();
          this.pageChangeSocketEventSubject.next(pageNo);
          //undo/redo initialization.
          this.UndoRedoService.initPage(pageNo);
          let test1 = this.UndoRedoService.isPageOperationsListEmpty();
          this.undoStatusChanged.emit(test1);
          let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
          this.redoStatusChanged.emit(test2);
        }
        catch (error) {
          this.canvas.__eventListeners = {};
          this.selectPenCil();
          this.canvas.renderAll();
          this.addAddModRemFabricListeners();
          throw new Error(error);
        }
      }

      synchronous_loadFromJSON(json) {
        return new Promise((resolve,reject)=>{
          this.canvas.loadFromJSON(json,()=>{
            resolve("content fully loaded");
          })
        });
      }

      synchronous_setBackgroundImage(backgroundImage) {
        return new Promise((resolve,reject)=>{
          fabric.Image.fromURL(backgroundImage, (img,isError:boolean) => {
            if(isError) {
              reject(new Error("Error in synchronous_setBackgroundImage."))
            }
            this.canvas.setBackgroundImage(img, ()=>{
              resolve("synchronous_setBackgroundImage ran successfuly.");
            }, {
              originX: 'left',
              originY: 'top',
              top: 0,
              left: 0,
              scaleX: this.canvas.width / img.width,
              scaleY: this.canvas.height / img.height
            });

          }, { crossOrigin: 'anonymous' });
        })
      }

      async addPage() {
        try {
            //some initialization.
            this.removeCompass();
            this.colorPickerVisible = false;
            this.isTextSelected = false;
            this.contextBoxVisible = false;
            this.lockUndo = false;
            this.lockRedo = false;

            //start of main logic.
            let currentPageObjects = this.canvas.getObjects().map(obj => obj.toJSON(['id', 'name', 'ownerType', 'hasBorders', 'hasControls']));
            if (currentPageObjects && currentPageObjects.length > 0) {
              currentPageObjects = this.removeDuplicates(currentPageObjects, "id");
            }
            let currentPage = {
              objects: [],
              version: "1.0",
              bgColor: this.canvas.backgroundColor,
              bgFrame: '',
              customBg: '',
              pageHeight: this.browserDetectionService.getBrowserInfo().canvasHeight,
              pageWidth: this.browserDetectionService.getBrowserInfo().canvasWidth,
            };
            if (currentPageObjects) {
              currentPage.objects = currentPageObjects;
            }
            if (this.canvas.backgroundImage != null) {
              let bg = this.canvas.backgroundImage.getSrc();
              bg = bg.replace(/^.*\/assets/, "assets");
              bg.startsWith('assets') ? currentPage.bgFrame = bg : currentPage.customBg = bg;
            }

            //update lesson file in db.
            let fileId = localStorage.getItem('FileID');
            let currentPageNo = JSON.parse(localStorage.getItem("currentPage"));
            let canvasData = {
              mainLessonId: localStorage.getItem('mainlessonID'),
              fileId: fileId,
              selectedPage: currentPageNo,
              objects: currentPage.objects,
              bgColor: currentPage.bgColor,
              bgFrame: currentPage.bgFrame,
              customBg: currentPage.customBg,
              pageWidth: this.browserDetectionService.getBrowserInfo().canvasWidth,
              pageHeight: this.browserDetectionService.getBrowserInfo().canvasHeight
            }
            if(this.fileservice.readPageChangeStatus(this.currentPageNumber)) {// Should update db only if a page has changed. For now update always.
              this.fileservice.generateNewThumbnailUrlForPage(this.currentPageNumber,localStorage.getItem("FileID"));
              await this.fileservice.updatePage(canvasData);
              this.fileservice.updatePageChangeStatus(this.currentPageNumber,false)// Set the page change status of current page to false once the db is updated.
            }
            //cache current page to localStorage.
            let lessonData = JSON.parse(localStorage.getItem("savedCanvasData"));
            let cacheIndex = currentPageNo - 1;
            lessonData[cacheIndex] = currentPage;
            localStorage.setItem("savedCanvasData", JSON.stringify(lessonData));
            //call add page api.
            let newPage = {
              objects: [],
              version: "1.0",
              bgColor: '',
              bgFrame: '',
              customBg: '',
              pageHeight: this.browserDetectionService.getBrowserInfo().canvasHeight,
              pageWidth: this.browserDetectionService.getBrowserInfo().canvasWidth,
            };
            await this.fileservice.addPage({fileId:fileId,newPage:newPage,
              TotalNrPages:Number(localStorage.getItem('totalPages'))+1});
            //add page in cache also.
            lessonData = JSON.parse(localStorage.getItem("savedCanvasData"));
            lessonData.push(newPage);
            localStorage.setItem("savedCanvasData", JSON.stringify(lessonData));
            //store fetched page into currentCanvas.
            localStorage.setItem('currentCanvas',JSON.stringify(newPage));
            //last logic
            //safe place to do undo/redo initialization.
            this.UndoRedoService.initPage(Number(localStorage.getItem('totalPages'))+1);
            let test1 = this.UndoRedoService.isPageOperationsListEmpty();
            this.undoStatusChanged.emit(test1);
            let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
            this.redoStatusChanged.emit(test2);
            //clear canvas.
            this.canvas.__eventListeners = {};
            this.canvas.clear();
            this.selectPenCil();
            this.canvas.renderAll();
            this.addAddModRemFabricListeners();
            this.pageChangeSocketEventSubject.next(Number(localStorage.getItem('totalPages'))+1);
        }
        catch(error) {
          this.canvas.__eventListeners = {};
          this.selectPenCil();
          this.canvas.renderAll();
          this.addAddModRemFabricListeners();
          throw new Error(error);
        }
      }

      async deletePage(pageNumberToBeDeleted) {
        try {
          //some initialization.
          this.removeCompass();
          this.colorPickerVisible = false;
          this.isTextSelected = false;
          this.contextBoxVisible = false;
          this.lockUndo = false;
          this.lockRedo = false;

          //start of main logic.
          let currentPageObjects = this.canvas.getObjects().map(obj => obj.toJSON(['id', 'name', 'ownerType', 'hasBorders', 'hasControls']));
          if (currentPageObjects && currentPageObjects.length > 0) {
            currentPageObjects = this.removeDuplicates(currentPageObjects, "id");
          }
          let currentPage = {
            objects: [],
            version: "1.0",
            bgColor: this.canvas.backgroundColor,
            bgFrame: '',
            customBg: '',
            pageHeight: this.browserDetectionService.getBrowserInfo().canvasHeight,
            pageWidth: this.browserDetectionService.getBrowserInfo().canvasWidth,
          };
          if (currentPageObjects) {
            currentPage.objects = currentPageObjects;
          }
          if (this.canvas.backgroundImage != null) {
            let bg = this.canvas.backgroundImage.getSrc();
            bg = bg.replace(/^.*\/assets/, "assets");
            bg.startsWith('assets') ? currentPage.bgFrame = bg : currentPage.customBg = bg;
          }
          //update lesson file in db.
          const lesson_id = localStorage.getItem('mainlessonID')
          const fileId = localStorage.getItem('FileID');
          let currentPageNo = JSON.parse(localStorage.getItem("currentPage"));
          let canvasData = {
            mainLessonId: localStorage.getItem('mainlessonID'),
            fileId: fileId,
            selectedPage: currentPageNo,
            objects: currentPage.objects,
            bgColor: currentPage.bgColor,
            bgFrame: currentPage.bgFrame,
            customBg: currentPage.customBg,
            pageWidth: this.browserDetectionService.getBrowserInfo().canvasWidth,
            pageHeight: this.browserDetectionService.getBrowserInfo().canvasHeight
          }
          if(this.fileservice.readPageChangeStatus(this.currentPageNumber)) {
            this.fileservice.generateNewThumbnailUrlForPage(this.currentPageNumber,localStorage.getItem("FileID"));
            await this.fileservice.updatePage(canvasData);
            this.fileservice.updatePageChangeStatus(this.currentPageNumber,false)// Set the page change status of current page to false once the db is updated.
          }
          //cache current page to localStorage.
          let lessonData = JSON.parse(localStorage.getItem("savedCanvasData"));
          let cacheIndex = currentPageNo - 1;
          lessonData[cacheIndex] = currentPage;
          localStorage.setItem("savedCanvasData", JSON.stringify(lessonData));

          //deletePage in db.
          await this.fileservice.deletePage(lesson_id,fileId, pageNumberToBeDeleted);
          //delete page in cache.
          lessonData = JSON.parse(localStorage.getItem("savedCanvasData"));
          let pageIndex = pageNumberToBeDeleted - 1;
          lessonData.splice(pageIndex,1);
          localStorage.setItem("savedCanvasData", JSON.stringify(lessonData));

          //fetch and load page
          let currentPageAfterDeletion = pageNumberToBeDeleted - 1;
          if(pageNumberToBeDeleted == 1) {
            currentPageAfterDeletion = 1;
          }
          let fetchIndex = currentPageAfterDeletion - 1;
          let fetchedPage = lessonData[fetchIndex];
          if(fetchedPage == undefined) {
            throw new Error("Page Not found.");
          }
          this.canvas.__eventListeners = {};
          this.canvas.clear();
          await this.synchronous_loadFromJSON(fetchedPage);
          //apply backgrounds.
          let bgColor = fetchedPage.bgColor;
          let bgFrame = fetchedPage.bgFrame;
          let customBg = fetchedPage.customBg;
          let bg = "";
          if (bgColor != "" && bgColor != null) {
            this.canvas.backgroundColor = bgColor;
          }
          if (customBg != "" && customBg != null) {
            bg = customBg;
          }
          else if (bgFrame != "" && bgFrame != null) {
            bg = bgFrame;
          }
          if (bg != "" && bg != null) {
            bg = bg.replace(/^.*\/assets/, "assets");
            await this.synchronous_setBackgroundImage(bg);
          }
          //store fetched page into currentCanvas.
          localStorage.setItem('currentCanvas',JSON.stringify(fetchedPage));
          this.fileservice.updateAllPageChangeStatus(true);

          //generate new urls for all the pages after page deletion.
          this.fileservice.initializeLessonThumbnails(localStorage.getItem("FileID"))

          //last logic
          this.selectPenCil();
          this.canvas.renderAll();
          this.addAddModRemFabricListeners();
          this.pageChangeSocketEventSubject.next(currentPageAfterDeletion);
          //undo/redo initialization.
          this.UndoRedoService.initPage(currentPageAfterDeletion);
          let test1 = this.UndoRedoService.isPageOperationsListEmpty();
          this.undoStatusChanged.emit(test1);
          let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
          this.redoStatusChanged.emit(test2);
        }
        catch (error) {
          this.canvas.__eventListeners = {};
          this.selectPenCil();
          this.canvas.renderAll();
          this.addAddModRemFabricListeners();
          throw new Error(error);
        }
      }


  //______________POINT_ERASER___________________

  togglePointEraserMode() {
    if(this.EraseCanvas.select){
      this.eraseCanvasChange()
    }
    //this.dddd(false,'');
    this.EraseCanvas.point = !this.EraseCanvas.point
    this.EraseCanvas.select = !this.EraseCanvas.select
    this.canvas.freeDrawingBrush = new fabric.EraserBrush(this.canvas);
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush.width = 15;
    const backgroundImage = this.canvas.backgroundImage;
    this.canvas.renderAll()
    if (backgroundImage) {
      backgroundImage.erasable = false;
    }
  }

  setupCustomControlForObjects() {
    console.log("deleteControl has been added:", fabric.Object.prototype.controls["deleteControl"]);
    let activeObj = this.canvas.getActiveObject()
    const deleteIcon: HTMLImageElement = new Image();
    deleteIcon.src = "assets/deleteIcon.svg";
    // const duplicateIcon: HTMLImageElement = new Image();
    // duplicateIcon.src = 'assets/duplicateIcon.svg';
    const rotateIcon: HTMLImageElement = new Image();
    rotateIcon.src = "assets/rotateIcon.svg";

    const colorIcon: HTMLImageElement = new Image();
    colorIcon.src = "assets/colorChangeIcon.svg";

    const menuIcon: HTMLImageElement = new Image();
    menuIcon.src = "assets/contextMenu.svg";

    const playIcon: HTMLImageElement = new Image();
    playIcon.src = "assets/playIcon.svg";
    const self = this;
    fabric.Object.prototype.controls["deleteControl"] = new fabric.Control({
      x: -0.5,
      y: -0.5,
      offsetY: -16,
      offsetX: -16,
      sizeX: 30,
      sizeY: 30,
      cursorStyle: "pointer",
      mouseUpHandler:  (eventData, transform: fabric.Transform) => {
        var activeObjects = this.canvas.getActiveObjects();
        const lenActiveObjects = activeObjects.length;
        this.multiObjsToRem = [];
        this.canvas.discardActiveObject();//deselect the active object
        activeObjects.forEach((object) => {
          this.multiObjsToRem.push(object);
          this.delCheck = (lenActiveObjects === this.multiObjsToRem.length);
          this.canvas.remove(object);
        });
        this.canvas.renderAll();
        return true;
      },
      render: renderIcon,
    });

    function renderIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ) {

      if (
        ["ruler",
        "protractor",
        "setsquare",
        "groupright",
        "groupleft",
        "compassCircle",
        // "rulerLine",
        // "line2"
      ].indexOf(
          fabricObject.name
        ) === -1
      ) {
        // if (allowedNames.includes(fabricObject.name) || (allowedTypes.includes(fabricObject.type))) {
        ctx.save();
        ctx.translate(left, top);
        const angle: number =
          fabricObject.angle !== undefined ? fabricObject.angle : 0;
        ctx.rotate(fabric.util.degreesToRadians(angle));
        ctx.drawImage(deleteIcon, -20, -20, 40, 40);
        ctx.restore();
      }
    }

    fabric.Object.prototype.controls["rotateControl"] = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -16,
      offsetX: 16,
      actionHandler: (fabric.controlsUtils as any).rotationWithSnapping,
      actionName: "rotate",
      cursorStyle: "pointer",
      sizeX: 30,
      sizeY: 30,
      render: renderrotateIcon,
    });

    function renderrotateIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ) {
      if (
        ["ruler",
         "protractor",
         "setsquare",
         "groupright",
         "groupleft",
        //  "compassCircle",
        //  "rulerLine",
        //  "line2"
        ].indexOf(
          fabricObject.name
        ) === -1
      ) {
        ctx.save();
        ctx.translate(left, top);
        const angle: number =
          fabricObject.angle !== undefined ? fabricObject.angle : 0;
        ctx.rotate(fabric.util.degreesToRadians(angle));
        ctx.drawImage(rotateIcon, -20, -20, 40, 40);
        ctx.restore();
      }
    }

    fabric.Object.prototype.controls["colorControl"] = new fabric.Control({
      x: 0.5,
      y: 0.5,
      offsetY: 16,
      offsetX: 16,
      cursorStyle: "pointer",
      sizeX: 30,
      sizeY: 30,
      mouseDownHandler:(eventData:MouseEvent,transformData:any,x:number,y:number)=>{
           // Check if the object is part of a group, is a group, or part of an active selection
           const target=transformData.target;
          //  if(target.group||target.type==="group"||target.canvas.getActiveObject()?.type==="activeSelection") {
          //   return false;// Do not trigger the color picker if the object is in a group or selected with other objects
          //   }

          if((target.group||target.type==="group"|| target.type === "image" ||target.canvas.getActiveObject()?.type==="activeSelection") && (target.name !== 'cylinder')&&(target.name !== 'arrow')) {
            return false;// Do not trigger the color picker if the object is in a group or selected with other objects
            }

           // Trigger color picker if it's an individual object
           this.handleColorPicker(eventData,transformData,x,y);
           return true;
           },


      render: renderColorIcon,
    });

    function renderColorIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ) {
      // if(fabricObject.group||fabricObject.type==="group"||fabricObject.canvas.getActiveObject()?.type==="activeSelection") {
      //      return;// Do not render the control if it's part of a group or an active selection
      //      }
      if((fabricObject.group||fabricObject.type==="group" || fabricObject.type === "image"||fabricObject.canvas.getActiveObject()?.type==="activeSelection") && (fabricObject.name !== 'cylinder')&&(fabricObject.name !== 'arrow')) {
        return false;// Do not trigger the color picker if the object is in a group or selected with other objects
        }
      if (
        [
          "ruler",
          "protractor",
          "setsquare",
          "image",
          "groupright",
          "groupleft",
          "groupVote",
          // "compassCircle",
          // "rulerLine",
          // "line2"
        ].indexOf(fabricObject.name) === -1
      ) {
        ctx.save();
        ctx.translate(left, top);
        const angle: number =
          fabricObject.angle !== undefined ? fabricObject.angle : 0;
        ctx.rotate(fabric.util.degreesToRadians(angle));
        ctx.drawImage(colorIcon, -20, -20, 40, 40);
        ctx.restore();
      }
    }

    fabric.Object.prototype.controls["menuControl"] = new fabric.Control({
      x: -0.5,
      y: 0.5,
      offsetX: -16,
      offsetY: 16,
      cursorStyle: "pointer",
      sizeX: 30,
      sizeY: 30,

      mouseDownHandler:(eventData:MouseEvent,transformData:any,x:number,y:number)=>{
        // Check if the object is part of a group, is a group, or part of an active selection
        const target=transformData.target;
        // if(target.group||target.type==="group"||target.canvas.getActiveObject()?.type==="activeSelection") {
        //  return false;// Do not trigger the color picker if the object is in a group or selected with other objects
        //  }
        if((target.group||target.type==="group"||target.canvas.getActiveObject()?.type==="activeSelection") && (target.name !== 'cylinder')&&(target.name !== 'arrow')) {
          return false;// Do not trigger the color picker if the object is in a group or selected with other objects
          }



        // Trigger color picker if it's an individual object
        this.handleMenuControl(eventData,transformData,x,y);
        return true;
        },

      render: renderMenuIcon,
    });

    function renderMenuIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ) {
      // if(fabricObject.group||fabricObject.type==="group"||fabricObject.canvas.getActiveObject()?.type==="activeSelection") {
      //   return;// Do not render the control if it's part of a group or an active selection
      //   }
      if((fabricObject.group||fabricObject.type==="group"||fabricObject.canvas.getActiveObject()?.type==="activeSelection") && (fabricObject.name !== 'cylinder')&&(fabricObject.name !== 'arrow')) {
        return false;// Do not trigger the color picker if the object is in a group or selected with other objects
        }
      if (
        [
          "ruler",
          "protractor",
          "setsquare",
          "groupright",
          "groupleft",
          "groupVote",
        ].indexOf(fabricObject.name) === -1
      ) {
        ctx.save();
        ctx.translate(left, top);
        const angle: number =
          fabricObject.angle !== undefined ? fabricObject.angle : 0;
        ctx.rotate(fabric.util.degreesToRadians(angle));
        ctx.drawImage(menuIcon, -20, -20, 40, 40);
        ctx.restore();
      }
    }

    //play control.
    fabric.Object.prototype.controls["playControl"] = new fabric.Control({
      x: 0.5,
      y: 0.5,
      offsetY: 16,
      offsetX: 16,
      sizeX: 30,
      sizeY: 30,
      actionName: "play",
      cursorStyle: "pointer",
      mouseDownHandler:(eventData:MouseEvent,transformData:any,x:number,y:number)=>{
        const target=transformData.target;
        console.log("playControl", target);
        const fileId = localStorage.getItem("FileID");
        this.fileservice.getMediaDocMetaData(fileId,target.id).subscribe((res)=>{
          console.log("Local Debugg: getMediaDocMetaData", res);
          let metaData:{fileId:string, thumbnailId:number, thumbnailType:string, url:any} = res.metaData;
          this.mediaDocMetaDataFromServer.emit({
            type: metaData.thumbnailType,
            url: metaData.url,
          });
        },(error)=>{
          console.log("Local Debugg: getMediaDocMetaData error", error);
        })
        return true;
        },
      render: renderPlayIcon,
    });

    function renderPlayIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ) {
      
      // render playIcon only for multimedia objects and do not render the PlayControl if there is more than 1 object
      if (fabricObject.canvas.getActiveObject()?.type === "activeSelection" || !fabricObject.name || !["windowYoutube", "windowVideoMyLibrary", "window3D", "windowDocMyLibrary", "windowAudioMyLibrary", "windowDoc"].includes(fabricObject.name)) {
        return false;
      }        

      if (
        ["ruler",
        "protractor",
        "setsquare",
        "groupright",
        "groupleft",
        "compassCircle",
        // "rulerLine",
        // "line2"
      ].indexOf(
          fabricObject.name
        ) === -1
      ) {
        ctx.save();
        ctx.translate(left, top);
        const angle: number =
          fabricObject.angle !== undefined ? fabricObject.angle : 0;
        ctx.rotate(fabric.util.degreesToRadians(angle));
        ctx.drawImage(playIcon, -20, -20, 40, 40);
        ctx.restore();
      }
    }
  }  ///copy


  compassControl() {
    // const imgIcon: HTMLImageElement = new Image();
    // imgIcon.src = "assets/icon_rotate.svg";

    const imgIcon: HTMLImageElement = new Image();
    imgIcon.src = "assets/icon_rotate_blue.svg";

    const imgIconPen: HTMLImageElement = new Image();
    imgIconPen.src = "assets/icon_pen.svg";

    // const closeIcon: HTMLImageElement = new Image();
    // closeIcon.src = "assets/icon_close.svg";

    const closeIcon: HTMLImageElement = new Image();
    closeIcon.src = "assets/icon_close_blue.svg";

    fabric.Object.prototype.controls["deleteControlTool"] = new fabric.Control({
      x: -0.54,
      y: -0.58,
      offsetX: 0,
      offsetY: 0,
      render: renderIcon,
      // cornersize: 54,
      cursorStyle: 'pointer',
      mouseUpHandler: (eventData: MouseEvent, transformData: any, x: number, y: number) => {
        this.handleToolsDelete(eventData, transformData, x, y);
      }
    });

    function renderIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ): void {
        ctx.save();
        ctx.translate(left, top);

        if (fabricObject.name === "groupleft") {
          const angle: number = fabricObject.angle !== undefined ? fabricObject.angle : 0;
          ctx.rotate(fabric.util.degreesToRadians(angle));

          // Set control position explicitly
          fabricObject.controls["deleteControlTool"].x = -0.54;
          fabricObject.controls["deleteControlTool"].y = -0.58;

          ctx.drawImage(closeIcon, -20, -20, 40, 40);
          ctx.restore();
        } else {
          ctx.restore();
        }
     }

      fabric.Object.prototype.controls["mtr"] = new fabric.Control({
      x: 0.17,
      y: 0.25,
      offsetX: 0,
      offsetY: 0,
      actionHandler: (eventData: any, transform: any, x: number, y: number) => {
        console.log('mtr target', transform.target.name, transform.target)
        if (transform.target.name !== "protractor") {
          console.log('mtr',transform.target.name !== "protractor")
          return (fabric as any).controlsUtils.rotationWithSnapping(eventData, transform, x, y);
        }
        return false;
      },
      actionName: "rotate",
      render: renderIcon1,
      withConnection: true,
      cursorStyle: 'pointer',
    });

    function renderIcon1(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ): void {
      ctx.save();
      ctx.translate(left, top);

      if (fabricObject.name === "groupright") {
        const angle: number = fabricObject.angle !== undefined ? fabricObject.angle : 0;
        ctx.rotate(fabric.util.degreesToRadians(angle));

        // Set control position explicitly
        fabricObject.controls["mtr"].x = 0.17;
        fabricObject.controls["mtr"].y = 0.25;

        ctx.drawImage(imgIcon, -10, -14, 24, 24);
        ctx.restore();
      } else {
        ctx.restore();
      }
    }

  }

  protractorControl() {
    const closeIcon: HTMLImageElement = new Image();
    closeIcon.src = "assets/icon_close_blue.svg";

    fabric.Object.prototype.controls["deleteControlTool"] = new fabric.Control({
      x: -2.1,
      y: -4.5,
      offsetX: 0,
      offsetY: 0,
      render: renderIcon,
      cursorStyle: 'pointer',
      // cornersize: 54,
      touchSizeX: 50,
      touchSizeY: 50,
      sizeX: 50,
      sizeY: 50,
      mouseUpHandler: (eventData: MouseEvent, transformData: any, x: number, y: number) => {
        this.handleToolsDelete(eventData, transformData, x, y);
      }
    });

    function renderIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ) : void {
      ctx.save();
      ctx.translate(left, top);

      if (fabricObject.name === "protractor") {
        const angle: number = fabricObject.angle !== undefined ? fabricObject.angle : 0;
        ctx.rotate(fabric.util.degreesToRadians(angle));

        // Set control position explicitly
        fabricObject.controls["deleteControlTool"].x = -2.1;
        fabricObject.controls["deleteControlTool"].y = -4.5;

        ctx.drawImage(closeIcon, -20, -20, 50, 50);
        ctx.restore();
      } else {
        ctx.restore();
      }
   }

   fabric.Object.prototype.controls["mtr"] = new fabric.Control({
    x: 0,
    y: -2,
    offsetX: 0,
    offsetY: 0,
    touchSizeX: 50,
    touchSizeY: 50,
    sizeX: 50,
    sizeY: 50,
    actionHandler: (fabric as any).controlsUtils.rotationWithSnapping,
    actionName: "rotate",
    render: renderIcon1,
    cursorStyle: 'pointer',
    withConnection: true,
  });

  function renderIcon1(
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object
  ): void {
    ctx.save();
    ctx.translate(left, top);

    if (fabricObject.name === "protractor") {
      const angle: number = fabricObject.angle !== undefined ? fabricObject.angle : 0;
      ctx.rotate(fabric.util.degreesToRadians(angle));

      // Set control position explicitly
      fabricObject.controls["mtr"].x = 0;
      fabricObject.controls["mtr"].y = -2;

      ctx.restore();
    } else {
      ctx.restore();
    }
  }
  }

  setsquareControl() {
    const closeIcon: HTMLImageElement = new Image();
    closeIcon.src = "assets/icon_close_blue.svg";

    fabric.Object.prototype.controls["deleteControlTool"] = new fabric.Control({
      x: -1.05,
      y: -1.3,
      offsetX: 0,
      offsetY: 0,
      touchSizeX: 50,
      touchSizeY: 50,
      render: renderIcon,
      cursorStyle: 'pointer',
      // cornersize: 54,
      mouseUpHandler: (eventData: MouseEvent, transformData: any, x: number, y: number) => {
        this.handleToolsDelete(eventData, transformData, x, y);
      }
    });

    function renderIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ) : void {
      ctx.save();
      ctx.translate(left, top);

      if (fabricObject.name === "setsquare") {
        const angle: number = fabricObject.angle !== undefined ? fabricObject.angle : 0;
        ctx.rotate(fabric.util.degreesToRadians(angle));

        // Set control position explicitly
        fabricObject.controls["deleteControlTool"].x = -1.05;
        fabricObject.controls["deleteControlTool"].y = -1.3;

        ctx.drawImage(closeIcon, -20, -20, 50, 50);
        ctx.restore();
      } else {
        ctx.restore();
      }
   }

   fabric.Object.prototype.controls["mtr"] = new fabric.Control({
    x: -0.01,
    y: -1,
    offsetX: 0,
    offsetY: 0,
    touchSizeX: 50,
    touchSizeY: 50,
    actionHandler: (eventData: any, transform: any, x: number, y: number) => {
      if (transform.target.name !== "protractor") {
        return (fabric as any).controlsUtils.rotationWithSnapping(eventData, transform, x, y);
      }
      return false;
    },
    actionName: "rotate",
    render: renderIcon1,
    cursorStyle: 'pointer',
    withConnection: true,
  });

  function renderIcon1(
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object
  ): void {
    ctx.save();
    ctx.translate(left, top);

    if (fabricObject.name === "protractor") {
      const angle: number = fabricObject.angle !== undefined ? fabricObject.angle : 0;
      ctx.rotate(fabric.util.degreesToRadians(angle));

      // Set control position explicitly
      fabricObject.controls["mtr"].x = -0.01;
      fabricObject.controls["mtr"].y = -1;

      ctx.restore();
    } else {
      ctx.restore();
    }
  }
  }

  groupleftDrawControl() {
    const imgIconPen: HTMLImageElement = new Image();
    imgIconPen.src = "assets/icon_pen_blue.svg";

    fabric.Object.prototype.controls["drawControl"] = new fabric.Control({
      x: 0.14,
      y: -0.45,
      offsetX: 0,
      offsetY: 0,
      actionHandler: (eventData: any, transform: any, x: number, y: number) => {
        if (transform.target.name !== "protractor") {
          return (fabric as any).controlsUtils.rotationWithSnapping(eventData, transform, x, y);
        }
        return false;
      },
      actionName: "rotate",
      render: renderIcon2,
      withConnection: true,
  });

  function renderIcon2(
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object
  ): void {
    ctx.save();
    ctx.translate(left, top);

    if (fabricObject.name === "groupleft") {
      const angle = fabricObject.angle !== undefined ? fabricObject.angle : 0;
      ctx.rotate(fabric.util.degreesToRadians(angle));
      ctx.drawImage(imgIconPen, -15, -15, 30, 30);
    }

    ctx.restore();
  }
  }

  rulerControl() {
    const closeIcon: HTMLImageElement = new Image();
    closeIcon.src = "assets/icon_close_blue.svg";

    fabric.Object.prototype.controls["deleteControlTool"] = new fabric.Control({
      x: -0.56,
      y: -0.80,
      offsetX: 0,
      offsetY: -30,
      touchSizeX: 50,
      touchSizeY: 50,
      render: renderIcon,
      cursorStyle: 'pointer',
      // cornersize: 54,
      mouseUpHandler: (eventData: MouseEvent, transformData: any, x: number, y: number) => {
        this.handleToolsDelete(eventData, transformData, x, y);
      }
    });

    function renderIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: fabric.Object
    ) : void {
      ctx.save();
      ctx.translate(left, top);

      if (fabricObject.name === "ruler") {
        const angle: number = fabricObject.angle !== undefined ? fabricObject.angle : 0;
        ctx.rotate(fabric.util.degreesToRadians(angle));

        // Set control position explicitly
        fabricObject.controls["deleteControlTool"].x = -0.56;
        fabricObject.controls["deleteControlTool"].y = -0.80;

        ctx.drawImage(closeIcon, -20, -20, 40, 40);
        ctx.restore();
      } else {
        ctx.restore();
      }
   }


const controlOffsetX = -500; // Relative x position for control
const controlOffsetY = -85; // Relative y position for control
const controlSize = 50; // Size of the close icon
const controlRadius = 370; // Distance from the object center to the control

this.canvas.on('after:render', () => {
  const ctx = this.canvas.contextTop;
  ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

  this.canvas.forEachObject((obj) => {
    if (obj.name === 'ruler') {
      const angleInRadians = fabric.util.degreesToRadians(obj.angle || 0);
      const center = obj.getCenterPoint();

      // Calculate control's absolute position based on the object's angle
      const controlLeft = center.x + controlRadius * Math.cos(angleInRadians + Math.atan2(controlOffsetY, controlOffsetX));
      const controlTop = center.y + controlRadius * Math.sin(angleInRadians + Math.atan2(controlOffsetY, controlOffsetX));

      ctx.save();
      ctx.translate(controlLeft, controlTop); // Move to control position
      ctx.rotate(angleInRadians); // Rotate context according to object's angle

      // Draw the close icon
      ctx.drawImage(closeIcon, -controlSize / 2, -controlSize / 2, controlSize, controlSize);

      ctx.restore();
    }
  });
});

   fabric.Object.prototype.controls["mtr"] = new fabric.Control({
    x: 0.5,
    y: 0.2,
    offsetX: 0,
    offsetY: 0,
    touchSizeX: 50,
    touchSizeY: 50,
    actionHandler: (eventData: any, transform: any, x: number, y: number) => {
      if (transform.target.name !== "protractor") {
        return (fabric as any).controlsUtils.rotationWithSnapping(eventData, transform, x, y);
      }
      return false;
    },
    actionName: "rotate",
    render: renderIcon1,
    cursorStyle: 'pointer',
    withConnection: true,
  });

  function renderIcon1(
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object
  ): void {
    ctx.save();
    ctx.translate(left, top);

    if (fabricObject.name === "ruler") {
      const angle: number = fabricObject.angle !== undefined ? fabricObject.angle : 0;
      ctx.rotate(fabric.util.degreesToRadians(angle));

      // Set control position explicitly
      fabricObject.controls["mtr"].x = 0.5;
      fabricObject.controls["mtr"].y = 0.2;

      ctx.restore();
    } else {
      ctx.restore();
    }
  }

  }





  // //customising the corners
  // setupCustomControl() {
  //   // Custom icon control (PNG image)
  //   const imgIcon: HTMLImageElement = new Image();
  //   imgIcon.src = "assets/icon_rotate.svg";

  //   const imgIconPen: HTMLImageElement = new Image();
  //   imgIconPen.src = "assets/icon_pen.svg";

  //   // const deleteIcon: HTMLImageElement = new Image();
  //   // deleteIcon.src = 'assets/deleteIcon.svg';

  //   const closeIcon: HTMLImageElement = new Image();
  //   closeIcon.src = "assets/icon_close.svg";

  //   fabric.Object.prototype.controls["deleteControlTool"] = new fabric.Control({
  //     render: renderIcon,
  //     // cornersize: 54,
  //     mouseUpHandler: (eventData: MouseEvent, transformData: any, x: number, y: number) => {
  //       this.handleToolsDelete(eventData, transformData, x, y);
  //     }
  //   });

  //   function renderIcon(
  //     ctx: CanvasRenderingContext2D,
  //     left: number,
  //     top: number,
  //     styleOverride: any,
  //     fabricObject: fabric.Object
  //   ) {
  //     ctx.save();
  //     ctx.translate(left, top);
  //     const angle: number =
  //       fabricObject.angle !== undefined ? fabricObject.angle : 0;
  //     ctx.rotate(fabric.util.degreesToRadians(angle));

  //     if (fabricObject.name === "ruler") {
  //       fabricObject.controls["deleteControlTool"].x = -0.53;
  //       fabricObject.controls["deleteControlTool"].y = -0.55;
  //       // ctx.rotate(fabric.util.degreesToRadians(angle));
  //       ctx.restore();
  //       ctx.drawImage(closeIcon, -365, -45, 40, 40);
  //     } else if (fabricObject.name === "protractor") {
  //       fabricObject.controls["deleteControlTool"].x = -2.1;
  //       fabricObject.controls["deleteControlTool"].y = -4.5;
  //       // ctx.rotate(fabric.util.degreesToRadians(angle));
  //       ctx.restore();
  //       ctx.drawImage(closeIcon, -208, -125, 40, 40);
  //     } else if (fabricObject.name === "setsquare") {
  //       fabricObject.controls["deleteControlTool"].x = -1.05;
  //       fabricObject.controls["deleteControlTool"].y = -1.3;
  //       // ctx.rotate(fabric.util.degreesToRadians(angle));
  //       ctx.restore();
  //       ctx.drawImage(closeIcon, -212, -124, 40, 40);
  //     }
  //     else if (fabricObject.name === 'groupleft') {
  //       fabricObject.controls["deleteControlTool"].x = -0.54;
  //       fabricObject.controls["deleteControlTool"].y = -0.58;
  //       ctx.restore();
  //       ctx.drawImage(closeIcon, -92, -210, 40, 40);
  //     }
  //     ctx.restore();
  //     return;
  //     // this.canvas.requestRenderAll();

  //   }

  //   fabric.Object.prototype.controls["mtr"] = new fabric.Control({
  //     // x: 0.17,
  //     // y: 0.25,
  //     offsetX: 0,
  //     offsetY: 0,
  //     actionHandler:(fabric as any).controlsUtils.rotationWithSnapping,
  //     actionName: "rotate",
  //     render: renderIcon1,
  //     withConnection: true,
  //   });
  //   function renderIcon1(
  //     ctx: CanvasRenderingContext2D,
  //     left: number,
  //     top: number,
  //     styleOverride: any,
  //     fabricObject: fabric.Object
  //   ): void {
  //     ctx.save();
  //     ctx.translate(left, top);

  //     const angle: number =
  //       fabricObject.angle !== undefined ? fabricObject.angle : 0;

  //     if (fabricObject.name === "ruler") {
  //       fabricObject.controls["mtr"].x = 0.45;
  //       fabricObject.controls["mtr"].y = 0.2;
  //       fabricObject.cornerSize = 50;

  //       ctx.rotate(fabric.util.degreesToRadians(angle));
  //       ctx.restore();
  //       // ctx.drawImage(imgIcon, 10, -10, 24, 24);
  //     } else if (fabricObject.name === "protractor") {
  //       fabricObject.controls["mtr"].x = 0;
  //       fabricObject.controls["mtr"].y = -2;
  //       fabricObject.cornerSize = 50;
  //       ctx.rotate(fabric.util.degreesToRadians(angle));
  //       ctx.restore();
  //       // ctx.drawImage(imgIcon, -73, -60, 24, 24);
  //     } else if (fabricObject.name === "groupright") {
  //       (fabricObject.controls["mtr"].x = 0.17),
  //         (fabricObject.controls["mtr"].y = 0.25);
  //         fabricObject.cornerSize = 50;
  //       ctx.rotate(fabric.util.degreesToRadians(angle));

  //       ctx.restore();
  //     } else if (fabricObject.name === "groupleft") {
  //       (fabricObject.controls["mtr"].x = 0.1),
  //         (fabricObject.controls["mtr"].y = -0.55);
  //         fabricObject.cornerSize = 100;
  //       // ctx.drawImage(imgIconPen, -10, -10, 24, 24);
  //       ctx.rotate(fabric.util.degreesToRadians(angle));

  //       ctx.restore();
  //     } else if (fabricObject.name === "setsquare") {
  //       (fabricObject.controls["mtr"].x = -0.01),
  //       (fabricObject.controls["mtr"].y = -1);
  //         fabricObject.cornerSize = 50;
  //       ctx.rotate(fabric.util.degreesToRadians(angle));

  //       ctx.restore();
  //     }
  //     ctx.restore();
  //   }
  // }
  circleAddEnable: boolean = true;
  selectCompass() {
    this.isToolsOpen = true;
    this.compassSelected = true;
    this.compassInserted = true;
    this.compassInsertedInfo = true;
    console.log("cccoompass editorr");
    this.compassControl();
    this.groupleftDrawControl();
      this.canvas.forEachObject((object) => {
      object.set({ selectable: false });
      object.set({ lockMovementX: true });
      object.set({ lockMovementY: true });
      object.set({ lockScalingX: true });
      object.set({ lockRotation: true });
    });
    this.canvas.requestRenderAll()

    //RIGHT_HAND
    //right hand thin line
    const line11 = new fabric.Line([87, 300, 170, 500], {
      stroke: "#6C767F",
      strokeWidth: 11,
      customType:'compass'
    });
    // this.canvas.add(line11);

    //rightHand thick line
    const thickline = new fabric.Line([155, 495, 192.5, 580], {
      stroke: "#6C767F",
      strokeWidth: 26,
      customType:'compass'
    });
    // this.canvas.add(thickline);

    //righthand triangle
    const righttriangle = new fabric.Triangle({
      width: 26,
      height: 70,
      top: 650,
      left: 247,
      angle: 155,
      stroke: "#6C767F",
      fill: "#6C767F",
      customType:'compass'
    });
    // this.canvas.add(righttriangle);

    fabric.Image.fromURL(this.iconRotateUrl, (rotateicon) => {
      rotateicon.set({
        left: 233,
        top: 380,
        scaleX: 0.5,
        scaleY: 0.5,
        customType:'compass'
      });
      this.groupright.addWithUpdate(rotateicon); // Add the image to the existing group
      this.canvas.add(this.groupright);
      // this.canvas.renderAll();
    });

    //RIGHT GROUPPPP
    //rightHand group
    this.groupright = new fabric.Group([line11, thickline, righttriangle], {
      hasBorders: false,
      centeredRotation: false,
      lockMovementX: true,
      lockMovementY: true,
      top: 180,
      left: 160,
      scaleX: 0.8,
      scaleY: 0.8,
      cornerSize: 30,
      excludeFromExport: true,
      name: "groupright",
      lockScalingX: true,
      lockScalingY: true,
      customType:'compass',
      perPixelTargetFind: true,
    });

    console.log('groupright loggg',this.groupright)

    this.groupright.setControlsVisibility({
      tl: false,
      mt: false, // middle-top
      tr: false, //top-right
      ml: false, //middle-left
      mr: false, //middle-right
      bl: false, // bottom-left
      mb: false, //middle-bottom
      br: false, //bottom-right
      colorControl: false,
      menuControl: false,
      rotateControl: false,
      deleteControl: false,
    });

    //LEFTTT HANDDDDD

    //thick lefthand line
    const line22 = new fabric.Line([99, 300, 17, 570], {
      stroke: "#6C767F",
      strokeWidth: 11,
      hasBorders: false,
      hasControls: false,
      customType:'compass'
    });
    console.log("Line on top of line2 added(line22)", line22);
    // this.canvas.add(line22);

    //top circle
    const circle2 = new fabric.Circle({
      radius: 50,
      fill: "white",
      stroke: "#6C767F",
      strokeWidth: 11,
      left: 46,
      top: 290,
      hasBorders: false,
      hasControls: false,
      customType:'compass'
    });
    console.log("Circle added", circle2);
    // this.canvas.add(circle2);

    //triangle lefthand
    const triangle2 = new fabric.Triangle({
      width: 12,
      height: 100,
      fill: "#6C767F",
      angle: 196,
      left: 0,
      top: 680,
      customType:'compass'
    });
    console.log("left hand triangular part added on line2", triangle2);
    // this.canvas.add(triangle2);

    // //move_icon image
    fabric.Image.fromURL(this.iconMoveUrl, (moveicon) => {
      moveicon.set({
        left: 90,
        top: 373,
        scaleX: 0.6,
        scaleY: 0.6,
        customType:'compass'
      });
      this.groupleft.addWithUpdate(moveicon); // Add the image to the existing group
      this.canvas.add(this.groupleft); // Add the updated group to the canvas
      // this.canvas.renderAll();
    });

    const triangletop = new fabric.Triangle({
      width: 40,
      height: 40,
      left: 120,
      top: 295,
      angle: 180,
      stroke: "#6C767F",
      fill: "#6C767F",
      customType:'compass'
    });
    console.log("top triangle added", triangletop);
    // this.canvas.add(triangletop);
    // this.canvas.renderAll();

    fabric.Image.fromURL(this.iconPenUrl, (drawIcon) => {
      drawIcon.set({
        left: 140,
        top: 90,
        scaleX: 0.7,
        scaleY: 0.7,
        customType:'compass'
      });
      this.groupleft.addWithUpdate(drawIcon); // Add the image to the existing group
      this.canvas.add(this.groupleft);
    });

    //GROUP LEFTTTTTTTT

    //grouping together the lefthand and circle
    this.groupleft = new fabric.Group(
      [line22, circle2, triangle2, triangletop],
      {
        scaleX: 0.8,
        scaleY: 0.8,
        hasBorders: false,
        originX: "center",
        originY: "center",
        excludeFromExport: true,
        name: "groupleft",
        lockScalingX: true,
        lockScalingY: true,
        customType:'compass',
        perPixelTargetFind: true,
        cornerSize: 30,
      }
    );
    console.log("lefthand and circle groupedd", this.groupleft);


    this.groupleft.setControlsVisibility({
      tl: false,
      mt: false, // middle-top
      tr: false, //top-right
      ml: false, //middle-left
      mr: false, //middle-right
      bl: false, // bottom-left
      mb: false, //middle-bottom
      br: false, //bottom-right
      colorControl: false,
      menuControl: false,
      rotateControl: false,
      deleteControl: false,
    });
    // this.canvas.renderAll();
    this.canvas.isDrawingMode=false;
    this.canvas.preserveObjectStacking = true;
    this.canvas.add(this.groupright,this.groupleft);
    this.canvas.moveTo(this.groupleft, this.canvas.getObjects().length - 1);
    this.canvas.bringToFront(this.groupleft);
    this.canvas.setActiveObject(this.groupleft);
    // this.canvas.renderAll();
    this.canvas.selection = false;
    this.canvas.preserveObjectStacking = true;

    // Angle and Radius information above the compass
    // this.info = new fabric.Text(`Angle: ${40}°\nRadius: ${206} px`, {
    //   left: 130,
    //   top: 45,
    //   textAlign: 'center',
    //   fontSize: 15,
    //   excludeFromExport: true,
    //   name: "circleInfo",
    //   fill: "#6C767F",
    //   customType:'compass'
    // });
    // this.canvas.add(this.info);

    // this.iconPenElement = document.getElementById("penicon") as HTMLImageElement;
    // this.iconPenImage = new fabric.Image(this.iconPenElement, {
    //   left: 135,
    //   top: 85,
    //   scaleX: 1,
    //   scaleY: 1,
    //   excludeFromExport: true,
    //   selectable: false,
    // });
    // this.canvas.add(this.iconPenImage);


    // let lastPosX = groupleft.left;
    // let lastPosY = groupleft.top;

    this.canvas.on("object: selected", (event) => {
      var selectedObject = event.target;
      if (selectedObject === this.groupright) {
        this.canvas.moveTo(this.groupright, JSON.parse(localStorage.getItem('currentCanvas'))["objects"].length - 1);
      }
    });

    this.groupleft.set({
      // hasControls: false,
      hasBorders: false,
      lockMovementX: false,
      lockMovementY: false,
      centeredRotation: false,
      originX: "left",
      originY: "bottom",
      lockScalingX: true,
      lockScalingY: true,
      customType:'compass',
      perPixelTargetFind: true,
      cornerSize: 30,
    });


    const normalizeAngle = (angle: number) => (angle % 360 + 360) % 360;

    // groupleft.on("mousedown", () => {
    //   this.canvas.setActiveObject(groupleft);
    // });

    // groupright.on("mousedown", () => {
    //   this.canvas.setActiveObject(groupright);
    // });

    let angleR = this.groupright.angle ?? 0; // Store the angle of groupright

     //Calculate the starting angle of the arc
     const grp = this.groupright.getPointByOrigin('right', 'bottom');
     const glp = this.groupleft.getPointByOrigin('left', 'bottom');
     let angleRadians = Math.atan2(grp.y! - glp.y!, grp.x! - glp.x!);
     let initialAngle = normalizeAngle(angleRadians * (180 / Math.PI));

     // Create new empty arc
     if(!this.arc){
       this.arc = new fabric.Circle({
         radius: 0,
         stroke: 'black',
         fill: '',
         originX: 'center',
         originY: 'center',
         toolType:"compass"
        //  selectable: false,
       });
        this.canvas.add(this.arc);
     }

    //  this.groupleft.on('touch:gesture', (event) => {
    //   console.log('gesture groupleft detected')
    //   if (event.e.touches && event.e.touches.length > 1) {
    //     this.dialogService.showSimplePopup('Multitouch detected.')
    //     this.groupleft.set({lockMovementX: true});
    //     this.groupleft.set({lockMovementY: true});
    //     this.groupleft.set({lockScalingX: true});
    //     this.groupleft.set({lockScalingY: true});
    //     this.groupleft.set({lockRotation: true});
    //     this.groupleft.set({selectable: false});
    //     this.canvas.renderAll();
    //   }
    //  });

    //  this.groupright.on('touch:gesture', (event) => {
    //   if (event.e.touches && event.e.touches.length > 1) {
    //     console.log('gestures groupright detected')
    //     this.dialogService.showSimplePopup('Multitouch detected.')
    //     this.groupright.set({lockMovementX: true});
    //     this.groupright.set({lockMovementY: true});
    //     this.groupright.set({lockScalingX: true});
    //     this.groupright.set({lockScalingY: true});
    //     this.groupright.set({lockRotation: true});
    //     this.groupright.set({selectable: false});
    //     this.canvas.renderAll();
    //   }
    //  })

    this.groupleft.on('mousedown', (options) => {
      // if (this.isMultitouch) {
      //   // console.log('multitouch true detected gr', options.target.startingAngle)
      //   // Stop the rotation by resetting the rotation angle or stopping interaction
      //   this.dialogService.showSimplePopup('Multitouch detected.')
      //   this.groupleft.set({lockMovementX: true});
      //   this.groupleft.set({ lockMovementY: true });
      //   this.groupleft.set({ lockScalingX: true });
      //   this.groupleft.set({ lockScalingY: true });
      //   this.groupleft.set({ lockRotation: true });
      //   this.groupleft.set({ selectable: false });
      //   // this.groupleft.angle = options.target.startingAngle || this.groupleft.angle;
      //   this.canvas.renderAll();
      //   return;
      // }
      if(this.saveClicked) {
        console.log('inside mousedown gl saveClicked')
        this.onRadiusInputChange(this.radius);
        // this.saveClicked = false;
      }
      this.canvas.setActiveObject(this.groupleft);
      this.arc = null;
    });

    this.groupright.on('mousedown', (options) => {
      if (this.isMultitouch) {
        this.dialogService.showSimplePopup('Multitouch detected.')
        console.log('multitouch true detected gr1', options, options.target)
        this.groupright.set({lockMovementX: true});
        this.groupright.set({ lockMovementY: true });
        this.groupright.set({ lockScalingX: true });
        this.groupright.set({ lockScalingY: true });
        this.groupright.set({ lockRotation: true });
        this.groupright.set({ selectable: false });

        // this.groupright.angle = options.target.startingAngle || this.groupright.angle;
        this.canvas.renderAll();
        return;
      }
      this.canvas.setActiveObject(this.groupright);
    });

    this.groupleft.on('mouseup', () => {
      console.log('groupleft mouseup detected')
      this.isMultitouch = false;
      this.groupleft.set({lockMovementX: false});
      this.groupleft.set({ lockMovementY: false });
      this.groupleft.set({ lockScalingX: true });
      this.groupleft.set({ lockScalingY: true });
      this.groupleft.set({ lockRotation: false });
      this.groupleft.set({ selectable: true});
      // this.groupleft.on();
      this.canvas.renderAll();
      const grp = this.groupright.getPointByOrigin('right', 'bottom');
      const glp = this.groupleft.getPointByOrigin('left', 'bottom');
      let angleRadians = Math.atan2(grp.y! - glp.y!, grp.x! - glp.x!);
      initialAngle = normalizeAngle(angleRadians * (180 / Math.PI));
      this.totalRotationAngle = 0;
      if(this.arc != null) {
        if(!("id" in this.arc)) {
          this.arc.id = Math.floor(100000 + Math.random() * 900000);
        }
        this.clearRemovedOperationsList()
        let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
        this.redoStatusChanged.emit(test2);
        this.UndoRedoService.pushOperationIntopageOperationsList({type:'add',newState:this.arc,oldState:null});
        this.sendObjectAddedToSocket({target:this.arc});
      }
    });

     // Variables to track the total rotation of groupleft.
    //  let totalRotationAngle = 0;
     let lastKnownAngle = 0;
    //  let testId;
     let testId = Math.floor(100000 + Math.random() * 900000);

     this.groupleft.on('rotating', async (options) => {
      console.log('groupleft rotate detected')
      // if (this.isMultitouch) {
      //   // console.log('multitouch true detected gr', options.target.startingAngle)
      //   // Stop the rotation by resetting the rotation angle or stopping interaction
      //   this.dialogService.showSimplePopup('Multitouch detected.')
      //   this.groupleft.set({lockMovementX: true});
      //   this.groupleft.set({ lockMovementY: true });
      //   this.groupleft.set({ lockScalingX: true });
      //   this.groupleft.set({ lockScalingY: true });
      //   this.groupleft.set({ lockRotation: true });
      //   this.groupleft.set({selectable: false});
      //   // this.groupleft.angle = options.target.startingAngle || this.groupleft.angle;
      //   this.canvas.renderAll();
      //   return;
      // }

       let c = this.groupleft.angle ?? 0;
       let angleChange = c - lastKnownAngle;

       // Adjust for angle reset at 360 degrees
       if (angleChange < -180) {
        angleChange += 360;
       }
       else if (angleChange > 180) {
        angleChange -= 360;
       }

       this.totalRotationAngle += angleChange;
       lastKnownAngle = c;

       // Maintaining the position of groupright as groupleft rotates
       const angle = this.groupleft.angle ?? 0;
       const pivotX = this.groupleft.left || 0;
       const pivotY = this.groupleft.top || 0;
       const newLeft = pivotX + (this.groupleft.height! - 66) * Math.sin((angle + 17) * (Math.PI / 180));
       const newTop = pivotY + (this.groupleft.height! - 66) * Math.cos((angle + 197) * (Math.PI / 180));

       if (this.saveClicked) {
        console.log('gl rotating saveClicked if')
        this.groupright.set({
          left:  newLeft,
          top:   newTop,
          // angle: angle + angleR,
         angle: angle - this.anticlockwiseAngle + 39,

        });
        // this.saveClicked = false;
       } else {
        console.log('gl rotating saveClicked else')
        this.groupright.set({
          left:  newLeft,
          top:   newTop,
          angle: angle + angleR,
         // angle: angle - this.anticlockwiseAngle + 39,

        });
       }

       // Maintaining the position of the angle and radius information text
       const infoLeft = pivotX + (this.groupleft.height! + 85) * Math.sin((angle + 9) * (Math.PI / 180));
       const infoTop = pivotY + (this.groupleft.height! + 85) * Math.cos((angle + 189) * (Math.PI / 180))

       const penLeft = pivotX + (this.groupleft.height! +45 ) * Math.sin((angle + 9) * (Math.PI / 180));
       const penTop = pivotY + (this.groupleft.height! +45) * Math.cos((angle + 189) * (Math.PI / 180))

       this.buttonLeft = infoLeft + this.sideGreyAreaWidth - 30;
       this.buttonTop = infoTop + 30;
       this.buttonRotate = angle;

      //  this.iconPenImage.set({
      //   left: penLeft,
      //   top: penTop,
      //   angle: angle,
      //  })

       // Calculate the ending angle of the arc
       const grp = this.groupright.getPointByOrigin('right', 'bottom');
       const glp = this.groupleft.getPointByOrigin('left', 'bottom');
       let angleRadians = Math.atan2(grp.y! - glp.y!, grp.x! - glp.x!);
       let currentAngle = normalizeAngle(angleRadians * (180 / Math.PI));

       // Calculate the arc radius
       const radius = Math.sqrt(Math.pow((grp.x - glp.x), 2) + Math.pow((grp.y - glp.y), 2)) - 10;

       this.circleCenterPoint = new fabric.Point(glp.x + 3, glp.y);

       if(!this.drawArc){
         // Update starting angle of the arc
        //  const grp = this.groupright.getPointByOrigin('right', 'bottom');
        //  const glp = this.groupleft.getPointByOrigin('left', 'bottom');
        //  let angleRadians = Math.atan2(grp.y! - glp.y!, grp.x! - glp.x!);
        //  initialAngle = normalizeAngle(angleRadians * (180 / Math.PI));
        //  this.arc = null;
        //  totalRotationAngle = 0;
        initialAngle = currentAngle;
        this.arc = null;
        // this.centerPoint = null;
        this.totalRotationAngle = 0;
       }

       if(this.drawArc){
         if(this.arc){
           this.canvas.remove(this.arc);
          //  this.canvas.remove(this.centerPoint);
         }

         // Draw the arc
         this.arc = new fabric.Circle({
           left: pivotX,
           top: pivotY,
           originX: 'center',
           originY: 'center',
           radius: radius,
           stroke: this.colcolcol,
           toolType:"compass",
           fill: '',
           id: testId,
           strokeWidth: this.arcThickness,
           selectable: false
         });

        //  this.centerPoint = new fabric.Circle({
        //   left: this.circleCenterPoint.x,
        //   top: this.circleCenterPoint.y,
        //   originX: 'center',
        //   originY: 'center',
        //   radius: 1,
        //   stroke: this.colcolcol,
        //   toolType:"compass",
        //   fill: this.colcolcol,
        //   id: testId,
        //   // strokeWidth: this.arcThickness,
        //   selectable: false
        //  })

         this.CompassHalfCircle = true
         if(this.circleAddEnable){
         // Adjusting angles based on clockwise or anti-clockwise rotation
         if(this.totalRotationAngle < 0){
           this.arc!.set({startAngle: currentAngle, endAngle: initialAngle});
           this.canvas.add(this.arc);
          //  this.canvas.add(this.centerPoint);
          //  this.arc.setCoords();
           this.canvas.sendToBack(this.arc);
           this.canvas.renderAll()
         } else {
           this.arc!.set({startAngle: initialAngle, endAngle: currentAngle});
            this.canvas.add(this.arc);
            // this.canvas.add(this.centerPoint);
          //  this.arc.setCoords();
           this.canvas.sendToBack(this.arc);
           this.canvas.renderAll()
         }
         if(Math.abs(this.totalRotationAngle) > 355){
          this.compassFullCircle = true
          // if(this.canvas._objects[this.canvas._objects.length-1]?.toolType == 'compass'){
          //   this.canvas.remove(this.canvas._objects[this.canvas._objects.length-1])
          // }
          // if(this.canvas._objects[this.canvas._objects.length-1]?.toolType == 'compass'){
          //   this.canvas.remove(this.canvas._objects[this.canvas._objects.length-1])
          // }
          // if(this.canvas._objects[this.canvas._objects.length-1]?.toolType == 'compass'){
          //   this.canvas.remove(this.canvas._objects[this.canvas._objects.length-1])
          // }
          console.log("circle canvas added",this.canvas._objects)
           this.canvas.remove(this.arc);
          //  this.canvas.remove(this.centerPoint);
           this.arc.set({startAngle: 0, endAngle: 360,duplicate:"yes"});
           // testId = Math.floor(100000 + Math.random() * 900000);

          // this.arc = new fabric.Circle({
          //   left: pivotX,
          //   top: pivotY,
          //   originX: 'center',
          //   originY: 'center',
          //   radius: radius,
          //   stroke: this.arcColor,
          //   fill: '',
          //   id: testId,
          //   strokeWidth: this.arcThickness,
          //   selectable: true,
          //   startAngle: 0,
          //   endAngle: 360
          // });
            this.canvas.add(this.arc);
            // this.canvas.add(this.centerPoint);
            this.circleAddEnable = false
          //  this.arc.setCoords();
           this.canvas.sendToBack(this.arc);
           this.canvas.renderAll()
           this.arc = null;
          //  this.centerPoint = null;
           this.totalRotationAngle = 0;
          //  this.toggleArcDraw();
         }

         this.canvas.sendToBack(this.arc);
        }

       }
       this.canvas.renderAll();
     });

     let grRadius: any;
     this.groupright.on('rotating', (options) => {
      console.log("groupright rotatingg detected")
      if (this.isMultitouch) {
        this.dialogService.showSimplePopup('Multitouch detected.')
        console.log('multitouch true detected gr1', options, options.target)
        // Stop the rotation by resetting the rotation angle or stopping interaction
        this.groupright.set({lockMovementX: true});
        this.groupright.set({ lockMovementY: true });
        this.groupright.set({ lockScalingX: true });
        this.groupright.set({ lockScalingY: true });
        this.groupright.set({ lockRotation: true });
        this.groupright.set({ selectable: false });

        // this.groupright.angle = options.target.startingAngle || this.groupright.angle;
        this.canvas.renderAll();
        return; // Exit the handler, so no further rotation happens
      }
      this.circleAddEnable = true;
      angleR = this.groupright.angle! - this.groupleft.angle!;

      // Update starting angle of the arc
      const grp = this.groupright.getPointByOrigin('right', 'bottom');
      const glp = this.groupleft.getPointByOrigin('left', 'bottom');
      let angleRadians = Math.atan2(grp.y! - glp.y!, grp.x! - glp.x!);
      initialAngle = normalizeAngle(angleRadians * (180 / Math.PI));
      this.groupright.setCoords();

      grRadius = Math.trunc(Math.sqrt(Math.pow((grp.x - glp.x), 2) + Math.pow((grp.y - glp.y), 2))) -18;
      console.log("sameeeeeeeeeeeeeeeeee111e esha",grRadius)
      grRadius = Math.round(grRadius * 0.161290323);
      this.radius = grRadius < 0 ? 0 : grRadius;
      this.displayRadius = this.radius;
      // this.radius = this.radius;
      // this.onRadiusInputChange(radius);
      let infoAngle = normalizeAngle(360 - normalizeAngle(this.groupright.angle! - this.groupleft.angle!) + 39);
      console.log('angleR:',angleR,
                  'angleRadians:', angleRadians,
                  'initialAngle:', initialAngle,
      )
      console.log('normalizeAngle(angleR)', normalizeAngle(angleR))
      if (infoAngle > 710) {
        console.log('not stopping?')
        this.groupright.rotate(this.groupleft.angle! + 218);
        this.dialogService.showError('Reached maximum allowed radius.')
        this.groupright.setCoords();
      }
      // this.groupright.rotate(this.anticlockwiseAngle);
      // this.groupright.setCoords();
      if (infoAngle > 179) {
        // Restrict to 179 degrees
        // console.log('restricted to 179 log', infoAngle, this.degreeInput, this.radius);
        this.groupright.rotate(this.groupleft.angle! + 220);
        this.dialogService.showError('Reached maximum allowed radius.')
        // this.degreeInput = 179;
        this.radius = Math.round(620 * 0.161290323);
        this.displayRadius = this.radius;
        this.groupright.setCoords();
    } else if (infoAngle < 4) {
        // Restrict to 0 degrees
        // console.log('restricted to 0 log', infoAngle, this.degreeInput, this.radius);
        this.groupright.rotate(this.groupleft.angle! + 34);
        this.radius = Math.round(30 * 0.161290323);
        this.displayRadius = this.radius;
        this.groupright.set({ lockRotation: true});
        this.dialogService.showError('Reached minimum allowed radius.')
        this.groupright.setCoords();
    }
    // else {
    //     // Update the angle in the info text if within the allowed range
    //     this.setAngle = infoAngle;
    //     this.degreeInput = Math.trunc(infoAngle);
    // }
    this.canvas.renderAll();

    });

    this.groupright.on('mouseup', () => {
      console.log('groupright mouseup detected')
      this.isMultitouch = false;
      this.groupright.set({lockMovementX: true});
      this.groupright.set({ lockMovementY: true });
      this.groupright.set({ lockScalingX: true });
      this.groupright.set({ lockScalingY: true });
      this.groupright.set({ lockRotation: false });
      this.groupright.set({ selectable: true });
      this.canvas.renderAll();
      this.groupleft.set({
        selectable: true,
        evented: true,
      });
    });

    this.groupleft.on('moving', (options) => {
      console.log('groupleft move detected')
      // if (this.isMultitouch) {
      //   // console.log('multitouch true detected gr', options.target.startingAngle)
      //   // Stop the rotation by resetting the rotation angle or stopping interaction
      //   this.dialogService.showSimplePopup('Multitouch detected.')
      //   this.groupleft.set({lockMovementX: true});
      //   this.groupleft.set({ lockMovementY: true });
      //   this.groupleft.set({ lockScalingX: true });
      //   this.groupleft.set({ lockScalingY: true });
      //   this.groupleft.set({ lockRotation: true });
      //   this.groupleft.set({ selectable: false });
      //   // this.groupleft.angle = options.target.startingAngle || this.groupleft.angle;
      //   this.canvas.renderAll();
      //   return; // Exit the handler, so no further rotation happens
      // }
      console.log("grouplefttttttttttttt")
      this.circleAddEnable = true
      testId = Math.floor(100000 + Math.random() * 900000);
      const angle = this.groupleft.angle ?? 0;
      const pivotX = this.groupleft.left || 0;
      const pivotY = this.groupleft.top || 0;
      const newLeft = (this.groupleft.height!-66)*Math.sin((angle+17)*(Math.PI/180));
      const newTop = (this.groupleft.height!-66)*Math.cos((angle+197)*(Math.PI/180));
      const infoLeft = pivotX + (this.groupleft.height! + 85) * Math.sin((angle + 9) * (Math.PI / 180));
      const infoTop = pivotY + (this.groupleft.height! + 85) * Math.cos((angle + 189) * (Math.PI / 180));

      const penLeft = pivotX + (this.groupleft.height! + 45) * Math.sin((angle + 9) * (Math.PI / 180));
      const penTop = pivotY + (this.groupleft.height! + 45) * Math.cos((angle + 189) * (Math.PI / 180));

      // Maintaining position of groupright
      this.groupright.set({
        left:  newLeft+pivotX,
        top:   newTop+pivotY,
      });

      // Maintaining position of the information above the compass
      this.buttonLeft = infoLeft + this.sideGreyAreaWidth - 30;
      this.buttonTop = infoTop + 30;
      this.buttonRotate = angle;

      // this.iconPenImage.set({
      //   left:  penLeft,
      //   top:   penTop,
      //   angle: angle,
      // })

      this.groupright.setCoords();
      this.canvas.renderAll();
    });


    this.groupleft.on("rotating", (options) => {
      console.log('groupleft rotate2 detected')
      // if (this.isMultitouch) {
      //   // console.log('multitouch true detected gr', options.target.startingAngle)
      //   // Stop the rotation by resetting the rotation angle or stopping interaction
      //   this.dialogService.showSimplePopup('Multitouch detected.')
      //   this.groupleft.set({lockMovementX: true});
      //   this.groupleft.set({ lockMovementY: true });
      //   this.groupleft.set({ lockScalingX: true });
      //   this.groupleft.set({ lockScalingY: true });
      //   this.groupleft.set({ lockRotation: true });
      //   this.groupleft.set({ selectable: false});
      //   // this.groupleft.angle = options.target.startingAngle || this.groupleft.angle;
      //   this.canvas.renderAll();
      //   return;
      // }
      const angle = this.groupleft.angle ?? 0;
      const pivotX = this.groupleft.left || 0;
      const pivotY = this.groupleft.top || 0;
      const newLeft =
        pivotX +
        (this.groupleft.height! - 66) * Math.sin((angle + 17) * (Math.PI / 180));
      const newTop =
        pivotY +
        (this.groupleft.height! - 66) * Math.cos((angle + 197) * (Math.PI / 180));

        if (this.saveClicked) {
          console.log('gl rotating saveClicked if 1')
          this.groupright.set({
            left: newLeft,
            top: newTop,
            // angle: angle + angleR,
            angle: angle - this.anticlockwiseAngle + 39,
          });
          // this.saveClicked = false;
        } else {
          console.log('gl rotating saveClicked else 1')
          this.groupright.set({
            left: newLeft,
            top: newTop,
            angle: angle + angleR,
            // angle: angle - this.anticlockwiseAngle + 39,
          });
        }

      let grp = this.groupright.getPointByOrigin("right", "bottom");
      let glp = this.groupleft.getPointByOrigin("left", "bottom");

      const radius = Math.sqrt(
        Math.pow(grp.x - glp.x, 2) + Math.pow(grp.y - glp.y, 2)-10
      );
      console.log("current objects is there",this.canvas._objects)
      console.log('radius log inside groupleft rotatinggg', radius);

      // circle.set({
      //   radius: radius - 10,
      //   startAngle: angle + angleR,
      //   originX: "center",
      //   originY: "center",
      //   left: pivotX,
      //   top: pivotY,
      // });

      // this.canvas.add(circle);
      // this.canvas.renderAll();
    });

    // this.groupright.on("rotating", (options) => {
    //   this.saveClicked = false;
    //   angleR = this.groupright.angle! - this.groupleft.angle!;
    //   this.groupright.setCoords();
    // });

    this.groupright.on("rotating", (options) => {
      // Prevent rotation if multitouch is detected
      console.log('groupright rotate2 event detected', this.isMultitouch)
      if (this.isMultitouch) {
        // console.log('multitouch true detected gr', options.target.startingAngle)
        // Stop the rotation by resetting the rotation angle or stopping interaction
        this.dialogService.showSimplePopup('Multitouch detected.')
        this.groupright.set({lockMovementX: true});
        this.groupright.set({ lockMovementY: true });
        this.groupright.set({ lockScalingX: true });
        this.groupright.set({ lockScalingY: true });
        this.groupright.set({ lockRotation: true });
        this.groupright.set({ selectable: false });
        // this.groupright.angle = options.target.startingAngle || this.groupright.angle;
        this.canvas.renderAll();
        return; // Exit the handler, so no further rotation happens
      }
      // this.isMultitouch = false;
      // this.groupright.set({lockMovementX: false});
      // this.groupright.set({ lockMovementY: false });
      // this.groupright.set({ lockScalingX: false });
      // this.groupright.set({ lockRotation: false });
      // console.log('no multitouch detected', this.isMultitouch);
      this.saveClicked = false;
      // Calculate the angle difference between groupright and groupleft
      angleR = this.groupright.angle! - this.groupleft.angle!;
      this.groupright.setCoords();
    });

  }

  selectedNumber: number | null = null;  // Holds the selected number

  // onKeypadPress(number: number): void {
  //   const currentInput = this.degreeInput ? this.degreeInput.toString() : '';
  //   this.selectedNumber = null;
  //   if (currentInput.length < 3) {
  //     const newInput = Number(currentInput + number);

  //     if (newInput <= 179) {
  //       this.degreeInput = newInput;
  //       this.selectedNumber = number;
  //     }
  //     else {
  //       this.dialogService.showError('Invalid angle. Please enter a value between 0 and 179 degrees.');
  //     }
  //   }
  // }

  // onDeletePress(): void {
  //   const currentInput = this.degreeInput ? this.degreeInput.toString() : '';

  //   const newInput = currentInput.length > 0 ? Number(currentInput.slice(0, -1)) : 0;
  //   this.selectedNumber = null;
  //   if (newInput <= 179) {
  //     this.degreeInput = newInput;
  //   }
  // }

  //   startAnglee: number;
  //   setGrouprightAngle() {
  //     this.circleAddEnable = true;
  //     console.log('degreeInput in setGrouprightAngle()', this.degreeInput);
  //     if (this.degreeInput >= 0 && this.degreeInput <= 179) {
  //       // Calculate the target angle relative to groupleft for anti-clockwise rotation
  //       const targetAngle = this.groupleft.angle! - this.degreeInput;
  //       const normalizeAngle = (angle: number) => (angle % 360 + 360) % 360;

  //       // Normalize the target angle to be within 0-360 degrees
  //       const normalizedTargetAngle = normalizeAngle(targetAngle);

  //       // Set the groupright angle considering anti-clockwise rotation
  //       console.log("Esha: debugg1",this.groupright.angle, 'targetAngle', targetAngle, 'normalizedTargetAngle', normalizedTargetAngle );
  //       this.groupright.rotate(normalizedTargetAngle + 39);
  //       console.log("Esha: debugg2",this.groupright.angle, 'targetAngle', targetAngle, 'normalizedTargetAngle', normalizedTargetAngle);

  //       // Store the set angle
  //       this.setAngle = this.degreeInput;
  //       this.startAnglee = this.groupright.angle;

  //       // Update the coordinates and re-render the canvas
  //       this.groupright.setCoords();
  //       this.canvas.renderAll();
  //        // Update the radius field based on the new angle
  //     const grp = this.groupright.getPointByOrigin('right', 'bottom');
  //     const glp = this.groupleft.getPointByOrigin('left', 'bottom');
  //     this.radius = Math.trunc(Math.sqrt(Math.pow((grp.x - glp.x), 2) + Math.pow((grp.y - glp.y), 2))) - 18;
  //     this.radius = this.radius < 0 ? 0 : this.radius;
  //     console.log('Updated radius:', this.radius);
  //       // this.totalRotationAngle = 0;
  //       this.canvas.renderAll();
  //       console.log('degreeInput inside if of setGrouprightAngle()', this.degreeInput);
  //       console.log('degreeInput:', this.degreeInput, 'targetAngle',targetAngle, 'normalizedTargetAngle', normalizedTargetAngle, 'setAngle', this.setAngle);
  //       // Optionally close the dialog after setting the angle
  //       this.angleInputClose();
  //       this.canvas.setActiveObject(this.groupright);
  //       this.selectedNumber = null;
  //     } else {
  //       this.degreeInput = 179;
  //       this.setAngle = 179;
  //       this.dialogService.showError('Invalid angle. Please enter a value between 0 and 179 degrees.');
  //     }
  //   }

  //   limitDegreeInput() {
  //     if (this.degreeInput > 179) {
  //       this.degreeInput = 179;
  //       this.dialogService.showError('Invalid angle. Please enter a value between 0 and 179 degrees.');
  //     }
  //   }

    openSetAngle() {
      console.log('compass radius esha21', this.radius)
      this.radius = this.displayRadius
      this.showAngleInputDialog = true;
      this.canvas.setActiveObject(this.groupright);
    }

    angleInputClose() {
      this.showAngleInputDialog = false;
      this.radius = this.radius;
      this.selectedNumber = null;
      // this.degreeInput = this.degreeInput;
    }

    limitDegreeInput() {
      if (this.radius > 790) {
        this.radius = Math.round(620 * 0.161290323);
        // this.dialogService.showError('Invalid radius. Please enter a value between 30 and 790 cm.');
      }
      else if (this.radius < 5) {
        this.radius = Math.round(5 * 0.161290323);
        // this.dialogService.showError('Invalid radius. Please enter a value between 30 and 790 cm.');
      }
    }

    radiusInput: string = '';  // This will handle string inputs from the keypad

    onKeypadPress(number: number) {
      // Append the number to the current radiusInput value
      // this.radius = Number(`${this.radius}${number}`);
      // this.selectedNumber = number;
      // this.updateRadius();
      const currentInput = this.radius ? this.radius.toString() : '';
      this.selectedNumber = null;
      // if (currentInput.length < 3) {
        const newInput = Number(currentInput + number);

        if (newInput <= 790) {
          this.radius = newInput;
          this.selectedNumber = number;
        }
        else  {
          // this.dialogService.showError('Invalid radius. Please enter a value between 30 and 790 cm.');
        }
      // }
      // else if (currentInput.length > 3) {
      //   this.dialogService.showError('Invalid radius. Please enter a value between 30 and 790 cm.');
      // }
    }

    onDeletePress() {
      // Remove the last digit from the radiusInput string
      // if (this.radiusInput.length > 0) {
      //   this.radiusInput = this.radiusInput.slice(0, -1);
      //   this.updateRadius();
      // }
      // this.radius = Math.floor(this.radius / 10);
      // this.selectedNumber = null;
      const currentInput = this.radius ? this.radius.toString() : '';

        const newInput = currentInput.length > 0 ? Number(currentInput.slice(0, -1)) : 0;
        this.selectedNumber = null;
        if (newInput <= 179) {
          this.radius = newInput;
        }
    }

    updateRadius() {
      // Convert radiusInput to a number
      const parsedRadius = parseFloat(this.radiusInput);
      this.radius = isNaN(parsedRadius) ? 0 : parsedRadius;
    }

    setGrouprightAngle() {
      this.saveClicked = true;
      this.circleAddEnable = true;
      if (this.radius >= 5) {
        // Use the calculated radius to set the angle
        this.displayRadius = this.radius;
        const setRadius = Math.round(this.radius/0.1612903238);
        this.onRadiusInputChange(setRadius);
        this.showAngleInputDialog = false;
        this.selectedNumber = null;
      }
      else if (this.radius < 5) {
        this.displayRadius = 5;
        this.radius = 5;
        this.onRadiusInputChange(this.radius);
        // this.dialogService.showError('Invalid radius. Please enter a value between 30 and 790.')
      }
    }


        // radius: number = 230;  // Bind to ngModel for the radius input

    // Constants for a and b
    readonly a = 363.1;
    readonly b = 356.43;

    // Function to calculate angle using the law of cosines
    calculateRotationAngle(radius: number): number {
      if (radius <= 0) {
        return 0;  // Prevent negative or zero radius
      }

      if (radius>350) {
        const cosC = (Math.pow(this.a, 2) + Math.pow(this.b, 2) - Math.pow(radius+100, 2)) / (2 * this.a * this.b);
          // Clamp cosC to ensure it's between -1 and 1
      const clampedCosC = Math.max(-1, Math.min(1, cosC));

      // Calculate the angle in radians and convert to degrees
      const angleInRadians = Math.acos(clampedCosC);
      const angleInDegrees = angleInRadians * (180 / Math.PI);
      console.log('esha calculateRotationAngle angleInDegrees', angleInDegrees);

      return angleInDegrees;
      }
      else if (radius < 350) {
        const cosC = (Math.pow(this.a, 2) + Math.pow(this.b, 2) - Math.pow(radius, 2)) / (2 * this.a * this.b);
          // Clamp cosC to ensure it's between -1 and 1
      const clampedCosC = Math.max(-1, Math.min(1, cosC));

      // Calculate the angle in radians and convert to degrees
      const angleInRadians = Math.acos(clampedCosC);
      const angleInDegrees = angleInRadians * (180 / Math.PI);
      console.log('esha calculateRotationAngle angleInDegrees', angleInDegrees);

      return angleInDegrees;

      }

      // Apply law of cosines
      // const cosC = (Math.pow(this.a, 2) + Math.pow(this.b, 2) - Math.pow(radius+100, 2)) / (2 * this.a * this.b);

      // // Clamp cosC to ensure it's between -1 and 1
      // const clampedCosC = Math.max(-1, Math.min(1, cosC));

      // // Calculate the angle in radians and convert to degrees
      // const angleInRadians = Math.acos(clampedCosC);
      // const angleInDegrees = angleInRadians * (180 / Math.PI);
      // console.log('esha calculateRotationAngle angleInDegrees', angleInDegrees);

      // return angleInDegrees;
    }
    anticlockwiseAngle: any;
    // Function to rotate the groupright object
    rotateGroupright(radius: number): void {
      const angle = this.calculateRotationAngle(radius);

      // Negate the angle to make the rotation anticlockwise
      this.anticlockwiseAngle = angle;
      console.log('esha rotateGroupright anticlockwise', this.anticlockwiseAngle);
      const targetAngle = this.groupleft.angle! - this.anticlockwiseAngle;
      const normalizeAngle = (angle: number) => (angle % 360 + 360) % 360;

            // Normalize the target angle to be within 0-360 degrees
      const normalizedTargetAngle = normalizeAngle(targetAngle);
      this.groupright.rotate(normalizedTargetAngle + 39);
      // Apply the negated angle to rotate the groupright in the anticlockwise direction
      // this.groupright.set('angle', this.anticlockwiseAngle);
      this.groupright.setCoords();  // Update the object's coordinates
      this.canvas.renderAll();  // Redraw the canvas
      this.canvas.setActiveObject(this.groupright);  // Set the groupright as the active object
    }


    // This function gets triggered when the user inputs a new radius
    onRadiusInputChange(newRadius: number): void {
      const radius = Number(newRadius);  // Convert string input to number

      // Prevent negative values and set radius
      this.radius = radius < 0 ? 0 : radius;

      // Rotate the groupright object based on the new radius
      this.rotateGroupright(this.radius);
    }

    closeAlert() {
      this.compassInsertedInfo = false;
    }

  toggleArcDraw(){
    console.log('togglearcdraw function working nowwwwwww')
    this.selectedOptioncompass = false;
    this.drawArc = !this.drawArc;
    this.showArcOptions = !this.showArcOptions;
    this.arcThickness = 4;
    this.arcColor = "#ff0000";
  }

  onRadioChange(value: boolean) {
    console.log('onRadioChange function workinggg noww')
    this.selectedOptioncompass = value;
  }

  onThicknessChange(newThickness: string) {
    console.log('onThicknessChange function workingg now')
    this.arcThickness = Number(newThickness);
  }

  onColorChange(newColor: string) {
    console.log('onColorChange function workingg noww')
    this.arcColor = newColor;
  }

  removeCompass() {
    console.log('editorcanvas log to remove compasss')
    if (this.groupleft) {
      console.log('checking remove log of compass');
      // this.groupleft.onDeselect = () => false;
      this.canvas.remove(this.groupleft, this.groupright, this.info, this.iconPenImage);
      this.removeCompassGroup()
      this.removeCompassGroupLocalStr()
      console.log('checking remove log of compass', this.canvas.remove(this.groupleft, this.groupright, this.info));
      // this.canvas.renderAll();
      this.groupleft.hasControls = false;
      this.compassInserted = false;
      this.compassInsertedInfo = false;
      this.showAngleInputDialog = false;
      this.buttonLeft = 100 + this.sideGreyAreaWidth;
      this.buttonTop = 50;
      this.buttonRotate = 0;
      // this.degreeInput = 40;
      // this.setAngle = 40;
      this.radius = 203;
      this.displayRadius = 30;
      this.selectedNumber = null;
      this.deleteCompass.emit(false);
      this.canvas.renderAll();

      // this.canvas.requestRenderAll();
    }
    this.canvas.selection = true;
    this.canvas.forEachObject((object) => {
      object.set({ selectable: true });
      object.set({ lockMovementX: false });
      object.set({ lockMovementY: false });
      object.set({ lockScalingX: false  });
      object.set({ lockRotation: false });
      object.setCoords();
    });
    this.canvas.renderAll();
    this.isToolsOpen = false;
  }

  isToolsOpen = false;

selectRuler() {
  this.isToolsOpen = true;
    this.rulerControl();
    this.canvas.forEachObject((object) => {
      object.set({ selectable: false });
      object.set({ lockMovementX: true });
      object.set({ lockMovementY: true });
      object.set({ lockScalingX: true });
      object.set({ lockRotation: true });
    });
    this.canvas.requestRenderAll()

    console.log("selectRuler() called");

    this.imgElement = document.getElementById("ruler") as HTMLImageElement;
    this.rulerImage = new fabric.Image(this.imgElement, {
      left: 570,
      top: 400,
      angle: 0.1,
      hasBorders: false,
      selectable: true,
      name: "ruler",
      excludeFromExport: true,
      cornerSize: 50,
      padding: -15,
      lockScalingX: true,
      lockScalingY: true,
      customType: 'ruler'
    });

    this.rulerImage.set({
      left: this.rulerImage.left,
      top: this.rulerImage.top,
      width: this.rulerImage.width - 0,
      height: this.rulerImage.height + 13
    });

    this.rulerImage.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tl: false,
      tr: false,
      br: false,
      bl: false,
      colorControl: false,
      menuControl: false,
      rotateControl: false,
      deleteControl: false,
    });

    this.canvas.add(this.rulerImage);
    this.canvas.setActiveObject(this.rulerImage);
    console.log("ruler added", this.rulerImage);

    let currentLine: fabric.Line;
    let undoRedoObj;

    this.canvas.on("mouse:down", (options: { target: any, pointer: any }) => {
      if (!options.target || (options.target && options.target.name !== "ruler")) {
        this.point1 = this.rulerImage.aCoords.tr;
        this.point2 = this.rulerImage.aCoords.tl;
        this.m = this.getSlope(this.point1, this.point2);
        this.point1 = this.rulerImage.aCoords.tl;
        this.c = this.getYIntercept(this.point1, this.m);

        this.isDown = true;
        const startPoint = {
          x: this.rulerImage.aCoords.tl.x,
          y: this.rulerImage.aCoords.tl.y,
        };

        let angle = this.rulerImage.angle ?? 0;
        let distance: number;
        let yDistance: number;
        if (angle >= 270 || angle < 90) {
          distance = 20;
        } else {
          yDistance = -10;
          distance = -20;
        }

        let startX = this.point1.x + distance * Math.cos(Math.atan(this.m));
        let startY = this.point1.y + distance * Math.sin(Math.atan(this.m));

        this.m2 = -(1 / this.m);
        this.c2 = this.getYIntercept(options.pointer, this.m2);
        this.point = this.getNearestPoint(this.m, this.m2, this.c, this.c2);

        currentLine = new fabric.Line([this.point.x, this.point.y, this.point.x, this.point.y], {
          strokeWidth: 2,
          fill: this.colcolcol,
          stroke: this.colcolcol,
          selectable: false,
          name: 'rulerLine',
          toolType:"ruler",
          toolAngle : this.rulerImage.angle
        });

        this.canvas.add(currentLine);
        this.isDisabled = false;
      }
      undoRedoObj = null;
    });

    this.canvas.on("mouse:move", (options: { pointer: any }) => {
      if (!this.isDown || !this.rulerImage) {
              console.log('inside mouse:move loggg')
              return;
      }

      this.canvas.selection = false;
      this.m2 = -(1 / this.m);
      this.c2 = this.getYIntercept(options.pointer, this.m2);
      this.point = this.getNearestPoint(this.m, this.m2, this.c, this.c2);

      if (currentLine) {
        // currentLine.set({ x2: options.pointer.x, y2: options.pointer.y });
        // this.rulerLine.selectable = true;
        this.isDisabled = false;
        let angle = this.rulerImage.angle ?? 0;
        let yDistance: number;
          if (angle >= 270 || angle < 90) {
              yDistance = 10;

          } else {
              yDistance = -10;

          }
        currentLine.set({ x2: this.point.x, y2: this.point.y });
        // currentLine.setCoords();
        this.canvas.renderAll();
        undoRedoObj = currentLine;
      }
    });

    this.canvas.on("mouse:up", () => {
      if (!this.isDown) {
              console.log('schasgwkdjagbslcd')
              this.canvas.renderAll();
              return;
      }
      this.isDown = false;
      this.canvas.setActiveObject(this.rulerImage);
      if (currentLine) {
        if(undoRedoObj != null) {
          if(!("id" in undoRedoObj)) {
            undoRedoObj.id = Math.floor(100000 + Math.random() * 900000);
          }
          this.clearRemovedOperationsList()
          let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
          this.redoStatusChanged.emit(test2);
          this.UndoRedoService.pushOperationIntopageOperationsList({type:'add',newState:currentLine,oldState:null});
          this.sendObjectAddedToSocket({target:currentLine});
        }
        undoRedoObj = null;
        currentLine = null;
        this.canvas.renderAll();
      }
    });

    this.rulerImage.on("rotating", (options) => {
      const currentAngle = this.rulerImage.angle;
      console.log("Current angle:", currentAngle);
      // this.protractorImage.setCoords();
    });

    this.canvas.on("object:selected", (event: any) => {
      const selectedObject = event.target;
      if (selectedObject && selectedObject.name === 'rulerLine') {
        selectedObject.set({ selectable: true });
      }
    });
}


  removeRuler() {
    this.isToolsOpen = false;
    if (this.rulerImage) {
      this.canvas.remove(this.rulerImage);
      this.rulerImage = null;
      this.deleteRuler.emit(false);
      this.canvas.off("mouse:down");
    }
    this.canvas.selection = true;
    this.canvas.forEachObject((object) => {
      object.set({ selectable: true });
      object.set({ lockMovementX: false });
      object.set({ lockMovementY: false });
      object.set({ lockScalingX: false  });
      object.set({ lockRotation: false });
      object.setCoords();
    });
    this.canvas.renderAll();
    this.canvas.clearContext(this.canvas.contextTop);
  }
  getSlope(point1: any, point2: any) {
    var m = (point2.y - point1.y) / (point2.x - point1.x);
    return m;
  }

  getYIntercept(point: any, m: any) {
    var c = point.y - m * point.x;
    return c;
  }

  getNearestPoint(m1: any, m2: any, c1: any, c2: any) {
    var point = { x: 0, y: 0 };
    point.x = (c1 - c2) / (m2 - m1);
    point.y = m2 * point.x + c2;
    return point;
  }

  selectProtractor() {
    this.isToolsOpen = true;
    let undoRedoObj;
    console.log("selectProtractor() called");
    this.protractorControl();
    this.canvas.forEachObject((object) => {
      object.set({ selectable: false });
      object.set({ lockMovementX: true });
      object.set({ lockMovementY: true });
      object.set({ lockScalingX: true });
      object.set({ lockRotation: true });
    });
    this.canvas.requestRenderAll()


    this.protractorElement = document.getElementById(
      "protractor"
    ) as HTMLImageElement;
    this.protractorImage = new fabric.Image(this.protractorElement, {
      left: 870,
      top: 500,
      hasBorders: false,
      angle: 0.1,
      selectable: true,
      name: "protractor",
      excludeFromExport: true,
      // perPixelTargetFind: true,
      // cornerSize: 50,
      padding: -130,
      transparentCorners: false,
      cornerColor: "white",
      cornerStrokeColor: "#109CF1",
      borderColor: "#109CF1",
      lockScalingX:true,
      lockScalingY:true,
      originX:0.5,
      originY:0.62,
      centeredRotation:false,
      customType: 'protractor'
    });

    this.protractorImage.set({
      left: this.protractorImage.left,
      top: this.protractorImage.top,
      width: this.protractorImage.width - 0,
      height: this.protractorImage.height + 105
    });

    this.protractorImage.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tl: false,
      tr: false,
      br: false,
      bl: false,
      colorControl: false,
      menuControl: false,
      rotateControl: false,
      deleteControl: false,
    });
    this.canvas.isDrawingMode = false;
    this.canvas.add(this.protractorImage);
    this.canvas.setActiveObject(this.protractorImage);
    let point;
    let currentLine: fabric.Line | null = null;


   this.canvas.on("mouse:down", (options) => {
      if (!options.target || (options.target && options.target.name !== "protractor")) {
        if (currentLine) return;
        this.isDisabled = false;
        this.point1 = this.protractorImage.getPointByOrigin(0, 0.62) ;
        this.point2 = this.protractorImage.getPointByOrigin(1, 0.62);
        this.lor = this.getPositionWithRespectToLine(
          this.point1,
          this.point2,
          options.pointer
        );
        console.log('point1, point2, lor', this.point1, this.point2, this.lor)

        if (this.lor < 0) {
          console.log("Line", this.lor);
          this.set = 1;
          const pointer = this.canvas.getPointer(options.e);
          const points = [pointer.x, pointer.y, pointer.x, pointer.y];
          this.line2 = new fabric.Line(points, {
            strokeWidth: 3.5,
            fill: this.colcolcol,
            stroke: this.colcolcol,
            selectable: false,
            name: 'line2',
            padding: 10,
          });
          this.canvas.add(this.line2);
          // currentLine = new fabric.Line(points, {
          //   strokeWidth: 2,
          //   fill: "black",
          //   stroke: "black",
          //   selectable: true,
          //   name: 'line2',
          // });
          // currentLine.selectable = true;
          // this.canvas.add(currentLine);
        } else {
          this.set = 0;
          console.log("Semicircular side clicked");

          const angle = Math.atan2(
            options.pointer.y - this.protractorImage.top,
            options.pointer.x - this.protractorImage.left
          );

          const radius = this.protractorImage.height - 105;

          const x = this.protractorImage.left + radius * Math.cos(angle);
          const y = this.protractorImage.top + radius * Math.sin(angle);

          point = new fabric.Circle({
            radius: 2,
            fill: this.colcolcol,
            left: x - 3,
            top: y - 3,
            selectable: false,
          });
          this.canvas.add(point);
        }

        this.m = this.getSlope(this.point1, this.point2);
        this.c = this.getYIntercept(this.point1, this.m);
        this.isDown2 = true;
      }
      undoRedoObj = null;
    });

    this.canvas.on("mouse:move", (options) => {
      if (!this.isDown2) {
        return;
      }
      this.canvas.selection = false;

      const angle = Math.atan2(
        options.pointer.y - this.protractorImage.top,
        options.pointer.x - this.protractorImage.left
      );
      const radius = this.protractorImage.height - 105;

      // Calculate the coordinates of the point on the semicircle
      const x = this.protractorImage.left + radius * Math.cos(angle);
      const y = this.protractorImage.top + radius * Math.sin(angle);

      this.m2 = -(1 / this.m);
      this.c2 = this.getYIntercept(options.pointer, this.m2);
      this.point = this.getNearestPoint(this.m, this.m2, this.c, this.c2);

      if (this.set == 0) {
        point.set({ left: x - 3, top: y - 3, fill: this.colcolcol });
        undoRedoObj = point;
      } else {
        // this.line2.set({
        //   x1: this.protractorImage.oCoords.mb.x,
        //   y1: this.protractorImage.oCoords.mb.y-20,
        //   selectable: true,
        // });
        // this.canvas.add(this.line2);
        // this.line2.set({ x2: this.point.x, y2: this.point.y-20, selectable: true });
        this.protractorImage.setCoords();
        console.log('valuessss', this.protractorImage.oCoords.mb.x, this.protractorImage.oCoords.mb.y)
        console.log('pointbyorigin valueee', this.protractorImage.getPointByOrigin(0.5, 0.9))
        const pointByOrigin = this.protractorImage.getPointByOrigin(0.5, 0.62);
        const xValue = pointByOrigin.x;
        const yValue = pointByOrigin.y;
        console.log('X value:', xValue);
        console.log('Y value:', yValue);

        this.line2.set({
          x1: xValue,
          y1: yValue,
          // x2: options.pointer.x,
          // y2: options.pointer.y,
          selectable: false,
          toolType : 'protractor'

        });
        console.log('valuessss', this.protractorImage.oCoords.mb.x, this.protractorImage.oCoords.mb.y)
        console.log('br coordinatesss', this.protractorImage.oCoords.br.x,this.protractorImage.oCoords.br.y - 20 )
        // this.canvas.add(this.line2);
        this.protractorImage.setCoords();

        this.line2.set({ x2: this.point.x, y2: this.point.y, toolType:"protractor",toolAngle:this.protractorImage.angle });
        undoRedoObj = this.line2;
      }
      this.canvas.renderAll();
    });
    this.canvas.on("mouse:up", (options) => {
      if (!this.isDown2) {
        return;
      }
      this.isDown2 = false;
      this.canvas.setActiveObject(this.protractorImage);
      this.canvas.renderAll();
      if(undoRedoObj != null) {
        if(!("id" in undoRedoObj)) {
          undoRedoObj.id = Math.floor(100000 + Math.random() * 900000);
        }
        this.clearRemovedOperationsList()
        let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
        this.redoStatusChanged.emit(test2);
        this.UndoRedoService.pushOperationIntopageOperationsList({type:'add',newState:undoRedoObj,oldState:null});
        this.sendObjectAddedToSocket({target:undoRedoObj});
      }
      undoRedoObj = null;
      currentLine = null;
    });

    this.protractorImage.on("rotating", (options) => {
      const currentAngle = this.protractorImage.angle;
      console.log("Current angle:", currentAngle);
      // this.protractorImage.setCoords();
    });

  }

  removeProtractor() {
    this.isToolsOpen = false;
    if (this.protractorImage) {
      this.canvas.remove(this.protractorImage);
      this.protractorImage = null;
      this.deleteProtractor.emit(false);
      this.canvas.off("mouse:down");
    }
    this.canvas.selection = true;
    this.canvas.forEachObject((object) => {
      object.set({ selectable: true });
      object.set({ lockMovementX: false });
      object.set({ lockMovementY: false });
      object.set({ lockScalingX: false  });
      object.set({ lockRotation: false });
      object.setCoords();
    });
    this.canvas.renderAll();
  }
  removeSetsquare() {
    this.isToolsOpen = false;
    if (this.setSquareImage) {
      this.canvas.remove(this.setSquareImage);
      this.setSquareImage = null;
      this.deleteSetsquare.emit(false);
      this.canvas.off("mouse:down");
    }
    this.canvas.selection = true;
    this.canvas.forEachObject((object) => {
      object.set({ selectable: true });
      object.set({ lockMovementX: false });
      object.set({ lockMovementY: false });
      object.set({ lockScalingX: false  });
      object.set({ lockRotation: false });
      object.setCoords();
    });
    this.canvas.renderAll();
  }

  selectSetsquare() {
    this.isToolsOpen = true;
    console.log("selectSetsquare() called");
    this.setsquareControl();
    this.canvas.isDrawingMode = false;
    this.canvas.forEachObject((object) => {
      object.set({ selectable: false });
      object.set({ lockMovementX: true });
      object.set({ lockMovementY: true });
      object.set({ lockScalingX: true });
      object.set({ lockRotation: true });
    });
    this.canvas.requestRenderAll()

    this.setsquareElement = document.getElementById(
      "setsquare"
    ) as HTMLImageElement;
    this.setSquareImage = new fabric.Image(this.setsquareElement, {
      selectable: true,
      name: "setsquare",
      excludeFromExport: true,
      lockScalingX:true,
      lockScalingY:true,
      customType: 'setsquare'
    });

    var padding: number = -90;
    var offsetImgHeight: number = 80;//adjust padding and offsetImgHeight if new images are given.
    this.canvas.selection = false
    var circle:fabric.Circle;
    var line:fabric.Line;
    //logic to move the point of rotation.
    var totalHeight = this.setSquareImage.height + offsetImgHeight;
    var originY = 1 - (offsetImgHeight / totalHeight);
    var originX = 0.5;

    this.setSquareImage.set({
      left: 870,
      top: 500,
      angle: 0.001,
      hasBorders: false,
      padding: padding,
      width: this.setSquareImage.width,
      height: totalHeight,
      cornerSize: 50,
      originX: originX,
      originY: originY,
      centeredRotation: false
    });

    this.setSquareImage.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tl: false,
      tr: false,
      br: false,
      bl: false,
      mtr: true,
      colorControl: false,
      menuControl: false,
      rotateControl: false,
      deleteControl: false,
    });
    this.canvas.add(this.setSquareImage);
    this.canvas.setActiveObject(this.setSquareImage);
    let slope1: number;
    let yIntercept1: number;
    let slope2: number;
    let yIntercept2: number;
    let set: number;
    let bl:any;
    let br:any;
    let mt,mb,tl,tr;
    let mouseDown: boolean;
    let undoRedoObj;
    this.canvas.on("mouse:down", (o) => {
      if (!o.target || (o.target && o.target.name !== "setsquare")) {
        mouseDown = true;
        bl = { ...this.setSquareImage.aCoords?.bl };
        bl = this.getCOnLine({...this.setSquareImage.aCoords?.tl},bl,offsetImgHeight);//have to be done since image height has changed using offset.
        br = { ...this.setSquareImage.aCoords?.br };
        br = this.getCOnLine({...this.setSquareImage.aCoords?.tr},br,offsetImgHeight);
        let tod = this.getPositionWithRespectToLine(bl, br, o.pointer);
        if (tod > 0) {
          console.log("circle selected in setSquare");
          set = 0;
          circle = new fabric.Circle({
            radius: 2,
            fill: this.colcolcol,
          });
          mt = new fabric.Point(0, 0);
          mb = new fabric.Point(0, 0);
          tl = {...this.setSquareImage.aCoords?.tl};
          tr = {...this.setSquareImage.aCoords?.tr};
          if (tl.x && tl.y && tr.x && tr.y && tr && bl && br) {// compute mt and mb from corners and dont take from oCoords.
            mt.x = (tl.x + tr.x) / 2;
            mt.y = (tl.y + tr.y) / 2;
            mb.x = (bl.x + br.x) / 2;
            mb.y = (bl.y + br.y) / 2;
          }
          let lor = this.getPositionWithRespectToLine(mt, mb, o.pointer);
          if (lor > 0) {//left or right
            slope1 = this.getSlope(mt, br);
            yIntercept1 = this.getYIntercept(mt, slope1);
          }
          else {
            slope1 = this.getSlope(mt, bl);
            yIntercept1 = this.getYIntercept(mt, slope1);
          }
          circle.selectable = false;
          console.log(bl, br, mt, mb);
          this.canvas.add(circle);
        }
        else {
          console.log("line selected in setSquare");
          set = 1;
          bl = { ...this.setSquareImage.aCoords?.bl };
          bl = this.getCOnLine({...this.setSquareImage.aCoords?.tl},bl,offsetImgHeight);
          br = { ...this.setSquareImage.aCoords?.br };
          br = this.getCOnLine({...this.setSquareImage.aCoords?.tr},br,offsetImgHeight);
          mb = new fabric.Point(0, 0);
          if (bl && br) {
            mb.x = (bl.x + br.x) / 2;
            mb.y = (bl.y + br.y) / 2;
          }
          var pointer = this.canvas.getPointer(o.e);
          var points = [pointer.x, pointer.y, pointer.x, pointer.y];
          line = new fabric.Line(points, {
              strokeWidth: 2,
              fill: this.colcolcol,
              stroke: this.colcolcol,
              name: 'line2',
              padding: 10,
          });
          slope1 = this.getSlope(bl, br);
          yIntercept1 = this.getYIntercept(bl, slope1);
          line.selectable = false;
          this.canvas.add(line);
        }
      }
      undoRedoObj = null;//this might prevent same object going in to undo/redo list.
    });

     this.canvas.on("mouse:move", (o) => {
      if(!o.target || (o.target && o.target.name !== "setsquare")) {
        if(mouseDown) {
          slope2 = -(1 / slope1); yIntercept2 = this.getYIntercept(o.pointer, slope2);
      let nearestPoint = this.getNearestPoint(slope1, slope2, yIntercept1, yIntercept2);
      if (set == 0) {
        //circle
        circle.set({ left: nearestPoint.x, top: nearestPoint.y });
        undoRedoObj = circle;
      }
      else if(set == 1) {
        //line
        line.set({ x1: mb.x, y1: mb.y, selectable: false });
        line.set({ x2: nearestPoint.x, y2: nearestPoint.y, selectable: false });
        undoRedoObj = line;
      }
        line.set({ toolType:"setsquare",toolAngle:this.setSquareImage.angle})
        // line.setCoords();
        this.canvas.renderAll();
        }
      }
    });

    this.canvas.on("mouse:up", (options) => {
      mouseDown = false;
      this.canvas.setActiveObject(this.setSquareImage);
      this.canvas.renderAll();
      if(undoRedoObj != null) {
        if(!("id" in undoRedoObj)) {
          undoRedoObj.id = Math.floor(100000 + Math.random() * 900000);
        }
        this.clearRemovedOperationsList()
        let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
        this.redoStatusChanged.emit(test2);
        this.UndoRedoService.pushOperationIntopageOperationsList({type:'add',newState:undoRedoObj,oldState:null});
        this.sendObjectAddedToSocket({target:undoRedoObj});
      }
      undoRedoObj = null;
    });

  }

  getCOnLine(A:any,B:any,l:any) {//finds the 3rd point on line made using points A and B. The third point is l units away from B
    var C = { x: 0, y: 0 };
    var D = Math.sqrt(((A.x - B.x) * (A.x - B.x)) + ((A.y - B.y) * (A.y - B.y)));
    console.log("D",D);
    C.x = A.x-(((A.x - B.x)/D)*(D-l));
    C.y = A.y-(((A.y - B.y)/D)*(D-l));
    return C;
  }

  getPositionWithRespectToLine(a: any, b: any, c: any) {
    return (c.x - a.x) * (b.y - a.y) - (c.y - a.y) * (b.x - a.x);
  }

  //------------SHAPES_PART-----------------

  insertLine() {
    this.canvas.isDrawingMode = false;
    console.log("insertLine working");

    const line = new fabric.Line([50, 100, 200, 200], {
      // left: 570,
      // top: 550,
      stroke: this.colcolcol,
      strokeWidth: 2,
      // cornerSize: 7,
      padding: 6,
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1"
      name: 'line'
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =100; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    line.set({
      left: randomLeft-200,
      top: randomTop,
      originX: 'center',
      originY: 'center',
      name: 'line',
    });
    line.setControlsVisibility({
      tl: true,
      mt: true, // middle-top
      tr: true, //top-right
      ml: true, //middle-left
      mr: true, //middle-right
      bl: true, // bottom-left
      mb: true, //middle-bottom
      br: true,
      mtr: false, //bottom-right
    });
    this.canvas.add(line);
    this.canvas.renderAll()
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(line);
    this.sendObjToSocket()
  }

  insertArrow() {
    this.canvas.isDrawingMode = false;
    const triangle = new fabric.Triangle({
      width: 10,
      height: 15,
      fill: this.colcolcol,
      left: 235,
      top: 65,
      angle: 90,
    });

    const line = new fabric.Line([50, 100, 200, 100], {
      left: 75,
      top: 70,
      stroke: this.colcolcol,
      strokeWidth: 2,
    });
    const objs = [line, triangle];
    const allTogetherObj = new fabric.Group(objs, {
      // cornerSize: 7,
      padding: 6,
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =100; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);


    allTogetherObj.set({
      left: randomLeft-500,
      top: randomTop-200,
      originX: 'center',
      originY: 'center',
      name:"arrow",
    });
    allTogetherObj.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });
    console.log("ArrowLine added");
    this.canvas.add(allTogetherObj);
    this.canvas.renderAll()
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(allTogetherObj);
    this.sendObjToSocket()
  }

  insertArc() {
    this.canvas.isDrawingMode = false;
    const centerX = 300;
    const centerY = 300;
    const radius = 100;

    const startAngle = 0;
    const endAngle = Math.PI;

    const arcPath = new fabric.Path(
      `M ${centerX} ${centerY} A ${radius} ${radius} 0 ${endAngle > Math.PI ? 1 : 0
      } 1 ${centerX + radius * Math.cos(endAngle)} ${centerY + radius * Math.sin(endAngle) 
      }`,
      {
        stroke: this.colcolcol,
        fill: "transparent",
        strokeWidth: 2,
        selectable: true,
        evented: true,
        // cornerSize: 7,
        padding: 6,
        // transparentCorners: false,
        // cornerColor: "white",
        // cornerStrokeColor: "#109CF1",
        // borderColor: "#109CF1",
        name: 'arc',
      }
    );


    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =100; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);


    arcPath.set({
      left: randomLeft-500,
      top: randomTop-200,
      originX: 'center',
      originY: 'center',
      name: 'arc',
    });
    arcPath.setControlsVisibility({
      tl: true,
      mt: true, // middle-top
      tr: true, //top-right
      ml: true, //middle-left
      mr: true, //middle-right
      bl: true, // bottom-left
      mb: true, //middle-bottom
      br: true,
      mtr: false,
    });

    this.canvas.add(arcPath);
    this.canvas.renderAll()
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(arcPath);
    this.sendObjToSocket()
  }

  insertPolygon() {
    this.canvas.isDrawingMode = false;
    const hexagon = new fabric.Polygon(
      [
        { x: 50, y: 0 },
        { x: 25, y: 43.3 },
        { x: -25, y: 43.301 },
        { x: -50, y: 0 },
        { x: -25, y: -43.301 },
        { x: 25, y: -43.301 },
      ],
      {
        left: 200,
        top: 200,
        fill: "transparent",
        stroke: this.colcolcol,
        strokeWidth: 2,
        // cornerSize: 7,
        padding: 6,
        // transparentCorners: false,
        // cornerColor: "white",
        // cornerStrokeColor: "#109CF1",
        // borderColor: "#109CF1",
        name: 'polygon'
      }
    );


    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =50; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    hexagon.set({
      left: randomLeft-300,
      top: randomTop-20,
      originX: 'center',
      originY: 'center',
      name: 'polygon'
    });
    hexagon.setControlsVisibility({
      tl: true,
      mt: true, // middle-top
      tr: true, //top-right
      ml: true, //middle-left
      mr: true, //middle-right
      bl: true, // bottom-left
      mb: true, //middle-bottom
      br: true,
      mtr: false, //bottom-right
    });
    this.canvas.add(hexagon);
    this.canvas.renderAll();
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(hexagon);
    this.sendObjToSocket()
  }

  // lastRectangle: fabric.Rect;
  // rectWidth = 100;
  // rectHeight = 100;
  // horizontalSpacing = 10;
  // verticalSpacing = 10;

  insertRectangle() {
    console.log("inside insert rectangle")
    let left = 300;
    let top = 300;

    // Calculate position for the new rectangle
    // if (this.lastRectangle) {
    //   left = this.lastRectangle.left + this.horizontalSpacing;
    //   top = this.lastRectangle.top-10;
    //   if (left + this.rectWidth > this.canvas.width) {
    //     left = 0;
    //     top += this.rectHeight + this.verticalSpacing;
    //   }
    //   console.log("left and top",left, top)
    // }
    // console.log("left and top",left, top)
    this.canvas.isDrawingMode = false;
    var rect = new fabric.Rect({
      width: 100,//this.rectWidth,
      height: 100, //this.rectHeight,
      //left: left,
      //top: top,
      fill: "transparent",
      stroke: this.colcolcol,
      strokeWidth: 2,
      // fill: 'red',
      // cornerSize: 7,
      padding: 6,
      name: 'rectangle'
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1"
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =50; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    rect.set({
      left: randomLeft-300,
      top: randomTop-100,
      originX: 'center',
      originY: 'center',
      name: 'rectangle'
    });

    rect.setControlsVisibility({
      tl: true,
      mt: true, // middle-top
      tr: true, //top-right
      ml: true, //middle-left
      mr: true, //middle-right
      bl: true, // bottom-left
      mb: true, //middle-bottom
      br: true,
      mtr: false, //bottom-right
    });
    this.canvas.add(rect);
    this.canvas.renderAll();
    this.saveShapeImgObj()
    //this.lastRectangle = rect;
    // this.canvas.setActiveObject(rect);
    this.sendObjToSocket()
  }

  insertRhombus() {
    this.canvas.isDrawingMode = false;
    const rhombus = new fabric.Polygon(
      [
        { x: 300, y: 50 },
        { x: 550, y: 50 },
        { x: 600, y: 180 },
        { x: 350, y: 180 },
      ],
      {
        fill: "transparent",
        // cornerSize: 7,
        padding: 6,
        stroke: this.colcolcol,
        strokeWidth: 2,
        name: 'rhombus'
        // transparentCorners: false,
        // cornerColor: "white",
        // cornerStrokeColor: "#109CF1",
        // borderColor: "#109CF1",
      }
    );

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =70; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    rhombus.set({
      left: randomLeft-300,
      top: randomTop-100,
      originX: 'center',
      originY: 'center',
      name: 'rhombus'
    });

    rhombus.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });
    this.canvas.add(rhombus);
    this.canvas.renderAll();
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(rhombus);
    this.sendObjToSocket()
  }

  insertCube() {
    this.canvas.isDrawingMode = false;

    const cubePath = `
  M 100 100
  L 200 100
  L 200 200
  L 100 200
  Z
  M 100 100
  L 150 50
  L 250 50
  L 200 100
  M 200 200
  L 250 150
  L 250 50
  M 250 150
  L 200 200
`;

    const cube = new fabric.Path(cubePath, {
      fill: "transparent",
      stroke: this.colcolcol,
      strokeWidth: 2,
      // cornerSize: 7,
      padding: 6,
      name: 'cube'
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });
    cube.setControlsVisibility({
      tl: true,
      mt: true, // middle-top
      tr: true, //top-right
      ml: true, //middle-left
      mr: true, //middle-right
      bl: true, // bottom-left
      mb: true, //middle-bottom
      br: true,
      mtr: false, //bottom-right
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =100; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    cube.set({
      left: randomLeft-400,
      top: randomTop,
      originX: 'center',
      originY: 'center',
      name: 'cube',
    });
    this.canvas.add(cube);
    this.canvas.renderAll();
    this.saveShapeImgObj();
    // this.canvas.setActiveObject(cube);
    this.sendObjToSocket()
  }

  insertStar() {
    this.canvas.isDrawingMode = false;
    const star = new fabric.Polygon(
      [
        { x: 349.9, y: 75 },
        { x: 379, y: 160.9 },
        { x: 469, y: 160.9 },
        { x: 397, y: 214.9 },
        { x: 423, y: 300.9 },
        { x: 350, y: 249.9 },
        { x: 276.9, y: 301 },
        { x: 303, y: 215 },
        { x: 231, y: 161 },
        { x: 321, y: 161 },
      ],
      {
        fill: "transparent",
        stroke: this.colcolcol,
        // cornerSize: 7,
        padding: 6,
        // transparentCorners: false,
        // cornerColor: "white",
        // cornerStrokeColor: "#109CF1",
        // borderColor: "#109CF1",
        strokeWidth:2,
        name: 'star',
      }
    );
    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =150; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    star.set({
      left: randomLeft-400,
      top: randomTop,
      originX: 'center',
      originY: 'center',
      name: 'star'
    });
    star.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });
    this.canvas.add(star);
    this.canvas.renderAll();
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(star);
    this.sendObjToSocket()
  }

  insertCircle() {
    this.canvas.isDrawingMode = false;
    var circle = new fabric.Circle({
      radius: 60,
      fill: "transparent",
      stroke: this.colcolcol,
      // cornerSize: 7,
      padding: 6,
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
      strokeWidth:2,
      name: 'circle'
    });

    circle.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =100; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    circle.set({
      left: randomLeft-500,
      top: randomTop,
      originX: 'center',
      originY: 'center',
      name: 'circle'
    });
    console.log("Circle added");
    this.canvas.add(circle);
    this.canvas.renderAll();
    this.saveShapeImgObj();
    // this.canvas.setActiveObject(circle);
    this.sendObjToSocket()
  }

  async insertHemisphereForStudent(value){
    const radius = 50;

    // const hemispherePath = `
    //   M 100 100
    //   A ${radius} ${radius} 0 1 1 200 100
    //   Q 150 140, 100 100
    //   z
    // `;
    // Q 130 120, 100 100

    const hemispherePath = `
    M 100 100
    A ${radius} ${radius} 1 1 0 200 100

    Q 150 70, 100 100
    Q 150 120, 200 100


    z
  `;

    const hemisphere = new fabric.Path(hemispherePath, {
      fill: "transparent",
      stroke: value.stroke,
      strokeWidth: 2,
      // angle: 180,
      // cornerSize: 7,
      padding: 6,
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });

    hemisphere.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });

    // Generate random left and top positions near the center but not at the corners
    // Set the origin of the prism to the center of the path
    hemisphere.set({
      left: value.left,
      top: value.top,
      originX: value.originX,
      originY: value.originY,
      name : 'hemisphere',
      selectable:value.selectable,
      id:value.id,
      angle: value.angle,
      scaleX: value.scaleX,
      scaleY: value.scaleY
    });

    return hemisphere
  }

  insertHemisphere(fromWhere) {
    this.canvas.isDrawingMode = false;

    const radius = 50;

    // const hemispherePath = `
    //   M 100 100
    //   A ${radius} ${radius} 0 1 1 200 100
    //   Q 150 140, 100 100
    //   z
    // `;
    // Q 130 120, 100 100

    const hemispherePath = `
    M 100 100
    A ${radius} ${radius} 1 1 0 200 100

    Q 150 70, 100 100
    Q 150 120, 200 100


    z
  `;

    const hemisphere = new fabric.Path(hemispherePath, {
      fill: "transparent",
      stroke: this.colcolcol,
      strokeWidth: 2,
      // angle: 180,
      // cornerSize: 7,
      padding: 6,
      name: 'hemisphere'
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });

    hemisphere.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =120; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    hemisphere.set({
      left: randomLeft-500,
      top: randomTop-100,
      originX: 'center',
      originY: 'center',
      name : 'hemisphere',
      id : Math.floor(100000 + Math.random() * 900000)
    });

    if(fromWhere == "kms") {
      hemisphere.fromServer = true;
    }
    else {
      hemisphere.fromServer = false;
    }

    this.canvas.add(hemisphere);
    this.canvas.renderAll();

    // this.canvas.setActiveObject(hemisphere);
    this.sendObjToSocket()
    this.saveShapeImgObj();
    console.log("Hemisphere added");
  }

  async insertSphereForStudent(object){
    const radius = 50;
    const spherePath = `
    M 100 ${100 - radius}
    A ${radius} ${radius} 0 1 0 100 ${100 + radius}
    A ${radius} ${radius} 0 1 0 100 ${100 - radius}
    M ${100 - radius} 100
    Q 100 ${100 + radius * 0.5} ${100 + radius} 100
  `;

    const sphere = new fabric.Path(spherePath, {
      fill: "transparent",
      stroke: object.stroke,
      strokeWidth: 3,
      // cornerSize: 7,
      padding: 6,
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });

    // Set the origin of the prism to the center of the path
    sphere.set({
      left: object.left,
      top: object.top,
      originX: object.originX,
      originY: object.originY,
      name:'sphere',
      selectable:object.selectable,
      id:object.id,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      angle: object.angle
    });


    sphere.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });

    return sphere

  }

  insertSphere(fromWhere) {
    this.canvas.isDrawingMode = false;

    const radius = 50;
    const spherePath = `
    M 100 ${100 - radius}
    A ${radius} ${radius} 0 1 0 100 ${100 + radius}
    A ${radius} ${radius} 0 1 0 100 ${100 - radius}
    M ${100 - radius} 100
    Q 100 ${100 + radius * 0.5} ${100 + radius} 100
  `;

    const sphere = new fabric.Path(spherePath, {
      fill: "transparent",
      stroke: this.colcolcol,
      strokeWidth: 2,
      // cornerSize: 7,
      padding: 6,
      name: 'sphere'
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });


    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =100; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    sphere.set({
      left: randomLeft-500,
      top: randomTop-200,
      originX: 'center',
      originY: 'center',
      name:'sphere',
      id:Math.floor(100000 + Math.random() * 900000)
    });


    sphere.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });

    if(fromWhere == "kms") {
      sphere.fromServer = true;
    }
    else {
      sphere.fromServer = false;
    }

    this.canvas.add(sphere);
    this.canvas.renderAll();
    this.saveShapeImgObj();
    // this.canvas.setActiveObject(sphere);
    console.log("Sphere added");
    this.sendObjToSocket()
  }


  insertCylinderForStudent(object) {
    this.canvas.isDrawingMode = false;
    const fill = "transparent";
    const border = object.stroke;
    const lineWidth = 2;
    // const cylinder = new fabric.Path(
    //   "M97.0,156.7 L97.0,17.7 L1.0,17.7 L1.0,156.7 L97.0,156.7 Z",
    //   {
    //     fill: fill,
    //     stroke: border,
    //     // strokeWidth: lineWidth,
    //     strokeLineCap: "round",
    //     strokeLineJoin: "round",
    //   }
    // );

    const topCurve = new fabric.Path(
      "M96.7,156.2 C96.7,165.3 75.4,172.7 49.0,172.7 C22.7,172.7 1.4,165.3 1.4,156.2 C1.4,147.2 22.7,139.8 49.0,139.8 C75.4,139.8 96.7,147.2 96.7,156.2 Z",
      {
        fill: fill,
        stroke: border,
        strokeLineCap: "round",
        strokeLineJoin: "round",
      }
    );

    const bottomCurve = new fabric.Path(
      "M96.7,17.4 C96.7,26.5 75.4,33.8 49.0,33.8 C22.7,33.8 1.4,26.5 1.4,17.4 C1.4,8.4 22.7,1.0 49.0,1.0 C75.4,1.0 96.7,8.4 96.7,17.4 Z",
      {
        fill: fill,
        stroke: border,
        strokeLineCap: "round",
        strokeLineJoin: "round",
      }
    );

    const leftLine = new fabric.Path('M16.7,40 L16.7,180', {
      left: 0,
      top: 17,
      stroke: border,
      strokeWidth: 2
    });

    const rightLine = new fabric.Path('M30,40 L30,180', {
      left: 97,
      top: 17,
      stroke: border,
      strokeWidth: 2
    });

    const cylinderGroup = new fabric.Group([leftLine, rightLine, topCurve, bottomCurve], {
      selectable: true,
      left: 300,
      top: 300,
      // cornerSize: 7,
      padding: 6,
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });
    cylinderGroup.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });

    // Set the origin of the prism to the center of the path
    cylinderGroup.set({
      left: object.left,
      top: object.top,
      originX: 'center',
      originY: 'center',
      name : 'cylinder',
      selectable:object.selectable,
      id:object.id
    });

    return cylinderGroup
  }

  insertCylinder(fromWhere) {
    this.canvas.isDrawingMode = false;
    const fill = "transparent";
    const border = this.colcolcol;
    const lineWidth = 2;
    // const cylinder = new fabric.Path(
    //   "M97.0,156.7 L97.0,17.7 L1.0,17.7 L1.0,156.7 L97.0,156.7 Z",
    //   {
    //     fill: '',
    //     stroke: border,
    //     // strokeWidth: lineWidth,
    //     strokeLineCap: "round",
    //     strokeLineJoin: "round",
    //   }
    // );

    const topCurve = new fabric.Path(
      "M96.7,156.2 C96.7,165.3 75.4,172.7 49.0,172.7 C22.7,172.7 1.4,165.3 1.4,156.2 C1.4,147.2 22.7,139.8 49.0,139.8 C75.4,139.8 96.7,147.2 96.7,156.2 Z",
      {
         fill: '',
        stroke: border,
        strokeLineCap: "round",
        strokeLineJoin: "round",
        strokeWidth: 2
      }
    );

    const bottomCurve = new fabric.Path(
      "M96.7,17.4 C96.7,26.5 75.4,33.8 49.0,33.8 C22.7,33.8 1.4,26.5 1.4,17.4 C1.4,8.4 22.7,1.0 49.0,1.0 C75.4,1.0 96.7,8.4 96.7,17.4 Z",
      {
        fill: '',
        stroke: border,
        strokeLineCap: "round",
        strokeLineJoin: "round",
        strokeWidth: 2
      }
    );

    const leftLine = new fabric.Path('M16.7,40 L16.7,180', {
      left: 0,
      top: 17,
      stroke: border,
      strokeWidth: 2
    });

    const rightLine = new fabric.Path('M30,40 L30,180', {
      left: 97,
      top: 17,
      stroke: border,
      strokeWidth: 2
    });

    const cylinderGroup = new fabric.Group([leftLine, rightLine, topCurve, bottomCurve], {
      selectable: true,
      left: 300,
      top: 300,
      // cornerSize: 7,
      padding: 6,
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
      name : 'cylinder',
    });
    cylinderGroup.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =100; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    cylinderGroup.set({
      left: randomLeft-500,
      top: randomTop,
      originX: 'center',
      originY: 'center',
      name : 'cylinder',
      id:Math.floor(100000 + Math.random() * 900000)
    });

    if(fromWhere == "kms") {
      cylinderGroup.fromServer = true;
    }
    else {
      cylinderGroup.fromServer = false;
    }
    this.canvas.add(cylinderGroup);
    this.canvas.renderAll();
    this.saveShapeImgObj();
    // this.canvas.setActiveObject(cylinderGroup);
    this.sendObjToSocket()
  }

  insertTriangle() {
    this.canvas.isDrawingMode = false;
    const triangle = new fabric.Triangle({
      width: 100,
      height: 100,
      stroke: this.colcolcol,
      fill: "transparent",
      // cornerSize: 7,
      padding: 6,
      name: 'triangle',
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
      strokeWidth:2
    });

    triangle.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =50; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    triangle.set({
      left: randomLeft-400,
      top: randomTop,
      originX: 'center',
      originY: 'center',
      name: 'triangle',
    });
    this.canvas.add(triangle);
    this.canvas.renderAll()
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(triangle);
    this.sendObjToSocket()
  }

  insertRightTriangle() {
    this.canvas.isDrawingMode = false;

    const base = 100;
    const height = 80;

    const trianglePath = `
    M 100 100
    L 100 ${100 - base}
    L ${100 + height} 100
    Z
  `;

    const rightTriangle = new fabric.Path(trianglePath, {
      fill: "transparent",
      stroke: this.colcolcol,
      strokeWidth: 2,
      // cornerSize: 7,
      padding: 6,
      name: 'righttriangle',
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });
    rightTriangle.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });
    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =100; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    rightTriangle.set({
      left: randomLeft-500,
      top: randomTop,
      originX: 'center',
      originY: 'center',
      name: 'righttriangle',
    });
    this.canvas.add(rightTriangle);
    this.canvas.renderAll();
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(rightTriangle);
    this.sendObjToSocket()
  }

  insertPyramid() {
    this.canvas.isDrawingMode = false;
    console.log("insertPyramid working");

    const pyramidPath = `
      M 100 130
      L 100 0
      M 100 0
      L 30 100
      M 100 130
      L 30 100
      M 175 100
      L 100 130
      M 100 0
      L 175 100


  `;

    const pyramid = new fabric.Path(pyramidPath, {
      fill: "transparent",
      stroke: this.colcolcol,
      strokeWidth: 2,
      // cornerSize: 7,
      padding: 6,
      name: 'pyramid'
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =100; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    pyramid.set({
      left: randomLeft-500,
      top: randomTop,
      originX: 'center',
      originY: 'center',
      name: 'pyramid'
    });
    pyramid.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });
    this.canvas.add(pyramid);
    this.canvas.renderAll();
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(pyramid);
    this.sendObjToSocket()
    console.log("Pyramid added", pyramid);
  }

  insertPrism() {
    this.canvas.isDrawingMode = false;
    console.log("insertPyramid working");

    const prismPath = `
      M 100 50
      L 20 0
      M 160 0
      L 20 0
      M 20 160
      L 21 0
      M 100 210
      L 20 160
      M 100 211
      L 100 50
      M 161 0
      L 100 50
      M 160 160
      L 161 0
      M 101 210
      L 160 160

  `;

    const prism = new fabric.Path(prismPath, {
      fill: "transparent",
      stroke: this.colcolcol,
      strokeWidth: 2,
      // cornerSize: 7,
      padding: 6,
      name: 'prism',
      // transparentCorners: false,
      // cornerColor: "white",
      // cornerStrokeColor: "#109CF1",
      // borderColor: "#109CF1",
    });

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2
    };

    // Define a range around the center where the prism can be placed
    const range =50; // Adjust this value as needed

    // Generate random left and top positions near the center but not at the corners
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    // Set the origin of the prism to the center of the path
    prism.set({
      left: randomLeft-200,
      top: randomTop,
      originX: 'center',
      originY: 'center',
      name: 'prism',
    });

    prism.setControlsVisibility({
      tl: true,
      mt: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      mb: true,
      br: true,
      mtr: false,
    });

    this.canvas.add(prism);
    this.canvas.renderAll()
    this.sendObjToSocket();
    this.saveShapeImgObj()
    // this.canvas.setActiveObject(prism);
    console.log("Prism added");
  }

  async viewObject(){
    let canvasObjects = JSON.parse(localStorage.getItem('currentCanvas'))["objects"]
    const canvasObjLen = canvasObjects.length-1
    const objects =  this.canvas.getObjects()
    const objectLen =  objects.length-1
    if(canvasObjects[canvasObjLen].id == objects[objectLen].id){
      return true
    }
    else{
      return false
    }
    // if(this.canvas._objects.length > 0 && canvasObjects.length > 0){
    //   if((this.canvas._objects[this.canvas._objects.length - 1].id && canvasObjects[canvasObjects.length-1].id) &&
    //     (this.canvas._objects[this.canvas._objects.length - 1].id == canvasObjects[canvasObjects.length-1].id)
    //     ){
    //     return true
    //   }
    //   else{
    //     false
    //   }
    // }
    // else{
    //   false
    // }
  }

  async lastObjectTextOrNot(){
    let objects = this.canvas.getObjects();
    let totalLength = objects.length-1;
    if(this.canvas.getActiveObject()){
      return false
    }
    else{
      if (objects.length >= 0) {
        console.log("my text logs",this.canvas.getObjects()[totalLength])
        if(this.canvas.getObjects()[totalLength].type == 'i-text'  && this.canvas.getObjects()[totalLength].text != ''){
          return true
        }
        else{
          return false
        }
      }
      else{
        return false
      }
    }

  }

  async addObjectToSocket(savedCanvasData){
    let objects = this.canvas.getObjects();
    let totalLength = objects.length-1;
    if (totalLength > 0) {
      // if(this.DuplObj){

      // }
      // else if(this.canvas.getObjects()[totalLength] && !this.canvas.getObjects()[totalLength].id) {
      //     console.log("send object to socket 4.33333333")
      //     this.canvas.getObjects()[totalLength].id = Math.floor(100000 + Math.random() * 900000);
      // }
      // if(!this.canvas.getObjects()[totalLength].id){
      //     this.canvas.getObjects()[totalLength].id = Math.floor(100000 + Math.random() * 900000);
      // }
      savedCanvasData.selected_object = this.canvas.getObjects()[totalLength].toJSON(['id'],['ownerType']);
      savedCanvasData.selected_object.action_type = 'add';
       if ('src' in savedCanvasData.selected_object && (savedCanvasData.selected_object.src.includes("protractor") || savedCanvasData.selected_object.src.includes("ruler")
            || savedCanvasData.selected_object.src.includes("setSquare"))) {
        console.log("Object with 'protractor' or 'ruler' in src is skipped");
        return;
        }
        if (savedCanvasData.selected_object.type === 'group') {
          console.log("Group type objects are skipped");
          return;
        }
        console.log("send object to socket 5555555555")
      // if(JSON.parse(localStorage.getItem('currentCanvas'))["objects"].length != this.canvas._objects.length){
        let json = JSON.stringify(this.canvas.toJSON(['id'],['ownerType']));
        console.log("sameerrrrrrrrrrrrr let json")
        json = JSON.parse(json)
        let len = json["objects"].length-1
        if(localStorage.getItem('userId')){
          savedCanvasData.selected_object.ownerType = 'teacher'
          json["objects"][len]["ownerType"] = 'teacher'
        }
        else{
          savedCanvasData.selected_object.ownerType = 'student'
          json["objects"][len]["ownerType"] = 'student'
        }
        let canvasObjects = JSON.parse(localStorage.getItem('currentCanvas'))["objects"]
        canvasObjects.push(savedCanvasData.selected_object)
        // let canvasObj = {
        //   objects: canvasObjects,
        //   version: "5.2.1",
        // };
        // localStorage.setItem('currentCanvas', JSON.stringify(canvasObj));
        console.log("send object to socket 66666")
        this.setCanvasLocalStrValue(canvasObjects)

      this.executeBool = true
    }
    else{
      if(!this.canvas.getObjects()[totalLength].id){
        this.canvas.getObjects()[totalLength].id = Math.floor(100000 + Math.random() * 900000);
      }
      savedCanvasData.selected_object = this.canvas.getObjects()[0].toJSON(['id'],['ownerType']);
      savedCanvasData.selected_object.action_type = 'add';
      this.executeBool = true
    }

  }

  async deleteObjectToSocket(savedCanvasData){
    if(this.EraseCanvas.select){
      if(this.EraseCanvas.eraseAll){
        savedCanvasData.selected_object = {action_type:'eraseAll'}
        // this.canvas.remove(this.canvas.getActiveObject())
        // this.canvas.renderAll()
        this.eraseCanvasChange()
      }
      // else if(this.EraseCanvas.onTouch){
      //   let activeObj = this.canvas.getActiveObject().toJSON(['id']);
      //   let canvasInd = await this.getIndexOfObject(activeObj.id)
      //   if(Number(canvasInd) >= 0){
      //     await this.updateCanvasSingleObj(activeObj,canvasInd,'delete')
      //   }
      //   savedCanvasData.selected_object = activeObj
      //   savedCanvasData.selected_object.action_type = 'delete';
      //   this.canvas.remove(this.canvas.getActiveObject())
      //   this.canvas.renderAll()
      // }
      // else if(this.EraseCanvas.context){
      //   let activeObj = this.canvas.getActiveObject().toJSON(['id']);
      //   let canvasInd = await this.getIndexOfObject(activeObj.id)
      //   if(Number(canvasInd) >= 0){
      //     await this.updateCanvasSingleObj(activeObj,canvasInd,'delete')
      //   }
      //   savedCanvasData.selected_object = activeObj
      //   savedCanvasData.selected_object.action_type = 'delete';
      //   this.isTextSelected = false;
      //   this.canvas.remove(this.canvas.getActiveObject())
      //   this.canvas.renderAll()
      //   this.eraseCanvasChange()
      // }
      // else if(this.EraseCanvas.select_area){
      //   let highliteObj = this.canvas._objects[this.canvas._objects.length-1]
      //   const objectsWithinHeightRange = this.getObjectsWithinAreaRange(highliteObj,highliteObj.top,highliteObj.top+highliteObj.height,highliteObj.left,highliteObj.left+highliteObj.width);
      //   // let activeObjects = this.canvas.getActiveObjects()
      //   this.canvas.remove(...objectsWithinHeightRange);
      //   this.canvas.discardActiveObject(); // Deselect all active objects
      //   this.canvas.renderAll();
      // }
      // else if(this.EraseCanvas.point){
      //   // let activeObj = this.canvas.getActiveObject().toJSON(['id']);
      //   // savedCanvasData.selected_object = activeObj
      //   const serializedObjects = this.canvas.toJSON(['id']);
      //   savedCanvasData.selected_object = serializedObjects
      //   savedCanvasData.action_type = 'point_delete';
      //   this.eraseCanvasChange()
      // }
      this.executeBool = true
    }
  }

  async editObjectToSocket(savedCanvasData){
      let activeObj = this.canvas.getActiveObject().toJSON(['id']);
      if(activeObj){
         let canvasInd = await this.getIndexOfObject(activeObj.id)
          if(Number(canvasInd) >= 0){
            await this.updateCanvasSingleObj(activeObj,canvasInd,'edit')
          }
          savedCanvasData.selected_object = activeObj;
          savedCanvasData.selected_object.action_type = 'edit';
          this.executeBool = true
      }
  }


  // ____________________cloudeSync_____________________

  async saveCanvasDataToSocket() {
//undo redo purpose - start
  //  await this.handleMovedUndoRedoObj()
  //  await this.changeObjectSerial()
   //undo redo purpose - end

    // Retrieve the savedCanvasData from local storage
    let savedCanvasData = JSON.parse(localStorage.getItem('currentCanvas'));
    const fileId = localStorage.getItem('FileID');
    const totalPage = localStorage.getItem('totalPages');
    const selectedPage = localStorage.getItem("currentPage");
    savedCanvasData.selected_object = null;
    this.executeBool = false
    let activeObject=null
    let addText=null

      //add text - start
      try{
        addText = await this.lastObjectTextOrNot()
      }
      catch{
        addText = false
      }
      if(addText && !this.executeBool){
         await this.addObjectToSocket(savedCanvasData)
      }
      //add text - end








      //send front back undo redo filp - start - IMP
      // if(this.editObjectType.select){
      //   savedCanvasData.selected_object = this.canvas.getActiveObject().toJSON(['id'],['ownerType']);
      //   if(this.editObjectType.bringFront){
      //     savedCanvasData.selected_object.action_type = 'bringFront'
      //   }
      //   else if(this.editObjectType.sendBack){
      //     savedCanvasData.selected_object.action_type = 'sendBack'
      //   }
      // }
      // this.resetEditObj()
     //send front back undo redo filp - end - IMP

    // Check if all conditions are met before calling updateCanvas
    if((this.avChatStarted() || (!this.isLoginByTeacher()))){
    const isTextActive = await this.isTextActive(activeObject)
    if ((this.executeBool) && (!isTextActive) && (addText || this.EraseCanvas.select)) {
      // Call the service to update the canvas data in MongoDB
      if(!savedCanvasData.selected_object){
        savedCanvasData.selected_object = {
          mainLessonId : null,
          pageNo : null,
          fileId:null,
          totalPage:null,
          selectedPage:null
        }
      }
      // Call the service to update the canvas data in MongoDB

      let currentObj = {
        object : savedCanvasData.selected_object,
        pageNo:localStorage.getItem('currentPage'),
        action_type : savedCanvasData.selected_object.action_type,
        mainLessonId:localStorage.getItem('mainlessonID')
      }
          this.fileservice.emitWebSocketEvent('addObject',currentObj);
          // const objects = this.canvas.getObjects();
          // let jsonObjects = objects.map(obj => obj.toJSON(['id']));
          // let canvasObjects = jsonObjects
          // if(this.compassFullCircle){
          //   canvasObjects = this.removeDuplicatesAndGetDeletedIds(canvasObjects, 'id')
          //   const indexToRemove = jsonObjects.findIndex(item => item.id === canvasObjects.deletedIds[0]);
          //   this.canvas.remove(this.canvas._objects[indexToRemove])
          //   jsonObjects = this.removeDuplicates(jsonObjects,"id")
          // }
          // this.compassFullCircle = false
          // this.setCanvasLocalStrValue(jsonObjects)
    }
  }
  else{
    const objects = this.canvas.getObjects();
    let jsonObjects = objects.map(obj => obj.toJSON(['id']));
    let canvasObjects = jsonObjects
    if(this.compassFullCircle){
      canvasObjects = this.removeDuplicatesAndGetDeletedIds(canvasObjects, 'id')
      const indexToRemove = jsonObjects.findIndex(item => item.id === canvasObjects.deletedIds[0]);
      this.canvas.remove(this.canvas._objects[indexToRemove])
      jsonObjects = this.removeDuplicates(jsonObjects,"id")
    }
    this.compassFullCircle = false
    this.setCanvasLocalStrValue(jsonObjects)
  }

  }

  removeObjectById(canvas, objectId) {
    const objectToRemove = canvas.getObjectById(objectId);
    if (objectToRemove) {
      canvas.remove(objectToRemove);
      canvas.renderAll(); // Render the canvas after removing the object
      return true; // Object successfully removed
    } else {
      return false; // Object with given ID not found
    }
  }

  removeDuplicatesAndGetDeletedIds(array, property) {
    const map = new Map();
    const deletedIds = [];

    array.forEach(obj => {
      if (!map.has(obj[property])) {
        map.set(obj[property], obj);
      } else {
        // Object with same property already exists, so mark its ID for deletion
        deletedIds.push(obj.id);
      }
    });

    const uniqueObjects = Array.from(map.values());
    return { uniqueObjects, deletedIds };
  }

  removeDuplicates(array, property) {
    return array.filter((obj, index, self) =>
      index === self.findIndex((o) => o[property] === obj[property])
    );
  }

  removetools(savedCanvasData){
    console.log("inside remove tool in localstorage",savedCanvasData)

  }

  async addEditTextBox(savedCanvasData){
    let editTextBox = false
    if(this.canvas._objects.length > 0 &&
       this.canvas._objects[this.canvas._objects.length-1].type == 'i-text' &&
       !this.canvas._objects[this.canvas._objects.length-1].id)
       {
        if(this.canvas._objects[this.canvas._objects.length-1].text.length > 0){
          editTextBox = true
          this.canvas._objects[this.canvas._objects.length - 1].id = Math.floor(100000 + Math.random() * 900000);
          savedCanvasData.selected_object = this.canvas._objects[this.canvas._objects.length - 1].toJSON(['id'],['ownerType']);
          savedCanvasData.selected_object.action_type = 'add';
          savedCanvasData.selected_object.mainLessonId = localStorage.getItem('mainlessonID')
          savedCanvasData.selected_object.ownerType = 'teacher'
        }
    }
    else if(this.previousActObj && this.previousActObj.type == 'i-text' && !editTextBox){
      savedCanvasData.selected_object = this.previousActObj
      savedCanvasData.selected_object.action_type = 'edit';
      savedCanvasData.selected_object.mainLessonId = localStorage.getItem('mainlessonID')
      savedCanvasData.selected_object.ownerType = 'teacher'
      // await this.editObjectToSocket(savedCanvasData)
    }
    if(this.canvas.getActiveObject()){
      this.previousActObj = this.canvas.getActiveObject().toJSON(['id'],['ownerType'])
    }
    return savedCanvasData
  }

  async saveCanvasDataToMongoDB(){
    this.colorPickerVisible = false;
    this.isTextSelected = false;
    this.contextBoxVisible = false;
    let fileId = localStorage.getItem('FileID');
    let selectedPage = localStorage.getItem("currentPage");
    let canvasObjects = []
    canvasObjects = this.canvas.getObjects().map(obj => obj.toJSON(['id', 'name', 'ownerType', 'hasBorders', 'hasControls']));
    if (canvasObjects && canvasObjects.length > 0) {
      canvasObjects = this.removeDuplicates(canvasObjects, "id");
    }
      canvasObjects.map(async (object: any, index: number) => {
        if (object.type === 'image') {
            if ('src' in object && (object.src.includes("protractor") || object.src.includes("ruler") || object.src.includes("14cmScale1") || object.src.includes("setSquare"))) {
                console.log("Object with 'protractor', 'ruler', or 'setSquare' in src is skipped");
                canvasObjects.splice(index, 1);
                return;
            }
        }
        if (object.type === 'group' && object.object && object.object.length == 5) {
            canvasObjects.splice(index, 1);
            console.log("Group type objects are skipped");
            return;
        }
     })
      let canvasData = {
        mainLessonId : localStorage.getItem('mainlessonID'),
        fileId:fileId,
        selectedPage:selectedPage,
        objects:canvasObjects,
        bgColor: this.canvas.backgroundColor,
        bgFrame: '',
        customBg: '',
        pageWidth : this.browserDetectionService.getBrowserInfo().canvasWidth,
        pageHeight: this.browserDetectionService.getBrowserInfo().canvasHeight
      }
      if (this.canvas.backgroundImage != null) {
        let bg = this.canvas.backgroundImage.getSrc();
        bg = bg.replace(/^.*\/assets/, "assets");
        bg.startsWith('assets') ? canvasData.bgFrame = bg : canvasData.customBg = bg;
      }
      console.log("01-11-2024 saveCanvasDataToMongoDB",canvasData);
      this.fileservice.updateCanvas(canvasData).subscribe({
        next: (response) => {
          let pageNo = Number(localStorage.getItem('currentCanvas'))
          // setTimeout(() => {
            let scaledPages = this.scalePages(response.updatedCanvasFile.Pages);
            console.log("DEBUGG: saveToMongo triggered",scaledPages);
            localStorage.setItem('savedCanvasData', JSON.stringify(scaledPages));
            this.updateCurrentCanvasCache();
            // const pageNo = Number(localStorage.getItem('currentPage')) -1
            // this.fileservice.loadThumbnail.next("yes")
          //  }, 350);
          // Handle the successful response here
        },
        error: (error) => {
        }
      });
  }


  undoForStudent(pageOperationsList,removedOperationsList,sender_canvasWidth,sender_canvasHeight) {
    this.UndoRedoService.setpageOperationsList(pageOperationsList);
    this.UndoRedoService.setremovedOperationsList(removedOperationsList);
    this.undo(sender_canvasWidth,sender_canvasHeight);
  }

  lockUndo: Boolean = false;
  lockRedo: Boolean = false;
  async undo(sender_canvasWidth,sender_canvasHeight) {
    if(this.lockUndo || this.lockRedo) {
      return;
    }
    this.lockUndo = true;
    this.lockRedo = true;
    this.fileservice.updatePageChangeStatus(this.currentPageNumber,true);
    if (this.avChatStarted() && this.isLoginByTeacher()) {
      console.log("Venki: avChat ON");
      this.fileservice.sendUndoEventToSocket(this.UndoRedoService.getpageOperationsList(),
        this.UndoRedoService.getremovedOperationsList(),
        this.browserDetectionService.getBrowserInfo().canvasWidth,
        this.browserDetectionService.getBrowserInfo().canvasHeight
      );
    }
    try {
      if (this.rulerImage) {
        this.canvas.remove(this.rulerImage);
        this.rulerImage = null;
        this.deleteRuler.emit(false);
        this.canvas.clearContext(this.canvas.contextTop);
      }
      if (this.setSquareImage) {
        this.canvas.remove(this.setSquareImage);
        this.setSquareImage = null;
        this.deleteSetsquare.emit(false);
      }
      if (this.protractorImage) {
        this.canvas.remove(this.protractorImage);
        this.protractorImage = null;
        this.deleteProtractor.emit(false);
      }
      if (this.groupleft) {
        this.canvas.remove(this.groupleft, this.groupright, this.info, this.iconPenImage);
        this.removeCompassGroup()
        this.removeCompassGroupLocalStr()
        this.compassInserted = false;
        this.compassInsertedInfo = false;
        this.showAngleInputDialog = false;
        this.buttonLeft = 100 + this.sideGreyAreaWidth;
        this.buttonTop = 50;
        this.buttonRotate = 0;
        // this.degreeInput = 40;
        // this.setAngle = 40;
        this.radius = 203;
        this.displayRadius = 30;
        this.selectedNumber = null;
        console.log('checking remove log of compass', this.canvas.remove(this.groupleft, this.groupright, this.info));
        this.groupleft.hasControls = false;
        this.deleteCompass.emit(false);
      }
      this.isToolsOpen = false;
    }
    catch(error) {
      console.log(error);
    }

    this.canvas.__eventListeners = {};//The event listeners have to turned off before doing undo operation since undo operation
    this.canvas.selection = true;
    this.canvas.forEachObject((object) => {
      object.set({ selectable: true });
      object.set({ lockMovementX: false });
      object.set({ lockMovementY: false });
      object.set({ lockScalingX: false  });
      object.set({ lockRotation: false });
      object.setCoords();
    });

    let objToBeAdded = this.UndoRedoService.undo();
    let test = this.UndoRedoService.isPageOperationsListEmpty();
    this.undoStatusChanged.emit(test);
    console.log("DEBUGG: undo",test);
    let test2=this.UndoRedoService.isRemovedOperationsListEmpty();
    this.redoStatusChanged.emit(test2);
    if(objToBeAdded == null) {
      this.addAddModRemFabricListeners();
      this.lockUndo = false;
      this.lockRedo = false;
    }
    else if(objToBeAdded.type == 'fabricObject'){
      this.addObjectUndoRedo(objToBeAdded.oldState,sender_canvasWidth,sender_canvasHeight);
    }
    else if(objToBeAdded.type == 'background') {
      if(objToBeAdded.oldState.bgImg != '') {
        this.addBGImageOnCanvas(objToBeAdded.oldState.bgImg,'',false);//just add bgImg
      }
      else {
        this.calltoggleFrame.emit({frame:objToBeAdded.oldState.bgFrame});
        this.callapplyBgColor.emit({index:objToBeAdded.oldState.bgColor});
      }
      this.lockUndo = false;
      this.lockRedo = false;
    }
    this.eventsForCornerFuncOn();
    return;
  }

  getLatestBgColor(objects) {
    for (let i = objects.length - 1; i >= 0; i--) {
      if (objects[i].bgColor) {
        return objects[i].bgColor;
      }
    }
    return null; // If no object with bgColor is found
  }

  getLatestBgFrameWithId(objects){
    for (let i = objects.length - 1; i >= 0; i--) {
      if (objects[i].bgFrame) {
        return objects[i];
      }
    }
    return null;
  }

  getLatestBgFrame(objects) {
    for (let i = objects.length - 1; i >= 0; i--) {
      if (objects[i].bgFrame) {
        return objects[i].bgFrame;
      }
    }
    return null; // If no object with bgColor is found
  }

  redoForStudent(pageOperationsList,removedOperationsList,sender_canvasWidth,sender_canvasHeight) {
    this.UndoRedoService.setpageOperationsList(pageOperationsList);
    this.UndoRedoService.setremovedOperationsList(removedOperationsList);
    this.redo(sender_canvasWidth,sender_canvasHeight);
  }

  redo(sender_canvasWidth,sender_canvasHeight) {
    if(this.lockUndo || this.lockRedo) {
      return;
    }
    this.lockUndo = true;
    this.lockRedo = true;
    this.fileservice.updatePageChangeStatus(this.currentPageNumber,true);
    if(this.avChatStarted() && this.isLoginByTeacher()) {
      this.fileservice.sendRedoEventToSocket(this.UndoRedoService.getpageOperationsList(),
      this.UndoRedoService.getremovedOperationsList(),
      this.browserDetectionService.getBrowserInfo().canvasWidth,
      this.browserDetectionService.getBrowserInfo().canvasHeight);
    }
    if (this.rulerImage) {
      this.canvas.remove(this.rulerImage);
      this.rulerImage = null;
      this.deleteRuler.emit(false);
    }
    if (this.setSquareImage) {
      this.canvas.remove(this.setSquareImage);
      this.setSquareImage = null;
      this.deleteSetsquare.emit(false);
    }
    if (this.protractorImage) {
      this.canvas.remove(this.protractorImage);
      this.protractorImage = null;
      this.deleteProtractor.emit(false);
    }
    if (this.groupleft) {
      this.canvas.remove(this.groupleft, this.groupright, this.info, this.iconPenImage);
      this.removeCompassGroup()
      this.removeCompassGroupLocalStr()
      console.log('checking remove log of compass', this.canvas.remove(this.groupleft, this.groupright, this.info));
      this.groupleft.hasControls = false;
      this.deleteCompass.emit(false);
    }
    this.isToolsOpen = false;

    this.canvas.__eventListeners = {};
    this.canvas.selection = true;
    this.canvas.forEachObject((object) => {
      object.set({ selectable: true });
      object.set({ lockMovementX: false });
      object.set({ lockMovementY: false });
      object.set({ lockScalingX: false  });
      object.set({ lockRotation: false });
      object.setCoords();
    });
    let objToBeAdded = this.UndoRedoService.redo();
    let test = this.UndoRedoService.isPageOperationsListEmpty();
    this.undoStatusChanged.emit(test);
    let test2=this.UndoRedoService.isRemovedOperationsListEmpty();
    this.redoStatusChanged.emit(test2);
    if(objToBeAdded == null) {
      this.addAddModRemFabricListeners();
      this.lockUndo = false;
      this.lockRedo = false;
    }
    else if(objToBeAdded.type == 'fabricObject'){
      this.addObjectUndoRedo(objToBeAdded.newState,sender_canvasWidth,sender_canvasHeight);
    }
    else if(objToBeAdded.type == 'background') {
      if(objToBeAdded.newState.bgImg != '') {
        this.addBGImageOnCanvas(objToBeAdded.newState.bgImg,'',false);//just add bgImg
      }
      else {
        this.calltoggleFrame.emit({frame:objToBeAdded.newState.bgFrame});
        this.callapplyBgColor.emit({index:objToBeAdded.newState.bgColor});
      }
      this.lockUndo = false;
      this.lockRedo = false;
    }
    this.eventsForCornerFuncOn();
    return;
  }




  EditCanvas(value) {
    // this.canvas.allowTouchScrolling = value
    this.canvas.isDrawingMode = true
    // this.canvas.forEachObject((object) => {
    //   object.set({ selectable: value });
    //   object.set({ lockMovementX: value });
    //   object.set({ lockMovementY: value });
    //   object.set({ lockScalingX: value });
    //   object.set({ lockRotation: value });
    // });
    this.canvas.renderAll()
  }

  addImageVotingOnCanvas(url, TotalVotes) {

    var totalVoteText = new fabric.Text(`Total Vote`, {
      fontSize: 20,
      left: 100,
      top: 10,
    });

    var totalVotes = new fabric.Text(`${TotalVotes.length}`, {
      fontSize: 20,
      left: 220,
      top: 10,
      fontWeight: "bold",
    });

    if (url) {
      fabric.Image.fromURL(url, (image) => {
        image.set({
          left: 10,
          top: 100,
          angle: 0,
        });

        const groupVote = new fabric.Group([totalVoteText, totalVotes, image], {
          top: 0,
          left: 160,
          scaleX: 0.8,
          scaleY: 0.8,
          excludeFromExport: true,
          name: "groupVote",
          // cornerSize: 7,
          padding: 6,
          // transparentCorners: false,
          // cornerColor: "white",
          // cornerStrokeColor: "lightblue",
        });
        groupVote.setControlsVisibility({
          tl: true,
          mt: true,
          tr: true,
          ml: true,
          mr: true,
          bl: true,
          mb: true,
          br: true,
          mtr: false,
        });

        // image.scaleToWidth(200);
        // image.scaleToHeight(200); //not neecded
        //this.extend(image, this.randomId());
        this.canvas.add(groupVote);
        this.selectItemAfterAdded(image);
      });
    }
  }


  addImageTostudentVotingOnCanvas(url, TotalVotes,obj) {

    var totalVoteText = new fabric.Text(`Total Vote`, {
      fontSize: 20,
      left: 100,
      top: 10,
    });

    var totalVotes = new fabric.Text(`${TotalVotes}`, {
      fontSize: 20,
      left: 220,
      top: 10,
      fontWeight: "bold",
    });

    if (url) {
      fabric.Image.fromURL(url, (image) => {
        image.set({
          left: 10,
          top: 100,
          angle: 0,
        });

        const groupVote = new fabric.Group([totalVoteText, totalVotes, image], {
          top: 0,
          left: 160,
          scaleX: 0.8,
          scaleY: 0.8,
          excludeFromExport: true,
          name: "groupVote",
          // cornerSize: 7,
          padding: 6,
          // transparentCorners: false,
          // cornerColor: "white",
          // cornerStrokeColor: "lightblue",
          id:obj.id
        });
        groupVote.setControlsVisibility({
          tl: true,
          mt: true,
          tr: true,
          ml: true,
          mr: true,
          bl: true,
          mb: true,
          br: true,
          mtr: false,
        });

        // image.scaleToWidth(200);
        // image.scaleToHeight(200);
        //this.extend(image, this.randomId());
        this.canvas.add(groupVote);
        this.selectItemAfterAdded(image);
      });
    }
  }

  async updateObject(objNewState,objToBeModified,sender_canvasWidth:number,sender_canvasHeight:number) {
    console.log("Debugg updateObject",objNewState);
    let Xt = this.browserDetectionService.getBrowserInfo().canvasWidth/sender_canvasWidth;
    let Yt = this.browserDetectionService.getBrowserInfo().canvasHeight/sender_canvasHeight;

    if(objNewState.SendBackObject){
      this.canvas.sendToBack(objToBeModified);
    }
    else if(objNewState.BringFrontObject){
      this.canvas.bringToFront(objToBeModified);
    }

    objToBeModified.set(
      objNewState
      );
      objToBeModified.set({fromServer:false});//needs to be set to false since obj:mod will not be triggered and fromServer will be true.
      objToBeModified.set({
        left:(objNewState.left)*Xt,
        top: (objNewState.top)*Yt,
        scaleX: (objNewState.scaleX) * Xt,
        scaleY: (objNewState.scaleY) * Yt
      })
      if ((objToBeModified.type === 'image') && ((objNewState.width * objNewState.scaleX) >= this.ThumbnailReplacerService.THRESHOLD )) {
        // await new Promise<void>((resolve) => {
        //   objToBeModified.setSrc(objNewState.src, (img) => {
        //     img.setCoords();
        //     resolve();
        //   });
        // });
        // this.canvas.renderAll();
        this.ThumbnailReplacerService.imageIdsList_push(objToBeModified.id);
        this.ThumbnailReplacerService.startExecutionOfTasks();
      }
      this.UndoRedoService.pushOperationIntopageOperationsList({type:'mod',newState:objToBeModified,oldState:objNewState.oldState});
      objToBeModified.setCoords();
      this.canvas.requestRenderAll();
   }

   async addImgObject(obj,sender_canvasWidth,sender_canvasHeight) {
    let val;
    let Xt = this.browserDetectionService.getBrowserInfo().canvasWidth/sender_canvasWidth;
    let Yt = this.browserDetectionService.getBrowserInfo().canvasHeight/sender_canvasHeight;
    val = fabric.Image.fromURL(obj.src, (image) => {
          try {
            image.set({
              left: obj.left,
              top: obj.top,
              angle: obj.angle,
              // cornerSize: 7,
              padding: 6,
              id: obj.id,
              // transparentCorners: false,
              // cornerColor: "white",
              // cornerStrokeColor: "#109CF1",
              // borderColor: "#109CF1",
              name: "image",
              selectable:obj.selectable,
              fromServer:obj.fromServer,
            });
            image.set({
              left:(image.left)*Xt,
              top: (image.top)*Yt,
              scaleX: (image.scaleX) * Xt,
              scaleY: (image.scaleY) * Yt
            })
            image.setControlsVisibility({
              tl: true,
              mt: true,
              tr: true,
              ml: true,
              mr: true,
              bl: true,
              mb: true,
              br: true,
              mtr: false,
              colorControl: false,
            });
            image.scaleToWidth(200);
            image.scaleToHeight(200);
            if(this.isObjectOnCanvas(obj.id) == false) {
              this.canvas.add(image);
            }
            this.canvas.renderAll();
           // Resolve the promise with the image
          } catch (error) {
       // Reject the promise if there is an error
          }
      })
    }





    async addObject(obj,sender_canvasWidth,sender_canvasHeight) {
      let val;
      let Xt = this.browserDetectionService.getBrowserInfo().canvasWidth/sender_canvasWidth;
      let Yt = this.browserDetectionService.getBrowserInfo().canvasHeight/sender_canvasHeight;
      switch (obj.type) {
        case 'line':
          val = new fabric.Line([obj.x1, obj.y1, obj.x2, obj.y2], {
            left: obj.left,
            top: obj.top,
            stroke: obj.stroke,
            strokewidth: obj.strokewidth,
            cornerSize: obj.cornerSize,
            id: obj.id,
            fill: obj.fill,
            padding: obj.padding,
            flipX: obj.flipX,
            flipY: obj.flipY,
            lineCoords:obj.lineCoords,
            originX: obj.originX,
            originY: obj.originY,
            transparentCorners: false,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            angle: obj.angle,
            strokeMiterLimit: obj.strokeMiterLimit,
            opacity: obj.opacity,
            cornerStrokeColor: obj.cornerStrokeColor,
            selectable:obj.selectable,
            fromServer: obj.fromServer
          });
          break;
        case 'polygon':
          val = new fabric.Polygon(obj.points,
            {
              left: obj.left,
              top: obj.top,
              fill: "transparent",
              stroke: obj.stroke,
              strokewidth: 2,
              cornerSize: 7,
              id: obj.id,
              padding: 6,
              flipX: obj.flipX,
              flipY: obj.flipY,
              originX: obj.originX,
              originY: obj.originY,
              transparentCorners: false,
              cornerColor: 'white',
              cornerStrokeColor: 'lightblue',
              selectable:obj.selectable,
              fromServer: obj.fromServer
            });
          break;
        case 'path':
          if(obj.name == "hemisphere"){
            val = await this.insertHemisphereForStudent(obj)
            val.fromServer = obj.fromServer;
          }
          else if(obj.name == 'sphere'){
            val = await this.insertSphereForStudent(obj)
            val.fromServer = obj.fromServer;
          }
          else if(obj.name == 'cylinder'){
            val = await this.insertCylinderForStudent(obj)
            console.log("Venki: cylinder",val);
            val.fromServer = obj.fromServer;
          }
          else if (obj.path[0].length == 3 && obj.path[1].length == 7) {
            const centerX = 300;
            const centerY = 300;
            const radius = 100;
            const startAngle = 0;
            const endAngle = Math.PI;
            val = new fabric.Path(
              `M ${centerX} ${centerY} A ${radius} ${radius} 0 ${endAngle > Math.PI ? 1 : 0
              } 1 ${centerX + radius * Math.cos(endAngle)} ${centerY + radius * Math.sin(endAngle)
              }`,
              {
                stroke: obj.stroke,
                fill: 'transparent',
                strokeWidth: 2,
                top: obj.top,
                left: obj.left,
                evented: true,
                flipX: obj.flipX,
                flipY: obj.flipY,
                originX: obj.originX,
                originY: obj.originY,
                cornerSize: 7,
                id: obj.id,
                padding: 6,
                transparentCorners: false,
                cornerColor: 'white',
                cornerStrokeColor: 'lightblue',
                selectable:obj.selectable,
                fromServer: obj.fromServer
              }
            );

          }
          else {
            val = new fabric.Path(obj.path, obj);
            val.set({fromServer: obj.fromServer});
          }
          break;
        case 'rect':
          val = new fabric.Rect({
            width: 100,
            height: 100,
            top: obj.top,
            left: obj.left,
            fill: 'transparent',
            stroke: obj.stroke,
            strokewidth: 2,
            cornerSize: 7,
            id: obj.id,
            padding: 6,
            flipX: obj.flipX,
            flipY: obj.flipY,
            originX: obj.originX,
            originY: obj.originY,
            transparentCorners: false,
            cornerColor: 'white',
            selectable:obj.selectable,
            cornerStrokeColor: 'lightblue',
            fromServer: obj.fromServer
          });
          break;
        case 'group':
          if (
            obj.name == "arrow" ||
            (obj.objects.length == 2) &&
            obj.objects[0].type == 'line' &&
            obj.objects[1].type == 'triangle'
          ) {
            let border = obj.objects[0].stroke;
          let fill = obj.objects[0].fill;
            const triangle = new fabric.Triangle({
              width: 10,
              height: 15,
              fill: fill,
            stroke: border,
              left: 235,
              top: 65,
              angle: 90,
              fromServer: obj.fromServer
            });
            const line = new fabric.Line([50, 100, 200, 100], {
              left: 75,
              top: 70,
              stroke: obj.objects[0].stroke,
              strokewidth: 2,
              fromServer: obj.fromServer
            });
            const objs = [line, triangle];
            val = new fabric.Group(objs, {
              cornerSize: 7,
              id: obj.id,
              top: obj.top,
              left: obj.left,
              padding: 6,
              transparentCorners: false,
              cornerColor: 'white',
              cornerStrokeColor: 'lightblue',
              selectable:obj.selectable,
              fromServer: obj.fromServer,
              name: "arrow",
              angle: obj.angle,
              flipX: obj.flipX,
              flipY: obj.flipY,
              originX: obj.originX,
              originY: obj.originY,
              scaleX: obj.scaleX,
              scaleY: obj.scaleY,
            });
          }
          else if (
            obj.name == 'cylinder' ||
            (obj.objects.length == 3) &&
            obj.objects[0].type == 'path' &&
            obj.objects[1].type == 'path' &&
            obj.objects[1].type == 'path'
          ) {
            const fill = '';
            const border = obj.objects[0].stroke;
            const lineWidth = 2;

            const topCurve = new fabric.Path('M96.7,156.2 C96.7,165.3 75.4,172.7 49.0,172.7 C22.7,172.7 1.4,165.3 1.4,156.2 C1.4,147.2 22.7,139.8 49.0,139.8 C75.4,139.8 96.7,147.2 96.7,156.2 Z', {
              fill: fill,
              stroke: border,
              strokeLineCap: 'round',
              strokeLineJoin: 'round',
              fromServer: obj.fromServer
            });

            const bottomCurve = new fabric.Path('M96.7,17.4 C96.7,26.5 75.4,33.8 49.0,33.8 C22.7,33.8 1.4,26.5 1.4,17.4 C1.4,8.4 22.7,1.0 49.0,1.0 C75.4,1.0 96.7,8.4 96.7,17.4 Z', {
              fill: fill,
              stroke: border,
              strokeLineCap: 'round',
              strokeLineJoin: 'round',
              fromServer: obj.fromServer,
              name:'cylinder',
              angle: obj.angle
            });

            const leftLine = new fabric.Path('M16.7,40 L16.7,180', {
              left: 0,
              top: 17,
              stroke: border,
              strokeWidth: 2,
              fromServer: obj.fromServer
            });

            const rightLine = new fabric.Path('M30,40 L30,180', {
              left: 97,
              top: 17,
              stroke: border,
              strokeWidth: 2,
              fromServer: obj.fromServer
            });

            const cylinderGroup = new fabric.Group([leftLine, rightLine, topCurve, bottomCurve], {
              top: obj.top,
              left: obj.left,
              cornerSize: 7,
              id: obj.id,
              flipX: obj.flipX,
              flipY: obj.flipY,
              originX: obj.originX,
              originY: obj.originY,
              padding: 6,
              transparentCorners: false,
              cornerColor: 'white',
              cornerStrokeColor: 'lightblue',
              selectable:obj.selectable,
              fromServer: obj.fromServer
            });
            val = cylinderGroup
          }
          else if(obj.name=="groupVote"){
            val=this.addImageTostudentVotingOnCanvas(obj.objects[2].src,obj.objects[1].text,obj)
           }
           else if(this.multimediaObjArr.includes(obj.name)){
            fabric.util.enlivenObjects((obj.objects),(objects) => {     
              delete obj.objects;         
              val = new fabric.Group(objects, obj)

              val.set({
                left:(val.left)*Xt,
                top: (val.top)*Yt,
                scaleX: (val.scaleX) * Xt,
                scaleY: (val.scaleY) * Yt,
                hasControls: false,
                hasBorders: false,
                id: obj.id
              });
              this.canvas.add(val);
              this.canvas.requestRenderAll();
            })
            return;
           }
          break;
        case 'triangle':
          val = new fabric.Triangle({
            width: 100,
            height: 100,
            stroke: obj.stroke,
            fill: 'transparent',
            top: obj.top,
            left: obj.left,
            cornerSize: 7,
            id: obj.id,
            flipX: obj.flipX,
            flipY: obj.flipY,
            originX: obj.originX,
            originY: obj.originY,
            padding: 6,
            transparentCorners: false,
            cornerColor: 'white',
            cornerStrokeColor: 'lightblue',
            selectable:obj.selectable,
            fromServer: obj.fromServer
          });
          break;
        case 'circle':
          val = new fabric.Circle({
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            angle: obj.angle,
            fill: obj.fill,
            stroke: obj.stroke,
            top: obj.top,
            left: obj.left,
            cornerSize: obj.cornerSize,
            id: obj.id,
            padding: obj.padding,
            flipX: obj.flipX,
            flipY: obj.flipY,
            strokeWidth:obj.strokeWidth,
            originX: obj.originX,
            originY: obj.originY,
            transparentCorners: obj.transparentCorners,
            cornerColor: obj.cornerColor,
            cornerStrokeColor: obj.cornerStrokeColor,
            radius: obj.radius,
            startAngle: obj.startAngle,
            endAngle: obj.endAngle,
            selectable:obj.selectable,
            fromServer: obj.fromServer
          });
          break;
        case 'i-text':
          val = new fabric.IText(obj.text, {
            left: obj.left,
            top: obj.top,
            fontFamily: obj.fontFamily,
            fontSize: obj.fontSize,
            angle: obj.angle,
            fill: obj.fill,
            scaleX: 0.5,
            editable: true,
            scaleY: 0.5,
            fontWeight: obj.fontWeight,
            hasRotatingPoint: false,
            // cornerSize: 7,
            id: obj.id,
            padding: 6,
            flipX: obj.flipX,
            flipY: obj.flipY,
            textAlign: obj.textAlign,
            originX: obj.originX,
            originY: obj.originY,
            // transparentCorners: false,
            // cornerColor: 'white',
            // cornerStrokeColor: 'lightblue',
            selectable:obj.selectable,
            fromServer: obj.fromServer
          });
          val.setControlsVisibility({
            tl: true,
            mt: true,
            tr: true,
            ml: true,
            mr: true,
            bl: true,
            mb: true,
            br: true,
            mtr: false,
          });
          break;
         }

          val.set({
            left:(val.left)*Xt,
            top: (val.top)*Yt,
            scaleX: (val.scaleX) * Xt,
            scaleY: (val.scaleY) * Yt
          });
          this.canvas.add(val);
          this.canvas.requestRenderAll();
    }

  async addObjectUndoRedo(obj,sender_canvasWidth,sender_canvasHeight) {
    console.log("Venki: addObjectUndoRedo",obj);
    let val;
    let Xt = this.browserDetectionService.getBrowserInfo().canvasWidth/sender_canvasWidth;
    let Yt = this.browserDetectionService.getBrowserInfo().canvasHeight/sender_canvasHeight;
    switch (obj.type) {
      case 'line':
      case 'polygon':
      case 'path':
      case 'rect':
      case 'triangle':
      case 'circle':
      case 'image':
      case 'i-text':
        fabric.util.enlivenObjects([obj], (objects) => {
          objects[0].set({
            left:(objects[0].left)*Xt,
            top: (objects[0].top)*Yt,
            scaleX: (objects[0].scaleX) * Xt,
            scaleY: (objects[0].scaleY) * Yt
          });
          this.canvas.add(objects[0]);
          objects[0].setControlsVisibility({
            tl: true,
            mt: true,
            tr: true,
            ml: true,
            mr: true,
            bl: true,
            mb: true,
            br: true,
            mtr: false,
          });
          this.canvas.requestRenderAll();
          this.addAddModRemFabricListeners();
          this.lockUndo = false;
          this.lockRedo = false;
      });
        break;
      case 'group':
        fabric.util.enlivenObjects(obj.objects, (objects) => {
          delete obj.objects;
          val = new fabric.Group(objects, obj);
          val.set({
            left:(val.left)*Xt,
            top: (val.top)*Yt,
            scaleX: (val.scaleX) * Xt,
            scaleY: (val.scaleY) * Yt
          });
          //enlivening eraser to group
          if (obj.eraser) {
            fabric.util.enlivenObjects([obj.eraser], (enlivenedErasers) => {
              const enlivenedEraser = enlivenedErasers[0];
              val.eraser = enlivenedEraser;
            });
          }
          this.canvas.add(val)
          val.setControlsVisibility({
            tl: true,
            mt: true,
            tr: true,
            ml: true,
            mr: true,
            bl: true,
            mb: true,
            br: true,
            mtr: false,
          });
          this.canvas.requestRenderAll();
          this.addAddModRemFabricListeners();
          this.lockUndo = false;
          this.lockRedo = false;
      });
        break;
      case 'activeSelection':
        fabric.util.enlivenObjects(obj.objects, (objects) => {
          delete obj.objects;
          const tempGroup = new fabric.Group(objects);
          const boundingBox = tempGroup.getBoundingRect();

          // Capture the group's transformation properties
          const groupLeft = obj.left;
          const groupTop = obj.top;
          const groupScaleX = obj.scaleX;
          const groupScaleY = obj.scaleY;
          const groupAngle = fabric.util.degreesToRadians(obj.angle);

          // Iterate over each object and adjust its position, scale, and angle
          objects.forEach((object) => {
            // relative position calculation -- relativeX and relativeY represent the object's position inside the bounding box of the group
            const relativeX = object.left - boundingBox.left;
            const relativeY = object.top - boundingBox.top;

            // rotation transformation -- standard 2D rotation formula
            const rotatedX = relativeX * Math.cos(groupAngle) - relativeY * Math.sin(groupAngle);
            const rotatedY = relativeX * Math.sin(groupAngle) + relativeY * Math.cos(groupAngle);

            // scaling transformation -- multiplying rotated position by the group’s scale factors
            const newLeft = groupLeft + rotatedX * groupScaleX;
            const newTop = groupTop + rotatedY * groupScaleY;

            object.set({
              left: newLeft,
              top: newTop,
              scaleX: object.scaleX * groupScaleX,
              scaleY: object.scaleY * groupScaleY,
              angle: obj.angle + object.angle,
            });

            this.canvas.add(object);
            object.setCoords(); // Update the object's coordinates for proper rendering

            object.setControlsVisibility({
              tl: true,
              mt: true,
              tr: true,
              ml: true,
              mr: true,
              bl: true,
              mb: true,
              br: true,
              mtr: false,
            });
          });
          tempGroup.remove();


          this.canvas.requestRenderAll();
          this.addAddModRemFabricListeners();
          this.lockUndo = false;
          this.lockRedo = false;
        });

        break;
      case 'multiremObj':
        fabric.util.enlivenObjects(obj.objects, (objects) => {

          objects.forEach((object) => {
            this.canvas.add(object);
            object.setControlsVisibility({
              tl: true,
              mt: true,
              tr: true,
              ml: true,
              mr: true,
              bl: true,
              mb: true,
              br: true,
              mtr: false,
            });

          })
          this.canvas.requestRenderAll();
          this.addAddModRemFabricListeners();
          this.lockUndo = false;
          this.lockRedo = false;
        });
        break;
      case 'eraserPaths':
        if (obj.objects) {
          const restoreEraserPaths = async (objects) => {
            let i = 0;
            let objLength = objects.length;
            while (i < objLength) {
              const { id, eraserState } = objects[i];
              const target = this.canvas.getObjects().find(obj => obj.id === id);
              if (target && target.eraser._objects && eraserState.length > target.eraser._objects.length) {
                const restoredPathJSON = eraserState[target.eraser._objects.length]; // // Get the next erased path to restore for the object
                let enlivenedObjects = await this.synchronous_enlivenObjects(restoredPathJSON);
                const restoredPath = enlivenedObjects[0];

                if (restoredPath) {
                  if (target) {
                    target.eraser._objects.push(restoredPath); // Re-add the erased path
                    target.eraser.set('dirty', true); // Mark eraser as dirty to redraw
                    target.set('dirty', true); // Mark target as dirty -- clearing cache
                    this.canvas.renderAll();
                  }
                }
              }
              i++;
            };
          }
          restoreEraserPaths(obj.objects);
          this.canvas.requestRenderAll();
          this.addAddModRemFabricListeners();
          this.lockUndo = false;
          this.lockRedo = false;
        }
        break;
        default:
          this.lockUndo = false;
          this.lockRedo = false;
       }
  }

  async synchronous_enlivenObjects(restoredPathJSON) {
    return new Promise((resolve, reject) => {
      fabric.util.enlivenObjects([restoredPathJSON], (enlivenedObjects) => {
        resolve(enlivenedObjects);
      })
    })
  }
  //----Backdrop Part-----

  bgcolorchanged(data,type) {
    if(data){
      this.bgColorApplied = true
    }
    // this.removebgImage();
    try{
      console.log("inside bgcolorchanged editorcanvas");
      this.bgColor = data;
      console.log("this.bgColor", this.bgColor);
      this.canvas.backgroundColor = this.bgColor;
      this.canvas.renderAll();
    }
    catch{
    }
    finally{
      if(type == 'sendSocketEvent'){
          let obj = {'bgColor':this.canvas.backgroundColor,'id':Math.floor(100000 + Math.random() * 900000)}
            if(this.avChatStarted()){
              this.sendObjDataToSocket(obj,'edit')
            }
      }
    }

  }

  //---Backdrop part ends----

  //changes start - shreyas.s
  getVideoElement(url) {
    var videoE = document.createElement("video");
    videoE.width = 530;
    videoE.height = 298;
    videoE.muted = true;
    videoE.controls = true;
    videoE.crossOrigin = "anonymous";
    videoE.loop = true;
    var source = document.createElement("source");
    source.src = url;
    source.type = "video/mp4";

    videoE.appendChild(source);
    return videoE;
  }

  getMaterialType(materialObj: any): string {
    const material = materialObj.file;
    const { src } = materialObj;

    const acceptedFormats = [
      "mp4",
      "mov",
      "mp3",
      "jpeg",
      "jpg",
      "png",
      "svg",
      "pdf",
      "docx",
      "pptx",
      "ppt",
      "txt",
      "xlsx",
    ];
    const imageFormats = ["png", "jpeg", "jpg"];

    if (src === "gc") {
      if (material.driveFile) {
        const titleArr = material.driveFile.driveFile.title.split(".");
        const format = titleArr[titleArr.length - 1];
        const val = acceptedFormats.includes(format)
          ? imageFormats.includes(format)
            ? "image"
            : format
          : "unknown";
        return val;
      } else if (material.link) {
        return "link";
      } else if (material.youtubeVideo) {
        return "youtube";
      } else {
        return "unknown";
      }
    } else if (src === "gd" || src === "local") {
      switch (material.mimeType) {
        case "application/pdf":
          return `pdf`;
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
          return `image`;
        case "video/mp4":
        case "video/quicktime":
          return `mp4`;
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "docx";
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return `xlsx`;
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return "pptx";
        case "text/plain":
          return "txt";
        default:
          return `unknown`;
      }
    } else {
    }
  }
  addImageToCanvasFromURL(imageURL) {
    let imagesCount = getTotalImagesCount(this.canvas);
    if(imagesCount >= 10) {
      this.dialogService.showSimplePopup("Maximum limit reached.");
      return;
    }
    const centerX = this.canvas.width / 2;
    const centerY = this.canvas.height / 2;
    fabric.Image.fromURL(imageURL, (fabricImage) => {
      fabricImage.set({
        left: centerX,
        top: centerY,
        originX: "center",
        originY: "center",
        scaleX: 0.5,
        scaleY: 0.5,
      });
      this.canvas.add(fabricImage);
    });
  }

  async uploadGoogleCourseMaterial(materialObj) {
    const centerX = this.canvas.width / 2;
    const centerY = this.canvas.height / 2;
    const material = materialObj.file;
    const fileType = this.getMaterialType(materialObj);
    let data;
    console.log(fileType);
    const { src } = materialObj;
    let googleDriveFileID =
      src === "gc" ? material?.driveFile?.driveFile?.id : material.id;

    switch (fileType) {
      case "mp4":
        const MAX_VIDEO_SIZE_BYTES = 100 * 1024 * 1024; // 50 MB as an example threshold
        this.googleClassroomService
          .getCourseMaterialMedia(googleDriveFileID)
          .subscribe((data) => {
            if (data.byteLength >= MAX_VIDEO_SIZE_BYTES) return;
            const blob = new Blob([data], { type: "video/mp4" });
            const dataUrl = URL.createObjectURL(blob);
            const safeUrl: SafeResourceUrl =
              this.sanitizer.bypassSecurityTrustResourceUrl(dataUrl);
            this.addFloatingWindowEvent.emit({
              type: "mp4",
              url: safeUrl,
              origURL: dataUrl,
            });
          });
        break;
      case "image":
        this.googleClassroomService
          .getCourseMaterialMedia(googleDriveFileID)
          .subscribe((data) => {
            const blob = new Blob([data], { type: "image/jpeg" });
            const dataUrl = URL.createObjectURL(blob);
            this.addImageToCanvasFromURL(dataUrl);
          });
        break;

      case "link":
        if (src != "gc") return;
        console.log("case link in gccc")
        const text = new fabric.IText(material.link.url, {
          // fontSize: 2.5,
          underline: true,
          fill: "blue",
          left: centerX,
          top: centerY,
          originX: "center",
          originY: "center",
        });
        this.canvas.add(text);
        break;

      case "youtube":
        if (src != "gc") return;
        console.log("emitted");
        this.addFloatingWindowEvent.emit({
          type: "youtube",
          data: material.youtubeVideo.id,
        });
        break;

      case "pdf":
        this.documentWindowLoadingEvent.emit(true);
        data = await this.googleClassroomService.postGoogleDriveFileToServer(
          googleDriveFileID,
          "pdf"
        );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "gcgd",
              type: "pdf/doc",
              fileId: googleDriveFileID,
              data: data,
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      case "docx":
        this.documentWindowLoadingEvent.emit(true);
        data = await this.googleClassroomService.postGoogleDriveFileToServer(
          googleDriveFileID,
          "docx"
        );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "gcgd",
              type: "pdf/doc",
              fileId: googleDriveFileID,
              data: data,
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      case "xlsx":
        this.documentWindowLoadingEvent.emit(true);
        data = await this.googleClassroomService.postGoogleDriveFileToServer(
          googleDriveFileID,
          "xlsx"
        );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "gcgd",
              type: "pdf/doc",
              fileId: googleDriveFileID,
              data: data,
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      case "txt":
        this.documentWindowLoadingEvent.emit(true);
        data = await this.googleClassroomService.postGoogleDriveFileToServer(
          googleDriveFileID,
          "txt"
        );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "gcgd",
              type: "pdf/doc",
              fileId: googleDriveFileID,
              data: data,
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      case "pptx":
        this.documentWindowLoadingEvent.emit(true);
        data = await this.googleClassroomService.postGoogleDriveFileToServer(
          googleDriveFileID,
          "pptx"
        );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "gcgd",
              type: "pptx",
              fileId: googleDriveFileID,
              data: data,
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      default:
        console.log("not supported");
        break;
    }

  }
  async addUploadDocumentToCanvas(fileObj) {
    const fileType = this.getMaterialType({ file: fileObj, src: "local" });
    let data;
    switch (fileType) {
      case "mp4":
        break;
      case "image":
        break;

      case "link":
        break;

      case "youtube":
        break;

      case "pdf":
        this.documentWindowLoadingEvent.emit(true);
        data =
          await this.googleClassroomService.postUploadDocumentImagesToServer(
            fileObj.destinationpath,
            "pdf"
          );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "local",
              type: "pdf/doc",
              fileId: data.fileID,
              data: data,
              fileName: fileObj.originalName,
              docdbid:fileObj._id
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      case "docx":
        this.documentWindowLoadingEvent.emit(true);
        data =
          await this.googleClassroomService.postUploadDocumentImagesToServer(
            fileObj.destinationpath,
            "docx"
          );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "local",
              type: "pdf/doc",
              fileId: data.fileID,
              data: data,
              fileName: fileObj.originalName,
              docdbid:fileObj._id
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      case "xlsx":
        this.documentWindowLoadingEvent.emit(true);
        data =
          await this.googleClassroomService.postUploadDocumentImagesToServer(
            fileObj.destinationpath,
            "xlsx"
          );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "local",
              type: "pdf/doc",
              fileId: data.fileID,
              data: data,
              fileName: fileObj.originalName,
              docdbid:fileObj._id
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      case "txt":
        this.documentWindowLoadingEvent.emit(true);
        data =
          await this.googleClassroomService.postUploadDocumentImagesToServer(
            fileObj.destinationpath,
            "txt"
          );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "local",
              type: "pdf/doc",
              fileId: data.fileID,
              data: data,
              fileName: fileObj.originalName,
              docdbid:fileObj._id
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      case "pptx":
        this.documentWindowLoadingEvent.emit(true);
        data =
          await this.googleClassroomService.postUploadDocumentImagesToServer(
            fileObj.destinationpath,
            "pptx"
          );
        data.subscribe((data) => {
          if (data.message === "ok") {
            this.addFloatingWindowEvent.emit({
              src: "local",
              type: "pdf/doc",
              fileId: data.fileID,
              data: data,
              fileName: fileObj.originalName,
              docdbid:fileObj._id
            });
          }
          this.documentWindowLoadingEvent.emit(false);
        });
        break;
      default:
        console.log("not supported");
        break;
    }
  }
  //changes end  - shreyas.s


  clearCanvas() {
    this.canvas.__eventListeners = {};
    this.canvas.clear();
    this.bgFrame = '';
    this.customBg = '';
    this.EraseCanvas.select = true
    this.EraseCanvas.eraseAll = true
    this.resetLocalStorage()
    localStorage.setItem('prevCanvasObjLen','0')
    this.saveCanvasDataToMongoDB()
    this.clearRemovedOperationsList()
    this.UndoRedoService.clearUndoState();
    let test1=this.UndoRedoService.isPageOperationsListEmpty();
    this.undoStatusChanged.emit(test1);
    let test2=this.UndoRedoService.isRemovedOperationsListEmpty();
    this.redoStatusChanged.emit(test2);
    console.log("DEBUGG: undo",test1);
    console.log("DEBUGG: redo",test2);
    this.addAddModRemFabricListeners();
    console.log("night test - send remove all to socket bool")
    this.eraseAllToSocket()
    this.lockUndo = false;
    this.lockRedo = false;
  }

  changeStudentPage(pageId){
    if(this.avChatStarted()){
      let obj = {
        pageId : pageId,
        lessonId : localStorage.getItem('mainlessonID')
      }
      this.fileservice.emitWebSocketEvent("changeLessonPage", obj);
    }
  }

  activepencilmode(){
      this.canvas.isDrawingMode=true
    }

    eraseCanvasChange(){
      this.EraseCanvas.eraseAll = false
      this.EraseCanvas.onTouch = false
      this.EraseCanvas.context = false
      this.EraseCanvas.select = false
      this.EraseCanvas.point = false
    }

    async setCanvasLocalStrValue(obj){
      console.log("sumant : after setCanvasLocalStrValue 1",obj)
      let jsonObjects;
      if(obj && obj.length > 0){
         jsonObjects = this.removeDuplicates(obj,"id")
      }
      let canvasObj = {
        objects: [],
        version: "5.2.1",
      };
      if(obj && obj.length > 0){
        canvasObj.objects = jsonObjects
      }
      console.log("sumant : after setCanvasLocalStrValue 2",canvasObj)
      localStorage.setItem('currentCanvas',JSON.stringify(canvasObj))
    }

    sendObjToSocket() {
      let objLen = this.canvas.getObjects().length
      objLen = objLen - 1
      // this.saveCache()
      // this.saveCanvasDataToSocket()
      // this.fileservice.sendObjDataToSocket(this.canvas._objects[objLen],'add')
    }

    async updateCanvasSingleObj(obj,index,type){
      let canvasData = JSON.parse(localStorage.getItem('currentCanvas'))["objects"];
      if(type == 'edit'){
        canvasData[index] = obj
      }
      else if(type == 'delete'){
        canvasData.splice(index,1)
      }
      await this.setCanvasLocalStrValue(canvasData)
    }

    async getIndexOfObject(id){
      let canvasData = JSON.parse(localStorage.getItem('currentCanvas'))["objects"];
      let index = -1;
      // Labeled block for using break
      loopBlock: for (const [currentIndex, item] of canvasData.entries()) {
        if (item.id === id) {
          index = currentIndex;
          break loopBlock; // Break out of the labeled block
        }
      }

      return index;
    }


    getDottedBrush(color: string, width: number): fabric.IBrush {
      const brush = new fabric.PencilBrush(this.canvas);

      brush.color = color;
      brush.width = width;
      brush.strokeLineCap = 'round';
      brush.strokeLineJoin = 'round';
      brush.strokeDashArray = [5, 5]; // Define the dash pattern for the dotted line

      return brush;
  }

    selectAreaEraser(){
      this.canvas.freeDrawingBrush = this.getDottedBrush('#FF0000', 2);
      this.canvas.freeDrawingBrush.color = 'red';
      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush.width = this.strokethicknes;
      this.canvas.select = false
      this.eraseCanvasChange()
      this.EraseCanvas.select = true
      this.EraseCanvas.select_area = true
    }

    selectAreaSelct(){
      this.canvas.isDrawingMode = false
      this.selectAreaBool = true
    }

      setupDrawing(): void {
        let isDrawing = false;
        let path: fabric.Path;

        // Mouse down event handler
        this.canvas.on('mouse:down', (event) => {
          isDrawing = true;
          const pointer = this.canvas.getPointer(event.e);
          const points = [{ x: pointer.x, y: pointer.y }];
          path = new fabric.Path('', {
            fill: 'blue',
            stroke: 'red',
            strokeWidth: 2,
            strokeLineCap: 'round',
            strokeLineJoin: 'round',
            selectable: false
          });
          path.set({ path: this.getPathString(points) });
          this.canvas.add(path);
        });

        // Mouse move event handler
        this.canvas.on('mouse:move', (event) => {
          if (!isDrawing) return;
          const pointer = this.canvas.getPointer(event.e);
          const points = path.path;
          points.push({ x: pointer.x, y: pointer.y });
          path.set({ path: this.getPathString(points) });
          this.canvas.renderAll();
        });

        // Mouse up event handler
        this.canvas.on('mouse:up', () => {
          isDrawing = false;
        });

    }

    getPathString(points: { x: number, y: number }[]): string {
        if (points.length === 0) return '';
        const path = ['M', points[0].x, points[0].y];
        for (let i = 1; i < points.length; i++) {
          path.push('L', points[i].x, points[i].y);
        }
        return path.join(' ');
    }

     getObjectsWithinAreaRange(canvas: fabric.Canvas, minTop: number, maxTop: number, minLeft: number, maxLeft: number): fabric.Object[] {
      // Get all objects on the canvas
      const allObjects = this.canvas.getObjects();

      // Filter objects based on their top and left positions within the specified range
      const objectsWithinAreaRange = allObjects.filter(object => {
          // Get the top and left positions of the object
          const objectTop = object.top || 0; // Default to 0 if top is undefined
          const objectLeft = object.left || 0; // Default to 0 if left is undefined

          // Check if the object's top and left positions are within the desired range
          return objectTop >= minTop && objectTop <= maxTop && objectLeft >= minLeft && objectLeft <= maxLeft;
      });

      return objectsWithinAreaRange;
  }

  async handleMovedUndoRedoObj(){
    if(this.canvas.getActiveObject()){
     let activeObj = this.canvas.getActiveObject().toJSON(['id'])
     const objects = JSON.parse(localStorage.getItem('currentCanvas'))["objects"]; // Serialize all objects with id and externalId
     let indexToRemove = 0
     for (const [index, item] of objects.entries()) {
       if (item.id === activeObj.id) {
         indexToRemove = index;
         break;
       }
     }

     let edited_objects = []
      if(localStorage.getItem('editedObj')){
        edited_objects = JSON.parse(localStorage.getItem('editedObj'))
      }
      if (activeObj.id == objects[indexToRemove].id &&
        (activeObj.left != objects[indexToRemove].left ||
         activeObj.top != objects[indexToRemove].top ||
         activeObj.width != objects[indexToRemove].width ||
         activeObj.angle != objects[indexToRemove].angle ||
         activeObj.flipX != objects[indexToRemove].flipX ||
         activeObj.flipY != objects[indexToRemove].flipY ||
         activeObj.stroke != objects[indexToRemove].stroke
        ))
        {
           edited_objects.push(objects[indexToRemove])
        }
      localStorage.setItem('editedObj',JSON.stringify(edited_objects))
    }
  }

  async changeObjectSerial(){
    if(this.canvas.getActiveObject()){
      let activeObj = this.canvas.getActiveObject().toJSON(['id']);
      let allObjects = JSON.parse(localStorage.getItem('currentCanvas')).objects;
      let indexToRemove = 0
      for (const [index, item] of allObjects.entries()) {
        if (item.id === activeObj.id) {
          indexToRemove = index;
          break;
        }
      }
      allObjects.splice(indexToRemove,1)
      allObjects.push(activeObj);
      this.setCanvasLocalStrValue(allObjects)
      // this.canvas.remove(activeObj)
      // this.canvas.add(activeObj)
      // allObjects.splice(allObjects.findIndex(obj => obj.id === activeObj.id), 1);
      // allObjects.push(activeObj)
      // this.setCanvasLocalStrValue(allObjects)

    }
  }

  openFlashlight(){
    this.canvas.renderAll()
  }

  closeFlashlight(){
    // this.canvas.visible = true
    this.canvas.renderAll()
  }

  resetLocalStorage(){
    localStorage.removeItem("undoCanvasObjects");
    this.setCanvasLocalStrValue('')
    localStorage.removeItem("editedObj")
    localStorage.removeItem("CurrentPosition")
  }

  isLoginByUser(): boolean {
    const userType = localStorage.getItem("type");
    const validTypes = ['teacher', 'regionaladmin', 'superadmin', 'schooladmin', 'countryadmin', 'demoaccounts'];
    return userType ? validTypes.includes(userType) : false;
  }

  isLoginByTeacher(){
    if(localStorage.getItem("type") && localStorage.getItem("type") != 'student'){
      return true
    }
    else{
      return false
    }
   }

   async isTextActive(activeObject){
        if(activeObject && activeObject.type == 'i-text' && !activeObject.text){
          return true
        }
        else{
          return false
        }
     }

   async areaEraser(){
    let highliteObj = this.canvas._objects[this.canvas._objects.length-1]
    const objectsWithinHeightRange = this.getObjectsWithinAreaRange(highliteObj,highliteObj.top,highliteObj.top+highliteObj.height,highliteObj.left,highliteObj.left+highliteObj.width);
    // let activeObjects = this.canvas.getActiveObjects()
    this.canvas.remove(...objectsWithinHeightRange);
    this.canvas.discardActiveObject(); // Deselect all active objects
    this.canvas.renderAll();
    let allObjects = JSON.parse(localStorage.getItem('currentCanvas'))["objects"];
    allObjects.pop()
    const indicesToRemove = [];
    for (let i = allObjects.length - 1; i >= 0; i--) {
        const item = allObjects[i];
        for (const obj of objectsWithinHeightRange) {
            if (item.id === obj.id) {
                indicesToRemove.push(i);
                break; // Exit the inner loop once a match is found
            }
        }
    }

    // Remove items from allObjects based on indicesToRemove
    for (const index of indicesToRemove) {
        allObjects.splice(index, 1);
      }
      await this.setCanvasLocalStrValue(allObjects)
   }

   avChatStarted(){
    if((localStorage.getItem('avchatstarted') && localStorage.getItem('avchatstarted') == 'yes')){
    return true
    }
    else{
      return false
    }
   }

   resetEditObj(){
    this.editObjectType.select = false
    this.editObjectType.bringFront = false
    this.editObjectType.horizontalFlip = false
    this.editObjectType.redo = false
    this.editObjectType.undo = false
    this.editObjectType.sendBack = false
    this.editObjectType.verticalFlip = false
    this.DuplObj = false
   }

   eventCleanUp(funcName) {
    if(!this.isPageLoading) {//dont clear events while page loading. Events are already cleared before page loading.
      console.log('Venki: event cleanup happening');
    this.canvas.__eventListeners = {};
    if(funcName !== "addEditText") {
      this.isTextSelected = false;
    }
    this.addAddModRemFabricListeners();
    }
   }

   addIdToFabricObject(object) {
    let newId
    if(object.target.id){
      newId = object.target.id
    }
    else{
      newId = Math.floor(100000 + Math.random() * 900000);
    }
    object.target.id = newId;
   }

   sendObjectAddedToSocket(object) {
    console.log("night test - object after bool",object)
    // if(object.target.name == "windowYoutube" ||
    //   object.target.name == "windowVideoMyLibrary" ||
    //   object.target.name == "windowDocMyLibrary" ||
    //   object.target.name == "windowDoc" ||
    //   object.target.name == "windowAudioMyLibrary" ||
    //   object.target.name == "window3D") {
    //     return
    //   }
    let newObject = object.target.toObject()
    newObject.id = object.target.id;

    newObject.name = object.target.name
    if(object.target.toolType){
      newObject.toolType = object.target.toolType
    }
    if(object.target.duplicate){
      newObject.duplicate = object.target.duplicate
    }
    console.log("night test - send add obj to socket bool",newObject)
    this.fileservice.sendObjDataToSocket(newObject,'add')
   }

   sendObjectModifiedToSocket(object) {
    console.log("night test - object after bool",object)
    if(object.target.name == "windowYoutube" ||
      object.target.name == "windowVideoMyLibrary" ||
      object.target.name == "windowDocMyLibrary" ||
      object.target.name == "windowDoc" ||
      object.target.name == "windowAudioMyLibrary" ||
      object.target.name == "window3D") {
        return
      }
          let newObject = object.target.toObject()
          newObject.id = object.target.id;
          newObject.name = object.target.name
          if(object.target.toolType){
            newObject.toolType = object.target.toolType
            newObject.toolAngle = object.target.toolAngle
          }
          newObject.BringFrontObject = object.target.BringFrontObject
          newObject.SendBackObject = object.target.SendBackObject
          newObject.oldState = object.target.oldState;
          console.log("night test - send add obj to socket bool",newObject)
          this.fileservice.sendObjDataToSocket(newObject,'edit')
   }

   sendObjectRemovedToSocket(object) {
    console.log("night test - object removed bool",object);
    if(object.target.name == "windowYoutube" ||
      object.target.name == "windowVideoMyLibrary" ||
      object.target.name == "windowDocMyLibrary" ||
      object.target.name == "windowDoc" ||
      object.target.name == "windowAudioMyLibrary" ||
      object.target.name == "window3D") {
        return
      }
    try {
      let newObject = object.target.toObject()
      newObject.id = object.target.id;
      newObject.oldState = object.target.oldState;
      console.log("night test - send remove obj to socket bool",newObject)
      this.fileservice.sendObjDataToSocket(newObject,'delete')
    }
    catch(error) { console.log(error); }
   }

   addAddModRemFabricListeners() {
    this.canvas.on('object:added',(object:any) => {
      console.log("Venki: object:added",object);
      this.fileservice.updatePageChangeStatus(this.currentPageNumber,true);
      this.undoStatusChanged.emit(false);
      this.addIdToFabricObject(object);
      if (object.target.type === 'i-text') {
        if (object.target.fromServer) {
          this.isTextSelected = false;
        } else {
          this.isTextSelected = true;
        this.handleTextMenuPosition(object.target);
        }
        // this.isTextSelected = true;
        // this.handleTextMenuPosition(object.target);
      }
      if("fromServer" in object.target){
        if(object.target.fromServer) {
          console.log("Venki: object just came from server",object.target.type,object.target.id);
          // if (object.target.type == 'i-text') {
          //   this.isTextSelected = false;
          // }
          object.target.fromServer = false;
        }
        else if(!this.isToolsOpen && object.target.customType != 'compass' && object.target.customType != 'ruler'&& object.target.customType != 'setsquare'&& object.target.customType != 'protractor') {
          this.sendObjectAddedToSocket(object);
        }
     } else if(!this.isToolsOpen && object.target.customType != 'compass' && object.target.customType != 'ruler'&& object.target.customType != 'setsquare'&& object.target.customType != 'protractor') {
      this.sendObjectAddedToSocket(object);
     }
     if(!this.isToolsOpen && object.target.customType != 'compass' && object.target.customType != 'ruler'&& object.target.customType != 'setsquare'&& object.target.customType != 'protractor' && object.target.name != "windowYoutube" &&
      object.target.name != "windowVideoMyLibrary" &&
      object.target.name != "windowDocMyLibrary" &&
      object.target.name != "windowDoc" &&
      object.target.name != "windowAudioMyLibrary" &&
      object.target.name != "window3D"
     ) {
      if(object.target.type != "i-text") {
        this.clearRemovedOperationsList()
        let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
        this.redoStatusChanged.emit(test2);
        this.UndoRedoService.pushOperationIntopageOperationsList({type:'add',newState:object.target,oldState:null});
      }
      else if(object.target.text != " " && object.target.text != "") {
        this.clearRemovedOperationsList()
        let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
        this.redoStatusChanged.emit(test2);
        this.UndoRedoService.pushOperationIntopageOperationsList({type:'add',newState:object.target,oldState:null});
      }
     }
    })

    this.canvas.on('object:modified',(object:any) => {
      console.log("Venki: object:modified",object);
      this.fileservice.updatePageChangeStatus(this.currentPageNumber,true);
      this.undoStatusChanged.emit(false);
      if(!this.isToolsOpen && object.target.customType != 'compass' && object.target.customType != 'ruler'&& object.target.customType != 'setsquare'&& object.target.customType != 'protractor') {
        this.sendObjectModifiedToSocket(object);
      }
      if(!this.isToolsOpen && object.target.customType != 'compass' && object.target.customType != 'ruler'&& object.target.customType != 'setsquare'&& object.target.customType != 'protractor' && object.target.name != "windowYoutube" &&
        object.target.name != "windowVideoMyLibrary" &&
        object.target.name != "windowDocMyLibrary" &&
        object.target.name != "windowDoc" &&
        object.target.name != "windowAudioMyLibrary" &&
        object.target.name != "window3D"
      ) {
        this.clearRemovedOperationsList()
        let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
        this.redoStatusChanged.emit(test2);
        this.UndoRedoService.pushOperationIntopageOperationsList({type:'mod',newState:object.target,oldState:object.target.oldState});
      }

      //thumb replacer.
      if(object.target.type == 'image') {
        if((object.target.width * object.target.scaleX) > this.ThumbnailReplacerService.THRESHOLD) {
          this.ThumbnailReplacerService.imageIdsList_push(object.target.id);
          this.ThumbnailReplacerService.startExecutionOfTasks();
        }
      }
    })

    this.canvas.on('object:removed',(object:any) => {
      console.log("Venki: object:removed",object);
      this.fileservice.updatePageChangeStatus(this.currentPageNumber,true);
      this.undoStatusChanged.emit(false);
      if("fromServer" in object.target){
        if(object.target.fromServer) {
          console.log("Venki: object just came from server",object.target.type,object.target.id);
          object.target.fromServer = false;
        }
        else if(!this.isToolsOpen && object.target.customType != 'compass' && object.target.customType != 'ruler'&& object.target.customType != 'setsquare'&& object.target.customType != 'protractor') {
          this.sendObjectRemovedToSocket(object);
        }
      }
      else if(!this.isToolsOpen && object.target.customType != 'compass' && object.target.customType != 'ruler'&& object.target.customType != 'setsquare'&& object.target.customType != 'protractor') {
        this.sendObjectRemovedToSocket(object);
      }
      if(!this.isToolsOpen && object.target.customType != 'compass' && object.target.customType != 'ruler'&& object.target.customType != 'setsquare'&& object.target.customType != 'protractor' && object.target.name != "windowYoutube" &&
        object.target.name != "windowVideoMyLibrary" &&
        object.target.name != "windowDocMyLibrary" &&
        object.target.name != "windowDoc" &&
        object.target.name != "windowAudioMyLibrary" &&
        object.target.name != "window3D") {
        this.clearRemovedOperationsList()
        let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
        this.redoStatusChanged.emit(test2);
        if (this.delCheck) {
          this.UndoRedoService.pushOperationIntopageOperationsList({type:'multirem',newState:{type: "multiremObj", objects: this.multiObjsToRem},oldState:{type: "multiremObj", objects: this.multiObjsToRem}});
        }
        if (!this.delCheck && this.multiObjsToRem.length<1) {
          this.UndoRedoService.pushOperationIntopageOperationsList({type:'rem',newState:object.target,oldState:object.target.oldState});
        }
      }
    })

    // ROHAN: canvas point erasing undo-redo
    this.canvas.on("erasing:end", (e: any) => {
      this.undoStatusChanged.emit(false);
      if (e.targets && e.targets.length > 0) {
        const originalStates = e.targets.map(target => { // Save the id's and erased paths of all the objects erased
          return {
            id: target.id,
            eraserState: target.eraser ? [...target.eraser._objects] : []
          };
        });

        this.UndoRedoService.clearRedoState();
        let test2 = this.UndoRedoService.isRemovedOperationsListEmpty();
        this.redoStatusChanged.emit(test2);
       //  this.UndoRedoService.pushOperationIntopageOperationsList({ type: 'erase', newState: (originalStates), oldState: null });
        this.UndoRedoService.pushOperationIntopageOperationsList({ type: 'erase', newState: JSON.parse(JSON.stringify(originalStates)), oldState: null });
      }
    })

    this.canvas.on('before:transform',(object:any)=>{
      console.log("Venki: before transform",object);
      var target = object.transform.target;
      var oldState = target.toObject();
      oldState.selectable = true;
      if("name" in object.transform.target) {
        oldState.name = object.transform.target.name;
      }
      target.oldState = oldState;
    })
   }


   handleColorPicker(eventData: MouseEvent, transform: any, x: number, y: number) {
    this.selectedObject = transform.target;
    this.addcolorpicker=false;
    console.log("Bandana Das",this.selectedObject);
    console.log("VENKI: custom color-picker variable", this.selectedObject.isColorPickerOpen);


    console.log("Bandana Das1111",this.selectedObject);
    if(this.selectedObject.type==="i-text"){
      this.SelectedColorpallete=this.selectedObject.fill;
    }


   else if (this.selectedObject.type === 'group') {
    console.log("this.selectedObject.type",this.selectedObject.type)
      let colorofgroup
      this.selectedObject._objects.forEach((obj) => {
        //For arrow

        if (obj.type === 'line' || obj.type === 'triangle') {
          // obj.set({ stroke: selectedColor, fill: selectedColor });
    colorofgroup=obj.stroke;
        }
        //For cylinder
        else if (obj.type === 'path' ) {
         // obj.set({ stroke: selectedColor });
          colorofgroup=obj.stroke;
        }
      });
      this.SelectedColorpallete=colorofgroup;
    }
    else{
      this.SelectedColorpallete=this.selectedObject.stroke;
      console.log("hiiiiiii",this.SelectedColorpallete);
    }

    console.log("this.SelectedColorpallete", this.SelectedColorpallete)
    let indexcolor=this.selectedColors.findIndex(item=>item===this.SelectedColorpallete)
   this.customcoloractive=indexcolor===-1?true:false;
   console.log("custom color bandana",this.customcoloractive);
  console.log(this.selectedObject.stroke)
  console.log(this.sideGreyAreaWidth,"this.sideGreyAreaWidth Bandana2")
    //var offsetLeft = 15;
    var offsetLeft = 15 + this.sideGreyAreaWidth;
    var offsetTop = 15;
    console.log("VENKI: debugg", this.canvas.height, transform.target.top);
    if ((transform.target.top + transform.target.height) > (this.canvas.height * 0.75)) { //if object br position is more than 75% of canvas height.
      offsetTop = offsetTop - 200; //removing some value from the top.
      offsetLeft = (offsetLeft + 10);
      console.log("left1,colorpicker Bandana2",offsetLeft)
    }

    let top = y + offsetTop;
    let left = x + offsetLeft;

      console.log(y,x,"Bandana2");
    const screenWidth = window.innerWidth;
    const colorPickerWidth = 210;

    let canvasWidth=screenWidth-this.sideGreyAreaWidth


    if ((left + colorPickerWidth) > canvasWidth) {
      left = (x - colorPickerWidth+10 )+this.sideGreyAreaWidth;


      console.log("left,colorpicker Bandana2",left)
    }

    if (this.isLoginByUser()) {
      this.colorPickerPosition = { top, left };
    } else {
      top = top + 380;
      left = left + 30;
      this.colorPickerPosition = { top, left };
    }

    if (this.selectedObject.isColorPickerOpen != null) {
      if (this.selectedObject.isColorPickerOpen == false) {
        this.colorPickerVisible = true;
        this.selectedObject.isColorPickerOpen = true;
        this.contextBoxVisible = false;
        this.selectedObject.isContextMenuOpen = false;
      } else if (this.selectedObject.isColorPickerOpen == true) {
        this.colorPickerVisible = false;
        this.selectedObject.isColorPickerOpen = false;
        this.contextBoxVisible = false;
        this.selectedObject.isContextMenuOpen = false;
      }
    }
    if (this.selectedObject.isColorPickerOpen == true || this.selectedObject.isContextMenuOpen == true) {
      this.isTextSelected = false;
    }
    else {
    if(this.selectedObject.type==="i-text"){
    console.log("texteditior sameer 2")
      this.isTextSelected = true;
    }
    }
  }


  handleMenuControl(eventData: MouseEvent, transform: any, x: number, y: number) {
    console.log("mouseevent Bandana",eventData)
    this.selectedObject = transform.target;
    console.log("VENKI: handleMenuControl target",transform.target);

     var offsetLeft = -15 - 75 + this.sideGreyAreaWidth;
     var offsetTop = 15;

     if((transform.target.top+transform.target.height)  > (this.canvas.height*0.50)) {//if object br position is more than 75% of canvas height.
      offsetTop = offsetTop - 350;//removing some value from the top.
      offsetLeft = offsetLeft - 10;
    }

     let top = y + offsetTop;
     let left = x + offsetLeft;

     if (left < 0) {
      left = 0+30; // Adjust the left position to be within the canvas
  }

     this.contextBoxPosition = { top, left };

    if(this.selectedObject.isContextMenuOpen != null) {
      if(this.selectedObject.isContextMenuOpen == false) {
        this.contextBoxVisible = true;
        this.selectedObject.isContextMenuOpen = true;
        this.colorPickerVisible = false;
        this.selectedObject.isColorPickerOpen = false;
      }
      else if(this.selectedObject.isContextMenuOpen == true){
        this.contextBoxVisible = false;
        this.selectedObject.isContextMenuOpen = false;
        this.colorPickerVisible = false;
        this.selectedObject.isColorPickerOpen = false;
      }
     }
     if (this.selectedObject.isColorPickerOpen == true || this.selectedObject.isContextMenuOpen == true) {
      this.isTextSelected = false;
    }
    else {
    //   if()
    //   this.isTextSelected = true;
    // }
    if(this.selectedObject.type==="i-text"){
    console.log("texteditior sameer 3")
      this.isTextSelected = true;
    }
    }
   }

  //  handleTextMenuPosition(target) {
  //   let offsetTop = -100;
  //   let offsetLeft = -50;
  //   let top = target.top + offsetTop;
  //   let left = (target.width / 2) + target.left - (300);
  //   this.textContextPosition = { top, left };
  //  }


  handleTextMenuPosition(target) {
    console.log("Bandana opening text menu position", target);

    const offsetTop = -100;
    const offsetLeft = -50;
    // const menuWidth = 300; // Adjust this to match the actual width of your menu
    // const menuHeight = 50; // Adjust this to match the actual height of your menu

setTimeout(()=>{
  let TextContextMenuDimensions = document.getElementById("textContextMenu") as HTMLElement;
  let TextContextMenuContainer=TextContextMenuDimensions.getBoundingClientRect();
  let menuWidth =  TextContextMenuContainer.width; // Width of color picker
   let menuHeight = TextContextMenuContainer.height; // Height of color picker
   let TextContextMenuLeft=TextContextMenuContainer.left;


  let top = target.top + offsetTop;
  let left = target.left + (target.width / 2) - menuWidth / 2 + offsetLeft;
  console.log("top of handleTextMenuPosition",top)


  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;


  if (top < 0) {
      top = 0;
  }


  if (left < 0) {
      left = 0;
  }


  if (left + menuWidth > viewportWidth) {
      left = viewportWidth - menuWidth;
  }
  if (top + menuHeight > viewportHeight) {
      top = viewportHeight - menuHeight;
  }


  this.textContextPosition = { top, left };
},100)

}



handletextcolorpicker(){
  console.log("hiii handle text colorpicker Bandana");
  const TextcolorPickerContainer = document.getElementById("TextcolorPicker") as HTMLElement;
let screenWidth = window.innerWidth; // Screen width
 let  screenHeight = window.innerHeight; // Screen height
  if(TextcolorPickerContainer){
    let TextColorpickerDimensions = TextcolorPickerContainer.getBoundingClientRect();
    let TextcolorPickerWidth = TextColorpickerDimensions.width; // Width of color picker
    let TextcolorPickerHeight = TextColorpickerDimensions.height; // Height of color picker
    let TextcolorPickerLeft=TextColorpickerDimensions.left;
   console.log(TextcolorPickerContainer,"TextcolorPickerContainer")

   let TextContextMenuDimensions = document.getElementById("textContextMenu") as HTMLElement;
   let TextContextMenuContainer=TextContextMenuDimensions.getBoundingClientRect();
   let TextContextMenuWidth =  TextContextMenuContainer.width; // Width of color picker
    let TextContextMenuHeight = TextContextMenuContainer.height; // Height of color picker
    let TextContextMenuLeft=TextContextMenuContainer.left;
    console.log("TextContextMenuWidth",TextContextMenuWidth)


   if (TextcolorPickerLeft + TextcolorPickerWidth+210 > screenWidth&&((TextContextMenuContainer.top + TextcolorPickerHeight+210 > screenHeight)===false)) {
    // Adjust left position to keep it within the screen
    let removalofleft = TextContextMenuWidth +  TextcolorPickerWidth;
    //let removalofleft=colorPickerWidth + colorPickerDimensions_width;
   // const newLeftPosition = screenWidth-550; // 10px buffer
   const newLeftPosition = screenWidth-(removalofleft+200);
    this.colorPickerPosition.left = newLeftPosition; // Update Angular binding
    TextContextMenuDimensions.style.left = `${newLeftPosition}px`; // Update DOM
    console.log('Adjusted Left Position (Right Overflow):', newLeftPosition);
  }


  }


}

    isMultitouch: boolean = false;
   eventsForCornerFuncOn() {
    this.canvas.off("touch:gesture");
    this.canvas.off("selection:updated");
    this.canvas.off("selection:created");
    this.canvas.off("object:moving");
    this.canvas.off("text:editing:entered");
    this.canvas.off("text:editing:exited");
    this.canvas.off("object:rotating");
    this.canvas.off("selection:cleared");
    this.canvas.off("object:scaling");
    console.log("VENKI: eventsForCornerFunc triggered");
    this.canvas.on('touch:gesture', (event) => {
      if (event.e.touches && event.e.touches.length > 1) {
        this.isMultitouch = true;

        // Get the touched objects on the canvas
        const touchedObjects = this.canvas.getObjects().filter((obj: any) => obj === event.target);

        if (touchedObjects.length > 0) {
          // Check if groupleft or groupright is one of the touched objects
          if (touchedObjects.includes(this.groupleft)) {
            console.log('Multitouch detected on groupleft');
            this.dialogService.showSimplePopup('Multitouch detected.');
            this.canvas.discardActiveObject();
            // Lock movement, scaling, rotation for groupleft
            // this.groupleft.set({
            //   lockMovementX: true,
            //   lockMovementY: true,
            //   lockScalingX: true,
            //   lockScalingY: true,
            //   lockRotation: true,
            //   selectable: false,
            // });
          }

          if (touchedObjects.includes(this.groupright)) {
            console.log('Multitouch detected on groupright');
            this.dialogService.showSimplePopup('Multitouch detected.');

            // if (event.target === this.groupright) {
            //   this.groupright.set({
            //     lockMovementX: true,
            //     lockMovementY: true,
            //     lockScalingX: true,
            //     lockScalingY: true,
            //     lockRotation: true,
            //     selectable: false,
            //   });
            // } else if (event.target === this.groupleft) {
            //   this.groupleft.set({
            //     lockMovementX: true,
            //     lockMovementY: true,
            //     lockScalingX: true,
            //     lockScalingY: true,
            //     lockRotation: true,
            //     selectable: false,
            //   });
            // }
            // Lock movement, scaling, rotation for groupright
            this.groupright.set({
              lockMovementX: true,
              lockMovementY: true,
              lockScalingX: true,
              lockScalingY: true,
              lockRotation: true,
              selectable: false,
            });
          }

          // If multitouch detected on any other object, handle it
          touchedObjects.forEach((obj: any) => {
            if (obj !== this.groupright) {
              console.log('Multitouch detected on another object:', obj);

              this.dialogService.showSimplePopup('Multitouch detected.');

              // Lock movement, scaling, rotation for this object
              this.groupright.set({
                lockMovementX: true,
                lockMovementY: true,
                lockScalingX: true,
                lockScalingY: true,
                lockRotation: true,
                selectable: false,
              });
              if (event.target === this.groupright) {
                this.canvas.discardActiveObject();
                this.groupright.set({
                  lockMovementX: true,
                  lockMovementY: true,
                  lockScalingX: true,
                  lockScalingY: true,
                  lockRotation: true,
                  selectable: false,
                });
              } else if (event.target === this.groupleft) {
                this.canvas.discardActiveObject();
                this.groupleft.set({
                  lockMovementX: true,
                  lockMovementY: true,
                  lockScalingX: true,
                  lockScalingY: true,
                  lockRotation: true,
                  selectable: false,
                });
                // this.groupleft.off();
              }
            }
          });

          if(event.target === this.groupleft) {
            touchedObjects.forEach((obj: any) => {
              if (obj !== this.groupleft) {
                console.log('Multitouch detected on another object:', obj);

                this.dialogService.showSimplePopup('Multitouch detected.');
                // this.groupleft.off();
                // Lock movement, scaling, rotation for this object
                this.groupleft.set({
                  lockMovementX: true,
                  lockMovementY: true,
                  lockScalingX: true,
                  lockScalingY: true,
                  lockRotation: true,
                  selectable: false,
                });
              }
            });
          }
        }

        // Render canvas after making changes
        this.canvas.renderAll();
      } else {
        this.isMultitouch = false;
      }
    });


    this.canvas.on('selection:updated', (object:any)=> {
      object.selected[0].isColorPickerOpen = false;
      object.selected[0].isContextMenuOpen = false;
      this.colorPickerVisible = false;
      this.contextBoxVisible = false;
      this.dropdownOpen = false;
      this.dropdownOpenFont = false;
      console.log("VENKI:selection:updated",object)
      // show the playIcon control only for the multimedia objects
      if (object.selected[0].name && this.multimediaObjArr.includes(object.selected[0].name)) {
        object.selected[0].setControlsVisibility({
          tl: false,
          mt: false,
          tr: false,
          ml: false,
          mr: false,
          bl: false,
          mb: false,
          br: false,
          rotateControl: false
        })
      } else {
        object.selected[0].setControlsVisibility({
          playControl: false
        })
      }

      if (object.selected[0].customType != "compass" && object.selected[0].name != "ruler" && object.selected[0].name != "setsquare" && object.selected[0].name != "protractor") {
        console.log('selection created')
        object.selected[0].setControlsVisibility({
          mtr: false
        })
      }

      if ((object.selected[0].name && object.selected[0].name === "IText") ||
      (object.selected[0].oldState && 'type' in object.selected[0].oldState)) {
        if(object.selected[0].name == "IText" || object.selected[0].oldState.type == "i-text") {
          console.log("texteditior sameer 4")
          this.isTextSelected = true;
          this.handleTextMenuPosition(object.selected[0]);
          this.updateTextContextStates();
        }else{
          this.isTextSelected = false;
        }
      }
      else{
        this.isTextSelected = false;
      }
    });
    this.canvas.on('selection:created', (object:any)=> {

      console.log("VENKI:selection:created", object);
      object.selected[0].isColorPickerOpen = false;
      object.selected[0].isContextMenuOpen = false;
      this.colorPickerVisible = false;
      this.contextBoxVisible = false;
      console.log("DDDDDDDDDDddd",object)
      console.log("DDDDDDDDDDddd",object.selected[0])
      if (object.selected[0].customType != "compass" && object.selected[0].name != "ruler" && object.selected[0].name != "setsquare" && object.selected[0].name != "protractor") {
        console.log('selection created')
        object.selected[0].setControlsVisibility({
          mtr: false
        })
      }
      if (this.canvas.getActiveObject()?.type === "activeSelection") {
        for (let i = 0; i < this.canvas.getActiveObject()._objects.length; i++) {
          if (this.multimediaObjArr.includes(this.canvas.getActiveObject()._objects[i].name)) {
            var activeSelection = this.canvas.getActiveObject();
            // Do not trigger the rotate icon and disable resizing if the multiSelected objects has multimedia objects
            activeSelection.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              bl: false,
              br: false,
              tl: false,
              tr: false,
              mtr: false,
              rotateControl: false
            });
        
            this.canvas.requestRenderAll();
            break;
          }
        }
        let multiObj = this.canvas.getActiveObject();
        multiObj.setControlsVisibility({
          playControl: false,
          mtr: false
        })
      }
      if (object.selected[0].name && this.multimediaObjArr.includes(object.selected[0].name)) {
        object.selected[0].setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          rotateCOntrol: false
        })
      } else {
        object.selected[0].setControlsVisibility({
          playControl: false
        })
      }

        if(object.selected[0].name && object.selected[0].name == "IText" || object.selected[0].type && object.selected[0].type == "i-text") {
          console.log("texteditior sameer 5")
          this.isTextSelected = true;
          this.handleTextMenuPosition(object.selected[0]);
          this.updateTextContextStates();
        }else{

          this.isTextSelected = false;
        }

    });



    this.canvas.on('object:moving', (object: any) => {
      console.log("VENKI:object:moving !!!!!!!!!");
      this.colorPickerVisible = false;
      this.contextBoxVisible = false;
      this.dropdownOpen = false;
      this.dropdownOpenFont = false;
      object.target.isColorPickerOpen = false;
      object.target.isContextMenuOpen = false;
      console.log("movinggggg",object)
      if ((object.target.name && object.target.name === "IText") ||
      (object.target.oldState && 'type' in object.target.oldState)) {
        if (object.target.name == "IText"|| object.target.oldState.type == "i-text") {
          console.log("texteditior sameer 6")
          this.isTextSelected = true;
          this.handleTextMenuPosition(object.target);
        } else {
          this.isTextSelected = false;
        }
      } else {
        this.isTextSelected = false;
      }

      let obj = object.target;

      const paddingLeftRight = 30; // Padding for left and right
      const paddingTop = 30; // Padding for top
      const paddingBottom = 95; // Padding for bottom

      console.log(obj, "Bandana obj");
      console.log(obj.getScaledHeight(), obj.getScaledWidth(), "Bandana obj currrent height and width");



      if (obj.getScaledHeight() > obj.canvas.height - paddingTop - paddingBottom ||
          obj.getScaledWidth() > obj.canvas.width - paddingLeftRight * 2) {
        console.log("getting in first if bandana");
        return;
      }

      obj.setCoords();

      // Top-left corner
      if (obj.getBoundingRect().top < paddingTop || obj.getBoundingRect().left < paddingLeftRight) {
        console.log("getting in second if bandana");
        obj.top = Math.max(obj.top, obj.top - (obj.getBoundingRect().top - paddingTop));
        obj.left = Math.max(obj.left, obj.left - (obj.getBoundingRect().left - paddingLeftRight));
      }

      // Bottom-right corner
      if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height - paddingBottom ||
          obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width - paddingLeftRight) {
        console.log("getting in third if bandana");
        obj.top = Math.min(
          obj.top,
          obj.canvas.height - obj.getBoundingRect().height + obj.top - (obj.getBoundingRect().top + paddingBottom)
        );
        obj.left = Math.min(
          obj.left,
          obj.canvas.width - obj.getBoundingRect().width + obj.left - (obj.getBoundingRect().left + paddingLeftRight)
        );
      }

      console.log(obj.getBoundingRect(), "Bandana gbr");
    });


    // this.canvas.on('path:created',(object:any) => {
    //   console.log("sumant ! path created",object)
    //   let newObject = object.target.toObject()
    //   newObject.uniqueID = '12345'
    //   this.fileservice.sendObjDataToSocket(newObject,'add')
    // })

    this.canvas.on('text:editing:entered',(event)=> {
      this.handleTextMode(true);
      console.log("hello rext entered")
      this.canvas.off("text:editing:exited");
      this.canvas.on('text:editing:exited',(event)=> {
        this.handleTextMode(false);
        console.log("hello rext exited")
       });
     });



    this.canvas.on('object:rotating', (event)=> {
      this.colorPickerVisible = false;
      this.contextBoxVisible = false;
      this.isTextSelected = false;
      this.dropdownOpen = false;
      this.dropdownOpenFont = false;
    })

    this.canvas.on('selection:cleared',(event)=>{
      console.log("VENKI:selection:cleared",event);
      this.colorPickerVisible = false;
      this.contextBoxVisible = false;
      this.isTextSelected = false;
      this.dropdownOpen = false;
      this.dropdownOpenFont = false;
    });

    this.canvas.on('object:scaling', (event)=> {
      // this.restrictScaling(event.target)
     })
   }

   eventsForCornerFuncOff() {
    console.log("Local Debugg eventsForCornerFuncOff");
    this.canvas.off("touch:gesture");
    this.canvas.off("selection:updated");
    this.canvas.off("selection:created");
    this.canvas.off("object:moving");
    this.canvas.off("text:editing:entered");
    this.canvas.off("text:editing:exited");
    this.canvas.off("object:rotating");
    this.canvas.off("selection:cleared");
    this.canvas.off("object:scaling");
    this.canvas.off("mouse:down");
    this.canvas.off("mouse:up");
   }

   handleToolsDelete(eventData: MouseEvent, transformData: any, x: number, y: number) {
    console.log('VENKI: handleToolsDelete');
        if (transformData.target.name === 'ruler') {
            this.removeRuler();
        } else if (transformData.target.name === 'setsquare') {
            this.removeSetsquare();
        } else if (transformData.target.name === 'protractor') {
            this.removeProtractor();
        } else if (transformData.target.name === 'groupleft') {
            this.removeCompass();
        }
   }


   sendObjDataToSocket(obj,type){
    //console.log("night test - sending object to socket function called")
    if((this.avChatStarted() || (!this.isLoginByTeacher()))){
      // obj.uniqueId = Math.floor(100000 + Math.random() * 900000)
      if ('src' in obj && (obj.src.includes("protractor") || obj.src.includes("ruler")
        || obj.src.includes("14cmScale1") || obj.src.includes("setSquare"))) {
        console.log("Object with 'protractor' or 'ruler' in src is skipped");
        return;
        }
        if (obj.type === 'group' && obj.name != 'arrow' && obj.name != 'cylinder') {
          console.log("Group type objects are skipped");
          return;
        }
        if(obj.type == 'text' && obj.text == 'Angle: 40°\nRadius: 206 px'){
          console.log("text type objects are skipped");
          return;
        }

          // let canvasObjects = JSON.parse(localStorage.getItem('currentCanvas'))["objects"]
          // canvasObjects.push(obj)
        if(localStorage.getItem('userId')){
          obj.ownerType = 'teacher'
        }
        else{
          obj.ownerType = 'student'
        }
        // this.setCanvasLocalStrValue(canvasObjects)
        let currentObj = {
          object : obj,
          pageNo:localStorage.getItem('currentPage'),
          action_type : type,
          mainLessonId:localStorage.getItem('mainlessonID')
        }
        //console.log("night test - sending object to socket",currentObj)
        this.fileservice.emitCanvasOperationToServer(currentObj);
      }
   }

   async eraseAllToSocket(){
    if((this.avChatStarted() || (!this.isLoginByTeacher()))){
      let currentObj = {
        object : '',
        pageNo:localStorage.getItem('currentPage'),
        action_type : 'eraseAll',
        mainLessonId:localStorage.getItem('mainlessonID')
      }
      this.fileservice.emitCanvasOperationToServer(currentObj);
      }
    }

   async sendTextToSocket(){
    const objects = this.canvas.getObjects();
    const lastObject = objects[objects.length - 1].toJSON(['id'],['ownerType']);
    let addText = await this.lastObjectTextOrNot()
    if(addText){
      let currentObj = {
        object : lastObject,
        pageNo:localStorage.getItem('currentPage'),
        action_type : 'add',
        mainLessonId:localStorage.getItem('mainlessonID')
      }
      this.fileservice.emitCanvasOperationToServer(currentObj);
    }
   }

   erasePointObj(){
    console.log("calling", this.EraseCanvas.select ,this.EraseCanvas.onTouch)
    if(this.EraseCanvas.select&&this.EraseCanvas.onTouch){
     let activeObj=this.canvas.getActiveObject()
     this.canvas.remove(activeObj)
     this.canvas.renderAll()
     if(activeObj == null || !('name' in activeObj)) {
      return
     }
     if(activeObj.name == "windowYoutube" ||
      activeObj.name == "windowVideoMyLibrary" ||
      activeObj.name == "windowDocMyLibrary" ||
      activeObj.name == "windowDoc" ||
      activeObj.name == "windowAudioMyLibrary" ||
      activeObj.name == "window3D") {
        const fileId = localStorage.getItem("FileID");
        this.fileservice.deleteMediaDocMetaData(fileId,activeObj.id).subscribe((res)=>{
          console.log("Local Debugg: deleteMediaDocMetaData", res);
        },(error)=>{
          console.log("Local Debugg: deleteMediaDocMetaData error", error);
        })
      }
     }
    }


    saveShapeImgObj(){
      const objects = this.canvas.getObjects();
      let jsonObjects = objects.map(obj => obj.toJSON(['id']));
      let canvasObjects = jsonObjects
      this.setCanvasLocalStrValue(canvasObjects)
    }

    async removeCompassGroup() {
      try {
        this.canvas._objects = this.canvas._objects.filter(object => {
          if ((object.type === 'group' && (object.name === 'groupleft' || object.name === 'groupright'))  || object.name === 'circleInfo') {
            this.canvas.remove(object);
            return false; // Exclude this object from the new array
          }
          return true; // Include this object in the new array
        });
      } catch (err) {
        console.error(err); // Log the error for debugging
      }
    }

    async removeCompassGroupLocalStr() {
      try {
        let objects = JSON.parse(localStorage.getItem('currentCanvas')).objects;
        objects = objects.filter((object,ind) => {
          if ((object.type === 'group' && (object.name === 'groupleft' || object.name === 'groupright'))  || object.name === 'circleInfo') {
            objects.splice(ind,1)
            this.setCanvasLocalStrValue(objects)
            return false; // Exclude this object from the new array
          }
          return true; // Include this object in the new array
        });
      } catch (err) {
        console.error(err); // Log the error for debugging
      }
    }

    async saveBgcolorBgframeToCanvas() {
      let canvasObject = this.canvas.getObjects().map(obj => obj.toJSON(['id', 'name', 'ownerType']));
      let allObjects = JSON.parse(localStorage.getItem('currentCanvas')).objects;
      try {
          const hasBgColorOrFrame = allObjects.some(obj => obj.hasOwnProperty('bgColor') || obj.hasOwnProperty('bgFrame'));
          if (hasBgColorOrFrame) {
              for (const [index, item] of allObjects.entries()) {
                  if (item.bgColor || item.bgFrame) {
                      canvasObject.splice(index, 0, item);
                  }
              }
          }
      } catch (error) {
          console.error('Error processing background color or frame:', error);
      } finally {
          return canvasObject;
      }
  }

  sendBgStatesToUndoRedoService(bgStates) {
      this.UndoRedoService.pushOperationIntopageOperationsList({type:'add-bg',oldState:bgStates.oldState,newState:bgStates.newState});
      let test1=this.UndoRedoService.isPageOperationsListEmpty();
      this.undoStatusChanged.emit(test1);
  }

  clearRemovedOperationsList() {
    this.redoStatusChanged.emit(true);//mean disable redo.
    this.UndoRedoService.clearRedoState();
  }

  restrictScaling(object) {
    //console.log(object.selected[0], "Bandana");
    console.log("Bandana Object", object);
    //let obj = object.selected[0];
    let obj = object;

    const originalWidth = obj.width;
    const originalHeight = obj.height;


   // if(obj.type==="image"){

    // Define your desired minimum width and height for the image on the canvas
    if(obj.type==="image"){
    const desiredMinWidth = 150;  // Adjust as needed
    const desiredMinHeight = 150; // Adjust as needed

    // Calculate the dynamic minimum scale based on the desired minimum dimensions
    const minScaleX = desiredMinWidth / originalWidth;
    const minScaleY = desiredMinHeight / originalHeight;

    const minScale = Math.min(minScaleX, minScaleY);
    const maxScale = 2.0;  // Adjust as needed

    console.log("obj", obj);

    obj.on('scaling', function() {
      console.log("Scalex", obj.scaleX);

      if (obj.scaleX < minScale) {
        obj.scaleX = minScale;
      }
      if (obj.scaleY < minScale) {
        obj.scaleY = minScale;
      }

      obj.setCoords();

    });
   }else{


    const desiredMinWidth = 80;  // Adjust as needed
    const desiredMinHeight = 80; // Adjust as needed

    // Calculate the dynamic minimum scale based on the desired minimum dimensions
    const minScaleX = desiredMinWidth / originalWidth;
    const minScaleY = desiredMinHeight / originalHeight;

    const minScale = Math.min(minScaleX, minScaleY);


    console.log("obj", obj);

    obj.on('scaling', function() {
      console.log("Scalex", obj.scaleX);

      if (obj.scaleX < minScale) {
        obj.scaleX = minScale;
      }
      if (obj.scaleY < minScale) {
        obj.scaleY = minScale;
      }

      obj.setCoords();

    });
  }
  }


  handleTextMode(value){
    console.log(value,"textmodevalue bandana");
   this.toggletextactive.emit(value);
  }


  pageNavDisabledState: Boolean = true;
  blockPageNav() {
    this.pageNavDisabledState = true;
    this.passNavDisabledState.emit(true);
  }

  unblockPageNav() {
    this.pageNavDisabledState = false;
    this.passNavDisabledState.emit(false);
  }

  isPageNavDisabled() {
    return this.pageNavDisabledState;
  }

  AddpageDisabledState: Boolean = false;
  blockADDPage() {
    this.AddpageDisabledState = true;
  }

  unblockADDPage() {
  this.AddpageDisabledState = false;
  }

  isADDPageDisabled() {
  return this.AddpageDisabledState;
  }

  addWindowThumbnailToCanvas(data:windowThumbnail) {
    console.log("Local Debugg:addWindowThumbnailToCanvas",data);
    let centreImage;
    let customType = data.windowType;
    let url = data.url;
    let thumbnailImageHeight = 300;
    let thumbnailImageWidth = 300;
    const thumbnailId = Math.floor(100000 + Math.random() * 900000);
    let groupLeft = 100;
    let groupTop = 100;

    const canvasCenter = {
      x: this.canvas.getWidth() / 2,
      y: this.canvas.getHeight() / 2,
    };
    const range = 30;
    const randomLeft = canvasCenter.x + (Math.random() * range * 2 - range);
    const randomTop = canvasCenter.y + (Math.random() * range * 2 - range);

    switch(data.windowType) {
      case 'windowYoutube':
        centreImage = 'assets/insert/mediaDocThumbnailIcons/playButton.svg';
        thumbnailImageHeight = 160;
        thumbnailImageWidth = 280;
        if(data.insertedFrom == 'insert') {
          groupLeft = randomLeft - 200;
          groupTop = randomTop;
        } else {
          groupLeft = randomLeft + 350;
          groupTop = randomTop;
        }
        break;
      case 'windowVideoMyLibrary':
        centreImage = 'assets/insert/mediaDocThumbnailIcons/playButton.svg';
        thumbnailImageHeight = 100;
        thumbnailImageWidth = 100;
        if(data.insertedFrom == 'insert') {
          groupLeft = randomLeft - 200;
          groupTop = randomTop - 200;
        } else {
          groupLeft = randomLeft + 450;
          groupTop = randomTop - 200;
        }
        break;
      case 'windowDocMyLibrary':
        case 'windowVideoMyLibrary':
        centreImage = 'assets/insert/mediaDocThumbnailIcons/playButton.svg';
        thumbnailImageHeight = 100;
        thumbnailImageWidth = 100;
        if(data.insertedFrom == 'insert') {
          groupLeft = randomLeft - 500;
          groupTop = randomTop - 50;
        } else {
          groupLeft = randomLeft + 450;
          groupTop = randomTop + 50;
        }
        break;
      case 'windowDoc':
        centreImage = 'assets/insert/mediaDocThumbnailIcons/playButton.svg';
        thumbnailImageHeight = 100;
        thumbnailImageWidth = 100;
        if(data.insertedFrom == 'insert') {
          groupLeft = randomLeft - 300;
          groupTop = randomTop;
        } else {
          groupLeft = randomLeft + 350;
          groupTop = randomTop - 150;
        }
        break;
      case 'windowAudioMyLibrary':
        thumbnailImageHeight = 74;
        thumbnailImageWidth = 74;
        centreImage = 'assets/insert/mediaDocThumbnailIcons/audioButton.svg';
        if(data.insertedFrom == 'insert') {
          groupLeft = randomLeft - 200;
          groupTop = randomTop + 100;
        } else {
          groupLeft = randomLeft + 400;
          groupTop = randomTop - 50;
        }
        break;
      case 'window3D':
        thumbnailImageHeight = 74
        thumbnailImageWidth = 74;
        centreImage = 'assets/insert/mediaDocThumbnailIcons/3DButton.svg';
        if(data.insertedFrom == 'insert') {
          groupLeft = randomLeft - 500;
          groupTop = randomTop - 200;
        } else {
          groupLeft = randomLeft + 250;
          groupTop = randomTop - 200;
        }
        break;
    }
      fabric.Image.fromURL(data.thumbnail, (thumbnailImage) => {
        thumbnailImage.set({
          top:300,
          left:300,
        })
        thumbnailImage.scaleToHeight(thumbnailImageHeight);
        thumbnailImage.scaleToWidth(thumbnailImageWidth);

        fabric.Image.fromURL(centreImage,(centreimage)=>{
          var centerX = thumbnailImage.left + thumbnailImage.getBoundingRect().width / 2;
          var centerY = thumbnailImage.top + thumbnailImage.getBoundingRect().height / 2;
          centreimage.set({
            left: centerX - (centreimage.width) / 2,
            top: centerY - (centreimage.height) / 2
          });
          var text = new fabric.Text(data.name, {
            fontSize: 20,
            fill: 'black',
            backgroundColor: 'white'
          });
          text.set({
            top: centerY + (thumbnailImage.getBoundingRect().height) / 2,
            left: centerX - (text.width) / 2
          });
          var centreText = new fabric.Text("Webpage", {
            fontSize: 15
          })
          centreText.set({
            left: centerX - (centreText.width)/2,
            top: centerY - (centreText.height)/2
          })
          let groupArray = [thumbnailImage, centreimage, text];
          switch(data.windowType) {
            case "windowYoutube":
            case 'windowAudioMyLibrary':
            case 'window3D':
              groupArray = [thumbnailImage, centreimage, text];
              break;
            case 'windowVideoMyLibrary':
            case 'windowDocMyLibrary':
              groupArray = [thumbnailImage, text];
              break;
            case 'windowDoc':
              groupArray = [thumbnailImage, centreText, text];
              break;
          }

          var group = new fabric.Group(groupArray, {
            left: groupLeft,
            top: groupTop,
            name: customType,
            hasBorders: true,
            hasControls: true,
            // borderColor: "#000000",
            padding: 8,
            id: thumbnailId,
            erasable: false
            });

            group.setControlsVisibility({
              tl: false,
              mt: false,
              tr: false,
              ml: false,
              mr: false,
              bl: false,
              mb: false,
              br: false,
              mtr: false,
              colorControl: false,
              menuControl: false,
              rotateControl: false,
            });

            const fileId = localStorage.getItem("FileID");
            this.fileservice.postMediaDocMetaData({fileId:fileId, thumbnailId:thumbnailId, thumbnailType: customType,url:url}).subscribe((res)=>{
              this.canvas.add(group);
              this.canvas.renderAll();
              console.log("Local Debuggggggg:",group);
            },
          (error)=> {
            console.log("Debugg: Error in postMediaDocMetaData api", error);
          })
        })

      });
  }

  private _currentPageNumber:number = 0;
  set currentPageNumber(num:number) {
    this._currentPageNumber = num;
  }
  get currentPageNumber():number {
    return this._currentPageNumber;
  }

  getCanvasBgStates() {
    console.log("02-11-2024",this.canvas.backgroundImage);
    if (this.canvas.backgroundImage != null) {
      let bg = this.canvas.backgroundImage.getSrc();
      bg = bg.replace(/^.*\/assets/, "assets");
      return {bgColor: this.canvas.backgroundColor, bgImage: bg};
    }
    else {
      return {bgColor: this.canvas.backgroundColor, bgImage: ''};
    }
  }

  updateCurrentCanvasCache() {
    let currentPageObjects = this.canvas.getObjects().map(obj => obj.toJSON(['id', 'name', 'ownerType', 'hasBorders', 'hasControls']));
    let currentPage = {
      objects: [],
      version: "1.0",
      bgColor: this.canvas.backgroundColor,
      bgFrame: '',
      customBg: '',
      pageHeight: this.browserDetectionService.getBrowserInfo().canvasHeight,
      pageWidth: this.browserDetectionService.getBrowserInfo().canvasWidth,
    };
    if (currentPageObjects) {
      currentPage.objects = currentPageObjects;
    }
    if (this.canvas.backgroundImage != null) {
      let bg = this.canvas.backgroundImage.getSrc();
      bg = bg.replace(/^.*\/assets/, "assets");
      bg.startsWith('assets') ? currentPage.bgFrame = bg : currentPage.customBg = bg;
    }
    localStorage.setItem('currentCanvas',JSON.stringify(currentPage));
  }

}

interface windowThumbnail {
  windowType:string,
  thumbnail:string,
  name:string,
  url:any,
  insertedFrom:string
}
