import { UserManagerSettings } from "oidc-client";
import { environment } from "src/environments/environment";

// const scopes = [
//   'openid',
//   'profile',
//   'email',
//   'https://www.googleapis.com/auth/classroom.announcements.readonly',
//   'https://www.googleapis.com/auth/classroom.courses',
//   'https://www.googleapis.com/auth/classroom.coursework.me',
//   'https://www.googleapis.com/auth/classroom.coursework.students',
//   'https://www.googleapis.com/auth/classroom.guardianlinks.me.readonly',
//   'https://www.googleapis.com/auth/classroom.guardianlinks.students',
//   'https://www.googleapis.com/auth/classroom.profile.emails',
//   'https://www.googleapis.com/auth/classroom.profile.photos',
//   'https://www.googleapis.com/auth/classroom.push-notifications',
//   'https://www.googleapis.com/auth/classroom.rosters',
//   'https://www.googleapis.com/auth/classroom.student-submissions.me.readonly',
//   'https://www.googleapis.com/auth/classroom.student-submissions.students.readonly',
//   'https://www.googleapis.com/auth/classroom.topics',
//   'https://www.googleapis.com/auth/classroom.topics.readonly',
// ];

const _scopeList = [
  "openid",
  "profile",
  "https://www.googleapis.com/auth/classroom.announcements.readonly",
  "https://www.googleapis.com/auth/classroom.student-submissions.me.readonly",
  "https://www.googleapis.com/auth/classroom.topics.readonly",
  "https://www.googleapis.com/auth/classroom.courses",

  "https://www.googleapis.com/auth/classroom.rosters",

  "https://www.googleapis.com/auth/classroom.profile.emails",
  "https://www.googleapis.com/auth/classroom.profile.photos",

  "https://www.googleapis.com/auth/classroom.coursework.students",
  "https://www.googleapis.com/auth/classroom.coursework.me",

  "https://www.googleapis.com/auth/classroom.courseworkmaterials",

  "https://www.googleapis.com/auth/drive",
];

export const authConfig: UserManagerSettings = {
  authority: "https://accounts.google.com",
  redirect_uri: `${environment.etheradURL}/whiteboard/signin-callback`,
  // redirect_uri: "http://localhost:4200/whiteboard/signin-callback",
  client_id:
    "549948922808-v7qte9cm2uc7hjernkggna5oragu2gbe.apps.googleusercontent.com",
  scope: _scopeList.join(" "),
  response_type: "id_token token",
  automaticSilentRenew: true,
};
