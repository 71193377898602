import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-simple-popup',
  templateUrl: './simple-popup.component.html',
  styleUrls: ['./simple-popup.component.scss']
})
export class SimplePopupComponent implements OnInit {

  @Input() popup: string = '';
  
  constructor() { }

  ngOnInit(): void {
  }

}
