import { Component, OnInit } from '@angular/core';
import { GoogleAuthService } from './google-auth.component';

@Component({
  template:`<div></div>`
})
export class SigninComponent implements OnInit {

  constructor(private _authService: GoogleAuthService) { }

  ngOnInit() {
    this._authService.signin();
  }
}

