import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserDetectionService {

  constructor() { }

  getBrowserInfo() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const viewportWidth = window.innerWidth; // Viewport width
    const viewportHeight = window.innerHeight; // Viewport height
    let canvasWidth = viewportWidth;
    let canvasHeight = (canvasWidth * 9) / 16;
    let viewPortType = 'type1';
    if(canvasHeight > viewportHeight) {
      canvasHeight = viewportHeight;
      canvasWidth = (viewportHeight * 16)/9;//set aspect ratio of canvas to 16:9
      viewPortType = 'type2';
    }
    const userAgent = navigator.userAgent;

    // Detect device type from userAgent string
    const isMobile = /Mobi|Android/i.test(userAgent);
    const isTablet = /Tablet|iPad/i.test(userAgent);

    let deviceType = 'Desktop';
    if (isMobile) {
      deviceType = 'Mobile';
    } else if (isTablet) {
      deviceType = 'Tablet';
    }

    return {
      screenWidth,
      screenHeight,
      userAgent,
      deviceType,
      viewportWidth,
      viewportHeight,
      canvasWidth,
      canvasHeight,
      viewPortType
    };
  }
}
